import { useQuery, useMutation, gql } from '@apollo/client';
import { AscotReview, AscotReviewByIdSearch, AscotReviewListSearch, CustomerSearch } from '../../__generated__/graphql';
import { GET_CUSTOMER } from './useCustomers';

export const GET_ASCOT_REVIEW = gql`
  query GetAscotReview($query: AscotReviewByIdSearch) {
    GetAscotReview(query: $query) {
      id
      supportedPersonId
      reviewDate
      reviewType
      intro
      newOutcomes
      meetingPurpose
      agreedActionsAndOutcomes
      progressToAcheivingOutComes
      socialParticipationAndInvolvement {
        answer
        score
      }
      occupation {
        answer
        score
      }
      controlOverDailyLife {
        answer
        score
      }
      personalSafety {
        answer
        score
      }
      foodAndDrink {
        answer
        score
      }
      whatDoBetter
      reviewId
      accomodationAndComfort {
        answer
        score
      }
      personalCleanlinessAndComfort {
        answer
        score
      }
      dignity {
        answer
        score
        score2
      }
      agreedActionsToChange
      infoToUpdate
      nextMeetingAgenda
    }
  }
`;

export const GET_ASCOT_REVIEWS = gql`
  query GetAscotReviews($query: AscotReviewListSearch) {
    GetAscotReviews(query: $query) {
      id
      supportedPersonId
      reviewDate
      reviewType
      intro
      newOutcomes
      meetingPurpose
      agreedActionsAndOutcomes
      progressToAcheivingOutComes
      socialParticipationAndInvolvement {
        answer
        score
      }
      occupation {
        answer
        score
      }
      controlOverDailyLife {
        answer
        score
      }
      personalSafety {
        answer
        score
      }
      foodAndDrink {
        answer
        score
      }
      whatDoBetter
      reviewId
      accomodationAndComfort {
        answer
        score
      }
      personalCleanlinessAndComfort {
        answer
        score
      }
      dignity {
        answer
        score
        score2
      }
      agreedActionsToChange
      infoToUpdate
      nextMeetingAgenda
    }
  }
`;

export const CREATE_ASCOT_REVIEW = gql`
  mutation CreateAscotReview($input: AscotReviewInput) {
    CreateAscotReview(input: $input) {
      message
      date
    }
  }
`;

export const UPDATE_ASCOT_REVIEW = gql`
  mutation UpdateAscotReview($input: AscotReviewInput) {
    UpdateAscotReview(input: $input) {
      message
      date
    }
  }
`;

export const DELETE_ASCOT_REVIEW = gql`
  mutation DeleteAscotReview($input: AscotReviewDeleteInput) {
    DeleteAscotReview(input: $input) {
      id
      date
      message
    }
  }
`;

interface GetAscotReviewResponse {
  GetAscotReview: AscotReview;
}

interface GetAscotReviewsResponse {
  GetAscotReviews: AscotReview[];
}

const refetchAscotReviews = (input: AscotReviewListSearch) => ({
  refetchQueries: [
    {
      query: GET_ASCOT_REVIEWS,
      variables: {
        query: { ...input },
      },
    },
  ],
});

export const useGetAscotReview = (query: AscotReviewByIdSearch) => {
  const { loading, data, error } = useQuery<GetAscotReviewResponse>(GET_ASCOT_REVIEW, {
    skip: !query.id && !query.reviewId,
    variables: {
      query: {
        ...query,
      },
    },
  });

  return { ascotReview: data?.GetAscotReview, loading, error };
};

export const useGetAscotReviews = (query: AscotReviewListSearch) => {
  const { loading, data, error } = useQuery<GetAscotReviewsResponse>(GET_ASCOT_REVIEWS, {
    skip: !query.customerId,
    variables: {
      query: {
        ...query,
      },
    },
  });

  return { ascotReviews: data?.GetAscotReviews, loading, error };
};

export const useCreateAscotReview = (query: AscotReviewListSearch) => {
  const [createAscotReview, mutationCreateAscotReview] = useMutation(CREATE_ASCOT_REVIEW, refetchAscotReviews(query));
  return { createAscotReview, mutationCreateAscotReview };
};

export const useUpdateAscotReview = (query: AscotReviewListSearch) => {
  const [updateAscotReview, mutationUpdateAscotReview] = useMutation(UPDATE_ASCOT_REVIEW, refetchAscotReviews(query));
  return { updateAscotReview, mutationUpdateAscotReview };
};

export const useDeleteAscotReview = (query: CustomerSearch) => {
  const [deleteAscotReview, mutationDeleteAscotReview] = useMutation(DELETE_ASCOT_REVIEW, {
    refetchQueries: [
      {
        query: GET_CUSTOMER,
        variables: {
          query: {
            ...query,
          },
        },
      },
    ],
  });

  return { deleteAscotReview, mutationDeleteAscotReview };
};
