import { useEffect, useState } from 'react';
import { Loading, LocationBar, Message } from 'components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUser, UserContextProps } from 'context/userContext';
import { SupportWorker, TeamMeeting } from '__generated__/graphql';
import { useGetTeamMeetings, useSaveTeamMeeting } from 'api/hooks/useTeamMeetings';
import { isSameDay } from 'date-fns';
import { useGetSupportWorkers } from 'api/hooks/useSupportWorkers';
import { Add } from '@mui/icons-material';
import { Helmet } from 'react-helmet';
import { useForm, FormProvider } from 'react-hook-form';
import { DateField } from 'components/Fields/DateField';
import { FormPrompt } from 'components/modals/FormPromptModal';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { TeamSelectField } from 'components/Fields/TeamSelectField';
import AttendeesField from './fields/AttendeesField';
import { TeamMeetingFormInput } from './types';

const TeamMeetingCreate = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [assignApologiesModalState, toggleApologiesModal] = useState(false);
  const [assignAttendeesModalState, toggleAttendeesModal] = useState(false);
  const [openSnack, setOpenSnack] = useState<boolean>(false);

  const { user, userLoading } = useUser() as UserContextProps;
  const team = searchParams.get('teamId') || user?.teamId || '';
  const methods = useForm<TeamMeetingFormInput>({
    mode: 'onSubmit',
    defaultValues: {
      date: new Date().getTime(),
      attendees: [],
      apologies: [],
      teamId: team,
    },
  });
  const { handleSubmit, reset, formState, watch } = methods;

  const teamId = watch('teamId');
  const { meetings } = useGetTeamMeetings({ teamId: teamId ?? '' });
  const { saveTeamMeeting, mutationSaveTeamMeeting } = useSaveTeamMeeting();

  const { supportWorkers: teamSupportWorkers, loading: teamLoading } = useGetSupportWorkers({
    teamId,
  });

  const validateDate = (d: Date) => {
    const isMeetingSameDay = meetings?.some((meeting: TeamMeeting) => {
      return isSameDay(meeting.date, d);
    });
    if (isMeetingSameDay) {
      return 'There is already a meeting on this date. Please select another date or go back to the team meetings list and edit the meeting that is already created.';
    } else {
      return true;
    }
  };

  useEffect(() => {
    const newAttendees: { supportWorkerId: string }[] = [];
    teamSupportWorkers.forEach((supportWorker: SupportWorker) => {
      newAttendees.push({ supportWorkerId: supportWorker.id });
    });
    reset({
      date: new Date().getTime(),
      apologies: [],
      attendees: newAttendees,
      teamId,
    });
  }, [reset, teamId, teamSupportWorkers]);

  useEffect(() => {
    if (teamId !== team) {
      setSearchParams({ teamId });
    }
  }, [setSearchParams, team, teamId]);

  const onSubmit = async (data: TeamMeetingFormInput) => {
    const response = await saveTeamMeeting({
      variables: {
        input: {
          ...data,
          attendees: data.attendees.map((attendee) => attendee.supportWorkerId),
        },
      },
    });
    if (response.data.SaveTeamMeeting.id) {
      navigate(`/team-meetings/${response.data.SaveTeamMeeting.id}/update?teamId=${teamId}`);
    }
  };

  if (mutationSaveTeamMeeting.loading || userLoading || teamLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Team Meetings</title>
      </Helmet>
      <LocationBar section="People" page="Team Meeting Create" Icon={Add} />
      <Message response={[mutationSaveTeamMeeting]} openSnack={openSnack} setOpenSnack={setOpenSnack} />
      <div className="my-10 px-4 md:px-[5%]">
        <button
          type="button"
          className="text-gray-500 font-semibold text-md leading-md flex items-center"
          onClick={() => navigate(`/team-meetings?teamId=${teamId}`)}
        >
          <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
        </button>

        <div className="mt-10 sm:mt-16">
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TeamSelectField label="Team" name="teamId" />
              <DateField label="Date" name="date" validate={validateDate} />
              <AttendeesField
                team={teamId ?? ''}
                assignAttendeesModalState={assignAttendeesModalState}
                toggleAttendeesModal={toggleAttendeesModal}
                assignApologiesModalState={assignApologiesModalState}
                toggleApologiesModal={toggleApologiesModal}
              />

              <button data-cy="start-meeting" type="submit" className="text-white bg-primary-700 w-fit rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
                Start Meeting
              </button>
            </form>
          </FormProvider>
        </div>
      </div>

      <FormPrompt hasUnsavedChanges={formState.isDirty} />
    </>
  );
};

export default TeamMeetingCreate;
