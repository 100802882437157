import { gql, useQuery } from '@apollo/client';
import { AbsenceSearch, Absence, AbsenceSearchTeam } from '__generated__/graphql';

export const GET_LEAVE = gql`
  query GetLeave($query: AbsenceSearch) {
    GetLeave(query: $query) {
      id
      externalId
      leaveType
      absenceReason
      description
      absenceDate
      returnDate
      duration
      durationInPeriod
      absenceStatus
      comments
      supportWorkerId
      teamId
      code
    }
  }
`;

export const GET_LEAVE_FOR_TEAM = gql`
  query GetLeaveForTeam($query: AbsenceSearchTeam) {
    GetLeaveForTeam(query: $query) {
      id
      externalId
      leaveType
      absenceReason
      description
      absenceDate
      returnDate
      duration
      durationInPeriod
      absenceStatus
      comments
      supportWorkerId
      teamId
      code
    }
  }
`;

interface GetLeaveResponse {
  GetLeave: Absence[];
}

interface GetLeaveForTeamResponse {
  GetLeaveForTeam: Absence[];
}

export const useGetLeave = (query: AbsenceSearch, skip?: boolean) => {
  const { data, loading, error } = useQuery<GetLeaveResponse>(GET_LEAVE, {
    variables: {
      skip,
      query: {
        ...query,
      },
    },
  });

  return { leave: data?.GetLeave || [], loading, error };
};

export const useGetLeaveForTeam = (query: AbsenceSearchTeam) => {
  const { data, loading, error } = useQuery<GetLeaveForTeamResponse>(GET_LEAVE_FOR_TEAM, {
    variables: {
      query: {
        ...query,
      },
    },
  });

  return { leave: data?.GetLeaveForTeam || [], loading, error };
};
