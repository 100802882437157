/* eslint-disable react/no-danger */
import Edit from '@mui/icons-material/Edit';
import { Family, InvoiceType, Maybe } from '__generated__/graphql';

interface FamilyMemberProps {
  familyMember: Maybe<Family>;
  onEditFamilyMember: (note: Maybe<Family>) => void;
  index: number;
}

const FamilyMember = ({ familyMember, onEditFamilyMember, index }: FamilyMemberProps) => {
  return (
    <div key={index} className="border border-gray-200 rounded-lg px-4 py-3 mb-3">
      <div className="flex flex-col md:flex-row items-start gap-4 md:gap-14 mt-4">
        <div className="shrink-0 w-full md:w-fit">
          <div className="text-gray-800 text-lg leading-lg font-medium mb-1.5">Name</div>
          {familyMember?.name}
        </div>
        <div className="shrink-0 w-full md:w-fit">
          <div className="text-gray-800 text-lg leading-lg font-medium mb-1.5">Email</div>
          {familyMember?.email}
        </div>
        <div className="w-full md:w-fit">
          <div className="text-gray-800 text-lg leading-lg font-medium mb-1">Invoice Type</div>
          {familyMember?.invoice || InvoiceType.None}
        </div>
        <div className="w-full md:w-fit">
          <div className="text-gray-800 text-lg leading-lg font-medium mb-1">Status</div>
          {familyMember?.isActive ? 'Active' : 'Inactive'}
        </div>
        <div className="w-full md:w-fit mt-2">
          <button
            type="button"
            onClick={() => onEditFamilyMember(familyMember)}
            className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center gap-2"
            data-cy={`wellbeing-board-review-${index}-edit-button`}
          >
            <Edit className="!w-5 !h-5" />
            Edit
          </button>
        </div>
      </div>
    </div>
  );
};

export default FamilyMember;
