import { useQuery, gql, useMutation } from '@apollo/client';
import { PlaceholderVisit, PlaceholderVisitSearch } from '__generated__/graphql';

interface PlaceholderVisitResponse {
  GetPlaceholderVisitsByRota: PlaceholderVisit[];
}

export const GET_PLACEHOLDER_VISITS_BY_ROTA = gql`
  query GetPlaceholderVisitsByRota($query: PlaceholderVisitSearch) {
    GetPlaceholderVisitsByRota(query: $query) {
      id
      teamId
      agreedRotaId
      agreedShiftRunId
      day
      startDate
      endDate
      name
      supportWorker
      note
    }
  }
`;

export const SAVE_PLACEHOLDER_VISIT = gql`
  mutation SavePlaceholderVisit($input: SavePlaceholderVisitInput) {
    SavePlaceholderVisit(input: $input) {
      id
      date
      message
    }
  }
`;

export const REMOVE_PLACEHOLDER_VISIT = gql`
  mutation RemovePlaceholderVisit($input: RemovePlaceholderVisitInput) {
    RemovePlaceholderVisit(input: $input) {
      date
      message
    }
  }
`;

export const useGetPlaceholderVisits = (query: PlaceholderVisitSearch) => {
  const { data, loading, error } = useQuery<PlaceholderVisitResponse>(GET_PLACEHOLDER_VISITS_BY_ROTA, {
    variables: {
      query: { ...query },
    },
  });

  return { placeholderVisits: data?.GetPlaceholderVisitsByRota || [], loading, error };
};

export const useSavePlaceholderVisit = (input: PlaceholderVisitSearch) => {
  const [savePlaceholderVisit, { loading: savePlaceholderVisitLoading, error: savePlaceHolderVisitError }] = useMutation(SAVE_PLACEHOLDER_VISIT, {
    fetchPolicy: 'network-only',
    refetchQueries: [
      {
        query: GET_PLACEHOLDER_VISITS_BY_ROTA,
        variables: {
          query: { ...input },
        },
      },
    ],
  });

  return {
    savePlaceholderVisit,
    savePlaceholderVisitLoading,
    savePlaceHolderVisitError,
  };
};

export const useRemovePlaceholderVisit = (input: PlaceholderVisitSearch) => {
  const [removePlaceholderVisit, { loading: removePlaceholderVisitLoading, error: removePlaceHolderVisitError }] = useMutation(REMOVE_PLACEHOLDER_VISIT, {
    fetchPolicy: 'network-only',
    refetchQueries: [
      {
        query: GET_PLACEHOLDER_VISITS_BY_ROTA,
        variables: {
          query: { ...input },
        },
      },
    ],
  });

  return {
    removePlaceholderVisit,
    removePlaceholderVisitLoading,
    removePlaceHolderVisitError,
  };
};
