import { AdjustmentsHorizontalIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { SelectType } from 'components/Filters';

interface FilterLabelButtonsProps {
  filters: SelectType[];
  setShowFiltersModal: (status: boolean) => void;
  onFilterChange: (name: string, value: { value: string; label: string }) => void;
}

const FilterLabelButtons = ({ filters, setShowFiltersModal, onFilterChange }: FilterLabelButtonsProps) => {
  const selectedFilters = filters.filter((f) => f.selectedValue.value !== 'all');

  return (
    <div className="flex flex-col md:flex-row items-center gap-3 w-full md:w-fit">
      <button
        data-cy="show-filters-button"
        type="button"
        className={`relative border ${
          selectedFilters.length > 0 ? 'border-gray-300' : 'border-gray-900'
        }  text-gray-700 text-md leading-md flex items-center px-4 py-2.5 rounded-lg shadow-xs justify-center w-full md:w-fit`}
        onClick={() => setShowFiltersModal(true)}
      >
        <AdjustmentsHorizontalIcon className="w-5 h-5 mr-2" />
        Filters
        {selectedFilters.length > 0 && (
          <span className="absolute bg-gray-900 h-5 w-5 text-white rounded-full -top-2 -right-2 flex items-center justify-center text-xs font-semibold">
            {selectedFilters.length}
          </span>
        )}
      </button>
      {selectedFilters.map((filter: SelectType) => {
        return (
          <div
            key={filter.name}
            className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center"
          >
            {filter.selectedValue?.label}
            <button
              type="button"
              aria-label={`remove ${filter.name} filter`}
              className="text-gray-500"
              onClick={() => {
                onFilterChange(filter.name, { value: 'all', label: 'All' });
              }}
            >
              <XMarkIcon className="w-4 h-4 ml-2" />
            </button>
          </div>
        );
      })}
    </div>
  );
};

export { FilterLabelButtons };
