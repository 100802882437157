import { useState } from 'react';
import { Add } from '@mui/icons-material';
import { format } from 'date-fns';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { TrashIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { UserContextProps, useUser } from 'context/userContext';
import { Maybe, Document } from '__generated__/graphql';
import { Table } from 'components';
import UploadDocument from './UploadDocument';

interface DocumentManagementProps {
  documents: Maybe<Maybe<Document>[]> | undefined;
  ownerId: string | undefined;
  teamId: string | undefined;
  ownerType: string;
  onDeleteDocument: (id: string, ownerId: string) => void;
}

const DocumentManagement = ({ documents, ownerId, teamId, ownerType, onDeleteDocument }: DocumentManagementProps) => {
  const { user } = useUser() as UserContextProps;
  const [showUploadModal, setShowUploadModal] = useState(false);
  const columnHelper = createColumnHelper<Document>();

  const onDelete = (row: Document) => {
    if (row && row?.id && row?.ownerId) {
      onDeleteDocument(row.id, row.ownerId);
    }
  };

  const RowActions = ({ row }: { row: Document }) => {
    return (
      <div className="flex flex-col md:flex-row md:items-center justify-end gap-6">
        {user?.permissions?.admin && (
          <div data-cy="list-url-link">
            <a href={row.url || ''} aria-label="Download">
              <ArrowDownTrayIcon className="w-6 h-6 text-gray-500 hover:text-primary600" />
            </a>
          </div>
        )}
        <div data-cy="list-delete-link">
          <button type="button" onClick={() => onDelete(row)} aria-label="delete">
            <TrashIcon className="w-6 h-6 text-gray-500 hover:text-primary600" />
          </button>
        </div>
      </div>
    );
  };

  const columns1 = [
    columnHelper.accessor('title', {
      header: () => 'Title',
      cell: (info) => <div data-cy="list-title">{info.renderValue()}</div>,
      footer: (info) => info.column.id,
      enableSorting: true,
    }),
    columnHelper.accessor('type', {
      header: () => 'Type',
      cell: (info) => <div data-cy="list-type">{info.renderValue()}</div>,
      footer: (info) => info.column.id,
      enableSorting: true,
    }),
    columnHelper.accessor('dateCreated', {
      header: () => 'Date created',
      cell: (info) => <div data-cy="list-dateCreated">{format(info.renderValue() ?? 0, 'dd/MM/yyyy')}</div>,
      enableSorting: true,
      footer: (info) => info.column.id,
    }),
    columnHelper.display({
      id: 'actions',
      header: () => '',
      cell: (props) => <RowActions row={props.row.original} />,
    }),
  ] as Array<ColumnDef<Maybe<Document>, unknown>>;

  return (
    <div className="mt-5">
      {showUploadModal && <UploadDocument ownerId={ownerId} teamId={teamId} ownerType={ownerType} onClose={() => setShowUploadModal(false)} />}
      <button
        type="button"
        onClick={() => setShowUploadModal(true)}
        className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center gap-2 mb-5"
        data-cy="upload-document-button"
      >
        <Add className="!w-5 !h-5" />
        Upload Document
      </button>
      <div className="mt-8">{documents && documents.length > 0 && <Table<Maybe<Document>> data={documents} columns={columns1} />}</div>
    </div>
  );
};

export default DocumentManagement;
