import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const Footer = () => {
  const [isDisabled, disableScroll] = useState('none');

  useEffect(() => {
    setTimeout(() => {
      disableScroll('');
    }, 500);
  }, [isDisabled]);

  return (
    <Box sx={{ height: 50, transform: 'translateZ(0px)', flexGrow: 1, display: isDisabled }}>
      <Tooltip style={{ flex: 1 }} title="Scroll to top" onClick={() => window.scroll(0, 0)}>
        <Fab color="primary" sx={{ position: 'absolute', bottom: 20, right: { xs: '8%', sm: '6%', md: '10%', lg: '6%' }, width: '3em', height: '3em' }}>
          <KeyboardArrowUpIcon />
        </Fab>
      </Tooltip>
    </Box>
  );
};

export default Footer;
