import React, { ReactNode } from 'react';
import Loading from 'components/Loading';

interface TWModalProps {
  title: string;
  children: ReactNode;
  buttonText?: string;
  onClose: () => void;
  onSave?: () => void;
  testId?: string;
  wFull?: boolean;
  buttonType?: 'submit' | 'button' | 'reset' | undefined;
  loading?: boolean;
}

const TWModal = ({ title, children, buttonText = 'Save', onClose, onSave, testId, wFull = false, buttonType = 'submit', loading = false }: TWModalProps) => {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-[1200] outline-none focus:outline-none">
        <div className={`relative ${wFull ? 'sm:w-[90%]' : 'w-auto mx-auto max-w-5xl'}`}>
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6 max-h-screen md:max-h-[90vh] md:min-w-[480px]">
            <div className="text-gray-900 text-xl leading-xl font-semibold mb-5">{title}</div>
            <div className="overflow-y-auto">{children}</div>
            <div className="flex justify-between items-center mt-5">
              <button data-cy="cancel-button" type="button" className="text-gray-500 font-semibold text-md leading-md" onClick={onClose}>
                Cancel
              </button>
              {loading ? (
                <Loading isComponent />
              ) : (
                onSave !== undefined && (
                  <button
                    data-cy={testId}
                    // eslint-disable-next-line react/button-has-type
                    type={buttonType}
                    className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
                    onClick={onSave}
                  >
                    {buttonText}
                  </button>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-gray-900" />
    </>
  );
};

export default TWModal;
