import { Outlet } from 'react-router-dom';
import { UserContextProps, useUser } from 'context/userContext';
import { Suspense } from 'react';
import Header from './Header';

function Layout() {
  const { user, userLoading, signout } = useUser() as UserContextProps;

  return (
    <div>
      <Suspense fallback={null}>
        <Header title="BelleVie Care Team Hub" user={user} userLoading={userLoading} signout={signout} />
        <Outlet />
      </Suspense>
    </div>
  );
}

export default Layout;
