import { ReactNode } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { TrashIcon } from '@heroicons/react/24/solid';

interface ModalProps {
  onConfirm?: () => void;
  open: boolean;
  onClose: () => void;
  onDelete?: () => void;
  title?: string;
  confirmLabel?: string;
  isDisabled: boolean;
  children: ReactNode;
}

function Modal({ onConfirm, open, onClose, title, onDelete, isDisabled, confirmLabel = 'Save', children }: ModalProps) {
  return (
    <div className="relative z-10 max-h-full" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      {open && <div className=" fixed inset-0 bg-gray-300 bg-opacity-75 transition-opacity" />}
      <dialog
        open={open}
        className=" overflow-y-auto overflow-x-hidden fixed  h-full md:h-fit sm:top-1/4 top-0 left-0 right-0	z-50 justify-center items-center "
      >
        <div className="relative p-4 w-full sm:h-full  z-51 border-gray-500 bg-opacity-75 transition-opacity">
          <div className={`flex items-center  p-4 md:p-5 ${title ? 'border-b rounded-t dark:border-gray-600' : ''}`}>
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              {title && <h3 className="text-lg font-semibold text-gray-900 dark:text-white">{title}</h3>}
            </div>
            <div>
              {onDelete && (
                <button className="m-2 absolute top-3 end-12" type="button" onClick={() => onDelete()} aria-label="Delete">
                  <TrashIcon className="w-6 h-6 text-error-500 hover:text-gray-500" />
                </button>
              )}
              <button className="m-2 absolute top-3 end-3.5" type="button" onClick={() => onClose()} aria-label="close">
                <XMarkIcon className="w-6 h-6 text-gray-500" />
              </button>
            </div>
          </div>
          <div className="relative break-words p-4 w-full max-w-2xl  max-h-full overflow-x-hidden">{children}</div>
          <div className={`flex items-center justify-evenly p-4 md:p-5 ${title ? 'border-t border-gray-200 rounded-b dark:border-gray-600' : ''}`}>
            <button
              type="submit"
              data-testid="modal-save-button"
              onClick={onConfirm}
              disabled={isDisabled}
              // eslint-disable-next-line max-len
              className="text-white w-30 bg-primary-500 disabled:shadow-none disabled:border-primary-200 disabled:bg-primary-200 disabled:text-white   rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
            >
              {confirmLabel}
            </button>

            <button
              type="button"
              data-testid="modal-close-button"
              onClick={onClose}
              className=" bg-error-600 text-white w-30 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
            >
              Cancel
            </button>
          </div>
        </div>
      </dialog>
    </div>
  );
}

export { Modal };
