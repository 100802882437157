export const currentStatuses = [
  { value: 'all', label: 'All' },
  { value: 'Applied', label: 'Applied' },
  { value: 'ContactedForInitialCall', label: 'Contacted For Initial Call' },
  { value: 'InitialCallArranged', label: 'Initial Call Arranged' },
  { value: 'InitialCallDone', label: 'IC Done Waiting For Workshop' },
  { value: 'WorkshopBooked', label: 'Workshop Booked' },
  { value: 'WorkshopAttended', label: 'Workshop Attended' },
  { value: 'AwaitingShadowing', label: 'Awaiting Shadowing' },
  { value: 'SuccessfulShadowing', label: 'Successful Shadowing' },
  { value: 'ShadowingAgreed', label: 'Shadowing Agreed' },
  { value: 'FullAppFormSubmitted', label: 'Full App Form Submitted' },
  { value: 'OnHold', label: 'OnHold' },
  { value: 'JobOfferAccepted', label: 'Job Offer Accepted' },
  { value: 'DidNotMeetEligibilityCriteria', label: 'Did Not Meet Eligibility Criteria' },
  { value: 'DidNotAnswer', label: 'Did Not Answer' },
  { value: 'DidNotShowUp', label: 'Did Not ShowUp' },
  { value: 'UnsuccessfulAfterWorkshop', label: 'Unsuccessful After Workshop' },
  { value: 'UnsuccessfulAfterShadowing', label: 'Unsuccessful After Shadowing' },
  { value: 'WithdrawnApplication', label: 'Withdrawn Application' },
];
