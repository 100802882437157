import { useState, useEffect } from 'react';
import { SupportWorker, Maybe, InputMaybe, BuddieGroupUpdate } from '__generated__/graphql';
import TWModal from 'components/TWModal';
import SupportWorkerSelect from 'components/SupportWorkerSelect';

interface AssignBuddieProps {
  selectedBuddies: string[];
  onClose: () => void;
  groupId: Maybe<string> | undefined;
  onSaveBuddieGroup: (data?: InputMaybe<BuddieGroupUpdate>) => void;
  modalBuddies: SupportWorker[];
  selectedTeam: string;
}

export default function AssignBuddie({ selectedBuddies, onClose, onSaveBuddieGroup, modalBuddies, selectedTeam, groupId }: AssignBuddieProps) {
  const [buddies, setBuddies] = useState<string[]>(modalBuddies.map((sw) => sw.id ?? '') ?? []);

  useEffect(() => {
    if (selectedBuddies) {
      setBuddies(selectedBuddies);
    }
  }, [selectedBuddies]);

  const onUpdateBuddie = () => {
    onSaveBuddieGroup({ teamId: selectedTeam, groupId: groupId || undefined, buddies });
    onClose();
  };

  const onClickBuddie = (id: string) => {
    if (buddies.includes(id)) {
      const workers = buddies?.filter((lw) => lw !== id);
      setBuddies(workers);
    } else {
      setBuddies([...buddies, id]);
    }
  };

  return (
    <TWModal title="Assign Buddies" onClose={onClose} onSave={onUpdateBuddie}>
      <SupportWorkerSelect selectedSupportWorkers={buddies} teamId={selectedTeam} onClick={onClickBuddie} isSelectMultiple showContractedHours />
    </TWModal>
  );
}
