import { SupportWorker } from '__generated__/graphql';
import Avatar from 'components/Avatar';
import { ImageSizeType } from 'types/types';

interface SupportWorkerGridProps {
  supportWorkers: SupportWorker[];
  gridType: string;
}

const SupportWorkerGrid = ({ supportWorkers, gridType }: SupportWorkerGridProps) => {
  return (
    <div className="grid xl:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-2">
      {supportWorkers.map(({ id, fullName, avatar }: SupportWorker) => {
        return <Avatar testId={`supportWorker-${id}-${gridType}`} alt={fullName} label={fullName} avatar={avatar} size={ImageSizeType.LG} />;
      })}
    </div>
  );
};

export default SupportWorkerGrid;
