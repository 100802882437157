import TWModal from 'components/TWModal';
import { DateField } from 'components/Fields/DateField';
import { SelectField } from 'components/Fields/SelectField';
import { TextAreaField } from 'components/Fields/TextAreaField';
import { v4 as uuidv4 } from 'uuid';
import { FormProvider, useForm } from 'react-hook-form';
import { CustomerReview, Maybe } from '../../../../__generated__/graphql';

interface EditWellbeingReviewProps {
  review: Maybe<CustomerReview> | undefined;
  onClose: () => void;
  onEditReviews: (reviews: CustomerReview) => void;
}

const EditWellbeingReview = ({ review, onClose, onEditReviews }: EditWellbeingReviewProps) => {
  const methods = useForm<{
    id: string;
    date: number;
    requestedBy: string;
    note: string;
  }>({
    mode: 'onSubmit',
    defaultValues: {
      id: review?.id ? review?.id : uuidv4(),
      date: review?.date ? review?.date : new Date().getTime(),
      requestedBy: review?.requestedBy ? review?.requestedBy : 'Bellevie',
      note: review?.note ? review?.note : '',
    },
  });

  const { handleSubmit } = methods;

  const onSubmit = (data: CustomerReview) => {
    onEditReviews(data);
  };

  return (
    <FormProvider {...methods}>
      <form>
        <TWModal title={review ? 'Edit review' : 'Add review'} onClose={onClose} onSave={handleSubmit(onSubmit)} wFull testId="save-review-button">
          <DateField isRequired={true} label="Date" name="date" />
          <SelectField
            options={[
              { label: 'Bellevie', value: 'Bellevie' },
              { label: 'Family member', value: 'Family member' },
              { label: 'Social Worker', value: 'Social Worker' },
              { label: 'Other', value: 'Other' },
            ]}
            label="Requested By"
            name="requestedBy"
            isRequired={true}
          />
          <TextAreaField label="Note" name="note" placeholder="" />
        </TWModal>
      </form>
    </FormProvider>
  );
};

export default EditWellbeingReview;
