import { useGetTeamOptions } from 'api/hooks/useGetTeams';
import { SelectField } from './SelectField';

interface TeamSelectFieldProps {
  label: string;
  name: string;
  disabled?: boolean;
  testId?: string;
  searchable?: boolean;
  showAll?: boolean;
}

const TeamSelectField = ({ label, name, disabled = false, searchable, testId, showAll = false }: TeamSelectFieldProps) => {
  const { teams } = useGetTeamOptions(null, showAll);
  const teamOptions = teams.map((s, index) => {
    return { label: s.teamName, value: s.teamId, id: index.toString() };
  });

  return <SelectField isRequired={true} searchable={searchable} testId={testId} label={label} name={name} options={teamOptions} disabled={disabled} />;
};

export { TeamSelectField };
