import { Maybe, CandidateHistory } from '__generated__/graphql';
import { format } from 'date-fns';
import { TrashIcon } from '@heroicons/react/24/solid';

interface CandidateHistoryListProps {
  history: Maybe<Array<Maybe<CandidateHistory>>>;
  onCandidateRemove: (index: number) => void;
}

export default function CandidateHistoryList({ history, onCandidateRemove }: CandidateHistoryListProps) {
  return (
    <div className="flex flex-col gap-3">
      {history?.map((h, index) => {
        const isHTML = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/.test(h?.message ?? '');
        return (
          <div className="bg-primary-100 rounded-lg p-6 flex flex-col sm:flex-row justify-between gap-4">
            <div className="flex flex-col justify-between self-stretch gap-4">
              <div className="text-gray-700 text-sm leading-sm font-semibold">{format(h?.date ?? 0, 'dd/MM/yyyy')}</div>
              {isHTML ? (
                // eslint-disable-next-line react/no-danger
                <div className="text-gray-700 text-md leading-md" dangerouslySetInnerHTML={{ __html: h?.message || '' }} />
              ) : (
                <div className="text-gray-700 text-md leading-md">{h?.message}</div>
              )}
              <div className="text-gray-700 text-md leading-md">{h?.from}</div>
            </div>
            <div className="flex flex-row-reverse sm:flex-col justify-between self-stretch items-end">
              <button type="button" aria-label="delete" onClick={() => onCandidateRemove(index)}>
                <TrashIcon className="text-gray-500 w-6 h-6" />
              </button>
              <div className="rounded-2xl border border-gray-300 bg-primary-50 py px-2.5 text-primary-700 text-sm leading-sm font-medium">{h?.contactType}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
