import { useNavigate } from 'react-router-dom';
import { Rota, Team } from '__generated__/graphql';
import { formatTime } from 'services/helpers';

interface RotaListProps {
  rotas: Rota[];
  teams: Team[];
}

const RotaList = ({ rotas, teams }: RotaListProps) => {
  const navigate = useNavigate();
  const unique = [...new Set(rotas.map((r) => r?.id))] as string[];

  const onSelectRota = (rota?: Rota) => {
    if (rota && rota.startRotaDateTime && rota.endRotaDateTime && rota.id && rota.status === 'Complete') {
      localStorage.setItem('rotaId', rota.id);
      localStorage.setItem('startRotaDateTime', rota.startRotaDateTime.toString());
      localStorage.setItem('endRotaDateTime', rota.endRotaDateTime.toString());
      navigate('/team-shifts');
    }
  };

  return (
    <div className="flex flex-col lg:flex-row lg:items-center gap-2">
      {unique.map((r: string, index: number) => {
        const rota = rotas.find((x) => x.id === r);
        const team = teams.find((x) => x.teamId === rota?.teamId);
        return (
          <button
            type="button"
            onClick={() => onSelectRota(rota)}
            key={index}
            className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md mt-0.5 disabled:opacity-40"
          >
            {team?.teamName} - {formatTime(rota?.startRotaDateTime || 0, 'PPP')} - {formatTime(rota?.endRotaDateTime || 0, 'PPP')}
          </button>
        );
      })}
    </div>
  );
};

export default RotaList;
