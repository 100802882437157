import { CheckBadgeIcon, ClockIcon } from '@heroicons/react/24/outline';
import { camelCaseToNormalString } from 'services/strings';
import { WorkshopStatus } from '../types';

const Status = ({ status, small }: { status: WorkshopStatus; small?: boolean }) => {
  switch (status) {
    case WorkshopStatus.Complete:
      return (
        <div
          data-cy="list-status-complete"
          className={`bg-success-50 border border-success-700 rounded-full md:rounded-2xl text-success-700 text-sm leading-sm font-medium w-fit flex items-center ${
            small ? 'p-1' : 'px-2.5 py-0.5'
          }`}
        >
          <CheckBadgeIcon className="w-5 h-5 text-success-500" />
          {!small && <div className="ml-2">{camelCaseToNormalString(status)}</div>}
        </div>
      );
    case WorkshopStatus.Scheduled:
      return (
        <div
          data-cy="list-status-scheduled"
          className={`bg-blue-50 border border-gray-300 rounded-full md:rounded-2xl text-blue-700 text-sm leading-sm font-medium w-fit flex items-center ${
            small ? 'p-1' : 'px-2.5 py-0.5'
          }`}
        >
          <ClockIcon className="w-5 h-5 text-blue-500" />
          {!small && <div className="ml-2">{camelCaseToNormalString(status)}</div>}
        </div>
      );
    default:
      return (
        <div
          data-cy="list-status-unknown"
          className="bg-gray-50 border border-gray-700 rounded-2xl text-gray-700 text-sm leading-sm font-medium px-2.5 py-0.5 w-fit"
        >
          Unknown
        </div>
      );
  }
};

export default Status;
