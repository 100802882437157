import { gql, useQuery, useMutation } from '@apollo/client';
import { SurveyReportSearch, CandidateReportList, CandidateReport } from '__generated__/graphql';

export const GET_SURVEY_REPORTS = gql`
  query GetSurveyReports {
    GetSurveyReports {
      id
      candidateName
      dateUpdated
      score
      supportWorkerId
      email
      candidateId
    }
  }
`;

export const GET_SURVEY_REPORT = gql`
  query GetSurveyReport($query: SurveyReportSearch) {
    GetSurveyReport(query: $query) {
      data
    }
  }
`;

export const ASSIGN_SUPPORT_WORKER_SURVEY_REPORT = gql`
  mutation AssignSupportWorkerSurveyReport($input: SupportWorkerSurveyReportInput) {
    AssignSupportWorkerSurveyReport(input: $input) {
      id
      message
      date
    }
  }
`;

interface GetSurveyReportsResponse {
  GetSurveyReports: CandidateReportList[];
}

interface GetSurveyReportResponse {
  GetSurveyReport: CandidateReport;
}

export const useGetSurveyReports = () => {
  const { data, refetch, loading } = useQuery<GetSurveyReportsResponse>(GET_SURVEY_REPORTS);

  return { surveyReports: data?.GetSurveyReports, refetch, loading };
};

export const useGetSurveyReport = (query: SurveyReportSearch) => {
  const { data, loading } = useQuery<GetSurveyReportResponse>(GET_SURVEY_REPORT, {
    variables: {
      query: {
        ...query,
      },
    },
  });
  return { loading, surveyReport: data?.GetSurveyReport?.data ? JSON.parse(data?.GetSurveyReport?.data) : null };
};
export const useAssignSupportWorkerSurveyReport = () => {
  const [assignSupportWorkerSurveyReport] = useMutation(ASSIGN_SUPPORT_WORKER_SURVEY_REPORT);
  return { assignSupportWorkerSurveyReport };
};
