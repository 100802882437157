import { Controller, useFormContext } from 'react-hook-form';
import FormLabel from '../FormLabel';

interface CheckboxFieldProps {
  label: string;
  name: string;
  disabled?: boolean;
  testId?: string;
}
const CheckboxField = ({ label, name, disabled = false, testId }: CheckboxFieldProps) => {
  const { control, register } = useFormContext(); // retrieve all hook methods

  return (
    <FormLabel label={label}>
      <div className="w-full lg:w-1/2">
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <>
                <input
                  ref={register(name).ref}
                  data-cy={testId}
                  disabled={disabled}
                  onChange={(event) => (onChange ? onChange(event.target.checked) : null)}
                  checked={value}
                  id="checked-checkbox"
                  type="checkbox"
                  // eslint-disable-next-line max-len
                  className="w-4 h-4 text-primary-300 bg-gray-100 border-gray-300 rounded "
                />

                <p className="mt-2 text-sm text-error-600 dark:text-green-500">
                  <span className="font-medium">{error?.message}</span>
                </p>
              </>
            );
          }}
        />
      </div>
    </FormLabel>
  );
};

export { CheckboxField };
