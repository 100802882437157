import { RadioGroupField } from 'components/Fields/RadioGroupField';

export default function CandidateScorecard() {
  return (
    <div className="mt-8 sm:mt-12">
      <RadioGroupField name="scorecard" />
      <div className="flex justify-end">
        <button type="submit" className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
          Save
        </button>
      </div>
    </div>
  );
}
