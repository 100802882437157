import { BookOpenIcon, CheckIcon, ChevronLeftIcon, ChevronRightIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { CourseReport } from '__generated__/graphql';
import { format, isAfter } from 'date-fns';
import { useState } from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

interface YourTrainingProps {
  courseReports: CourseReport[];
  testId: string;
}

const YourTraining = ({ courseReports, testId }: YourTrainingProps) => {
  const [pagination, setPagination] = useState(1);
  const [courseReportsIndex, setCourseReportsIndex] = useState(0);
  const learningCloudUrl = process.env.REACT_APP_LEARNING_CLOUD_AUTH_URL;
  const nbPages = Math.ceil(courseReports.length / 5);

  const percentageCompleted = (courseReports.filter((cR) => cR.dateCompleted).length / courseReports.length) * 100;

  return (
    <div className="bg-background2 border border-black border-opacity-[0.13] p-6 rounded-3xl xl:col-span-3" data-cy={testId}>
      <div className="flex flex-row items-center justify-between mb-4">
        <div className="flex flex-row items-center gap-3">
          <div className="border border-black border-opacity-20 w-10 h-10 rounded-full flex items-center justify-center">
            <BookOpenIcon className="!w-5 !h-5 text-content-secondary" />
          </div>
          <div className="text-xl leading-xl font-semibold text-black">Your Training</div>
        </div>
      </div>
      <div className="flex flex-col xl:flex-row-reverse items-center gap-4">
        <div className="bg-backgroundl1 rounded-2xl px-4 py-8 self-stretch xl:w-1/3">
          <div className="w-2/3 mx-auto">
            <CircularProgressbar
              value={percentageCompleted}
              styles={buildStyles({
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'round',
                // How long animation takes to go from one percentage to another, in seconds
                pathTransitionDuration: 0.5,
                trailColor: '#B6C7CE',
                pathColor: '#669F2A',
              })}
            />
          </div>
          <div className="flex flex-row items-center justify-center gap-4 mt-4">
            <div className="flex flex-row items-center justify-center gap-2">
              <div className="w-2 h-2 rounded-full bg-backgroundNotice" />
              <div className="text-sm leading-sm text-content-gray">Due</div>
            </div>
            <div className="flex flex-row items-center justify-center gap-2">
              <div className="w-2 h-2 rounded-full bg-contentPositive" />
              <div className="text-sm leading-sm text-content-gray">Completed</div>
            </div>
          </div>
        </div>
        <div className="bg-background3 px-4 rounded-2xl flex flex-col divide-y divide-gray-200 self-stretch xl:w-2/3">
          {courseReports.slice(courseReportsIndex, courseReportsIndex + 5).map((cR) => {
            const isOverdue = isAfter(Date.now(), (cR.dateExpiry || 0) * 1000);
            return (
              <a href={learningCloudUrl} target="__blank" className="flex flex-row items-center justify-between gap-4 py-4">
                <div>
                  <div className="text-gray-900 font-semibold text-md leading-md">{cR.courseName}</div>
                  {cR.dateCompleted ? (
                    <div className="text-gray-700 text-sm leading-sm">Completed {format(cR.dateCompleted * 1000, 'dd/MM/yy')}</div>
                  ) : (
                    <div className="text-gray-700 text-sm leading-sm flex flex-row gap-2 items-center">
                      {isOverdue ? 'Overdue since' : 'Due'} {format((cR.dateExpiry || 0) * 1000, 'dd/MM/yy')}{' '}
                      {isOverdue && (
                        <div className="bg-content-warning rounded-full w-6 h-6 flex justify-center items-center sm:hidden shrink-0">
                          <ExclamationCircleIcon className="!w-4 !h-4 text-white" />
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="flex flex-row items-center gap-4">
                  {cR.dateCompleted && (
                    <div className="bg-contentPositive rounded-full w-6 h-6 flex justify-center items-center">
                      <CheckIcon className="!w-4 !h-4 text-white" />
                    </div>
                  )}
                  {isOverdue && !cR.dateCompleted && (
                    <div className="bg-content-warning rounded-full w-6 h-6 sm:flex justify-center items-center hidden">
                      <ExclamationCircleIcon className="!w-4 !h-4 text-white" />
                    </div>
                  )}
                  {!cR.dateCompleted && (
                    <div className="flex flex-row items-center gap-3 p-3 bg-contentBrand text-white rounded-[40px] text-md leading-md font-semibold">
                      <span className="hidden sm:block">Start</span> <ChevronRightIcon className="w-5 h-5" />
                    </div>
                  )}
                </div>
              </a>
            );
          })}
        </div>
      </div>
      <div className="flex flex-row items-center justify-between mt-5">
        <div className="text-content-gray text-md leading-md font-medium">
          Page {pagination} of {nbPages}
        </div>
        <div className="flex flex-row items-center gap-4">
          <button
            type="button"
            aria-label="previous team page"
            onClick={() => {
              if (pagination > 1) {
                setPagination(pagination - 1);
                setCourseReportsIndex(courseReportsIndex - 5);
              }
            }}
            className="w-9 h-9 rounded-lg border border-black border-opacity-20 shadow-sm p-2 flex items-center justify-center"
          >
            <ChevronLeftIcon className="text-content-gray w-5 h-5" />
          </button>
          <button
            type="button"
            aria-label="next team page"
            onClick={() => {
              if (pagination < nbPages) {
                setPagination(pagination + 1);
                setCourseReportsIndex(courseReportsIndex + 5);
              }
            }}
            className="w-9 h-9 rounded-lg border border-black border-opacity-20 shadow-sm p-2 flex items-center justify-center"
          >
            <ChevronRightIcon className="text-content-gray w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default YourTraining;
