import { useCallback, useEffect, useMemo, useState } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { Customer, SupportWorker, Maybe } from '__generated__/graphql';
import Loading from '../Loading';
import { MapMarker } from './MapMarker';

interface MapRequiredProps {
  customers: (Maybe<Customer> | undefined)[];
  workers: (Maybe<SupportWorker> | undefined)[];
  showCustomers: boolean;
  showSupportWorkers: boolean;
  zoom: number;
}

interface MapOptionalProps {
  centreOnCustomer?: boolean;
  showPostCodeMarker?: boolean;
  postCodeLat?: number;
  postCodeLng?: number;
  postCode?: string;
}

interface MapProps extends MapRequiredProps, MapOptionalProps {}

const center = {
  lat: 54.42,
  lng: -2.169,
};

const Map = ({
  customers,
  workers,
  showCustomers,
  showSupportWorkers,
  centreOnCustomer = false,
  zoom,
  showPostCodeMarker = false,
  postCodeLat = 0,
  postCodeLng = 0,
  postCode = '',
}: MapProps) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  });

  const [map, setMap] = useState<google.maps.Map | null>(null);

  const onLoad = useCallback((newMap: google.maps.Map) => {
    setMap(newMap);
  }, []);

  const markers: { lat: number; lng: number; label: string; background: string; zIndex?: number }[] = useMemo(() => {
    const shownMarkers: { lat: number; lng: number; label: string; background: string; zIndex?: number }[] = [];
    if (showCustomers) {
      const customerMarkers = customers
        .filter((c: Maybe<Customer> | undefined) => c?.lat && c?.lat > 0 && c?.lng)
        .map((m) => {
          return {
            lat: m?.lat || 0,
            lng: m?.lng || 0,
            label: `${m?.firstName || ''} ${m?.lastName || ''} - ${m?.postcode || ''}`,
            zIndex: 1001,
            background: 'yellow',
          };
        });
      shownMarkers.push(...customerMarkers);
    }
    if (showSupportWorkers) {
      const workerMarkers = workers
        .filter((w: Maybe<SupportWorker> | undefined) => w?.lat && w?.lat > 0 && w?.lng)
        .map((m) => {
          return { lat: m?.lat || 0, lng: m?.lng || 0, label: `${m?.fullName || ''} - ${m?.postcode || ''}`, zIndex: 1000, background: 'Fuchsia' };
        });
      shownMarkers.push(...workerMarkers);
    }
    if (showPostCodeMarker) {
      shownMarkers.push({ lat: postCodeLat, lng: postCodeLng, label: `${postCode || ''}`, background: 'yellow' });
    }
    return shownMarkers;
  }, [postCodeLat, customers, workers, postCodeLng, showCustomers, postCode, showPostCodeMarker, showSupportWorkers]);

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();

      if (markers.length > 0 || centreOnCustomer) {
        markers.forEach((c) => {
          bounds.extend(new window.google.maps.LatLng(c?.lat, c.lng));
        });
        map?.fitBounds(bounds);
        map?.setCenter({ lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng() });
        map.panTo({ lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng() });
      }
    }
  }, [centreOnCustomer, map, markers]);

  if (!isLoaded) {
    return <Loading />;
  }

  return (
    <GoogleMap
      options={{
        scaleControl: true,
      }}
      mapContainerStyle={{
        width: '100%',
        height: '30em',
      }}
      center={center}
      zoom={zoom}
      onLoad={onLoad}
    >
      {markers.length > 0 &&
        markers.map(({ lat, lng, background, label, zIndex }, index) => (
          <MapMarker key={index} index={index} lat={lat} lng={lng} background={background} zIndex={zIndex} color="black" markerLabel={label} />
        ))}
    </GoogleMap>
  );
};

export default Map;
