import { useState } from 'react';
import { format } from 'date-fns';
import Helmet from 'react-helmet';
import { LocationBar, Loading, Message, Table, ToggleButtons, Search } from 'components';
import { useGetTemplates, useSaveTemplate, useRemoveTemplate } from 'api/hooks/useTemplates';
import ArticleIcon from '@mui/icons-material/Article';
import { Template } from '__generated__/graphql';
import { PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { SizeType } from 'types/types';
import RemoveTemplate from './RemoveTemplate';
import SaveTemplate from './SaveTemplate';

export default function TemplateList() {
  const { templates, templatesLoading } = useGetTemplates();
  const { saveTemplate, mutationSaveTemplate } = useSaveTemplate();
  const { removeTemplate, mutationRemoveTemplate } = useRemoveTemplate();
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const [showRemoveTemplate, setShowRemoveTemplate] = useState<boolean>(false);
  const [showSaveTemplate, setShowSaveTemplate] = useState<boolean>(false);
  const [showAddTemplate, setShowAddTemplate] = useState<boolean>(false);
  const [templateSelected, setTemplate] = useState<Template | null>(null);
  const [templateType, setTemplateType] = useState('All');
  const [search, setSearch] = useState('');
  const columnVisibility = {
    dateUpdated: window.innerWidth > 768,
    name: true,
    subject: window.innerWidth > 640,
    type: true,
  };

  if (templatesLoading || mutationSaveTemplate.loading || mutationRemoveTemplate.loading) return <Loading />;

  const onSaveTemplate = (t: Template) => {
    setOpenSnack(true);
    saveTemplate({ variables: { input: { ...t } } });
    setTemplate(null);
    setShowRemoveTemplate(false);
    setShowSaveTemplate(false);
    setShowAddTemplate(false);
    setTimeout(() => setOpenSnack(false), 2000);
  };

  const deleteTemplate = (t: Template) => {
    if (t) {
      setTemplate(t);
      setShowRemoveTemplate(true);
    }
  };

  const editTemplate = (t: Template) => {
    if (t) {
      setTemplate(t);
      setShowSaveTemplate(true);
    }
  };

  const onAddTemplate = () => {
    setShowAddTemplate(true);
  };

  const onConfirmRemoveTemplate = (id: string) => {
    setOpenSnack(true);
    removeTemplate({ variables: { input: { id } } });
    setTemplate(null);
    setShowRemoveTemplate(false);
    setShowSaveTemplate(false);
    setShowAddTemplate(false);
    setTimeout(() => setOpenSnack(false), 2000);
  };

  const onCancel = () => {
    setTemplate(null);
    setShowRemoveTemplate(false);
    setOpenSnack(false);
    setShowSaveTemplate(false);
    setShowAddTemplate(false);
  };

  const RowActions = ({ row }: { row: Template }) => {
    return (
      <div className="flex items-center gap-3">
        <button type="button" onClick={() => editTemplate(row)} aria-label="Edit">
          <PencilIcon className="w-6 h-6 text-gray-500 hover:text-primary-600" />
        </button>
        <button type="button" onClick={() => deleteTemplate(row)} aria-label="Delete">
          <TrashIcon className="w-6 h-6 text-gray-500 hover:text-primary-600" />
        </button>
      </div>
    );
  };

  const columnHelper = createColumnHelper<Template>();

  const columns1 = [
    columnHelper.accessor('name', {
      header: () => 'Name',
      cell: (info) => {
        return <div data-cy="list-name">{info.renderValue()}</div>;
      },
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.accessor('subject', {
      header: () => 'Subject',
      cell: (info) => <div data-cy="list-subject">{info.renderValue()}</div>,
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.accessor('type', {
      header: () => 'Type',
      cell: (info) => <div data-cy="list-type">{info.renderValue()}</div>,
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.accessor('dateUpdated', {
      header: () => 'Date updated',
      cell: (info) => <div data-cy="list-dateUpdated">{format(info.renderValue() ?? 0, 'dd/MM/yyyy')}</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (props) => <RowActions row={props.row.original} />,
    }),
  ] as Array<ColumnDef<Template, unknown>>;

  const changeFilter = (f: string) => {
    setTemplateType(f);
  };

  const filterType = (t: Template) => {
    if (templateType === 'All') return true;
    if (templateType === 'Email' && t.type === 'Email') return true;
    if (templateType === 'SMS' && t.type === 'SMS') return true;

    return false;
  };

  const filterByName = (t: Template) => {
    if (t?.name?.toLowerCase().includes(search.toLowerCase())) return true;
    return false;
  };

  const rows = templates.filter((t) => filterType(t)).filter((t) => filterByName(t));

  return (
    <>
      <Helmet>
        <title>Templates</title>
      </Helmet>
      <LocationBar section="Templates" page="Template List" Icon={ArticleIcon} />
      <Message response={[mutationSaveTemplate, mutationRemoveTemplate]} openSnack={openSnack} setOpenSnack={setOpenSnack} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex justify-between place-items-center mb-8">
          <div className="text-display-sm md:text-display-lg leading-display-sm md:leading-display-lg font-semibold md:tracking-display-lg text-black">
            Templates
          </div>
          <button
            data-cy="create-template-button"
            type="button"
            className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md hidden md:block"
            onClick={onAddTemplate}
          >
            Add Template
          </button>
          <button
            type="button"
            aria-label="Create workshop"
            className="text-white bg-primary-700 rounded-lg p-2 font-semibold text-md leading-md md:hidden"
            onClick={onAddTemplate}
          >
            <PlusIcon className="w-5 h-5 text-white" />
          </button>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-between mt-8 md:mt-16">
          <div className="flex flex-col md:flex-row items-center gap-4 md:gap-9 w-full md:w-fit">
            <ToggleButtons
              testId="status-filter"
              buttons={['All', 'Email', 'SMS']}
              selectedButton={templateType}
              onSelectButton={changeFilter}
              size={window.innerWidth > 768 ? SizeType.Fit : SizeType.Half}
            />
            <Search value={search} onSearch={setSearch} />
          </div>
        </div>
        <div className="mt-3 md:mt-8 mb-10">
          {rows && rows.length > 0 && <Table<Template> data={rows} columns={columns1} columnVisibility={columnVisibility} />}
        </div>
      </div>
      {showRemoveTemplate && <RemoveTemplate onClose={onCancel} onConfirm={onConfirmRemoveTemplate} id={templateSelected?.id || ''} />}
      {showSaveTemplate && <SaveTemplate template={templateSelected} onUpdate={onSaveTemplate} onClose={onCancel} />}
      {showAddTemplate && <SaveTemplate template={null} onUpdate={onSaveTemplate} onClose={onCancel} />}
    </>
  );
}
