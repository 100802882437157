import { Controller, useFormContext } from 'react-hook-form';
import ReactQuill from 'react-quill';
import FormLabel from '../FormLabel';
import 'react-quill/dist/quill.snow.css';

interface QuillFieldProps {
  label: string;
  name: string;
}

const QuillField = ({ label, name }: QuillFieldProps) => {
  const { control, register } = useFormContext(); // retrieve all hook methods

  const validate = (value: string) => {
    const newString = value.replace(/<[^>]*>?/gm, '');
    if (newString.length === 0) {
      return 'Please fill in field';
    } else {
      return true;
    }
  };
  return (
    <FormLabel label={label}>
      <div className="w-full lg:w-1/2">
        <Controller
          name={name}
          control={control}
          rules={{ validate }}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <>
                <ReactQuill ref={register(name).ref} theme="snow" value={value} onChange={onChange} />
                <p className="mt-2 text-sm text-error-600 dark:text-green-500">
                  <span className="font-medium">{error?.message}</span>
                </p>
              </>
            );
          }}
        />
      </div>
    </FormLabel>
  );
};

export { QuillField };
