import { Absence, SupportWorker } from '__generated__/graphql';
import { addDays, isSameDay, isAfter } from 'date-fns';
import DayCell from './DayCell';

interface HolidaysRunProps {
  startDateTime: number;
  week: number;
  leave: Absence[];
  supportWorkers: SupportWorker[];
}

const HolidaysRun = ({ startDateTime, week, leave, supportWorkers }: HolidaysRunProps) => {
  return (
    <div className="flex w-full">
      <div className="flex flex-col xl:flex-row w-full">
        <div className="hidden xl:block xl:h-auto xl:w-[9%] px-2">
          <div className="flex items-center justify-center h-[70px] xl:h-[38px] text-lg text-gray-800 font-semibold xl:font-medium">Leaves</div>
        </div>
        <div className="flex flex-row xl:hidden">
          <div className="w-1/2 px-2">
            <div className="flex items-center justify-center h-[70px] xl:h-[38px] text-lg text-gray-800 font-semibold xl:font-medium">Date</div>
          </div>
          <div className="w-1/2 px-2">
            <div className="flex items-center justify-center h-[70px] xl:h-[38px] text-lg text-gray-800 font-semibold xl:font-medium">Leaves</div>
          </div>
        </div>
        {[...Array(7)].map((_, index) => {
          const daysToAdd = week === 1 ? index : index + 7;
          const day = addDays(startDateTime ?? 0, daysToAdd);
          const dayLeave = leave.filter((l) => {
            if (
              isSameDay(l.absenceDate ?? 0, day) ||
              isSameDay(l.returnDate ?? 0, day) ||
              (isAfter(day, l.absenceDate ?? 0) && isAfter(l.returnDate ?? 0, day))
            ) {
              return l;
            }
            return null;
          });
          return <DayCell day={day} key={index} leave={dayLeave} supportWorkers={supportWorkers} />;
        })}
      </div>
    </div>
  );
};

export default HolidaysRun;
