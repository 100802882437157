import { BuddieGroup } from '__generated__/graphql';
import AvatarGroup from 'components/AvatarGroup';
import { ImageSizeType } from 'types/types';
import Tooltip from 'components/Tooltip';
import { TrashIcon, UserPlusIcon } from '@heroicons/react/24/solid';

interface BuddieProps {
  buddieGroups: BuddieGroup[];
  selectBuddieGroup: (group: BuddieGroup | null) => void;
  onRemoveBuddieGroup: (groupId: string) => void;
}

export default function Buddies({ buddieGroups, selectBuddieGroup, onRemoveBuddieGroup }: BuddieProps) {
  return (
    <div className="mt-3">
      {(buddieGroups || []).map((group, i) => (
        <div className="py-3 flex justify-between items-center border-b border-gray-50">
          <div className="text-xl leading-xl font-medium text-gray-800">Buddy Group {i + 1}</div>
          <div className="flex items-center gap-4">
            <AvatarGroup
              avatars={group?.buddies?.map((lw) => ({ avatar: lw?.avatar ?? '', alt: lw?.fullName ?? '', tooltip: lw?.fullName ?? '' })) ?? []}
              size={ImageSizeType.LG}
              max={5}
              border
            />
            <Tooltip tooltip="Add buddies" right>
              <button type="button" aria-label="Add buddies" onClick={() => selectBuddieGroup(group)}>
                <UserPlusIcon className="w-7 h-7 text-primary-700" />
              </button>
            </Tooltip>
            <Tooltip tooltip="Remove buddy group" right>
              <button type="button" aria-label="Remove buddy group" onClick={() => onRemoveBuddieGroup(group.groupId ?? '')}>
                <TrashIcon className="w-7 h-7 text-error-700" />
              </button>
            </Tooltip>
          </div>
        </div>
      ))}
      <div className="py-3 flex justify-between items-center border-b border-gray-50">
        <div className="text-xl leading-xl font-medium text-gray-800">New buddy group</div>
        <div className="flex items-center gap-4">
          <Tooltip tooltip="Add buddies" right>
            <button type="button" aria-label="Add buddies" onClick={() => selectBuddieGroup(null)}>
              <UserPlusIcon className="w-7 h-7 text-primary-700" />
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}
