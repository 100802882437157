import { useState } from 'react';
import { useUser, UserContextProps } from 'context/userContext';
import Helmet from 'react-helmet';
import PunchClock from '@mui/icons-material/PunchClock';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetReportingHours,
  useUpdateReportingHour,
  useAddMileageReportingHour,
  useDeleteMileageReportingHour,
  useGetReportingHoursPeriod,
  useSyncReportingHour,
} from 'api/hooks/useReportingHours';
import { User } from 'types/types';
import { useGetCustomers } from 'api/hooks/useCustomers';
import { useGetSupportWorker } from 'api/hooks/useSupportWorkers';
import useGetTeams from 'api/hooks/useGetTeams';
import {
  AddMileageReportingHourInput,
  DeleteMileageReportingHourInput,
  ReportingHours,
  ReportingHoursPeriod,
  Rota,
  UpdateReportingHourInput,
} from '__generated__/graphql';
import { groupByKey } from 'services/arrays';
import { LocationBar, Loading, Message } from 'components';
import { ArrowLeftIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import Button from 'components/Button';
import ShiftHeading from './ShiftHeading';
import ReportingHoursProfile from './ReportingHoursProfile';
// import ShiftFooter from './ShiftFooter';
import ShiftRow from './ShiftRow';
import Mileage from './Mileage';
import { ShiftType, VisitStatus } from './service';

const ManageReportingHours = () => {
  const { user, userLoading } = useUser() as UserContextProps;

  const navigate = useNavigate();
  const { teamId, swId, id } = useParams<{ teamId: string; swId: string; id: string }>();
  const [openSnack, setOpenSnack] = useState<boolean>(false);

  const { reportingHoursPeriod, loading: rhpLoading } = useGetReportingHoursPeriod({ teamId, supportWorkerId: swId });
  const { reportingHours, loading: rhLoading } = useGetReportingHours({ teamId: teamId ?? '', supportWorkerId: swId ?? '', id });
  const { customers, loading: customersLoading } = useGetCustomers({ showAll: true });
  const { supportWorker, loading: swLoading } = useGetSupportWorker({ id: swId });
  const { updateReportingHour, mutationUpdateReportingHour } = useUpdateReportingHour({ teamId, supportWorkerId: swId, id });
  const { addMileageReportingHour, mutationAddMileageReportingHour } = useAddMileageReportingHour({ teamId, supportWorkerId: swId, id });
  const { deleteMileageReportingHour, mutationDeleteMileageReportingHour } = useDeleteMileageReportingHour({ teamId, supportWorkerId: swId, id });
  const { syncReportingHour, mutationSyncReportingHour } = useSyncReportingHour({ teamId, supportWorkerId: swId }, id);
  const { teams, teamsLoading } = useGetTeams();

  if (rhpLoading || rhLoading || customersLoading || userLoading || swLoading || teamsLoading) {
    return <Loading />;
  }

  const onUpdateReportHour = async (input: UpdateReportingHourInput) => {
    await updateReportingHour({
      variables: {
        input: {
          ...input,
        },
      },
    });
    setOpenSnack(true);
  };

  const onAddMileageReportHour = async (input: AddMileageReportingHourInput) => {
    await addMileageReportingHour({
      variables: {
        input: {
          ...input,
        },
      },
    });
    setOpenSnack(true);
  };

  const onDeleteMileageReportHour = async (input: DeleteMileageReportingHourInput) => {
    await deleteMileageReportingHour({
      variables: {
        input: {
          ...input,
        },
      },
    });
    setOpenSnack(true);
  };

  const syncReportingHours = async (rhp: ReportingHoursPeriod) => {
    await syncReportingHour({
      variables: {
        input: {
          supportWorkerId: rhp.supportWorkerId,
          teamId: rhp.teamId,
          period: rhp.id,
        },
      },
    });
    setOpenSnack(true);
  };

  const period = reportingHoursPeriod.find((rhp) => rhp.id === id);
  const visitsAndActivities = reportingHours
    .filter((rh) => rh.type !== ShiftType.mileage)
    .sort((x, y) => (x.visit?.startDateTime || 0) - (y.visit?.startDateTime || 0));
  const visitsAndActivitiesGrouped = groupByKey(visitsAndActivities, 'shiftId');
  const rotas = reportingHours.filter((r) => r?.rota?.id).map((rhp: ReportingHours) => rhp?.rota) as Rota[];

  const getColor = (rhp: ReportingHours[]) => {
    return rhp[0].shift?.type === ShiftType.oneoff ? 'bg-blue-50' : 'bg-slate-50';
  };

  const shiftHasMoved = (items: ReportingHours[]) => {
    const moved = items.filter((rh: ReportingHours) => rh?.visit?.type === ShiftType.visit && rh?.visit?.status === VisitStatus.moved);
    return items.length === moved.length ? '0.5' : '1';
  };

  return (
    <>
      <Message
        response={[mutationUpdateReportingHour, mutationAddMileageReportingHour, mutationDeleteMileageReportingHour, mutationSyncReportingHour]}
        openSnack={openSnack}
        setOpenSnack={setOpenSnack}
      />
      <Helmet>
        <title>Manage Reporting Hours</title>
      </Helmet>
      <LocationBar section="Reporting" page="Reporting Hours" Icon={PunchClock} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="columns-2">
          <div className="w-full">
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center mb-5"
            >
              <ArrowLeftIcon className="w-4 h-4 mr-2" />
              <div className="sm:hidden">Go back</div>
              <div className="hidden md:block">Go back to the reporting hours list</div>
            </button>
          </div>
          <div className="w-full">
            {period && (user?.profile === supportWorker?.id || user?.permissions?.admin) && (
              <div className="float-right">
                <Button
                  loading={mutationSyncReportingHour.loading}
                  color="bg-primary-700"
                  label="Sync"
                  onClick={() => syncReportingHours(period)}
                  loadingLabel="Syncing..."
                />
              </div>
            )}
          </div>
        </div>
        <div className="mt-6">
          {supportWorker && (
            <ReportingHoursProfile
              teams={teams}
              user={user}
              rotas={rotas}
              reportingHoursPeriod={period}
              title={`Reporting Hours for ${period?.fullName}`}
              showRotas={true}
              reportingHours={reportingHours}
              supportWorker={supportWorker}
            />
          )}
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-3 mt-5">
          {Object.keys(visitsAndActivitiesGrouped).map((shiftId: string, index: number) => {
            return (
              <div
                style={{ opacity: shiftHasMoved(visitsAndActivitiesGrouped[shiftId]) }}
                className={`border ${getColor(visitsAndActivitiesGrouped[shiftId])} border-gray-100 rounded-lg p-5 shadow-md`}
                key={index}
              >
                <ShiftHeading supportWorkerId={swId ?? ''} user={user || ({} as User)} reportingHours={visitsAndActivitiesGrouped[shiftId]} />
                {visitsAndActivitiesGrouped[shiftId].map((rh: ReportingHours, i: number) => {
                  return (
                    <ShiftRow
                      period={period}
                      updateReportingHour={onUpdateReportHour}
                      customers={customers}
                      supportWorkerId={swId ?? ''}
                      teamId={teamId ?? ''}
                      reportingHours={rh}
                      user={user || ({} as User)}
                      key={i}
                      apiLoading={mutationUpdateReportingHour.loading}
                    />
                  );
                })}

                {/* <ShiftFooter reportingHours={visitsAndActivitiesGrouped[shiftId]} supportWorkerId={swId || ''} /> */}
              </div>
            );
          })}
          <div className="border border-gray-100 rounded-lg p-5 shadow-md">
            <Mileage
              reportingHoursPeriod={period}
              reportingHours={reportingHours}
              deleteMileageReportingHour={onDeleteMileageReportHour}
              addMileageReportHour={onAddMileageReportHour}
              apiAddMileageLoading={mutationAddMileageReportingHour.loading}
              apiDeleteMileageLoading={mutationDeleteMileageReportingHour.loading}
            />
          </div>
        </div>
        <div className="mt-4">
          <div className="flex items-center gap-2 mb-2 text-lg leading-lg font-semibold text-primary-700">
            <InformationCircleIcon className="w-6 h-6 text-primary-700" />
            Agreed shifts
          </div>
          <div className="text-md leading-md text-gray-800">Your shifts as described on the agreed shift on rota templates and team shifts</div>
        </div>
        <div className="mt-4">
          <div className="flex items-center gap-2 mb-2 text-lg leading-lg font-semibold text-primary-700">
            <InformationCircleIcon className="w-6 h-6 text-primary-700" />
            Banked Hours
          </div>
          <div className="text-md leading-md text-gray-800">
            The hours before your first visit or after your last visit within the 5 hours paid shift. Please declare as "Banked hours" any activities you do
            outside of your agreed shift visits. If you do not have banked hours available anymore, it will automatically be added as overtime.
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageReportingHours;
