import React, { useState } from 'react';
import { useUser, UserContextProps } from 'context/userContext';
import { Helmet } from 'react-helmet';
import { PermContactCalendar } from '@mui/icons-material';
import SelectRota from 'components/SelectRota';
import { Loading, LocationBar } from 'components';
import ShiftsList from '../TeamShifts/ShiftsList';

export default function MyShifts() {
  const { user, userLoading } = useUser() as UserContextProps;
  const [createShiftModalState, toggleCreateShiftModal] = useState<boolean>(false);
  const [createActivityModalState, toggleCreateActivityModal] = useState<boolean>(false);

  if (userLoading) return <Loading />;
  return (
    <>
      <Helmet>
        <title>My Shifts | Rota</title>
      </Helmet>
      <LocationBar section="Rota" page="My Shifts" Icon={PermContactCalendar} />

      <div className="my-10 px-4 md:px-[5%]">
        {user && (
          <>
            <SelectRota user={user} toggleCreateShiftModal={toggleCreateShiftModal} toggleCreateActivityModal={toggleCreateActivityModal} showAddShift />
            <ShiftsList
              toggleCreateShiftModal={toggleCreateShiftModal}
              createShiftModalState={createShiftModalState}
              toggleCreateActivityModal={toggleCreateActivityModal}
              createActivityModalState={createActivityModalState}
              user={user}
              rotaId={user.rota.id}
              team={user?.teamId}
              rotaStart={user.rota.startRotaDateTime}
            />
          </>
        )}
      </div>
    </>
  );
}
