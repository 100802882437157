import { useQuery, useMutation, gql } from '@apollo/client';
import { SupportWorker, SupportWorkerRole, SupportWorkerSearch } from '../../__generated__/graphql';

export const SAVE_SUPPORT_WORKER_PROFILE = gql`
  mutation SaveSupportWorkerProfile($input: SupportWorkerProfileInput) {
    SaveSupportWorkerProfile(input: $input) {
      id
      message
      date
    }
  }
`;

export const ACTIVATE_ACCOUNT = gql`
  mutation ActivateAccountForWorker($input: ManageSupportWorkerAccountInput) {
    ActivateAccountForWorker(input: $input) {
      id
      message
      date
    }
  }
`;

export const TERMINATE_ACCOUNT = gql`
  mutation TerminateAccountForWorker($input: ManageSupportWorkerAccountInput) {
    TerminateAccountForWorker(input: $input) {
      id
      message
      date
    }
  }
`;

export const CREATE_BIRDIE_ACCOUNT = gql`
  mutation CreateBirdieAccountForWorker($input: ManageSupportWorkerAccountInput) {
    CreateBirdieAccountForWorker(input: $input) {
      id
      message
      date
    }
  }
`;

export const CREATE_COGNITO_ACCOUNT = gql`
  mutation CreateCognitoAccountForWorker($input: ManageSupportWorkerAccountInput) {
    CreateCognitoAccountForWorker(input: $input) {
      id
      message
      date
    }
  }
`;

export const CHANGE_SW_TEAM = gql`
  mutation TeamChangeForWorker($input: AssignTeamSupportWorkerAccountInput) {
    TeamChangeForWorker(input: $input) {
      id
      message
      date
    }
  }
`;

export const GET_SUPPORT_WORKERS = gql`
  query GetSupportWorkers($query: SupportWorkerSearch) {
    GetSupportWorkers(query: $query) {
      id
      fullName
      teamName
      avatar
      position
      contractedHours
      employmentStatus
      lat
      lng
      phone
      roleId
      birdieId
      email
      cognito
      teamId
      isActive
      isTerminated
      city
      address
      postcode
      dateUpdated
      dateCreated
      circleId
      circleName
      role {
        id
        name
      }
      lastLoggedInDate
      permissions {
        developer
        admin
      }
      profileData {
        aboutMe
        matters
        support
      }
      teamPeriod {
        teamLeftId
        teamJoinedId
        date
      }
    }
  }
`;

export const GET_SUPPORT_WORKERS_OPTIONS = gql`
  query GetSupportWorkers($query: SupportWorkerSearch) {
    GetSupportWorkers(query: $query) {
      id
      fullName
      teamId
      permissions {
        developer
        admin
      }
    }
  }
`;

export const GET_SUPPORT_WORKER_ROLES_OPTIONS = gql`
  query GetSupportWorkerRoles {
    GetSupportWorkerRoles {
      id
      name
    }
  }
`;

export const GET_SUPPORT_WORKER = gql`
  query GetSupportWorker($query: SupportWorkerSearch) {
    GetSupportWorker(query: $query) {
      id
      teamId
      teamName
      firstName
      lastName
      dob
      avatar
      role {
        id
        name
      }
      circleId
      circleName
      contractedHours
      employmentStatus
      email
      fullName
      position
      lat
      lng
      startedAt
      profileData {
        aboutMe
        matters
        support
      }
      rota {
        id
        startRotaDateTime
        endRotaDateTime
      }
      permissions {
        developer
        admin
      }
      teamPeriod {
        teamLeftId
        teamJoinedId
        date
      }
    }
  }
`;

export const GET_SUPPORT_WORKER_MARKER = gql`
  query GetSupportWorkers($query: SupportWorkerSearch) {
    GetSupportWorkers(query: $query) {
      lat
      lng
      fullName
      postcode
    }
  }
`;
interface SupportWorkersResponse {
  GetSupportWorkers: SupportWorker[];
}

interface SupportWorkerResponse {
  GetSupportWorker: SupportWorker;
}

interface SupportWorkerRoleResponse {
  GetSupportWorkerRoles: SupportWorkerRole[];
}

export const useGetSupportWorkers = (query: SupportWorkerSearch) => {
  const { data, loading, error } = useQuery<SupportWorkersResponse>(GET_SUPPORT_WORKERS, {
    variables: {
      query: { ...query },
    },
  });

  return { supportWorkers: data?.GetSupportWorkers || [], loading, error };
};

export const useGetSupportWorkerMarker = (query: SupportWorkerSearch) => {
  const { data, loading, error } = useQuery<SupportWorkersResponse>(GET_SUPPORT_WORKER_MARKER, {
    variables: {
      query: { ...query },
    },
  });

  return { supportWorkers: data?.GetSupportWorkers || [], loading, error };
};

export const useGetSupportWorkerOptions = (query: SupportWorkerSearch) => {
  const { data, loading, error } = useQuery<SupportWorkersResponse>(GET_SUPPORT_WORKERS_OPTIONS, {
    variables: {
      query: { ...query },
    },
  });

  return { supportWorkers: data?.GetSupportWorkers || [], loading, error };
};

export const useGetSupportWorkerRoleOptions = () => {
  const { data, loading, error } = useQuery<SupportWorkerRoleResponse>(GET_SUPPORT_WORKER_ROLES_OPTIONS, {});

  return { suportWorkerRoles: data?.GetSupportWorkerRoles || [], supportWorkerRolesLoading: loading, error };
};

export const useGetSupportWorker = (query: SupportWorkerSearch) => {
  const { data, loading, error } = useQuery<SupportWorkerResponse>(GET_SUPPORT_WORKER, {
    variables: {
      skip: !query.id,
      query: { ...query },
    },
  });

  return { supportWorker: data?.GetSupportWorker, loading, error };
};

export const useActivateAccount = (query: SupportWorkerSearch) => {
  const [activateAccount, mutationActivateAccount] = useMutation(ACTIVATE_ACCOUNT, {
    refetchQueries: [
      {
        query: GET_SUPPORT_WORKERS,
        variables: {
          query: { ...query },
        },
      },
    ],
  });

  return { activateAccount, mutationActivateAccount };
};

export const useTerminateAccount = (query: SupportWorkerSearch) => {
  const [terminateAccount, mutationTerminateAccount] = useMutation(TERMINATE_ACCOUNT, {
    refetchQueries: [
      {
        query: GET_SUPPORT_WORKERS,
        variables: {
          query: { ...query },
        },
      },
    ],
  });

  return { terminateAccount, mutationTerminateAccount };
};

export const useCreateBirdieAccount = (query: SupportWorkerSearch) => {
  const [createBirdieAccount, mutationCreateBirdieAccount] = useMutation(CREATE_BIRDIE_ACCOUNT, {
    refetchQueries: [
      {
        query: GET_SUPPORT_WORKERS,
        variables: {
          query: { ...query },
        },
      },
    ],
  });

  return { createBirdieAccount, mutationCreateBirdieAccount };
};

export const useCreateCognitoAccount = (query: SupportWorkerSearch) => {
  const [createCognitoAccount, mutationCreateCognitoAccount] = useMutation(CREATE_COGNITO_ACCOUNT, {
    refetchQueries: [
      {
        query: GET_SUPPORT_WORKERS,
        variables: {
          query: { ...query },
        },
      },
    ],
  });

  return { createCognitoAccount, mutationCreateCognitoAccount };
};

export const useChangeTeam = (query: SupportWorkerSearch) => {
  const [changeTeam, mutationChangeTeam] = useMutation(CHANGE_SW_TEAM, {
    refetchQueries: [
      {
        query: GET_SUPPORT_WORKERS,
        variables: {
          query: { ...query },
        },
      },
    ],
  });

  return { changeTeam, mutationChangeTeam };
};

export const useSaveSupportWorkerProfile = (query?: SupportWorkerSearch) => {
  const refetch = query
    ? {
        refetchQueries: [
          {
            query: GET_SUPPORT_WORKERS,
            variables: {
              query: { ...query },
            },
          },
        ],
      }
    : {};
  const [saveSupportWorkerProfile, mutationSaveSupportWorkerProfile] = useMutation(SAVE_SUPPORT_WORKER_PROFILE, refetch);
  return { saveSupportWorkerProfile, mutationSaveSupportWorkerProfile };
};
