import { useFieldArray } from 'react-hook-form';
import { TrashIcon } from '@heroicons/react/24/outline';
import { SupportWorkerSelectField } from 'components/Fields/SupportWorkerSelectField';
import { SelectFieldWithoutLabel } from 'components/Fields/SelectField';
import { TextAreaFieldWithoutLabel } from 'components/Fields/TextAreaField';
import { AddOutlined } from '@mui/icons-material';

interface TeamMeetingActionsFieldProps {
  name: string;
  disabled?: boolean;
}
const TeamMeetingActionsField = ({ name, disabled = false }: TeamMeetingActionsFieldProps) => {
  const { fields, append, remove } = useFieldArray({
    name, // unique name for your Field Array
  });

  return (
    <>
      <div className="flex flex-col sm:flex-row justify-between sm:items-end mt-2 mb-5">
        <button
          type="button"
          data-cy="add-action-button"
          onClick={() => append(' ')}
          className="border border-primary-700 text-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center gap-2 w-fit mt-2 sm:mt-0 self-end sm:self-auto"
        >
          <AddOutlined className="w-5 h-5" />
          Add an action
        </button>
      </div>
      <div className="flex flex-col">
        {fields?.map((field, index: number) => {
          return (
            <div key={index} className="border border-gray-200 rounded-lg px-4 py-3 mb-3">
              <div className="flex flex-col md:flex-row items-start gap-4 mt-4">
                <div className="w-full md:w-1/3">
                  <div className="text-gray-800 text-lg leading-lg font-medium mb-1">Status</div>
                  <SelectFieldWithoutLabel
                    isRequired={true}
                    testId={`add-action-status-select-${index}`}
                    name={`${name}.${index}.status`}
                    disabled={disabled}
                    searchable={true}
                    options={[
                      { label: 'In Progress', value: 'In Progress', id: '0' },
                      { label: 'Resolved', value: 'Resolved', id: '1' },
                    ]}
                  />
                </div>
                <div className="  w-full md:w-1/3">
                  <div className="text-gray-800 text-lg leading-lg font-medium mb-1">Owner</div>
                  <SupportWorkerSelectField
                    testId={`add-action-sw-select-${index}`}
                    asRow={true}
                    showWholeTeam={true}
                    name={`${name}.${index}.ownerId`}
                    showAll={false}
                    disabled={disabled}
                    searchable={true}
                  />
                </div>
                <div className="w-full md:w-2/3">
                  <div className="text-gray-800 text-lg leading-lg font-medium mb-1">Action</div>
                  <TextAreaFieldWithoutLabel rows={1} testId={`add-action-input-${index}`} placeholder="Action" name={`${name}.${index}.action`} />
                </div>
                <button
                  type="button"
                  data-cy={`delete-action-button-${index}`}
                  onClick={() => remove(index)}
                  aria-label="Remove this action"
                  className="text-white rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center gap-2"
                >
                  <TrashIcon className="w-6 h-6 text-error-500 hover:text-primary600" />
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export { TeamMeetingActionsField };
