import { VolunteerActivismOutlined } from '@mui/icons-material';
import { Customer } from '__generated__/graphql';
import AvatarGroup from 'components/AvatarGroup';
import { useNavigate } from 'react-router-dom';
import { ImageSizeType } from 'types/types';

interface YourPeopleProps {
  customers: Customer[];
  testId: string;
}

const YourPeople = ({ customers, testId }: YourPeopleProps) => {
  const navigate = useNavigate();

  return (
    <button
      type="button"
      onClick={() => navigate('/supported-people')}
      className="bg-background2 border border-black border-opacity-[0.13] p-6 rounded-3xl"
      data-cy={testId}
    >
      <div className="flex flex-row items-center justify-between mb-4">
        <div className="flex flex-row items-center gap-3">
          <div className="border border-black border-opacity-20 w-10 h-10 rounded-full flex items-center justify-center">
            <VolunteerActivismOutlined className="!w-5 !h-5 text-content-secondary" />
          </div>
          <div className="text-xl leading-xl font-semibold text-black">Your People</div>
        </div>
      </div>
      <AvatarGroup
        avatars={
          customers.map((c) => ({ avatar: c.image?.url ?? '', alt: `${c.firstName} ${c.lastName}` ?? '', tooltip: `${c.firstName} ${c.lastName}` ?? '' })) ?? []
        }
        size={ImageSizeType.LG}
        max={5}
        borderWhite
      />
    </button>
  );
};

export default YourPeople;
