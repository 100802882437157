import { useState } from 'react';
import { Input } from 'components';
import { SelectOption } from 'types/types';
import { CreateChatRoomInput } from '__generated__/graphql';
import { useGetSupportWorkers } from 'api/hooks/useSupportWorkers';
import ReactMultiSelect from 'components/MultiSelect';
import { useGetFamilyMembers } from 'api/hooks/useFamily';
import ReactSelect from 'components/Select';
import { useGetCustomers } from 'api/hooks/useCustomers';
import TeamSelect from 'components/TeamSelect';

interface CreateChatRoomProps {
  teamId: string;
  onCloseModal: () => void;
  onCreate: (room: CreateChatRoomInput) => void;
}

const CreateChatRoom = ({ teamId, onCloseModal, onCreate }: CreateChatRoomProps) => {
  const [selectedTeam, setSelectedTeam] = useState(teamId !== 'all' ? teamId : '');
  const { supportWorkers, loading: supportWorkersLoading } = useGetSupportWorkers({});
  const { familyMembers, familyMembersLoading } = useGetFamilyMembers({ teamId: selectedTeam });
  const { customers, loading: customersLoading } = useGetCustomers({ teamId: selectedTeam });

  const [selectedSupportWorkers, setSelectedSupportWorkers] = useState<SelectOption[]>([]);
  const [selectedFamily, setSelectedFamily] = useState<SelectOption[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<SelectOption>({ value: '', label: '' });
  const [topic, setTopic] = useState('');
  const [validation, setValidation] = useState<string | null>(null);

  const supportWorkersOptions = supportWorkers.map((sw) => ({ value: sw.id, label: sw.fullName }));
  const familyMembersOptions = familyMembers.map((f) => ({ value: f.id ?? '', label: f.name ?? '' }));
  const customersOptions = customers.map((c) => ({ value: c.id ?? '', label: `${c.firstName} ${c.lastName}` ?? '' }));

  const onCreateRoom = () => {
    if (selectedTeam === '') {
      setValidation('You need to select a team.');
      return;
    }
    if (selectedCustomer.value === '') {
      setValidation('You need to add at least a customer to be able to create a room.');
      return;
    }
    const newSWMembers = selectedSupportWorkers.map((selectedSW) => {
      const supportWorker = supportWorkers.find((sw) => selectedSW.value === sw.id);
      return {
        avatar: supportWorker?.avatar,
        id: supportWorker?.id,
        name: supportWorker?.fullName,
        newMessages: 0,
        type: 'Support Worker',
      };
    });

    const newFamilyMembers = selectedFamily.map((f) => ({
      avatar: '',
      id: f.value,
      name: f.label,
      newMessages: 0,
      type: 'Family',
    }));

    onCreate({
      teamId: selectedTeam,
      topic,
      customer: {
        avatar: '',
        id: selectedCustomer.value,
        name: selectedCustomer.label,
        newMessages: 0,
        type: 'Client',
      },
      members: [...newSWMembers, ...newFamilyMembers],
    });
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto mx-auto max-w-5xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none p-6 max-h-screen md:max-h-[70vh] overflow-y-auto md:w-[480px] lg:w-[680px]">
            <div className="text-gray-900 text-lg leading-lg font-semibold mb-5">Create Chat Room</div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Topic</div>
              <div className="mt-2">
                <Input value={topic} onChange={setTopic} />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 w-auto">Team (for the supported person and family members)</div>
              <div className="mt-2">
                <TeamSelect onSelectedTeam={setSelectedTeam} selectedTeamId={selectedTeam || ''} />
              </div>
            </div>

            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Supported person</div>
              <div className="mt-2">
                <ReactSelect
                  options={customersOptions}
                  onChange={(value) => {
                    setValidation(null);
                    setSelectedCustomer(value);
                  }}
                  selectedValue={selectedCustomer}
                  isLoading={customersLoading}
                />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Support workers</div>
              <div className="mt-2">
                <ReactMultiSelect
                  options={supportWorkersOptions}
                  onChange={setSelectedSupportWorkers}
                  selectedValue={selectedSupportWorkers}
                  searchable
                  isLoading={supportWorkersLoading}
                />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Family members</div>
              <div className="mt-2">
                <ReactMultiSelect
                  options={familyMembersOptions}
                  onChange={setSelectedFamily}
                  selectedValue={selectedFamily}
                  searchable
                  isLoading={familyMembersLoading}
                />
              </div>
            </div>
            {validation && <div className="mb-5 text-md text-error-600 font-medium">{validation}</div>}
            <div className="flex justify-between items-center">
              <button type="button" className="text-gray-500 font-semibold text-md leading-md" onClick={onCloseModal}>
                Cancel
              </button>
              <button
                type="button"
                className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md disabled:opacity-50"
                onClick={onCreateRoom}
                disabled={validation !== null}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-gray-900" />
    </>
  );
};

export default CreateChatRoom;
