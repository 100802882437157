import { SupportWorkerSelectField } from 'components/Fields/SupportWorkerSelectField';
import { DateField } from 'components/Fields/DateField';
import { InputField } from 'components/Fields/InputField';
import { RadioField } from 'components/Fields/RadioField';

export default function CandidateWorkshopFeedback() {
  const progressShadowOptions = ['Yes', 'No', 'Needs additional call'];

  return (
    <div className="mt-8 sm:mt-12">
      <SupportWorkerSelectField isRequired={false} name="workshopFeedback.personObserving" label="Person carrying out observation" />
      <DateField name="workshopFeedback.observationDate" label="Date of observation" />
      <InputField
        placeholder="Communication"
        name="workshopFeedback.communication"
        label="Communication – Does the applicant contribute to discussions and answers and communicate effectively with others in the workshop?  "
      />
      <InputField
        placeholder="Team Work"
        name="workshopFeedback.teamWork"
        label="Team Work – During the group exercises does the applicant show good team working skills? Collaborating well with others on the workshop."
      />
      <InputField
        placeholder="Personability"
        name="workshopFeedback.personability"
        label="Personability – Does the applicant appear social and agreeable? Do they come across as approachable and able to build effective relationships?"
      />
      <InputField
        placeholder="Patience"
        name="workshopFeedback.patience"
        label="Patience – Has the applicant waited their turn to answer and allowed others to have their say?"
      />
      <InputField
        placeholder="Empathy"
        name="workshopFeedback.empathy"
        label="Empathy – During the workshop discussion does the applicant show signs of empathy and consideration of others?"
      />
      <InputField placeholder="Overall Applicant Feedback" name="workshopFeedback.overallFeedback" label="Overall Applicant Feedback" />
      <RadioField label="Progress to shadow" radioOptions={progressShadowOptions} name="workshopFeedback.progressShadow" />
      <div className="flex justify-end">
        <button type="submit" className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
          Save
        </button>
      </div>
    </div>
  );
}
