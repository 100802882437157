import Helmet from 'react-helmet';
import { LocationBar } from 'components';
import { Box } from '@mui/material';
import GroupWorkIcon from '@mui/icons-material/GroupWork';

const maptioStyle = { height: '73vh', width: '95vw', border: 'none', borderRadius: '8px', display: 'block' };

const LeadershipTeam = () => (
  <>
    <Helmet>
      <title>BelleVie Organisation Map</title>
    </Helmet>
    <LocationBar section="Policies & Practices" page="Organisation Map" Icon={GroupWorkIcon} />
    <Box display="flex" justifyContent="center">
      <iframe title="maptio" src="https://app.maptio.com/embed/635ea2cf321c3600045ea007" style={maptioStyle} sandbox="allow-same-origin allow-scripts" />
    </Box>
  </>
);

export default LeadershipTeam;
