import ReactSelect from 'components/Select';
import { useState } from 'react';
import { ToggleButtons } from 'components';
import { SizeType, SelectOption } from 'types/types';
import TextArea from 'components/TextArea';
import { UserContextProps, useUser } from 'context/userContext';
import { useGetSupportWorkerOptions } from 'api/hooks/useSupportWorkers';

interface AddMessageProps {
  onCloseModal: () => void;
  onAdd: (contactType: string, from: string, message: string) => void;
}

const AddMessage = ({ onCloseModal, onAdd }: AddMessageProps) => {
  const { user, userLoading } = useUser() as UserContextProps;
  const userId = user?.profile;
  const { supportWorkers, loading } = useGetSupportWorkerOptions({});
  const adminWorkers = supportWorkers.filter((sw) => sw.permissions?.admin === true).map((t) => ({ value: t.id, label: t.fullName }));
  const [message, setMessage] = useState<string>('');
  const fromDefault = { value: userId || '', label: adminWorkers.find((aw) => aw.value === userId)?.label ?? '' };
  const [from, setFrom] = useState<SelectOption>(fromDefault);
  const [contactType, setContactType] = useState<string>('Email');
  const contactsType = ['Email', 'SMS'];

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto mx-auto max-w-5xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6 max-h-screen md:max-h-[90vh] overflow-y-auto">
            <div className="text-gray-900 text-lg leading-lg font-semibold mb-5">Add message</div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Type</div>
              <div className="w-60 sm:w-96 mt-2">
                <ToggleButtons buttons={contactsType} size={SizeType.Full} selectedButton={contactType} onSelectButton={setContactType} />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Recorded by</div>
              <div className="w-60 sm:w-96 mt-2">
                <ReactSelect isLoading={loading || userLoading} options={adminWorkers} onChange={setFrom} selectedValue={fromDefault || from} />
              </div>
            </div>

            <div className="mb-7">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Message</div>
              <div className="w-60 sm:w-96 mt-2">
                <TextArea value={message} onChange={setMessage} />
              </div>
            </div>
            <div className="flex justify-between items-center">
              <button type="button" className="text-gray-500 font-semibold text-md leading-md" onClick={onCloseModal}>
                Cancel
              </button>
              <button
                type="button"
                className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
                onClick={() => onAdd(contactType, from.label, message)}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-gray-900" />
    </>
  );
};

export default AddMessage;
