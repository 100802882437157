import { useEffect, useState } from 'react';
import { AgreedShiftRun, AgreedShiftRunWeeks, Maybe } from '__generated__/graphql';
import { useGetPlaceholderVisits } from 'api/hooks/usePlaceholderVisits';
import { useGetAgreedShifts } from 'api/hooks/useAgreedShifts';
import ReactSelect from 'components/Select';
import VisitRun from './VisitRun';
import AgreedVisitHeader from '../AgreedVisit/AgreedVisitHeader';

interface AvailabilitiesProps {
  date: number;
  selectedTeam: string;
  selectedAgreedRota: string;
}

const Availabilities = ({ date, selectedTeam, selectedAgreedRota }: AvailabilitiesProps) => {
  const [selectedWeek, setSelectedWeek] = useState({ value: '1', label: 'Week 1' });
  const [selectedPeriod, setSelectedPeriod] = useState({ value: 'All', label: 'All' });
  const [selectedRun, setSelectedRun] = useState({ value: '', label: '' });
  const { agreedShifts } = useGetAgreedShifts({
    teamId: selectedTeam,
    agreedRotaId: selectedAgreedRota,
  });
  const { placeholderVisits } = useGetPlaceholderVisits({
    teamId: selectedTeam,
    agreedRotaId: selectedAgreedRota,
  });
  const [agreedVisits, setAgreedVisits] = useState<AgreedShiftRunWeeks>();

  useEffect(() => {
    if (agreedShifts) {
      setAgreedVisits(agreedShifts);
    }
  }, [agreedShifts]);

  const selectedAgreedShift = selectedWeek.value === '1' ? agreedShifts.week1 : agreedShifts.week2;
  const weekOptions = [
    { value: '1', label: 'Week 1' },
    { value: '2', label: 'Week 2' },
  ];
  const runOptions = selectedAgreedShift?.map((ag) => ({ value: ag?.period ?? '', label: ag?.period ?? '' })) ?? [];
  const periodOptions = [
    { value: 'All', label: 'All' },
    { value: 'AM', label: 'AM' },
    { value: 'PM', label: 'PM' },
  ];
  return (
    <>
      <div className="xl:hidden">
        <div className="mt-5">
          <div className="text-sm font-medium text-gray-700 capitalize w-auto">Select a week</div>
          <div className="mt-2">
            <ReactSelect
              selectedValue={selectedWeek}
              options={weekOptions}
              onChange={(option) => {
                setSelectedWeek(option);
                const week = option.value === '1' ? agreedShifts.week1 || [] : agreedShifts.week2 || [];
                setSelectedRun({ value: week[0]?.period || '', label: week[0]?.period || '' });
              }}
            />
          </div>
        </div>
        <div className="mt-3">
          <div className="text-sm font-medium text-gray-700 capitalize w-auto">Select a Run</div>
          <div className="mt-2">
            <ReactSelect selectedValue={selectedRun} options={runOptions} onChange={setSelectedRun} />
          </div>
        </div>
      </div>
      <div className="hidden xl:block">
        <div className="mt-5">
          <div className="text-sm font-medium text-gray-700 capitalize w-auto">Select a Period</div>
          <div className="mt-2">
            <ReactSelect selectedValue={selectedPeriod} options={periodOptions} onChange={setSelectedPeriod} />
          </div>
        </div>
      </div>
      <div className="flex items-center gap-4 mt-5">
        <div className="flex items-center gap-2">
          <div className="w-6 h-6 bg-warning-600 rounded-sm" />
          <div className="text-lg leading-lg text-gray-800 font-medium">New packages</div>
        </div>
        <div className="flex items-center gap-2">
          <div className="w-6 h-6 bg-[#0C0] opacity-20 rounded-sm" />
          <div className="text-lg leading-lg text-gray-800 font-medium">Available times</div>
        </div>
      </div>
      <div className={`${selectedWeek.value === '1' ? 'flex' : 'hidden'} xl:flex items-stretch border border-gray-100 flex-wrap mt-5`}>
        <div className="hidden xl:flex flex-wrap w-full">
          <AgreedVisitHeader date={date} week={1} startDateTime={0} endDateTime={0} />
        </div>
        {agreedVisits?.week1?.map((row: Maybe<AgreedShiftRun>, index: number) => {
          if (row && row?.status !== 'paused') {
            return (
              <div
                key={index}
                className={`${row?.period === selectedRun.value ? 'flex' : 'hidden'} ${
                  (row?.period?.includes('AM') && selectedPeriod.value === 'AM') ||
                  (row?.period?.includes('PM') && selectedPeriod.value === 'PM') ||
                  selectedPeriod.value === 'All'
                    ? 'xl:flex'
                    : 'xl:hidden'
                } w-full border-t border-gray-100`}
              >
                <VisitRun agreedShifts={agreedVisits} row={row} week={1} placeholderVisits={placeholderVisits} />
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
      <div className={`${selectedWeek.value === '2' ? 'flex' : 'hidden'} xl:flex items-stretch border border-gray-100 flex-wrap mt-5`}>
        <div className="hidden xl:flex flex-wrap w-full">
          <AgreedVisitHeader date={date} week={2} startDateTime={0} endDateTime={0} />
        </div>
        {agreedVisits?.week2?.map((row: Maybe<AgreedShiftRun>, index: number) => {
          if (row && row.status !== 'paused') {
            return (
              <div
                key={index}
                className={`${row?.period === selectedRun.value ? 'flex' : 'hidden'} ${
                  (row?.period?.includes('AM') && selectedPeriod.value === 'AM') ||
                  (row?.period?.includes('PM') && selectedPeriod.value === 'PM') ||
                  selectedPeriod.value === 'All'
                    ? 'xl:flex'
                    : 'xl:hidden'
                } w-full border-t border-gray-100`}
              >
                <VisitRun agreedShifts={agreedVisits} row={row} week={2} placeholderVisits={placeholderVisits} />
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    </>
  );
};

export default Availabilities;
