import { useState } from 'react';
import {
  useActivateAccount,
  useGetSupportWorkers,
  useTerminateAccount,
  useCreateBirdieAccount,
  useCreateCognitoAccount,
  useChangeTeam,
} from 'api/hooks/useSupportWorkers';
import { GroupsOutlined } from '@mui/icons-material';
import { Helmet } from 'react-helmet';
import { ArrowLeftIcon, ArrowPathIcon, PlusIcon, EnvelopeIcon, UserGroupIcon, UserIcon, AcademicCapIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import { Loading, LocationBar, Message } from 'components';
import { useNavigate, useParams } from 'react-router-dom';
import TabSwitcher from 'components/TabSwitcher';
import { useUser, UserContextProps } from 'context/userContext';
import ManageSupportWorkerDialog from './ManageSupportWorkerDialog';
import ManageSupportWorker from './ManageSupportWorker';
import ManageSupportWorkerAccounts from './ManageSupportWorkerAccounts';
import { SupportWorkerStateTransition } from './types';
import { ManageSupportWorkerFormList } from './ManageSupportWorkerFormList';

function ManageSupportWorkerDetail() {
  const { user, userLoading } = useUser() as UserContextProps;
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const [manageType, setManageType] = useState<string>('');
  const { supportWorkers, loading } = useGetSupportWorkers({ showAll: true });
  const { supportWorkerId } = useParams();
  const { activateAccount, mutationActivateAccount } = useActivateAccount({ showAll: true });
  const { terminateAccount, mutationTerminateAccount } = useTerminateAccount({ showAll: true });
  const { createBirdieAccount, mutationCreateBirdieAccount } = useCreateBirdieAccount({ showAll: true });
  const { createCognitoAccount, mutationCreateCognitoAccount } = useCreateCognitoAccount({ showAll: true });

  const { changeTeam, mutationChangeTeam } = useChangeTeam({ showAll: true });
  const [modalState, toggleModal] = useState<boolean>(false);
  const [newTeamId, setNewTeam] = useState<string>('');

  const supportWorker = supportWorkerId && supportWorkers?.length > 0 ? supportWorkers.find((sw) => sw.id === supportWorkerId) : null;

  const navigate = useNavigate();

  const onChangeAccount = (type: string, teamId?: string) => {
    toggleModal(true);
    if (type) setManageType(type);
    if (teamId) setNewTeam(teamId);
  };

  const onConfirmChangeAccount = async () => {
    if (!supportWorker) return null;

    const query = { variables: { input: { id: supportWorkerId } } };

    switch (manageType) {
      case SupportWorkerStateTransition.terminated:
        activateAccount(query);
        break;
      case SupportWorkerStateTransition.maps:
        activateAccount(query);
        break;
      case SupportWorkerStateTransition.active:
        terminateAccount(query);
        break;
      case SupportWorkerStateTransition.birdieDisabled:
        createBirdieAccount(query);
        break;
      case SupportWorkerStateTransition.cognitoDisabled:
        createCognitoAccount(query);
        break;
      case SupportWorkerStateTransition.changeTeam:
        changeTeam({ variables: { input: { id: supportWorkerId, teamId: newTeamId } } });
        break;
      default:
        break;
    }
    setOpenSnack(true);
    toggleModal(false);
  };

  if (loading || userLoading) {
    return <Loading />;
  }

  if (!supportWorker) return null;

  const tabs = [
    {
      label: 'Details',
      component: (
        <ManageSupportWorker user={user} teamChangeLoading={mutationChangeTeam.loading} onChangeAccount={onChangeAccount} supportWorker={supportWorker} />
      ),
    },
    {
      label: 'Accounts',
      component: (
        <ManageSupportWorkerAccounts
          onChangeAccount={onChangeAccount}
          supportWorker={supportWorker}
          birdieLoading={mutationCreateBirdieAccount.loading}
          cognitoLoading={mutationCreateCognitoAccount.loading}
          accountChangeLoading={mutationActivateAccount.loading || mutationTerminateAccount.loading}
        />
      ),
    },
    { label: 'Forms', component: <ManageSupportWorkerFormList supportWorker={supportWorker} /> },
  ];

  return (
    <>
      <Helmet>
        <title>Support Workers</title>
      </Helmet>
      <LocationBar section="Admin" page="Manage Support Worker" Icon={GroupsOutlined} />
      <Message
        response={[mutationActivateAccount, mutationTerminateAccount, mutationCreateBirdieAccount, mutationCreateCognitoAccount, mutationChangeTeam]}
        openSnack={openSnack}
        setOpenSnack={setOpenSnack}
      />
      <ManageSupportWorkerDialog title={manageType} onChange={onConfirmChangeAccount} modalState={modalState} toggleModal={toggleModal} />
      <div className="my-10 px-4 md:px-[5%]">
        <button type="button" className="text-gray-500 font-semibold text-md leading-md flex items-center" onClick={() => navigate(-1)}>
          <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
        </button>

        <div className="flex items-start justify-between mt-16">
          <div>
            <div className="flex items-center  py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
              <img className="w-20 h-20 rounded-full" src={supportWorker?.avatar} alt={`Link worker ${supportWorker?.fullName}`} />
              <div className="ps-3">
                <div className="text-black text-xl sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1">{supportWorker?.fullName}</div>
              </div>
            </div>
            <div className="text-gray-600 text-md leading-md mb-2 flex items-center">
              <EnvelopeIcon className="w-5 h-5 mr-2 text-gray-600" />
              <span className="pt-0.5">{supportWorker?.email}</span>
            </div>

            <div className="text-gray-600 text-md leading-md mb-2 flex items-center">
              <UserGroupIcon className="w-5 h-5 mr-2 text-gray-600" />
              <span className="pt-0.5">{supportWorker?.position}</span>
            </div>

            <div className="text-gray-600 text-md leading-md mb-2 flex items-center">
              <UserIcon className="w-5 h-5 mr-2 text-gray-600" />
              <span className="pt-0.5">Admin: {supportWorker?.permissions?.admin?.toString()}</span>
            </div>

            <div className="text-gray-600 text-md leading-md mb-2 flex items-center">
              <AcademicCapIcon className="w-5 h-5 mr-2 text-gray-600" />
              <span className="pt-0.5">Developer: {supportWorker?.permissions?.developer?.toString()}</span>
            </div>
          </div>
          <div className="flex-col justify-between self-stretch hidden sm:flex">
            <div className="text-gray-400 text-xs font-medium leading-xs mb-2 flex items-center">
              <UserGroupIcon className="w-3.5 h-3.5 mr-2" />
              Last Logged In: {supportWorker?.lastLoggedInDate ? format(supportWorker?.lastLoggedInDate, 'dd/MM/yyyy') : 'Never logged in'}
            </div>
            <div className="text-gray-400 text-xs font-medium leading-xs mb-2 flex items-center">
              <PlusIcon className="w-3.5 h-3.5 mr-2" />
              Created: {supportWorker?.dateCreated ? format(supportWorker?.dateCreated, 'dd/MM/yyyy') : 'unknown'}
            </div>
            <div className="text-gray-400 text-xs font-medium leading-xs mb-2 flex items-center">
              <ArrowPathIcon className="w-3.5 h-3.5 mr-2" />
              Updated: {supportWorker?.dateUpdated ? format(supportWorker?.dateUpdated, 'dd/MM/yyyy') : 'unknown'}
            </div>
          </div>
        </div>
        <div className="mt-10 sm:mt-16">
          <TabSwitcher tabs={tabs} />
        </div>
      </div>
    </>
  );
}

export default ManageSupportWorkerDetail;
