import { Warning } from '@mui/icons-material';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { Table } from 'components';
import { BorderLinearProgress } from 'components/BorderLinearProgress';
import { AnswerDataType, QuestionGroupType } from '../types';

interface QuestionGroupProps {
  questionGroup: QuestionGroupType[];
}

const QuestionGroup = ({ questionGroup }: QuestionGroupProps) => {
  const columnHelper = createColumnHelper<AnswerDataType>();

  const columns1 = useMemo(() => {
    return [
      columnHelper.accessor('question', {
        header: () => 'Question',
        cell: (info) => <div className="flex items-center font-medium text-gray-900 whitespace-wrap dark:text-white xl:w-20 mr-4">{info.renderValue()} </div>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('team_mean', {
        header: () => 'Bellevie Team',
        cell: (info) => {
          return (
            <span className=" mr-4 xl:w-20">
              <BorderLinearProgress value={100 - (info.row.original.team_mean ?? 0) * 100} />
              <div className="flex flex-row justify-between mt-0.3">
                <div>No {parseFloat((100 - (info.row.original.team_mean ?? 0) * 100).toFixed(0)).toString()}%</div>

                <div>Yes {parseFloat(((info.row.original.team_mean ?? 0) * 100).toFixed(0)).toString()}%</div>
              </div>
            </span>
          );
        },
        footer: (info) => info.column.id,
      }),

      columnHelper.accessor('candidate_mean', {
        header: () => 'Candidate',
        cell: (info) => {
          const color = info.row.original.candidate_mean === 0 ? 'error' : 'success';
          return (
            <span className="flex flex-end align-middle">
              <div className={`text-${color} mr-3`}>{info.row.original.candidate_mean === 0 ? 'No' : 'Yes'}</div>
              {info.row.original.annotation && <Warning style={{ color: '#E86F51', width: '18px', height: '18px' }} />}
            </span>
          );
        },
        footer: (info) => info.column.id,
      }),
    ] as Array<ColumnDef<AnswerDataType, unknown>>;
  }, [columnHelper]);

  return (
    <div className="mt-8 sm:mt-12 ">
      {questionGroup.map((group) => {
        return (
          <div className="print:break-before-page">
            <div className="text-black text-xl sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1">{group.group_name}</div>

            {group.answer_data && group.answer_data.length > 0 && (
              <div className="mt-3 md:mt-12 mb-10">
                <Table<AnswerDataType> pageSize={group.answer_data.length} data={group.answer_data} columns={columns1} />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default QuestionGroup;
