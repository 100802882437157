import { Amplify } from 'aws-amplify';
import { Authenticator, useAuthenticator, View, Image, Text, Heading, Button } from '@aws-amplify/ui-react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ApolloProvider } from '@apollo/client';
import Routes from 'routes';
import { apiPlugin, storyblokInit } from '@storyblok/react';
import config from './config';
import { client } from './api';
import { UserProvider, UserWithAttributes } from './context/userContext';
import { GenericProvider } from './context/genericContext';

Amplify.configure(config);

storyblokInit({
  accessToken: process.env.REACT_APP_STORYBLOK_API_KEY,
  use: [apiPlugin],
});

const teamHubTheme = createTheme({
  typography: {
    h6: {
      paddingLeft: '0.2em',
      fontSize: '0.9em',
    },
  },
  palette: {
    primary: {
      main: '#185d6f',
    },
    secondary: {
      main: '#FFC247',
    },
  },
});

const components = {
  Header() {
    return (
      <View textAlign="center">
        <View style={{ padding: '2em' }}>
          <Image width="50%" height="50%" alt="BelleVie" src="logo.png" />
          <Heading data-cy="login-welcome" level={4}>
            Welcome to TeamHub
          </Heading>
        </View>
      </View>
    );
  },
  SignIn: {
    Header() {
      return (
        <View style={{ paddingTop: '1.4em', paddingLeft: '2em', paddingRight: '2em' }}>
          <Heading level={4}>Enter your BelleVie email address</Heading>
          <View style={{ paddingTop: '1.4em' }}>If this is your first time logging in to Team Hub please reset your password first.</View>
        </View>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center" style={{ paddingBottom: '1em' }}>
          <Button fontWeight="normal" onClick={toResetPassword} size="small" variation="link">
            Reset Password
          </Button>
        </View>
      );
    },
  },
  ResetPassword: {
    Header() {
      return <Heading level={4}>Reset your Password:</Heading>;
    },
    Footer() {
      return <Text>Please check your email for a a confirmation code</Text>;
    },
  },
  ConfirmResetPassword: {
    Header() {
      return <Heading level={4}>Confirmation Code</Heading>;
    },
    Footer() {
      const { toSignIn } = useAuthenticator();

      return (
        <View textAlign="center" style={{ paddingBottom: '1em' }}>
          <Button fontWeight="normal" onClick={toSignIn} size="small" variation="link">
            Back to Sign In
          </Button>
        </View>
      );
    },
  },
};

const formFields = {
  signIn: {
    username: {
      labelHidden: false,
      label: 'Email',
      placeholder: 'Enter your email',
    },
    password: {
      labelHidden: false,
      label: 'Password (12 characters with one lower/upper case)',
      placeholder: 'Enter your Password',
    },
  },
  resetPassword: {
    username: {
      labelHidden: false,
      label: 'Email',
      placeholder: 'Enter your email',
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      labelHidden: false,
      placeholder: 'Enter your Code:',
      label: 'Enter your confirmation code, (please check you email)',
      isRequired: false,
    },
    password: {
      labelHidden: false,
      label: 'Password (12 characters with one lower/upper case)',
      placeholder: 'Enter your Password',
    },
    confirm_password: {
      labelHidden: false,
      placeholder: 'Confirm your Password',
    },
  },
};

function App() {
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });

  return (
    <Authenticator formFields={formFields} components={components} hideSignUp>
      {({ user }) => (
        <ThemeProvider theme={teamHubTheme}>
          <ApolloProvider client={client}>
            <UserProvider user={user as UserWithAttributes}>
              <GenericProvider>
                <Routes />
              </GenericProvider>
            </UserProvider>
          </ApolloProvider>
        </ThemeProvider>
      )}
    </Authenticator>
  );
}

export default App;
