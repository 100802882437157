import { getHoursAndMinutes, msToTime } from 'services/helpers';
import { PlaceholderVisit } from '__generated__/graphql';

interface PHVisitProps {
  visit: PlaceholderVisit;
  startDateTime: number;
  index: number;
  onEdit: () => void;
}

const PHVisit = ({ visit, startDateTime, index, onEdit }: PHVisitProps) => {
  const runStartDate = new Date();
  const visitStartDate = new Date();
  const visitEndDate = new Date();

  runStartDate.setHours(new Date(startDateTime).getHours());
  if (visit.startDate) {
    visitStartDate.setHours(new Date(visit.startDate).getHours());
    visitStartDate.setMinutes(new Date(visit.startDate).getMinutes());
  }
  if (visit.endDate) {
    visitEndDate.setHours(new Date(visit.endDate).getHours());
    visitEndDate.setMinutes(new Date(visit.endDate).getMinutes());
  }
  runStartDate.setMinutes(new Date(startDateTime).getMinutes());
  const startTimeDifference = Math.abs(visitStartDate.getTime() - runStartDate.getTime());
  const visitDuration = Math.abs(visitEndDate.getTime() - visitStartDate.getTime());
  const { hours: startHours, minutes: startMinutes } = msToTime(startTimeDifference);
  const { hours: endHours, minutes: endMinutes } = msToTime(visitDuration);

  const visitPosition = (hours: number, minutes: number) => {
    return hours * 60 + minutes;
  };

  return (
    <div
      style={{ top: visitPosition(startHours, startMinutes), height: visitPosition(endHours, endMinutes) - 2 }}
      className="absolute left-0 right-0 bg-warning-600 overflow-hidden z-10 px-2 py-1 cursor-pointer"
      key={index}
      onClick={onEdit}
    >
      <div className="text-md leading-md text-white font-medium">{visit?.name}</div>
      <div className="text-sm leading-sm text-white">
        {visit.startDate ? getHoursAndMinutes(visit.startDate) : ''} - {visit.endDate ? getHoursAndMinutes(visit.endDate) : ''}
      </div>
    </div>
  );
};

export default PHVisit;
