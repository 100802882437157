// import { useState } from 'react';
import Helmet from 'react-helmet';
import { Auth } from 'aws-amplify';
import { LocationBar } from 'components';
import { Code } from '@mui/icons-material';
import { GraphiQL } from 'graphiql';
import type { Fetcher } from '@graphiql/toolkit';
// eslint-disable-next-line import/no-extraneous-dependencies
import { explorerPlugin } from '@graphiql/plugin-explorer';
import 'graphiql/graphiql.min.css';

const GraphQlInspector = () => {
  const fetcher: Fetcher = async (graphQLParams) => {
    const session = await Auth.currentSession();
    const accessToken = session.getIdToken();
    const jwt = accessToken.getJwtToken();
    const data = await fetch(process.env.REACT_APP_API_URL || '', {
      method: 'POST',
      headers: { Authorization: jwt },
      body: JSON.stringify(graphQLParams),
      credentials: 'same-origin',
    });
    const response = await data.json().catch(() => data.text());
    return response;
  };

  const explorer = explorerPlugin({ showAttribution: false });

  return (
    <>
      <Helmet>
        <title>Developer - GraphQl Inspector</title>
      </Helmet>
      <LocationBar section="Developers" page="GraphQl Inspector" Icon={Code} />
      <div style={{ height: '1400px' }}>
        <GraphiQL editorTheme="solarized light" fetcher={fetcher} plugins={[explorer]} />
      </div>
    </>
  );
};

export default GraphQlInspector;
