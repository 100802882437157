import { useFormContext } from 'react-hook-form';
import { InputField } from 'components/Fields/InputField';
import { CheckboxField } from 'components/Fields/CheckboxField';
import { User } from 'types/types';

export default function IncidentUpdate({ disabled, user }: { disabled: boolean; user: User | undefined }) {
  const { watch } = useFormContext();
  const id = watch('id');
  const isPrivate = watch('incident.isPrivate');
  const canEdit = !isPrivate || id === undefined || user?.permissions?.admin;

  return (
    <div className="mt-8 sm:mt-12">
      {canEdit && (
        <>
          <InputField
            testId="form-descriptionOfAccident"
            disabled={disabled}
            label="Description Of Accident"
            placeholder="Description Of Accident"
            name="incident.descriptionOfAccident"
          />
          <InputField
            testId="form-resultingAction"
            disabled={disabled}
            label="Resulting Action"
            placeholder="Resulting Action"
            name="incident.resultingAction"
          />
          <InputField testId="form-address" disabled={disabled} label="Address of Incident" placeholder="Address of Incident" name="incident.address" />
          <InputField testId="form-postCode" disabled={disabled} label="PostCode" placeholder="PostCode" name="incident.postCode" />
        </>
      )}
      <CheckboxField testId="form-isPrivate" disabled={disabled} label="Is Private" name="incident.isPrivate" />
    </div>
  );
}
