import { SupportWorkerSelectField } from 'components/Fields/SupportWorkerSelectField';
import { DateField } from 'components/Fields/DateField';

export default function FormSharedUpdate({ disabled }: { disabled: boolean }) {
  return (
    <div className="mt-8 sm:mt-12">
      <SupportWorkerSelectField
        testId="form-observing-person"
        searchable={true}
        disabled={disabled}
        label="Observing Person"
        name="observingPersonId"
        showAll={disabled}
      />
      <SupportWorkerSelectField
        testId="form-support-worker"
        searchable={true}
        disabled={disabled}
        label="Support Worker"
        name="supportWorkerId"
        showAll={disabled}
      />

      <DateField testId="form-observing-date" label="Observation Date" name="dateTimeOfObservation" disabled={disabled} />
    </div>
  );
}
