import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Helmet from 'react-helmet';
import { useUser, UserContextProps } from 'context/userContext';
import ConnectWithoutContact from '@mui/icons-material/ConnectWithoutContact';
import { LocationBar, Loading } from 'components';
import { useGetCandidates, useImportCandidates, useSaveCandidateTable, useSendCandidateEmail, useSendCandidateSms } from 'api/hooks/useCandidates';
import { useGetSupportWorkers } from 'api/hooks/useSupportWorkers';
import Message from 'components/Message';
import { Candidate, CandidateTracking, CurrentStatus, SendCandidateMessagesInput, SendSmsInput, Team } from '__generated__/graphql';
import { ColumnDef, RowSelectionState, createColumnHelper } from '@tanstack/react-table';
import Table from 'components/Table';
import { AdjustmentsHorizontalIcon, ArrowDownTrayIcon, ArrowUpTrayIcon, EnvelopeIcon, PencilIcon, PrinterIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import ToggleButtons from 'components/ToggleButtons';
import Search from 'components/Search/index';
import Filters from 'components/Filters';
import { CSVLink } from 'react-csv';
import { useReactToPrint } from 'react-to-print';
import { SizeType, SelectOption } from 'types/types';
import { useGetTemplates } from 'api/hooks/useTemplates';
import { useGetTeamOptions } from 'api/hooks/useGetTeams';
import { MapCandidate, MapFile } from './utils/FileMapper';
import CandidateMessaging from './components/CandidateMessaging';
import CandidatesStats from './components/CandidatesStats';
import { currentStatuses } from './types';
import StatusSelect from './components/StatusSelect';

export default function CandidateList() {
  const { user, userLoading } = useUser() as UserContextProps;

  const navigate = useNavigate();
  const { candidates, candidateLoading } = useGetCandidates({});
  const { teams, teamsLoading } = useGetTeamOptions(null, true);
  const { supportWorkers, loading } = useGetSupportWorkers({});
  const { saveCandidateTable } = useSaveCandidateTable();
  const { templates, templatesLoading } = useGetTemplates();
  const { sendCandidateMessages, mutationSendCandidateMessages } = useSendCandidateEmail();
  const { sendSms, mutationSendSms } = useSendCandidateSms();
  const { importCandidates, mutationImportCandidates } = useImportCandidates();
  const teamOptions = teams?.map((t) => ({ value: t.teamId, label: t.teamName }));
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const [filterStatus, setFilterStatus] = useState('Open');
  const [currentStatus, setCurrentStatus] = useState('all');
  const [team, setTeam] = useState('all');
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
  const [showMessageModal, setShowMessageModal] = useState<boolean>(false);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [selectedCandidates, setSelectedCandidates] = useState<Candidate[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState([
    { name: 'teams', selectedValue: { value: 'all', label: 'All' }, values: [{ value: 'all', label: 'All' }, ...(teamOptions || [])] },
    { name: 'currentStatus', selectedValue: { value: 'all', label: 'All' }, values: currentStatuses },
  ]);

  useEffect(() => {
    const teamSearch = searchParams.get('teams');
    const statusSearch = searchParams.get('currentStatus');
    const newFilters = filters;
    if (teamSearch) {
      setTeam(teamSearch);
      const teamOption = teams?.map((t) => ({ value: t.teamId, label: t.teamName })).find((t) => t.value === teamSearch);
      if (teamOption) {
        newFilters[0].selectedValue = teamOption;
      }
    }
    if (statusSearch) {
      setCurrentStatus(statusSearch);
      const currentStatusOption = currentStatuses.find((t) => t.value === statusSearch);
      if (currentStatusOption) {
        newFilters[1].selectedValue = currentStatusOption;
      }
    }
    setFilters(newFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teams]);

  const columnVisibility = {
    firstName: true,
    lastName: true,
    workshop: window.innerWidth > 640,
    progress: window.innerWidth > 1280,
    phone: window.innerWidth > 1280,
    postCode: window.innerWidth > 1280,
    teamName: window.innerWidth > 1280,
    circleName: window.innerWidth > 1280,
    dateCreated: false,
    dateUpdated: false,
    currentStatus: true,
  };
  const tableRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });

  useEffect(() => {
    const newFilters = filters;
    const teamsOptions = teams?.map((t) => ({ value: t.teamId, label: t.teamName }));
    newFilters[0].values = [{ value: 'all', label: 'All' }, ...teamsOptions];
    setFilters(newFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teams]);

  const onButtonClick = (candidate: Candidate) => {
    navigate(`/candidates/${candidate.id}`);
  };

  const onWorkshopClick = () => {
    navigate('/workshops');
  };

  const onTemplatesClick = () => {
    navigate('/templates');
  };

  const changeFilter = (f: string) => {
    setFilterStatus(f);
    setCurrentStatus('all');
    onFilterChange('currentStatus', { value: 'all', label: 'All' });
  };

  const filterByStatus = useCallback(
    (candidate: Candidate) => {
      if (filterStatus === 'All') return true;
      if (filterStatus === 'Open' && candidate.status === 'Open') return true;
      if (filterStatus === 'Closed' && candidate.status === 'Closed') return true;
      if (filterStatus === 'Hired' && candidate.status === 'Hired') return true;

      return false;
    },
    [filterStatus],
  );

  const filterByName = useCallback(
    (candidate: Candidate) => {
      if (candidate && candidate.firstName?.toLowerCase().includes(search.toLowerCase())) return true;
      if (candidate && candidate.lastName?.toLowerCase().includes(search.toLowerCase())) return true;
      return false;
    },
    [search],
  );

  const filterByTeam = useCallback(
    (candidate: Candidate) => {
      if (team === 'all') return true;
      if (candidate && candidate.teamId === team) return true;
      return false;
    },
    [team],
  );

  const filterByCurrentStatus = useCallback(
    (candidate: Candidate) => {
      if (currentStatus.toLowerCase() === 'all') return true;
      if (candidate && candidate.currentStatus?.toLowerCase() === currentStatus.toLowerCase()) return true;
      return false;
    },
    [currentStatus],
  );

  const onSendEmail = (input: SendCandidateMessagesInput) => {
    sendCandidateMessages({
      variables: {
        input,
      },
    });
    setOpenSnack(true);
  };

  const onSendSms = (input: SendSmsInput) => {
    sendSms({
      variables: {
        input,
      },
    });
    setOpenSnack(true);
  };
  const onCandidateStatusUpdate = async (candidate: Candidate, status: string) => {
    const newCandidate: Candidate = MapCandidate({ ...candidate, currentStatus: status });
    const data = newCandidate as Partial<Candidate>;
    delete data?.previousApplications;
    await saveCandidateTable({
      variables: {
        input: {
          ...data,
        },
      },
    });
    setOpenSnack(true);
  };

  const handleFileUpload = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e?.target && e?.target?.result) {
        const candidatesToImport = MapFile(e?.target?.result?.toString(), user?.profile || '');

        if (candidatesToImport && candidatesToImport.length > 0) {
          importCandidates({
            variables: {
              input: {
                candidates: candidatesToImport,
              },
            },
          });
          setOpenSnack(true);
        }
      }
    };
    reader.readAsText(file);
  };

  const filteredCandidates = useMemo(() => {
    return candidates
      .filter((sw) => filterByName(sw))
      .filter((sw) => filterByCurrentStatus(sw))
      .filter((sw) => filterByStatus(sw))
      .filter((sw) => filterByTeam(sw))
      .sort((x, y) => (y.dateCreated || 0) - (x.dateCreated || 0));
  }, [candidates, filterByCurrentStatus, filterByName, filterByStatus, filterByTeam]);

  const csv = useMemo(() => {
    const selectedIndexes = Object.keys(rowSelection);
    const csvCandidate = selectedIndexes?.length > 0 ? selectedIndexes.map((i: string) => filteredCandidates[parseInt(i, 10)]) : filteredCandidates;
    return csvCandidate.map((c) => {
      const worker = supportWorkers.find((sw) => sw.id === c.owner);

      return {
        ...c,
        dateCreated: format(c.dateCreated ?? 0, 'dd/MM/yy'),
        dateUpdated: format(c.dateUpdated ?? 0, 'dd/MM/yy'),
        dateImported: format(c.dateImported ?? 0, 'dd/MM/yy'),
        workshop: JSON.stringify(c.workshop).replace(/"/g, "'"),
        scorecard: JSON.stringify(c.scorecard).replace(/"/g, "'"),
        history: JSON.stringify(c.history).replace(/"/g, "'"),
        tracking: JSON.stringify(c.tracking).replace(/"/g, "'"),
        owner: worker?.fullName,
      };
    });
  }, [filteredCandidates, rowSelection, supportWorkers]);

  const rows = filteredCandidates.map((c) => {
    const worker = supportWorkers.find((sw) => sw.id === c.owner);
    return {
      ...c,
      owner: worker?.fullName,
    };
  });

  const onSendMessageClick = () => {
    const selectedIndexes = Object.keys(rowSelection);
    setSelectedCandidates(selectedIndexes.map((i: string) => filteredCandidates[parseInt(i, 10)]));
    setShowMessageModal(true);
  };

  const HeaderActions = () => {
    return (
      <button type="button" onClick={onSendMessageClick} aria-label="Edit" className="m-0 p-0">
        <EnvelopeIcon className="w-6 h-6 text-gray-500 hover:text-primary-600" />
      </button>
    );
  };

  const RowActions = ({ row }: { row: Candidate }) => {
    return (
      <button type="button" onClick={() => onButtonClick(row)} aria-label="Edit" className="m-0 p-0">
        <PencilIcon className="w-6 h-6 text-gray-500 hover:text-primary600" />
      </button>
    );
  };

  const Progress = ({ tracking }: { tracking: CandidateTracking }) => {
    let percentage = 0;
    if (tracking.autoWelcomeTextAndEmailSent?.status) {
      percentage = 10;
    }
    if (tracking.initialCallArranged?.status) {
      percentage = 20;
    }
    if (tracking.initialCallDone?.status) {
      percentage = 30;
    }
    if (tracking.workshopInviteSent?.status) {
      percentage = 40;
    }
    if (tracking.workshopAttended?.status) {
      percentage = 60;
    }
    if (tracking.shadowAgreed?.status) {
      percentage = 70;
    }
    if (tracking.shadowSuccess?.status) {
      percentage = 90;
    }
    if (tracking.jobOfferAccepted?.status) {
      percentage = 100;
    }
    return (
      <div className="flex items-center gap-3">
        <div className="w-14 bg-gray-100 rounded-lg h-2">
          <div className="bg-primary-600 rounded-lg h-2" style={{ width: `${percentage}%` }} />
        </div>
        <div className="text-gray-700 text-sm leading-sm font-medium">{percentage}%</div>
      </div>
    );
  };

  const Region = ({ teamId }: { teamId: string }) => {
    const teamRow = teams?.find((t: Team) => t.teamId === teamId);
    return <div className="text-gray-700 text-sm leading-sm">{teamRow?.circleName ?? ''}</div>;
  };

  const columnHelper = createColumnHelper<Candidate>();

  const columns1 = [
    {
      id: 'select-col',
      header: ({ table }) => (
        <div className="flex items-center">
          <input
            onChange={table.getToggleAllRowsSelectedHandler()}
            checked={table.getIsAllRowsSelected()}
            type="checkbox"
            className="w-4 h-4 text-primary-300 bg-gray-100 border-gray-300 rounded cursor-pointer"
          />
        </div>
      ),
      cell: ({ row }) => (
        <div className="flex items-center">
          <input
            type="checkbox"
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
            className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 rounded cursor-pointer"
          />
        </div>
      ),
    },
    columnHelper.accessor('firstName', {
      header: () => 'Candidate',
      cell: (info) => (
        <div data-cy="list-name" className="">
          <div className="text-md leading-md text-gray-900 font-semibold">
            {info.renderValue()} {info.row.original.lastName}
          </div>
          <div className="text-sm leading-sm text-gray-700 hidden sm:block">{info.row.original.email}</div>
        </div>
      ),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('progress', {
      header: () => 'Progress',
      cell: (info) => <Progress tracking={info.row.original.tracking ?? {}} />,
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.accessor('phone', {
      header: () => 'Phone number',
      cell: (info) => <span data-cy="list-phone">{info.renderValue()}</span>,
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.accessor('postCode', {
      header: () => 'Area',
      cell: (info) => <span data-cy="list-postCode">{info.renderValue()}</span>,
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.accessor('circleName', {
      header: () => 'Region',
      cell: (info) => <Region teamId={info.row.original.teamId ?? ''} />,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('teamName', {
      header: () => 'Team',
      cell: (info) => info.renderValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('dateCreated', {
      header: () => 'Created',
      cell: (info) => format(info.renderValue() ?? 0, 'dd/MM/yy'),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('dateUpdated', {
      header: () => 'Updated',
      cell: (info) => format(info.renderValue() ?? 0, 'dd/MM/yy'),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('workshop', {
      header: () => 'Workshop',
      cell: (info) => (info.row.original.workshop?.date ? format(info.row.original.workshop?.date, 'EEEE dd MMM: HH:mm') : ''),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('currentStatus', {
      header: () => 'Status',
      cell: (props) => (
        <div data-cy="list-current-status" className=" 		">
          <StatusSelect
            candidate={props.row.original}
            status={props.row.original.currentStatus as CurrentStatus}
            onCandidateStatusUpdate={onCandidateStatusUpdate}
          />
        </div>
      ),
      footer: (info) => info.column.id,
    }),
    columnHelper.display({
      id: 'actions',
      header: () => <HeaderActions />,
      cell: (props) => <RowActions row={props.row.original} />,
    }),
  ] as Array<ColumnDef<Candidate, unknown>>;

  const onFilterChange = (name: string, value: SelectOption) => {
    const newFilters = [...filters];
    const filterIndex = newFilters.findIndex((f) => f.name === name);
    newFilters[filterIndex].selectedValue = value;
    setFilters(newFilters);
  };

  const onSaveFilters = () => {
    const newSearchParamsArray = filters.map((f) => ({ [f.name]: f.selectedValue.value }));
    setSearchParams(Object.assign({}, ...newSearchParamsArray));
    setTeam(filters[0].selectedValue.value);
    setCurrentStatus(filters[1].selectedValue.value);
    setShowFiltersModal(false);
  };

  const handleImportFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;

    if (fileList && fileList.length > 0) {
      handleFileUpload(fileList[0]);
    }
  };

  if (
    loading ||
    candidateLoading ||
    mutationImportCandidates.loading ||
    teamsLoading ||
    mutationSendCandidateMessages.loading ||
    candidateLoading ||
    templatesLoading ||
    userLoading ||
    mutationSendSms.loading
  ) {
    return <Loading />;
  }
  return (
    <>
      <Helmet>
        <title>Candidates</title>
      </Helmet>
      <LocationBar section="Candidates" page="Candidate List" Icon={ConnectWithoutContact} />
      <Message response={[mutationImportCandidates, mutationSendCandidateMessages, mutationSendSms]} openSnack={openSnack} setOpenSnack={setOpenSnack} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-row justify-between items-center">
          <div className="text-display-sm sm:text-display-lg leading-display-sm sm:leading-display-lg sm:tracking-display-lg text-black font-medium">
            Candidates
          </div>

          <div>
            <label
              htmlFor="custom-input"
              className="text-gray-500 text-sm leading-sm font-semibold flex items-center hover:no-underline cursor-pointer md:hidden"
            >
              <input type="file" id="custom-input" onChange={handleImportFile} hidden />
              <ArrowDownTrayIcon className="w-5 h-5 mr-2" />
              Import CSV
            </label>
          </div>
          <div>
            <button
              type="button"
              className=" border flex border-gray-300 text-gray-700 text-md leading-md px-4 py-2.5 rounded-lg shadow-xs md:hidden"
              onClick={() => navigate('/candidates/create')}
            >
              Create Candidate
            </button>
          </div>
          <div className="hidden md:flex items-center gap-3 mt-4 sm:mt-0">
            <label htmlFor="custom-input" className="text-gray-500 text-sm leading-sm font-semibold flex items-center hover:no-underline cursor-pointer">
              <input type="file" id="custom-input" onChange={handleImportFile} hidden />
              <ArrowDownTrayIcon className="w-5 h-5 mr-2" />
              Import CSV
            </label>
            <button
              type="button"
              data-cy="create-candidate-button"
              className=" border flex border-gray-300 text-gray-700 text-md leading-md px-4 py-2.5 rounded-lg shadow-xs"
              onClick={() => navigate('/candidates/create')}
            >
              Create Candidate
            </button>
            <button
              type="button"
              className="relative border border-gray-300 text-gray-700 text-md leading-md px-4 py-2.5 rounded-lg shadow-xs"
              onClick={onTemplatesClick}
            >
              Templates
            </button>
            <button type="button" className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md" onClick={onWorkshopClick}>
              Workshops
            </button>
          </div>
        </div>
        <div className="md:hidden flex justify-between items-center gap-4 mt-4">
          <button
            type="button"
            className="relative border border-gray-300 text-gray-700 text-md leading-md px-4 py-2.5 rounded-lg shadow-xs w-full"
            onClick={onTemplatesClick}
          >
            Templates
          </button>
          <button type="button" className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md w-full" onClick={onWorkshopClick}>
            Workshops
          </button>
        </div>
        <CandidatesStats candidates={candidates} />
        <div className="flex flex-col lg:flex-row items-center justify-between mt-8 md:mt-16">
          <div className="flex flex-col md:flex-row items-center gap-4 md:gap-9 w-full md:w-fit">
            <ToggleButtons
              buttons={['All', 'Open', 'Closed', 'Hired']}
              selectedButton={filterStatus}
              onSelectButton={changeFilter}
              size={window.innerWidth > 768 ? SizeType.Fit : SizeType.Full}
            />
            <Search value={search} onSearch={setSearch} />
            <div className="flex flex-col md:flex-row items-center gap-3 w-full md:w-fit">
              <button
                type="button"
                className={`relative border ${
                  team === 'all' && currentStatus === 'all' ? 'border-gray-300' : 'border-gray-900'
                }  text-gray-700 text-md leading-md flex items-center px-4 py-2.5 rounded-lg shadow-xs justify-center w-full md:w-fit`}
                onClick={() => setShowFiltersModal(true)}
              >
                <AdjustmentsHorizontalIcon className="w-5 h-5 mr-2" />
                Filters
                {(team !== 'all' || currentStatus !== 'all') && (
                  <span className="absolute bg-gray-900 h-5 w-5 text-white rounded-full -top-2 -right-2 flex items-center justify-center text-xs font-semibold">
                    {team !== 'all' && currentStatus !== 'all' ? '2' : '1'}
                  </span>
                )}
              </button>
              {team !== 'all' && (
                <div className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center">
                  {teamOptions?.find((t) => t.value === team)?.label}
                  <button
                    type="button"
                    aria-label="remove team filter"
                    className="text-gray-500"
                    onClick={() => {
                      onFilterChange('teams', { value: 'all', label: 'All' });
                      const newSearchParamsArray = filters.map((f) => ({ [f.name]: f.selectedValue.value }));
                      setSearchParams(Object.assign({}, ...newSearchParamsArray));
                      setTeam('all');
                    }}
                  >
                    <XMarkIcon className="w-4 h-4 ml-2" />
                  </button>
                </div>
              )}
              {currentStatus !== 'all' && (
                <div className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center">
                  {currentStatuses.find((t) => t.value === currentStatus)?.label}
                  <button
                    type="button"
                    aria-label="remove team filter"
                    className="text-gray-500"
                    onClick={() => {
                      onFilterChange('currentStatus', { value: 'all', label: 'All' });
                      const newSearchParamsArray = filters.map((f) => ({ [f.name]: f.selectedValue.value }));
                      setSearchParams(Object.assign({}, ...newSearchParamsArray));
                      setCurrentStatus('all');
                    }}
                  >
                    <XMarkIcon className="w-4 h-4 ml-2" />
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between lg:justify-normal gap-3 md:gap-9 w-full lg:w-fit mt-6 lg:mt-0">
            <button type="button" className="text-gray-500 text-sm leading-sm font-semibold flex items-center hover:no-underline" onClick={handlePrint}>
              <PrinterIcon className="w-5 h-5 mr-2" />
              Print
            </button>
            <CSVLink filename="candidates.csv" data={csv} className="text-gray-500 text-sm leading-sm font-semibold flex items-center hover:no-underline">
              <ArrowUpTrayIcon className="w-5 h-5 mr-2" />
              Export CSV
            </CSVLink>
          </div>
        </div>
        <div className="mt-3 md:mt-12 mb-10" ref={tableRef}>
          {rows && rows.length > 0 && (
            <Table<Candidate>
              data={rows}
              columns={columns1}
              columnVisibility={columnVisibility}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
            />
          )}
        </div>
      </div>
      {showFiltersModal && (
        <Filters filters={filters} onFilterChange={onFilterChange} onCloseModal={() => setShowFiltersModal(false)} onSaveFilters={onSaveFilters} />
      )}
      {showMessageModal && (
        <CandidateMessaging
          onClose={() => setShowMessageModal(false)}
          candidates={candidates}
          selectedCandidates={selectedCandidates}
          templates={templates}
          onSendEmail={onSendEmail}
          onSendSms={onSendSms}
        />
      )}
    </>
  );
}
