import { Shift, ReportingHours, ShiftRun } from '__generated__/graphql';
import InfoIcon from '@mui/icons-material/Info';
import { formatTime, getDurationInMinutes, setTime, formatHoursAndMinutes, differenceBetweenDates } from 'services/helpers';
import { User } from 'types/types';
import {
  ShiftRunTypeEnum,
  VisitStatus,
  calculateBankedHoursOnVisits,
  calculateMilesInCarTravelled,
  calculateMilesTravelled,
  calculateTotalHoursInActivities,
} from './service';

interface ShiftHeadingProps {
  user: User;
  supportWorkerId: string;
  reportingHours: ReportingHours[];
}

const remainingBankedHours = (supportWorkerId: string, shifts: Shift[], shiftLength: number, sr?: ShiftRun | null) => {
  let bankedHours = 0;

  if (sr) {
    if (shifts?.length === 0 && bankedHours === 0) bankedHours = shiftLength;

    const hoursAtStartOfShift = shifts?.length > 0 ? differenceBetweenDates(setTime(sr.startDateTime), setTime(shifts[0].startDateTime)) : 0;
    const hoursAtEndOfShift = shifts?.length > 0 ? differenceBetweenDates(setTime(shifts[shifts.length - 1].endDateTime), setTime(sr.endDateTime)) : 0;

    if (hoursAtStartOfShift > 0) {
      bankedHours += hoursAtStartOfShift;
    }

    if (hoursAtEndOfShift > 0) {
      bankedHours += hoursAtEndOfShift;
    }
  }

  return formatHoursAndMinutes(bankedHours);
};

const ShiftHeading = ({ user, reportingHours, supportWorkerId }: ShiftHeadingProps) => {
  const shifts = reportingHours.map((rhp: ReportingHours) => rhp.visit) as Shift[];
  const shiftRun = reportingHours[0]?.shift;
  const start = shiftRun?.startDateTime || 0;
  const end = shiftRun?.endDateTime || 0;
  const shiftLength = getDurationInMinutes(start, end);
  const shiftLengthFormatted = formatHoursAndMinutes(shiftLength);

  const filtered = shifts.filter((s) => s.status === VisitStatus.active && s?.supportWorkerId === supportWorkerId);
  const remainingTotal = filtered.length > 0 ? remainingBankedHours(supportWorkerId, filtered, shiftLength, shiftRun) : { hours: 0, minutes: 0 };

  const milesTravelled = calculateMilesTravelled(shifts);
  const milesTravelledInCar = calculateMilesInCarTravelled(shifts);
  const bankedHours = calculateBankedHoursOnVisits(reportingHours);
  const bankedHoursAndMinutes = formatHoursAndMinutes(bankedHours);
  const activitiesTotal = calculateTotalHoursInActivities(shifts);
  const activitiesHoursAndMinutes = formatHoursAndMinutes(activitiesTotal);
  let shiftType = '';

  if (shiftRun?.ownerId === supportWorkerId && shiftRun?.type === ShiftRunTypeEnum.agreed) {
    shiftType = 'Agreed';
  } else if (shiftRun?.ownerId !== supportWorkerId && shiftRun?.type === ShiftRunTypeEnum.agreed) {
    shiftType = 'Agreed Cover';
  } else if (shiftRun?.type === ShiftRunTypeEnum.oneoff && shiftRun.ownerId === supportWorkerId) {
    shiftType = 'Banked Hours';
  } else if (shiftRun?.type === ShiftRunTypeEnum.oneoff && shiftRun.ownerId !== supportWorkerId) {
    shiftType = 'Oneoff';
  }

  return (
    <>
      <div className="text-xl font-semibold text-primary-700">
        {formatTime(start, 'EEE dd MMM')}: {formatTime(start, 'HH:mm')} - {formatTime(end, 'HH:mm')}
        {user?.permissions?.admin && (
          <InfoIcon sx={{ cursor: 'pointer', float: 'right' }} color="info" onClick={() => navigator?.clipboard?.writeText(JSON.stringify(reportingHours))} />
        )}
      </div>
      <>
        <div className="text-md leading-md text-gray-900 mt-3">
          <span className="font-semibold">{shiftType} Shift Duration:</span> {shiftLengthFormatted.hours} hrs {shiftLengthFormatted.minutes} mins
        </div>
        <div className="text-md leading-md text-gray-800 font-medium mt-1">
          <span className="font-semibold">Hours on Activity:</span> {activitiesHoursAndMinutes.hours} hrs {activitiesHoursAndMinutes.minutes} mins
        </div>
        <div className="text-md leading-md text-gray-800 font-medium mt-1">
          <span className="font-semibold">Travel Mileage:</span> £{(milesTravelled * 0.45).toFixed(2)}
        </div>
        <div className="text-md leading-md text-gray-800 font-medium mt-1">
          <span className="font-semibold">Person Mileage:</span> £{(milesTravelledInCar * 0.45).toFixed(2)}
        </div>
        {shiftRun?.type === ShiftRunTypeEnum.oneoff && (
          <div className="text-md leading-md text-gray-800 font-medium mt-1">
            <span className="font-semibold">Banked Hours Consumed:</span> {bankedHoursAndMinutes.hours} hours {bankedHoursAndMinutes.minutes} mins
          </div>
        )}
        {shiftRun?.type === ShiftRunTypeEnum.agreed && shiftRun?.ownerId !== supportWorkerId && (
          <div className="text-md leading-md text-gray-800 font-medium mt-1">
            <span className="font-semibold">Banked Hours Consumed:</span> {bankedHoursAndMinutes.hours} hours {bankedHoursAndMinutes.minutes} mins
          </div>
        )}
        {shiftRun?.type === ShiftRunTypeEnum.agreed && shiftRun?.ownerId === supportWorkerId && (
          <div className="text-md leading-md text-gray-900 mt-2 bg-yellow-50 pt-2 pb-2">
            <span className="font-semibold">Banked Hours (to make up):</span> {remainingTotal.hours} hrs {remainingTotal.minutes} mins
          </div>
        )}
      </>
      <div className="w-full h-px bg-gray-100 my-4" />
    </>
  );
};

export default ShiftHeading;
