import { AgreedRota, AgreedRotaSearch } from '__generated__/graphql';
import { gql, useQuery, useMutation } from '@apollo/client';

export const GET_AGREED_ROTA = gql`
  query GetAgreedRotas($query: AgreedRotaSearch) {
    GetAgreedRotas(query: $query) {
      id
      teamId
      startRotaDateTime
      endRotaDateTime
    }
  }
`;

export const SAVE_AGREED_ROTA = gql`
  mutation SaveAgreedRota($input: AgreedRotaInput) {
    SaveAgreedRota(input: $input) {
      message
      date
    }
  }
`;

export const REMOVE_AGREED_ROTA = gql`
  mutation RemoveAgreedRota($input: RemoveAgreedRotaInput) {
    RemoveAgreedRota(input: $input) {
      message
      date
    }
  }
`;

export const PUBLISH_AGREED_ROTA = gql`
  mutation PublishAgreedRota($input: PublishAgreedRotaInput) {
    PublishAgreedRota(input: $input) {
      message
      date
    }
  }
`;

interface GetAgreedRotaResponse {
  GetAgreedRotas: AgreedRota[];
}

const refetch = (input: Record<string, unknown>) => ({
  refetchQueries: [
    {
      query: GET_AGREED_ROTA,
      variables: {
        query: {
          ...input,
        },
      },
    },
  ],
});

export const useGetAgreedRota = (query: AgreedRotaSearch) => {
  const { loading: agreedRotasLoading, data } = useQuery<GetAgreedRotaResponse>(GET_AGREED_ROTA, {
    variables: {
      query: {
        ...query,
      },
    },
  });
  return { agreedRotas: data?.GetAgreedRotas || [], agreedRotasLoading };
};

export const useSaveAgreedRota = (query: AgreedRotaSearch) => {
  const [saveAgreedRota, mutationSaveAgreedRota] = useMutation(SAVE_AGREED_ROTA, refetch(query));
  return { saveAgreedRota, mutationSaveAgreedRota };
};

export const useRemoveAgreedRota = (query: AgreedRotaSearch) => {
  const [removeAgreedRota, mutationRemoveAgreedRota] = useMutation(REMOVE_AGREED_ROTA, refetch(query));
  return { removeAgreedRota, mutationRemoveAgreedRota };
};

export const usePublishAgreedRota = () => {
  const [publishAgreedRota, mutationPublishAgreedRota] = useMutation(PUBLISH_AGREED_ROTA);

  return { publishAgreedRota, mutationPublishAgreedRota };
};
