export interface ChecklistItem {
  id: string | undefined | null;
  checked: boolean;
  status: string | undefined | null;
  startDateTime: number | undefined | null;
  endDateTime: number | undefined | null;
  type: string | undefined | null;
  cancelledDateTime: number | undefined | null;
  whoCancelled: string | undefined | null;
  oneOffVisitReason: string | undefined | null;
  oneOffVisitNote: string | undefined | null;
  travelWithCustomerMileage: number | undefined | null;
  travelWithCustomerDestination: string | undefined | null;
}

export const columnVisibility = {
  dateUpdated: window.innerWidth > 768,
  name: true,
  subject: window.innerWidth > 640,
  type: true,
};

export interface ThriveBookingChecklistItem {
  id: string | undefined | null;
  checked: boolean;
  status: string | undefined | null;
  startDateTime: number | undefined | null;
  endDateTime: number | undefined | null;
  customerName: string | undefined | null;
  price: number | undefined | null;
  product: string | undefined | null;
  partnerName: string | undefined | null;
}

export interface ExpenseChecklistItem {
  id: string | undefined | null;
  checked: boolean;
  expenseDate: string | undefined | null;
  description: string | undefined | null;
  comment: string | undefined | null;
  totalAmount: number | undefined | null;
  externalId: string | undefined | null;
  employeeFullName: string | undefined | null;
}
