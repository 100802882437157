import { useState } from 'react';
import { SelectOption } from 'types/types';
import ReactMultiSelect from 'components/MultiSelect';
import 'react-quill/dist/quill.snow.css';

interface AddCandidatesProps {
  candidates: SelectOption[];
  addedCandidates: SelectOption[];
  onClose: () => void;
  onAdd: (addedCandidates: SelectOption[]) => void;
}

export default function AddCandidates({ candidates, addedCandidates, onClose, onAdd }: AddCandidatesProps) {
  const [selectedCandidates, setCandidates] = useState<SelectOption[]>(addedCandidates);

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-[1200] outline-none focus:outline-none">
        <div className="relative w-auto mx-auto max-w-5xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none p-6 max-h-screen md:max-h-[70vh] overflow-y-auto md:w-[480px] lg:w-[680px]">
            <div className="text-gray-900 text-lg leading-lg font-semibold mb-5">Edit candidates</div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Select candidates</div>
              <div className="mt-2">
                <ReactMultiSelect testId="candidates-type" options={candidates} onChange={setCandidates} selectedValue={selectedCandidates} searchable />
              </div>
            </div>
            <div className="flex justify-between items-center mt-16 md:mt-12">
              <button type="button" className="text-gray-500 font-semibold text-md leading-md" onClick={onClose}>
                Cancel
              </button>
              <button
                type="button"
                data-cy="save-add-candidate-button"
                className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
                onClick={() => onAdd(selectedCandidates)}
              >
                Add candidates
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-gray-900" />
    </>
  );
}
