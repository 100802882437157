import { useState, useEffect, useCallback } from 'react';
import Helmet from 'react-helmet';
import { getTime, add } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { EmojiPeople } from '@mui/icons-material';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { Message, LocationBar, Loading, FormLabel, ToggleButtons } from 'components';
import { SelectField } from 'components/Fields/SelectField';
import { InputField } from 'components/Fields/InputField';
import { RadioField } from 'components/Fields/RadioField';
import { TeamSelectField } from 'components/Fields/TeamSelectField';
import { DateField } from 'components/Fields/DateField';
import { useGetCustomers } from 'api/hooks/useCustomers';
// import { SupportedPersonSelectField } from 'components/Fields/SupportedPersonSelectField';
import ReactSelect from 'components/Select';
import { useCreateInvoice } from 'api/hooks/useInvoice';
import { getLastDayOfPayroll, getFirstDayOfPayroll, getPeriodsForYear, formatTime, numberDaysBetweenDates } from 'services/helpers';
import { useGetCustomerPackages } from 'api/hooks/useCustomerPackages';
import { useGetBookingsLazy } from 'api/hooks/useThriveBookings';
import { PackagePriceFundingSourceEnum } from '__generated__/graphql';
import { SelectOption } from 'types/types';

const InvoiceCreate = () => {
  const fundingSources = [
    { label: 'Other', value: 'Other' },
    { label: 'Local Authority', value: 'LocalAuthority' },
  ];
  const [validation, setValidation] = useState<string | null>(null);
  const [fundingType, setFundingType] = useState<string>('Other');
  const { customerPackages } = useGetCustomerPackages({});
  const { saveInvoice, mutationSaveInvoice } = useCreateInvoice();
  const { customers, loading } = useGetCustomers({ showAll: true });
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const { getBookingsForThrive, bookings } = useGetBookingsLazy();
  const invoiceTypes = ['Care', 'Thrive'];

  const periods = getPeriodsForYear().map((p) => {
    return { label: formatTime(p, 'do MMMM, yyyy'), value: getFirstDayOfPayroll(p).toString() };
  });

  const [period, setPeriod] = useState<SelectOption>(periods[0]);

  const methods = useForm<{
    teamId: string;
    name: string;
    customerId: string;
    dueDate: number;
    invoiceDate: number;
    payrollStartDate: number;
    payrollEndDate: number;
    invoiceNumber: string;
    invoiceType: string;
    thriveCustomerId: string;
  }>({
    mode: 'onChange',
    defaultValues: {
      invoiceType: invoiceTypes[0],
    },
  });

  const { handleSubmit, watch, setValue } = methods;
  const navigate = useNavigate();
  const customer = watch('customerId');
  const thriveCustomer = watch('thriveCustomerId');
  const payrollStartDate = watch('payrollStartDate');
  const invoiceType = watch('invoiceType');

  const resetDates = useCallback(
    (startDate: number) => {
      const newStartDate = getFirstDayOfPayroll(startDate);
      const newEndDate = getLastDayOfPayroll(newStartDate);
      const invoiceDate = getTime(add(newEndDate, { days: 3 }));
      const dueDate = getTime(add(newEndDate, { days: 10 }));
      setValue('payrollStartDate', newStartDate);
      setValue('payrollEndDate', newEndDate);
      setValue('invoiceDate', invoiceDate);
      setValue('dueDate', dueDate);
    },
    [setValue],
  );

  const changePeriod = (option: SelectOption) => {
    setPeriod(option);
    const startDate = parseFloat(option.value.toString());
    const newStartDate = getFirstDayOfPayroll(startDate);
    resetDates(newStartDate);
  };

  const changeFundingType = (option: string) => {
    setFundingType(option);
    setPeriod(periods[0]);
    const startDate = option === PackagePriceFundingSourceEnum.LocalAuthority ? Date.now() : parseFloat(periods[0].value);
    const newStartDate = getFirstDayOfPayroll(startDate);
    resetDates(newStartDate);
  };

  useEffect(() => {
    if (period) {
      const startDate = parseFloat(period.value);
      const newStartDate = getFirstDayOfPayroll(startDate);
      resetDates(newStartDate);
      const newEndDate = getLastDayOfPayroll(newStartDate);

      getBookingsForThrive({
        variables: {
          query: {
            startDateTime: newStartDate,
            endDateTime: newEndDate,
          },
        },
      });
    }
  }, [period, resetDates, getBookingsForThrive]);

  const thriveCustomers = bookings.map((booking) => {
    const name = `${booking?.customer?.firstName} ${booking?.customer?.surname}`;
    return { label: name, value: booking?.customer?.id || '' };
  });

  useEffect(() => {
    const filtered = customers.find((c) => c.id === customer);

    if (filtered) {
      setValue('teamId', filtered.teamId);
      setValue('name', `${filtered.firstName} ${filtered.lastName}`);
    }
  }, [customer, customers, setValue]);

  useEffect(() => {
    const filtered = bookings.find((c) => c?.customer?.id === thriveCustomer);

    if (filtered) {
      setValue('name', `${filtered?.customer?.firstName} ${filtered?.customer?.surname}`);
    }
  }, [thriveCustomer, bookings, setValue]);

  const onSubmit = async (data: {
    teamId: string;
    name: string;
    customerId: string;
    dueDate: number;
    invoiceDate: number;
    invoiceNumber: string;
    payrollStartDate: number;
    payrollEndDate: number;
    invoiceType: string;
    thriveCustomerId: string;
  }) => {
    setOpenSnack(true);
    let address = '';
    if (invoiceType === invoiceTypes[0]) {
      const care = customers.find((c) => c.id === data.customerId);
      address = `${care?.address}, ${care?.postcode}`;
    } else {
      const thrive = bookings.find((c) => c.customer?.id === data.thriveCustomerId);
      address = `${thrive?.address?.addressLine1}, ${thrive?.address?.postcode}`;
    }

    if (data.payrollStartDate > data.payrollEndDate) {
      setValidation('Start date cannot be after end date');
      return;
    }

    if (numberDaysBetweenDates(data.payrollStartDate, data.payrollEndDate) > 31) {
      setValidation('Invoice period cannot be longer than 31 days');
      return;
    }

    if (data.dueDate < data.invoiceDate) {
      setValidation('Due date cannot be before invoice date');
      return;
    }

    if (data.invoiceDate < data.payrollEndDate) {
      setValidation('Invoice date cannot be before payroll end date');
      return;
    }

    await saveInvoice({
      awaitRefetchQueries: true,
      variables: {
        input: {
          payrollStartDate: data.payrollStartDate,
          payrollEndDate: data.payrollEndDate,
          name: data.name,
          dueDate: data.dueDate,
          invoiceDate: data.invoiceDate,
          invoiceNumber: data.invoiceNumber,
          invoiceType: data.invoiceType,
          customerId: invoiceType === invoiceTypes[1] ? data.thriveCustomerId : data.customerId,
          teamId: invoiceType === invoiceTypes[1] ? invoiceTypes[1] : data.teamId,
          address,
        },
      },
    });
    setTimeout(() => {
      setOpenSnack(false);
      navigate(`/developer/invoices/${getFirstDayOfPayroll(data.payrollStartDate)}/${data.teamId || 'Thrive'}/${data.customerId || data.thriveCustomerId}`);
    }, 2000);
  };

  let supportedPersonOptions = customers.map((s, index) => {
    return { label: `${s.firstName} ${s.lastName}`, value: s.id, id: index.toString() };
  });

  if (fundingType === PackagePriceFundingSourceEnum.LocalAuthority) {
    supportedPersonOptions = supportedPersonOptions.filter((c) =>
      customerPackages.some((pkg) => pkg.customerId === c.value && pkg.fundingSource === PackagePriceFundingSourceEnum.LocalAuthority),
    );
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Invoices</title>
      </Helmet>
      <LocationBar section="Invoices" page="Create Invoice" Icon={EmojiPeople} />
      <Message response={[mutationSaveInvoice]} openSnack={openSnack} setOpenSnack={setOpenSnack} />
      <div className="my-10 px-4 md:px-[5%]">
        <button type="button" className="text-gray-500 font-semibold text-md leading-md flex items-center" onClick={() => navigate('/developer/invoices/')}>
          <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
        </button>
        {validation && <div className="text-sm leading-lg mt-5 text-red-800">{validation}</div>}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-10 sm:mt-16">
              <div>
                <FormLabel label="Funding Type">
                  <div className="w-full lg:w-1/2">
                    <ToggleButtons
                      buttons={fundingSources.map((fs) => fs.value)}
                      selectedButton={fundingType}
                      onSelectButton={(selected: string) => changeFundingType(selected)}
                    />
                  </div>
                </FormLabel>
                {fundingType !== PackagePriceFundingSourceEnum.LocalAuthority && (
                  <>
                    <FormLabel label="Select a period">
                      <div className="w-full lg:w-1/2">
                        <ReactSelect options={periods} onChange={changePeriod} selectedValue={period} searchable={true} />
                      </div>
                    </FormLabel>
                    {payrollStartDate && (
                      <>
                        <DateField label="Start Date" name="payrollStartDate" disabled />
                        <DateField label="End Date" name="payrollEndDate" disabled />
                        <DateField label="Invoice Date" name="invoiceDate" disabled />
                        <DateField label="Due Date" name="dueDate" disabled />
                      </>
                    )}
                  </>
                )}
                {fundingType === PackagePriceFundingSourceEnum.LocalAuthority && (
                  <>
                    <DateField label="Start Date" name="payrollStartDate" />
                    <DateField label="End Date" name="payrollEndDate" />
                    <DateField label="Invoice Date" name="invoiceDate" />
                    <DateField label="Due Date" name="dueDate" />
                  </>
                )}
                {payrollStartDate && (
                  <>
                    {fundingType !== PackagePriceFundingSourceEnum.LocalAuthority && (
                      <RadioField radioOptions={invoiceTypes} testId="invoice-type" label="Invoice Type" name="invoiceType" />
                    )}
                    {invoiceType === invoiceTypes[1] && <SelectField isRequired options={thriveCustomers} label="Thrive Customer" name="thriveCustomerId" />}
                    {invoiceType === invoiceTypes[0] && (
                      <>
                        <SelectField
                          isRequired={true}
                          searchable={true}
                          label="Supported Person"
                          name="customerId"
                          options={supportedPersonOptions}
                          disabled={false}
                        />
                        <TeamSelectField label="Team" name="teamId" disabled searchable />
                      </>
                    )}
                    {customer && (
                      <>
                        <InputField label="Name" placeholder="name" name="name" disabled />
                        <InputField label="Invoice Number" placeholder="InvoiceNumber" name="invoiceNumber" isRequired />
                      </>
                    )}
                  </>
                )}
              </div>
              <button
                disabled={!payrollStartDate}
                type="submit"
                data-cy="save-candidate-button"
                className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
              >
                Save
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default InvoiceCreate;
