import TextareaAutosize from 'react-textarea-autosize';

interface TextAreaProps {
  value: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  error?: string;
  limit?: number;
  rows?: number;
  testId?: string;
}

const TextArea = ({ value, placeholder, onChange, disabled, error, limit, rows, testId }: TextAreaProps) => {
  return (
    <>
      <div
        className="relative
   "
      >
        <TextareaAutosize
          disabled={disabled}
          value={value}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(event: any) => (onChange ? onChange(event.target.value) : null)}
          // eslint-disable-next-line max-len
          className="bg-white px-5 py-2  disabled:bg-slate-50 disabled:text-slate-500 rounded-lg text-md leading-md text-gray-700 placeholder:text-gray-500 border border-gray-300 shadow-xs w-full "
          placeholder={placeholder ?? ''}
          maxLength={limit}
          rows={rows}
          data-cy={testId}
        />
        {limit && (
          <div className="right-6 bottom-1 absolute text-gray-400 text-xs leading-xs">
            {value.length}/{limit}
          </div>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-error-600 dark:text-green-500">
          <span className="font-medium">{error}</span>
        </p>
      )}
    </>
  );
};

export default TextArea;
