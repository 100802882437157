import { Shift as ShiftType, Customer, ReportingHoursPeriod, SupportWorker, TeamAnswer, CourseReport } from '__generated__/graphql';
import { useReviewStats } from 'pages/ReviewStats/useReviewStats';
import { VisitStatus } from 'pages/ReportingHours/service';
import MagicNumber from './components/MagicNumber';
import Overdue from './components/Overdue';
import YourPeople from './components/YourPeople';
import TBCRemaining from './components/TBCRemaining';
import YourTeam from './components/YourTeam';
import Links, { SlackLinkType } from './components/Links';
import YourTraining from './components/YourTraining';

type CPStatusesType = 'complete' | 'partComplete' | 'overdue' | 'error';

interface StatisticsProps {
  teamShifts: ShiftType[];
  ownStatus: CPStatusesType[];
  teamCPStatus: TeamAnswer[];
  customers: Customer[];
  customersReviews: Customer[];
  reportingHoursPeriod: ReportingHoursPeriod[];
  supportWorkers: SupportWorker[];
  courseReports: CourseReport[];
  teamId: string | undefined;
}

const Statistics = ({
  teamId,
  teamShifts = [],
  ownStatus,
  teamCPStatus,
  customers,
  customersReviews,
  reportingHoursPeriod,
  courseReports,
  supportWorkers,
}: StatisticsProps) => {
  const filteredShifts = teamShifts?.filter((sh: ShiftType) => sh.status !== VisitStatus.cancelled);

  const remainingTBCs = filteredShifts?.filter(
    (sh: ShiftType) =>
      sh.secondarySupportWorker === null && sh.startDateTime > Date.now() && sh.status !== VisitStatus.cancelled && sh.status !== VisitStatus.moved,
  );

  const ownCPStatus = ownStatus[0] || 'error';
  const { overdue } = useReviewStats(customersReviews);

  const hoursForConsideration = reportingHoursPeriod.filter((rp) => rp.employmentStatus === 'Permanent');
  const uniqueSupportWorkers = [...new Set(hoursForConsideration.map((sw) => sw.supportWorkerId))];
  const teamSupportWorkers = supportWorkers.filter((s) => s.teamId === teamId && s.employmentStatus === 'Permanent');
  const totalSupportWorkers = uniqueSupportWorkers.length > teamSupportWorkers.length ? uniqueSupportWorkers.length : teamSupportWorkers.length;

  const magicNumberForcast = hoursForConsideration
    .map((rh) => {
      const supportWorker = supportWorkers.find((sw) => sw.id === rh.supportWorkerId);
      if (!supportWorker || supportWorker.employmentStatus !== 'Permanent') return 0;
      const hoursInVisitsTotal = rh?.timeOnVisits && rh?.timeOnVisits > 0 ? rh.timeOnVisits / 60 : 0;
      const bankedHours = rh?.bankedHours && rh?.bankedHours > 0 ? rh.bankedHours / 60 : 0;
      const bankedHoursOnVisitsTotal = rh?.bankedHoursAssignedOnVisits && rh?.bankedHoursAssignedOnVisits > 0 ? rh.bankedHoursAssignedOnVisits / 60 : 0;
      const remainingBankedHours = bankedHours - bankedHoursOnVisitsTotal;
      const overtime = remainingBankedHours < 0 ? Math.abs(remainingBankedHours) : 0;
      const contractedHoursForMonth = parseFloat(supportWorker?.contractedHours.toString()) * 4.345;
      const contractedHoursForMonthPlusOvertime = contractedHoursForMonth + overtime;
      return (hoursInVisitsTotal / contractedHoursForMonthPlusOvertime) * 100;
    })
    .reduce((a, b) => a + b, 0);

  const overallTotal = magicNumberForcast === 0 || reportingHoursPeriod.length === 0 ? 0 : magicNumberForcast / reportingHoursPeriod.length;

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-5 mt-10" data-cy="statistics">
        <MagicNumber members={uniqueSupportWorkers.length} totalMembers={totalSupportWorkers} testId="team-magic-number" percentage={overallTotal} />
        <div className="flex flex-col gap-4">
          <Overdue reviewsOverdue={overdue} confirmationPractices={ownCPStatus === 'overdue'} testId="overdue" />
          <YourPeople customers={customers} testId="nb-supported-people" />
        </div>
        <TBCRemaining teamShifts={remainingTBCs} testId="dashboard-tbc" />
        <YourTraining courseReports={courseReports} testId="course-reports" />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mt-4">
        <YourTeam supportWorkers={teamSupportWorkers} teamCPStatus={teamCPStatus} testId="number-cp-colleagues" />
        <Links teamId={teamId as SlackLinkType} testId="useful-links" />
      </div>
    </>
  );
};

export default Statistics;
