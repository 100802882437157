import { useForm, FormProvider } from 'react-hook-form';
import { InputField } from 'components/Fields/InputField';
import { SelectField } from 'components/Fields/SelectField';
import { CreateCustomerInput, HubSpotCustomer, Team } from '../../__generated__/graphql';

interface HubspotNewTeamContactProps {
  customer: HubSpotCustomer | null;
  teams: Team[];
  onSubmit: (data: CreateCustomerInput) => void;
}

const HubspotNewTeamContact = ({ customer, teams, onSubmit }: HubspotNewTeamContactProps) => {
  const circles = (teams || [])
    .map((t) => ({ label: t.circleName, value: t.circleId }))
    .filter((a, i) => teams?.findIndex((s) => a.label && a.value === s.circleId) === i);

  const methods = useForm<CreateCustomerInput>({
    mode: 'onSubmit',
    values: {
      id: customer?.id ? customer?.id : '',
      firstName: customer?.firstname ? customer?.firstname : '',
      lastName: customer?.lastname ? customer?.lastname : '',
      phone: customer?.phone ? customer?.phone : '',
      mobile: customer?.mobilephone ? customer?.mobilephone : '',
      address: customer?.address ? customer?.address : '',
      city: customer?.city ? customer?.city : '',
      postcode: customer?.zip ? customer?.zip : '',
      hubspotDealId: customer?.deal?.id,
      teamName: customer?.deal?.team || '',
      teamId: '',
      circleId: '',
      prefix: '',
    },
  });

  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-10 sm:mt-16">
          <div className="mt-8 sm:mt-12">
            <InputField label="New Team" name="teamName" placeholder="New Team" />
            <InputField label="Team Prefix" name="prefix" placeholder="Team Prefix" />
            <SelectField isRequired={true} searchable={true} testId="hubspot-circle" label="Circle" name="circleId" options={circles} />
            <InputField disabled label="Id" name="id" placeholder="id" />
            <InputField disabled label="DealId" name="hubspotDealId" placeholder="Deal Id" />
            <InputField disabled label="Firstname" name="firstName" placeholder="Firstname" />
            <InputField disabled label="Lastname" name="lastName" placeholder="Lastname" />
            <InputField disabled label="Phone" name="phone" placeholder="Phone" isRequired={false} />
            <InputField disabled label="Mobilephone" name="mobile" placeholder="Mobilephone" isRequired={false} />
            <InputField disabled label="Address" name="address" placeholder="Address" />
            <InputField disabled label="City" name="city" placeholder="City" />
            <InputField disabled label="Postcode" name="postcode" placeholder="Postcode" />
          </div>
          <button type="submit" className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
            Save
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

export default HubspotNewTeamContact;
