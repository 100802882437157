import FormLabel from '../FormLabel';
import { isPostcodeValid } from './validators';
import { InputFieldWithoutLabel } from './InputField';

interface AddressFieldProps {
  disabled?: boolean;
  testId?: string;
  isRequired?: boolean;
}
const AddressField = ({ disabled = false, testId, isRequired = false }: AddressFieldProps) => {
  return (
    <FormLabel label="Address">
      <div className="w-full lg:w-1/2">
        <InputFieldWithoutLabel name="address" placeholder="Address" disabled={disabled} isRequired={isRequired} testId={`${testId}-address`} />
        <InputFieldWithoutLabel name="city" placeholder="City" disabled={disabled} isRequired={isRequired} testId={`${testId}-city`} />
        <InputFieldWithoutLabel name="state" placeholder="State" disabled={disabled} isRequired={isRequired} testId={`${testId}-state`} />
        <InputFieldWithoutLabel
          name="postCode"
          placeholder="Postcode"
          disabled={disabled}
          isRequired={isRequired}
          testId={`${testId}-postCode`}
          validate={isPostcodeValid}
        />
        <InputFieldWithoutLabel name="country" placeholder="Country" disabled={disabled} isRequired={isRequired} testId={`${testId}-country`} />
      </div>
    </FormLabel>
  );
};

export { AddressField };
