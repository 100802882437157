export type EventType = {
  cross: boolean;
  end: Date;
  id: string;
  start: Date;
  title: string;
  type: EventTypeType;
};

export enum EventTypeType {
  Activity = 'Activity',
  Birthday = 'Birthday',
  Absence = 'Absence',
  SickLeave = 'Sick Leave',
  AnuualLeave = 'Annual Leave',
  OtherLeave = 'Other Leave',
  Unassigned = 'Unassigned',
  StartDate = 'Start Date',
}
