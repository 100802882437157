import { Customer as CustomerType } from '__generated__/graphql';
import { ImageSizeType } from 'types/types';
import AvatarGroup from 'components/AvatarGroup';
import Tooltip from 'components/Tooltip';
import { UserPlusIcon } from '@heroicons/react/24/solid';

interface LinkWorkerProps {
  customers: CustomerType[];
  selectCustomer: (customer: CustomerType) => void;
}

export default function LinkWorkers({ customers, selectCustomer }: LinkWorkerProps) {
  return (
    <div className="mt-3">
      {(customers || []).map((customer) => (
        <div className="py-3 flex justify-between items-center border-b border-gray-50">
          <div className="text-xl leading-xl font-medium text-gray-800">
            {customer?.firstName} {customer?.lastName}
          </div>
          <div className="flex items-center gap-4">
            <AvatarGroup
              avatars={customer.linkWorkers?.map((lw) => ({ avatar: lw?.avatar ?? '', alt: lw?.fullName ?? '', tooltip: lw?.fullName ?? '' })) ?? []}
              size={ImageSizeType.LG}
              max={5}
              border
            />
            <Tooltip tooltip="Add a link worker" right>
              <button type="button" aria-label="Add a link worker" onClick={() => selectCustomer(customer)}>
                <UserPlusIcon className="w-7 h-7 text-primary-700" />
              </button>
            </Tooltip>
          </div>
        </div>
      ))}
    </div>
  );
}
