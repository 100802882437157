import React, { useState, useEffect } from 'react';
import { getTime, set, add } from 'date-fns';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Shift } from '__generated__/graphql';
import { Loading } from 'components';

interface ChangeShiftTimeProps {
  shifts: Shift[];
  shift: Shift | null;
  apiLoading: boolean;
  toggleChangeShiftTimeModal: (changeShiftTimeModalState: boolean) => void;
  changeShiftTimeModalState: boolean;
  onChangeShiftTime: (teamId: string, rotaId: string, id: string, startDateTime: number, endDateTime: number) => Promise<void>;
}

export default function ChangeShiftTime({
  shifts,
  shift,
  toggleChangeShiftTimeModal,
  changeShiftTimeModalState,
  onChangeShiftTime,
  apiLoading,
}: ChangeShiftTimeProps) {
  const [validation, setValidation] = useState<string | null>(null);
  const [shiftStartDateTime, setShiftStartDateTime] = useState<Date>();
  const [timePickerMinDate, setTimePickerMinDate] = useState<Date>();
  const [timePickerMaxDate, setTimePickerMaxDate] = useState<Date>();

  useEffect(() => {
    const shiftsForThis = shifts.filter((s) => s.shiftId === shift?.shiftId);

    if (shiftsForThis?.length > 0) {
      const startDateTime = shiftsForThis[0]?.startDateTime;
      setShiftStartDateTime(set(startDateTime, { hours: 9, minutes: 0 }));
      setTimePickerMinDate(set(startDateTime, { hours: 6, minutes: 0 }));
      setTimePickerMaxDate(set(startDateTime, { hours: 21, minutes: 0 }));
    } else {
      setShiftStartDateTime(set(new Date(), { hours: 9, minutes: 0 }));
      setTimePickerMinDate(set(new Date(), { hours: 6, minutes: 0 }));
      setTimePickerMaxDate(set(new Date(), { hours: 21, minutes: 0 }));
    }
  }, [shifts, shift]);

  const onChangeShiftStartDate = (time: Date | null) => {
    if (time) {
      setShiftStartDateTime(time);
      setValidation(null);
    }
  };

  const onSaveShiftTime = () => {
    setValidation(null);

    if (!shift?.shiftRun?.teamId) {
      setValidation('Please provide a shiftrun');
      return;
    }

    const endDateTime = add(shiftStartDateTime || 0, { hours: 5 });

    if (shiftStartDateTime && endDateTime) {
      onChangeShiftTime(shift?.shiftRun?.teamId, shift?.shiftRun?.rotaId, shift?.shiftRun?.id, getTime(shiftStartDateTime), getTime(endDateTime));
    }
  };

  return (
    <Dialog data-cy="create-shift-dialog" open={changeShiftTimeModalState} onClose={() => toggleChangeShiftTimeModal(false)}>
      <DialogTitle sx={{ fontSize: '1.6em', padding: { xs: '0.8em', sm: '1.2em' } }}>Change Shift Time</DialogTitle>
      <DialogContent sx={{ width: '100%', marginLeft: '0.2em', marginBottom: '1em', padding: { xs: '0', sm: '1em' } }}>
        {validation && (
          <Alert variant="filled" severity="error" sx={{ ml: '1em', mb: '1em', padding: { xs: '0.6', sm: '1em' }, width: { xs: '90%', sm: '90%' } }}>
            {validation}
          </Alert>
        )}
        <Typography sx={{ m: '1em' }}>Are you sure you want to change the time of this Fixed shift</Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <FormControl sx={{ width: '90%', ml: '1em', mb: '1em' }}>
            <Typography>Start time</Typography>
            <TimePicker
              minTime={timePickerMinDate}
              maxTime={timePickerMaxDate}
              value={shiftStartDateTime}
              ampm={false}
              minutesStep={5}
              onChange={onChangeShiftStartDate}
            />
          </FormControl>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions sx={{ padding: '1em' }}>
        <Button data-cy="close-shift-button" variant="contained" onClick={() => toggleChangeShiftTimeModal(false)}>
          Close
        </Button>
        {apiLoading ? (
          <Loading isComponent />
        ) : (
          <Button data-cy="change-shift-button" variant="contained" onClick={() => onSaveShiftTime()}>
            Change Shift Time
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
