/* eslint-disable max-len */
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { TrashIcon } from '@heroicons/react/24/solid';
import { PlaceholderVisit, SavePlaceholderVisitInput } from '__generated__/graphql';
import { Input } from 'components';
import TextArea from 'components/TextArea';
import TimePicker from 'components/TimePicker';
import { format, getTime } from 'date-fns';
import { useEffect, useState } from 'react';
import { getHoursAndMinutes } from 'services/helpers';
import { createDateWithTime } from '../utils';

interface AddPlaceholderVisitProps {
  placeHolderVisit: PlaceholderVisit | null;
  startDateTime: number;
  endDateTime: number;
  isAbleToAdd: boolean;
  conflictedVisits: { customer: string; startDate: number; endDate: number }[];
  setIsAbleToAdd: (value: boolean) => void;
  onClose: () => void;
  onAdd: (input: SavePlaceholderVisitInput) => void;
  onRemove: (id: string) => void;
}

const AddPlaceholderVisit = ({
  placeHolderVisit,
  startDateTime,
  endDateTime,
  isAbleToAdd,
  conflictedVisits,
  setIsAbleToAdd,
  onClose,
  onAdd,
  onRemove,
}: AddPlaceholderVisitProps) => {
  const [supportedPerson, setSupportedPerson] = useState<string>(placeHolderVisit?.name ?? '');
  const [teamMember, setTeamMember] = useState<string>(placeHolderVisit?.supportWorker ?? '');
  const [startTime, setStartTime] = useState<Date | null>(placeHolderVisit ? new Date(placeHolderVisit.startDate ?? 0) : new Date(startDateTime));
  const [endTime, setEndTime] = useState<Date | null>(placeHolderVisit ? new Date(placeHolderVisit.endDate ?? 0) : new Date(endDateTime));
  const [note, setNote] = useState<string>(placeHolderVisit?.note ?? '');
  const [validation, setValidation] = useState<string | null>(null);

  useEffect(() => {
    if (!isAbleToAdd) {
      setValidation('There is already a visit at this time. Visits cannot overlap.');
    }
  }, [isAbleToAdd]);

  const onAddVisit = () => {
    const agreedShiftRunStartDateTime = createDateWithTime(Date.now(), startDateTime);
    const agreedShiftRunEndDateTime = createDateWithTime(Date.now(), endDateTime);
    const visitStartDateTime = createDateWithTime(Date.now(), startTime?.getTime() ?? 0);
    const visitEndDateTime = createDateWithTime(Date.now(), endTime?.getTime() ?? 0);

    setValidation(null);
    setIsAbleToAdd(true);

    if (supportedPerson === '') {
      setValidation('Please select a new supported person');
      return;
    }

    if (teamMember === '') {
      setValidation('Please select a bellevie team member');
      return;
    }

    if (getTime(visitStartDateTime) >= getTime(visitEndDateTime)) {
      setValidation('Please provide a valid date time; end time should be greater than start time');
      return;
    }
    if (getTime(visitStartDateTime) < getTime(agreedShiftRunStartDateTime) || getTime(visitEndDateTime) > getTime(agreedShiftRunEndDateTime)) {
      setValidation(
        `The start time and end time must be between ${getHoursAndMinutes(agreedShiftRunStartDateTime)} and ${getHoursAndMinutes(agreedShiftRunEndDateTime)}`,
      );
      return;
    }
    if (placeHolderVisit) {
      onAdd({ id: placeHolderVisit.id, name: supportedPerson, supportWorker: teamMember, startDate: startTime?.getTime(), endDate: endTime?.getTime(), note });
    } else {
      onAdd({ name: supportedPerson, supportWorker: teamMember, startDate: startTime?.getTime(), endDate: endTime?.getTime(), note });
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto mx-auto max-w-5xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6 max-h-screen md:max-h-[90vh] overflow-y-auto md:w-[480px]">
            <div className="text-gray-900 text-lg leading-lg font-semibold mb-5">{placeHolderVisit ? 'Edit this new package' : 'Add a new package'}</div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Name of new supported person</div>
              <div className="mt-2">
                <Input
                  value={supportedPerson}
                  onChange={(value: string) => {
                    setSupportedPerson(value);
                    setValidation(null);
                  }}
                />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Bellevie team member</div>
              <div className="mt-2">
                <Input
                  value={teamMember}
                  onChange={(value: string) => {
                    setTeamMember(value);
                    setValidation(null);
                  }}
                />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Start Time</div>
              <div className="mt-2">
                <TimePicker
                  date={startTime}
                  onChange={(date: Date | null) => {
                    setStartTime(date);
                    setValidation(null);
                  }}
                  minTime={new Date(startDateTime)}
                  maxTime={new Date(endDateTime)}
                />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">End Time</div>
              <div className="mt-2">
                <TimePicker
                  date={endTime}
                  onChange={(date: Date | null) => {
                    setEndTime(date);
                    setValidation(null);
                  }}
                  minTime={new Date(startDateTime)}
                  maxTime={new Date(endDateTime)}
                />
              </div>
            </div>
            <div>
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Note</div>
              <div className="mt-2">
                <TextArea
                  value={note}
                  onChange={(value: string) => {
                    setNote(value);
                    setValidation(null);
                  }}
                />
              </div>
            </div>
            {validation && (
              <>
                <div className="text-error-700 text-lg leading-lg flex items-center gap-2 mt-3">
                  <ExclamationTriangleIcon className="w-5 h-5" />
                  {validation}
                </div>
                {conflictedVisits.length > 0 && <div className="text-gray-800 text-lg leading-lg font-semibold">Conflicted visits :</div>}
                {conflictedVisits.map((visit, index) => (
                  <div className="text-gray-800 text-md leading-md ml-2" key={index}>
                    {visit?.customer}: {format(visit.startDate, 'EEEE dd MMMM kk:mm')} - {format(visit.endDate, 'kk:mm')}
                  </div>
                ))}
              </>
            )}
            <div className="flex justify-between items-center mt-7">
              <button type="button" className="text-gray-500 font-semibold text-md leading-md" onClick={onClose}>
                Cancel
              </button>
              <div className="flex items-center gap-3">
                {placeHolderVisit && (
                  <button
                    type="button"
                    aria-label="remove place holder visit"
                    className="text-white bg-error-600 rounded-lg p-3"
                    onClick={() => onRemove(placeHolderVisit.id ?? '')}
                  >
                    <TrashIcon className="w-5 h-5" />
                  </button>
                )}
                <button
                  type="button"
                  disabled={validation !== null}
                  className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
                  onClick={onAddVisit}
                >
                  {placeHolderVisit ? 'Save' : 'Add'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-gray-900" />
    </>
  );
};

export default AddPlaceholderVisit;
