import { CalendarIcon, CheckIcon } from '@heroicons/react/24/outline';
import { Error } from '@mui/icons-material';
import { Shift, Shift as ShiftType } from '__generated__/graphql';
import { useMemo } from 'react';

import { useNavigate } from 'react-router-dom';
import { weekFromMonday } from 'services/helpers';

type Week = 1 | 2;

type WeekCalendarType = {
  '0': {
    am: boolean | null;
    pm: boolean | null;
  };
  '1': {
    am: boolean | null;
    pm: boolean | null;
  };
  '2': {
    am: boolean | null;
    pm: boolean | null;
  };
  '3': {
    am: boolean | null;
    pm: boolean | null;
  };
  '4': {
    am: boolean | null;
    pm: boolean | null;
  };
  '5': {
    am: boolean | null;
    pm: boolean | null;
  };
  '6': {
    am: boolean | null;
    pm: boolean | null;
  };
};

type RotaDay = {
  am: boolean | null;
  pm: boolean | null;
};

type RotaWeek = {
  0: RotaDay;
  1: RotaDay;
  2: RotaDay;
  3: RotaDay;
  4: RotaDay;
  5: RotaDay;
  6: RotaDay;
};

type RotaCalendarType = {
  1: RotaWeek;
  2: RotaWeek;
};

interface TBCRemainingProps {
  teamShifts: ShiftType[];
  testId: string;
}

const TBCRemaining = ({ teamShifts, testId }: TBCRemainingProps) => {
  const navigate = useNavigate();
  const weekLabels = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

  const rotaCalendar = useMemo(() => {
    const rota: RotaCalendarType = {
      1: {
        0: {
          am: null,
          pm: null,
        },
        1: {
          am: null,
          pm: null,
        },
        2: {
          am: null,
          pm: null,
        },
        3: {
          am: null,
          pm: null,
        },
        4: {
          am: null,
          pm: null,
        },
        5: {
          am: null,
          pm: null,
        },
        6: {
          am: null,
          pm: null,
        },
      },
      2: {
        0: {
          am: null,
          pm: null,
        },
        1: {
          am: null,
          pm: null,
        },
        2: {
          am: null,
          pm: null,
        },
        3: {
          am: null,
          pm: null,
        },
        4: {
          am: null,
          pm: null,
        },
        5: {
          am: null,
          pm: null,
        },
        6: {
          am: null,
          pm: null,
        },
      },
    };

    teamShifts?.forEach((shift: Shift) => {
      if (shift.startDateTime > Date.now()) {
        const day = weekFromMonday(new Date(shift.startDateTime).getDay());
        const week = parseInt(shift.week ? shift.week : '', 10);
        const amPm = new Date(shift.startDateTime).getHours() >= 12 ? 'pm' : 'am';
        if (shift.secondarySupportWorker === null) {
          rota[week as Week][day][amPm] = false;
        } else if (rota[week as Week][day][amPm] !== false) {
          rota[week as Week][day][amPm] = true;
        }
      }
    });
    return rota;
  }, [teamShifts]);

  const getBoxColor = (rotaState: boolean | null) => {
    if (rotaState === null) {
      return (
        <div className="w-6 h-6 sm:w-8 sm:h-8 xl:w-6 xl:h-6 2xl:w-8 2xl:h-8 bg-backgroundNotice rounded-full flex justify-center items-center">
          <CheckIcon className="w-4 h-4 text-white" />
        </div>
      );
    }
    if (rotaState) {
      return (
        <div className="w-6 h-6 sm:w-8 sm:h-8 xl:w-6 xl:h-6 2xl:w-8 2xl:h-8 bg-contentPositive rounded-full flex justify-center items-center">
          <CheckIcon className="w-4 h-4 text-white" />
        </div>
      );
    }
    return (
      <Error className="!w-6 !h-6 sm:!w-8 sm:!h-8 xl:!w-6 xl:!h-6 2xl:!w-8 2xl:!h-8 text-contentNegative rounded-full border-2 border-contentNegative flex items-center justify-center" />
    );
  };

  return (
    <button
      type="button"
      onClick={() => navigate('/team-shifts?assignment=2')}
      className="bg-background2 border border-black border-opacity-[0.13] p-6 rounded-3xl"
      data-cy={testId}
    >
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-3">
          <div className="border border-black border-opacity-20 w-10 h-10 rounded-full flex items-center justify-center">
            <CalendarIcon className="!w-5 !h-5 text-content-secondary" />
          </div>
          <div className="text-xl leading-xl font-semibold text-black">TBCs remaining</div>
        </div>
      </div>
      <div className="bg-backgroundl1 p-2 rounded-2xl mt-4">
        <div className="flex flex-row items-center gap-1 sm:gap-2.5 px-2 sm:px-6">
          <div className="w-[16%]" />
          {weekLabels.map((day, index) => (
            <div className="text-xl leading-xl font-medium text-content-secondary w-[12%] text-center" key={index}>
              {day}
            </div>
          ))}
        </div>
        {Object.keys(rotaCalendar).map((rotaKey: string, rotaIndex: number) => (
          <div className="bg-background3 px-2 sm:px-6 py-2 sm:py-4 rounded-xl flex gap-1 sm:gap-2.5 mt-2.5" key={rotaIndex}>
            <div className="w-[16%] flex flex-col gap-2.5">
              <div
                className="min-h-[28px] bg-content-secondary rounded-[20px] text-white text-xs leading-xs sm:text-md sm:leading-md xl:text-[9px] xl:leading-xs 2xl:text-md 2xl:leading-md
                          flex justify-center items-center font-medium py-1 px-2.5 xl:px-2 2xl:px-2.5 w-fit"
              >
                AM
              </div>
              <div
                className="min-h-[28px] bg-content-secondary rounded-[20px] text-white text-xs leading-xs sm:text-md sm:leading-md xl:text-[9px] xl:leading-xs 2xl:text-md 2xl:leading-md
                          flex justify-center items-center font-medium py-1 px-2.5 xl:px-2 2xl:px-2.5 w-fit"
              >
                PM
              </div>
            </div>
            {Object.keys(rotaCalendar[Number(rotaKey) as keyof RotaCalendarType]).map((key: string, index: number) => {
              return (
                <div className="w-[12%] flex flex-col gap-2.5 items-center" key={index}>
                  <div className="min-h-[28px] flex justify-center items-center">
                    {getBoxColor(rotaCalendar[Number(rotaKey) as keyof RotaCalendarType][key as keyof WeekCalendarType].am)}
                  </div>
                  <div className="min-h-[28px] flex justify-center items-center">
                    {getBoxColor(rotaCalendar[Number(rotaKey) as keyof RotaCalendarType][key as keyof WeekCalendarType].pm)}
                  </div>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </button>
  );
};

export default TBCRemaining;
