import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { InputField } from 'components/Fields/InputField';
import { DateField } from 'components/Fields/DateField';
import { SupportedPersonSelectField } from 'components/Fields/SupportedPersonSelectField';
import { useGetCustomersReviews } from 'api/hooks/useCustomers';
import { getReviewType } from 'pages/WellbeingBoard/utils';
import { AscotReview, AscotReviewInput } from '__generated__/graphql';
import { FormPrompt } from 'components/modals/FormPromptModal';
import { TextAreaFieldWithDescription } from './TextAreaWithDescriptionField';
import { RadioField } from './RadioField';

const AscotReviewForm = ({ onSubmit, ascotReview }: { onSubmit: (data: AscotReviewInput) => void; ascotReview?: AscotReview }) => {
  const { supportedPersonId, reviewId } = useParams<{ supportedPersonId?: string; reviewId?: string }>();
  const { customers } = useGetCustomersReviews({});
  const customer = customers.find((c) => c.id === supportedPersonId);
  const currentReview = customer?.reviews?.find((review) => review?.id === reviewId);
  const reviewIndex = currentReview ? customer?.reviews?.indexOf(currentReview) : null;
  const reviewType = getReviewType(reviewIndex);
  const navigate = useNavigate();

  const methods = useForm<AscotReviewInput>({
    mode: 'onSubmit',
    defaultValues: {
      id: ascotReview?.id,
      supportedPersonId: ascotReview?.supportedPersonId || supportedPersonId,
      reviewDate: ascotReview?.reviewDate,
      reviewType: ascotReview?.reviewType,
      intro: ascotReview?.intro,
      meetingPurpose: ascotReview?.meetingPurpose,
      newOutcomes: ascotReview?.newOutcomes,
      agreedActionsAndOutcomes: ascotReview?.agreedActionsAndOutcomes,
      progressToAcheivingOutComes: ascotReview?.progressToAcheivingOutComes,
      socialParticipationAndInvolvement: {
        answer: ascotReview?.socialParticipationAndInvolvement?.answer,
        score: ascotReview?.socialParticipationAndInvolvement?.score,
      },
      occupation: {
        answer: ascotReview?.occupation?.answer,
        score: ascotReview?.occupation?.score,
      },
      controlOverDailyLife: {
        answer: ascotReview?.controlOverDailyLife?.answer,
        score: ascotReview?.controlOverDailyLife?.score,
      },
      personalSafety: {
        answer: ascotReview?.personalSafety?.answer,
        score: ascotReview?.personalSafety?.score,
      },
      foodAndDrink: {
        answer: ascotReview?.foodAndDrink?.answer,
        score: ascotReview?.foodAndDrink?.score,
      },
      whatDoBetter: ascotReview?.whatDoBetter,
      reviewId: ascotReview?.reviewId,
      accomodationAndComfort: {
        answer: ascotReview?.accomodationAndComfort?.answer,
        score: ascotReview?.accomodationAndComfort?.score,
      },
      personalCleanlinessAndComfort: {
        answer: ascotReview?.personalCleanlinessAndComfort?.answer,
        score: ascotReview?.personalCleanlinessAndComfort?.score,
      },
      dignity: {
        answer: ascotReview?.dignity?.answer,
        score: ascotReview?.dignity?.score,
        score2: ascotReview?.dignity?.score2,
      },
      agreedActionsToChange: ascotReview?.agreedActionsToChange,
      infoToUpdate: ascotReview?.infoToUpdate,
      nextMeetingAgenda: ascotReview?.nextMeetingAgenda,
    },
  });

  const { handleSubmit, setValue, reset, formState } = methods;

  useEffect(() => {
    if (!ascotReview) {
      setValue('reviewDate', currentReview?.date || 0);
      setValue('reviewType', reviewType || '');
      setValue('reviewId', reviewId || '');
    }
  }, [setValue, ascotReview, currentReview?.date, reviewId, reviewType]);

  const formSubmit = (data: AscotReviewInput) => {
    onSubmit(data);
    reset({}, { keepValues: true });
    navigate(-1);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(formSubmit)}>
          <div className="mt-10 sm:mt-16">
            <div className="mt-8 sm:mt-12">
              <SupportedPersonSelectField name="supportedPersonId" label="Supported Person" disabled={true} />
              <DateField name="reviewDate" label="Date of review" />
              <InputField isRequired label="Type of review" name="reviewType" placeholder="Type of review" disabled={true} />
              <InputField isRequired label="Welcome and introductions" name="intro" placeholder="Welcome and introductions" />
              <InputField isRequired label="Purpose of the meeting" name="meetingPurpose" placeholder="Purpose of the meeting" />
              <div>Review of any outstanding actions and a summary of agreed outcomes since last review (if applicable):</div>
              <br />
              <TextAreaFieldWithDescription
                label="Agreed actions and person's outcomes from last review"
                name="agreedActionsAndOutcomes"
                placeholder="Please copy and paste any agreed actions & person's outcomes from last review in here:"
              />
              <TextAreaFieldWithDescription
                label="Person's progress towards acheiving their outcomes"
                name="progressToAcheivingOutComes"
                placeholder="Please also document the person's progress towards achieving their outcomes"
              />
              <TextAreaFieldWithDescription label="New outcomes that the person would like to acheive" name="newOutcomes" placeholder="New outcomes" />
              <br />
              <div className=" text-gray-700 text-md leading-sm font-bold">
                What is working and not working from different perspectives (person, family, wellbeing team etc)
              </div>
              <br />
              <div className=" text-gray-700 text-md leading-sm font-bold">Adult Social Care Outcomes Toolkit (ASCOT) Questions</div>
              <br />

              <div>Please use the following text to introduce ASCOT to the person supported:</div>
              <br />

              <div className="text-primary-500 font-semibold">
                We would like to talk to you about how you feel things are going in general and we have got some questions about specific areas of your life.
              </div>
              <br />

              <TextAreaFieldWithDescription
                questions={[
                  'How are you finding the team?',
                  'Are you getting to speak with/spend time with (choose what is most appropriate) your loved ones and are we helping you to do that?',
                ]}
                label="Social participation and involvement"
                name="socialParticipationAndInvolvement.answer"
                placeholder="Please document your notes from the discussion here:"
              />
              <RadioField
                options={[
                  { id: '1', label: 'I have as much social contact as I want with people I like', value: 0.873 },
                  { id: '2', label: 'I have adequate social contact with people', value: 0.748 },
                  { id: '3', label: 'I have some social contact with people, but not enough', value: 0.497 },
                  { id: '4', label: 'I have little social contact with people and feel socially isolated', value: 0.241 },
                ]}
                name="socialParticipationAndInvolvement.score"
                label="Following on from my last question, can I get you to rate which of the following best describes your situation?"
                isRequired
              />

              <TextAreaFieldWithDescription
                questions={['Do you get to do the things that interest you?', 'Are there other activities that you would like BelleVie to support you with?']}
                label="Occupation"
                name="occupation.answer"
                placeholder="Please document your notes from the discussion here:"
              />

              <RadioField
                options={[
                  { id: '1', label: 'I`m able to spend my time as I want, doing things I value or enjoy', value: 0.962 },
                  { id: '2', label: ' I`m able to do enough of the things I value or enjoy with my time', value: 0.927 },
                  { id: '3', label: 'I do some of the things I value or enjoy with my time, but not enough', value: 0.567 },
                  { id: '4', label: 'I don`t do anything I value or enjoy with my time', value: 0.17 },
                ]}
                name="occupation.score"
                label="Can I ask you to choose from the following statements?"
                isRequired
              />

              <TextAreaFieldWithDescription
                label="Control over daily life"
                questions={[
                  'Do you feel supported in being able to do things that help you feel more independent?',
                  'Do you feel listened to if there is something that you are unhappy with?',
                ]}
                name="controlOverDailyLife.answer"
                placeholder="Please document your notes from the discussion here:"
              />
              <RadioField
                options={[
                  { id: '1', label: 'I have as much control over my daily life as I want', value: 1.0 },
                  { id: '2', label: 'I have adequate control over my daily life', value: 0.919 },
                  { id: '3', label: 'I have some control over my daily life, but not enough', value: 0.541 },
                  { id: '4', label: 'I have no control over my daily life', value: 0.0 },
                ]}
                name="controlOverDailyLife.score"
                label="Which of the following best describes your current situation?"
                isRequired
              />
              <TextAreaFieldWithDescription
                questions={[
                  'Does the team help you feel safe?',
                  'Do you worry about falling? If so, why?',
                  'Do you feel secure/safe inside your home?',
                  'Are you worried about leaving your house?',
                ]}
                label="Personal safety"
                name="personalSafety.answer"
                placeholder="Please document your notes from the discussion here:"
              />
              <RadioField
                options={[
                  { id: '1', label: 'I feel as safe as I want', value: 0.88 },
                  { id: '2', label: 'Generally I feel adequately safe, but not as safe as I would like', value: 0.452 },
                  { id: '3', label: 'I feel less than adequately safe', value: 0.298 },
                  { id: '4', label: 'I don’t feel safe at all', value: 0.114 },
                ]}
                name="personalSafety.score"
                label="Can I ask you to choose from the following statements?"
                isRequired
              />
              <TextAreaFieldWithDescription
                questions={[
                  'Do you like the food that you get to eat?',
                  'Tell me what happens at meal times?',
                  'How would you get a snack or a drink outside of meal times?',
                  'How much choice do you get around food?',
                ]}
                label="Food and drink"
                name="foodAndDrink.answer"
                placeholder="Please document your notes from the discussion here:"
              />
              <RadioField
                options={[
                  { id: '1', label: 'I get all the food and drink I like when I want', value: 0.879 },
                  { id: '2', label: 'I get adequate food and drink at OK times', value: 0.775 },
                  { id: '3', label: 'I don`t always get adequate or timely food and drink', value: 0.294 },
                  { id: '4', label: 'I don`t always get adequate or timely food and drink, and I think there is a risk to my health', value: 0.184 },
                ]}
                name="foodAndDrink.score"
                label="Thinking about the food and drink you get, which of the following best describes your situation?"
                isRequired
              />
              <TextAreaFieldWithDescription
                questions={['Do you feel your home is kept clean enough?', 'Do you find your home comfortable?', 'Are you able to relax at home?']}
                label="Accommodation and comfort"
                name="accomodationAndComfort.answer"
                placeholder="Please document your notes from the discussion here:"
              />
              <RadioField
                options={[
                  { id: '1', label: 'My home is as clean and comfortable as I want', value: 0.863 },
                  { id: '2', label: 'My home is adequately clean and comfortable', value: 0.78 },
                  { id: '3', label: 'My home is not quite clean or comfortable enough', value: 0.374 },
                  { id: '4', label: 'My home is not at all clean or comfortable', value: 0.288 },
                ]}
                name="accomodationAndComfort.score"
                label="Following on from my last question, can I get your to choose a statement that best describes your situation?"
                isRequired
              />
              <TextAreaFieldWithDescription
                questions={[
                  'Do you feel you have enough choice over the clothes you wear?',
                  'Are you shaved as often as you would like (male respondents)',
                  'Does the team help you present yourself in the way that you like? (e.g. jewellery, lipstick, nails etc)',
                  'Are you able to have as many showers as you like within the agreed visit times?',
                ]}
                label="Personal cleanliness and comfort"
                name="personalCleanlinessAndComfort.answer"
                placeholder="Please document your notes from the discussion here:"
              />
              <RadioField
                options={[
                  { id: '1', label: 'I feel clean and am able to present myself the way I like', value: 0.911 },
                  { id: '2', label: 'I feel adequately clean and presentable', value: 0.789 },
                  { id: '3', label: 'I feel less than adequately clean or presentable', value: 0.265 },
                  { id: '4', label: 'I don’t feel at all clean or presentable', value: 0.195 },
                ]}
                name="personalCleanlinessAndComfort.score"
                label="Following on from my last question, can I get your to choose a statement that best describes your situation?"
                isRequired
              />
              <TextAreaFieldWithDescription
                questions={[
                  'Do you feel listened to and treated with respect from the team?',
                  'Are you encouraged by the team to keep on doing the things that you can do?',
                ]}
                label="Dignity"
                name="dignity.answer"
                placeholder="Please document your notes from the discussion here:"
              />
              <RadioField
                options={[
                  { id: '1', label: 'Having help makes me think and feel better about myself ', value: 0.847 },
                  { id: '2', label: 'Having help does not affect the way I think or feel about myself ', value: 0.637 },
                  { id: '3', label: 'Having help sometimes undermines the way I think and feel about myself', value: 0.295 },
                  { id: '4', label: 'Having help completely undermines the way I think and feel about myself', value: 0.263 },
                ]}
                name="dignity.score"
                label="Which of these statements best describes how the way you are helped and treated by BelleVie makes you think and feel about yourself? "
                isRequired
              />
              <RadioField
                options={[
                  { id: '1', label: 'The way I`m helped and treated makes me think and feel better about myself', value: 0.847 },
                  { id: '2', label: 'The way I`m helped and treated does not affect the way I think or feel about myself', value: 0.637 },
                  { id: '3', label: 'The way I`m helped and treated sometimes undermines the way I think and feel about myself', value: 0.295 },
                  { id: '4', label: 'The way I`m helped and treated completely undermines the way I think and feel about myself', value: 0.263 },
                ]}
                name="dignity.score2"
                label="Which of these statements best describes how the way you are helped and treated by BelleVie makes you think and feel about yourself? "
                isRequired
              />
              <TextAreaFieldWithDescription label="What could we do better?" name="whatDoBetter" placeholder="What could we do better?" />
              <TextAreaFieldWithDescription
                label="`If I could I would`` (agreed actions to change)"
                name="agreedActionsToChange"
                placeholder="`If I could I would`` (agreed actions to change)
"
              />
              <TextAreaFieldWithDescription
                // eslint-disable-next-line max-len
                label="Do we need to update any information? E.g. the one-page profile, the information on the care and support plan and family communication plan, the wellbeing outcomes, who provides support when (weekly planner)."
                name="infoToUpdate"
                placeholder="Update to any information required?"
              />
              <TextAreaFieldWithDescription
                // eslint-disable-next-line max-len
                label="What will happen next / date of next meeting"
                name="nextMeetingAgenda"
                placeholder="Date of next meeting?"
              />
            </div>

            <button type="submit" className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
              Save
            </button>
          </div>
        </form>
      </FormProvider>
      <FormPrompt hasUnsavedChanges={formState.isDirty} />
    </>
  );
};

export default AscotReviewForm;
