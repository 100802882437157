import { useGetSupportWorkers } from 'api/hooks/useSupportWorkers';
import { addWeeks, getTime } from 'date-fns';
import { useGetLeaveForTeam } from 'api/hooks/useLeave';
import { Loading } from 'components';
import { BeachAccess, Sick, Cake } from '@mui/icons-material';
import AgreedVisitHeader from '../AgreedVisit/AgreedVisitHeader';
import HolidaysRun from './HolidaysRun';

interface HolidaysProps {
  selectedTeam: string;
  startDate: number;
}

const Holidays = ({ selectedTeam, startDate }: HolidaysProps) => {
  const endDate = addWeeks(startDate, 2);
  const { leave, loading } = useGetLeaveForTeam({
    teamId: selectedTeam,
    startDateTime: getTime(startDate),
    endDateTime: getTime(endDate),
  });
  const { supportWorkers } = useGetSupportWorkers({ showAll: true, teamId: selectedTeam });

  if (loading) {
    return <Loading isComponent />;
  }
  return (
    <div className="my-5">
      <div className="flex flex-col md:flex-row md:items-center gap-2 md:gap-8">
        <div className="text-gray-800 text-lg leading-lg flex flex-row items-center gap-2">
          <BeachAccess />
          <div>Leave</div>
        </div>
        <div className="text-gray-800 text-lg leading-lg flex flex-row items-center gap-2">
          <Sick />
          <div>Sick Leave</div>
        </div>
        <div className="text-gray-800 text-lg leading-lg flex flex-row items-center gap-2">
          <Cake />
          <div>Birthday Leave</div>
        </div>
      </div>
      <div className="flex items-stretch border border-gray-100 flex-wrap mt-5">
        <div className="hidden xl:flex flex-wrap w-full">
          <AgreedVisitHeader date={startDate} week={1} startDateTime={0} endDateTime={0} />
        </div>
        <div className="flex w-full border-t border-gray-100">
          <HolidaysRun startDateTime={startDate} week={1} leave={leave} supportWorkers={supportWorkers} />
        </div>
      </div>
      <div className="flex items-stretch border border-gray-100 flex-wrap mt-5">
        <div className="hidden xl:flex flex-wrap w-full">
          <AgreedVisitHeader date={startDate} week={2} startDateTime={0} endDateTime={0} />
        </div>
        <div className="flex w-full border-t border-gray-100">
          <HolidaysRun startDateTime={startDate} week={2} leave={leave} supportWorkers={supportWorkers} />
        </div>
      </div>
    </div>
  );
};

export default Holidays;
