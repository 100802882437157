import { v4 as uuidv4 } from 'uuid';
import { useUser, UserContextProps } from 'context/userContext';
import Helmet from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useSaveConfirmationPracticesGroup, useGetConfirmationPracticeGroups } from 'api/hooks/useConfirmationPractices';
import { useGetBuddiesForSupportWorker } from 'api/hooks/useBuddies';
import { ConfirmationPracticeGroup } from '__generated__/graphql';
import { List } from '@mui/icons-material';
import { LocationBar, Loading, Error } from 'components';
import History from './History';
import { formatDateWithYMD } from '../../services/helpers';
import CreateConfirmationPracticesGroup from './CreateGroup';

export default function ConfirmationPractices() {
  const navigate = useNavigate();
  const { user, userLoading } = useUser() as UserContextProps;

  const { buddies, buddiesLoading, buddiesError } = useGetBuddiesForSupportWorker({ supportWorkerId: user?.profile, teamId: user?.teamId });
  const { cpGroups, cpgLoading, cpgError } = useGetConfirmationPracticeGroups({ supportWorkerId: user?.profile });
  const { saveConfirmationPracticesGroup, mutationSaveConfirmationPracticesGroup } = useSaveConfirmationPracticesGroup({ supportWorkerId: user?.profile });

  if (buddiesLoading || cpgLoading || mutationSaveConfirmationPracticesGroup.loading) return <Loading />;
  if (buddiesError || cpgError || mutationSaveConfirmationPracticesGroup.error) return <Error />;

  const onEditVisit = (d: ConfirmationPracticeGroup) => {
    navigate(`/confirmation-practices/manage/${d.id}`);
  };

  const onSaveGroup = (isSolo: boolean, selectedBuddies: string[]) => {
    const id = uuidv4();
    saveConfirmationPracticesGroup({
      variables: {
        input: {
          id,
          createdDateTime: Date.now(),
          isSolo,
          supportWorkerId: user?.profile,
          teamId: user?.teamId,
          buddies: selectedBuddies,
        },
      },
    });

    navigate(`/confirmation-practices/manage/${id}`);
  };

  const confirmationPracticeHasEntryForToday = () => {
    if (cpGroups && cpGroups.length > 0) {
      const answer = cpGroups.find((a) => formatDateWithYMD(a.createdDateTime || 0) === formatDateWithYMD(Date.now()));
      if (answer) {
        return true;
      }
    }
    return false;
  };
  if (userLoading) {
    return <Loading />;
  }
  return (
    <>
      <Helmet>
        <title>Confirmation Practices</title>
      </Helmet>
      <LocationBar section="Policies & Practices" page="Confirmation Practices" Icon={List} />
      <div className="section">
        <div className="content">
          {!confirmationPracticeHasEntryForToday() ? (
            <CreateConfirmationPracticesGroup buddies={buddies} onSaveGroup={onSaveGroup} />
          ) : (
            <Typography sx={{ mb: '1em', p: '0.2em' }}>You can only record one set of Confirmation Practice statements per day.</Typography>
          )}
          {cpGroups.length > 0 && <History groups={cpGroups} onEditVisit={onEditVisit} />}
        </div>
      </div>
    </>
  );
}
