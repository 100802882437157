import React from 'react';

interface HalfCircleProgressBarProps {
  diameter: number;
  percentage: number;
  showPercentValue?: boolean;
  strokeColor?: string;
  strokeWidth?: number;
  backgroundColor?: string;
}

const HalfCircleProgressBar = ({
  diameter,
  percentage,
  showPercentValue = true,
  strokeColor = '#669F2A',
  strokeWidth = 26.2,
  backgroundColor = '#B6C7CE',
}: HalfCircleProgressBarProps) => {
  const coordinateForCircle = diameter / 2;
  const radius = (diameter - 2 * strokeWidth) / 2;
  const circumference = Math.PI * radius;

  let percentageValue;
  if (percentage > 100) {
    percentageValue = 100;
  } else if (percentage < 0) {
    percentageValue = 0;
  } else if (percentage > 0 && percentage < 3.5) {
    percentageValue = 3.5;
  } else {
    percentageValue = percentage;
  }
  const semiCirclePercentage = percentageValue * (circumference / 100);

  return (
    <div className="relative flex justify-center">
      <svg
        width={diameter}
        height={diameter / 2}
        style={{ transform: 'rotateY(180deg)', overflow: 'hidden' }}
        strokeLinecap={percentage > 0 ? 'round' : 'inherit'}
      >
        <circle
          cx={coordinateForCircle}
          cy={coordinateForCircle}
          r={radius}
          fill="none"
          stroke={backgroundColor}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          style={{
            strokeDashoffset: circumference,
          }}
        />
        <circle
          cx={coordinateForCircle}
          cy={coordinateForCircle}
          r={radius}
          fill="none"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          style={{
            strokeDashoffset: semiCirclePercentage,
            transition: 'stroke-dashoffset .3s ease 0s, stroke-dasharray .3s ease 0s, stroke .3s',
          }}
        />
      </svg>
      {showPercentValue && <span className="w-full left-0 bottom-0 text-center absolute text-[44px] font-semibold text-[#101828]">{percentage}%</span>}
    </div>
  );
};

export default HalfCircleProgressBar;
