import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ShiftUpdateItem } from '__generated__/graphql';
import { VisitStatus } from 'pages/ReportingHours/service';
import { Loading } from 'components';

interface RemoveVisitProps {
  removeVisitModalState: boolean;
  apiLoading: boolean;
  toggleRemoveModal: (removeVisitModalState: boolean) => void;
  onSaveVisit: (data: Partial<ShiftUpdateItem>, event: string) => void;
}

export default function RemoveVisit({ toggleRemoveModal, removeVisitModalState, onSaveVisit, apiLoading }: RemoveVisitProps) {
  const onRemoveVisit = () => {
    onSaveVisit({ status: VisitStatus.deleted }, 'Remove');
  };

  return (
    <Dialog data-cy="delete-visit-dialog" open={removeVisitModalState} onClose={() => toggleRemoveModal(false)}>
      <DialogTitle sx={{ fontSize: '1.6em', padding: { xs: '0.8em', sm: '1.2em' } }}>Remove Visit</DialogTitle>
      <DialogContent>Are you sure you would like to remove this visit?</DialogContent>
      <DialogActions sx={{ padding: '1em' }}>
        <Button data-cy="close-visit-button" variant="contained" onClick={() => toggleRemoveModal(false)}>
          Close
        </Button>
        {apiLoading ? (
          <Loading isComponent />
        ) : (
          <Button data-cy="delete-visit-button" variant="contained" onClick={() => onRemoveVisit()}>
            Remove Visit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
