import { format, isBefore, isWithinInterval } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { SelectOption, User } from 'types/types';
import { AgreedRota, Team } from '__generated__/graphql';
import Loading from 'components/Loading';
import Message from 'components/Message';
import { Add, Delete, InfoOutlined, Publish, Schedule } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { removeTime } from 'services/helpers';
import { useGetAgreedRota, useRemoveAgreedRota, useSaveAgreedRota, usePublishAgreedRota } from 'api/hooks/useAgreedRota';
import ReactSelect from 'components/Select';
import useGetTeams from 'api/hooks/useGetTeams';
import CreateNewTeamAgreedRota from './CreateNewTeamAgreedRota';

interface SelectAgreedRotaProps {
  user: User;
  selectedTeamId: string;
  selectedAgreedRotaId: string;
  isDuplicateVisits: boolean;
  onSelectedTeam: (teamId: string, teamName: string) => void;
  onSelectedRota: (rotaId: string, date: number) => void;
  setIsDisable: (value: boolean) => void;
}

const SelectAgreedRota = ({
  user,
  selectedTeamId,
  selectedAgreedRotaId,
  isDuplicateVisits,
  onSelectedTeam,
  onSelectedRota,
  setIsDisable,
}: SelectAgreedRotaProps) => {
  const disablePublishing = false;
  const navigate = useNavigate();
  const { teams, teamsLoading } = useGetTeams();
  const selectedTeam = teams?.find((t: Team) => t.teamId === selectedTeamId);
  const selectedTeamOption = { value: selectedTeam?.teamId ?? '', label: selectedTeam?.teamName ?? '' };
  const teamOptions = teams.map((t) => ({ value: t.teamId, label: t.teamName }));
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const [isPublished, setIsPublished] = React.useState<boolean>(false);

  const { agreedRotas, agreedRotasLoading } = useGetAgreedRota({
    teamId: selectedTeamId,
  });
  const [selectedAgreedRota, setSelectedAgreedRota] = useState<AgreedRota | undefined>(agreedRotas?.find((aR: AgreedRota) => aR.id === selectedAgreedRotaId));
  const selectedAgreedRotaOption = {
    value: selectedAgreedRota?.id ?? '',
    label: `${format(selectedAgreedRota?.startRotaDateTime ?? 0, 'PP')} - ${format(selectedAgreedRota?.endRotaDateTime ?? 0, 'PP')}`,
  };
  const agreedRotasOptions = agreedRotas.map((aR) => ({
    value: aR.id ?? '',
    label: `${format(aR?.startRotaDateTime ?? 0, 'PP')} - ${format(aR?.endRotaDateTime ?? 0, 'PP')}`,
  }));
  const { saveAgreedRota, mutationSaveAgreedRota } = useSaveAgreedRota({
    teamId: selectedTeamId,
  });

  const { removeAgreedRota, mutationRemoveAgreedRota } = useRemoveAgreedRota({
    teamId: selectedTeamId,
  });

  const { publishAgreedRota, mutationPublishAgreedRota } = usePublishAgreedRota();

  useEffect(() => {
    setSelectedAgreedRota(agreedRotas?.find((aR: AgreedRota) => aR.id === selectedAgreedRotaId));
  }, [agreedRotas, selectedAgreedRotaId]);

  useEffect(() => {
    let isRotaSelected = false;
    agreedRotas?.forEach((aR: AgreedRota) => {
      if (aR.id && aR.startRotaDateTime && aR.endRotaDateTime && isCurrentRota(aR.startRotaDateTime, aR.endRotaDateTime)) {
        onSelectedRota(aR.id, aR.startRotaDateTime);
        isRotaSelected = true;
      }
    });
    if (!isRotaSelected && agreedRotas?.length > 0) {
      const latestRota = agreedRotas[0];
      onSelectedRota(latestRota?.id || '', latestRota?.startRotaDateTime || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreedRotas]);

  useEffect(() => {
    const rotas = [user.rota.data.current, ...(user.rota.data.future || []), ...(user.rota.data.previous || [])];
    const isRotaPublished =
      selectedAgreedRota &&
      rotas.find(
        (r) =>
          r !== null &&
          r?.startRotaDateTime &&
          selectedAgreedRota.startRotaDateTime &&
          removeTime(r.startRotaDateTime) === removeTime(selectedAgreedRota.startRotaDateTime) &&
          r.endRotaDateTime &&
          selectedAgreedRota.endRotaDateTime &&
          removeTime(r.endRotaDateTime) === removeTime(selectedAgreedRota.endRotaDateTime),
      );
    setIsPublished(!!isRotaPublished);
  }, [user, selectedAgreedRota]);

  useEffect(() => {
    if (selectedAgreedRota) {
      const rotas = [user.rota.data.current, ...(user.rota.data.future || []), ...(user.rota.data.previous || [])];
      const isRotaPublished = rotas?.find(
        (r) =>
          r !== null &&
          r?.startRotaDateTime &&
          selectedAgreedRota?.startRotaDateTime &&
          selectedAgreedRota?.endRotaDateTime &&
          removeTime(r?.startRotaDateTime) === removeTime(selectedAgreedRota?.startRotaDateTime) &&
          r?.endRotaDateTime &&
          removeTime(r?.endRotaDateTime) === removeTime(selectedAgreedRota?.endRotaDateTime),
      );

      if ((selectedAgreedRota?.endRotaDateTime && isBefore(selectedAgreedRota.endRotaDateTime, Date.now())) || !!isRotaPublished) {
        setIsDisable(true);
      } else {
        setIsDisable(false);
      }
    }
  }, [selectedAgreedRota, setIsDisable, user]);

  const isCurrentRota = (start: number, end: number) => {
    return isWithinInterval(Date.now(), { start, end });
  };

  const isFutureRota = (end: number) => {
    return isBefore(Date.now(), end);
  };

  const onSaveAgreedRota = async () => {
    await saveAgreedRota({
      variables: {
        input: {
          teamId: selectedTeamId,
        },
      },
    });
  };

  const onRemoveAgreedRota = async () => {
    await removeAgreedRota({
      variables: {
        input: {
          teamId: selectedTeamId,
          id: selectedAgreedRota?.id,
        },
      },
    });
  };

  const onCreateAgreedRota = async (startDate: number) => {
    await saveAgreedRota({
      variables: {
        input: {
          teamId: selectedTeamId,
          startDateTime: startDate,
        },
      },
    });
  };

  const onPublishAgreedRota = async () => {
    await publishAgreedRota({
      variables: {
        input: {
          teamId: selectedTeamId,
          agreedRotaId: selectedAgreedRotaId,
        },
      },
    });
    setIsPublished(true);
    setIsDisable(true);
    setOpenSnack(true);
  };

  const onRotaChange = (option: SelectOption) => {
    const newRota = agreedRotas?.find((aR: AgreedRota) => aR.id === option.value);
    if (newRota?.id && newRota?.startRotaDateTime) onSelectedRota(newRota?.id, newRota?.startRotaDateTime);
    setSelectedAgreedRota(newRota);
  };

  if (mutationSaveAgreedRota.loading || mutationRemoveAgreedRota.loading || mutationPublishAgreedRota.loading) {
    return <Loading />;
  }

  return (
    <>
      <Message response={[mutationPublishAgreedRota]} openSnack={openSnack} setOpenSnack={setOpenSnack} />
      <div className="p-4 border border-gray-200 rounded-lg">
        <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-4">
          <div className="flex flex-col xl:flex-row gap-2">
            <ReactSelect
              options={teamOptions}
              onChange={(option) => onSelectedTeam(option.value, option.label)}
              selectedValue={selectedTeamOption}
              isContained
              isLoading={teamsLoading}
              testId="select-rota"
              searchable
            />
            {agreedRotas?.length !== 0 && (
              <ReactSelect
                options={agreedRotasOptions}
                onChange={onRotaChange}
                selectedValue={selectedAgreedRotaOption}
                isContained
                isLoading={agreedRotasLoading}
              />
            )}
          </div>
          <div className="flex flex-col lg:flex-row lg:items-center gap-2">
            <div className="flex flex-col gap-2">
              {agreedRotas?.length !== 0 && (
                <button
                  type="button"
                  className="text-primary-700 rounded-lg border border-primary-700 text-md leading-md font-medium flex items-center gap-2 px-3 py-2 disabled:opacity-50"
                  data-cy="team-agreed-rota-navigate-live-button"
                  onClick={() => {
                    navigate('/team-shifts');
                  }}
                >
                  <Schedule />
                  Go to live rota
                </button>
              )}
              {selectedAgreedRota &&
                agreedRotas &&
                selectedAgreedRota?.id === agreedRotas[0]?.id &&
                selectedAgreedRota.startRotaDateTime &&
                selectedAgreedRota.endRotaDateTime &&
                !isCurrentRota(selectedAgreedRota.startRotaDateTime, selectedAgreedRota.endRotaDateTime) &&
                isFutureRota(selectedAgreedRota.endRotaDateTime) &&
                !isPublished && (
                  <button
                    type="button"
                    className="bg-error-700 rounded-lg text-white text-md leading-md font-medium flex items-center gap-2 px-3 py-2 disabled:opacity-50"
                    onClick={onRemoveAgreedRota}
                    data-cy="agreed-rota-delete-button"
                  >
                    <Delete />
                    <div>Delete template</div>
                  </button>
                )}
            </div>
            <div className="flex flex-col gap-2">
              {agreedRotas?.length !== 0 && (
                <button
                  type="button"
                  className="bg-primary-700 rounded-lg text-white text-md leading-md font-medium flex items-center gap-2 px-3 py-2 disabled:opacity-50"
                  onClick={onSaveAgreedRota}
                  disabled={isDuplicateVisits}
                  data-cy="team-agreed-rota-add-button"
                >
                  <Add />
                  <div>New template</div>
                </button>
              )}
              {!user.isGuest && !disablePublishing && agreedRotas?.length !== 0 && !isPublished && (
                <button
                  type="button"
                  className="bg-primary-700 rounded-lg text-white text-md leading-md font-medium flex items-center gap-2 px-3 py-2 disabled:opacity-50"
                  onClick={onPublishAgreedRota}
                  disabled={isDuplicateVisits}
                  data-cy="team-agreed-rota-publish-button"
                >
                  <Publish />
                  Publish template
                </button>
              )}
            </div>
          </div>
          {agreedRotas && agreedRotas?.length === 0 && !agreedRotasLoading && <CreateNewTeamAgreedRota onCreateAgreedRota={onCreateAgreedRota} />}
        </div>

        {selectedAgreedRota &&
          isPublished &&
          selectedAgreedRota?.endRotaDateTime &&
          isFutureRota(selectedAgreedRota?.endRotaDateTime) &&
          selectedAgreedRota?.startRotaDateTime &&
          !isCurrentRota(selectedAgreedRota?.startRotaDateTime, selectedAgreedRota?.endRotaDateTime) && (
            <div className="mt-4 text-md leading-md text-gray-800 flex items-center gap-2">
              <InfoOutlined className="text-primary-700" />
              There is already a live rota in Team Shifts for these 2 weeks, you will need to delete it in "Manage Rotas" first before you can edit and publish
              this one.
            </div>
          )}
        {selectedAgreedRota &&
          isPublished &&
          selectedAgreedRota?.startRotaDateTime &&
          selectedAgreedRota?.endRotaDateTime &&
          isCurrentRota(selectedAgreedRota?.startRotaDateTime, selectedAgreedRota?.endRotaDateTime) && (
            <div className="mt-4 text-md leading-md text-gray-800 flex items-center gap-2">
              <InfoOutlined className="text-primary-700" />
              This rota is currently live in Team Shifts and cannot be edited and published
            </div>
          )}
        {selectedAgreedRota &&
          selectedAgreedRota?.startRotaDateTime &&
          selectedAgreedRota?.endRotaDateTime &&
          !isCurrentRota(selectedAgreedRota?.startRotaDateTime, selectedAgreedRota?.endRotaDateTime) &&
          !isFutureRota(selectedAgreedRota?.endRotaDateTime) && (
            <div className="mt-4 text-md leading-md text-gray-800 flex items-center gap-2">
              <InfoOutlined className="text-primary-700" />
              This rota is in the past. It can't be edited or published.
            </div>
          )}
        {agreedRotas?.length === 0 && (
          <div className="mt-4 text-md leading-md text-gray-800 flex items-center gap-2">
            <InfoOutlined className="text-primary-700" />
            This team has never had an agreed rota. Choose the start date and create the first rota template.
          </div>
        )}
      </div>
    </>
  );
};

export default SelectAgreedRota;
