import { SelfImprovement, Groups } from '@mui/icons-material';
import { LocationBar } from 'components';
import { Helmet } from 'react-helmet';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUser, UserContextProps } from 'context/userContext';

const WelllingbeingNav = () => {
  const { user } = useUser() as UserContextProps;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const teamId = searchParams.get('teamId') || user?.teamId || '';

  return (
    <>
      <Helmet>
        <title>Wellingbeing Board</title>
      </Helmet>
      <LocationBar section="People" page="Wellbeing board" Icon={SelfImprovement} />

      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-col sm:flex-row justify-between items-center gap-3">
          <div className="text-display-sm sm:text-display-lg leading-display-sm sm:leading-display-lg sm:tracking-display-lg text-black font-medium">
            Wellbeing Board
          </div>
          <button
            type="button"
            className="text-primary-700 px-4 py-2 border border-primary-700 flex flex-row items-center gap-2 rounded-lg font-medium text-md w-full sm:w-fit"
            onClick={() => navigate({ pathname: '/team-meetings', search: `teamId=${teamId}` })}
            data-cy="team-detail-button"
          >
            <Groups />
            Team meetings
          </button>
        </div>
      </div>
    </>
  );
};

export default WelllingbeingNav;
