import React, { useState, useEffect } from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Expense, Invoice } from '__generated__/graphql';
import { useGetExpenses } from 'api/hooks/useExpense';
import { Table, Loading } from 'components';
import { MapInvoice } from './Mapper';
import { ExpenseChecklistItem, columnVisibility } from './types';

interface InvoiceExpensesProps {
  invoice: Invoice | undefined;
  onSubmit: (invoice: Invoice) => void;
}

export default function InvoiceExpenses({ invoice, onSubmit }: InvoiceExpensesProps) {
  const [rowSelection, setRowSelection] = useState<ExpenseChecklistItem[]>([]);
  const { expenses, loading } = useGetExpenses({ period: invoice?.payrollStartDate || 0 });

  const setChecked = (e: Expense, inv?: Invoice) => {
    return inv?.expenseItems?.some((i) => i?.externalId === e.externalId && i?.included) || false;
  };

  useEffect(() => {
    if (expenses && expenses?.length > 0) {
      const checked = expenses
        .filter((e) => e?.status === 'approved')
        .map((e) => ({
          id: e.id,
          checked: setChecked(e, invoice),
          expenseDate: e?.expenseDate,
          description: e?.description,
          comment: e?.comment,
          totalAmount: e?.totalAmount,
          externalId: e?.externalId,
          employeeFullName: e?.employeeFullName,
        }));
      setRowSelection(checked);
    }
  }, [expenses, invoice]);

  const onSaveInvoiceItems = () => {
    onSubmit(
      MapInvoice({
        ...invoice,
        expenseItems: rowSelection
          .filter((r) => r.checked)
          .map((e) => ({
            id: e.id,
            included: e.checked,
            expenseDate: e?.expenseDate,
            description: e?.description,
            comment: e?.comment,
            totalAmount: e?.totalAmount,
            externalId: e?.externalId,
            employeeFullName: e?.employeeFullName,
          })),
      }),
    );
  };

  const onSelectItem = (externalId: string) => {
    setRowSelection(rowSelection.map((item) => (item.externalId === externalId ? { ...item, checked: !item.checked } : item)));
  };

  const RowActions = ({ row }: { row: ExpenseChecklistItem }) => {
    return (
      <input
        onChange={(e) => onSelectItem(e.target.value)}
        checked={row ? row.checked : true}
        type="checkbox"
        className="w-4 h-4 text-primary-300 bg-gray-100 border-gray-300 rounded cursor-pointer"
        value={row.externalId || ''}
      />
    );
  };

  const columnHelper = createColumnHelper<ExpenseChecklistItem>();

  const columns1 = [
    columnHelper.accessor('externalId', {
      enableSorting: false,
      header: () => 'ID',
      cell: (info) => <div data-cy="list-externalId">{info.renderValue()}</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('employeeFullName', {
      enableSorting: false,
      header: () => 'Support Worker',
      cell: (info) => <div data-cy="list-name">{info.renderValue()}</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('totalAmount', {
      enableSorting: false,
      header: () => 'Total',
      cell: (info) => <div data-cy="list-total">£{info.renderValue()?.toFixed(2)}</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('expenseDate', {
      enableSorting: false,
      header: () => 'Expense Date',
      cell: (info) => <div data-cy="list-expenseDate">{info.renderValue()}</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('description', {
      enableSorting: false,
      header: () => 'Description',
      cell: (info) => <div data-cy="list-description">{info.renderValue()?.substring(0, 50)}</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('comment', {
      enableSorting: false,
      header: () => 'Comment',
      cell: (info) => <div data-cy="list-comment">{info.renderValue()?.substring(0, 30)}..</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (props) => <RowActions row={props.row.original} />,
      header: () => (
        <button type="button" onClick={onSaveInvoiceItems} className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
          Save
        </button>
      ),
    }),
  ] as Array<ColumnDef<ExpenseChecklistItem, unknown>>;

  return (
    <div className="mt-8">
      <div className="flex flex-col mt-2">
        {loading && <Loading isComponent />}
        {!loading && rowSelection && rowSelection.length > 0 && (
          <Table<ExpenseChecklistItem> pageSize={500} data={rowSelection} columns={columns1} columnVisibility={columnVisibility} />
        )}
        {!loading && (!rowSelection || rowSelection.length === 0) && <div>There are no expenses to consider.</div>}
      </div>
    </div>
  );
}
