import { useState, lazy, Suspense } from 'react';
import { client } from 'api';
import { Auth } from 'aws-amplify';
import Helmet from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { groupByKey } from 'services/arrays';
import { Bars3Icon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { BellIcon, ChatBubbleBottomCenterIcon } from '@heroicons/react/24/solid';
import { Maybe, Notification } from '__generated__/graphql';
import { useGetNotifications, useRemoveNotifications, useUpdateNotification } from 'api/hooks/useNotifications';
import { User } from 'types/types';
import { useGetNewChatMessages } from 'api/hooks/useChat';
import Loading from '../Loading';
import Title from '../Title';
import { useGetAnswerStatus } from '../../api/hooks/useConfirmationPractices';

const Drawer = lazy(() => import('../Drawer/index'));
const NotificationsPopup = lazy(() => import('./NotificationsPopup'));

interface HeaderProps {
  title: string;
  user: User | undefined;
  signout: () => void;
  userLoading: boolean;
}

const Header = ({ title, signout, user, userLoading }: HeaderProps) => {
  const handleLogout = async () => {
    await Auth.signOut();
    client.resetStore();
    signout();
    window.location.assign('/');
  };

  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const { answerStatus } = useGetAnswerStatus({ supportWorkerId: user?.profile });
  const { newChatMessages } = useGetNewChatMessages({ userId: user?.profile });

  const { notifications } = useGetNotifications({
    teamId: user?.teamId,
    rotaId: user?.rota?.id,
    supportWorkerId: user?.profile,
  });
  const { removeNotifications } = useRemoveNotifications({
    teamId: user?.teamId,
    rotaId: user?.rota?.id,
    supportWorkerId: user?.profile,
  });

  const { updateNotification } = useUpdateNotification({
    teamId: user?.teamId,
    rotaId: user?.rota?.id,
    supportWorkerId: user?.profile,
  });

  const removeNotification = async (id: string) => {
    await updateNotification({
      variables: {
        input: { id, teamId: user?.teamId, supportWorkerId: user?.profile },
      },
    });
  };

  const onRemoveNotifications = async () => {
    await removeNotifications({
      variables: {
        input: { teamId: user?.teamId, supportWorkerId: user?.profile },
      },
    });
    setNotificationsOpen(false);
  };

  if (userLoading) {
    return <Loading />;
  }

  const hasWarnings = notifications?.find((n: Notification) => n.type === 'Warning');
  const notificationsGrouped: { [key: string]: Maybe<Notification>[] } = groupByKey(notifications || [], 'type');
  const ownCPStatus = answerStatus[0] || 'error';
  const newMessagesNumber = newChatMessages.reduce((total, room) => total + (room?.newMessages ?? 0), 0);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {user?.sub && (
        <Suspense fallback={null}>
          <Drawer isOpen={drawerOpen} onClose={() => setDrawerOpen(false)} handleLogout={handleLogout} user={user} />
        </Suspense>
      )}
      <div className="shadow-header py-4 px-6">
        <div className="flex justify-between items-center">
          <div>
            {!user?.sub && <img data-cy="logo" width="128" src="/logo.png" alt="Bellevie Care" />}
            {user?.sub && (
              <button
                type="button"
                aria-label="open menu"
                data-cy="drawer"
                className="shadow-button rounded-full p-2 text-gray-900 hover:bg-primary-25"
                onClick={() => setDrawerOpen(true)}
              >
                <Bars3Icon className="w-5 h-5" />
              </button>
            )}
          </div>
          <div className="flex items-center gap-4">
            <div className="hidden md:block">{user && <Title user={user} />}</div>
            {ownCPStatus === 'overdue' && (
              <div className="sm:flex items-center text-error-600 hidden">
                <button
                  type="button"
                  className="flex items-center gap-2 text-error-600 text-lg leading-lg font-medium"
                  onClick={() => navigate('/confirmation-practices')}
                >
                  <ExclamationTriangleIcon className="w-6 h-6" />
                  Conf. prac. overdue
                </button>
              </div>
            )}
            {user?.sub && (
              <div className="flex items-center gap-3">
                <button
                  type="button"
                  aria-label="chat notifications"
                  className="relative text-primary-700 hover:text-primary-500 disabled:text-gray-100"
                  disabled={newMessagesNumber === 0}
                  onClick={() => navigate('/chat')}
                >
                  <ChatBubbleBottomCenterIcon className="w-6 h-6" />
                  {newMessagesNumber > 0 && (
                    <span
                      className={`absolute ${
                        hasWarnings ? 'bg-error-300' : 'bg-warning-300'
                      }  h-5 w-5 text-primary-900 rounded-full -top-2.5 -right-2 flex items-center justify-center text-xs font-medium`}
                    >
                      {newMessagesNumber}
                    </span>
                  )}
                </button>
                <button
                  data-cy="notifications-button"
                  type="button"
                  aria-label="notifications"
                  className="relative text-primary-700 hover:text-primary-500 disabled:text-gray-100"
                  disabled={notifications?.length === 0}
                  onClick={() => setNotificationsOpen(true)}
                >
                  <BellIcon className="w-6 h-6" />
                  {notifications?.length > 0 && (
                    <span
                      className={`absolute ${
                        hasWarnings ? 'bg-error-300' : 'bg-warning-300'
                      }  h-5 w-5 text-primary-900 rounded-full -top-2.5 -right-2 flex items-center justify-center text-xs font-medium`}
                    >
                      {notifications?.length}
                    </span>
                  )}
                </button>
                {/* <button
                  type="button"
                  aria-label="cross team notification"
                  className="relative text-primary-700 hover:text-primary-500 disabled:text-gray-100"
                  disabled={crossTeamVisits?.length === 0}
                  onClick={() => setCrossTeamNotificationsOpen(true)}
                >
                  <UserGroupIcon className="w-6 h-6" />
                  {crossTeamVisits?.length > 0 && (
                    <span
                      className={`absolute ${
                        hasWarnings ? 'bg-error-300' : 'bg-warning-300'
                      }  h-5 w-5 text-primary-900 rounded-full -top-2.5 -right-2 flex items-center justify-center text-xs font-medium`}
                    >
                      {crossTeamVisits?.length}
                    </span>
                  )}
                </button> */}
                <button
                  type="button"
                  aria-label="my shifts"
                  data-cy="myshifts-button"
                  onClick={() => {
                    navigate('/my-shifts');
                  }}
                  className="ml-3"
                >
                  <img src={user.avatar} alt="avatar" className="rounded-full w-8 h-8" />
                </button>
              </div>
            )}
          </div>
        </div>
        {ownCPStatus === 'overdue' && (
          <div className="flex items-center justify-center text-error-600 sm:hidden">
            <button
              type="button"
              className="flex items-center gap-2 text-error-600 text-lg leading-lg font-medium"
              onClick={() => navigate('/confirmation-practices')}
            >
              <ExclamationTriangleIcon className="w-6 h-6" />
              Conf. prac. overdue
            </button>
          </div>
        )}
      </div>
      {notificationsOpen && (
        <Suspense fallback={null}>
          <NotificationsPopup
            notificationsGrouped={notificationsGrouped}
            onRemoveNotifications={onRemoveNotifications}
            removeNotification={removeNotification}
            onClose={() => setNotificationsOpen(false)}
          />
        </Suspense>
      )}
      {/* {crossTeamNotificationsOpen && (
        <Suspense fallback={<Loading />}>
          <CrossTeamNotificationsPopup notificationsGrouped={crossTeamVisitsGrouped} onClose={() => setCrossTeamNotificationsOpen(false)} />
        </Suspense>
      )} */}
    </>
  );
};

export default Header;
