import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Invoice, Shift } from '__generated__/graphql';
import { useGetMileageVisitsForCustomerByDateRange } from 'api/hooks/useInvoice';
import { Table, Loading } from 'components';
import { MapInvoice } from './Mapper';
import { ChecklistItem, columnVisibility } from './types';

interface InvoiceCareMileagePackageProps {
  invoice: Invoice | undefined;
  onSubmit: (invoice: Invoice) => void;
}

export default function InvoiceCareMileagePackage({ invoice, onSubmit }: InvoiceCareMileagePackageProps) {
  const [rowSelection, setRowSelection] = useState<ChecklistItem[]>([]);

  const { visits, loading } = useGetMileageVisitsForCustomerByDateRange({
    customerId: invoice?.customerId,
    startDateTime: invoice?.payrollStartDate,
    endDateTime: invoice?.payrollEndDate,
    teamId: invoice?.teamId,
  });

  const setChecked = (v: Shift, inv?: Invoice) => {
    // if (inv?.mileageItems?.length === 0 && v?.status === 'active') return true;
    return inv?.mileageItems?.some((i) => i?.id === v.id && i?.included) || false;
  };

  useEffect(() => {
    if (visits?.length > 0) {
      const checked = visits
        .filter((v) => v?.travelWithCustomerMileage && v?.travelWithCustomerMileage > 0)
        .filter((v) => v?.status === 'active')
        .map((v) => ({
          id: v.id,
          checked: setChecked(v, invoice),
          status: v.status,
          startDateTime: v?.startDateTime,
          endDateTime: v?.endDateTime,
          type: v?.type === 'visit' ? 'rota' : v?.type,
          cancelledDateTime: v?.cancelledDateTime,
          whoCancelled: v?.whoCancelled,
          oneOffVisitReason: v?.oneOffVisitReason,
          oneOffVisitNote: v?.oneOffVisitNote,
          travelWithCustomerMileage: v?.travelWithCustomerMileage,
          travelWithCustomerDestination: v?.travelWithCustomerDestination,
        }));
      setRowSelection(checked);
    }
  }, [visits, invoice]);

  const onSaveInvoiceItems = () => {
    onSubmit(
      MapInvoice({
        ...invoice,
        mileageItems: rowSelection
          .filter((r) => r.checked)
          .map((r) => ({
            id: r.id,
            included: r.checked,
            type: r.type,
            status: r.status,
            startDateTime: r.startDateTime || 0,
            endDateTime: r.endDateTime || 0,
            whoCancelled: r.whoCancelled,
            cancelledDateTime: r.cancelledDateTime,
            travelWithCustomerMileage: r?.travelWithCustomerMileage,
            travelWithCustomerDestination: r?.travelWithCustomerDestination,
          })),
      }),
    );
  };

  const onSelectItem = (id: string) => {
    setRowSelection(rowSelection.map((item) => (item.id === id ? { ...item, checked: !item.checked } : item)));
  };

  const RowActions = ({ row }: { row: ChecklistItem }) => {
    return (
      <input
        onChange={(e) => onSelectItem(e.target.value)}
        checked={row.status ? row.checked : true}
        type="checkbox"
        className="w-4 h-4 text-primary-300 bg-gray-100 border-gray-300 rounded cursor-pointer"
        value={row.id || ''}
      />
    );
  };

  const columnHelper = createColumnHelper<ChecklistItem>();

  const columns1 = [
    columnHelper.accessor('startDateTime', {
      enableSorting: false,
      header: () => '#',
      cell: (info) => <div data-cy="list-index">{info.row.index + 1}</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('startDateTime', {
      enableSorting: false,
      header: () => 'Date',
      cell: (info) => <div data-cy="list-invoiceDate">{format(info.renderValue() ?? 0, 'PPPP')}</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('endDateTime', {
      enableSorting: false,
      header: () => 'Time',
      cell: (info) => (
        <div data-cy="list-invoiceDate">
          {format(info.row.original.startDateTime ?? 0, 'HH:mm')} - {format(info.renderValue() ?? 0, 'HH:mm')}
        </div>
      ),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('travelWithCustomerMileage', {
      enableSorting: false,
      header: () => 'Mileage',
      cell: (info) => {
        return <div data-cy="list-name">{info.renderValue()}</div>;
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('travelWithCustomerDestination', {
      enableSorting: false,
      header: () => 'Destination',
      cell: (info) => {
        return <div data-cy="list-name">{info.renderValue()}</div>;
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (props) => <RowActions row={props.row.original} />,
      header: () => (
        <button type="button" onClick={onSaveInvoiceItems} className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
          Save
        </button>
      ),
    }),
  ] as Array<ColumnDef<ChecklistItem, unknown>>;

  return (
    <div className="mt-8">
      <div className="flex flex-col mt-2">
        {loading && <Loading isComponent />}
        {!loading && rowSelection && rowSelection.length > 0 && (
          <Table<ChecklistItem> pageSize={500} data={rowSelection} columns={columns1} columnVisibility={columnVisibility} />
        )}
        {!loading && (!rowSelection || rowSelection.length === 0) && <div>There are no mileage claims to consider.</div>}
      </div>
    </div>
  );
}
