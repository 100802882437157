import { useState } from 'react';
import { ReportingHoursPeriod } from '__generated__/graphql';
import { addMonthsToDate, formatTime, getFirstDayOfPayroll, getLastDayOfPayroll, getPeriodsForYear } from 'services/helpers';
import { SelectOption } from 'types/types';
import ReactSelect from '../../components/Select';

interface CreateReportingHoursPeriodProps {
  reportingHoursPeriod: ReportingHoursPeriod[];
  onClose: () => void;
  onUpdate: (startDateTime: number, endDateTime: number) => void;
}

export default function CreateReportingHoursPeriod({ reportingHoursPeriod, onClose, onUpdate }: CreateReportingHoursPeriodProps) {
  const activeReportingHours = reportingHoursPeriod.map((rhp) => rhp.startDateTime);

  const periods = getPeriodsForYear()
    .filter((rhp) => !activeReportingHours.includes(rhp) && rhp > addMonthsToDate(Date.now(), -6))
    .map((p) => {
      return { label: formatTime(p, 'do MMMM, yyyy'), value: getFirstDayOfPayroll(p).toString() };
    })
    .slice(0, 3);

  const [period, setPeriod] = useState<SelectOption>();

  const onSavePackagePrice = () => {
    if (period) {
      const startDateTime = getFirstDayOfPayroll(parseFloat(period.value));
      const endDateTime = getLastDayOfPayroll(parseFloat(period.value));
      onUpdate(startDateTime, endDateTime);
      onClose();
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-[1200] outline-none focus:outline-none">
        <div className="relative w-auto mx-auto max-w-5xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none p-6 max-h-screen md:max-h-[70vh] overflow-y-auto">
            <div className="text-gray-900 text-lg leading-lg font-semibold mb-5">Create New Reporting Hours Period</div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Period</div>
              <div className="mt-2">
                <ReactSelect testId="generate-period-select" options={periods} onChange={setPeriod} selectedValue={period} searchable={true} />
              </div>
            </div>
            <div className="flex justify-between items-center mt-5">
              <button type="button" className="text-gray-500 font-semibold text-md leading-md" onClick={onClose}>
                Cancel
              </button>
              <button
                type="button"
                data-cy="save-package-button"
                className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
                onClick={onSavePackagePrice}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-gray-900" />
    </>
  );
}
