import { useState } from 'react';
import { Input } from 'components';
import { SelectOption } from 'types/types';
import { ChatUser, Maybe } from '__generated__/graphql';
import { useGetSupportWorkers } from 'api/hooks/useSupportWorkers';
import ReactMultiSelect from 'components/MultiSelect';
import { useGetFamilyMembers } from 'api/hooks/useFamily';
import { Delete } from '@mui/icons-material';

interface EditChatRoomProps {
  members: Maybe<ChatUser>[];
  topic: string;
  teamId: string;
  onCloseModal: () => void;
  onEdit: (members: ChatUser[], topic: string) => void;
  onDelete: () => void;
}

const EditChatRoom = ({ members, topic, teamId, onCloseModal, onEdit, onDelete }: EditChatRoomProps) => {
  const { supportWorkers } = useGetSupportWorkers({});
  const { familyMembers } = useGetFamilyMembers({ teamId });

  const [selectedSupportWorkers, setSelectedSupportWorkers] = useState<SelectOption[]>(
    members?.filter((m) => m?.type !== 'Family').map((m) => ({ value: m?.id ?? '', label: m?.name ?? '' })) ?? [],
  );
  const [selectedFamily, setSelectedFamily] = useState<SelectOption[]>(
    members?.filter((m) => m?.type === 'Family').map((m) => ({ value: m?.id ?? '', label: m?.name ?? '' })) ?? [],
  );
  const [newTopic, setNewTopic] = useState(topic);

  const supportWorkersOptions = supportWorkers.map((sw) => ({ value: sw.id, label: sw.fullName }));
  const familyMembersOptions = familyMembers.map((f) => ({ value: f.id ?? '', label: f.name ?? '' }));

  const onEditRoom = () => {
    const newSWMembers = selectedSupportWorkers.map((selectedSW) => {
      const supportWorker = supportWorkers.find((sw) => selectedSW.value === sw.id);
      return {
        avatar: supportWorker?.avatar,
        id: supportWorker?.id,
        name: supportWorker?.fullName,
        newMessages: 0,
        type: 'Support Worker',
      };
    });

    const newFamilyMembers = selectedFamily.map((f) => ({
      avatar: '',
      id: f.value,
      name: f.label,
      newMessages: 0,
      type: 'Family',
    }));

    onEdit([...newSWMembers, ...newFamilyMembers], newTopic);
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto mx-auto max-w-5xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none p-6 max-h-screen md:max-h-[70vh] overflow-y-auto md:w-[480px] lg:w-[680px]">
            <div className="text-gray-900 text-lg leading-lg font-semibold mb-5">Edit Chat Room</div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Topic</div>
              <div className="mt-2">
                <Input value={newTopic} onChange={setNewTopic} />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Support workers</div>
              <div className="mt-2">
                <ReactMultiSelect options={supportWorkersOptions} onChange={setSelectedSupportWorkers} selectedValue={selectedSupportWorkers} searchable />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Family members</div>
              <div className="mt-2">
                <ReactMultiSelect options={familyMembersOptions} onChange={setSelectedFamily} selectedValue={selectedFamily} searchable />
              </div>
            </div>
            <div className="flex justify-between items-center">
              <button type="button" className="text-gray-500 font-semibold text-md leading-md" onClick={onCloseModal}>
                Cancel
              </button>
              <div className="flex items-center gap-3">
                <button type="button" aria-label="delete chat room" className="text-white bg-error-600 rounded-lg p-2.5" onClick={onDelete}>
                  <Delete className="!w-5 !h-5" />
                </button>
                <button type="button" className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md" onClick={onEditRoom}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-gray-900" />
    </>
  );
};

export default EditChatRoom;
