import { useEffect, useState, useMemo } from 'react';
import { LineAxis } from '@mui/icons-material';
import { Chart } from 'react-google-charts';
import { useUser, UserContextProps } from 'context/userContext';
import { useGeneric, GenericContextProps } from 'context/genericContext';
import { useGetCustomers } from 'api/hooks/useCustomers';
import { useGetSupportWorkers } from 'api/hooks/useSupportWorkers';
import { useGetLeaveForTeam } from 'api/hooks/useLeave';
import { useGetAllReportingHoursPeriod } from 'api/hooks/useReportingHours';
import { Loading, LocationBar, Filters } from 'components';
import { Helmet } from 'react-helmet';
import { AdjustmentsHorizontalIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { SelectOption } from 'types/types';
import {
  getStartOfDay,
  getEndOfDay,
  formatTime,
  getFirstDayOfPayroll,
  getLastDayOfPayroll,
  getPeriodsForYear,
  getPreviousMonth,
  getPreviousDay,
} from 'services/helpers';
import { Absence, Customer, ReportingHoursPeriod, SupportWorker } from '__generated__/graphql';
import { useSearchParams } from 'react-router-dom';
import { isToday } from 'date-fns';
import KpiStats, { calculateBankedHours } from './KpiStats';

const Kpi = () => {
  const periods = getPeriodsForYear().map((period) => ({ label: formatTime(period, 'MMMM, yyyy'), value: period.toString() }));
  periods.unshift({ label: 'Today', value: getStartOfDay(Date.now()).toString() });

  const { user, userLoading } = useUser() as UserContextProps;
  const { teams: genericTeams } = useGeneric() as GenericContextProps;
  const [teamsToUseForFilter, setTeamsToUseForFilter] = useState<string[]>([]);
  const [circle, setCircle] = useState('all');
  const [team, setTeam] = useState('all');
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
  const [startDateTime, setStartDateTime] = useState<number>(getStartOfDay(Date.now()));
  const [endDateTime, setEndDateTime] = useState<number>(getEndOfDay(Date.now()));
  const [previousStartDateTime, setPreviousStartDateTime] = useState<number>(getPreviousDay(Date.now()));
  const [period, setPeriod] = useState<string>(periods[0].value);
  const [searchParams, setSearchParams] = useSearchParams();

  const { customers, loading: cloading } = useGetCustomers({});
  const { supportWorkers, loading: swloading } = useGetSupportWorkers({});
  const { leave, loading: lloading } = useGetLeaveForTeam({ teamId: 'all', startDateTime, endDateTime });
  const { reportingHoursPeriod, loading: rhpLoading } = useGetAllReportingHoursPeriod({
    teamId: '0',
    period: startDateTime,
  });
  const { reportingHoursPeriod: previousReportingHoursPeriod, loading: rhpPreviousLoading } = useGetAllReportingHoursPeriod({
    teamId: '0',
    period: previousStartDateTime,
  });

  const activeTeams = genericTeams?.filter((t) => t.activeTeam && t.circleName.toLowerCase() !== 'dev/qa circle');
  const activeSupportWorkers = supportWorkers.filter((sw) => sw?.position?.toLowerCase() === 'wellbeing support worker');
  const activeCustomers = customers.filter((c) => c?.circleName !== 'Dev/QA Circle');

  const teams = useMemo(() => activeTeams?.map((t) => ({ value: t.teamId, label: t.teamName })) || [], [activeTeams]);
  const circles = useMemo(
    () =>
      activeTeams
        ?.map((t) => ({ value: t.circleId, label: t.circleName }))
        .filter((a, i) => activeTeams?.findIndex((s) => a.value && a.value === s.circleId) === i) || [],
    [activeTeams],
  );

  const [filters, setFilters] = useState([
    { name: 'period', selectedValue: periods[0], values: periods },
    { name: 'circle', selectedValue: { value: 'all', label: 'All' }, values: [{ value: 'all', label: 'All' }, ...circles] },
    { name: 'teams', selectedValue: { value: 'all', label: 'All' }, values: [{ value: 'all', label: 'All' }, ...teams] },
  ]);

  useEffect(() => {
    const periodSearch = searchParams.get('period');
    const circleSearch = searchParams.get('circle');
    const teamSearch = searchParams.get('teams');
    const newFilters = filters;

    if (periodSearch) {
      const selectedPeriod = filters[0].selectedValue.value;
      const selectedDate = parseInt(selectedPeriod, 10);

      if (isToday(selectedDate)) {
        setStartDateTime(getStartOfDay(selectedDate));
        setEndDateTime(getEndOfDay(selectedDate));
        setPreviousStartDateTime(getPreviousDay(selectedDate));
      } else {
        setStartDateTime(getFirstDayOfPayroll(selectedDate));
        setEndDateTime(getLastDayOfPayroll(selectedDate));
        setPreviousStartDateTime(getPreviousMonth(selectedDate));
      }
      setPeriod(selectedPeriod);
      const periodsOption = periods.find((t) => t.value === periodSearch);
      if (periodsOption) {
        newFilters[0].selectedValue = periodsOption;
      }
    }
    if (circleSearch) {
      setCircle(circleSearch);
      const circleTeams =
        circleSearch !== 'all' ? activeTeams?.filter((t) => t.circleId === circleSearch).map((t) => ({ value: t.teamId, label: t.teamName })) || [] : teams;
      newFilters[2].values = [{ value: 'all', label: 'All' }, ...circleTeams];
      setTeamsToUseForFilter(circleTeams.map((ct) => ct.value));
      const circleOption = activeTeams
        ?.map((t) => ({ value: t.circleId, label: t.circleName }))
        .filter((a, i) => activeTeams?.findIndex((s) => a.value && a.value === s.circleId) === i)
        .find((t) => t.value === circleSearch);
      if (circleOption) {
        newFilters[1].selectedValue = circleOption;
      }
    } else if (teamSearch) {
      setTeam(teamSearch);
      const teamOption = activeTeams?.map((t) => ({ value: t.teamId, label: t.teamName })).find((t) => t.value === teamSearch);
      if (teamOption) {
        newFilters[2].selectedValue = teamOption;
      }
    }
    setFilters(newFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTeams]);

  useEffect(() => {
    const newFilters = filters;
    newFilters[0].values = periods;
    newFilters[1].values = [{ value: 'all', label: 'All' }, ...circles];
    newFilters[2].values = [{ value: 'all', label: 'All' }, ...teams];
    setFilters(newFilters);
  }, [filters, teams, circles, periods]);

  const boardChartOptions = { chart: { title: 'Wellbeing Board' }, legend: { position: 'none' } };
  const teamChartOptions = { chart: { title: 'Banked hours Accured' }, legend: { position: 'none' } };

  const onSaveFilters = () => {
    const selectedPeriod = filters[0].selectedValue.value;
    const selectedDate = parseInt(selectedPeriod, 10);
    const newSearchParamsArray = filters.map((f) => ({ [f.name]: f.selectedValue.value }));
    setSearchParams(Object.assign({}, ...newSearchParamsArray));

    if (filters[0].selectedValue.label === 'Today') {
      setStartDateTime(getStartOfDay(selectedDate));
      setEndDateTime(getEndOfDay(selectedDate));
      setPreviousStartDateTime(getPreviousDay(selectedDate));
    } else {
      setStartDateTime(getFirstDayOfPayroll(selectedDate));
      setEndDateTime(getLastDayOfPayroll(selectedDate));
      setPreviousStartDateTime(getPreviousMonth(selectedDate));
    }
    setPeriod(selectedPeriod);
    setCircle(filters[1].selectedValue.value);
    setTeam(filters[2].selectedValue.value);
    setShowFiltersModal(false);
  };

  const filterByTeams = (c: Customer | SupportWorker | ReportingHoursPeriod | Absence) => {
    if (team === 'all' && circle === 'all') return true;
    if (team !== 'all' && c.teamId === team) return true;
    if (circle !== 'all' && c.teamId && teamsToUseForFilter.includes(c.teamId)) return true;
    return false;
  };

  const onFilterChange = (name: string, value: SelectOption) => {
    const newFilters = [...filters];
    const filterIndex = newFilters.findIndex((f) => f.name === name);

    if (name === 'period' && value.label === 'All') {
      newFilters[filterIndex].selectedValue = { ...periods[0] };
    } else {
      newFilters[filterIndex].selectedValue = value;
    }

    if (name === 'circle') {
      const circleTeams =
        value.value !== 'all' ? activeTeams?.filter((t) => t.circleId === value.value).map((t) => ({ value: t.teamId, label: t.teamName })) || [] : teams;
      newFilters[2].values = [{ value: 'all', label: 'All' }, ...circleTeams];
      setTeamsToUseForFilter(circleTeams.map((ct) => ct.value));
    }

    setFilters(newFilters);
  };

  if (cloading || swloading || userLoading || lloading || rhpLoading || rhpPreviousLoading) {
    return <Loading />;
  }

  const filteredLeave = leave.filter((c) => filterByTeams(c));
  const filteredCustomers = activeCustomers.filter((c) => filterByTeams(c));
  const filtereddSupportWorkers = activeSupportWorkers.filter((c) => filterByTeams(c));
  const filteredReportingHoursPeriod = reportingHoursPeriod.filter((c) => filterByTeams(c));
  const filteredPreviousReportingHoursPeriod = previousReportingHoursPeriod.filter((c) => filterByTeams(c));

  const wellbeingBoard = [
    ['', 'Number with status'],
    ['On Track', activeCustomers.filter((c) => filterByTeams(c) && c.wellbeingStatus === 'On Track').length],
    ['Off Track', activeCustomers.filter((c) => filterByTeams(c) && c.wellbeingStatus === 'Off Track').length],
    ['Slightly Off', activeCustomers.filter((c) => filterByTeams(c) && c.wellbeingStatus === 'Slightly Off').length],
    ['Paused', activeCustomers.filter((c) => filterByTeams(c) && c.wellbeingStatus === 'Paused').length],
  ];

  const teamBankedHours = [
    ['', 'Team banked hours'],
    ...teams.map((x) => [x.label, calculateBankedHours(reportingHoursPeriod.filter((t) => t.teamId === x.value)) / 24]),
  ];

  return (
    <>
      <Helmet>
        <title>Performance Metrics</title>
      </Helmet>
      <LocationBar section="Admin" page="Performance Metrics" Icon={LineAxis} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-col lg:flex-row items-center justify-between mt-8 md:mt-16">
          <div className="flex flex-col md:flex-row items-center gap-4 md:gap-9 w-full md:w-fit">
            <div className="text-display-sm sm:text-display-lg leading-display-sm sm:leading-display-lg sm:tracking-display-lg text-black font-medium">
              Performance Metrics
            </div>
            <div className="flex flex-col md:flex-row items-center gap-3 w-full md:w-fit">
              <button
                type="button"
                className={`relative border ${
                  team === 'all' ? 'border-gray-300' : 'border-gray-900'
                }  text-gray-700 text-md leading-md flex items-center px-4 py-2.5 rounded-lg shadow-xs justify-center w-full md:w-fit`}
                onClick={() => setShowFiltersModal(true)}
              >
                <AdjustmentsHorizontalIcon className="w-5 h-5 mr-2" />
                Filters
              </button>
              {period !== '0' && (
                <div
                  data-cy="tag-1"
                  className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center"
                >
                  {periods.find((type) => type.value === period)?.label}
                </div>
              )}
              {/* <div>{`${formatTime(startDateTime, 'PPP')} - ${formatTime(endDateTime, 'PPP')}`}</div> */}
              {user?.permissions?.admin && circle !== 'all' && (
                <div className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center">
                  {circles.find((t) => t.value === circle)?.label}
                  <button
                    type="button"
                    aria-label="remove team filter"
                    className="text-gray-500"
                    onClick={() => {
                      onFilterChange('circle', { value: 'all', label: 'All' });
                      const newSearchParamsArray = filters.map((f) => ({ [f.name]: f.selectedValue.value }));
                      setSearchParams(Object.assign({}, ...newSearchParamsArray));
                      setCircle('all');
                    }}
                  >
                    <XMarkIcon className="w-4 h-4 ml-2" />
                  </button>
                </div>
              )}
              {user?.permissions?.admin && team !== 'all' && (
                <div className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center">
                  {teams.find((t) => t.value === team)?.label}
                  <button
                    type="button"
                    aria-label="remove team filter"
                    className="text-gray-500"
                    onClick={() => {
                      onFilterChange('teams', { value: 'all', label: 'All' });
                      const newSearchParamsArray = filters.map((f) => ({ [f.name]: f.selectedValue.value }));
                      setSearchParams(Object.assign({}, ...newSearchParamsArray));
                      setTeam('all');
                    }}
                  >
                    <XMarkIcon className="w-4 h-4 ml-2" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <KpiStats
          customers={filteredCustomers}
          supportWorkers={filtereddSupportWorkers}
          leave={filteredLeave}
          reportingHoursPeriod={filteredReportingHoursPeriod}
          previousReportingHoursPeriod={filteredPreviousReportingHoursPeriod}
          startDateTime={startDateTime}
          endDateTime={endDateTime}
          previousDateTime={previousStartDateTime}
        />
        <div className="mt-5">
          <Chart chartType="Bar" width="100%" height="400px" data={wellbeingBoard} options={boardChartOptions} />
        </div>
        <div className="mt-5">
          <Chart chartType="Bar" width="100%" height="400px" data={teamBankedHours} options={teamChartOptions} />
        </div>
      </div>
      {showFiltersModal && (
        <Filters filters={filters} onFilterChange={onFilterChange} onCloseModal={() => setShowFiltersModal(false)} onSaveFilters={onSaveFilters} />
      )}
    </>
  );
};

export default Kpi;
