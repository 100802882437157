import { SupportWorker } from '__generated__/graphql';
import Avatar from 'components/Avatar';
import { ImageSizeType } from 'types/types';
import { SelectFieldWithoutLabel } from 'components/Fields/SelectField';

const ApologyReasonField = ({ teamMeetingApologies }: { teamMeetingApologies: SupportWorker[] }) => {
  const options = [
    { label: 'On a course', value: 'On a course' },
    { label: 'Sick', value: 'Sick' },
    { label: 'Holiday', value: 'Holiday' },
    { label: 'On a visit', value: 'On a visit' },
    { label: 'Other', value: 'Other' },
  ];
  return (
    <>
      <div className=" text-gray-700 text-md py-2 leading-sm font-bold">Select why they are absent:</div>
      <div className="grid xl:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-2">
        {teamMeetingApologies?.map((s: SupportWorker, index: number) => {
          return (
            <div key={s.id} className="flex flex-col align-middle">
              <Avatar testId={`supportWorker-${s.id}-absentees`} label={s.fullName} size={ImageSizeType.LG} alt="" avatar={s.avatar} />
              <SelectFieldWithoutLabel searchable testId="apologies-reason" options={options} isRequired={true} name={`apologies.${index}.reason`} />
            </div>
          );
        })}
      </div>
    </>
  );
};

export { ApologyReasonField };
