import { gql, useQuery, useMutation } from '@apollo/client';
import { Expense, ExpenseSearchInput } from '__generated__/graphql';

export const GET_EXPENSES = gql`
  query GetExpenses($query: ExpenseSearchInput) {
    GetExpenses(query: $query) {
      id
      payrollStartDate
      payrollEndDate
      teamId
      teamName
      externalId
      description
      comment
      status
      isPaid
      expenseDate
      totalAmount
      employeeFullName
    }
  }
`;

export const SAVE_EXPENSES = gql`
  mutation SaveExpenses($input: ExpenseSearchInput) {
    SaveExpenses(input: $input) {
      date
      message
    }
  }
`;

interface GetInvoicesResponse {
  GetExpenses: Expense[];
}

export const useGetExpenses = (query: ExpenseSearchInput) => {
  const { data, loading, error } = useQuery<GetInvoicesResponse>(GET_EXPENSES, {
    variables: {
      query,
    },
  });

  return { expenses: data?.GetExpenses, loading, error };
};

export const useSaveExpenses = (query: ExpenseSearchInput) => {
  const [saveExpenses, mutationSaveExpenses] = useMutation(SAVE_EXPENSES, {
    refetchQueries: [
      {
        query: GET_EXPENSES,
        variables: {
          query,
        },
      },
    ],
  });

  return { saveExpenses, mutationSaveExpenses };
};
