import { getHoursAndMinutes } from 'services/helpers';
import { Days } from 'types/types';
import { differenceInHours, differenceInMinutes, subMinutes } from 'date-fns';
import {
  AgreedShift,
  AgreedShiftRun,
  AgreedVisitType,
  DeleteMultipleAgreedVisitInput,
  AddAgreedVisitInput,
  EditAgreedVisitInput,
  AddMultipleAgreedVisitInput,
  DeleteAgreedVisitInput,
  Maybe,
  AgreedShiftRunWeeks,
  Absence,
} from '__generated__/graphql';
import AgreedVisitHeader from './AgreedVisitHeader';
import DayCell from './DayCell';

interface VisitRunProps {
  agreedShifts: AgreedShiftRunWeeks;
  row: AgreedShiftRun;
  week: number;
  onVisitDrop: (visit: AgreedVisitType, oldAgreedShiftRunId: string, oldAgreedShiftId: string, agreedShiftRunId: string, agreedShiftId: string) => void;
  onAddAgreedVisit: (newVisit: AddAgreedVisitInput) => void;
  onAddMultipleAgreedVisit: (newMultipleVisits: AddMultipleAgreedVisitInput) => void;
  onEditAgreedVisit: (editedVisit: EditAgreedVisitInput) => void;
  onDeleteAgreedVisit: (visit: DeleteAgreedVisitInput) => void;
  onDeleteMultipleAgreedVisit: (visits: DeleteMultipleAgreedVisitInput) => void;
  isDnDEditing: boolean;
  selectedAgreedRota: string;
  isDisable: boolean;
  leave: Absence[];
  isOptimised?: boolean;
}

const VisitRun = ({
  agreedShifts,
  row,
  week,
  onVisitDrop,
  onAddAgreedVisit,
  onAddMultipleAgreedVisit,
  onEditAgreedVisit,
  onDeleteAgreedVisit,
  onDeleteMultipleAgreedVisit,
  isDnDEditing,
  selectedAgreedRota,
  isDisable,
  leave,
  isOptimised = false,
}: VisitRunProps) => {
  const defaultDayProps = {
    id: '',
    ownerId: '',
    agreedShiftRunId: row.id || '',
    agreedRotaId: row.agreedRotaId || '',
    teamId: row.teamId || '',
    fullName: '',
    visits: [] as Maybe<AgreedVisitType>[],
  };

  const days = {
    0: { ...defaultDayProps, day: 0 },
    1: { ...defaultDayProps, day: 1 },
    2: { ...defaultDayProps, day: 2 },
    3: { ...defaultDayProps, day: 3 },
    4: { ...defaultDayProps, day: 4 },
    5: { ...defaultDayProps, day: 5 },
    6: { ...defaultDayProps, day: 6 },
  };

  row.shifts?.forEach((shift: Maybe<AgreedShift>) => {
    if (shift?.day !== undefined) {
      const day = days[shift?.day as Days];
      day.id = shift?.id || day.id;
      day.visits = shift?.visits || day.visits;
      day.ownerId = shift?.ownerId || day.ownerId;
      day.fullName = shift?.ownerId === '' ? 'TBC' : shift?.fullName || day.fullName;
    }
  });

  const numberOfHoursInShift = differenceInHours(new Date(row.endDateTime || 0), new Date(row.startDateTime || 0));

  const numberOfMinutesRemainingInShift = differenceInMinutes(new Date(row.endDateTime || 0), new Date(row.startDateTime || 0)) - numberOfHoursInShift * 60;

  const endDateSubMinutes = subMinutes(row.endDateTime || 0, numberOfMinutesRemainingInShift);

  return (
    <div className="flex w-full">
      <div className="flex flex-col xl:hidden shrink-0">
        <AgreedVisitHeader date={row.startDateTime || 0} week={week} startDateTime={row.startDateTime || 0} endDateTime={row.endDateTime || 0} />
      </div>
      <div className="flex flex-col xl:flex-row w-full">
        <div className="h-[70px] xl:h-auto xl:w-[9%] px-2">
          <div className="flex items-center justify-center h-[70px] xl:h-[76px] text-lg text-gray-800 font-semibold xl:font-medium">{row.period}</div>
          <div className="hidden xl:block">
            {[...Array(numberOfHoursInShift)].map((_, index: number) => {
              const start = new Date(row.startDateTime || 0);
              const end = new Date(row.startDateTime || 0);
              start.setHours(start.getHours() + index);
              end.setHours(end.getHours() + index + 1);

              return (
                <div className="flex items-center justify-end h-[60px]" key={index}>
                  {getHoursAndMinutes(start.getTime())} - {getHoursAndMinutes(end.getTime())}
                </div>
              );
            })}
            {numberOfMinutesRemainingInShift > 0 && (
              <div className="flex items-center justify-end" style={{ height: numberOfMinutesRemainingInShift }}>
                {getHoursAndMinutes(new Date(endDateSubMinutes).getTime())} - {getHoursAndMinutes(new Date(row.endDateTime || 0).getTime())}
              </div>
            )}
          </div>
        </div>
        {Object.entries(days).map(([key, _], index) => {
          return (
            <DayCell
              agreedShifts={agreedShifts}
              shift={days[parseInt(key, 10) as Days]}
              startDateTime={row.startDateTime || 0}
              endDateTime={row.endDateTime || 0}
              week={week}
              onVisitDrop={onVisitDrop}
              onAddAgreedVisit={onAddAgreedVisit}
              onAddMultipleAgreedVisit={onAddMultipleAgreedVisit}
              onEditAgreedVisit={onEditAgreedVisit}
              onDeleteAgreedVisit={onDeleteAgreedVisit}
              onDeleteMultipleAgreedVisit={onDeleteMultipleAgreedVisit}
              isDnDEditing={isDnDEditing}
              selectedAgreedRota={selectedAgreedRota}
              isDisable={isDisable}
              key={index}
              leave={leave}
              isOptimised={isOptimised}
            />
          );
        })}
      </div>
    </div>
  );
};

export default VisitRun;
