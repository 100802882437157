import { AddCircleOutlineOutlined } from '@mui/icons-material';
import { getTextColor } from 'services/helpers';
import stc from 'string-to-color';
import { AgreedShift } from '__generated__/graphql';

interface DayCellProps {
  shiftData: Partial<AgreedShift>;
  onCellClick: (shiftData: Partial<AgreedShift>) => void;
  status: string | null;
  isDisable: boolean;
}

const DayCell = ({ shiftData, onCellClick, status, isDisable }: DayCellProps) => {
  return (
    <div
      className="flex justify-center items-center border-t xl:border-t-0 border-l border-gray-100 px-2 h-[70px] xl:w-[10%]"
      style={{
        cursor: status !== 'paused' && !isDisable ? 'pointer' : 'initial',
        backgroundColor: shiftData.fullName === 'TBC' ? '#D32F2F' : shiftData.fullName !== '' ? stc(shiftData.fullName) : '#ffffff',
      }}
      onClick={() => {
        if (status !== 'paused' && !isDisable) onCellClick(shiftData);
      }}
      data-cy={`shift-${shiftData.day}`}
    >
      {shiftData.fullName !== '' ? (
        <div
          className="text-center font-semibold text-md leading-md"
          style={{ color: shiftData.fullName === 'TBC' ? 'white' : getTextColor(stc(shiftData.fullName)) }}
          data-cy={`shift-${shiftData.day}-owner`}
        >
          {shiftData.fullName}
        </div>
      ) : (
        status !== 'paused' && !isDisable && <AddCircleOutlineOutlined className="text-gray-800" />
      )}
    </div>
  );
};

export default DayCell;
