import { useState, useMemo, useCallback, useEffect } from 'react';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { PollOutlined } from '@mui/icons-material';
import { Autocomplete, TextField } from '@mui/material';
import { LocationBar, Loading, ToggleButtons, Search, Table } from 'components';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Helmet } from 'react-helmet';
import { useGetSupportWorkers } from 'api/hooks/useSupportWorkers';
import { CandidateReportList } from '__generated__/graphql';
import { useAssignSupportWorkerSurveyReport, useGetSurveyReports } from 'api/hooks/useSurveyReports';

const Survey = () => {
  const { surveyReports, loading: surveyReportsLoading, refetch } = useGetSurveyReports();
  const { supportWorkers, loading } = useGetSupportWorkers({});
  const navigate = useNavigate();

  const [search, setSearch] = useState('');
  const [filterStatus, setFilterStatus] = useState('All');
  const [isLoading, setIsLoading] = useState(true);
  const { assignSupportWorkerSurveyReport } = useAssignSupportWorkerSurveyReport();

  const onAssignSupportWorkerSurveyReport = useCallback(
    async (reportId: string, supportWorkerId: string) => {
      await assignSupportWorkerSurveyReport({
        variables: {
          input: {
            reportId,
            supportWorkerId,
          },
        },
      });
    },
    [assignSupportWorkerSurveyReport],
  );

  useEffect(() => {
    if (surveyReports) {
      surveyReports.forEach(async (surveyReport: CandidateReportList) => {
        if (!surveyReport.supportWorkerId) {
          const supportWorker = supportWorkers.find((sw) => sw.fullName === surveyReport.candidateName);
          if (supportWorker) {
            await onAssignSupportWorkerSurveyReport(surveyReport.id, supportWorker.id);
          }
        }
      });
      refetch();
    }
    setIsLoading(false);
  }, [supportWorkers, surveyReports, onAssignSupportWorkerSurveyReport, refetch]);

  const filterByName = (sw: CandidateReportList) => {
    if (sw.candidateName.toLowerCase().includes(search.toLowerCase())) return true;
    return false;
  };

  const filterByStatus = (rp: CandidateReportList) => {
    const supportWorker = supportWorkers.find((sw) => sw.id === rp.supportWorkerId);

    if (filterStatus === 'All') return true;
    if (filterStatus === 'Active Support Workers' && supportWorker) return true;
    if (filterStatus === 'Candidates / Inactive Support Workers' && !supportWorker) return true;
    return false;
  };

  const filteredReports = () => {
    return surveyReports
      ?.filter((sw: CandidateReportList) => filterByName(sw))
      ?.filter((sw: CandidateReportList) => filterByStatus(sw))
      .sort((x: CandidateReportList, y: CandidateReportList) => (y.dateUpdated || 0) - (x.dateUpdated || 0));
  };

  const onButtonClick = useCallback(
    (sw: CandidateReportList) => {
      navigate(`/survey/${sw.id}`);
    },
    [navigate],
  );

  const RowActions = useCallback(
    ({ row }: { row: CandidateReportList }) => {
      return (
        <button
          type="button"
          onClick={() => onButtonClick(row)}
          aria-label="View"
          className="text-white bg-primary-600 font-medium rounded-lg text-sm p-2 lg:px-5 lg:py-2.5 text-center inline-flex items-center"
        >
          <span data-cy="list-edit-button" className="ml-2 hidden lg:block">
            View Survey
          </span>
        </button>
      );
    },
    [onButtonClick],
  );

  const columnHelper = createColumnHelper<CandidateReportList>();

  const columns1 = useMemo(() => {
    return [
      columnHelper.accessor('candidateName', {
        header: () => 'Name',
        cell: (info) => (
          <div className="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            <div className="ps-3">
              <div data-cy="list-name" className="text-base font-semibold">
                {info.row.original.candidateName}
              </div>
            </div>
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('score', {
        header: () => 'Score',
        cell: (info) => <span data-cy="list-score">{info.renderValue()}</span>,
        footer: (info) => info.column.id,
      }),

      columnHelper.accessor('dateUpdated', {
        header: () => 'Date Update',
        cell: (info) => <span data-cy="list-dateUpdated">{format(info.renderValue() ?? 0, 'dd/MM/yy')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('supportWorkerId', {
        header: () => 'Support Worker',
        cell: (info) => {
          const supportWorker = supportWorkers.find((sw) => sw.id === info.row.original.supportWorkerId);

          return (
            <Autocomplete
              disablePortal
              value={supportWorker}
              options={supportWorkers}
              getOptionLabel={(option) => option.fullName}
              sx={{ width: { xs: '100%', md: 'initial' } }}
              onChange={(_, sw) => {
                if (sw) onAssignSupportWorkerSurveyReport(info.row.original.id, sw.id);
              }}
              renderInput={(params) => <TextField {...params} label="Support Worker" />}
            />
          );
        },
        footer: (info) => info.column.id,
      }),

      columnHelper.display({
        id: 'actions',
        cell: (props) => <RowActions row={props.row.original} />,
      }),
    ] as Array<ColumnDef<CandidateReportList, unknown>>;
  }, [columnHelper, supportWorkers, onAssignSupportWorkerSurveyReport, RowActions]);

  const rows = filteredReports()?.map((c: CandidateReportList) => {
    return {
      ...c,
    };
  });

  if (isLoading || loading || surveyReportsLoading) {
    return <Loading />;
  }

  return (
    <div>
      <Helmet>
        <title>Survey</title>
      </Helmet>
      <LocationBar section="Analytics" page="Survey" Icon={PollOutlined} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-display-lg leading-display-lg tracking-display-lg text-black font-medium">Surveys</div>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-between mt-8 md:mt-16">
          <div className="flex flex-col md:flex-row items-center gap-4 md:gap-9 w-full md:w-fit">
            <ToggleButtons
              testId="status-filter"
              buttons={['All', 'Active Support Workers', 'Candidates / Inactive Support Workers']}
              selectedButton={filterStatus}
              onSelectButton={setFilterStatus}
            />
            <Search value={search} onSearch={setSearch} />
          </div>
        </div>

        <div className="mt-3 md:mt-12 mb-10">{rows && rows.length > 0 && <Table<CandidateReportList> data={rows} columns={columns1} />}</div>
      </div>
    </div>
  );
};

export default Survey;
