import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Helmet from 'react-helmet';
import EmojiPeople from '@mui/icons-material/EmojiPeople';
import {
  Candidate,
  CurrentStatus,
  PublishCandidateInput,
  ReasonForNotHired,
  SaveCandidateInput,
  SendCandidateMessagesInput,
  SendSmsInput,
} from '__generated__/graphql';
import {
  ArrowDownTrayIcon,
  ArrowLeftIcon,
  ArrowPathIcon,
  PlusIcon,
  EnvelopeIcon,
  UserGroupIcon,
  PhoneIcon,
  MapPinIcon,
  ChartBarIcon,
  LinkIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import { Loading, LocationBar, Message } from 'components';
import { FormPrompt } from 'components/modals/FormPromptModal';
import { useGetCandidate, usePublishCandidate, useSaveCandidate, useSendCandidateEmail, useSendCandidateSms } from 'api/hooks/useCandidates';
import { format } from 'date-fns';
import TabSwitcher from 'components/TabSwitcher';
import { useGetTemplates } from 'api/hooks/useTemplates';
import { FormProvider, useForm } from 'react-hook-form';
import CandidateUpdate from './CandidateUpdate';
import CandidateTrackingUpdate from './CandidateTrackingUpdate';
import CandidateMarketing from './CandidateMarketing';
import CandidateHistory from './CandidateHistory';
import CandidateScorecard from './CandidateScorecard';
import Status from './components/Status';
import CandidateMessaging from './components/CandidateMessaging';
import CandidateWorkshopFeedback from './CandidateWorkshopFeedback';
import CandidateShadowFeedback from './CandidateShadowFeedback';
import { MapCandidate } from './utils/FileMapper';
import CandidatePreviousApplications from './CandidatePreviousApplications';

export default function CandidateDetail() {
  const navigate = useNavigate();

  const { candidateId } = useParams<{ candidateId?: string }>();
  const { candidate, candidateLoading } = useGetCandidate({ id: candidateId });
  const { saveCandidate, mutationSaveCandidate } = useSaveCandidate({ id: candidateId });
  const { templates, templatesLoading } = useGetTemplates();
  const { sendCandidateMessages, mutationSendCandidateMessages } = useSendCandidateEmail();
  const { sendSms, mutationSendSms } = useSendCandidateSms();
  const { publishCandidate, mutationPublishCandidate } = usePublishCandidate();
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const [showMessageModal, setShowMessageModal] = useState<boolean>(false);
  const methods = useForm<SaveCandidateInput>({
    mode: 'onChange',
  });

  const { handleSubmit, formState, reset } = methods;

  useEffect(() => {
    reset({
      ...MapCandidate(candidate),
    });
  }, [candidate, reset]);

  if (candidateLoading || mutationSaveCandidate.loading || templatesLoading || mutationSendCandidateMessages.loading || mutationSendSms.loading) {
    return <Loading />;
  }

  const onPublishCandidate = (input: PublishCandidateInput) => {
    publishCandidate({
      variables: {
        input,
      },
    });
    setOpenSnack(true);
  };

  const onSendEmail = (input: SendCandidateMessagesInput) => {
    sendCandidateMessages({
      variables: {
        input,
      },
    });
    setOpenSnack(true);
  };

  const onSendSms = (input: SendSmsInput) => {
    sendSms({
      variables: {
        input,
      },
    });
    setOpenSnack(true);
  };

  const onSubmit = (data: Candidate) => {
    setOpenSnack(true);
    const saveData = data;
    delete saveData.previousApplications;
    saveCandidate({
      variables: {
        input: {
          ...saveData,
          reasonNotHiredOther: data.reasonNotHired === ReasonForNotHired.Other ? data.reasonNotHiredOther : '',
        },
      },
    });
    setTimeout(() => {
      setOpenSnack(false);
    }, 2000);
  };

  const onBack = () => {
    navigate(-1);
  };

  const tabs = [
    { label: 'Details', component: <CandidateUpdate /> },
    { label: 'Application Tracking', component: <CandidateTrackingUpdate /> },
    { label: 'Marketing', component: <CandidateMarketing /> },
    { label: 'Scorecard', component: <CandidateScorecard /> },
    { label: 'Workshop feedback', component: <CandidateWorkshopFeedback /> },
    { label: 'Shadow feedback', component: <CandidateShadowFeedback /> },
    { label: 'History', component: <CandidateHistory onSubmit={onSubmit} /> },
    { label: 'Previous Applications', component: <CandidatePreviousApplications /> },
  ];

  return (
    <>
      <Helmet>
        <title>Candidates</title>
      </Helmet>
      <LocationBar section="Candidates" page="Manage Candidate" Icon={EmojiPeople} />
      <Message
        response={[mutationSaveCandidate, mutationSendCandidateMessages, mutationSendSms, mutationPublishCandidate]}
        openSnack={openSnack}
        setOpenSnack={setOpenSnack}
      />
      <div className="flex flex-col gap-3">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="my-10 px-4 md:px-[5%]">
              <button type="button" className="text-gray-500 font-semibold text-md leading-md flex items-center" onClick={onBack}>
                <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
              </button>
              <div className="flex items-start justify-between mt-16">
                <div>
                  <div className="text-black text-xl sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1">
                    {candidate?.firstName} {candidate?.lastName}
                  </div>
                  <div className="text-gray-600 text-md leading-md mb-2 flex items-center">
                    <EnvelopeIcon className="w-5 h-5 mr-2 text-gray-600" />
                    <span className="pt-0.5">{candidate?.email}</span>
                  </div>
                  <div className="text-gray-600 text-md leading-md my-2 flex items-center">
                    <PhoneIcon className="w-5 h-5 mr-2 text-gray-600" />
                    <span className="pt-1">{candidate?.phone || 'Unknown'}</span>
                  </div>
                  <div className="text-gray-600 text-md leading-md my-2 flex items-center">
                    <UserGroupIcon className="w-5 h-5 mr-2 text-gray-600" />
                    <span className="pt-1">{candidate?.teamName || 'Unknown'}</span>
                  </div>
                  <div className="text-gray-600 text-md leading-md my-2 flex items-center">
                    <MapPinIcon className="w-5 h-5 mr-2 text-gray-600" />
                    <span className="pt-1">{candidate?.postCode || 'Unknown'}</span>
                  </div>
                  <div className="mt-2 sm:hidden">
                    <Status status={candidate?.currentStatus as CurrentStatus} />
                  </div>
                  {candidate?.surveyId && (
                    <div className="text-gray-600 text-md leading-md my-2 flex items-center">
                      <ChartBarIcon className="w-5 h-5 mr-2 text-gray-600" />
                      <span className="pt-1">
                        <a href={`../survey/${candidate?.surveyId}`}>View survey</a>
                      </span>
                    </div>
                  )}
                  {candidate?.externalId && (
                    <div className="text-gray-600 text-md leading-md my-2 flex items-center">
                      <LinkIcon className="w-5 h-5 mr-2 text-gray-600" />
                      <a href={`https://bellevie.hrpartner.io/portal/directory/employee/${candidate?.externalId}`} target="_blank" rel="noreferrer">
                        View in Hr Partner
                      </a>
                    </div>
                  )}
                </div>
                <div className="flex-col justify-between self-stretch hidden sm:flex">
                  <Status status={candidate?.currentStatus as CurrentStatus} />
                  <div>
                    <div className="text-gray-400 text-xs font-medium leading-xs mb-2 flex items-center">
                      <ArrowDownTrayIcon className="w-3.5 h-3.5 mr-2" />
                      Imported: {format(candidate?.dateImported ?? 0, 'dd/MM/yyyy')}
                    </div>
                    <div className="text-gray-400 text-xs font-medium leading-xs mb-2 flex items-center">
                      <PlusIcon className="w-3.5 h-3.5 mr-2" />
                      Created: {format(candidate?.dateCreated ?? 0, 'dd/MM/yyyy')}
                    </div>
                    <div className="text-gray-400 text-xs font-medium leading-xs mb-2 flex items-center">
                      <ArrowPathIcon className="w-3.5 h-3.5 mr-2" />
                      Updated: {format(candidate?.dateUpdated ?? 0, 'dd/MM/yyyy')}
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="mt-3 px-5 py-2.5 border border-gray-600 rounded-lg text-gray-600 hover:border-primary-600 hover:text-primary-600 text-sm leading-sm font-semibold flex items-center"
                onClick={() => setShowMessageModal(true)}
              >
                <EnvelopeIcon className="w-6 h-6  mr-2" />
                Send email/SMS
              </button>
              <div className="flex flex-col sm:flex-row sm:justify-between">
                <button
                  type="button"
                  className="w-fit mt-3 px-5 py-2.5 border border-gray-600 rounded-lg text-gray-600 hover:border-primary-600 hover:text-primary-600 text-sm leading-sm font-semibold flex items-center"
                  onClick={() => navigate('/workshops')}
                >
                  View Workshops
                </button>
                {candidate?.file && (
                  <a
                    className="w-fit mt-3 px-5 py-2.5 border border-gray-600 rounded-lg text-gray-600
                            hover:border-primary-600 hover:text-primary-600 text-sm leading-sm font-semibold flex items-center"
                    href={candidate.file}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Resume
                  </a>
                )}
              </div>
              {!candidate?.externalId && candidate?.status === 'Hired' && candidate?.teamId && (
                <button
                  type="button"
                  className="mt-3 px-5 py-2.5 border border-gray-600 rounded-lg text-gray-600 hover:border-primary-600 hover:text-primary-600 text-sm leading-sm font-semibold flex items-center"
                  onClick={() => onPublishCandidate({ id: candidate?.id })}
                >
                  <UserPlusIcon className="w-6 h-6  mr-2" />
                  Publish to Hr Partner
                </button>
              )}
              <div className="mt-10 sm:mt-16">
                <TabSwitcher tabs={tabs} />
              </div>
            </div>
            {showMessageModal && (
              <CandidateMessaging
                onClose={() => setShowMessageModal(false)}
                candidates={candidate ? [candidate] : []}
                selectedCandidates={candidate ? [candidate] : []}
                templates={templates}
                onSendEmail={onSendEmail}
                onSendSms={onSendSms}
                recipientsDisabled
              />
            )}
          </form>
        </FormProvider>
      </div>
      <FormPrompt hasUnsavedChanges={formState.isDirty} />
    </>
  );
}
