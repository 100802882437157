import React, { useState, ReactNode } from 'react';

interface AccordianProps {
  title: string;
  children: ReactNode;
  color: string;
}

const Accordion = ({ title, children, color }: AccordianProps) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div>
      <div onClick={() => setIsActive(!isActive)}>
        <div className={`mt-3 p-3 $ ${color} cursor-pointer rounded`}>
          <span className="font-medium">
            {title} <span className="float-right font-bold text-lg">{isActive ? '-' : '+'}</span>
          </span>
        </div>
      </div>
      {isActive && <div>{children}</div>}
    </div>
  );
};

export default Accordion;
