import TextField from '@mui/material/TextField';

interface TextBoxProps {
  value: string | null;
  label?: string | null;
  onChange: (value: string) => void;
  limit: number;
}

export default function TextBox({ value, onChange, limit, label }: TextBoxProps) {
  const CHARACTER_LIMIT = limit || 1000;

  const handleChange = (data: string) => {
    onChange(data);
  };

  return (
    <TextField
      inputProps={{ maxlength: CHARACTER_LIMIT, border: 'none' }}
      label={label}
      value={value}
      helperText={`${value?.length}/${CHARACTER_LIMIT}`}
      onChange={(e) => handleChange(e.target.value)}
      margin="none"
      variant="outlined"
      multiline
      rows={4}
      sx={{ padding: 0, margin: 0, width: '100%' }}
    />
  );
}
