import { useMutation, gql } from '@apollo/client';
import { CustomerSearch } from '__generated__/graphql';
import { refetchCustomer } from './useCustomers';

export const SAVE_DOCUMENT = gql`
  mutation SaveDocument($input: SaveDocumentInput) {
    SaveDocument(input: $input) {
      id
      message
      date
    }
  }
`;

export const REMOVE_DOCUMENT = gql`
  mutation RemoveDocument($input: RemoveDocumentInput) {
    RemoveDocument(input: $input) {
      id
      message
      date
    }
  }
`;

export const useSaveDocumentForSupportedPerson = (query: CustomerSearch) => {
  const [saveDocument, mutationSaveDocument] = useMutation(SAVE_DOCUMENT, refetchCustomer(query));
  return { saveDocument, mutationSaveDocument };
};

export const useRemoveDocumentForSupportedPerson = (query: CustomerSearch) => {
  const [removeDocument, mutationRemoveDocument] = useMutation(REMOVE_DOCUMENT, refetchCustomer(query));
  return { removeDocument, mutationRemoveDocument };
};
