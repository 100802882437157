import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SelectOption } from 'types/types';
import { ShiftType } from 'pages/ReportingHours/service';
import ReactSelect from 'components/Select';
import { useUrlQuery } from 'hooks/useUrlQuery';
import { useLocation } from 'react-router-dom';
import { useUser } from 'context/userContext';
import { XCircleIcon } from '@heroicons/react/24/outline';
import FilterLabels from './FilterLabels';

type FilterProps = {
  filters: Record<string, SelectOption>;
  setFilters: Dispatch<SetStateAction<Record<string, SelectOption>>>;
  supportWorkers: SelectOption[];
  customers: SelectOption[];
};

const Filter = ({ filters, setFilters, supportWorkers, customers }: FilterProps) => {
  const query = useUrlQuery();
  const { user } = useUser();
  const location = useLocation();

  const weeks = [
    { value: 'all', label: 'All Weeks' },
    { value: '1', label: 'Week 1' },
    { value: '2', label: 'Week 2' },
  ];

  const visitTypes = [
    { value: 'all', label: 'All Activities' },
    { value: ShiftType.visit, label: 'Visit' },
    { value: ShiftType.oneoff, label: 'One Off Visit' },
    { value: ShiftType.activity, label: 'Activity' },
  ];

  const movedOptions = [
    { value: 'all', label: 'Exclude Moved' },
    { value: 'include', label: 'Include Moved' },
  ];

  const assignments = useMemo(() => {
    return [
      { value: 'all', label: 'Next Visits' },
      { value: '1', label: 'All Visits' },
      { value: '2', label: 'TBCs' },
      { value: '3', label: 'Cover Assigned' },
      { value: '4', label: 'Cancelled' },
    ];
  }, []);

  const days = useMemo(() => {
    return [
      { value: 'all', label: 'All Days' },
      { value: '1', label: 'Monday' },
      { value: '2', label: 'Tuesday' },
      { value: '3', label: 'Wednesday' },
      { value: '4', label: 'Thursday' },
      { value: '5', label: 'Friday' },
      { value: '6', label: 'Saturday' },
      { value: '0', label: 'Sunday' },
    ];
  }, []);

  useEffect(() => {
    const filterAssignment = query.get('assignment') || '';
    const selectedDay = query.get('selectedDay') || '';
    const filterAssignmentOption = assignments.find((a) => a.value === filterAssignment);

    if (filterAssignmentOption && filterAssignmentOption?.value !== filters.assignment.value) {
      setFilters({ ...filters, assignment: filterAssignmentOption });
    }

    if (selectedDay) {
      const dayAsNumber = parseInt(selectedDay, 10);
      if (dayAsNumber && dayAsNumber < 7) {
        const selectedDayOption = days.find((a) => a.value === selectedDay);
        if (selectedDayOption && selectedDayOption?.value !== filters.day.value) setFilters({ ...filters, day: selectedDayOption });
      }
    }

    if (location.pathname === '/my-shifts') {
      const supportWorkerOption = supportWorkers.find((a) => a?.value === user?.profile);
      if (supportWorkerOption && supportWorkerOption.value !== filters.supportWorker.value) {
        setFilters({ ...filters, supportWorker: { value: supportWorkerOption?.value, label: supportWorkerOption?.label } });
      }
    }
  }, [filters, assignments, days, query, location.pathname, setFilters, supportWorkers, user?.profile]);

  const resetFilters = () => {
    setFilters({
      ...filters,
      assignment: { value: 'all', label: 'Next Visits' },
      day: { value: 'all', label: 'All Days' },
      supportWorker: { value: 'all', label: 'All Colleagues' },
      week: { value: 'all', label: 'All Weeks' },
      customer: { value: 'all', label: 'All Persons' },
      visitType: { value: 'all', label: 'All Activities' },
      excludeMovedVisits: { value: 'include', label: 'Include Moved' },
    });
  };

  const onChangeFilter = (option: SelectOption, name: string | undefined) => {
    if (name) {
      query.delete(name);
      setFilters({ ...filters, [name]: option });
    }
  };

  return (
    <Accordion>
      <AccordionSummary
        className="relative"
        aria-controls="filter-accordian-content"
        id="filter-accordian-header"
        data-cy="filter-accordian"
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography color="primary" sx={{ width: { sm: '8%', md: '4%' }, display: { sm: 'block' }, m: '0.3em', mr: '1em' }}>
          Filters
        </Typography>
        <FilterLabels filters={filters} />
      </AccordionSummary>
      <AccordionDetails>
        <div className="flex xl:flex-row flex-col gap-5">
          <ReactSelect
            searchable
            options={[{ value: 'all', label: 'All Persons' }, ...customers]}
            name="customer"
            testId="filter-customer"
            onChange={onChangeFilter}
            selectedValue={filters.customer}
          />
          <ReactSelect
            searchable
            options={[{ value: 'all', label: 'All Colleagues' }, ...supportWorkers]}
            placeholder="All Colleagues"
            name="supportWorker"
            disabled={location.pathname === '/my-shifts'}
            testId="filter-supportWorker"
            onChange={onChangeFilter}
            selectedValue={filters.supportWorker}
          />
          <ReactSelect
            searchable
            options={assignments}
            name="assignment"
            testId="filter-assignment"
            onChange={onChangeFilter}
            selectedValue={filters.assignment}
          />
          <ReactSelect searchable options={days} name="day" testId="filter-day" onChange={onChangeFilter} selectedValue={filters.day} />
          <ReactSelect searchable options={weeks} name="week" testId="filter-week" onChange={onChangeFilter} selectedValue={filters.week} />
          <ReactSelect searchable options={visitTypes} name="visitType" testId="filter-visitType" onChange={onChangeFilter} selectedValue={filters.visitType} />
          <ReactSelect
            searchable
            options={movedOptions}
            name="excludeMovedVisits"
            testId="filter-excludeMovedVisits"
            onChange={onChangeFilter}
            selectedValue={filters.excludeMovedVisits}
          />
          <button
            type="button"
            data-cy="reset-filter"
            className="text-primary-700 text-md leading-sm font-semibold flex items-center"
            onClick={() => resetFilters()}
          >
            <XCircleIcon className="w-6 h-6 mr-2" />
            Clear All Filters
          </button>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default Filter;
