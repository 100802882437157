import { ReasonForNotHired } from '__generated__/graphql';
import { camelCaseToNormalString } from 'services/strings';
import { InputField } from 'components/Fields/InputField';
import { SelectField } from 'components/Fields/SelectField';
import { useFormContext } from 'react-hook-form';

export default function CandidateMarketing() {
  const { watch } = useFormContext();
  const reasonNotHired = watch('reasonNotHired');

  const reasonsForNotHired = Object.values(ReasonForNotHired).map((c) => ({ value: c, label: camelCaseToNormalString(c) }));

  return (
    <div className="mt-8 sm:mt-12">
      <InputField label="Source" placeholder="Source" name="source" />
      <InputField label="Campaign" placeholder="Campaign" name="campaign" />
      <InputField label="Price" placeholder="Price" name="price" />
      <SelectField label="Reason not hired" options={reasonsForNotHired} name="reasonNotHired" />
      {reasonNotHired === ReasonForNotHired.Other && <InputField label="Other" placeholder="Other" name="reasonNotHiredOther" />}
      <div className="flex justify-end">
        <button type="submit" className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
          Save
        </button>
      </div>
    </div>
  );
}
