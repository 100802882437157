import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CircularProgress } from '@mui/material';
import FileInput from 'components/FileInput';
import Avatar from 'components/Avatar';
import { getFilename, uploadFile } from 'services/file';
import { ImageSizeType } from 'types/types';
import { Image, Maybe } from '__generated__/graphql';

const bucket = process.env.REACT_APP_TEAMHUB_S3_BUCKET;
const cloudfront = process.env.REACT_APP_TEAMHUB_CLOUDFRONT;
const region = process.env.REACT_APP_AWS_REGION;

function ImageField({ onUpload, image }: { onUpload: (image: { url: string; id: string }) => void; image: Maybe<Image> | undefined }) {
  const [imageUrl, setImageUrl] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const handleImageUpload = async (file: File) => {
    setLoading(true);
    setImageUrl('');

    if (file && file?.name) {
      const id = uuidv4();
      const filename = getFilename(id, 'images/sp/', file);

      if (id && filename) {
        const url = await uploadFile(file, filename, { bucket, region, cloudfront });

        if (url) {
          await onUpload({ id, url });
          setImageUrl(url);
        }
      }
    }

    setLoading(false);
  };

  const avatar = imageUrl?.toString() || image?.url || '/defaultProfile.jpg';

  return (
    <div className="flex items-center space-x-6 py-4">
      <div className="shrink-0">
        {loading ? <CircularProgress color="inherit" /> : <Avatar size={ImageSizeType.XL} avatar={avatar} alt="Current profile" />}
      </div>
      <FileInput handleUpload={handleImageUpload} placeholder="Choose profile photo" accept="image/*" />
    </div>
  );
}

export { ImageField };
