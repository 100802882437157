import { useQuery, useMutation, gql } from '@apollo/client';
import { Candidate, CandidateSearch, GetCandidateSearch, CandidateForWorkshopSearchInput } from '../../__generated__/graphql';

export const GET_CANDIDATE = gql`
  query GetCandidate($query: GetCandidateSearch) {
    GetCandidate(query: $query) {
      id
      firstName
      lastName
      email
      phone
      dateCreated
      dateUpdated
      source
      eligibleToWork
      canDrive
      circleName
      teamName
      jobRole
      status
      owner
      currentStatus
      teamId
      circleId
      dob
      address
      city
      state
      postCode
      country
      campaign
      price
      reasonNotHired
      reasonNotHiredOther
      dateImported
      surveyId
      externalId
      whyYou
      roleAppeal
      file
      workshop {
        attended
        date
        note
        passed
        shadowWithId
      }
      scorecard {
        question
        answer
        questionType
      }
      history {
        id
        from
        message
        date
        contactType
      }
      workshopFeedback {
        personObserving
        observationDate
        communication
        teamWork
        personability
        patience
        empathy
        overallFeedback
        progressShadow
      }
      shadowFeedback {
        personObserving
        observationDate
        communication
        teamPlayer
        listening
        engagementWillingness
        conscientiousness
        values
        other
      }
      tracking {
        applicationUploaded {
          stage
          dateUpdated
          status
          canManuallyUpdate
        }
        autoWelcomeTextAndEmailSent {
          stage
          dateUpdated
          status
          canManuallyUpdate
        }
        initialCallArranged {
          stage
          dateUpdated
          status
          canManuallyUpdate
        }
        initialCallDone {
          stage
          dateUpdated
          status
          canManuallyUpdate
        }
        workshopInviteSent {
          stage
          dateUpdated
          status
          canManuallyUpdate
        }
        workshopAttended {
          stage
          dateUpdated
          status
          canManuallyUpdate
        }
        shadowAgreed {
          stage
          dateUpdated
          status
          canManuallyUpdate
        }
        shadowSuccess {
          stage
          dateUpdated
          status
          canManuallyUpdate
        }
        jobOfferAccepted {
          stage
          dateUpdated
          status
          canManuallyUpdate
        }
      }
      previousApplications {
        id
        firstName
        lastName
        email
        phone
        dateCreated
        dateUpdated
        source
        eligibleToWork
        canDrive
        circleName
        teamName
        jobRole
        status
        owner
        currentStatus
        teamId
        circleId
        dob
        address
        city
        state
        postCode
        country
        campaign
        price
        reasonNotHired
        reasonNotHiredOther
        dateImported
        surveyId
        externalId
        workshop {
          attended
          date
          note
          passed
          shadowWithId
        }
        scorecard {
          question
          answer
          questionType
        }
        history {
          id
          from
          message
          date
          contactType
        }
        workshopFeedback {
          personObserving
          observationDate
          communication
          teamWork
          personability
          patience
          empathy
          overallFeedback
          progressShadow
        }
        shadowFeedback {
          personObserving
          observationDate
          communication
          teamPlayer
          listening
          engagementWillingness
          conscientiousness
          values
          other
        }
        tracking {
          applicationUploaded {
            stage
            dateUpdated
            status
            canManuallyUpdate
          }
          autoWelcomeTextAndEmailSent {
            stage
            dateUpdated
            status
            canManuallyUpdate
          }
          initialCallArranged {
            stage
            dateUpdated
            status
            canManuallyUpdate
          }
          initialCallDone {
            stage
            dateUpdated
            status
            canManuallyUpdate
          }
          workshopInviteSent {
            stage
            dateUpdated
            status
            canManuallyUpdate
          }
          workshopAttended {
            stage
            dateUpdated
            status
            canManuallyUpdate
          }
          shadowAgreed {
            stage
            dateUpdated
            status
            canManuallyUpdate
          }
          shadowSuccess {
            stage
            dateUpdated
            status
            canManuallyUpdate
          }
          jobOfferAccepted {
            stage
            dateUpdated
            status
            canManuallyUpdate
          }
        }
      }
    }
  }
`;

export const GET_CANDIDATES = gql`
  query GetCandidates($query: CandidateSearch) {
    GetCandidates(query: $query) {
      id
      firstName
      lastName
      email
      phone
      dateCreated
      dateUpdated
      source
      eligibleToWork
      canDrive
      circleName
      teamName
      jobRole
      status
      owner
      currentStatus
      teamId
      circleId
      dob
      address
      city
      state
      postCode
      country
      campaign
      price
      reasonNotHired
      reasonNotHiredOther
      dateImported
      surveyId
      externalId
      whyYou
      roleAppeal
      file
      workshop {
        attended
        date
        note
        passed
        shadowWithId
      }
      scorecard {
        question
        answer
        questionType
      }
      history {
        id
        from
        message
        date
        contactType
      }
      workshopFeedback {
        personObserving
        observationDate
        communication
        teamWork
        personability
        patience
        empathy
        overallFeedback
        progressShadow
      }
      shadowFeedback {
        personObserving
        observationDate
        communication
        teamPlayer
        listening
        engagementWillingness
        conscientiousness
        values
        other
      }
      tracking {
        applicationUploaded {
          stage
          dateUpdated
          status
          canManuallyUpdate
        }
        autoWelcomeTextAndEmailSent {
          stage
          dateUpdated
          status
          canManuallyUpdate
        }
        initialCallArranged {
          stage
          dateUpdated
          status
          canManuallyUpdate
        }
        initialCallDone {
          stage
          dateUpdated
          status
          canManuallyUpdate
        }
        workshopInviteSent {
          stage
          dateUpdated
          status
          canManuallyUpdate
        }
        workshopAttended {
          stage
          dateUpdated
          status
          canManuallyUpdate
        }
        shadowAgreed {
          stage
          dateUpdated
          status
          canManuallyUpdate
        }
        shadowSuccess {
          stage
          dateUpdated
          status
          canManuallyUpdate
        }
        jobOfferAccepted {
          stage
          dateUpdated
          status
          canManuallyUpdate
        }
      }
    }
  }
`;

export const GET_CANDIDATES_FOR_WORKSHOP = gql`
  query GetCandidatesForWorkshop($query: CandidateForWorkshopSearchInput) {
    GetCandidatesForWorkshop(query: $query) {
      id
      firstName
      lastName
      email
      phone
      dateCreated
      dateUpdated
      source
      eligibleToWork
      canDrive
      circleName
      teamName
      jobRole
      status
      owner
      currentStatus
      teamId
      circleId
      dob
      address
      city
      state
      postCode
      country
      campaign
      price
      reasonNotHired
      reasonNotHiredOther
      dateImported
      surveyId
      externalId
      workshop {
        attended
        date
        note
        passed
        shadowWithId
      }
      scorecard {
        question
        answer
        questionType
      }
      history {
        id
        from
        message
        date
        contactType
      }
      workshopFeedback {
        personObserving
        observationDate
        communication
        teamWork
        personability
        patience
        empathy
        overallFeedback
        progressShadow
      }
      shadowFeedback {
        personObserving
        observationDate
        communication
        teamPlayer
        listening
        engagementWillingness
        conscientiousness
        values
        other
      }
      tracking {
        applicationUploaded {
          stage
          dateUpdated
          status
          canManuallyUpdate
        }
        autoWelcomeTextAndEmailSent {
          stage
          dateUpdated
          status
          canManuallyUpdate
        }
        initialCallArranged {
          stage
          dateUpdated
          status
          canManuallyUpdate
        }
        initialCallDone {
          stage
          dateUpdated
          status
          canManuallyUpdate
        }
        workshopInviteSent {
          stage
          dateUpdated
          status
          canManuallyUpdate
        }
        workshopAttended {
          stage
          dateUpdated
          status
          canManuallyUpdate
        }
        shadowAgreed {
          stage
          dateUpdated
          status
          canManuallyUpdate
        }
        shadowSuccess {
          stage
          dateUpdated
          status
          canManuallyUpdate
        }
        jobOfferAccepted {
          stage
          dateUpdated
          status
          canManuallyUpdate
        }
      }
    }
  }
`;

export const IMPORT_CANDIDATES = gql`
  mutation ImportCandidates($input: CandidateImportInput) {
    ImportCandidates(input: $input) {
      date
      message
    }
  }
`;

export const CREATE_CANDIDATE = gql`
  mutation CreateCandidate($input: CreateCandidateInput) {
    CreateCandidate(input: $input) {
      date
      message
    }
  }
`;

export const SAVE_CANDIDATE = gql`
  mutation SaveCandidate($input: SaveCandidateInput) {
    SaveCandidate(input: $input) {
      date
      message
    }
  }
`;

export const SEND_CANDIDATE_EMAIL = gql`
  mutation SendCandidateMessages($input: SendCandidateMessagesInput) {
    SendCandidateMessages(input: $input) {
      date
      message
    }
  }
`;

export const SEND_CANDIDATE_SMS = gql`
  mutation SendSms($input: SendSmsInput) {
    SendSms(input: $input) {
      message
      date
    }
  }
`;

export const PUBLISH_CANDIDATE_HRPARTNER = gql`
  mutation PublishCandidateToHrPartner($input: PublishCandidateInput) {
    PublishCandidateToHrPartner(input: $input) {
      message
      date
    }
  }
`;

interface GetCandidatesResponse {
  GetCandidates: Candidate[];
}

interface GetCandidateResponse {
  GetCandidate: Candidate;
}

interface GetCandidatesForWorkshopResponse {
  GetCandidatesForWorkshop: Candidate[];
}

const refetch = () => ({
  refetchQueries: [
    {
      query: GET_CANDIDATES,
      variables: {
        query: {},
      },
    },
  ],
});

export const useGetCandidatesForWorkshop = (query: CandidateForWorkshopSearchInput) => {
  const { data, loading, error } = useQuery<GetCandidatesForWorkshopResponse>(GET_CANDIDATES_FOR_WORKSHOP, {
    variables: {
      query: { ...query },
    },
  });

  return { candidates: data?.GetCandidatesForWorkshop || [], candidateLoading: loading, error };
};

export const useGetCandidates = (query: CandidateSearch) => {
  const { data, loading, error } = useQuery<GetCandidatesResponse>(GET_CANDIDATES, {
    variables: {
      query: { ...query },
    },
  });

  return { candidates: data?.GetCandidates || [], candidateLoading: loading, error };
};

export const useImportCandidates = () => {
  const [importCandidates, mutationImportCandidates] = useMutation(IMPORT_CANDIDATES, refetch());
  return { importCandidates, mutationImportCandidates };
};

export const useGetCandidate = (query: GetCandidateSearch) => {
  const { data, loading, error } = useQuery<GetCandidateResponse>(GET_CANDIDATE, {
    variables: {
      query: { ...query },
    },
  });

  return { candidate: data?.GetCandidate || null, candidateLoading: loading, error };
};

export const useSaveCandidate = (input: GetCandidateSearch) => {
  const [saveCandidate, mutationSaveCandidate] = useMutation(SAVE_CANDIDATE, {
    refetchQueries: [
      {
        query: GET_CANDIDATES,
        variables: {
          query: {},
        },
      },
      {
        query: GET_CANDIDATE,
        variables: {
          query: { ...input },
        },
      },
    ],
  });
  return { saveCandidate, mutationSaveCandidate };
};

export const useSaveCandidateTable = () => {
  const [saveCandidate, mutationSaveCandidate] = useMutation(SAVE_CANDIDATE, {
    refetchQueries: [
      {
        query: GET_CANDIDATES,
        variables: {
          query: {},
        },
      },
    ],
  });
  return { saveCandidateTable: saveCandidate, mutationSaveCandidateTable: mutationSaveCandidate };
};

export const useSendCandidateEmail = () => {
  const [sendCandidateMessages, mutationSendCandidateMessages] = useMutation(SEND_CANDIDATE_EMAIL, refetch());
  return { sendCandidateMessages, mutationSendCandidateMessages };
};

export const useSendCandidateSms = () => {
  const [sendSms, mutationSendSms] = useMutation(SEND_CANDIDATE_SMS, refetch());
  return { sendSms, mutationSendSms };
};

export const useCreateCandidate = () => {
  const [createCandidate, mutationCreateCandidate] = useMutation(CREATE_CANDIDATE, refetch());
  return { createCandidate, mutationCreateCandidate };
};

export const usePublishCandidate = () => {
  const [publishCandidate, mutationPublishCandidate] = useMutation(PUBLISH_CANDIDATE_HRPARTNER, refetch());
  return { publishCandidate, mutationPublishCandidate };
};
