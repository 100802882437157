import { EmojiPeople } from '@mui/icons-material';
import { useState } from 'react';
import { Message, LocationBar, Loading } from 'components';
import Helmet from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useUpdateAscotReview, useGetAscotReview } from 'api/hooks/useAscotReviews';
import { AscotReviewInput } from '__generated__/graphql';
import AscotReviewForm from './AscotReviewForm';

const AscotReviewUpdate = () => {
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const { supportedPersonId, ascotReviewId } = useParams<{ supportedPersonId?: string; ascotReviewId?: string }>();
  const { ascotReview, loading } = useGetAscotReview({ id: ascotReviewId });
  const { updateAscotReview, mutationUpdateAscotReview } = useUpdateAscotReview({ customerId: supportedPersonId });

  const navigate = useNavigate();

  const onSubmit = async (data: AscotReviewInput) => {
    await updateAscotReview({
      awaitRefetchQueries: true,

      variables: {
        input: {
          ...data,
        },
      },
    });
    setOpenSnack(true);
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <Helmet>
        <title>Ascot</title>
      </Helmet>
      <LocationBar section="Ascot" page="Update Ascot Review" Icon={EmojiPeople} />
      <Message response={[mutationUpdateAscotReview]} openSnack={openSnack} setOpenSnack={setOpenSnack} />
      <div className="my-10 px-4 md:px-[5%]">
        <button type="button" className="text-gray-500 font-semibold text-md leading-md flex items-center" onClick={() => navigate(-1)}>
          <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
        </button>

        <AscotReviewForm ascotReview={ascotReview} onSubmit={onSubmit} />
      </div>
    </>
  );
};

export default AscotReviewUpdate;
