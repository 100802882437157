import { useEffect, useState } from 'react';
import { FormLabel } from 'components';
import Button from 'components/Button';
import { Customer } from '../../__generated__/graphql';
import { SupportedPeopleStateTransition } from './types';

interface ManageSupportedPeopleAccountsProps {
  customer: Customer | null | undefined;
  onChangeAccount: (type: string) => void;
  accountLoading: boolean;
  birdieLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  accountRef: any;
}

const ManageSupportedPeopleAccounts = ({ accountRef, customer, onChangeAccount, accountLoading, birdieLoading }: ManageSupportedPeopleAccountsProps) => {
  const [disable, setShouldDisable] = useState<boolean>(false);

  useEffect(() => {
    if (!customer || !customer?.teamId || !customer.teamName || !customer?.city || !customer?.address || !customer.postcode) {
      setShouldDisable(true);
    }
  }, [customer]);

  if (!customer) return null;

  return (
    <div className="mt-8 sm:mt-12 print:break-before-page	" ref={accountRef}>
      <div className="text-black underline text-md sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1 invisible  print:visible">
        Accounts
      </div>

      {disable && (
        <p className="p-2 text-md text-error-600 dark:text-green-500">
          <span className="font-medium">Account buttons disabled please check account details </span>
        </p>
      )}
      {customer?.birdieId && customer?.active && (
        <FormLabel label={`${customer.firstName} ${customer.lastName} birdie is active:`}>
          <a
            className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md print:hidden"
            href={`https://admin.birdie.care/care-recipients/${customer.birdieId}`}
            target="_blank"
            rel="noreferrer"
          >
            Visit Birdie
          </a>
        </FormLabel>
      )}
      {(!customer?.birdieId || !customer?.phone) && (
        <FormLabel label="Birdie">
          <div className="flex flex-col">
            <Button
              disabled={disable || !customer.phone}
              onClick={() => (customer?.id ? onChangeAccount(SupportedPeopleStateTransition.birdieDisabled) : null)}
              color="bg-success-700"
              label="Activate Birdie Account"
              loading={birdieLoading}
              selector="activate-birdie-button"
            />
            <p className="mt-2 text-sm text-error-600 dark:text-green-500">
              <span className="font-medium">
                {customer?.phone ? (
                  `${customer.firstName} ${customer.lastName} does not have a birdie account:`
                ) : (
                  <p>
                    {customer.firstName} {customer.lastName} has no phone number &ensp;
                    <a className="underline" target="_blank" rel="noreferrer" href="https://www.hubspot.com/">
                      add one in hubspot in order to create a birdie account
                    </a>
                  </p>
                )}
              </span>
            </p>
          </div>
        </FormLabel>
      )}
      {!customer?.active ? (
        <FormLabel label="Team Hub">
          <div className="flex flex-col">
            <Button
              disabled={disable}
              onClick={() => onChangeAccount(SupportedPeopleStateTransition.terminated)}
              color="bg-success-700"
              label="Activate Account in Team Hub"
              loading={accountLoading}
              selector="activate-supported-person-button"
            />
            <p className="mt-2 text-sm text-error-600 dark:text-green-500">
              <span className="font-medium">{`${customer.firstName} ${customer.lastName} Team Hub account is not active, please activate the account`}</span>
            </p>
          </div>
        </FormLabel>
      ) : (
        <FormLabel label="Team Hub">
          <div className="flex flex-col">
            <Button
              disabled={disable}
              onClick={() => onChangeAccount(SupportedPeopleStateTransition.active)}
              color="bg-error-700"
              label="Close Team Hub account"
              loading={accountLoading}
              selector="deactivate-supported-person-button"
            />
            <p className="mt-2 text-sm text-primary-600 dark:text-green-500">
              <span className="font-medium">{`${customer?.firstName} ${customer?.lastName} Team Hub account is active`}</span>
            </p>
          </div>
        </FormLabel>
      )}
      {customer?.active && (customer.lat === 0 || customer.lng === 0) && (
        <FormLabel label="Maps">
          <div className="flex flex-col">
            <Button
              disabled={disable}
              onClick={() => onChangeAccount(SupportedPeopleStateTransition.maps)}
              color="bg-success-700"
              label="Activate Maps"
              loading={accountLoading}
            />
            <p className="mt-2 text-sm text-error-600 dark:text-green-500">
              <span className="font-medium">{`${customer?.firstName} ${customer?.lastName} is not displayed on maps and cannot compute travel time`}</span>
            </p>
          </div>
        </FormLabel>
      )}
    </div>
  );
};

export default ManageSupportedPeopleAccounts;
