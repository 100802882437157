/* eslint-disable react/no-danger */
import { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { Edit, ElderlyOutlined } from '@mui/icons-material';
import { FormLabel, Loading, LocationBar } from 'components';
import { Maybe, Customer } from '__generated__/graphql';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetCustomers } from 'api/hooks/useCustomers';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { ImageSizeType } from 'types/types';
import Avatar from 'components/Avatar';

const Profile = () => {
  const { supportedPersonId } = useParams();
  const navigate = useNavigate();
  const [customer, setCustomer] = useState<Customer | null>();
  const { customers, loading } = useGetCustomers({ showAll: true });

  useEffect(() => {
    setCustomer(customers.filter((c: Customer) => c.id === supportedPersonId)[0]);
  }, [supportedPersonId, customers]);
  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Helmet>
        <title>Supported People</title>
      </Helmet>
      <LocationBar section="People" page="Supported People List" Icon={ElderlyOutlined} />
      <div className="mt-10 sm:mt-16">
        <div className="my-10 px-4 md:px-[5%]">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <button type="button" className="text-gray-500 font-semibold text-md leading-md flex items-center" onClick={() => navigate('/supported-people/')}>
              <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
            </button>

            <button
              type="button"
              className="text-gray-500 font-semibold text-md leading-md flex items-center"
              onClick={() => navigate(`/supported-people/${supportedPersonId}/update`)}
            >
              <Edit className="mr-2 w-5 h-5" /> Update this profile
            </button>
          </div>
          <div className="my-10 px-4 md:px-[5%]">
            <div className="flex items-center  py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
              <div className="ps-3">
                <div className="text-black text-xl sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1">
                  <Avatar
                    size={ImageSizeType.XL}
                    avatar={customer?.image?.url || '/defaultProfile.jpg'}
                    alt={`Supported Person ${customer?.firstName} ${customer?.lastName}`}
                  />
                  {customer?.firstName} {customer?.lastName}
                </div>
              </div>
            </div>
            <div className="mt-8 sm:mt-12 w-full">
              <FormLabel label="What people like and admire about me">
                <ul className="w-full lg:w-1/2">
                  {customer?.profileData?.aboutMe?.map((aM: Maybe<string>, index: number) => (
                    <li key={index} className="list list-disc">
                      <p>{aM}</p>
                    </li>
                  ))}
                </ul>
              </FormLabel>

              <FormLabel label=" What matters to me">
                <div className="w-full lg:w-1/2" dangerouslySetInnerHTML={{ __html: customer?.profileData?.matters || '' }} />
              </FormLabel>
              <FormLabel label="How best to support me">
                <div className="w-full lg:w-1/2" dangerouslySetInnerHTML={{ __html: customer?.profileData?.support || '' }} />
              </FormLabel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
