import { Box } from '@mui/material';
import { useMemo } from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { SelfImprovement } from '@mui/icons-material';
import { Table } from 'components';

export type OverdueCustomerTableType = {
  id: string;
  teamId: string;
  circle: string;
  team: string;
  name: string;
  daysOverdue: number;
};

interface OverdueCustomerTableProps {
  overdueCustomers: OverdueCustomerTableType[];
  isOverdue: boolean;
}

const OverdueCustomerTable = ({ overdueCustomers, isOverdue }: OverdueCustomerTableProps) => {
  const navigate = useNavigate();
  const columnVisibility = {
    circle: window.innerWidth > 1280,
    team: window.innerWidth > 640,
    name: true,
    daysOverdue: true,
    wellbeing: true,
  };
  const columnHelper = createColumnHelper<OverdueCustomerTableType>();

  const columns1 = useMemo(() => {
    return [
      columnHelper.accessor('circle', {
        header: () => 'Circle',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('team', {
        header: () => 'Team',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('name', {
        header: () => 'Name',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('daysOverdue', {
        header: () => (isOverdue ? 'Days Overdue' : 'Days due'),
        cell: (info) => (
          <div className="px-2 sm:px-4 md:px-6 py-4 sm:whitespace-nowrap text-gray-700 text-md leading-md">
            {isOverdue ? info.row.original.daysOverdue : info.row.original.daysOverdue * -1}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.display({
        id: 'wellbeing',
        cell: (props) => {
          return (
            <button
              type="button"
              onClick={() => navigate(`/wellbeing-board/${props.row.original.id}?teamId=${props.row.original.teamId}`)}
              className="text-white bg-primary-600 font-medium rounded-lg text-sm p-2 sm:px-5 sm:py-2.5 text-center inline-flex items-center me-2 mb-2"
            >
              <SelfImprovement />
              <span className="hidden sm:block ml-2">See wellbeing board</span>
            </button>
          );
        },
      }),
    ] as Array<ColumnDef<OverdueCustomerTableType, unknown>>;
  }, [columnHelper, navigate, isOverdue]);

  return (
    <Box mt={5}>
      <div className="mt-3 md:mt-12 mb-10">
        {overdueCustomers && overdueCustomers.length > 0 && (
          <Table<OverdueCustomerTableType> data={overdueCustomers} columns={columns1} columnVisibility={columnVisibility} />
        )}
      </div>
    </Box>
  );
};

export default OverdueCustomerTable;
