import { InputField } from 'components/Fields/InputField';
import { SelectField } from 'components/Fields/SelectField';
import { CheckboxField } from 'components/Fields/CheckboxField';
import { isValidEmail } from 'components/Fields/validators';

export default function MedicalObvsUpdate({ disabled }: { disabled: boolean }) {
  const statusOptions = [
    { label: 'Passed', value: 'PASSED' },
    { label: 'Not Passed', value: 'NOT_PASSED' },
  ];

  return (
    <div className="mt-8 sm:mt-12">
      <InputField testId="form-email" disabled={disabled} label="Email" placeholder="Email" name="medicalObvs.emailAddress" validate={isValidEmail} />
      <SelectField
        isRequired={true}
        searchable={true}
        testId="form-status"
        disabled={disabled}
        label="Status"
        name="medicalObvs.status"
        options={statusOptions}
      />
      <InputField testId="form-note" disabled={disabled} label="Any Other Notes" placeholder="Any Other Notes" name="medicalObvs.anyOtherNotes" />
      <CheckboxField testId="form-signature" disabled={disabled} label="Digital Signature Given" name="medicalObvs.digitalSignatureGiven" />
    </div>
  );
}
