import { useEffect, useState } from 'react';
import getTime from 'date-fns/getTime';
import ReactSelect from 'components/Select';
import TimePicker from 'components/TimePicker';
import TWModal from 'components/TWModal';

interface SelectTimeProps {
  date: number;
  onAddRun: (startTime: number, endTime: number, week: number) => void;
  toggleSelectTimeModal: (selectTimeModalState: boolean) => void;
  selectedWeek: number;
  period: string;
}

export default function SelectTime({ period, date, onAddRun, toggleSelectTimeModal, selectedWeek }: SelectTimeProps) {
  const [startDateTime, setStartDateTime] = useState(new Date(new Date(date).setHours(9, 0, 0)));
  const [endDateTime, setEndDateTime] = useState(new Date(new Date(date).setHours(14, 0, 0)));
  const [week, setWeek] = useState({ value: selectedWeek.toString(), label: `Week ${selectedWeek}` });
  const weekOptions = [
    { value: '1', label: 'Week 1' },
    { value: '2', label: 'Week 2' },
  ];
  useEffect(() => {
    setStartDateTime(new Date(new Date(date).setHours(period === 'AM' ? 9 : 14, 0, 0)));
    setEndDateTime(new Date(new Date(date).setHours(period === 'AM' ? 14 : 19, 0, 0)));
  }, [date, period]);

  useEffect(() => {
    setWeek({ value: selectedWeek.toString(), label: `Week ${selectedWeek}` });
  }, [selectedWeek]);

  const onChangeStartDateTime = (dateTime: Date | null) => {
    if (dateTime) {
      setStartDateTime(dateTime);
      setEndDateTime(new Date(new Date(dateTime).setHours(dateTime.getHours() + 5)));
    }
  };

  const onChangeEndDateTime = (dateTime: Date | null) => {
    if (dateTime) {
      setEndDateTime(dateTime);
    }
  };

  const onAddVisit = () => {
    onAddRun(getTime(startDateTime), getTime(endDateTime), parseInt(week.value, 10));
  };

  return (
    <TWModal testId="save-shift-run" title="Add a run" onClose={() => toggleSelectTimeModal(false)} onSave={onAddVisit}>
      <div className="my-5">
        <div className="text-sm font-medium text-gray-700 capitalize w-auto">Week</div>
        <div className="mt-2">
          <ReactSelect selectedValue={week} options={weekOptions} onChange={(option) => setWeek(option)} testId="add-shift-run-select-week" />
        </div>
      </div>
      <div className="mb-5">
        <div className="text-md font-medium text-gray-700 w-auto">Start time</div>
        <div className="mt-2">
          <TimePicker date={startDateTime} onChange={onChangeStartDateTime} />
        </div>
      </div>
      <div className="mb-5">
        <div className="text-md font-medium text-gray-700 w-auto">End time</div>
        <div className="mt-2">
          <TimePicker
            date={endDateTime}
            onChange={onChangeEndDateTime}
            disabled
            minTime={new Date(new Date(startDateTime).setHours(startDateTime.getHours() + 5))}
            maxTime={new Date(new Date(startDateTime).setHours(startDateTime.getHours() + 5))}
          />
        </div>
      </div>
    </TWModal>
  );
}
