import { useEffect, useState } from 'react';
import { FormLabel } from 'components';
import Button from 'components/Button';
import { SupportWorker } from '../../__generated__/graphql';
import { SupportWorkerStateTransition } from './types';

interface ManageSupportWorkerAccountsProps {
  supportWorker: SupportWorker | null | undefined;
  onChangeAccount: (type: string) => void;
  birdieLoading: boolean;
  accountChangeLoading: boolean;
  cognitoLoading: boolean;
}

const ManageSupportWorkerAccounts = ({
  supportWorker,
  onChangeAccount,
  birdieLoading,
  accountChangeLoading,
  cognitoLoading,
}: ManageSupportWorkerAccountsProps) => {
  const [disable, setShouldDisable] = useState<boolean>(false);
  useEffect(() => {
    if (
      !supportWorker ||
      !supportWorker.teamId ||
      !supportWorker.teamName ||
      !supportWorker.phone ||
      !supportWorker?.city ||
      !supportWorker?.address ||
      !supportWorker.postcode
    ) {
      setShouldDisable(true);
    }
  }, [supportWorker]);
  if (!supportWorker) return null;

  return (
    <div className="mt-8 sm:mt-12">
      {disable && (
        <p className="p-2 text-md text-error-600 dark:text-green-500">
          <span className="font-medium">Account buttons disabled please check account details </span>
        </p>
      )}
      {supportWorker?.birdieId && (
        <FormLabel label={`${supportWorker?.fullName} birdie is active:`}>
          <a
            className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
            href={`https://admin.birdie.care/carers/${supportWorker.birdieId}`}
            target="_blank"
            rel="noreferrer"
            data-cy="has-birdie-link"
          >
            Visit Birdie
          </a>
        </FormLabel>
      )}
      {(!supportWorker?.birdieId || !supportWorker?.phone) && (
        <FormLabel
          label={
            supportWorker?.phone
              ? `${supportWorker?.fullName} does not have a birdie account:`
              : `${supportWorker?.fullName} has no phone number; add one in hrpartner in order to create a birdie account:`
          }
        >
          <Button
            loading={birdieLoading}
            disabled={disable}
            label="Activate Birdie Account"
            color="bg-success-700"
            selector="activate-birdie-button"
            onClick={() => (supportWorker?.id ? onChangeAccount(SupportWorkerStateTransition.birdieDisabled) : null)}
          />
        </FormLabel>
      )}
      {!supportWorker?.isActive && (
        <FormLabel label={`${supportWorker?.fullName} Team Hub account is not active, please activate the account`}>
          <Button
            loading={accountChangeLoading}
            disabled={disable}
            label="Activate Account in Team Hub"
            color="bg-success-700"
            selector="activate-support-worker-button"
            onClick={() => onChangeAccount(SupportWorkerStateTransition.terminated)}
          />
        </FormLabel>
      )}
      {supportWorker.isActive && (supportWorker.lat === 0 || supportWorker.lng === 0) && (
        <FormLabel label={`${supportWorker?.fullName} is not displayed on maps and cannot compute travel time:`}>
          <Button
            loading={accountChangeLoading}
            color="bg-primary-700"
            label="Activate Maps"
            disabled={disable}
            onClick={() => onChangeAccount(SupportWorkerStateTransition.maps)}
          />
        </FormLabel>
      )}
      {!supportWorker.cognito && (
        <FormLabel label={`${supportWorker?.fullName} can't access teamhub, enable a login for ${supportWorker?.fullName}:`}>
          <Button
            loading={cognitoLoading}
            color="bg-success-700"
            label="Create a login to Team Hub"
            disabled={disable}
            selector="login-support-worker-button"
            onClick={() => onChangeAccount(SupportWorkerStateTransition.cognitoDisabled)}
          />
        </FormLabel>
      )}

      {supportWorker.isActive && (
        <FormLabel label={`${supportWorker?.fullName} Team Hub account is active`}>
          <Button
            loading={accountChangeLoading}
            color="bg-error-700"
            label="Close Team Hub account"
            // disabled={disable}
            selector="deactivate-support-worker-button"
            onClick={() => onChangeAccount(SupportWorkerStateTransition.active)}
          />
        </FormLabel>
      )}
    </div>
  );
};

export default ManageSupportWorkerAccounts;
