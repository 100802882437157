import { gql, useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { Notification, NotificationSearch } from '__generated__/graphql';

export const UPDATE_NOTIFICATION = gql`
  mutation UpdateNotification($input: NotificationUpdate) {
    UpdateNotification(input: $input) {
      message
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query GetNotifications($query: NotificationSearch) {
    GetNotifications(query: $query) {
      id
      teamId
      supportWorkerId
      description
      dateCreated
      fullName
      avatar
      type
      status
    }
  }
`;

export const REMOVE_NOTIFICATIONS = gql`
  mutation RemoveNotifications($input: RemoveNotificationsInput) {
    RemoveNotifications(input: $input) {
      message
      date
    }
  }
`;

interface GetNotificationsResponse {
  GetNotifications: Notification[];
}

export const useGetNotifications = (query: NotificationSearch) => {
  const { loading: notificationsLoading, data } = useQuery<GetNotificationsResponse>(GET_NOTIFICATIONS, {
    variables: {
      query: {
        ...query,
      },
    },
  });
  return { notifications: data?.GetNotifications || [], notificationsLoading };
};

export const useRemoveNotifications = (query: NotificationSearch) => {
  const [removeNotifications, mutationRemoveNotification] = useMutation(REMOVE_NOTIFICATIONS, {
    refetchQueries: [
      {
        query: GET_NOTIFICATIONS,
        variables: {
          query: {
            ...query,
          },
        },
      },
    ],
  });

  return { removeNotifications, mutationRemoveNotification };
};

export const useUpdateNotification = (query: NotificationSearch) => {
  const [updateNotification, mutationUpdate] = useMutation(UPDATE_NOTIFICATION, {
    refetchQueries: [
      {
        query: GET_NOTIFICATIONS,
        variables: {
          query: {
            ...query,
          },
        },
      },
    ],
  });
  return { updateNotification, mutationUpdate };
};

export const useGetNotificationsLazy = () => {
  const [getNotifications, { loading: notificationsLoading, data }] = useLazyQuery<GetNotificationsResponse>(GET_NOTIFICATIONS);
  return { getNotifications, notificationsLoading, notifications: data?.GetNotifications || [] };
};
