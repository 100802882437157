import { AddMileageReportingHourInput, DeleteMileageReportingHourInput, ReportingHours, ReportingHoursPeriod } from '__generated__/graphql';
import { formatTime } from 'services/helpers';
import Button from 'components/Button';
import { ShiftType } from './service';
import CreateMileage from './CreateMileage';

interface MileageProps {
  reportingHours: ReportingHours[];
  reportingHoursPeriod?: ReportingHoursPeriod | null;
  apiAddMileageLoading: boolean;
  apiDeleteMileageLoading: boolean;
  addMileageReportHour: (input: AddMileageReportingHourInput) => void;
  deleteMileageReportingHour: (input: DeleteMileageReportingHourInput) => void;
}

const Mileage = ({
  reportingHoursPeriod,
  reportingHours,
  apiAddMileageLoading,
  apiDeleteMileageLoading,
  addMileageReportHour,
  deleteMileageReportingHour,
}: MileageProps) => {
  const mileages = reportingHours.filter((rh) => rh.type === ShiftType.mileage && rh.mileage);

  const onDeleteMileage = (rh: ReportingHours) => {
    deleteMileageReportingHour({
      id: rh.id,
      teamId: rh.teamId,
      supportWorkerId: rh.secondarySupportWorkerId,
      period: rh.reportingHoursPeriodId,
    });
  };

  return (
    <>
      <div className="text-display-xs text-center font-semibold text-primary-700 mb-5">Other Mileage</div>
      {mileages.map((rh: ReportingHours, index: number) => {
        return (
          <div key={index}>
            <div className="text-md leading-md text-gray-800 font-medium mt-1">
              <span className="font-semibold">Date:</span> {formatTime(rh?.mileage?.date || 0, 'PPP')}
            </div>
            <div className="text-md leading-md text-gray-800 font-medium mt-1">
              <span className="font-semibold">Notes:</span> {rh?.mileage?.notes}
            </div>
            <div className="text-md leading-md text-gray-800 font-medium mt-1">
              <span className="font-semibold">Mileage:</span> {rh?.mileage?.mileage} miles/£{((rh?.mileage?.mileage || 0) * 0.45).toFixed(2)}
            </div>
            <div className="text-md leading-md text-gray-800 font-medium mt-1">
              <span className="font-semibold">Destination:</span> {rh?.mileage?.mileageDestination}
            </div>
            <div className="flex justify-end mt-5">
              <Button
                loading={apiDeleteMileageLoading}
                color="bg-error-700"
                label="Delete"
                disabled={reportingHoursPeriod?.status === 'Submitted'}
                onClick={() => onDeleteMileage(rh)}
                loadingLabel="Deleting..."
              />
            </div>
            <div className="w-full h-px bg-gray-100 my-4" />
          </div>
        );
      })}
      <CreateMileage reportingHoursPeriod={reportingHoursPeriod} addMileageReportHour={addMileageReportHour} apiLoading={apiAddMileageLoading} />
    </>
  );
};

export default Mileage;
