import { useNavigate } from 'react-router-dom';

const AscotButtons = ({
  reviewId,
  ascotReviewId,
  ascotExpectedOutcomeId,
  teamId,
}: {
  reviewId: string;
  ascotReviewId: string | undefined;
  ascotExpectedOutcomeId: string | undefined;
  teamId: string | undefined;
}) => {
  const navigate = useNavigate();
  return (
    <>
      {ascotReviewId ? (
        <button
          type="button"
          onClick={() => navigate(`ascot/${ascotReviewId}?teamId=${teamId}`)}
          className="text-primary-700 underline text-md leading-md font-medium"
        >
          ASCOT Review
        </button>
      ) : (
        <button
          type="button"
          onClick={() => navigate(`review/${reviewId}/ascot/create?teamId=${teamId}`)}
          className="text-white bg-primary-700 rounded-lg px-4 py-2 font-semibold text-md leading-md"
        >
          Add ASCOT Review
        </button>
      )}
      {ascotReviewId && ascotExpectedOutcomeId ? (
        <button
          type="button"
          onClick={() => navigate(`ascot/${ascotReviewId}/expected-outcome/${ascotExpectedOutcomeId}/update?teamId=${teamId}`)}
          className="text-primary-700 underline text-md leading-md font-medium"
        >
          Expected Outcomes
        </button>
      ) : ascotReviewId ? (
        <button
          type="button"
          onClick={() => navigate(`ascot/${ascotReviewId}/expected-outcome/create?teamId=${teamId}`)}
          className="text-white bg-primary-700 rounded-lg px-4 py-2 font-semibold text-md leading-md"
        >
          Add Expected Outcomes
        </button>
      ) : (
        <div />
      )}
    </>
  );
};

export { AscotButtons };
