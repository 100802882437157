import React, { useState, useEffect } from 'react';
import getTime from 'date-fns/getTime';
import addHours from 'date-fns/addHours';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ShiftType } from 'pages/ReportingHours/service';
import { clockMessage } from 'config/messages';
import { Rota, ShiftInputItem } from '__generated__/graphql';
import { SelectOption } from 'types/types';
import TextBox from 'components/TextBox';
import { Loading } from 'components';
import { getStartDateTime, getEndDateTime, getDurationInMinutes, setHoursAndMinutes, addHoursToDate } from '../../../services/helpers';

interface CreateNewShiftProps {
  toggleCreateShiftModal: (createShiftModalState: boolean) => void;
  createShiftModalState: boolean;
  onCreateShift: (shift: Partial<ShiftInputItem>) => Promise<void>;
  customers: SelectOption[];
  rota: Rota;
  supportWorkers: SelectOption[];
  apiLoading: boolean;
}

export default function CreateNewShift({
  toggleCreateShiftModal,
  createShiftModalState,
  onCreateShift,
  customers,
  rota,
  supportWorkers,
  apiLoading,
}: CreateNewShiftProps) {
  const [customerId, setCustomer] = useState<string | null>(null);
  const [startDateTime, setStartDateTime] = useState<Date | null>();
  const [endDateTime, setEndDateTime] = useState<Date | null>();
  const [validation, setValidation] = useState<string | null>(null);
  const [oneOffVisitNote, setOneOffVisitNote] = useState<string | null>('');
  const [oneOffVisitReason, setOneOffVisitReason] = useState<string | null>('0');
  const [ownerId, setOwner] = useState<string | null>('');
  const [newShiftType, setNewShiftType] = useState<string | null>(ShiftType.visit);
  const [shiftStartDateTime, setShiftStartDateTime] = useState<Date | null>();

  useEffect(() => {
    setStartDateTime(new Date());
    setEndDateTime(addHours(Date.now(), 1));
    setValidation(null);
  }, []);

  const onChangeShiftType = (shiftType: string | null) => {
    setNewShiftType(shiftType);
    setShiftStartDateTime(null);
    setValidation(null);
  };

  const onChangeCustomer = (customer: string | null) => {
    setCustomer(customer);
    setValidation(null);
  };

  const onChangeOwner = (sw: string | null) => {
    setOwner(sw);
    setValidation(null);
  };

  const onChangeStartDateTime = (dateTime: Date | null) => {
    if (dateTime) {
      setStartDateTime(dateTime);
      setEndDateTime(addHours(dateTime, 1));
      setValidation(null);
    }
  };

  const onChangeEndDateTime = (dateTime: Date | null) => {
    setEndDateTime(dateTime);
    setValidation(null);
  };

  const onChangeOneOffVisitReason = (reason: string | null) => {
    setOneOffVisitReason(reason);
    setValidation(null);
  };

  const onChangeOneOffVisitNote = (note: string | null) => {
    setOneOffVisitNote(note);
    setValidation(null);
  };

  const onChangeShiftStartDate = (time: Date | null) => {
    if (time) {
      setShiftStartDateTime(time);
      setValidation(null);
    }
  };

  const onAddVisit = () => {
    setValidation(null);

    if (newShiftType === ShiftType.visit && !shiftStartDateTime) {
      setValidation('Please assign a shift start date');
      return;
    }

    if (newShiftType === ShiftType.visit && !ownerId) {
      setValidation('Please assign a shift owner to this shift');
      return;
    }

    if (!customerId) {
      setValidation('Please select a person');
      return;
    }

    if (!startDateTime || !endDateTime) {
      setValidation('Please select a start date and end date');
      return;
    }

    if (getTime(startDateTime) >= getTime(endDateTime)) {
      setValidation('Please provide a valid shift date time; end time should be greater than start time');
      return;
    }

    if (getDurationInMinutes(getTime(startDateTime), getTime(endDateTime)) > 300) {
      setValidation('The length of this visit is greater than 5 hours');
      return;
    }

    if (oneOffVisitReason === '0') {
      setValidation('Please select a reason for this one off visit');
      return;
    }

    if (oneOffVisitNote === '') {
      setValidation('Please add a note for this one off visit');
      return;
    }

    const shiftStartDate = shiftStartDateTime ? setHoursAndMinutes(startDateTime.getTime(), shiftStartDateTime.getTime()) : null;
    const shiftEndDate = shiftStartDate ? addHoursToDate(shiftStartDate, 5) : null;

    if (newShiftType === ShiftType.visit && shiftStartDate && shiftStartDate > startDateTime.getTime()) {
      setValidation('Please assign a shift start time thats before the visit start time');
      return;
    }

    onCreateShift({
      customerId,
      startDateTime: getTime(startDateTime),
      endDateTime: getTime(endDateTime),
      type: newShiftType || ShiftType.visit,
      oneOffVisitReason,
      oneOffVisitNote,
      supportWorkerId: ownerId,
      secondarySupportWorkerId: ownerId,
      shiftStartDateTime: shiftStartDate,
      shiftEndDateTime: shiftEndDate,
    });
  };

  return (
    <Dialog data-cy="create-shift-dialog" open={createShiftModalState} onClose={() => toggleCreateShiftModal(false)}>
      <DialogTitle sx={{ fontSize: '1.6em', padding: { xs: '0.8em', sm: '1.2em' } }}>Create a new shift</DialogTitle>
      <DialogContent sx={{ width: '100%', marginLeft: '0.2em', marginBottom: '1em', padding: { xs: '0', sm: '1em' } }}>
        {validation && (
          <Alert variant="filled" severity="error" sx={{ ml: '1em', mb: '1em', padding: { xs: '0.6', sm: '1em' }, width: { xs: '90%', sm: '90%' } }}>
            {validation}
          </Alert>
        )}
        {/* <Box bgcolor="#D4E3FC" mx="1em" mb="1em" px={2} py={1} width="90%">
          This will allow you to add a <b>one-off visit</b> only and will use the WSWs banked hours. To add contracted hours visits that appear every rota,
          please do this in Rota Templates
        </Box> */}
        <FormControl sx={{ width: '90%', ml: '1em', mb: '1em', mt: '1em' }}>
          <FormLabel>What type of shift</FormLabel>
          <RadioGroup row name="isToBeRescheduled" value={newShiftType} onChange={(e) => onChangeShiftType(e.target.value)}>
            <FormControlLabel value="visit" control={<Radio />} label="5hr Fixed Shift" />
            <FormControlLabel value="oneoff" control={<Radio />} label="Banked Hours Shift" />
          </RadioGroup>
        </FormControl>
        <br />
        {newShiftType === ShiftType.visit && (
          <Box bgcolor="#D4E3FC" mx="1em" mb="1em" px={2} py={1} width="90%">
            Fixed shifts allow you to create a 5 hour agreed shift, if you do not have enough agreed shifts published from rota templates
          </Box>
        )}
        {newShiftType === ShiftType.oneoff && (
          <Box bgcolor="#D4E3FC" mx="1em" mb="1em" px={2} py={1} width="90%">
            Banked Hours shifts allow you to create a Banked Hours shift, use this option if you already have enough agreed shifts
          </Box>
        )}
        {newShiftType === ShiftType.visit && (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FormControl sx={{ width: '90%', ml: '1em', mb: '1em' }}>
              <Typography>Start time</Typography>
              <TimePicker ampm={false} minutesStep={5} onChange={onChangeShiftStartDate} />
            </FormControl>
          </LocalizationProvider>
        )}
        <FormControl data-cy="owner-selector" sx={{ width: '90%', ml: '1em', mb: '1em' }}>
          <Typography>Assign a shift owner</Typography>
          <Select labelId="owner" id="owner" value={ownerId} onChange={(e) => onChangeOwner(e.target.value)}>
            <MenuItem data-cy="owner-item-empty" key="" value="">
              None
            </MenuItem>
            {supportWorkers.map((option: SelectOption, i: number) => (
              <MenuItem data-cy={`owner-item-${i}`} key={option.value} value={option.value.toString()}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl data-cy="person-selector" sx={{ width: '90%', ml: '1em', mb: '1em' }}>
          <Typography>Select a person</Typography>
          <Select labelId="customer" id="customer" value={customerId} onChange={(e) => onChangeCustomer(e.target.value)}>
            {customers.map((option: SelectOption, i: number) => (
              <MenuItem data-cy={`person-item-${i}`} key={option.value} value={option.value.toString()}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <br />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <FormControl sx={{ width: '90%', ml: '1em', mb: '1em' }}>
            <Typography>Start time</Typography>
            <DateTimePicker
              format="PPPP HH:mm"
              ampm={false}
              closeOnSelect
              // toolbarTitle="Select Start Time"
              minutesStep={5}
              minDate={getStartDateTime(rota?.startRotaDateTime || 0)}
              maxDate={getEndDateTime(rota?.endRotaDateTime || 0)}
              value={startDateTime}
              onChange={onChangeStartDateTime}
              slotProps={{ textField: { helperText: clockMessage } }}
            />
          </FormControl>
          &nbsp;
          <FormControl sx={{ width: '90%', ml: '1em', mb: '1em' }}>
            <Typography>End time</Typography>
            <DateTimePicker
              format="PPPP HH:mm"
              ampm={false}
              // showToolbar
              closeOnSelect
              // toolbarTitle="Select End Time"
              minutesStep={5}
              minDate={getStartDateTime(rota?.startRotaDateTime || 0)}
              maxDate={getEndDateTime(rota?.endRotaDateTime || 0)}
              value={endDateTime}
              onChange={onChangeEndDateTime}
              slotProps={{ textField: { helperText: clockMessage } }}
            />
          </FormControl>
          <FormControl data-cy="reason-selector" sx={{ width: '90%', ml: '1em', mb: '1em', mt: '1em' }}>
            <Typography>What is the reason for this one off visit</Typography>
            <Select size="small" id="reason" value={oneOffVisitReason} onChange={(e) => onChangeOneOffVisitReason(e.target.value)}>
              <MenuItem value="0">What is the reason for this one off visit</MenuItem>
              <MenuItem data-cy="reason-item-0" value="Is a reschedule of a cancelled job (covered by package)">
                Is a reschedule of a cancelled job (covered by package)
              </MenuItem>
              <MenuItem data-cy="reason-item-1" value="Is an emergency visit (not chargeable)">
                Is an emergency visit (not chargeable)
              </MenuItem>
              <MenuItem data-cy="reason-item-2" value="Is an pre-agreed extra visit (chargeable)">
                Is an pre-agreed extra visit (chargeable)
              </MenuItem>
              <MenuItem data-cy="reason-item-3" value="Is an additional complementary visit using banked hours">
                Is an additional complementary visit using banked hours
              </MenuItem>
              <MenuItem data-cy="reason-item-4" value="New package starting at short notice (chargeable)’">
                New package starting at short notice (chargeable)
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl data-cy="reason-for-visit" sx={{ width: '90%', ml: '1em', mb: '1em', mt: '0.4em' }}>
            <Typography>One off visit note</Typography>
            <TextBox value={oneOffVisitNote} onChange={onChangeOneOffVisitNote} limit={1000} />
          </FormControl>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions sx={{ padding: '1em' }}>
        <Button data-cy="close-shift-button" variant="contained" onClick={() => toggleCreateShiftModal(false)}>
          Close
        </Button>
        {apiLoading ? (
          <Loading isComponent />
        ) : (
          <Button data-cy="create-shift-button" variant="contained" onClick={() => onAddVisit()}>
            Create
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
