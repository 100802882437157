import { ChangeEvent } from 'react';

/* eslint-disable max-len */
const Switch = ({
  label,
  isChecked,
  name,
  onChange,
}: {
  name: string;
  label: string;
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
  isChecked: boolean;
}) => {
  return (
    <label className=" inline-flex items-center  cursor-pointer" htmlFor={label}>
      <input
        className="sr-only peer"
        id={label}
        name={name}
        type="checkbox"
        checked={isChecked}
        onChange={(e) => {
          onChange(e);
        }}
      />
      <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-600 dark:peer-focus:ring-blue-600 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
      <span className="ms-3 text-md font-medium text-gray-900 dark:text-gray-300">{label}</span>
    </label>
  );
};

export { Switch };
