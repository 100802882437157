import React, { createContext, useContext } from 'react';
import { ApolloError } from '@apollo/client';
import { Team } from '__generated__/graphql';
import useGetTeams from 'api/hooks/useGetTeams';
import { Error } from 'components';

type UserProviderProps = {
  children: React.ReactNode;
};

export interface GenericContextProps {
  teamsLoading: boolean;
  genericError: ApolloError | undefined;
  teams: Team[] | undefined;
  team: Team | null | undefined;
}

const defaultProps = {
  teamsLoading: false,
  genericError: undefined,
  teams: undefined,
  team: undefined,
};

const GenericContext = createContext<GenericContextProps>(defaultProps);

const GenericProvider = ({ children }: UserProviderProps) => {
  const { teams, teamsLoading, error: errorTeams } = useGetTeams();

  const storageTeamId = localStorage.getItem('teamId');

  // populate this object with team hub data, so we dont need to call multiple times
  const team = storageTeamId ? teams.find((t: Team) => t.teamId === storageTeamId) : null;
  if (errorTeams) return <Error />;

  return (
    <GenericContext.Provider
      value={{
        teamsLoading,
        genericError: errorTeams,
        teams,
        team,
      }}
    >
      {children}
    </GenericContext.Provider>
  );
};

const useGeneric = (): GenericContextProps | null => {
  const context = useContext(GenericContext);

  return context;
};

export { GenericProvider, useGeneric };
