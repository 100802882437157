import { useUser, UserContextProps } from 'context/userContext';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { CalendarMonth } from '@mui/icons-material';
import { Loading, LocationBar } from 'components';
import { useSaveSupportWorkerProfile } from 'api/hooks/useSupportWorkers';
import ShiftsList from './ShiftsList';
import SelectRota from '../../components/SelectRota';
import Footer from './ShiftsList/ShiftsListFooter';

export default function TeamShifts() {
  const { user, userLoading } = useUser() as UserContextProps;
  const [createShiftModalState, toggleCreateShiftModal] = useState<boolean>(false);
  const [createActivityModalState, toggleCreateActivityModal] = useState<boolean>(false);
  const { saveSupportWorkerProfile } = useSaveSupportWorkerProfile();

  useEffect(() => {
    saveSupportWorkerProfile({
      variables: {
        input: { lastLoggedInDate: Date.now(), id: user?.profile },
      },
    });
  }, [saveSupportWorkerProfile, user?.profile]);
  if (userLoading) return <Loading />;
  return (
    <>
      <Helmet>
        <title>Team Shifts | Rota</title>
      </Helmet>
      <LocationBar section="Rota" page="Team Shifts" Icon={CalendarMonth} />
      <div className="my-10 px-4 md:px-[5%]">
        {user && <SelectRota user={user} toggleCreateShiftModal={toggleCreateShiftModal} toggleCreateActivityModal={toggleCreateActivityModal} showAddShift />}
        {!user?.rota?.id && (
          <h2 data-cy="team-shifts-no-rota" className="flex wrap">
            This team has no current rota
          </h2>
        )}
        {user?.rota?.id && user?.rota?.startRotaDateTime && (
          <ShiftsList
            toggleCreateShiftModal={toggleCreateShiftModal}
            createShiftModalState={createShiftModalState}
            toggleCreateActivityModal={toggleCreateActivityModal}
            createActivityModalState={createActivityModalState}
            user={user}
            rotaId={user.rota.id}
            team={user?.teamId}
            rotaStart={user.rota.startRotaDateTime}
          />
        )}
      </div>
      <Footer />
    </>
  );
}
