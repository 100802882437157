import { useState } from 'react';
import { Family, Maybe } from '__generated__/graphql';
// import { Add } from '@mui/icons-material';
import { useCreateFamilyAccounts, useGetCustomer } from 'api/hooks/useCustomers';
import { Loading, FormLabel } from 'components';
import Button from 'components/Button';
import FamilyMemberDetail from './FamilyMemberDetail';
import EditFamilyMember from './EditFamilyMember';

interface FamilyMembersProps {
  teamId: string;
  customerId: string;
  // familyMembers: Maybe<Maybe<Family>[]> | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  familyMembersRef?: any;
}

const FamilyMembers = ({ teamId, customerId, familyMembersRef }: FamilyMembersProps) => {
  const [showEditFamilyMember, setShowEditFamilyMember] = useState(false);
  const [selectedFamilyMember, setSelectedFamilyMember] = useState<Maybe<Family>>();
  const { customer, loading } = useGetCustomer({
    teamId,
    id: customerId,
  });
  const { createFamilyAccounts, mutationCreateFamilyAccounts } = useCreateFamilyAccounts({ showAll: true });

  const onEditFamilyMember = (note: Maybe<Family>) => {
    setSelectedFamilyMember(note);
    setShowEditFamilyMember(true);
  };

  if (loading || mutationCreateFamilyAccounts.loading) {
    return <Loading />;
  }

  const syncFamilyMembers = () => {
    const query = { variables: { input: { id: customer?.id, teamId: customer?.teamId } } };
    createFamilyAccounts(query);
  };

  return (
    <div ref={familyMembersRef}>
      {showEditFamilyMember && (
        <EditFamilyMember customerId={customerId} teamId={teamId} familyMember={selectedFamilyMember} onClose={() => setShowEditFamilyMember(false)} />
      )}
      {customer?.active && (
        <div className="print:hidden">
          <FormLabel label="Sync Family Members">
            <div className="flex flex-col">
              <Button
                onClick={syncFamilyMembers}
                color="bg-success-700"
                label="Sync Family Members"
                loading={loading || mutationCreateFamilyAccounts.loading}
              />
            </div>
          </FormLabel>
        </div>
      )}
      {customer?.familyMembers?.map((familyMember: Maybe<Family>, index: number) => (
        <div key={familyMember?.id}>
          <FamilyMemberDetail familyMember={familyMember} onEditFamilyMember={onEditFamilyMember} index={index} />
        </div>
      ))}
    </div>
  );
};

export default FamilyMembers;
