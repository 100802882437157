import { Filters, Loading, Search, Table } from 'components';
import { useState, useEffect, useMemo } from 'react';
import { CheckBadgeIcon, ClockIcon, AdjustmentsHorizontalIcon, PauseCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { camelCaseToNormalString } from 'services/strings';
import { useGeneric, GenericContextProps } from 'context/genericContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetCustomers } from 'api/hooks/useCustomers';
import { useUser, UserContextProps } from 'context/userContext';
import { ImageSizeType, SelectOption } from 'types/types';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import AvatarGroup from 'components/AvatarGroup';
import { Customer } from '../../__generated__/graphql';
import WellbeingNav from './WellbeingNav';
import { WellbeingStatus } from './types';

const Status = ({ status, small }: { status: string; small?: boolean }) => {
  switch (status) {
    case WellbeingStatus.ON_TRACK:
      return (
        <div
          className={`bg-success-50 border border-success-700 rounded-full md:rounded-2xl text-success-700 text-sm leading-sm font-medium w-fit flex items-center ${
            small ? 'p-1' : 'px-2.5 py-0.5'
          }`}
          data-cy="list-status"
        >
          <CheckBadgeIcon className="w-5 h-5 text-success-500" />
          {!small && <div className="ml-2">{camelCaseToNormalString(status)}</div>}
        </div>
      );
    case WellbeingStatus.OFF_TRACK:
      return (
        <div
          className={`bg-red-50 border border-red-700 rounded-full md:rounded-2xl text-red-700 text-sm leading-sm font-medium w-fit flex items-center ${
            small ? 'p-1' : 'px-2.5 py-0.5'
          }`}
          data-cy="list-status"
        >
          <ClockIcon className="w-5 h-5 text-red-500" />
          {!small && <div className="ml-2">{camelCaseToNormalString(status)}</div>}
        </div>
      );
    case WellbeingStatus.SLIGHTLY_OFF_TRACK:
      return (
        <div
          className={`bg-yellow-50 border border-yellow-700 rounded-full md:rounded-2xl text-yellow-700 text-sm leading-sm font-medium w-fit flex items-center ${
            small ? 'p-1' : 'px-2.5 py-0.5'
          }`}
          data-cy="list-status"
        >
          <ClockIcon className="w-5 h-5 text-yellow-500" />
          {!small && <div className="ml-2">{camelCaseToNormalString(status)}</div>}
        </div>
      );
    case WellbeingStatus.PAUSED:
      return (
        <div
          className={`bg-gray-50 border border-gray-700 rounded-full md:rounded-2xl text-gray-700 text-sm leading-sm font-medium w-fit flex items-center ${
            small ? 'p-1' : 'px-2.5 py-0.5'
          }`}
          data-cy="list-status"
        >
          <PauseCircleIcon className="w-5 h-5 text-gray-500" />
          {!small && <div className="ml-2">{camelCaseToNormalString(status)}</div>}
        </div>
      );
    default:
      return (
        <div
          className={`bg-success-50 border border-success-700 rounded-full md:rounded-2xl text-success-700 text-sm leading-sm font-medium w-fit flex items-center ${
            small ? 'p-1' : 'px-2.5 py-0.5'
          }`}
          data-cy="list-status"
        >
          <CheckBadgeIcon className="w-5 h-5 text-success-500" />
          {!small && <div className="ml-2">{camelCaseToNormalString(status)}</div>}
        </div>
      );
  }
};

const RowActions = ({ row }: { row: Customer }) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col md:flex-row md:items-center justify-end gap-2">
      <button
        type="button"
        onClick={() => navigate(`/wellbeing-board/${row.id}?teamId=${row.teamId}`)}
        aria-label="Edit"
        data-cy="list-edit-button"
        className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md mt-0.5"
      >
        View
      </button>
    </div>
  );
};
const WellbeingBoard = () => {
  const columnVisibility = {
    firstName: true,
    wellbeingStatus: true,
    linkWorkers: window.innerWidth > 640,
  };

  const { user, userLoading } = useUser() as UserContextProps;
  const [search, setSearch] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const { teams: genericTeam, teamsLoading } = useGeneric() as GenericContextProps;

  const teams = useMemo(() => genericTeam?.map((t) => ({ value: t.teamId, label: t.teamName })) || [], [genericTeam]);
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
  const [filters, setFilters] = useState([
    { name: 'teams', selectedValue: { value: 'all', label: 'All' }, values: [{ value: 'all', label: 'All' }, ...teams] },
  ]);

  const teamId = searchParams.get('teamId') || user?.teamId || '';
  const [team, setTeam] = useState(teamId || 'all');

  const { customers, loading: customersLoading } = useGetCustomers({});

  useEffect(() => {
    const newFilters = filters;
    newFilters[0].values = [{ value: 'all', label: 'All' }, ...teams];
    setFilters(newFilters);
  }, [filters, teams]);

  const columnHelper = createColumnHelper<Customer>();

  const columns1 = [
    columnHelper.accessor('firstName', {
      header: () => 'Supported Person',
      cell: (info) => (
        <div className="flex items-center font-medium text-gray-900 md:whitespace-nowrap dark:text-white">
          <img
            className="w-12 h-12 rounded-full"
            src={info.row.original.image?.url || '/defaultProfile.jpg'}
            alt={`Link worker ${info.row.original.firstName} ${info.row.original.lastName}`}
          />
          <div className="ps-3">
            <div data-cy="list-name" className="text-md leading-md font-semibold text-gray-700">
              {`${info.row.original.firstName} ${info.row.original.lastName}`}
            </div>
          </div>
        </div>
      ),
      footer: (info) => info.column.id,
    }),

    columnHelper.accessor('wellbeingStatus', {
      header: () => 'Wellingbeing Status',
      cell: (info) => <Status status={info.row.original.wellbeingStatus || ''} />,
      footer: (info) => info.column.id,
    }),

    columnHelper.accessor('linkWorkers', {
      header: () => 'Link Workers',
      cell: (info) => (
        <div className="flex-row">
          <AvatarGroup
            avatars={info.row.original?.linkWorkers?.map((lw) => ({ avatar: lw?.avatar ?? '', alt: lw?.fullName ?? '', tooltip: lw?.fullName ?? '' })) ?? []}
            size={ImageSizeType.LG}
            max={4}
            border
          />
        </div>
      ),
      footer: (info) => info.column.id,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (props) => <RowActions row={props.row.original} />,
    }),
  ] as Array<ColumnDef<Customer, unknown>>;

  const filterByName = (customer: Customer) => {
    if (`${customer.firstName} ${customer.lastName}`.toLowerCase().includes(search.toLowerCase())) return true;
    return false;
  };

  const filterByTeam = (sw: Customer) => {
    if (team === 'all') return true;
    if (team && sw.teamId === team) return true;
    return false;
  };

  const filteredCustomers = () => {
    return customers
      ?.filter((sw: Customer) => filterByTeam(sw))
      .filter((sw: Customer) => filterByName(sw))
      .sort((x: Customer, y: Customer) => (y.dateUpdated || 0) - (x.dateUpdated || 0));
  };

  const onSaveFilters = () => {
    setTeam(filters[0].selectedValue.value);
    setSearchParams({ teamId: filters[0].selectedValue.value });

    setShowFiltersModal(false);
  };

  const onFilterChange = (name: string, value: SelectOption) => {
    const newFilters = [...filters];
    const filterIndex = newFilters.findIndex((f) => f.name === name);
    newFilters[filterIndex].selectedValue = value;
    setFilters(newFilters);
  };
  const rows = filteredCustomers()?.map((c: Customer) => {
    return {
      ...c,
    };
  });
  if (customersLoading || userLoading || teamsLoading) {
    return <Loading />;
  }
  return (
    <>
      <WellbeingNav />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-col lg:flex-row items-center justify-between mt-8 md:mt-16">
          <div className="flex flex-col md:flex-row items-center gap-4 md:gap-9 w-full md:w-fit">
            <Search value={search} onSearch={setSearch} />
            {user?.permissions?.admin && (
              <div className="flex flex-col md:flex-row items-center gap-3 w-full md:w-fit">
                <button
                  data-cy="show-filters-button"
                  type="button"
                  className={`relative border ${
                    team === 'all' ? 'border-gray-300' : 'border-gray-900'
                  }  text-gray-700 text-md leading-md flex items-center px-4 py-2.5 rounded-lg shadow-xs justify-center w-full md:w-fit`}
                  onClick={() => setShowFiltersModal(true)}
                >
                  <AdjustmentsHorizontalIcon className="w-5 h-5 mr-2" />
                  Filters
                  {team !== 'all' && (
                    <span className="absolute bg-gray-900 h-5 w-5 text-white rounded-full -top-2 -right-2 flex items-center justify-center text-xs font-semibold">
                      1
                    </span>
                  )}
                </button>
                {team !== 'all' && (
                  <div className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center">
                    {teams.find((t) => t.value === team)?.label}
                    <button
                      type="button"
                      aria-label="remove team filter"
                      className="text-gray-500"
                      onClick={() => {
                        onFilterChange('teams', { value: 'all', label: 'All' });
                        setSearchParams({});
                        setTeam('all');
                      }}
                    >
                      <XMarkIcon className="w-4 h-4 ml-2" />
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="mt-3 md:mt-12 mb-10">
          {rows && rows.length > 0 && <Table<Customer> columnVisibility={columnVisibility} data={rows} columns={columns1} />}
        </div>
      </div>

      {showFiltersModal && user?.permissions?.admin && (
        <Filters filters={filters} onFilterChange={onFilterChange} onCloseModal={() => setShowFiltersModal(false)} onSaveFilters={onSaveFilters} />
      )}
    </>
  );
};

export default WellbeingBoard;
