import React from 'react';

interface LocationBarProps {
  section: string;
  page: string;
  Icon: React.ElementType;
}

const LocationBar = ({ section, page, Icon }: LocationBarProps) => {
  return (
    <div className="bg-primary-700 px-4 md:px-[5%] py-1.5 flex items-center gap-1">
      <div className="text-md leading-md text-white font-semibold">{section}:&nbsp;</div>
      <Icon className="text-white" />
      <div className="text-md leading-md text-white font-semibold">&nbsp;{page}</div>
    </div>
  );
};

export default LocationBar;
