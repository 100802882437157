import { ElderlyOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { Loading, Message, LocationBar } from 'components';
import Helmet from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { SupportWorker, Maybe } from '__generated__/graphql';
import { useGetSupportWorkers, useSaveSupportWorkerProfile } from 'api/hooks/useSupportWorkers';
import { useForm, FormProvider } from 'react-hook-form';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { QuillField } from 'components/Fields/QuillField';
import { TextListField } from 'components/Fields/TextListField';
import { FormPrompt } from 'components/modals/FormPromptModal';

const TeamMemberUpdate = () => {
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const { teamMemberId } = useParams();
  const { supportWorkers, loading } = useGetSupportWorkers({ showAll: true });
  const { saveSupportWorkerProfile, mutationSaveSupportWorkerProfile } = useSaveSupportWorkerProfile();

  const supportWorker = supportWorkers.filter((c: SupportWorker) => c.id === teamMemberId)[0];
  const methods = useForm<{
    profileData: {
      matters: string;
      support: string;
      aboutMe: Maybe<string>[] | { value: Maybe<string> }[];
    };
  }>({
    mode: 'onChange',
  });

  const { handleSubmit, formState, reset } = methods;

  useEffect(() => {
    reset(
      {
        profileData: {
          matters: supportWorker?.profileData?.matters ? supportWorker?.profileData?.matters.replace(/<br\s*\/?>/g, '\r\n') : '',
          support: supportWorker?.profileData?.support ? supportWorker?.profileData?.support.replace(/<br\s*\/?>/g, '\r\n') : '',
          aboutMe: supportWorker?.profileData?.aboutMe ? supportWorker?.profileData?.aboutMe : [],
        },
      },
      { keepDirty: true },
    );
  }, [reset, supportWorker?.profileData?.aboutMe, supportWorker?.profileData?.support, supportWorker?.profileData?.matters]);

  const navigate = useNavigate();

  const onSubmit = async (data: {
    profileData: {
      matters: string;
      support: string;
      aboutMe: Maybe<string>[] | { value: Maybe<string> }[];
    };
  }) => {
    const map = data.profileData.aboutMe?.map((am) => {
      if (typeof am === 'string') {
        return am;
      } else {
        return am?.value;
      }
    });
    await saveSupportWorkerProfile({
      awaitRefetchQueries: true,

      variables: {
        input: {
          id: teamMemberId,
          profileData: {
            aboutMe: map || [],
            matters: data.profileData?.matters?.replace(/\n\r?/g, '<br />'),
            support: data.profileData?.support?.replace(/\n\r?/g, '<br />'),
          },
        },
      },
    });

    reset({}, { keepValues: true });
    setOpenSnack(true);
    navigate(`/team-members/${teamMemberId}`);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Team Member</title>
      </Helmet>
      <LocationBar section="People" page="Team Member Update" Icon={ElderlyOutlined} />
      <Message response={[mutationSaveSupportWorkerProfile]} openSnack={openSnack} setOpenSnack={setOpenSnack} />
      <div className="my-10 px-4 md:px-[5%]">
        <button type="button" className="text-gray-500 font-semibold text-md leading-md flex items-center" onClick={() => navigate('/team-members/')}>
          <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
        </button>

        <div className="mt-10 sm:mt-16">
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="my-10 px-4 md:px-[5%]">
                <div className="flex items-center  py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  <div className="ps-3">
                    <img className="w-20 h-20 rounded-full" src={supportWorker?.avatar} alt={`Team member ${supportWorker?.fullName}`} />
                    <div className="text-black text-xl sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1">{supportWorker?.fullName}</div>
                  </div>
                </div>
                <div className="mt-8 sm:mt-12 w-full">
                  <TextListField label="What people like and admire about me" name="profileData.aboutMe" />

                  <QuillField label="What matters to me" name="profileData.matters" />
                  <QuillField label="How best to support me" name="profileData.support" />
                </div>
                <button type="submit" className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
                  Save
                </button>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
      <FormPrompt hasUnsavedChanges={formState.isDirty} />
    </>
  );
};

export default TeamMemberUpdate;
