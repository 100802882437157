import { useState, useMemo, useRef, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Helmet from 'react-helmet';
import { format, isValid } from 'date-fns';
import { AdjustmentsHorizontalIcon, XMarkIcon, EyeIcon } from '@heroicons/react/24/outline';
import { LocationBar, Loading, Search, Table, Filters } from 'components';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { SelectOption } from 'types/types';
import { SupportWorker } from '__generated__/graphql';
import { GroupOutlined } from '@mui/icons-material';
import { useGetSupportWorkerRoleOptions, useGetSupportWorkers } from 'api/hooks/useSupportWorkers';
import { useUser, UserContextProps } from 'context/userContext';
import { useGeneric, GenericContextProps } from 'context/genericContext';

const TeamMembersList = () => {
  const { user, userLoading } = useUser() as UserContextProps;
  const { teams: genericTeam, teamsLoading } = useGeneric() as GenericContextProps;
  const { suportWorkerRoles, supportWorkerRolesLoading } = useGetSupportWorkerRoleOptions();
  const supportWorkerRoleOptions = suportWorkerRoles.map((sw) => {
    return { value: sw.id, label: sw.name };
  });
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [team, setTeam] = useState(user?.teamId);
  const [role, setRole] = useState('all');
  const teams = useMemo(() => genericTeam?.map((t) => ({ value: t.teamId, label: t.teamName })) || [], [genericTeam]);
  const tableRef = useRef(null);

  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
  const [filters, setFilters] = useState([
    {
      name: 'teams',
      selectedValue: user?.teamName ? { value: user?.teamId, label: user.teamName } : { value: 'all', label: 'All' },
      values: [{ value: 'all', label: 'All' }, ...(teams || [])],
    },
    {
      name: 'role',
      selectedValue: { value: 'all', label: 'All' },
      values: [{ value: 'all', label: 'All' }, ...supportWorkerRoleOptions],
    },
  ]);

  useEffect(() => {
    const newFilters = filters;
    newFilters[0].values = [{ value: 'all', label: 'All' }, ...teams];
    newFilters[1].values = [{ value: 'all', label: 'All' }, ...supportWorkerRoleOptions];
    setFilters(newFilters);
  }, [filters, supportWorkerRoleOptions, teams]);

  const { supportWorkers, loading } = useGetSupportWorkers({ showAll: false });

  const filterByTeam = (sw: SupportWorker) => {
    if (team === 'all') return true;
    if (team && sw.teamId === team) return true;
    return false;
  };
  const filterByRole = (sw: SupportWorker) => {
    if (role === 'all') return true;
    if (role && sw.roleId === role) return true;
    return false;
  };
  const filterByName = (sw: SupportWorker) => {
    if (sw.fullName.toLowerCase().includes(search.toLowerCase())) return true;
    return false;
  };
  const filteredTeamMembers = () => {
    return supportWorkers
      ?.filter((sw) => filterByTeam(sw))
      .filter((sw) => filterByName(sw))
      .filter((sw) => filterByRole(sw))
      .sort((x, y) => (y.dateUpdated || 0) - (x.dateUpdated || 0));
  };

  const onButtonClick = useCallback(
    (sw: SupportWorker, link?: string) => {
      navigate(link ? `/team-members/${sw.id}/${link}` : `/team-members/${sw.id}`);
    },
    [navigate],
  );

  const RowActions = useCallback(
    ({ row }: { row: SupportWorker }) => {
      return (
        <div className="flex flex-col sm:flex-row items-center gap-5">
          <button type="button" onClick={() => onButtonClick(row)} aria-label="Edit" className="m-0 p-0">
            <EyeIcon className="w-6 h-6 text-gray-500 hover:text-primary600" />
          </button>
        </div>
      );
    },
    [onButtonClick],
  );

  const onFilterChange = (name: string, value: SelectOption) => {
    const newFilters = [...filters];
    const filterIndex = newFilters.findIndex((f) => f.name === name);
    newFilters[filterIndex].selectedValue = value;
    setFilters(newFilters);
  };

  const columnVisibility = {
    avatar: true,
    position: window.innerWidth > 1024,
    lastLoggedInDate: window.innerWidth > 768,
    actions: true,
  };

  const columnHelper = createColumnHelper<SupportWorker>();

  const columns1 = useMemo(() => {
    return [
      columnHelper.accessor('avatar', {
        header: () => 'Name',
        cell: (info) => (
          <div className="flex items-center font-medium text-gray-900 whitespace-nowrap dark:text-white">
            <img className="w-12 h-12 rounded-full" src={info.row.original.avatar || '/defaultProfile.jpg'} alt={`Link worker ${info.row.original.fullName}`} />
            <div className="ps-3">
              <div className="text-md leading-md font-semibold">{info.row.original.fullName}</div>
              <div className="text-sm leading-sm font-normal text-gray-500 hidden sm:block">{info.row.original.email}</div>
            </div>
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('position', {
        header: () => 'Position',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('role', {
        header: () => 'Role',
        cell: (info) => info.row.original.role?.name,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('lastLoggedInDate', {
        header: () => 'Last Login',
        cell: (info) => {
          return <span>{isValid(info.renderValue()) ? format(info.renderValue() || 0, 'dd/MM/yy') : 'Never logged in'}</span>;
        },
        footer: (info) => info.column.id,
      }),

      columnHelper.display({
        id: 'actions',
        cell: (props) => <RowActions row={props.row.original} />,
      }),
    ] as Array<ColumnDef<SupportWorker, unknown>>;
  }, [columnHelper, RowActions]);

  const onSaveFilters = () => {
    setTeam(filters[0].selectedValue.value);
    setRole(filters[1].selectedValue.value);

    setShowFiltersModal(false);
  };

  if (loading || userLoading || teamsLoading || supportWorkerRolesLoading) {
    return <Loading />;
  }

  const rows = filteredTeamMembers().map((c) => {
    return {
      ...c,
    };
  });

  return (
    <div>
      <Helmet>
        <title>Team Members</title>
      </Helmet>
      <LocationBar section="People" page="Team Member List" Icon={GroupOutlined} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-display-sm leading-display-sm text-black font-medium">Find out more about your colleagues with their 1-page profiles</div>
          <button
            type="button"
            className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md mt-4 md:mt-0"
            onClick={() => navigate(`/team-members/${user?.profile}/update`)}
          >
            Update your profile
          </button>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-between mt-8 md:mt-16">
          <div className="flex flex-col md:flex-row items-center gap-4 md:gap-9 w-full md:w-fit">
            <Search value={search} onSearch={setSearch} />
            <div className="flex flex-col md:flex-row items-center gap-3 w-full md:w-fit">
              <button
                type="button"
                className={`relative border ${
                  team === 'all' ? 'border-gray-300' : 'border-gray-900'
                }  text-gray-700 text-md leading-md flex items-center px-4 py-2.5 rounded-lg shadow-xs justify-center w-full md:w-fit`}
                onClick={() => setShowFiltersModal(true)}
              >
                <AdjustmentsHorizontalIcon className="w-5 h-5 mr-2" />
                Filters
                {team !== 'all' ||
                  (role !== 'all' && (
                    <span className="absolute bg-gray-900 h-5 w-5 text-white rounded-full -top-2 -right-2 flex items-center justify-center text-xs font-semibold">
                      {team !== 'all' && role !== 'all' ? '2' : '1'}
                    </span>
                  ))}
              </button>
              {team !== 'all' && (
                <div className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center">
                  {teams?.find((t) => t.value === team)?.label}
                  <button
                    type="button"
                    aria-label="remove team filter"
                    className="text-gray-500"
                    onClick={() => {
                      onFilterChange('teams', { value: 'all', label: 'All' });
                      setTeam('all');
                    }}
                  >
                    <XMarkIcon className="w-4 h-4 ml-2" />
                  </button>
                </div>
              )}
              {role !== 'all' && (
                <div className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center">
                  {supportWorkerRoleOptions.find((t) => t.value === role)?.label}
                  <button
                    type="button"
                    aria-label="remove role filter"
                    className="text-gray-500"
                    onClick={() => {
                      onFilterChange('role', { value: 'all', label: 'All' });
                      setRole('all');
                    }}
                  >
                    <XMarkIcon className="w-4 h-4 ml-2" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="mt-3 md:mt-12 mb-10" ref={tableRef}>
          {rows && rows.length > 0 && <Table<SupportWorker> data={rows} columns={columns1} columnVisibility={columnVisibility} />}
        </div>
      </div>

      {showFiltersModal && (
        <Filters filters={filters} onFilterChange={onFilterChange} onCloseModal={() => setShowFiltersModal(false)} onSaveFilters={onSaveFilters} />
      )}
    </div>
  );
};

export default TeamMembersList;
