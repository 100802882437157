import { ReportingHoursPeriod, Rota, Team, ReportingHours, SupportWorker } from '__generated__/graphql';
import { User } from 'types/types';
import { formatHoursAndMinutes } from 'services/helpers';
import { ClockIcon } from '@heroicons/react/24/outline';
import InfoIcon from '@mui/icons-material/Info';
import { groupByKey } from 'services/arrays';
import { ShiftRunTypeEnum, VisitStatus } from './service';
import RotaList from './RotaList';

interface ProfileProps {
  rotas: Rota[];
  reportingHoursPeriod?: ReportingHoursPeriod | null;
  title?: string;
  showRotas?: boolean;
  user?: User;
  teams: Team[];
  reportingHours: ReportingHours[];
  supportWorker: SupportWorker;
}

const formatNumberOfFixedShifts = (reportingHours: ReportingHours[]) => {
  const shifts = reportingHours
    .filter((sh) => sh?.visit?.status === VisitStatus.active)
    .map((rh) => rh.shift)
    .filter((sh) => sh?.type === ShiftRunTypeEnum.agreed);
  const grouped = groupByKey(shifts, 'id');
  return Object.keys(grouped).length;
};

const ReportingHoursProfile = ({ user, supportWorker, teams, rotas, reportingHoursPeriod, reportingHours, showRotas, title }: ProfileProps) => {
  const milesTravelled = reportingHoursPeriod?.mileage || 0;
  const milesTravelledInCar = reportingHoursPeriod?.mileageWithCustomer || 0;
  const bankedHours = reportingHoursPeriod?.bankedHours || 0;
  const activitiesTotal = reportingHoursPeriod?.timeOnActivities || 0;
  const bankedHoursOnVisitsTotal = reportingHoursPeriod?.bankedHoursAssignedOnVisits || 0;
  const otherMileage = reportingHoursPeriod?.mileageOther || 0;
  const hoursInVisitsTotal = reportingHoursPeriod?.timeOnVisits || 0;

  const milesTravelledCost = milesTravelled * 0.45;
  const milesTravelledInCarCost = milesTravelledInCar * 0.45;
  const otherMileageCost = otherMileage * 0.45;

  const hoursInVisits = formatHoursAndMinutes(hoursInVisitsTotal);
  const bankedHoursAndMinutes = formatHoursAndMinutes(bankedHours);
  const activitiesHoursAndMinutes = formatHoursAndMinutes(activitiesTotal);
  const remainingBankedHours = bankedHours - bankedHoursOnVisitsTotal;
  const overtime = remainingBankedHours < 0 ? Math.abs(remainingBankedHours) : 0;
  const bankedHoursMinutesOnVisitsTotal = formatHoursAndMinutes(bankedHoursOnVisitsTotal);
  const remainingBankedHoursAndMinutes = remainingBankedHours > 0 ? formatHoursAndMinutes(remainingBankedHours) : formatHoursAndMinutes(0);
  const numberOfFixedShifts = formatNumberOfFixedShifts(reportingHours);
  const remainingHours = Math.abs(supportWorker?.contractedHours || 0) * 4 - (numberOfFixedShifts * 5 - 6);
  const contractedHoursForMonth = parseFloat(supportWorker?.contractedHours.toString()) * 4.345;
  const overtimeHoursMinutes = formatHoursAndMinutes(overtime);
  const contractedHoursForMonthPlusOvertime = contractedHoursForMonth + overtime;
  const magicNumberForcast = hoursInVisitsTotal / contractedHoursForMonthPlusOvertime / 60;

  return (
    <>
      <div className="text-display-sm md:text-display-md leading-display-sm md:leading-display-md md:tracking-display-md text-gray-800 text-center font-semibold">
        {title}
      </div>
      {showRotas && (
        <div className="mt-5">
          <div className="flex items-center gap-2 mb-2 text-lg leading-lg font-semibold text-primary-700">
            <ClockIcon className="w-6 h-6 text-primary-700" />
            Rotas
          </div>
          <RotaList teams={teams} rotas={rotas} />
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-2 border border-gray-100 divide-gray-100 md:border-none mt-5">
        <div className="grid grid-cols-1 md:border border-gray-100 divide-y divide-gray-100">
          <div className="grid grid-cols-2 divide-x divide-gray-100 text-md leading-md text-gray-800">
            <div className="px-5 py-4 font-semibold">Team</div>
            <div className="px-5 py-4">{reportingHoursPeriod?.teamName}</div>
          </div>
          <div className="grid grid-cols-2 divide-x divide-gray-100 text-md leading-md text-gray-800 bg-gray-50">
            <div className="px-5 py-4 font-semibold">Employment Status</div>
            <div className="px-5 py-4">{reportingHoursPeriod?.employmentStatus}</div>
          </div>
          <div className="grid grid-cols-2 divide-x divide-gray-100 text-md leading-md text-gray-800">
            <div className="px-5 py-4 font-semibold">Team meetings</div>
            <div className="px-5 py-4">{reportingHoursPeriod?.meetings}</div>
          </div>
          <div className="grid grid-cols-2 divide-x divide-gray-100 text-md leading-md text-gray-800 bg-gray-50">
            <div className="px-5 py-4 font-semibold">Sick leave</div>
            <div className="px-5 py-4">{reportingHoursPeriod?.sickLeave}</div>
          </div>
          <div className="grid grid-cols-2 divide-x divide-gray-100 text-md leading-md text-gray-800">
            <div className="px-5 py-4 font-semibold">Miles between visits</div>
            <div className="px-5 py-4">
              {milesTravelled.toFixed(2)} miles = £{milesTravelledCost.toFixed(2)}
            </div>
          </div>
          <div className="grid grid-cols-2 divide-x divide-gray-100 text-md leading-md text-gray-800 bg-gray-50">
            <div className="px-5 py-4 font-semibold">Miles with supported people</div>
            <div className="px-5 py-4">
              {milesTravelledInCar.toFixed(2)} miles = £{milesTravelledInCarCost.toFixed(2)}
            </div>
          </div>
          <div className="grid grid-cols-2 divide-x divide-gray-100 text-md leading-md text-gray-800">
            <div className="px-5 py-4 font-semibold">Mileage (Other)</div>
            <div className="px-5 py-4">
              {otherMileage.toFixed(2)} miles = £{otherMileageCost.toFixed(2)}
            </div>
          </div>
          {supportWorker?.contractedHours !== undefined && supportWorker?.contractedHours > 0 && (
            <div className="grid grid-cols-2 divide-x divide-gray-100 text-md leading-md text-gray-800">
              <div className="px-5 py-4 font-semibold">Fixed Shifts</div>
              <div className="px-5 py-4">
                Contracted Hours: {Math.abs(supportWorker?.contractedHours || 0) * 4}
                <br /> Number Used Fixed Shifts: {numberOfFixedShifts}
                <br /> Number Used Hours: {numberOfFixedShifts * 5 - 6}
                <br /> Remaining Hours: {remainingHours}
                <br /> Remaining Shifts: {Math.floor(remainingHours / 5)}
              </div>
            </div>
          )}
        </div>
        <div className="grid grid-cols-1 md:border border-gray-100 divide-y divide-gray-100 h-fit">
          <div className="grid grid-cols-2 divide-x divide-gray-100 text-md leading-md text-gray-800 bg-gray-50">
            <div className="px-5 py-4 font-semibold">
              Time on Visits
              <div className="font-normal text-sm">The total amount of time spent on visits across all shifts</div>
            </div>
            <div className="px-5 py-4">
              {hoursInVisits.hours} hrs {hoursInVisits.minutes} mins
            </div>
          </div>
          <div className="grid grid-cols-2 divide-x divide-gray-100 text-md leading-md text-gray-800">
            <div className="px-5 py-4 font-semibold">
              Banked hours this payroll
              <div className="font-normal text-sm">The total number of banked hours accumulated across all Agreed shifts</div>
            </div>
            <div className="px-5 py-4">
              {bankedHoursAndMinutes.hours} hrs {bankedHoursAndMinutes.minutes} mins
            </div>
          </div>
          <div className="grid grid-cols-2 divide-x divide-gray-100 text-md leading-md text-gray-800 bg-gray-50">
            <div className="px-5 py-4 font-semibold">
              Time on activities
              <div className="font-normal text-sm">The total amount of time spent on activities across all shifts</div>
            </div>
            <div className="px-5 py-4">
              {activitiesHoursAndMinutes.hours} hrs {activitiesHoursAndMinutes.minutes} mins
            </div>
          </div>
          <div className="grid grid-cols-2 divide-x divide-gray-100 text-md leading-md text-gray-800">
            <div className="px-5 py-4 font-semibold">
              Banked hours consumed on visits and activities
              <div className="font-normal text-sm">The total amount of time spent on one off visits and activities not on agreed shifts</div>
            </div>
            <div className="px-5 py-4">
              {bankedHoursMinutesOnVisitsTotal.hours} hrs {bankedHoursMinutesOnVisitsTotal.minutes} mins
            </div>
          </div>
          <div className="grid grid-cols-2 divide-x divide-gray-100 text-md leading-md text-gray-800  bg-gray-50">
            <div className="px-5 py-4 font-semibold">
              Total Overtime Hours
              <div className="font-normal text-sm">The total amount of overtime to be paid</div>
            </div>
            <div className="px-5 py-4">
              {overtimeHoursMinutes.hours} hrs {overtimeHoursMinutes.minutes} mins
            </div>
          </div>
          {supportWorker?.contractedHours > 0 && (
            <div className="grid grid-cols-2 divide-x divide-gray-100 text-md leading-md text-gray-800">
              <div className="px-5 py-4 font-semibold">
                Forecasted Magic Number
                <div className="font-normal text-sm">
                  This is the magic number you are predicted to reach this month, based on your contracted hours and the visits in this timesheet
                </div>
              </div>
              <div className="px-5 py-4">
                {/* Time on visits: {hoursInVisits.hours} hrs {hoursInVisits.minutes} mins <br />
                Weekly contracted hours: {supportWorker?.contractedHours} <br />
                {supportWorker?.contractedHours} x 4.345 (weeks per month): {contractedHoursForMonth} <br />+ {overtime} (overtime hours) <br />
                {hoursInVisitsTotal / 60} / {contractedHoursForMonth}:  */}
                {(magicNumberForcast * 100).toFixed(2)}%
              </div>
            </div>
          )}
          <div className="grid grid-cols-2 divide-x divide-gray-100 text-md leading-md text-gray-800 bg-yellow-50">
            <div className="px-5 py-4 font-semibold">
              Outstanding banked hours
              <div className="font-normal text-sm">The total amount of banked hours outstanding and should be made up</div>
            </div>
            <div className="px-5 py-4">
              {remainingBankedHoursAndMinutes.hours} hrs {remainingBankedHoursAndMinutes.minutes} mins
            </div>
          </div>
          <div className="grid grid-cols-2 divide-x divide-gray-100 text-md leading-md text-gray-800 bg-yellow-50">
            <div className="px-5 py-4 font-semibold">
              Annual leave hours
              <div className="font-normal text-sm">The total amount of annual leave</div>
            </div>
            <div className="px-5 py-4">{reportingHoursPeriod?.annualLeave}</div>
          </div>
          <div className="grid grid-cols-2 divide-x divide-gray-100 text-md leading-md text-gray-800">
            <div className="px-5 py-4 font-semibold">&nbsp;</div>
            <div className="px-5 py-4">
              {user?.permissions?.developer && (
                <InfoIcon
                  sx={{ cursor: 'pointer', float: 'right' }}
                  color="info"
                  onClick={() => navigator?.clipboard?.writeText(JSON.stringify(reportingHoursPeriod))}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportingHoursProfile;
