import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import ArticleIcon from '@mui/icons-material/Article';
import { useGetWorkshop, useSaveWorkshop, useSendWorkshopEmails } from 'api/hooks/useWorkshop';
import { useGetCandidates, useSaveCandidate } from 'api/hooks/useCandidates';
import { useGetTemplates } from 'api/hooks/useTemplates';
import { useGetSupportWorkers } from 'api/hooks/useSupportWorkers';
import { ArrowLeftIcon, ClockIcon, EnvelopeIcon, PlusIcon } from '@heroicons/react/24/outline';
import { Candidate } from '__generated__/graphql';
import { format, isAfter } from 'date-fns';
import { LocationBar, Loading, Message } from 'components';
import { SelectOption } from 'types/types';
import { WorkshopStatus } from './types';
import WorkshopCandiateList from './WorkshopCandidateList';
import WorkshopCandidateUpdate from './components/WorkshopCandidateUpdate';
import Status from './components/Status';
import WorkshopInvites from './components/WorkshopInvites';
import AddCandidates from './components/AddCandidates';

export default function WorkshopDetail() {
  const navigate = useNavigate();
  const { workshopId } = useParams<{ workshopId?: string }>();
  const { saveWorkshop, mutationSaveWorkshop } = useSaveWorkshop(workshopId || '');
  const { workshop, workshopLoading } = useGetWorkshop({ id: workshopId });

  const { candidates, candidateLoading } = useGetCandidates({});
  const { templates, templatesLoading } = useGetTemplates();
  const { supportWorkers, loading } = useGetSupportWorkers({});
  const { sendWorkshopEmails, mutationSendWorkshopEmails } = useSendWorkshopEmails(workshopId || '');
  const [date, setDate] = useState<number>(Date.now());
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const [openCandidateUpdateModal, setOpenCandidateUpdateModal] = useState<boolean>(false);
  const [openSendInviteModal, setOpenSendInviteModal] = useState<boolean>(false);
  const [openEditCandidatesModal, setOpenEditCandidatesModal] = useState<boolean>(false);
  const [selectedCandidate, setSelectedCandidate] = useState<Candidate>();
  const { saveCandidate, mutationSaveCandidate } = useSaveCandidate({ id: selectedCandidate?.id });

  useEffect(() => {
    setDate(workshop?.date || Date.now());
  }, [workshop]);

  const onSaveCandidate = async (update: Candidate) => {
    setOpenSnack(true);
    await saveCandidate({
      variables: {
        input: {
          ...update,
          teamId: selectedCandidate?.teamId,
        },
      },
    });
    setOpenCandidateUpdateModal(false);
    setTimeout(() => {
      setOpenSnack(false);
    }, 2000);
  };

  const onClose = () => {
    navigate('/workshops');
  };

  const onSendEmails = (subject: string, body: string, templateId: string, recipients: string[]) => {
    setOpenSnack(true);
    sendWorkshopEmails({
      variables: {
        input: {
          id: workshopId,
          subject,
          body,
          templateId,
          candidates: recipients,
        },
      },
    });
    setTimeout(() => {
      setOpenSnack(false);
    }, 2000);
    setOpenSendInviteModal(false);
  };

  const onAdd = (newCandidates: SelectOption[]) => {
    setOpenSnack(true);
    const selectedCadidates = newCandidates.map((c) => c.value);
    saveWorkshop({
      variables: {
        input: {
          id: workshopId,
          date: workshop?.date,
          subject: workshop?.subject,
          body: workshop?.body,
          templateId: workshop?.templateId,
          candidates: selectedCadidates,
        },
      },
    });
    setTimeout(() => {
      setOpenSnack(false);
    }, 2000);
    setOpenEditCandidatesModal(false);
  };

  const onSelectAndUpdateCandidate = (c: Candidate) => {
    setSelectedCandidate(c);
    setOpenCandidateUpdateModal(true);
  };

  if (loading || workshopLoading || candidateLoading || templatesLoading || mutationSaveWorkshop.loading || mutationSendWorkshopEmails.loading) {
    return <Loading />;
  }

  const assignedCandidates = candidates.filter((s) => workshop?.candidates?.some((r) => r === s.id));
  const filteredRecipients = assignedCandidates.map((c) => ({ value: c.id || '', label: `${c.firstName} ${c.lastName}` || '' }));
  const filteredCandidates = candidates.map((c) => ({ value: c.id || '', label: `${c.firstName} ${c.lastName}` || '' }));

  return (
    <>
      <Helmet>
        <title>Workshop Detail</title>
      </Helmet>
      <LocationBar section="Workshops" page="Workshop Detail" Icon={ArticleIcon} />
      <Message response={[mutationSendWorkshopEmails, mutationSaveWorkshop, mutationSaveCandidate]} openSnack={openSnack} setOpenSnack={setOpenSnack} />
      {selectedCandidate && openCandidateUpdateModal && (
        <WorkshopCandidateUpdate
          supportWorkers={supportWorkers}
          onClose={setOpenCandidateUpdateModal}
          onSaveCandidate={onSaveCandidate}
          candidate={selectedCandidate}
        />
      )}
      {openSendInviteModal && (
        <WorkshopInvites
          recipients={filteredRecipients}
          assignedCandidates={assignedCandidates}
          templates={templates}
          workshop={workshop ?? {}}
          onClose={() => setOpenSendInviteModal(false)}
          onSend={onSendEmails}
        />
      )}
      {openEditCandidatesModal && (
        <AddCandidates candidates={filteredCandidates} addedCandidates={filteredRecipients} onClose={() => setOpenEditCandidatesModal(false)} onAdd={onAdd} />
      )}
      <div className="my-10 px-4 md:px-[5%]">
        <button type="button" className="text-gray-500 font-semibold text-md leading-md flex items-center" onClick={onClose}>
          <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
        </button>
        <div className="flex items-center justify-between mt-14">
          <div className="flex items-center gap-6">
            <div className="bg-[#DBEAF0] rounded-xl p-1.5">
              <div className="bg-gray-500 rounded-md text-white px-7 py-2">
                <div className="text-xl leading-xl font-medium text-center">{format(date, 'ccc')}</div>
                <div className="text-display-sm font-semibold text-center mt-1">{format(date, 'dd')}</div>
                <div className="text-xl leading-xl font-medium text-center mt-1">{format(date, 'MMM')}</div>
              </div>
            </div>
            <div className="hidden md:block">
              <Status status={isAfter(new Date(), date) ? WorkshopStatus.Complete : WorkshopStatus.Scheduled} />
              <div className="text-black text-display-sm leading-display-sm font-semibold mt-3">{format(date, 'cccc do MMMM')}</div>
              <div className="text-gray-700 text-md leading-md mt-1 flex items-center">
                <ClockIcon className="w-6 h-6 text-gray-400 mr-1" />
                {format(date, 'kk:mm a')}
              </div>
            </div>
          </div>
          <div className="flex items-center gap-3 md:gap-5">
            <button
              type="button"
              data-cy="workshop-send-invite-button"
              className="relative border border-gray-300 text-gray-700 text-md leading-md font-semibold flex items-center p-2 md:px-5 md:py-2.5 rounded-lg shadow-xs justify-center w-full md:w-fit"
              onClick={() => setOpenSendInviteModal(true)}
            >
              <EnvelopeIcon className="w-5 h-5 md:mr-2" />
              <div className="hidden md:block">Send invite</div>
            </button>
            <button
              type="button"
              data-cy="workshop-add-candidate-button"
              className="text-white bg-primary-700 rounded-lg p-2 md:px-5 md:py-2.5 font-semibold text-md leading-md flex items-center"
              onClick={() => setOpenEditCandidatesModal(true)}
            >
              <PlusIcon className="w-5 h-5 md:mr-2" /> <div className="hidden md:block">Candidate</div>
            </button>
          </div>
        </div>
        <div className="md:hidden mt-6">
          <div className="text-black text-xl md:text-display-sm leading-xl md:leading-display-sm font-semibold">{format(date, 'cccc do MMMM')}</div>

          <div className="flex justify-between items-end">
            <div className="text-gray-700 text-md leading-md mt-1 flex items-center">
              <ClockIcon className="w-6 h-6 text-gray-400 mr-1" />
              {format(date, 'kk:mm a')}
            </div>
            <Status status={isAfter(new Date(), date) ? WorkshopStatus.Complete : WorkshopStatus.Scheduled} />
          </div>
        </div>
        <div className="mt-5 md:mt-14">
          <WorkshopCandiateList
            onSaveCandidate={onSaveCandidate}
            supportWorkers={supportWorkers}
            candidates={assignedCandidates || []}
            onSelectAndUpdateCandidate={onSelectAndUpdateCandidate}
          />
        </div>
      </div>
    </>
  );
}
