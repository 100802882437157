import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { SaveCandidateInput } from '__generated__/graphql';
import CandidateHistoryList from './CandidateHistoryList';
import AddMessage from './components/AddMessage';

interface CandidateHistoryProps {
  onSubmit: (data: SaveCandidateInput) => void;
}
export default function CandidateHistory({ onSubmit }: CandidateHistoryProps) {
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const { control, handleSubmit } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'history',
  });
  const onCandidateRemove = (index: number) => {
    remove(index);
    handleSubmit(onSubmit)();
  };

  const onCandidateHistoryUpdate = (contactType: string, from: string, message: string) => {
    append({ id: uuidv4(), date: Date.now(), contactType, message, from });
    handleSubmit(onSubmit)();
    setShowAddModal(false);
  };

  return (
    <>
      <div className="mt-8 sm:mt-12">
        <button
          type="button"
          className="border border-gray-300 rounded-lg py-2 px-3.5 shadow-xs text-gray-700 text-sm leading-sm font-semibold flex items-center mb-7"
          onClick={() => setShowAddModal(true)}
        >
          <PlusIcon className="w-5 h-5 mr-2" /> Message
        </button>
        <CandidateHistoryList history={fields} onCandidateRemove={onCandidateRemove} />
      </div>
      {showAddModal && <AddMessage onCloseModal={() => setShowAddModal(false)} onAdd={onCandidateHistoryUpdate} />}
    </>
  );
}
