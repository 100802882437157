import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

interface MessageProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response: Array<any>;
  openSnack: boolean;
  setOpenSnack: (value: boolean) => void;
}

const Message = ({ response, openSnack, setOpenSnack }: MessageProps) => {
  let message = null;
  let error = 'Woops, unable to perform action';

  const messages = response
    ?.filter((m) => m)
    .filter((m) => m?.data)
    .map((x) => {
      const key = Object.keys(x.data)[0];
      return { message: x.data[key].message, date: x.data[key].date };
    })
    .sort((a, b) => b.date - a.date);

  if (messages.length > 0) {
    message = messages[0].message;
  }

  const errors = response?.find((m) => m?.error);
  if (!message || errors?.length === 0) return null;
  // eslint-disable-next-line dot-notation, @typescript-eslint/no-explicit-any
  error = errors ? errors?.error?.graphQLErrors?.find((x: any) => x['errorType'])?.message : 'Unable to complete request';
  error = error || 'Unable to complete request';

  return (
    <Snackbar
      autoHideDuration={4000}
      sx={{ padding: '0', width: { xs: '96%', sm: '40%' }, height: { xs: '4em', sm: '3.6em' } }}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={openSnack}
      onClose={() => setOpenSnack(false)}
    >
      {message ? (
        <Alert
          data-cy="message-motification"
          sx={{ fontSize: '1em', height: { xs: '3.6em', sm: '3.6em' }, boxShadow: '3', minWidth: '86%' }}
          onClose={() => setOpenSnack(false)}
          severity="success"
        >
          {message}
        </Alert>
      ) : (
        <Alert sx={{ boxShadow: '3', minWidth: '86%' }} onClose={() => setOpenSnack(false)} severity="error">
          {error}
        </Alert>
      )}
    </Snackbar>
  );
};

export default Message;
