import { getHoursAndMinutes, msToTime } from 'services/helpers';
import { AgreedVisitType } from '__generated__/graphql';

interface VisitProps {
  visit: AgreedVisitType;
  startDateTime: number;
  index: number;
  day: number;
}

const Visit = ({ visit, startDateTime, index, day }: VisitProps) => {
  const runStartDate = new Date();
  const visitStartDate = new Date();
  const visitEndDate = new Date();

  runStartDate.setHours(new Date(startDateTime).getHours());
  if (visit.startDateTime) {
    visitStartDate.setHours(new Date(visit.startDateTime).getHours());
    visitStartDate.setMinutes(new Date(visit.startDateTime).getMinutes());
  }
  if (visit.endDateTime) {
    visitEndDate.setHours(new Date(visit.endDateTime).getHours());
    visitEndDate.setMinutes(new Date(visit.endDateTime).getMinutes());
  }
  runStartDate.setMinutes(new Date(startDateTime).getMinutes());
  const startTimeDifference = Math.abs(visitStartDate.getTime() - runStartDate.getTime());
  const visitDuration = Math.abs(visitEndDate.getTime() - visitStartDate.getTime());
  const { hours: startHours, minutes: startMinutes } = msToTime(startTimeDifference);
  const { hours: endHours, minutes: endMinutes } = msToTime(visitDuration);

  const visitPosition = (hours: number, minutes: number) => {
    return hours * 60 + minutes;
  };

  return (
    <div
      style={{ top: visitPosition(startHours, startMinutes), height: visitPosition(endHours, endMinutes) - 2 }}
      className="absolute left-0 right-0 bg-gray-300 overflow-hidden z-10 px-2 py-1 cursor-pointer"
      key={index}
      data-cy={`shift${day}-visit${index}-edit-visit-button`}
    >
      <div className="text-md leading-md text-white font-medium" data-cy={`shift${day}-visit${index}-customer`}>
        {visit?.customer?.firstName} {visit?.customer?.lastName}
      </div>
      <div className="text-sm leading-sm text-white" data-cy={`shift${day}-visit${index}-time`}>
        {visit.startDateTime ? getHoursAndMinutes(visit.startDateTime) : ''} - {visit.endDateTime ? getHoursAndMinutes(visit.endDateTime) : ''}
      </div>
    </div>
  );
};

export default Visit;
