import { CurrentStatus } from '__generated__/graphql';
import { camelCaseToNormalString } from 'services/strings';

const Status = ({ status }: { status: CurrentStatus }) => {
  switch (true) {
    case status === CurrentStatus.Applied ||
      status === CurrentStatus.ContactedForInitialCall ||
      status === CurrentStatus.InitialCallArranged ||
      status === CurrentStatus.InitialCallDone ||
      status === CurrentStatus.WorkshopBooked ||
      status === CurrentStatus.WorkshopAttended ||
      status === CurrentStatus.ShadowingAgreed ||
      status === CurrentStatus.SuccessfulShadowing ||
      status === CurrentStatus.FullAppFormSubmitted ||
      status === CurrentStatus.JobOfferAccepted:
      return (
        <div className="bg-success-50 border border-success-700 rounded-2xl text-success-700 text-sm leading-sm font-medium px-2.5 py-0.5 w-fit">
          {camelCaseToNormalString(status)}
        </div>
      );
    case status === CurrentStatus.DidNotAnswer || status === CurrentStatus.DidNotShowUp:
      return (
        <div className="bg-warning-50 border border-warning-700 rounded-2xl text-warning-700 text-sm leading-sm font-medium px-2.5 py-0.5 w-fit">
          {camelCaseToNormalString(status)}
        </div>
      );
    case status === CurrentStatus.OnHold:
      return (
        <div className="bg-gray-50 border border-gray-700 rounded-2xl text-gray-700 text-sm leading-sm font-medium px-2.5 py-0.5 w-fit">
          {camelCaseToNormalString(status)}
        </div>
      );
    case status === CurrentStatus.UnsuccessfulAfterWorkshop ||
      status === CurrentStatus.UnsuccessfulAfterShadowing ||
      status === CurrentStatus.DidNotMeetEligibilityCriteria ||
      status === CurrentStatus.WithdrawnApplication:
      return (
        <div className="bg-error-50 border border-error-700 rounded-2xl text-error-700 text-sm leading-sm font-medium px-2.5 py-0.5 w-fit">
          {camelCaseToNormalString(status)}
        </div>
      );
    default:
      return <div className="bg-gray-50 border border-gray-700 rounded-2xl text-gray-700 text-sm leading-sm font-medium px-2.5 py-0.5 w-fit">Unknown</div>;
  }
};

export default Status;
