import { useEffect, useState } from 'react';
import { Candidate, MessageType, Template, Workshop } from '__generated__/graphql';
import { Input } from 'components';
import { SelectOption } from 'types/types';
import ReactSelect from 'components/Select';
import ReactMultiSelect from 'components/MultiSelect';
import DatePicker from 'components/DatePicker';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import TimePicker from 'components/TimePicker';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

interface WorkshopInvitesProps {
  recipients: SelectOption[];
  assignedCandidates: Candidate[];
  templates: Template[];
  workshop: Workshop;
  onClose: () => void;
  onSend: (subject: string, body: string, template: string, recipients: string[]) => void;
}

export default function WorkshopInvites({ recipients, assignedCandidates, templates, workshop, onClose, onSend }: WorkshopInvitesProps) {
  const [selectedRecipients, setSelectedRecipients] = useState<SelectOption[]>(recipients);
  const [date, setDate] = useState<Date>(new Date(workshop.date ?? 0));
  const [subject, setSubject] = useState<string>('');
  const [body, setBody] = useState<string>('');
  const [template, setTemplate] = useState<SelectOption>({ value: '', label: '' });
  const [nameEmailAlreadySent, setNameEmailAlreadySent] = useState<string[]>([]);
  const modules = {
    toolbar: [['bold', 'italic', 'underline', 'strike'], [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }], ['clean']],
  };

  const onSelectTemplate = (t: SelectOption) => {
    const selectedTemplate = templates.find((temp) => temp.id === t.value);
    if (selectedTemplate) {
      setTemplate(t);
      setSubject(selectedTemplate.subject || '');
      setBody(selectedTemplate.message || '');
    }
  };

  const isValidWorkshop = () => {
    if (selectedRecipients.length === 0) return true;
    if (subject.length === 0) return true;
  };

  useEffect(() => {
    const candidateEmailAlreadySent: string[] = [];
    assignedCandidates.forEach((candidate) => {
      if (selectedRecipients.find((r) => r.value === candidate.id) && candidate.tracking?.workshopInviteSent?.status) {
        candidateEmailAlreadySent.push(`${candidate.firstName} ${candidate.lastName}` ?? '');
      }
    });
    setNameEmailAlreadySent(candidateEmailAlreadySent);
  }, [assignedCandidates, selectedRecipients]);

  const filteredTemplates = Object.values(templates)
    .filter((x) => x.id && x.name && x.type === MessageType.Email)
    .map((t) => ({ value: t.id || '', label: t.name || '' }));

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-[1200] outline-none focus:outline-none">
        <div className="relative w-auto mx-auto max-w-5xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none p-6 max-h-screen md:max-h-[70vh] overflow-y-auto md:w-[480px] lg:w-[680px]">
            <div className="text-gray-900 text-lg leading-lg font-semibold mb-5">Workshop invites</div>
            {nameEmailAlreadySent.length > 0 && (
              <div className="text-warning-600 text-md leading-md font-semibold flex items-center gap-3 mb-5">
                <ExclamationTriangleIcon className="w-10 h-10" />
                {`${nameEmailAlreadySent.join(', ')} ${nameEmailAlreadySent.length === 1 ? 'has' : 'have'} already received a workshop invite email.`}
              </div>
            )}
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Recipients</div>
              <div className="mt-2">
                <ReactMultiSelect options={recipients} onChange={setSelectedRecipients} selectedValue={selectedRecipients} searchable />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Template</div>
              <div className="mt-2">
                <ReactSelect testId="template-type" options={filteredTemplates} onChange={onSelectTemplate} selectedValue={template} />
              </div>
            </div>
            <div className="mb-5">
              <div className="flex items-center gap-5">
                <div className="w-1/2">
                  <div className="text-sm font-medium text-gray-700 capitalize w-auto">Date</div>
                  <div className="mt-2">
                    <DatePicker
                      value={date}
                      onChange={(d) => {
                        if (d) setDate(date);
                      }}
                      disabled
                    />
                  </div>
                </div>
                <div className="w-1/2">
                  <div className="text-sm font-medium text-gray-700 capitalize w-auto">Time</div>
                  <div className="mt-2">
                    <TimePicker date={date} disabled />
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Subject</div>
              <div className="mt-2">
                <Input testId="workshop-subject" value={subject} onChange={setSubject} />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Email Content</div>
              <div className="mt-2">
                <ReactQuill
                  data-cy="workshop-email-content"
                  theme="snow"
                  value={body}
                  onChange={setBody}
                  modules={modules}
                  style={{ height: '12em', borderRadius: '8px' }}
                />
              </div>
            </div>
            <div className="flex justify-between items-center mt-16 md:mt-12">
              <button type="button" className="text-gray-500 font-semibold text-md leading-md" onClick={onClose}>
                Cancel
              </button>
              <button
                type="button"
                className="text-white disabled:opacity-50 bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
                onClick={() =>
                  onSend(
                    subject,
                    body,
                    template.value,
                    selectedRecipients.map((r) => r.value),
                  )
                }
                disabled={isValidWorkshop()}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-gray-900" />
    </>
  );
}
