import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { AutoFixHigh } from '@mui/icons-material';
import HalfCircleProgressBar from 'components/HalfCircleProgressBar';
import { useNavigate } from 'react-router-dom';

interface MagicNumberProps {
  members: number;
  totalMembers: number;
  percentage: number;
  testId: string;
}

const MagicNumber = ({ percentage, members, totalMembers, testId }: MagicNumberProps) => {
  const navigate = useNavigate();

  const getHalfCircleWidth = () => {
    if (window.innerWidth > 1536) {
      return 400;
    } else if (window.innerWidth > 1280) {
      return 300;
    } else if (window.innerWidth > 1024) {
      return 250;
    } else if (window.innerWidth > 640) {
      return 400;
    }
    return 300;
  };

  const magicNumberForcastColour = () => {
    if (percentage >= 50) {
      return '#669F2A';
    } else if (percentage >= 45 && percentage < 50) {
      return '#F79009';
    }
    return '#D92D20';
  };

  const magicNumberMessage = () => {
    if (percentage >= 50) {
      return 'Excellent work!..keep it up 👏';
    } else if (percentage >= 45 && percentage < 50) {
      return "You're almost there!";
    }
    return 'Keep it up!';
  };

  return (
    <div className="bg-background2 border border-black border-opacity-[0.13] p-6 rounded-3xl flex flex-col justify-between" data-cy={testId}>
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-3">
          <div className="border border-black border-opacity-20 w-10 h-10 rounded-full flex items-center justify-center">
            <AutoFixHigh className="!w-5 !h-5 text-content-secondary" />
          </div>
          <div className="text-xl leading-xl font-semibold text-black">Magic number</div>
        </div>
        <button type="button" onClick={() => navigate('/magic-number')} aria-label="more informations">
          <QuestionMarkCircleIcon className="w-6 h-6 text text-content-secondary" />
        </button>
      </div>
      <HalfCircleProgressBar
        diameter={getHalfCircleWidth()}
        percentage={parseFloat(percentage.toFixed(1))}
        showPercentValue
        strokeColor={magicNumberForcastColour()}
      />
      <div className="text-center font-medium text-sm leading-sm text-content-secondary mt-1">
        Based on <span className="text-content-primary font-bold">{members}</span> out of <span className="text-content-primary font-bold">{totalMembers}</span>{' '}
        team members
      </div>
      <div className="bg-primary-700 w-full text-center p-4 text-white text-md leading-md font-medium rounded-2xl mt-5">{magicNumberMessage()}</div>
    </div>
  );
};

export default MagicNumber;
