import { differenceInHours, differenceInMinutes, isAfter, isEqual, isSameDay } from 'date-fns';
import { useRef, useState } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { getHoursAndMinutes, getTextColor } from 'services/helpers';
import stc from 'string-to-color';
import {
  AgreedRota,
  AgreedShiftRunWeeks,
  AgreedShift,
  AgreedShiftRun,
  DeleteMultipleAgreedVisitInput,
  AddAgreedVisitInput,
  AddMultipleAgreedVisitInput,
  DeleteAgreedVisitInput,
  EditAgreedVisitInput,
  AgreedVisitType,
  Maybe,
  Absence,
} from '__generated__/graphql';
import { AddCircle, BeachAccess, Cake, Sick, Warning } from '@mui/icons-material';
import { useGetCustomers } from 'api/hooks/useCustomers';
import { useGetAgreedShifts } from 'api/hooks/useAgreedShifts';
import { useGetAgreedRota } from 'api/hooks/useAgreedRota';
import Tooltip from 'components/Tooltip';
import { AddAgreedVisitModalType, AddMultipleAgreedVisitModalType, EditAgreedVisitModalType } from '../types';
import AddAgreedVisit from './AddAgreedVisit';
import EditAgreedVisit from './EditAgreedVisit';
import Visit from './Visit';
import { createDateWithTime, doDateRangesOverlap } from '../utils';

interface DayCellProps {
  agreedShifts: AgreedShiftRunWeeks;
  shift: AgreedShift;
  startDateTime: number;
  endDateTime: number;
  week: number;
  onVisitDrop: (visit: AgreedVisitType, oldAgreedShiftRunId: string, oldAgreedShiftId: string, agreedShiftRunId: string, agreedShiftId: string) => void;
  onAddAgreedVisit: (newVisit: AddAgreedVisitInput) => void;
  onAddMultipleAgreedVisit: (newMultipleVisits: AddMultipleAgreedVisitInput) => void;
  onEditAgreedVisit: (editedVisit: EditAgreedVisitInput) => void;
  onDeleteAgreedVisit: (visit: DeleteAgreedVisitInput) => void;
  onDeleteMultipleAgreedVisit: (visits: DeleteMultipleAgreedVisitInput) => void;
  isDnDEditing: boolean;
  selectedAgreedRota: string;
  isDisable: boolean;
  leave: Absence[];
  isOptimised?: boolean;
}

type VisitDropMonitorProps = {
  visit: AgreedVisitType;
  agreedShiftRunId: string;
  agreedShiftId: string;
};

const DayCell = ({
  agreedShifts,
  shift,
  startDateTime,
  endDateTime,
  week,
  onVisitDrop,
  onAddAgreedVisit,
  onAddMultipleAgreedVisit,
  onEditAgreedVisit,
  onDeleteAgreedVisit,
  onDeleteMultipleAgreedVisit,
  isDnDEditing,
  selectedAgreedRota,
  isDisable,
  leave,
  isOptimised = false,
}: DayCellProps) => {
  const { customers } = useGetCustomers({
    teamId: shift.teamId,
  });

  const [addAgreedVisitModalState, toggleAddAgreedVisitModal] = useState(false);
  const [editAgreedVisitModalState, toggleEditAgreedVisitModal] = useState(false);
  const [visitToEdit, setVisitToEdit] = useState<AgreedVisitType>();
  const [isAbleToAdd, setIsAbleToAdd] = useState(true);
  const [isFutureShiftNotAbleToAdd, setIsFutureShiftNotAbleToAdd] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [draggingStartTimeHover, setDraggingStartTimeHover] = useState(0);
  const [draggingEndTimeHover, setDraggingEndTimeHover] = useState(0);
  const [isAbleToDrop, setIsAbleToDrop] = useState(false);
  const [conflictedVisits, setConflictedVisits] = useState<AgreedVisitType[]>([]);

  const [agreedRotaId, setAgreedRotaId] = useState(selectedAgreedRota);
  const { agreedRotas } = useGetAgreedRota({
    teamId: shift.teamId,
  });

  const { agreedShifts: teamShifts } = useGetAgreedShifts({
    teamId: shift.teamId,
    agreedRotaId,
  });

  const [{ isOver }, dropRef] = useDrop({
    accept: 'visit',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    hover: (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { visit, agreedShiftRunId, agreedShiftId }: { visit: AgreedVisitType; agreedShiftRunId: string; agreedShiftId: string },
      monitor: DropTargetMonitor<VisitDropMonitorProps>,
    ) => {
      const offset = monitor.getSourceClientOffset();

      if (offset && containerRef.current) {
        const dropTargetXy = containerRef.current.getBoundingClientRect();
        const visitDuration = Math.abs(new Date(visit.endDateTime || 0).getTime() - new Date(visit.startDateTime || 0).getTime());
        const startTime = positionToHours(offset.y - dropTargetXy.top);

        const isVisitCanBeDropped = checkIfVisitCanBeDropped(startTime, startTime + visitDuration, visit.id || '');

        setIsAbleToDrop(isVisitCanBeDropped);
        setDraggingStartTimeHover(startTime);
        setDraggingEndTimeHover(startTime + visitDuration);
      }
    },
    drop: (
      { visit, agreedShiftRunId, agreedShiftId }: { visit: AgreedVisitType; agreedShiftRunId: string; agreedShiftId: string },
      monitor: DropTargetMonitor<VisitDropMonitorProps>,
    ) => {
      const offset = monitor.getSourceClientOffset();

      if (offset && containerRef.current) {
        const dropTargetXy = containerRef.current.getBoundingClientRect();
        const visitDuration = Math.abs(new Date(visit.endDateTime || 0).getTime() - new Date(visit.startDateTime || 0).getTime());
        const startTime = positionToHours(offset.y - dropTargetXy.top);

        if (checkIfVisitCanBeDropped(startTime, startTime + visitDuration, visit.id || '')) {
          const newVisit = { ...visit };
          const { startDate, endDate } = getExactVisitDate(startTime, startTime + visitDuration);
          newVisit.startDateTime = startDate.getTime();
          newVisit.endDateTime = endDate.getTime();
          onVisitDrop(newVisit, agreedShiftRunId, agreedShiftId, shift.agreedShiftRunId, shift.id);
        }
      }
    },
    collect: (
      monitor: DropTargetMonitor<{
        visit: AgreedVisitType;
        agreedShiftRunId: string;
        agreedShiftId: string;
      }>,
    ) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const checkIfVisitCanBeDropped = (startTime: number, endTime: number, visitId: string) => {
    let isVisitCanBeDropped = true;
    const visitStartDate = createDateWithTime(Date.now(), startTime);
    const visitEndDate = createDateWithTime(Date.now(), endTime);
    const shiftStartDate = createDateWithTime(Date.now(), startDateTime);
    const shiftEndDate = createDateWithTime(Date.now(), endDateTime);

    if (!(shiftStartDate <= visitStartDate && visitEndDate <= shiftEndDate)) {
      return false;
    }

    shift.visits?.forEach((visit: Maybe<AgreedVisitType>) => {
      const startDate = new Date();
      const endDate = new Date();
      if (visit?.startDateTime) startDate.setHours(new Date(visit.startDateTime).getHours(), new Date(visit.startDateTime).getMinutes(), 0, 0);
      if (visit?.endDateTime) endDate.setHours(new Date(visit.endDateTime).getHours(), new Date(visit.endDateTime).getMinutes(), 0, 0);
      if (visitId !== visit?.id && doDateRangesOverlap(visitStartDate, startDate, visitEndDate, endDate)) {
        isVisitCanBeDropped = false;
      }
    });
    return isVisitCanBeDropped;
  };

  const positionToHours = (y: number) => {
    const round = Math.round(y / 5) * 5;
    const numberOfDecimalHours = round / 60;
    const time = new Date(0, 0);
    time.setMinutes(+numberOfDecimalHours * 60);
    const startDate = new Date();
    startDate.setHours(new Date(startDateTime).getHours() + time.getHours());
    startDate.setMinutes(new Date(startDateTime).getMinutes() + time.getMinutes());
    const startTime = startDate.getTime();
    return startTime;
  };

  const checkIfVisitCanBeAdded = (edit: boolean, visitId: string, startTime: number, endTime: number) => {
    let isVisitCanBeAdded = true;
    const visitStartDate = createDateWithTime(Date.now(), startTime);
    const visitEndDate = createDateWithTime(Date.now(), endTime);
    const newConflictedVisits: AgreedVisitType[] = [];

    const checkVisits = (w: Maybe<AgreedShiftRun>[]) => {
      w.forEach((shiftRun: Maybe<AgreedShiftRun>) => {
        if (shiftRun?.id === shift.agreedShiftRunId) {
          shiftRun?.shifts?.forEach((s) => {
            if (s?.id === shift.id) {
              s?.visits?.forEach((visit) => {
                const startDate = createDateWithTime(Date.now(), visit?.startDateTime ?? 0);
                const endDate = createDateWithTime(Date.now(), visit?.endDateTime ?? 0);
                if (((edit && visit?.id !== visitId) || !edit) && doDateRangesOverlap(visitStartDate, startDate, visitEndDate, endDate)) {
                  if (visit) {
                    newConflictedVisits.push(visit);
                  }
                  isVisitCanBeAdded = false;
                }
              });
            }
          });
        }
      });
    };

    checkVisits(teamShifts.week1 ?? []);
    checkVisits(teamShifts.week2 ?? []);

    setConflictedVisits(newConflictedVisits);
    return isVisitCanBeAdded;
  };

  const checkIfMultipleVisitsCanBeAddedInRota = (
    agreedRotaStartDate: number,
    shiftDate: number,
    shiftRunIndex: number,
    addMultipleVisit: AddMultipleAgreedVisitModalType,
  ): boolean => {
    let isVisitCanBeAdded = true;
    const visitStartDate = createDateWithTime(Date.now(), addMultipleVisit.startDateTime);
    const visitEndDate = createDateWithTime(Date.now(), addMultipleVisit.endDateTime);
    const newConflictedVisits: AgreedVisitType[] = [];

    const processShift = (shiftRun: Maybe<AgreedShiftRun>, dayOffset: number) => {
      shiftRun?.shifts?.forEach((s: Maybe<AgreedShift>) => {
        const shiftStartDate = createDateWithTime(agreedRotaStartDate, shiftRun?.startDateTime ?? 0);

        if (s?.day !== undefined) shiftStartDate.setDate(shiftStartDate.getDate() + s.day + dayOffset);
        if (addMultipleVisit.untilDate === null || (addMultipleVisit.untilDate !== null && isAfter(addMultipleVisit.untilDate, shiftStartDate))) {
          if (s?.day !== undefined && addMultipleVisit.days.includes(s?.day) && (isAfter(shiftStartDate, shiftDate) || isEqual(shiftDate, shiftStartDate))) {
            const shiftRunStartDate = createDateWithTime(Date.now(), shiftRun?.startDateTime ?? 0);
            const shiftRunEndDate = createDateWithTime(Date.now(), shiftRun?.endDateTime ?? 0);
            if (
              (isAfter(visitEndDate, shiftRunEndDate) && !isEqual(visitEndDate, shiftRunEndDate)) ||
              (isAfter(shiftRunStartDate, visitStartDate) && !isEqual(visitStartDate, shiftRunStartDate))
            ) {
              setIsFutureShiftNotAbleToAdd(true);
              isVisitCanBeAdded = false;
            }

            s?.visits?.forEach((visit: Maybe<AgreedVisitType>) => {
              const startDate = createDateWithTime(Date.now(), visit?.startDateTime ?? 0);
              const endDate = createDateWithTime(Date.now(), visit?.endDateTime ?? 0);

              if (doDateRangesOverlap(visitStartDate, startDate, visitEndDate, endDate)) {
                if (visit) {
                  newConflictedVisits.push(visit);
                }
                isVisitCanBeAdded = false;
              }
            });
          }
        }
      });
    };

    teamShifts?.week1?.forEach((shiftRun: Maybe<AgreedShiftRun>, index: number) => {
      if (shiftRunIndex === index) {
        processShift(shiftRun, 0);
      }
    });

    teamShifts?.week2?.forEach((shiftRun: Maybe<AgreedShiftRun>, index: number) => {
      if (shiftRunIndex === index) {
        processShift(shiftRun, 7);
      }
    });

    setConflictedVisits(newConflictedVisits);
    return isVisitCanBeAdded;
  };

  const checkIfMultipleVisitsCanBeAdded = (shiftDate: Date, addMultipleVisit: AddMultipleAgreedVisitModalType) => {
    let isVisitCanBeAdded = true;
    let shiftRunIndex = 0;
    setIsFutureShiftNotAbleToAdd(false);
    [agreedShifts?.week1, agreedShifts?.week2].forEach((w) => {
      w?.forEach((visitRun: Maybe<AgreedShiftRun>, index: number) => {
        if (visitRun?.id === shift.agreedShiftRunId) {
          shiftRunIndex = index;
        }
      });
    });
    agreedRotas.forEach((agreedRota: AgreedRota) => {
      if (agreedRota.endRotaDateTime && agreedRota.id && isAfter(agreedRota.endRotaDateTime, shiftDate)) {
        setAgreedRotaId(agreedRota.id);
        if (
          agreedRota.startRotaDateTime &&
          !checkIfMultipleVisitsCanBeAddedInRota(agreedRota.startRotaDateTime, shiftDate.getTime(), shiftRunIndex, addMultipleVisit)
        ) {
          isVisitCanBeAdded = false;
        }
      }
    });
    return isVisitCanBeAdded;
  };

  const getExactVisitDate = (visitStartTime: number, visitEndTime: number) => {
    const startDate = createDateWithTime(startDateTime, visitStartTime);
    const endDate = createDateWithTime(startDateTime, visitEndTime);

    const dayShift = week === 1 ? shift.day : shift.day + 7;

    startDate.setDate(startDate.getDate() + dayShift);
    endDate.setDate(endDate.getDate() + dayShift);

    return { startDate, endDate };
  };

  const onAddVisit = (addVisit: AddAgreedVisitModalType) => {
    const { startDate, endDate } = getExactVisitDate(addVisit.startDateTime, addVisit.endDateTime);
    if (checkIfVisitCanBeAdded(false, '', addVisit.startDateTime, addVisit.endDateTime)) {
      onAddAgreedVisit({
        teamId: shift.teamId,
        agreedRotaId: shift.agreedRotaId,
        agreedShiftRunId: shift.agreedShiftRunId,
        agreedShiftId: shift.id,
        customerId: addVisit.customerId,
        startDateTime: startDate.getTime(),
        endDateTime: endDate.getTime(),
      });
      toggleAddAgreedVisitModal(false);
    } else {
      setIsAbleToAdd(false);
    }
  };

  const onAddMultipleVisit = (addMultipleVisit: AddMultipleAgreedVisitModalType) => {
    const { startDate, endDate } = getExactVisitDate(addMultipleVisit.startDateTime, addMultipleVisit.endDateTime);
    const newMultipleVisits = {
      teamId: shift.teamId,
      agreedRotaId: shift.agreedRotaId,
      agreedShiftRunId: shift.agreedShiftRunId,
      agreedShiftId: shift.id,
      customerId: addMultipleVisit.customerId,
      startDateTime: startDate.getTime(),
      endDateTime: endDate.getTime(),
      days: addMultipleVisit.days?.length > 0 ? addMultipleVisit.days : [shift.day],
      untilDate: addMultipleVisit.untilDate,
    };
    if (checkIfMultipleVisitsCanBeAdded(getExactShiftDate(), newMultipleVisits)) {
      onAddMultipleAgreedVisit(newMultipleVisits);
      toggleAddAgreedVisitModal(false);
    } else {
      setIsAbleToAdd(false);
    }
  };

  const onEditVisitClick = (visit: AgreedVisitType) => {
    setVisitToEdit(visit);
    toggleEditAgreedVisitModal(true);
  };

  const onEditVisit = (visitEdited: EditAgreedVisitModalType) => {
    const { startDate, endDate } = getExactVisitDate(visitEdited.startDateTime, visitEdited.endDateTime);
    if (checkIfVisitCanBeAdded(true, visitEdited.id, visitEdited.startDateTime, visitEdited.endDateTime)) {
      onEditAgreedVisit({
        id: visitEdited.id,
        teamId: shift.teamId,
        agreedRotaId: shift.agreedRotaId,
        customerId: visitEdited.customerId,
        startDateTime: startDate.getTime(),
        endDateTime: endDate.getTime(),
      });
      toggleEditAgreedVisitModal(false);
    } else {
      setIsAbleToAdd(false);
    }
  };

  const onDeleteVisit = (id: string) => {
    onDeleteAgreedVisit({
      id,
      teamId: shift.teamId,
      agreedRotaId: shift.agreedRotaId,
    });
  };

  const onDeleteMultipleVisit = (startDate: number | null, endDate: number | null, customerId: string) => {
    onDeleteMultipleAgreedVisit({
      teamId: shift.teamId,
      customerId,
      startDateTime: startDate,
      endDateTime: endDate,
    });
  };

  const getExactShiftDate = () => {
    const startDate = new Date(startDateTime);
    const shiftDays = week === 1 ? shift.day : shift.day + 7;

    startDate.setDate(startDate.getDate() + shiftDays);
    return startDate;
  };

  const numberOfHoursInShift = differenceInHours(new Date(endDateTime), new Date(startDateTime));

  const numberOfMinutesRemainingInShift = differenceInMinutes(new Date(endDateTime), new Date(startDateTime)) - numberOfHoursInShift * 60;

  const shiftHeight = numberOfHoursInShift * 60 + numberOfMinutesRemainingInShift + 77;

  const isDuplicateVisit = (visit: Maybe<AgreedVisitType>) => {
    let isDuplicate = false;
    const visitStartDate = createDateWithTime(Date.now(), visit?.startDateTime ?? 0);
    const visitEndDate = createDateWithTime(Date.now(), visit?.endDateTime ?? 0);
    shift.visits?.forEach((v) => {
      const startDate = createDateWithTime(Date.now(), v?.startDateTime ?? 0);
      const endDate = createDateWithTime(Date.now(), v?.endDateTime ?? 0);
      if (v?.id !== visit?.id && doDateRangesOverlap(visitStartDate, startDate, visitEndDate, endDate)) {
        isDuplicate = true;
      }
    });
    return isDuplicate;
  };

  const isShiftOwnerOnLeave = leave.some(
    (l) =>
      l.supportWorkerId === shift.ownerId &&
      (isSameDay(getExactShiftDate(), l.absenceDate ?? 0) ||
        isSameDay(getExactShiftDate(), l.returnDate ?? 0) ||
        (isAfter(getExactShiftDate(), l.absenceDate ?? 0) && isAfter(l.returnDate ?? 0, getExactShiftDate()))),
  );

  const shiftOwnerLeaveType = leave.find(
    (l) =>
      isSameDay(getExactShiftDate(), l.absenceDate ?? 0) ||
      isSameDay(getExactShiftDate(), l.returnDate ?? 0) ||
      (isAfter(getExactShiftDate(), l.absenceDate ?? 0) && isAfter(l.returnDate ?? 0, getExactShiftDate()) && l.supportWorkerId === shift.ownerId),
  )?.absenceReason;

  return (
    <>
      {addAgreedVisitModalState && (
        <AddAgreedVisit
          onClose={() => toggleAddAgreedVisitModal(false)}
          onAddAgreedVisit={onAddVisit}
          onAddMultipleAgreedVisit={onAddMultipleVisit}
          customers={customers}
          agreedShiftRunStartTime={startDateTime}
          agreedShiftRunEndTime={endDateTime}
          isAbleToAdd={isAbleToAdd}
          isFutureShiftNotAbleToAdd={isFutureShiftNotAbleToAdd}
          conflictedVisits={conflictedVisits}
          setIsAbleToAdd={setIsAbleToAdd}
          isDnDEditing={isDnDEditing}
          shiftDate={getExactShiftDate()}
        />
      )}
      {editAgreedVisitModalState && (
        <EditAgreedVisit
          onClose={() => toggleEditAgreedVisitModal(false)}
          visit={visitToEdit}
          onEditAgreedVisit={onEditVisit}
          onDeleteAgreedVisit={onDeleteVisit}
          onDeleteMultipleAgreedVisit={onDeleteMultipleVisit}
          customers={customers}
          agreedShiftRunStartTime={startDateTime}
          agreedShiftRunEndTime={endDateTime}
          isAbleToAdd={isAbleToAdd}
          conflictedVisits={conflictedVisits}
          setIsAbleToAdd={setIsAbleToAdd}
          isDnDEditing={isDnDEditing}
        />
      )}
      <div className="flex flex-col xl:w-[13%] border-t xl:border-t-0 border-l border-gray-100" style={{ height: shiftHeight }}>
        {shift.fullName !== '' && (
          <>
            <div
              className="h-15 p-2"
              style={{ backgroundColor: shift.fullName === 'TBC' ? '#D32F2F' : shift.fullName !== '' ? stc(shift.fullName) : '#ffffff' }}
            >
              <div className="flex items-center justify-between h-10">
                {isOver && isAbleToDrop ? (
                  <div className="text-md leading-xs font-semibold" style={{ color: shift.fullName === 'TBC' ? 'white' : getTextColor(stc(shift.fullName)) }}>
                    {getHoursAndMinutes(draggingStartTimeHover)} - {getHoursAndMinutes(draggingEndTimeHover)}
                  </div>
                ) : (
                  <>
                    <div
                      className="text-md leading-xs font-semibold"
                      style={{ color: shift.fullName === 'TBC' ? 'white' : getTextColor(stc(shift.fullName)) }}
                      onClick={() => navigator?.clipboard?.writeText(JSON.stringify(shift))}
                    >
                      {shift.fullName}
                    </div>
                    {!isDisable && (
                      <Tooltip tooltip="Add Agreed Visit" right>
                        <button
                          data-cy={`add-agreed-visit-button-week-${week}-day-${shift.day}`}
                          type="button"
                          aria-label="Add Placeholder Visit"
                          onClick={() => toggleAddAgreedVisitModal(true)}
                        >
                          <AddCircle style={{ color: shift.fullName === 'TBC' ? 'white' : getTextColor(stc(shift.fullName)) }} />
                        </button>
                      </Tooltip>
                    )}
                  </>
                )}
              </div>
              <div className="h-5">
                {isShiftOwnerOnLeave && (
                  <div
                    className="flex flex-row justify-between items-center gap-2"
                    style={{ color: shift.fullName === 'TBC' ? 'white' : getTextColor(stc(shift.fullName)) }}
                  >
                    <Warning />
                    {shiftOwnerLeaveType?.includes('Birthday') ? <Cake /> : shiftOwnerLeaveType?.includes('Sick') ? <Sick /> : <BeachAccess />}
                  </div>
                )}
              </div>
            </div>
            <div ref={containerRef}>
              <div ref={dropRef}>
                <div className="relative">
                  {shift.visits?.map((visit: Maybe<AgreedVisitType>, index: number) => {
                    if (visit) {
                      return (
                        <Visit
                          isDuplicateVisit={isDuplicateVisit(visit)}
                          visit={visit}
                          startDateTime={startDateTime}
                          agreedShiftRunId={shift.agreedShiftRunId}
                          agreedShiftId={shift.id}
                          onEditVisit={onEditVisitClick}
                          isDisable={isDisable}
                          index={index}
                          day={shift.day}
                          key={index}
                          isOptimised={isOptimised}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                  {[...Array(numberOfHoursInShift)].map((_, index: number) => {
                    return (
                      <div
                        key={index}
                        className="opacity-20 h-[60px]"
                        style={{
                          borderBottom: `1px solid ${shift.fullName === 'TBC' ? 'white' : getTextColor(stc(shift.fullName))}`,
                          backgroundColor: shift.fullName === 'TBC' ? '#D32F2F' : shift.fullName !== '' ? stc(shift.fullName) : '#ffffff',
                        }}
                      />
                    );
                  })}
                  {numberOfMinutesRemainingInShift > 0 && (
                    <div
                      className="opacity-20 border-b border-gray-100"
                      style={{
                        height: numberOfMinutesRemainingInShift,
                        backgroundColor: shift.fullName === 'TBC' ? '#D32F2F' : shift.fullName !== '' ? stc(shift.fullName) : '#ffffff',
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DayCell;
