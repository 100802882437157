import { ColumnDef, SortingState, VisibilityState, getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { useReactToPrint } from 'react-to-print';
import { SelectOption } from 'types/types';
import { useState, SetStateAction, Dispatch, useRef } from 'react';
import Filters, { SelectType } from 'components/Filters';
import { TablePagination } from './TablePagination';
import { TableFilterPanel } from './TableFilterPanel';
import { TableContent } from '.';

export type filterType = { name: string; selectedValue: SelectOption; values: SelectOption[] };

interface TableProps<TableType> {
  columns: ColumnDef<TableType>[];
  data: TableType[];
  columnVisibility?: VisibilityState;
  search?: string;
  setSearch?: Dispatch<SetStateAction<string>>;
  filters: SelectType[];
  setFilters: Dispatch<SetStateAction<filterType[]>>;
  csv?: { data: string | object[] | (() => string | object[]); fileName: string };
  toggleButtonKey?: string;
}

const Table = <TableType,>({ columns, data, columnVisibility, search, setSearch, filters, setFilters, csv, toggleButtonKey }: TableProps<TableType>) => {
  const onSaveFilters = () => {
    setShowFiltersModal(false);
  };

  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);

  const onFilterChange = (name: string, value: SelectOption) => {
    const newFilters = [...filters];
    const filterIndex = newFilters.findIndex((f) => f.name === name);
    newFilters[filterIndex].selectedValue = value;
    setFilters(newFilters);
  };
  const filterWithoutToggle = filters.filter((f) => f.name !== toggleButtonKey);

  const tableRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    initialState: {
      columnVisibility: columnVisibility ?? {},
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      <TableFilterPanel
        search={search}
        setSearch={setSearch}
        filters={filters}
        filterWithoutToggle={filterWithoutToggle}
        csv={csv}
        toggleButtonKey={toggleButtonKey}
        setShowFiltersModal={setShowFiltersModal}
        onFilterChange={onFilterChange}
        handlePrint={handlePrint}
      />
      <div className="mt-3 md:mt-12 mb-10 overflow-x-auto overflow-y-hidden">
        {data && data.length > 0 && (
          <div ref={tableRef} className="mt-3 md:mt-12 mb-10">
            <div className="mt-2 flex flex-col">
              <div className="-my-2">
                <div className="py-2 align-middle inline-block min-w-full">
                  <TableContent<TableType> table={table} />
                  <TablePagination
                    isNextDisabled={!table.getCanNextPage()}
                    isPreviousDisabled={!table.getCanPreviousPage()}
                    onClickPrevious={table.previousPage}
                    onClickNext={table.nextPage}
                    pageCount={table.getPageCount()}
                    pageIndex={table.getState().pagination.pageIndex}
                    setIndex={table.setPageIndex}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {showFiltersModal && (
        <Filters filters={filterWithoutToggle} onFilterChange={onFilterChange} onCloseModal={() => setShowFiltersModal(false)} onSaveFilters={onSaveFilters} />
      )}
    </>
  );
};

export default Table;
