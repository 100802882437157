import { useState } from 'react';
import getTime from 'date-fns/getTime';
import { AddMileageReportingHourInput, ReportingHoursPeriod } from '__generated__/graphql';
import { Input } from 'components';
import DateTimePicker from 'components/DateTimePicker';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import Button from 'components/Button';

interface MileageProps {
  reportingHoursPeriod?: ReportingHoursPeriod | null;
  apiLoading: boolean;
  addMileageReportHour: (input: AddMileageReportingHourInput) => void;
}

const CreateMileage = ({ reportingHoursPeriod, apiLoading, addMileageReportHour }: MileageProps) => {
  const [date, setDate] = useState<Date | null>(new Date());
  const [notes, setNotes] = useState<string>('');
  const [mileage, setMileage] = useState<string>('');
  const [mileageDestination, setMileageDestination] = useState<string>('');
  const [validation, setValidation] = useState<string>('');

  const onChangeStartDateTime = (dateTime: Date | null) => {
    if (dateTime) {
      setDate(dateTime);
      setValidation('');
    }
  };

  const onAddMileage = () => {
    if (Number.isNaN(parseFloat(mileage))) {
      setValidation('Mileage should be a number');
      return;
    }

    if (!mileageDestination) {
      setValidation('Please provide a destination');
      return;
    }

    if (!notes) {
      setValidation('Please provide notes');
      return;
    }

    addMileageReportHour({
      teamId: reportingHoursPeriod?.teamId,
      supportWorkerId: reportingHoursPeriod?.supportWorkerId,
      period: reportingHoursPeriod?.id,
      date: getTime(date ?? 0),
      notes,
      mileage: parseFloat(mileage),
      mileageDestination,
    });

    setValidation('');
  };

  return (
    <>
      <div className="mt-5">
        <div className="text-sm font-medium text-gray-700 capitalize w-auto">Start time</div>
        <div className="mt-2">
          <DateTimePicker
            minDate={new Date(reportingHoursPeriod?.startDateTime || 0)}
            maxDate={new Date(reportingHoursPeriod?.endDateTime || 0)}
            date={date}
            onChange={onChangeStartDateTime}
            disabled={reportingHoursPeriod?.status === 'Submitted'}
          />
        </div>
      </div>
      <div className="mt-3">
        <div className="text-sm font-medium text-gray-700 capitalize w-auto">Notes</div>
        <div className="mt-2">
          <Input value={notes} onChange={setNotes} disabled={reportingHoursPeriod?.status === 'Submitted'} />
        </div>
      </div>
      <div className="mt-3">
        <div className="text-sm font-medium text-gray-700 capitalize w-auto">Mileage</div>
        <div className="mt-2">
          <Input value={mileage} onChange={setMileage} disabled={reportingHoursPeriod?.status === 'Submitted'} />
        </div>
      </div>
      <div className="mt-3">
        <div className="text-sm font-medium text-gray-700 capitalize w-auto">Destination</div>
        <div className="mt-2">
          <Input value={mileageDestination} onChange={setMileageDestination} disabled={reportingHoursPeriod?.status === 'Submitted'} />
        </div>
      </div>
      {validation && (
        <div className="text-error-700 text-lg leading-lg flex items-center gap-2 mt-2">
          <ExclamationTriangleIcon className="w-5 h-5" />
          {validation}
        </div>
      )}
      <div className="flex justify-end mt-5">
        <Button
          loading={apiLoading}
          color="bg-primary-700"
          label="Add"
          disabled={reportingHoursPeriod?.status === 'Submitted'}
          onClick={onAddMileage}
          loadingLabel="Adding..."
        />
      </div>
    </>
  );
};

export default CreateMileage;
