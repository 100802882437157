import React from 'react';

interface TabsProps {
  tabs: string[];
  selectedTab: number;
  onChange: (index: number) => void;
}

const Tabs = ({ tabs, selectedTab, onChange }: TabsProps) => {
  return (
    <div className="w-full border-b-2 border-gray-100 flex items-center gap-10 -mb-px overflow-auto">
      {tabs.map((tab, index) => (
        <button
          data-cy={`${tab.toLowerCase().replaceAll(' ', '-')}-tab`}
          type="button"
          className={`${
            selectedTab === index ? 'text-primary-900 border-primary-700 border-b-2' : 'text-gray-500'
          } text-md leading-md font-semibold pb-4 shrink-0`}
          onClick={() => onChange(index)}
        >
          {tab}
        </button>
      ))}
    </div>
  );
};

export default Tabs;
