import { useState, useMemo } from 'react';
import Helmet from 'react-helmet';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Loading, Table } from 'components';
import { Report } from '__generated__/graphql';
import { getPeriodsForYear, formatTime, getFirstDayOfPayroll } from 'services/helpers';
import { useGenerateReport, useGetReports } from 'api/hooks/useReports';
import { SelectOption } from 'types/types';
import ReactSelect from '../../components/Select';
import Message from '../../components/Message';
import Navigation from './Navigation';

const cloudfront = process.env.REACT_APP_TEAMHUB_CLOUDFRONT;

export default function GenerateRotaReport() {
  const periods = getPeriodsForYear().map((period) => {
    return { label: formatTime(period, 'MMMM, yyyy'), value: period.toString() };
  });

  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const [period, setPeriod] = useState<SelectOption>(periods[0]);
  const { generateReport, mutationGenerateReport } = useGenerateReport({});
  const { reports, loading } = useGetReports({});

  const onGenerateReport = () => {
    setOpenSnack(true);
    generateReport({ variables: { input: { startDateTime: getFirstDayOfPayroll(parseFloat(period?.value)) } } });
    setTimeout(() => setOpenSnack(false), 5000);
  };

  const columnHelper = createColumnHelper<Report>();

  const columns1 = useMemo(() => {
    return [
      columnHelper.accessor('url', {
        header: () => 'Url',
        cell: (info) => (
          <a className="text-blue-900" href={`${cloudfront}${info.renderValue()}`} target="_blank" rel="noopener noreferrer">
            {info.renderValue()}
          </a>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('startDateTime', {
        header: () => 'Start Date Time',
        cell: (info) => formatTime(info.renderValue() || 0, 'PPP'),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('endDateTime', {
        header: () => 'End Date Time',
        cell: (info) => formatTime(info.renderValue() || 0, 'PPP'),
        footer: (info) => info.column.id,
      }),
    ] as Array<ColumnDef<Report, unknown>>;
  }, [columnHelper]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Generated Rota Reports</title>
      </Helmet>
      <Navigation report="Generated Rota Reports" />
      <Message response={[mutationGenerateReport]} openSnack={openSnack} setOpenSnack={setOpenSnack} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-display-lg leading-display-lg tracking-display-lg text-black font-medium">Generated Rota Reports</div>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-between mt-8 md:mt-16">
          <div className="flex flex-col md:flex-row items-center gap-4 md:gap-9 w-full md:w-fit">
            <ReactSelect testId="generate-period-select" options={periods} onChange={setPeriod} selectedValue={period} searchable={true} />
            <button
              type="button"
              data-cy="generate-report-button"
              className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
              onClick={onGenerateReport}
            >
              Generate Report
            </button>
          </div>
        </div>
        <div className="mt-3 md:mt-12 mb-10 overflow-x-auto overflow-y-hidden">
          {reports && reports.length > 0 && <Table<Report> data={reports} columns={columns1} />}
        </div>
      </div>
    </>
  );
}
