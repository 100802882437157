import { useGetSupportWorkers } from 'api/hooks/useSupportWorkers';
import { SupportWorker } from '__generated__/graphql';
import Loading from 'components/Loading';
import Avatar from 'components/Avatar';
import { ImageSizeType } from 'types/types';

interface SupportWorkerSelectProps {
  onClick: (id: string) => void;
  selectedSupportWorker?: string;
  selectedSupportWorkers?: string[];
  isSelectMultiple?: boolean;
  showContractedHours?: boolean;
  teamId: string | undefined;
}

export default function SupportWorkerSelect({
  selectedSupportWorker,
  selectedSupportWorkers,
  isSelectMultiple = false,
  onClick,
  showContractedHours = false,
  teamId,
}: SupportWorkerSelectProps) {
  const { supportWorkers, loading } = useGetSupportWorkers({
    teamId,
  });

  return (
    <>
      {loading && <Loading isComponent />}
      {!loading && (
        <div className="grid xl:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-2">
          {supportWorkers.map((supportWorker: SupportWorker) => {
            const isSelected =
              (isSelectMultiple && selectedSupportWorkers?.includes(supportWorker.id)) || (!isSelectMultiple && selectedSupportWorker === supportWorker.id);
            return (
              <div
                data-cy={`supportWorker-${supportWorker.id}`}
                className={`${isSelected ? ' text-white bg-primary-700' : 'text-gray-800'} cursor-pointer p-2.5 rounded-lg`}
                onClick={() => onClick(supportWorker.id)}
                key={supportWorker.id}
              >
                <Avatar alt={supportWorker.fullName} avatar={supportWorker.avatar} size={ImageSizeType.LG} />
                <div className="text-md leading-md font-medium">{supportWorker.fullName}</div>
                {showContractedHours && <div className="text-sm leading-sm">{supportWorker.contractedHours} hours per week</div>}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}
