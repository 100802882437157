import Helmet from 'react-helmet';
import { Loading, LocationBar } from 'components';
import { LocalLibrary } from '@mui/icons-material';
import { useGetGuides } from 'api/hooks/useGetGuides';
import FileSections from 'components/FileSections';

const Guides = () => {
  const { guides, loading } = useGetGuides();

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <Helmet>
        <title>BelleVie Guides</title>
      </Helmet>
      <LocationBar section="Policies & Practices" page="Guides" Icon={LocalLibrary} />
      <div className="my-10 px-4 md:px-[5%]">
        <FileSections sections={guides ?? []} />
      </div>
    </>
  );
};

export default Guides;
