import { RadioGroupField } from 'components/Fields/RadioGroupField';

export default function FormCheckList({ disabled }: { disabled: boolean }) {
  const booleanButtons = ['No', 'Yes', 'Not seen this time'];

  return (
    <div className="mt-8 sm:mt-12">
      <RadioGroupField name="medicalObvs.competencyChecklist" disabled={disabled} isRequired={true} radioOptions={booleanButtons} />
    </div>
  );
}
