import { Groups } from '@mui/icons-material';
import { LocationBar } from 'components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUser } from 'context/userContext';
import { Helmet } from 'react-helmet';

const TeamMeetingsNav = () => {
  const [searchParams] = useSearchParams();
  const { user } = useUser();
  const teamId = searchParams.get('teamId') || user?.teamId || '';
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Team Meetings</title>
      </Helmet>
      <LocationBar section="People" page="Team Meeting List" Icon={Groups} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-display-sm sm:text-display-lg leading-display-sm sm:leading-display-lg sm:tracking-display-lg text-black font-medium">
            Team Meetings
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <button
              type="button"
              data-cy="new-team-meeting-button"
              className=" border flex border-gray-300 text-gray-700 text-md leading-md px-4 py-2.5 rounded-lg shadow-xs"
              onClick={() => navigate(`/team-meetings/new?teamId=${teamId}`)}
            >
              Start a new team meeting
            </button>
            <button
              type="button"
              className="text-primary-700 px-4 py-2 border border-primary-700 flex flex-row items-center gap-2 rounded-lg font-medium text-md w-full sm:w-fit"
              onClick={() => navigate({ pathname: '/wellbeing-board', search: `teamId=${teamId}` })}
              data-cy="wellbeing-board-button"
            >
              <Groups />
              View wellbeing board
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamMeetingsNav;
