import { Maybe, Shift, ShiftRun } from '__generated__/graphql';
import { useUser } from 'context/userContext';
import { getDay } from 'date-fns';
import { ShiftType, VisitStatus } from 'pages/ReportingHours/service';
import { useMemo } from 'react';
import { groupByKey } from 'services/arrays';
import { getStartOfDay } from 'services/helpers';
import { SelectOption } from 'types/types';

const useShiftFilters = (filters: Record<string, SelectOption>, teamShifts: Shift[]) => {
  const { user } = useUser();
  const rotaId = user?.rota.id;

  const isHistoricalRota = user?.rota?.data?.previous?.find((r) => r?.id === rotaId) || null;

  const filterByIsVisitType = (sh: Shift) => {
    return sh?.type === 'visit' || sh.type === ShiftType.oneoff;
  };

  const filterValues = useMemo(() => {
    const filterByWeek = (sh: Shift) => {
      if (!filters.week || filters.week.value === 'all') return true;
      if (sh?.week === filters.week.value) return true;
      return false;
    };

    const filterByAssignment = (sh: Shift) => {
      if (filters.assignment.value === 'all' && isHistoricalRota) return true;
      if (filters.assignment.value === 'all' && sh?.endDateTime > getStartOfDay(Date.now())) return true;
      if (filters.assignment.value === '1') return true;
      if (filters.assignment.value === '2' && sh?.secondarySupportWorker === null && sh?.status === VisitStatus.active) return true;
      if (filters.assignment.value === '3' && sh?.secondarySupportWorker) return true;
      if (filters.assignment.value === '4' && sh?.status === VisitStatus.cancelled) return true;
      return false;
    };

    const filterBySupportWorker = (sh: Shift) => {
      if (!filters.supportWorker || filters.supportWorker.value === 'all') return true;
      if (filters.supportWorker && sh?.secondarySupportWorker?.id === filters.supportWorker.value) return true;
      return false;
    };

    const filterByDay = (sh: Shift) => {
      if (!filters.day || filters.day.value === 'all') return true;
      if (filters.day && getDay(sh?.startDateTime).toString() === filters.day.value) return true;
      return false;
    };

    const filterByType = (sh: Shift) => {
      if (!filters.visitType || filters.visitType.value === 'all') return true;
      if (filters.visitType && sh?.type === filters.visitType.value) return true;
      return false;
    };

    const filterByCustomer = (sh: Shift) => {
      if (!filters.customer || filters.customer.value === 'all') return true;
      if (sh?.customer?.id === filters.customer.value) return true;
      return false;
    };

    const filterByShiftMoved = (sh: Shift) => {
      if (filters.excludeMovedVisits.value === 'all' && sh?.status !== VisitStatus.moved) return true;
      if (filters.excludeMovedVisits.value === 'include') return true;
      return false;
    };

    const allShifts = teamShifts
      .filter((sh: Shift) => filterByType(sh))
      .filter((sh: Shift) => filterByAssignment(sh))
      .filter((sh: Shift) => filterBySupportWorker(sh))
      .filter((sh: Shift) => filterByCustomer(sh))
      .filter((sh: Shift) => filterByDay(sh))
      .filter((sh: Shift) => filterByWeek(sh))
      .filter((sh: Shift) => filterByType(sh))
      .filter((sh: Shift) => filterByShiftMoved(sh));

    const tbcs = teamShifts
      .filter((sh: Shift) => filterByIsVisitType(sh))
      .filter((sh: Shift) => sh?.secondarySupportWorker === null && sh?.status === VisitStatus.active && sh?.endDateTime > Date.now());

    const allShiftRuns = teamShifts
      ?.map((sh: Shift) => sh.shiftRun)
      .reduce((acc: (Maybe<ShiftRun> | undefined)[], x: Maybe<ShiftRun> | undefined) => acc.concat(acc.find((y) => y?.id === x?.id) ? [] : [x]), []);

    const myShiftRuns = teamShifts
      ?.filter((sh: Shift) => sh.shiftRun && sh?.shiftRun?.ownerId === user?.profile)
      .map((sh: Shift) => sh.shiftRun)
      .reduce((acc: (Maybe<ShiftRun> | undefined)[], x: Maybe<ShiftRun> | undefined) => acc?.concat(acc?.find((y) => y?.id === x?.id) ? [] : [x]), []);

    const myActivities = teamShifts
      .filter((sh: Shift) => !filterByIsVisitType(sh))
      .filter((sh: Shift) => sh?.status === VisitStatus.active && sh?.secondarySupportWorker?.id === user?.profile);

    const weekOne = groupByKey(
      allShifts.filter((s: Shift) => s?.week === '1'),
      'shiftId',
    );

    const weekTwo = groupByKey(
      allShifts.filter((s: Shift) => s?.week === '2'),
      'shiftId',
    );
    return { tbcs, allShiftRuns, allShifts, myShiftRuns, myActivities, weekOne, weekTwo };
  }, [
    filters.customer,
    filters.supportWorker,
    filters.visitType,
    filters.week,
    filters.assignment.value,
    filters.day,
    isHistoricalRota,
    teamShifts,
    user?.profile,
    filters.excludeMovedVisits.value,
  ]);
  return filterValues;
};

export { useShiftFilters };
