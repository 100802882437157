import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { formatTime } from 'services/helpers';
import { useGetReportingHours, useGetReportingHoursPeriod, useSubmitReportingHour } from 'api/hooks/useReportingHours';
import useGetTeams from 'api/hooks/useGetTeams';
import { UserContextProps, useUser } from 'context/userContext';
import { useGetSupportWorker } from 'api/hooks/useSupportWorkers';
import { ReportingHours, Rota, ReportingHoursPeriod } from '__generated__/graphql';
import PunchClock from '@mui/icons-material/PunchClock';
import { useNavigate, useParams } from 'react-router-dom';
import { LocationBar, Loading } from 'components';
import ReportingHoursProfile from './ReportingHoursProfile';
import { ReportingHourStatus } from './service';

const SubmitReportingHours = () => {
  const navigate = useNavigate();
  const { user, userLoading } = useUser() as UserContextProps;
  const { teamId, swId, id } = useParams<{ teamId: string; swId: string; id: string }>();
  const { supportWorker, loading: swLoading } = useGetSupportWorker({ id: swId });
  const { reportingHoursPeriod, loading: rhpLoading } = useGetReportingHoursPeriod({ teamId, supportWorkerId: swId });
  const { reportingHours, loading: rhLoading } = useGetReportingHours({ teamId: teamId ?? '', supportWorkerId: swId ?? '', id });
  const { submitReportingHour, mutationSubmitReportingHour } = useSubmitReportingHour({ teamId, supportWorkerId: swId });

  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const { teams, teamsLoading } = useGetTeams();

  if (userLoading || apiLoading || swLoading || rhLoading || rhpLoading || teamsLoading || mutationSubmitReportingHour.loading) {
    return <Loading />;
  }

  const updateReportingHours = (rhp: ReportingHoursPeriod, status: string) => {
    setApiLoading(true);
    submitReportingHour({
      variables: {
        input: {
          supportWorkerId: rhp.supportWorkerId,
          teamId: rhp.teamId,
          period: rhp.id,
          status,
        },
      },
    });
    setApiLoading(false);
    navigate('/reporting-hours/list');
  };

  const period = reportingHoursPeriod.find((rhp) => rhp.id === id);
  const rotas = reportingHours.filter((r) => r?.rota?.id).map((rhp: ReportingHours) => rhp?.rota) as Rota[];

  return (
    <>
      <Helmet>
        <title>Submit Reporting Hours</title>
      </Helmet>
      <LocationBar section="Reporting" page="Reporting Hours" Icon={PunchClock} />
      <div className="my-10 px-4 md:px-[5%]">
        {period?.status && period?.status !== ReportingHourStatus.submitted && (
          <button
            type="button"
            className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md mr-2"
            onClick={() => updateReportingHours(period, ReportingHourStatus.submitted)}
          >
            Yes, submit now
          </button>
        )}
        <button type="button" className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md" onClick={() => navigate(-1)}>
          No, go back and make changes
        </button>
      </div>
      <div className="my-10 px-4 md:px-[5%]">I confirm these are my correct hours and understand I will be paid based on this information I am submitting</div>
      <div className="my-10 px-4 md:px-[5%]">
        {supportWorker && (
          <ReportingHoursProfile
            teams={teams}
            user={user}
            rotas={rotas}
            reportingHoursPeriod={period}
            title={`${period?.fullName} - ${formatTime(period?.startDateTime || 0, 'PPP')} - ${formatTime(period?.endDateTime || 0, 'PPP')}`}
            reportingHours={reportingHours}
            supportWorker={supportWorker}
          />
        )}
      </div>
    </>
  );
};

export default SubmitReportingHours;
