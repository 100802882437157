import { useState, useEffect } from 'react';
import getTime from 'date-fns/getTime';
import setMilliseconds from 'date-fns/setMilliseconds';
import setSeconds from 'date-fns/setSeconds';
import { getHoursAndMinutes } from 'services/helpers';
import { AutoDelete, Delete, DeleteSweep, WarningAmber } from '@mui/icons-material';
import { Customer, AgreedVisitType } from '__generated__/graphql';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import ReactSelect from 'components/Select';
import { SelectOption } from 'types/types';
import TimePicker from 'components/TimePicker';
import DatePicker from 'components/DatePicker';
import { EditAgreedVisitModalType } from '../types';
import { createDateWithTime } from '../utils';

interface EditAgreedVisitProps {
  onClose: () => void;
  visit: AgreedVisitType | undefined;
  onEditAgreedVisit: (addVisit: EditAgreedVisitModalType) => void;
  onDeleteAgreedVisit: (id: string) => void;
  onDeleteMultipleAgreedVisit: (startDate: number | null, endDate: number | null, customerId: string) => void;
  customers: Customer[];
  agreedShiftRunStartTime: number;
  agreedShiftRunEndTime: number;
  isAbleToAdd: boolean;
  conflictedVisits: AgreedVisitType[];
  setIsAbleToAdd: (state: boolean) => void;
  isDnDEditing: boolean;
}

export default function EditAgreedVisit({
  onClose,
  visit,
  onDeleteAgreedVisit,
  onDeleteMultipleAgreedVisit,
  onEditAgreedVisit,
  customers,
  agreedShiftRunStartTime,
  agreedShiftRunEndTime,
  isAbleToAdd,
  conflictedVisits,
  setIsAbleToAdd,
  isDnDEditing,
}: EditAgreedVisitProps) {
  const [customer, setCustomer] = useState<SelectOption>({
    value: visit?.customerId ? visit.customerId : '',
    label: visit?.customer?.firstName ? `${visit?.customer?.firstName} ${visit?.customer?.lastName}` : '',
  });
  const [startDateTime, setStartDateTime] = useState<Date>(new Date());
  const [endDateTime, setEndDateTime] = useState<Date>(new Date());
  const [validation, setValidation] = useState<string | null>(null);
  const [deleteUntilDate, setDeleteUntilDate] = useState<Date | null>(new Date(visit?.startDateTime || 0));
  const customersOptions = customers.map((c) => ({ value: c.id, label: `${c.firstName} ${c.lastName}` }));

  useEffect(() => {
    if (visit) {
      if (visit.startDateTime) setStartDateTime(setMilliseconds(setSeconds(visit.startDateTime, 0), 0));
      if (visit.endDateTime) setEndDateTime(setMilliseconds(setSeconds(visit.endDateTime, 0), 0));
    }
    setValidation(null);
    setIsAbleToAdd(true);
  }, [setIsAbleToAdd, visit]);

  useEffect(() => {
    if (!isAbleToAdd) {
      setValidation('There is already a visit at this time. Visits cannot overlap.');
    }
  }, [isAbleToAdd]);

  const onChangeCustomer = (value: SelectOption) => {
    setCustomer(value);
    setValidation(null);
    setIsAbleToAdd(true);
  };

  const onChangeStartDateTime = (dateTime: Date | null) => {
    if (dateTime) {
      setStartDateTime(dateTime);
    }
    setValidation(null);
    setIsAbleToAdd(true);
  };

  const onChangeEndDateTime = (dateTime: Date | null) => {
    if (dateTime) {
      setEndDateTime(dateTime);
    }
    setValidation(null);
    setIsAbleToAdd(true);
  };

  const onEditVisit = () => {
    const agreedShiftRunStartDateTime = createDateWithTime(Date.now(), agreedShiftRunStartTime);
    const agreedShiftRunEndDateTime = createDateWithTime(Date.now(), agreedShiftRunEndTime);
    const visitStartDateTime = createDateWithTime(Date.now(), startDateTime.getTime());
    const visitEndDateTime = createDateWithTime(Date.now(), endDateTime.getTime());

    setValidation(null);
    setIsAbleToAdd(true);

    if (customer.value === '') {
      setValidation('Please select a person');
      return;
    }

    if (getTime(visitStartDateTime) >= getTime(visitEndDateTime)) {
      setValidation('Please provide a valid date time; end time should be greater than start time');
      return;
    }
    if (getTime(visitStartDateTime) < getTime(agreedShiftRunStartDateTime) || getTime(visitEndDateTime) > getTime(agreedShiftRunEndDateTime)) {
      setValidation(
        `The start time and end time must be between ${getHoursAndMinutes(agreedShiftRunStartDateTime)} and ${getHoursAndMinutes(agreedShiftRunEndDateTime)}`,
      );
      return;
    }
    if (visit?.id) {
      onEditAgreedVisit({
        id: visit?.id,
        customerId: customer.value,
        startDateTime: getTime(startDateTime),
        endDateTime: getTime(endDateTime),
      });
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto mx-auto max-w-5xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6 max-h-screen md:max-h-[90vh] overflow-y-auto md:w-[680px]">
            <div className="text-gray-900 text-lg leading-lg font-semibold mb-5">Edit or Delete this visit</div>
            {isDnDEditing && (
              <div className="flex items-center gap-3 text-md display-md text-warning-700 font-medium">
                <WarningAmber />
                Becareful your drag and drop changes will be lost. Please save your changes before adding this visit.
              </div>
            )}
            <div className="mb-5">
              <div className="text-md font-medium text-gray-700 w-auto">Select a supported person</div>
              <div className="mt-2">
                <ReactSelect options={customersOptions} onChange={onChangeCustomer} selectedValue={customer} />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-md font-medium text-gray-700 w-auto">Start time</div>
              <div className="mt-2">
                <TimePicker date={startDateTime} onChange={onChangeStartDateTime} />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-md font-medium text-gray-700 w-auto">End time</div>
              <div className="mt-2">
                <TimePicker date={endDateTime} onChange={onChangeEndDateTime} />
              </div>
            </div>
            {validation && (
              <div className="mb-3">
                <div className="text-error-700 text-lg leading-lg flex items-center gap-2">
                  <ExclamationTriangleIcon className="w-5 h-5" />
                  {validation}
                </div>
                {conflictedVisits.length > 0 && <div className="text-gray-800 text-lg leading-lg font-semibold">Conflicted visits :</div>}
                {conflictedVisits.map((v, index) => (
                  <div className="text-gray-800 text-md leading-md ml-2" key={index}>
                    {v?.customer?.firstName} {v?.customer?.lastName}: {format(v.startDateTime ?? 0, 'EEEE dd MMMM kk:mm')} -{' '}
                    {format(v.endDateTime ?? 0, 'kk:mm')}
                  </div>
                ))}
              </div>
            )}
            <div className="flex flex-row items-center justify-between gap-4 mt-3">
              <button
                type="button"
                className="text-white bg-error-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center gap-2 w-max shrink-0"
                onClick={() => onDeleteAgreedVisit(visit?.id ? visit.id : '')}
                data-cy="delete-visit-button"
              >
                <Delete />
                Delete this visit
              </button>
              <button
                type="button"
                disabled={validation !== null}
                className="text-white bg-error-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center gap-2 w-full"
                onClick={() =>
                  onDeleteMultipleAgreedVisit(visit?.startDateTime ? visit?.startDateTime : null, null, visit?.customerId ? visit?.customerId : '')
                }
                data-cy="delete-all-visits-button"
              >
                <DeleteSweep />
                Delete all agreed visits for this person
              </button>
            </div>
            <div className="flex flex-row items-center justify-between gap-4 mt-3">
              <button
                type="button"
                disabled={validation !== null}
                className="text-white bg-error-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center gap-2 shrink-0"
                onClick={() =>
                  onDeleteMultipleAgreedVisit(visit?.startDateTime ? visit?.startDateTime : null, null, visit?.customerId ? visit?.customerId : '')
                }
                data-cy="delete-all-visits-button"
              >
                <AutoDelete />
                Delete all agreed visits until :
              </button>
              <DatePicker value={deleteUntilDate} onChange={(newValue) => setDeleteUntilDate(newValue)} minDate={new Date(visit?.startDateTime || 0)} />
            </div>
            <div className="flex justify-between items-center mt-7">
              <button
                type="button"
                className="text-gray-500 font-semibold text-md leading-md"
                onClick={() => {
                  setIsAbleToAdd(true);
                  onClose();
                }}
              >
                Cancel
              </button>
              <div className="flex items-center gap-3">
                <button
                  type="button"
                  disabled={validation !== null}
                  className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
                  onClick={onEditVisit}
                  data-cy="add-agreed-visit-button"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-gray-900" />
    </>
  );
}
