import ReactSelect from 'components/Select';
import { addHours, addDays, startOfWeek, getTime, addWeeks } from 'date-fns';
import { useState } from 'react';
import { formatTime } from 'services/helpers';

interface CreateNewTeamAgreedRotaProps {
  onCreateAgreedRota: (startDate: number) => void;
}

const CreateNewTeamAgreedRota = ({ onCreateAgreedRota }: CreateNewTeamAgreedRotaProps) => {
  const startDate = addHours(addDays(startOfWeek(Date.now()), 1), 1);
  const weekOne = getTime(addWeeks(startDate, -2));
  const weekTwo = getTime(addWeeks(startDate, -1));
  const weekThree = getTime(startDate);
  const weekFour = getTime(addWeeks(startDate, 1));
  const weekFive = getTime(addWeeks(startDate, 2));
  const [startDateTime, setStartDateTime] = useState({ value: weekThree.toString(), label: formatTime(weekThree, 'do MMMM') });
  const startDateOptions = [
    { value: weekOne.toString(), label: formatTime(weekOne, 'do MMMM') },
    { value: weekTwo.toString(), label: formatTime(weekTwo, 'do MMMM') },
    { value: weekThree.toString(), label: formatTime(weekThree, 'do MMMM') },
    { value: weekFour.toString(), label: formatTime(weekFour, 'do MMMM') },
    { value: weekFive.toString(), label: formatTime(weekFive, 'do MMMM') },
  ];

  return (
    <div className="flex items-center gap-3">
      <ReactSelect options={startDateOptions} onChange={setStartDateTime} selectedValue={startDateTime} testId="select-rota-start-date" searchable />
      <button
        className="bg-primary-700 rounded-lg text-white text-md leading-md font-medium flex items-center gap-2 px-3 py-2.5 disabled:opacity-50"
        type="button"
        disabled={!startDateTime}
        onClick={() => onCreateAgreedRota(parseInt(startDateTime.value, 10))}
        data-cy="create-agreed-rota-button"
      >
        Create Rota template
      </button>
    </div>
  );
};

export default CreateNewTeamAgreedRota;
