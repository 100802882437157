import React, { useState } from 'react';
import {
  Avatar,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Checkbox,
  Button,
  Accordion,
} from '@mui/material';
import { Buddie } from '__generated__/graphql';

interface CreateConfirmationPracticesGroupProps {
  onSaveGroup: (isSolo: boolean, selectedBuddies: string[]) => void;
  buddies: Buddie[];
}

export default function CreateConfirmationPracticesGroup({ buddies, onSaveGroup }: CreateConfirmationPracticesGroupProps) {
  const [isSolo, setIsSolo] = useState<boolean>(true);
  const [selectedBuddies, setSelectedBuddies] = React.useState<string[]>([]);

  const handleSelectBuddy = (id: string) => {
    const currentIndex = selectedBuddies.indexOf(id);
    const newBuddieList = [...selectedBuddies];

    if (currentIndex === -1) {
      newBuddieList.push(id);
    } else {
      newBuddieList.splice(currentIndex, 1);
    }

    setSelectedBuddies(newBuddieList);
  };

  const handleIsSolo = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'solo') {
      setIsSolo(true);
      setSelectedBuddies([]);
    } else {
      setIsSolo(false);
    }
  };

  const onNewGroup = () => {
    onSaveGroup(isSolo, selectedBuddies);
  };

  return (
    <Accordion expanded sx={{ p: '1em' }}>
      <Typography sx={{ pb: '1em', pt: '1em' }} fontWeight="bold">
        Are you reflecting on the Confirmation Practices statements with buddies or by yourself?
      </Typography>
      <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="female" name="radio-buttons-group">
        <FormControlLabel value="solo" control={<Radio checked={isSolo} onChange={handleIsSolo} />} label="By myself" />
        <FormControlLabel value="withBuddy" control={<Radio checked={!isSolo} onChange={handleIsSolo} />} label="With my buddies" />
      </RadioGroup>

      {!isSolo && (
        <List sx={{ width: '100%', maxWidth: 500 }}>
          {buddies.map((buddy) => {
            return (
              <ListItem key={buddy.id} disablePadding>
                <ListItemButton>
                  <ListItemAvatar>
                    <Avatar alt={`${buddy.fullName}`} src={`/static/images/avatar/${buddy.avatar}.jpg`} />
                  </ListItemAvatar>
                  <ListItemText primary={`${buddy.fullName}`} />
                  <FormControlLabel
                    sx={{ width: '50%' }}
                    label=""
                    control={<Checkbox onChange={() => handleSelectBuddy(buddy.id || '')} checked={selectedBuddies?.includes(buddy.id || '')} />}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      )}

      <Button sx={{ mt: '2em' }} onClick={onNewGroup} variant="contained">
        Start Confirmation Practices
      </Button>
    </Accordion>
  );
}
