import React from 'react';

interface CheckboxProps {
  label: string;
  checked: boolean;
  onChange: (value: boolean) => void;
}

const Checkbox = ({ label, checked, onChange }: CheckboxProps) => {
  return (
    <div className="flex items-center gap-4 mb-5 text-md leading-md font-semibold text-gray-800 shrink-0">
      <input
        className="w-4 h-4 text-primary-300 bg-gray-100 border-gray-300 rounded cursor-pointer"
        name="checkbox"
        checked={checked}
        onChange={(value) => onChange(value.target.checked)}
        type="checkbox"
      />
      <label htmlFor="checkbox">{label}</label>
    </div>
  );
};

export default Checkbox;
