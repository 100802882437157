import { useQuery, gql, useMutation } from '@apollo/client';
import { TeamMeeting, TeamMeetingsSearch } from '../../__generated__/graphql';

const GET_TEAM_MEETINGS = gql`
  query GetTeamMeetings($query: TeamMeetingsSearch) {
    GetTeamMeetings(query: $query) {
      id
      teamId
      date
      apologies {
        supportWorkerId
        reason
      }
      attendees
      note {
        note
        actions {
          action
          ownerId
          status
        }
      }
    }
  }
`;

interface GetTeamMeetingsResponse {
  GetTeamMeetings: TeamMeeting[];
}

const SAVE_TEAM_MEETING = gql`
  mutation SaveTeamMeeting($input: SaveTeamMeetingInput) {
    SaveTeamMeeting(input: $input) {
      id
      message
      date
    }
  }
`;

const UPDATE_TEAM_MEETING = gql`
  mutation UpdateTeamMeeting($input: UpdateTeamMeetingInput) {
    UpdateTeamMeeting(input: $input) {
      id
      message
      date
    }
  }
`;

export const useUpdateTeamMeeting = (query: TeamMeetingsSearch) => {
  const [updateTeamMeeting, mutationUpdateTeamMeeting] = useMutation(UPDATE_TEAM_MEETING, {
    refetchQueries: [
      {
        query: GET_TEAM_MEETINGS,
        variables: {
          query: { ...query },
        },
      },
    ],
  });

  return { updateTeamMeeting, mutationUpdateTeamMeeting };
};
export const useSaveTeamMeeting = () => {
  const [saveTeamMeeting, mutationSaveTeamMeeting] = useMutation(SAVE_TEAM_MEETING, {});
  return { saveTeamMeeting, mutationSaveTeamMeeting };
};

export const useGetTeamMeetings = ({ teamId = '' }: TeamMeetingsSearch) => {
  const { data, refetch, loading } = useQuery<GetTeamMeetingsResponse>(GET_TEAM_MEETINGS, {
    variables: {
      query: {
        teamId,
      },
    },
  });

  return { meetings: data?.GetTeamMeetings || [], refetch, loading };
};
