import React, { useState, useEffect, useMemo } from 'react';
import { useUser, UserContextProps } from 'context/userContext';
import { useParams, useNavigate } from 'react-router-dom';
import Helmet from 'react-helmet';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { LocationBar } from 'components';
import { List } from '@mui/icons-material';
import { useGetAnswers, useSaveAnswer } from 'api/hooks/useConfirmationPractices';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import Form from './Form';
import questions from './questions.json';

export default function ManageConfirmationPractices() {
  const { user, userLoading } = useUser() as UserContextProps;

  const theme = useTheme();
  const navigate = useNavigate();
  const { groupId } = useParams<{ groupId?: string }>();
  const [id, setId] = useState<string>();
  const [questionId, setQuestionId] = useState<string>();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [whyScore, setWhyScore] = useState<string>('');
  const [focus, setFocus] = useState<string>('');
  const [score, setScore] = useState<number>(3);
  const [createdDateTime, setDateTime] = useState<number>(Date.now());

  const maxSteps = questions.length;

  useEffect(() => {
    if (activeStep !== maxSteps) {
      setQuestionId(questions[activeStep].id);
    } else {
      setActiveStep(0);
    }
  }, [maxSteps, activeStep, questionId]);

  const { saveAnswer, mutationLoading, mutationError } = useSaveAnswer();
  const { answers, answerError, answerLoading } = useGetAnswers({
    supportWorkerId: user?.profile || '',
    groupId,
    questionId,
  });
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setQuestionId(questions[activeStep].id);
  };

  const goBack = () => {
    navigate('/confirmation-practices');
  };

  useMemo(() => {
    if (answers) {
      const answer = answers[0];
      if (answer) {
        setId(answer.id);
        setWhyScore(answer.whyScore);
        setScore(answer.score);
        setFocus(answer.focus);
        setDateTime(answer.createdDateTime);
      } else {
        setId('');
        setWhyScore('');
        setWhyScore('');
        setScore(3);
        setFocus('');
      }
    }
  }, [answers]);

  if (answerLoading || userLoading || mutationLoading) return <Loading />;
  if (answerError || mutationError) return <Error />;

  const handleNext = async () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setQuestionId(questions[activeStep].id);
    await saveAnswer({
      variables: {
        input: {
          id,
          groupId,
          questionId: questions[activeStep].id,
          supportWorkerId: user?.profile,
          whyScore,
          score,
          focus,
          createdDateTime,
        },
      },
    });

    if (activeStep + 1 === maxSteps) {
      navigate('/confirmation-practices');
    }
  };

  return (
    <>
      <Helmet>
        <title>Confirmation Practices</title>
      </Helmet>
      <LocationBar section="Policies & Practices" page="Confirmation Practices" Icon={List} />
      <div className="section">
        <div className="content">
          {answers && (
            <>
              <Form
                whyScore={whyScore}
                setWhyScore={setWhyScore}
                focus={focus}
                setFocus={setFocus}
                score={score}
                setScore={setScore}
                dateTime={createdDateTime}
                question={questions[activeStep].question}
                title={questions[activeStep].title}
                checkList={questions[activeStep].checkList}
                goBack={goBack}
              />
              <MobileStepper
                sx={{ mt: '1em', border: '1px solid #eee' }}
                variant="dots"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                  <Button sx={{ fontSize: '0.7em' }} size="small" onClick={handleNext} disabled={activeStep === maxSteps}>
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    {activeStep !== maxSteps - 1 ? 'Save & Next' : 'Save & Complete'}
                  </Button>
                }
                backButton={
                  <Button sx={{ fontSize: '0.7em' }} size="small" onClick={handleBack} disabled={activeStep === 0}>
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                    Previous
                  </Button>
                }
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}
