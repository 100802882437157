import { format } from 'date-fns';
import { useMemo } from 'react';
import { Customer } from '__generated__/graphql';
import { Chart } from 'react-google-charts';
import { Grid } from '@mui/material';
import { useReviewStats } from './useReviewStats';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const ReviewStatsCharts = ({ rows }: { rows: Customer[] }) => {
  const { dueSoon, notYetDue, overdue, upToDate } = useReviewStats(rows);
  const pieChart = [
    ['Team', 'Supported person'],
    ['Due soon', dueSoon],
    ['Not yet due', notYetDue],
    ['Up to date', upToDate],
    ['Overdue', overdue],
  ];

  const pieChartOptions = {
    title: 'Reviews status',
    is3D: true,
    colors: ['#FF6D01', '#B6D7A8', '#008400', '#FF0000'],
    pieSliceText: 'value',
  };

  const barChartOptions = {
    chart: {
      title: 'Number of reviews / month',
    },
    legend: { position: 'none' },
  };

  const numberOfReviews: [string, number | string][] = useMemo(() => {
    const customerReviewNumber: [string, number | string][] = [['', 'Number of Reviews']];
    rows?.forEach((customer: Customer) => {
      customer.reviews?.forEach((review) => {
        const index = customerReviewNumber.findIndex((r) => r[0] === format(review?.date ?? 0, 'LLL yyyy'));
        if (index === -1) {
          customerReviewNumber.push([format(review?.date ?? 0, 'LLL yyyy'), 1]);
        } else {
          customerReviewNumber[index] = [format(review?.date ?? 0, 'LLL yyyy'), (customerReviewNumber[index][1] as number) + 1];
        }
      });
    });
    customerReviewNumber.sort((a, b) => {
      const dateA = a[0].split(' ');
      const dateB = b[0].split(' ');
      if (dateA[1] !== dateB[1]) {
        return parseInt(dateA[1], 10) - parseInt(dateB[1], 10);
      } else {
        return months.indexOf(dateA[0]) - months.indexOf(dateB[0]);
      }
    });
    return customerReviewNumber;
  }, [rows]);

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Chart chartType="PieChart" data={pieChart} options={pieChartOptions} width="100%" height="400px" />
      </Grid>
      <Grid item xs={12} md={6}>
        <Chart chartType="Bar" width="100%" height="400px" data={numberOfReviews} options={barChartOptions} />
      </Grid>
    </Grid>
  );
};
