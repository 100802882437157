import { useState } from 'react';
import DatePicker from 'components/DatePicker';
import { CustomerPackage } from '__generated__/graphql';
import { setEndOfDay } from './service';

interface PackageEndProps {
  customerPackage: CustomerPackage;
  onClose: () => void;
  onUpdate: (data: CustomerPackage) => void;
}

export default function PackagePriceEnd({ customerPackage, onClose, onUpdate }: PackageEndProps) {
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const onSavePackage = () => {
    if (endDate) {
      const newDate = setEndOfDay(endDate);
      const prices = customerPackage.prices?.map((pp) => ({ ...pp, newDate }));

      onUpdate({
        ...customerPackage,
        prices,
        endDate: newDate,
      });
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-[1200] outline-none focus:outline-none">
        <div className="relative w-auto mx-auto max-w-5xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none p-6 max-h-screen md:max-h-[70vh] overflow-y-auto">
            <div className="text-gray-900 text-lg leading-lg font-semibold mb-5">End Package</div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">End Date</div>
              <div className="mt-2">
                <DatePicker testId="package-price-end-date" value={endDate ?? undefined} onChange={setEndDate} />
              </div>
            </div>
            <div className="flex justify-between items-center mt-5">
              <button type="button" className="text-gray-500 font-semibold text-md leading-md" onClick={onClose}>
                Cancel
              </button>
              <button
                type="button"
                data-cy="save-package-button"
                className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
                onClick={onSavePackage}
              >
                End
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-gray-900" />
    </>
  );
}
