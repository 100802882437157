import React, { useEffect, useState } from 'react';
import { getTime, add } from 'date-fns';
import ReactSelect from 'components/Select';
import TeamSelect from 'components/TeamSelect';
import Input from 'components/Input';
import { formatTime, getFirstDayOfPayroll, getLastDayOfPayroll, getPeriodsForYear } from 'services/helpers';
import { SelectOption } from 'types/types';
import { useCreateInvoiceForAll } from 'api/hooks/useInvoice';

interface InvoiceCreateAllProps {
  onClose: () => void;
}

export default function InvoiceCreateAll({ onClose }: InvoiceCreateAllProps) {
  const periods = getPeriodsForYear().map((period) => {
    return { label: formatTime(period, 'dd MMMM, yyyy'), value: period.toString() };
  });

  const [period, setPeriod] = useState<SelectOption>(periods[0]);
  const [endDateTime, setEndDAteTime] = useState<string>('');
  const [invoiceNumber, setInvoiceNumber] = useState<string>('');
  const [validation, setValidation] = useState<string | null>(null);
  const [teamId, setTeamId] = useState('all');
  const date = getFirstDayOfPayroll(parseFloat(period.value.toString()));
  const { saveInvoice } = useCreateInvoiceForAll(date);

  useEffect(() => {
    if (period) {
      const payrollStartDate = getFirstDayOfPayroll(parseFloat(period.value.toString()));
      const payrollEndDate = getLastDayOfPayroll(payrollStartDate);
      setEndDAteTime(formatTime(payrollEndDate, 'dd MMMM, yyyy'));
    }
  }, [period]);

  const cleanInvoiceNumber = (inv: string) => {
    const cleaned = inv.match(/[^0-9]*([0-9]+)/);
    if (!cleaned) return '';
    return cleaned[1];
  };

  function increment(inv: string): string {
    const clean = cleanInvoiceNumber(inv);
    if (!inv || !clean) return '';
    const incrementedNumber = parseInt(clean, 10) + 1;
    const originalLength = clean.length;
    let incrementedStr = incrementedNumber.toString();

    while (incrementedStr.length < originalLength) {
      incrementedStr = `0${incrementedStr}`;
    }

    return incrementedStr;
  }

  const onSavePackage = async () => {
    setValidation(null);

    if (!cleanInvoiceNumber(invoiceNumber) || !increment(invoiceNumber)) {
      setValidation('Please provide an invoice number');
    }

    const payrollStartDate = getFirstDayOfPayroll(parseFloat(period.value.toString()));
    const payrollEndDate = getLastDayOfPayroll(payrollStartDate);
    const invoiceDate = getTime(add(payrollEndDate, { days: 3 }));
    const dueDate = getTime(add(payrollEndDate, { days: 10 }));

    await saveInvoice({
      awaitRefetchQueries: true,
      variables: {
        input: {
          teamId,
          payrollStartDate,
          payrollEndDate,
          invoiceDate,
          dueDate,
          invoiceNumber,
          invoiceType: 'Care',
        },
      },
    });
    onClose();
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-[1200] outline-none focus:outline-none">
        <div className="relative w-auto mx-auto max-w-5xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none p-6 max-h-screen md:max-h-[70vh] overflow-y-auto">
            <div className="text-gray-900 text-lg leading-lg font-semibold mb-5">Create Invoices For All</div>
            <div className="text-red-500 text-sm leading-lg mb-5">{validation}</div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Period</div>
              <div className="mt-2">
                <ReactSelect testId="invoice-period" options={periods} onChange={setPeriod} selectedValue={period} searchable={true} />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">End Date</div>
              <div className="mt-2">
                <Input value={endDateTime} disabled />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Team</div>
              <div className="mt-2">
                <TeamSelect onSelectedTeam={setTeamId} selectedTeamId={teamId || ''} showAll={true} />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Starting Invoice Number</div>
              <div className="mt-2">
                <Input placeholder="INV-000" value={invoiceNumber} onChange={setInvoiceNumber} testId="invoice-number" />
              </div>
              <div className="mt-2 text-xs">Next: INV-{cleanInvoiceNumber(invoiceNumber)}</div>
              <div className="mt-2 text-xs">Next+1: INV-{increment(invoiceNumber)}</div>
            </div>
            <div className="flex justify-between items-center mt-5">
              <button type="button" className="text-gray-500 font-semibold text-md leading-md" onClick={onClose}>
                Cancel
              </button>
              <button
                type="button"
                data-cy="save-package-button"
                className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
                onClick={onSavePackage}
              >
                Create All
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-gray-900" />
    </>
  );
}
