import { useState, useMemo, useEffect } from 'react';
import { useUser, UserContextProps } from 'context/userContext';
import { useGeneric, GenericContextProps } from 'context/genericContext';
import Helmet from 'react-helmet';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { AdjustmentsHorizontalIcon, ArrowUpTrayIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Loading, Filters, Table } from 'components';
import { SelectOption } from 'types/types';
import { ReportingHoursPeriod } from '__generated__/graphql';
import { useGetAllReportingHoursPeriod } from 'api/hooks/useReportingHours';
import { formatHoursAndMinutes, getPeriodsForYear, formatTime, getPreviousMonth } from 'services/helpers';
import { CSVLink } from 'react-csv';
import { useSearchParams } from 'react-router-dom';
import Navigation from './Navigation';

export default function SupportWorkerReport() {
  const { user, userLoading } = useUser() as UserContextProps;
  const { teams: genericTeams } = useGeneric() as GenericContextProps;
  const [searchParams, setSearchParams] = useSearchParams();

  const periods = getPeriodsForYear().map((period) => {
    return { label: formatTime(period, 'MMMM, yyyy'), value: period.toString() };
  });

  const teams = useMemo(() => {
    return genericTeams?.map((t) => ({ value: t.teamId, label: t.teamName })) || [];
  }, [genericTeams]);

  const [period, setPeriod] = useState<string>(periods[0].value);
  const [team, setTeam] = useState<string>(user?.teamId || '');
  const { reportingHoursPeriod, loading: rhpLoading } = useGetAllReportingHoursPeriod({
    teamId: team,
    period: getPreviousMonth(parseInt(period, 10)),
  });
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
  const [filters, setFilters] = useState([
    { name: 'period', selectedValue: periods[0], values: periods },
    {
      name: 'team',
      selectedValue: user?.teamName ? { value: user?.teamId, label: user.teamName } : teams[0],
      values: [{ value: 'all', label: 'All' }, ...teams],
    },
  ]);

  useEffect(() => {
    const teamSearch = searchParams.get('team');
    const periodSearch = searchParams.get('period');
    const newFilters = filters;
    if (teamSearch) {
      setTeam(teamSearch);
      const teamOption = genericTeams?.map((t) => ({ value: t.teamId, label: t.teamName })).find((t) => t.value === teamSearch);
      if (teamOption) {
        newFilters[1].selectedValue = teamOption;
      }
    }
    if (periodSearch) {
      setPeriod(periodSearch);
      const periodOption = periods.find((t) => t.value === periodSearch);
      if (periodOption) {
        newFilters[0].selectedValue = periodOption;
      }
    }
    setFilters(newFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genericTeams]);

  useEffect(() => {
    filters[1].values = [{ value: 'all', label: 'All' }, ...teams];

    setFilters(filters);
  }, [filters, teams]);

  const onFilterChange = (name: string, value: SelectOption) => {
    const newFilters = [...filters];
    const filterIndex = newFilters.findIndex((f) => f.name === name);
    newFilters[filterIndex].selectedValue = value;
    setFilters(newFilters);
  };

  const rows = reportingHoursPeriod?.map((rhp: ReportingHoursPeriod) => {
    const banked = formatHoursAndMinutes(rhp?.bankedHours || 0);
    const visits = formatHoursAndMinutes(rhp?.timeOnVisits || 0);
    const activities = formatHoursAndMinutes(rhp?.timeOnActivities || 0);

    return {
      teamName: rhp.teamName,
      name: rhp.fullName,
      bankedHours: `${banked.hours} ${banked.minutes}`,
      timeOnVisits: `${visits.hours} ${visits.minutes}`,
      timeOnActivities: `${activities.hours} ${activities.minutes}`,
      overtime: rhp?.overtime,
      annualLeaveBooked: rhp?.annualLeave,
      sickLeaveBooked: rhp?.sickLeave,
      meetings: rhp?.meetings,
      milesTravelled: `${rhp.mileage?.toFixed(2)}`,
      milesInCarTravelled: `${rhp?.mileageWithCustomer?.toFixed(2)}`,
      status: rhp.status,
    };
  });

  const columnHelper = createColumnHelper<ReportingHoursPeriod>();

  const columns1 = useMemo(() => {
    return [
      columnHelper.accessor('teamName', {
        header: () => 'Team',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('fullName', {
        header: () => 'Name',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('status', {
        header: () => 'Status',
        cell: (props) => <span>{`${props.getValue()}`}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('bankedHours', {
        header: () => 'Banked Hours',
        cell: (props) => {
          const banked = formatHoursAndMinutes(props.row.original?.bankedHours || 0);

          return <span>{`${banked.hours}h${banked.minutes}`}</span>;
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('timeOnVisits', {
        header: () => 'Time On Visits',
        cell: (props) => {
          const visits = formatHoursAndMinutes(props.row.original?.timeOnVisits || 0);

          return <span>{`${visits.hours}h${visits.minutes}`}</span>;
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('timeOnActivities', {
        header: () => 'Time On Activities',
        cell: (props) => {
          const activities = formatHoursAndMinutes(props.row.original?.timeOnActivities || 0);

          return <span>{`${activities.hours}h${activities.minutes}`}</span>;
        },
        footer: (info) => info.column.id,
      }),

      columnHelper.accessor('overtime', {
        header: () => 'Overtime',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('annualLeaveBooked', {
        header: () => 'Annual Leave Booked',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('sickLeaveBooked', {
        header: () => 'Sick Leave Booked',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('meetings', {
        header: () => 'Meetings',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('mileage', {
        header: () => 'Miles Travelled',
        cell: (props) => <span>{`${props.getValue()?.toFixed(2)}`}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('mileageWithCustomer', {
        header: () => 'Miles In Car Travelled',
        cell: (props) => <span>{`${props.getValue()?.toFixed(2)}`}</span>,
        footer: (info) => info.column.id,
      }),
    ] as Array<ColumnDef<ReportingHoursPeriod, unknown>>;
  }, [columnHelper]);

  const onSaveFilters = () => {
    const newSearchParamsArray = filters.map((f) => ({ [f.name]: f.selectedValue.value }));
    setSearchParams(Object.assign({}, ...newSearchParamsArray));
    setTeam(filters[1].selectedValue.value);
    setPeriod(filters[0].selectedValue.value);

    setShowFiltersModal(false);
  };

  if (rhpLoading || userLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Support Worker Report</title>
      </Helmet>
      <Navigation report="Support Worker Report" />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-display-lg leading-display-lg tracking-display-lg text-black font-medium">Support Worker Report</div>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-between mt-8 md:mt-16">
          <div className="flex flex-col md:flex-row items-center gap-4 md:gap-9 w-full md:w-fit">
            <div className="flex flex-col md:flex-row items-center gap-3 w-full md:w-fit">
              <button
                data-cy="show-filters-button"
                type="button"
                className={`relative border ${
                  team === 'all' && period === '0' ? 'border-gray-300' : 'border-gray-900'
                }  text-gray-700 text-md leading-md flex items-center px-4 py-2.5 rounded-lg shadow-xs justify-center w-full md:w-fit`}
                onClick={() => setShowFiltersModal(true)}
              >
                <AdjustmentsHorizontalIcon className="w-5 h-5 mr-2" />
                Filters
                {team !== 'all' && period !== '0' && (
                  <span className="absolute bg-gray-900 h-5 w-5 text-white rounded-full -top-2 -right-2 flex items-center justify-center text-xs font-semibold">
                    {team !== 'all' && period !== '0' ? '2' : '1'}
                  </span>
                )}
              </button>

              {team !== 'all' && (
                <div
                  data-cy="tag-2"
                  className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center"
                >
                  {teams.find((type) => type.value === team)?.label}
                  <button
                    data-cy="remove-tag-2"
                    type="button"
                    aria-label="remove overdue filter"
                    className="text-gray-500"
                    onClick={() => {
                      onFilterChange('team', { value: 'all', label: 'All' });
                      const newSearchParamsArray = filters.map((f) => ({ [f.name]: f.selectedValue.value }));
                      setSearchParams(Object.assign({}, ...newSearchParamsArray));
                      setTeam('all');
                    }}
                  >
                    <XMarkIcon className="w-4 h-4 ml-2" />
                  </button>
                </div>
              )}
              {period !== '0' && (
                <div
                  data-cy="tag-1"
                  className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center"
                >
                  {periods.find((type) => type.value === period)?.label}
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between lg:justify-normal gap-3 md:gap-9 w-full lg:w-fit mt-6 lg:mt-0">
            <CSVLink
              filename="supportWorkerReport.csv"
              data={rows}
              className="text-gray-500 text-sm leading-sm font-semibold flex items-center hover:no-underline"
            >
              <ArrowUpTrayIcon className="w-5 h-5 mr-2" />
              Export CSV
            </CSVLink>
          </div>
        </div>
        <div className="mt-3 md:mt-12 mb-10 overflow-x-auto overflow-y-hidden">
          {reportingHoursPeriod && reportingHoursPeriod.length > 0 && <Table<ReportingHoursPeriod> data={reportingHoursPeriod} columns={columns1} />}
        </div>
      </div>
      {showFiltersModal && (
        <Filters
          filters={filters}
          onFilterChange={onFilterChange}
          onCloseModal={() => setShowFiltersModal(false)}
          onSaveFilters={onSaveFilters}
          showClearFilters={false}
        />
      )}
    </>
  );
}
