import { isAfter, sub } from 'date-fns';
import { postcodeValidator } from 'postcode-validator';

function isValidEmail(value: string) {
  const re = /\S+@\S+\.\S+/;
  if (re.test(value)) {
    return true;
  } else {
    return 'Please enter valid email';
  }
}

function isValueRequired(value: string) {
  if (value?.toString()?.trim().length > 0) {
    return true;
  } else {
    return 'This is required';
  }
}

// function isValidPhone(value: string, countryCode: string) {
//   const phone = parsePhoneNumber(value, countryCode as CountryCode);
//   if (phone?.isValid()) {
//     return true;
//   } else {
//     return 'Please enter valid phone number';
//   }
// }

const isPostcodeValid = (value: string) => {
  if (!value) {
    return true;
  } else if (postcodeValidator(value, 'GB')) {
    return true;
  } else {
    return 'Please enter valid UK postcode';
  }
};

const isValidDOB = (value: Date) => {
  const result = sub(new Date(), { years: 16 });
  if (!value) {
    return true;
  } else if (isAfter(result, value)) {
    return true;
  } else {
    return 'Candidate must be 16 and over';
  }
};
export { isValueRequired, isValidEmail, isPostcodeValid, isValidDOB };
