/* eslint-disable max-len */
import TWModal from 'components/TWModal';

interface EditWellbeingStatusProps {
  toggleWellbeingStatusModal: (state: boolean) => void;
  onEditStatus: (status: string) => void;
}

export default function EditWellbeingStatus({ toggleWellbeingStatusModal, onEditStatus }: EditWellbeingStatusProps) {
  const onCloseModal = () => {
    toggleWellbeingStatusModal(false);
  };

  const onStatusClicked = (status: string) => {
    onEditStatus(status);
    toggleWellbeingStatusModal(false);
  };

  return (
    <TWModal title="Change status" onClose={onCloseModal}>
      <button
        type="button"
        className="border border-success-600 rounded-lg text-success-600 hover:bg-success-600 hover:text-white text-display-xs sm:text-display-sm leading-display-xs sm:leading-display-sm uppercase font-semibold w-full px-4 py-6"
        onClick={() => onStatusClicked('On Track')}
        data-cy="on-track-status-button"
      >
        ON TRACK
      </button>
      <button
        type="button"
        className="border border-error-600 rounded-lg text-error-600 hover:bg-error-600 hover:text-white text-display-xs sm:text-display-sm leading-display-xs sm:leading-display-sm uppercase font-semibold w-full px-4 py-6 mt-2"
        onClick={() => onStatusClicked('Off Track')}
        data-cy="off-track-status-button"
      >
        OFF TRACK
      </button>
      <button
        type="button"
        className="border border-warning-600 rounded-lg text-warning-600 hover:bg-warning-600 hover:text-white text-display-xs sm:text-display-sm leading-display-xs sm:leading-display-sm uppercase font-semibold w-full px-4 py-6 mt-2"
        onClick={() => onStatusClicked('Slightly Off Track')}
        data-cy="slightly-off-track-status-button"
      >
        SLIGHTLY OFF TRACK
      </button>
      <button
        type="button"
        className="border border-gray-600 rounded-lg text-gray-600 hover:bg-gray-600 hover:text-white text-display-xs sm:text-display-sm leading-display-xs sm:leading-display-sm uppercase font-semibold w-full px-4 py-6 mt-2"
        onClick={() => onStatusClicked('Paused')}
        data-cy="paused-status-button"
      >
        PAUSED
      </button>
    </TWModal>
  );
}
