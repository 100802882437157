import React, { useState, useEffect, useRef, ReactInstance } from 'react';
import addDays from 'date-fns/addDays';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import { SelectOption, User } from 'types/types';
import { useGetCustomers } from 'api/hooks/useCustomers';
import {
  Maybe,
  Shift as ShiftType,
  ShiftUpdateItem,
  AddActivityToShiftInput,
  SupportWorker as SupportWorkerType,
  Customer as CustomerType,
  ShiftInputItem,
  CancelVisitInput,
} from '__generated__/graphql';
import { useGetSupportWorkers } from 'api/hooks/useSupportWorkers';
import {
  useAddActivityToShift,
  useAddVisitToShift,
  useDeleteActivityToShift,
  useGetTeamShifts,
  useMoveVisitToShift,
  useSaveShift,
  useUpdateActivity,
  useUpdateShift,
  useRevertVisit,
  useChangeShiftType,
  useCancelVisit,
  useChangeShiftTime,
} from 'api/hooks/useTeamShifts';
import EditVisit from '../modals/EditVisit';
import EditSupportWorker from '../modals/EditSupportWorker';
import RemoveVisit from '../modals/RemoveVisit';
import CancelVisit from '../modals/CancelVisit';
import EnableVisit from '../modals/EnableVisit';
import CreateNewShift from '../modals/CreateNewShift';
import AddVisitToShift from '../modals/AddVisitToShift';
import Customer from './Customer';
import SupportWorker from './SupportWorker';
import Filter from '../Filter';
import Message from '../../../components/Message';
import Loading from '../../../components/Loading';
import Tbc from '../../../components/Tbc';
import ManageActivity from '../modals/ManageActivity';
import RemoveActivity from '../modals/RemoveActivity';
import EditActivity from '../modals/EditActivity';
import AddActivityToShift from '../modals/AddActivityToShift';
import { formatTime } from '../../../services/helpers';
import Shift from '../Shift';
import { useShiftFilters } from '../hooks/useShiftFilters';
import ChangeShiftType from '../modals/ChangeShiftType';
import ChangeShiftTime from '../modals/ChangeShiftTime';

interface ShiftsListProps {
  user: User;
  team: string;
  rotaId: string;
  rotaStart: number;
  createShiftModalState: boolean;
  toggleCreateShiftModal: (createShiftModalState: boolean) => void;
  createActivityModalState: boolean;
  toggleCreateActivityModal: (createActivityModalState: boolean) => void;
}

export interface EditShiftTravelTime {
  id: string;
  mileage: Maybe<number> | undefined;
  travelWithCustomerMileage: Maybe<number> | undefined;
  travelWithCustomerDestination: Maybe<string> | undefined;
}

interface onSaveVisitProps {
  secondarySupportWorker: {
    id: string | null;
  };
}
export default function ShiftsList({
  user,
  team,
  rotaId,
  rotaStart,
  createShiftModalState,
  toggleCreateShiftModal,
  createActivityModalState,
  toggleCreateActivityModal,
}: ShiftsListProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<Record<string, SelectOption>>({
    assignment: { value: 'all', label: 'Next Visits' },
    day: { value: 'all', label: 'All Days' },
    week: { value: 'all', label: 'All Weeks' },
    customer: { value: 'all', label: 'All Persons' },
    visitType: { value: 'all', label: 'All Activities' },
    supportWorker: { value: 'all', label: 'All Colleagues' },
    excludeMovedVisits: { value: 'include', label: 'Include Moved' },
  });

  const [editVisitModalState, toggleEditModal] = useState<boolean>(false);
  const [removeVisitModalState, toggleRemoveModal] = useState<boolean>(false);
  const [cancelVisitModalState, toggleCancelModal] = useState<boolean>(false);
  const [enableVisitModalState, toggleEnableModal] = useState<boolean>(false);
  const [toggledShiftId, setToggledShiftId] = useState<string | null>('');

  const [addVisitToShiftModalState, toggleAddVisitToShiftModal] = useState<boolean>(false);
  const [removeActivityVisitModalState, toggleRemoveActivityModal] = useState<boolean>(false);
  const [editActivityModalState, toggleEditActivityModal] = useState<boolean>(false);
  const [addActivityToShiftModalState, toggleAddActivityToShiftModal] = useState<boolean>(false);
  const [selectedShift, setSelectedShift] = useState<ShiftType | null>(null);
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [editSupportWorkerModalState, toggleSupportWorkerModal] = useState<boolean>(false);
  const [changeShiftTypeModalState, toggleChangeShiftTypeModal] = useState<boolean>(false);
  const [changeShiftTimeModalState, toggleChangeShiftTimeModal] = useState<boolean>(false);
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const componentRef = useRef<ReactInstance | null>(null);

  const scroll = (id: string) => {
    const offset = 100; // sticky nav height
    const element = document.getElementById(id);
    if (element) {
      window.scroll({ top: element.offsetTop - offset, left: 0, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (toggledShiftId) {
      scroll(toggledShiftId);
    }

    if (toggledShiftId) {
      scroll(toggledShiftId);
    }
  }, [toggledShiftId]);

  const {
    loading: teamShiftsLoading,
    error: teamShiftsError,
    teamShifts,
  } = useGetTeamShifts({
    teamId: team,
    rotaId,
  });

  const {
    loading: colleaguesLoading,
    error: colleaguesError,
    supportWorkers: supportWorkersFull,
  } = useGetSupportWorkers({
    teamId: team,
    rotaId,
  });

  const {
    loading: customersLoading,
    error: customersError,
    customers: customersData,
  } = useGetCustomers({
    teamId: team,
  });

  const { saveShift, mutationCreate } = useSaveShift({
    teamId: team,
    rotaId,
    supportWorkerId: user?.profile || '',
  });

  const { addVisitToShift, mutationAddVisit } = useAddVisitToShift({
    teamId: team,
    rotaId,
    supportWorkerId: user?.profile || '',
  });

  const { updateShift, mutationUpdate } = useUpdateShift({
    teamId: team,
    rotaId,
    supportWorkerId: user?.profile || '',
  });

  const { addActivityToShift, mutationAddActivityToShift } = useAddActivityToShift({
    teamId: team,
    rotaId,
  });

  const { deleteActivityToShift, mutationDeleteActivityToShift } = useDeleteActivityToShift({
    teamId: team,
    rotaId,
  });

  const { updateActivity, mutationUpdateActivity } = useUpdateActivity({
    teamId: team,
    rotaId,
  });
  const { moveVisitToShift, mutationMoveVisitToShift } = useMoveVisitToShift({
    teamId: team,
    rotaId,
  });

  const { revertMoveVisit, mutationRevertMoveVisit } = useRevertVisit({
    teamId: team,
    rotaId,
  });

  const { changeShiftType, mutationChangeShiftType } = useChangeShiftType({
    teamId: team,
    rotaId,
  });

  const { changeShiftTime, mutationChangeShiftTime } = useChangeShiftTime({
    teamId: team,
    rotaId,
  });

  const { cancelVisit, mutationCancelVisit } = useCancelVisit({
    teamId: team,
    rotaId,
  });

  const { tbcs, allShifts, weekOne, weekTwo } = useShiftFilters(filters, teamShifts);

  if (teamShiftsLoading || colleaguesLoading || customersLoading) {
    return <Loading />;
  }

  const customers: SelectOption[] = customersData?.map((c: CustomerType) => ({ value: c.id, label: `${c.firstName} ${c.lastName}` }));
  const customer = customersData?.find((c: CustomerType) => c?.id === filters.customer.value);

  const supportWorkers = supportWorkersFull.map((sw: SupportWorkerType) => ({ value: sw.id, label: sw.fullName }));
  const supportWorker = supportWorkersFull?.find((sw: SupportWorkerType) => sw?.id === filters.supportWorker.value);
  const setCustomer = (customerId: string) => {
    const foundCustomer = customers?.find((c) => c?.value === customerId);
    if (foundCustomer) setFilters({ ...filters, customer: foundCustomer });
  };
  const onExpandShift = () => {
    setOpen((current) => !current);
  };

  const onMoveVisitToShift = async (
    newShiftId: string,
    newRotaId: string,
    newTeamId: string,
    currentVisitId: string,
    currentRotaId: string,
    currentTeamId: string,
    supportWorkerId: string,
    shiftType: string,
    shiftStartDateTime?: number,
  ) => {
    setApiLoading(true);

    await moveVisitToShift({
      variables: {
        input: {
          newShiftId,
          newRotaId,
          newTeamId,
          currentVisitId,
          currentRotaId,
          currentTeamId,
          supportWorkerId,
          shiftType,
          shiftStartDateTime,
        },
      },
    });
    setApiLoading(false);
    toggleSupportWorkerModal(false);
    setOpenSnack(true);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mapUpdatedShift = (us: any) => {
    return {
      id: us?.id,
      startDateTime: us?.startDateTime,
      endDateTime: us?.endDateTime,
      cancelledDateTime: us?.cancelledDateTime,
      mileage: us?.mileage,
      travelWithCustomerMileage: us?.travelWithCustomerMileage,
      supportWorkerId: us?.supportWorkerId,
      visitId: us?.visitId,
      customerId: us?.customer?.id,
      teamId: us?.teamId,
      secondarySupportWorkerId: us?.secondarySupportWorkerId,
      status: us?.status,
      week: us?.week,
      notes: us?.notes,
      whoCancelled: us?.whoCancelled,
      type: us?.type,
      oneOffVisitReason: us?.oneOffVisitReason,
      oneOffVisitNote: us?.oneOffVisitNote,
      isToBeRescheduled: us?.isToBeRescheduled,
    };
  };

  const onSaveVisit = async (update: Partial<ShiftUpdateItem> | onSaveVisitProps, operation: string) => {
    const shift = teamShifts.find((s: ShiftType) => s.id === selectedShift?.id);
    const shiftToReplace = { ...shift, ...update };
    const item = mapUpdatedShift(shiftToReplace);
    setApiLoading(true);

    await updateShift({
      variables: {
        input: {
          shiftId: shift?.shiftId,
          rotaId: user?.rota?.id,
          item,
          operation,
        },
      },
    });
    setApiLoading(false);
    toggleCancelModal(false);
    toggleRemoveModal(false);
    toggleEditModal(false);
    toggleEnableModal(false);
    toggleSupportWorkerModal(false);
    setOpenSnack(true);
  };

  const onRefreshVisitForBirdie = async (id: string) => {
    const shift = teamShifts.find((s: ShiftType) => s.id === id);
    const item = shift ? mapUpdatedShift(shift) : null;
    setApiLoading(true);

    await updateShift({
      variables: {
        input: {
          shiftId: shift?.shiftId,
          rotaId: user?.rota?.id,
          item,
          operation: 'edit',
        },
      },
    });
    setApiLoading(false);
    setOpenSnack(true);
  };

  const onCancelVisit = async (update: Partial<CancelVisitInput>) => {
    setApiLoading(true);
    await cancelVisit({
      variables: {
        input: {
          ...update,
        },
      },
    });

    toggleCancelModal(false);
    setApiLoading(false);
    setOpenSnack(true);
  };

  const onChangeShiftType = async (teamId: string, rId: string, id: string, startDateTime: number, endDateTime: number) => {
    setApiLoading(true);
    await changeShiftType({
      variables: {
        input: {
          teamId,
          id,
          rotaId: rId,
          startDateTime,
          endDateTime,
        },
      },
    });

    toggleChangeShiftTypeModal(false);
    setApiLoading(false);
    setOpenSnack(true);
  };

  const onChangeShiftTime = async (teamId: string, rId: string, id: string, startDateTime: number, endDateTime: number) => {
    setApiLoading(true);
    await changeShiftTime({
      variables: {
        input: {
          teamId,
          id,
          rotaId: rId,
          startDateTime,
          endDateTime,
        },
      },
    });

    toggleChangeShiftTimeModal(false);
    setApiLoading(false);
    setOpenSnack(true);
  };

  const onRevertMoveVisit = async (teamId: string, rId: string, visitId: string) => {
    setApiLoading(true);
    await revertMoveVisit({
      variables: {
        input: {
          teamId,
          visitId,
          rotaId: rId,
        },
      },
    });

    setApiLoading(false);
    setOpenSnack(true);
  };

  const onCreateShift = async (shift: Partial<ShiftInputItem>) => {
    setApiLoading(true);
    const payload = {
      variables: {
        input: { rotaId: user?.rota?.id, item: { ...shift, teamId: team } },
      },
    };
    await saveShift(payload);
    toggleCreateShiftModal(false);
    setApiLoading(false);
    setOpenSnack(true);
  };

  const onSaveActivity = async (activity: AddActivityToShiftInput) => {
    setApiLoading(true);
    const payload = {
      variables: {
        input: {
          rotaId: selectedShift?.rotaId || user?.rota.id,
          teamId: team,
          activityType: activity?.activityType,
          endDateTime: activity?.endDateTime,
          notes: activity?.notes,
          shiftId: activity?.shiftId,
          startDateTime: activity?.startDateTime,
          supportWorkerId: selectedShift?.shiftRun?.ownerId || user?.profile,
        },
      },
    };

    await addActivityToShift(payload);
    setSelectedShift(null);
    setApiLoading(false);
    setOpenSnack(true);
    toggleAddActivityToShiftModal(false);
  };

  const onUpdateActivity = async (activity: Partial<ShiftType>) => {
    setApiLoading(true);
    const payload = {
      variables: {
        input: {
          rotaId: user?.rota?.id,
          teamId: team,
          id: activity?.id,
          activityType: activity?.activityType,
          endDateTime: activity?.endDateTime,
          notes: activity?.notes,
          shiftId: activity?.shiftId,
          startDateTime: activity?.startDateTime,
        },
      },
    };
    await updateActivity(payload);
    setApiLoading(false);
    toggleEditActivityModal(false);
    setOpenSnack(true);
  };

  const onDeleteActivity = async (activityToRemove: Partial<ShiftType> | null) => {
    setApiLoading(true);
    const activity = activityToRemove || teamShifts.find((s: ShiftType) => s.id === selectedShift?.id);
    const payload = {
      variables: {
        input: {
          rotaId: user?.rota?.id,
          teamId: team,
          id: activity?.id,
        },
      },
    };
    await deleteActivityToShift(payload);
    setApiLoading(false);
    toggleRemoveActivityModal(false);
    setOpenSnack(true);
  };

  const onAddVisitToShift = async (item: Partial<ShiftType>) => {
    setApiLoading(true);
    const payload = {
      variables: {
        input: {
          rotaId: user?.rota?.id,
          item: {
            ...item,
            shiftId: item?.shiftId,
            teamId: team,
          },
        },
      },
    };
    await addVisitToShift(payload);
    toggleAddVisitToShiftModal(false);
    setApiLoading(false);
    setOpenSnack(true);
  };

  return (
    <>
      <Message
        response={[
          mutationAddVisit,
          mutationUpdate,
          mutationCreate,
          mutationAddActivityToShift,
          mutationDeleteActivityToShift,
          mutationUpdateActivity,
          teamShiftsError,
          colleaguesError,
          customersError,
          mutationMoveVisitToShift,
          mutationRevertMoveVisit,
          mutationChangeShiftType,
          mutationCancelVisit,
          mutationChangeShiftTime,
        ]}
        openSnack={openSnack}
        setOpenSnack={setOpenSnack}
      />
      <React.Suspense fallback={<CircularProgress />}>
        <RemoveVisit removeVisitModalState={removeVisitModalState} toggleRemoveModal={toggleRemoveModal} onSaveVisit={onSaveVisit} apiLoading={apiLoading} />
        <CancelVisit
          cancelVisitModalState={cancelVisitModalState}
          toggleCancelModal={toggleCancelModal}
          onCancelVisit={onCancelVisit}
          apiLoading={apiLoading}
          shift={selectedShift}
          rota={user?.rota}
        />
        <EnableVisit enableVisitModalState={enableVisitModalState} toggleEnableModal={toggleEnableModal} onSaveVisit={onSaveVisit} apiLoading={apiLoading} />
        <CreateNewShift
          rota={user?.rota}
          createShiftModalState={createShiftModalState}
          toggleCreateShiftModal={toggleCreateShiftModal}
          onCreateShift={onCreateShift}
          customers={customers}
          supportWorkers={supportWorkers}
          apiLoading={apiLoading}
        />
        <ChangeShiftType
          shifts={allShifts}
          shift={selectedShift}
          changeShiftTypeModalState={changeShiftTypeModalState}
          toggleChangeShiftTypeModal={toggleChangeShiftTypeModal}
          onChangeShiftType={onChangeShiftType}
          apiLoading={apiLoading}
        />
        <ChangeShiftTime
          shifts={allShifts}
          shift={selectedShift}
          changeShiftTimeModalState={changeShiftTimeModalState}
          toggleChangeShiftTimeModal={toggleChangeShiftTimeModal}
          onChangeShiftTime={onChangeShiftTime}
          apiLoading={apiLoading}
        />
        <ManageActivity
          createActivityModalState={createActivityModalState}
          toggleCreateActivityModal={toggleCreateActivityModal}
          onSaveActivity={onSaveActivity}
          // onUpdateActivity={onUpdateActivity}
          // onDeleteActivity={onDeleteActivity}
          // myShiftRuns={myShiftRuns}
          // myActivities={myActivities}
          rota={user?.rota}
          apiLoading={apiLoading}
        />
        <EditActivity
          editActivityModalState={editActivityModalState}
          toggleEditActivityModal={toggleEditActivityModal}
          onUpdateActivity={onUpdateActivity}
          shifts={teamShifts}
          selectedActivity={selectedShift}
          apiLoading={apiLoading}
        />
        <AddActivityToShift
          shifts={teamShifts}
          onSaveActivity={onSaveActivity}
          addActivityToShiftModalState={addActivityToShiftModalState}
          toggleAddActivityToShiftModal={toggleAddActivityToShiftModal}
          selectedActivity={selectedShift}
          apiLoading={apiLoading}
        />
        <RemoveActivity
          removeActivityVisitModalState={removeActivityVisitModalState}
          toggleRemoveActivityModal={toggleRemoveActivityModal}
          onDeleteActivity={onDeleteActivity}
          apiLoading={apiLoading}
        />
        <AddVisitToShift
          shifts={teamShifts}
          selectedShift={selectedShift}
          addVisitToShiftModalState={addVisitToShiftModalState}
          toggleAddVisitToShiftModal={toggleAddVisitToShiftModal}
          onAddVisitToShift={onAddVisitToShift}
          customers={customers}
          apiLoading={apiLoading}
        />
        <EditVisit
          selectedShift={selectedShift}
          shifts={teamShifts}
          editVisitModalState={editVisitModalState}
          toggleEditModal={toggleEditModal}
          onSaveVisit={onSaveVisit}
          apiLoading={apiLoading}
        />
        <EditSupportWorker
          shifts={teamShifts}
          visit={selectedShift}
          editSupportWorkerModalState={editSupportWorkerModalState}
          toggleSupportWorkerModal={toggleSupportWorkerModal}
          user={user}
          onMoveVisitToShift={onMoveVisitToShift}
          onSaveVisit={onSaveVisit}
          apiLoading={apiLoading}
        />
        <Tbc tbcs={tbcs} />
        <Filter filters={filters} setFilters={setFilters} supportWorkers={supportWorkers} customers={customers} />
        {supportWorker && <SupportWorker supportWorker={supportWorker} shifts={allShifts} />}
      </React.Suspense>
      <div>
        {customer && (
          <Customer
            customer={customer}
            workers={supportWorker ? [supportWorker] : supportWorkersFull}
            componentRef={componentRef}
            enablePrint={allShifts.length > 0}
          />
        )}
        {allShifts?.length > 0 && (
          <>
            {filters.week.value !== '2' && (
              <>
                {Object.keys(weekOne)?.length !== 0 && (
                  <Card sx={{ marginTop: 1, marginBottom: 2, paddingLeft: 1 }}>
                    <Typography color="primary" variant="h6" sx={{ marginBottom: '1em', marginTop: '1em', marginLeft: 0 }}>
                      &nbsp;Week 1: {formatTime(rotaStart, 'PPPP')}
                    </Typography>
                  </Card>
                )}
                <div className="flex wrap">
                  {Object.keys(weekOne).map((shift, i) => {
                    return (
                      <React.Suspense key={`weekOne${i}`} fallback={<CircularProgress />}>
                        <Shift
                          setSelectedShift={setSelectedShift}
                          toggleEditModal={toggleEditModal}
                          toggleRemoveModal={toggleRemoveModal}
                          toggleCancelModal={toggleCancelModal}
                          toggleEnableModal={toggleEnableModal}
                          toggleAddVisitToShiftModal={toggleAddVisitToShiftModal}
                          toggleSupportWorkerModal={toggleSupportWorkerModal}
                          toggleRemoveActivityModal={toggleRemoveActivityModal}
                          toggleEditActivityModal={toggleEditActivityModal}
                          toggleAddActivityToShiftModal={toggleAddActivityToShiftModal}
                          toggleChangeShiftTypeModal={toggleChangeShiftTypeModal}
                          toggleChangeShiftTimeModal={toggleChangeShiftTimeModal}
                          index={`weekOne-${i}`}
                          shifts={weekOne[shift]}
                          open={open}
                          onExpandShift={onExpandShift}
                          user={user}
                          supportWorkers={supportWorkersFull}
                          setCustomer={setCustomer}
                          setToggledShiftId={setToggledShiftId}
                          onRefreshVisitForBirdie={onRefreshVisitForBirdie}
                          onRevertMoveVisit={onRevertMoveVisit}
                        />
                      </React.Suspense>
                    );
                  })}
                </div>
              </>
            )}
            {filters.week.value !== '1' && (
              <>
                {Object.keys(weekTwo)?.length !== 0 && (
                  <Card sx={{ marginTop: 0, marginBottom: 2, paddingLeft: 1 }}>
                    <Typography color="primary" variant="h6" sx={{ marginBottom: '1em', marginTop: '1em', marginLeft: 0 }}>
                      &nbsp;Week 2: {formatTime(addDays(rotaStart, 7), 'PPPP')}
                    </Typography>
                  </Card>
                )}
                <div className="flex wrap">
                  {Object.keys(weekTwo).map((shift, i) => {
                    return (
                      <React.Suspense key={`weekTwo${i}`} fallback={<CircularProgress />}>
                        <Shift
                          setSelectedShift={setSelectedShift}
                          toggleEditModal={toggleEditModal}
                          toggleRemoveModal={toggleRemoveModal}
                          toggleCancelModal={toggleCancelModal}
                          toggleEnableModal={toggleEnableModal}
                          toggleAddVisitToShiftModal={toggleAddVisitToShiftModal}
                          toggleSupportWorkerModal={toggleSupportWorkerModal}
                          toggleRemoveActivityModal={toggleRemoveActivityModal}
                          toggleEditActivityModal={toggleEditActivityModal}
                          toggleAddActivityToShiftModal={toggleAddActivityToShiftModal}
                          toggleChangeShiftTypeModal={toggleChangeShiftTypeModal}
                          toggleChangeShiftTimeModal={toggleChangeShiftTimeModal}
                          index={`weekTwo-${i}`}
                          shifts={weekTwo[shift]}
                          open={open}
                          onExpandShift={onExpandShift}
                          user={user}
                          supportWorkers={supportWorkersFull}
                          setCustomer={setCustomer}
                          setToggledShiftId={setToggledShiftId}
                          onRefreshVisitForBirdie={onRefreshVisitForBirdie}
                          onRevertMoveVisit={onRevertMoveVisit}
                        />
                      </React.Suspense>
                    );
                  })}
                </div>
              </>
            )}
          </>
        )}
      </div>
      {allShifts?.length === 0 && <h2 className="flex wrap">No results found</h2>}
    </>
  );
}
