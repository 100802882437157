import { useGetSupportWorkers } from 'api/hooks/useSupportWorkers';
import { SelectField, SelectFieldWithoutLabel } from './SelectField';

interface SupportWorkerSelectFieldProps {
  label?: string;
  name: string;
  disabled?: boolean;
  showAll?: boolean;
  adminOnly?: boolean;
  testId?: string;
  searchable?: boolean;
  asRow?: boolean;
  showWholeTeam?: boolean;
  isRequired?: boolean;
}

const SupportWorkerSelectField = ({
  asRow,
  label,
  name,
  disabled = false,
  adminOnly = false,
  showAll = false,
  showWholeTeam = false,
  searchable,
  testId,
  isRequired = true,
}: SupportWorkerSelectFieldProps) => {
  const { supportWorkers } = useGetSupportWorkers({ showAll });
  const options = showWholeTeam ? [{ value: '03c69dc0-a72a-4c50-b4a3-cc0ebb70cc1d', label: 'Whole team' }] : [];
  const adminWorkers = supportWorkers.filter((sw) => sw.permissions?.admin === true).map((t) => ({ value: t.id, label: t.fullName }));

  const supportWorkerOptions = supportWorkers.map((s, index) => {
    return { label: s.fullName, value: s.id, id: index.toString() };
  });

  if (label) {
    return (
      <SelectField
        asRow={asRow}
        isRequired={isRequired}
        searchable={searchable}
        testId={testId}
        label={label}
        name={name}
        options={adminOnly ? [...options, ...adminWorkers] : [...options, ...supportWorkerOptions]}
        disabled={disabled}
      />
    );
  } else {
    return (
      <SelectFieldWithoutLabel
        isRequired={true}
        searchable={searchable}
        testId={testId}
        name={name}
        options={adminOnly ? [...options, ...adminWorkers] : [...options, ...supportWorkerOptions]}
        disabled={disabled}
      />
    );
  }
};

export { SupportWorkerSelectField };
