import React, { useState, useEffect } from 'react';
import { Team } from '__generated__/graphql';
import { Input, ToggleButtons } from 'components';
import 'react-quill/dist/quill.snow.css';
import ReactSelect from 'components/Select';
import { SelectOption } from 'types/types';
import { TrashIcon } from '@heroicons/react/24/outline';
import { AddOutlined } from '@mui/icons-material';

interface SaveTeamProps {
  team: Team | null;
  circles: SelectOption[];
  onUpdate: (t: Team) => void;
  onClose: () => void;
}

export enum StatusEnum {
  Active = 'Active',
  Inactive = 'Inactive',
}

export default function SaveTeam({ team, circles, onUpdate, onClose }: SaveTeamProps) {
  const [teamId, setTeamId] = useState<string>('');
  const [status, setStatus] = useState<string>(StatusEnum.Inactive);
  const [activeTeam, setActiveTeam] = useState<string>(StatusEnum.Inactive);
  const [teamName, setTeamName] = useState<string>('');
  const [prefix, setPrefix] = useState<string>('');
  const [locations, setLocations] = useState<string[]>([]);
  const [circle, setCircle] = useState<SelectOption>();
  const [newLocation, setNewLocation] = useState<string>('');

  const append = (name: string) => {
    setLocations([...locations, name]);
    setNewLocation('');
  };

  const remove = (index: number) => {
    const z = [...locations.filter((n, i) => i !== index)];
    setLocations(z);
  };

  useEffect(() => {
    setTeamName(team?.teamName || '');
    setPrefix(team?.prefix || '');
    setTeamId(team?.teamId || '');
    setCircle({ value: team?.circleId || '', label: team?.circleName || '' });
    setStatus(team?.status ? StatusEnum.Active : StatusEnum.Inactive);
    setActiveTeam(team?.activeTeam ? StatusEnum.Active : StatusEnum.Inactive);

    const selectedLocations: string[] = [];
    team?.locations?.forEach((t) => {
      if (t) selectedLocations.push(t);
    });
    setLocations(selectedLocations);
  }, [team]);

  const onSaveTeam = () => {
    onUpdate({
      teamName,
      prefix,
      teamId,
      circleId: circle?.value || '',
      circleName: circle?.label || '',
      status: status === StatusEnum.Active,
      activeTeam: activeTeam === StatusEnum.Active,
      locations,
    });
  };

  const isInvalidTeam = () => {
    if (!teamName) return true;
    if (!circle) return true;
    if (!teamId) return true;
    if (!status) return true;
    if (!activeTeam) return true;
  };

  const changeCircle = (option: SelectOption) => {
    if (option) {
      setCircle(option);
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-[1200] outline-none focus:outline-none">
        <div className="relative w-auto mx-auto max-w-5xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none p-6 max-h-screen md:max-h-[90vh] overflow-y-auto md:w-[480px] lg:w-[680px]">
            <div className="text-gray-900 text-lg leading-lg font-semibold mb-5">{team ? `Edit ${team.teamName}` : 'Add Team'}</div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Team Name</div>
              <div className="mt-2">
                <Input disabled value={teamName} onChange={setTeamName} testId="team-name" />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Circle</div>
              <div className="mt-2">
                <ReactSelect searchable={true} testId="team-circle-id" options={circles} onChange={changeCircle} selectedValue={circle} />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Prefix</div>
              <div className="mt-2">
                <Input value={prefix} onChange={setPrefix} testId="team-prefix" />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Status</div>
              <div className="mt-2">
                <ToggleButtons testId="team-status" buttons={[StatusEnum.Active, StatusEnum.Inactive]} selectedButton={status} onSelectButton={setStatus} />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Is Active Team</div>
              <div className="mt-2">
                <ToggleButtons
                  testId="team-active-team"
                  buttons={[StatusEnum.Active, StatusEnum.Inactive]}
                  selectedButton={activeTeam}
                  onSelectButton={setActiveTeam}
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-between sm:items-end mt-2 mb-5">
              <div className="w-full md:w-2/3">
                <Input value={newLocation} onChange={setNewLocation} testId="form-team-location" />
              </div>
              <button
                type="button"
                data-cy="add-location-button"
                onClick={() => append(newLocation)}
                className="border border-primary-700 text-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center gap-2 w-fit mt-2 sm:mt-0 self-end sm:self-auto"
              >
                <AddOutlined className="w-5 h-5" />
                Add a Location
              </button>
            </div>
            {locations?.map((name, index: number) => {
              return (
                <div key={index} className="border border-gray-200 rounded-lg p-4 m-2">
                  <div className="flex flex-col md:flex-row items-start gap-4 ">
                    <div className="w-full md:w-2/3">
                      <div className="text-gray-800 text-md leading-lg font-medium">{name}</div>
                    </div>
                    <button
                      type="button"
                      data-cy={`delete-location-button-${index}`}
                      onClick={() => remove(index)}
                      aria-label="Remove this location"
                      className="text-white rounded-lg font-semibold text-md leading-md flex items-center gap-2"
                    >
                      <TrashIcon className="w-6 h-6 text-error-500 hover:text-primary600" />
                    </button>
                  </div>
                </div>
              );
            })}
            {/* activeTeam */}
            <div className="flex justify-between items-center mt-16 md:mt-12">
              <button type="button" className="text-gray-500 font-semibold text-md leading-md" onClick={onClose}>
                Cancel
              </button>
              <button
                data-cy="save-form-button"
                type="button"
                className="text-white disabled:opacity-50 bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
                onClick={onSaveTeam}
                disabled={isInvalidTeam()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-gray-900" />
    </>
  );
}
