export const DimensionDescriptions = {
  Openness:
    'Openness is a general appreciation for art, emotion, adventure, unusual ideas, ' +
    'imagination, curiosity, and variety of experience. People who are open to experience are ' +
    'intellectually curious, open to emotion, sensitive to beauty and willing to try new ' +
    'things. They tend to be, when compared to closed people, more creative and more aware of their feelings.',
  Conscientiousness:
    'Conscientiousness is a tendency to display self-discipline, act dutifully, and strive for ' +
    'achievement against measures or outside expectations. It is related to the way in which ' +
    'people control, regulate, and direct their impulses. High conscientiousness is often ' +
    'perceived as being stubborn and focused. Low conscientiousness is associated with ' +
    'flexibility and spontaneity, but can also appear as sloppiness and lack of reliability.',
  Extraversion:
    'Extraverts enjoy interacting with people, and are often perceived as full of energy. They tend to ' +
    'be enthusiastic, action-oriented individuals. They possess high group visibility, like to talk, ' +
    'and assert themselves. Extroverted people may appear more dominant in social settings, as opposed ' +
    'to introverted people in this setting. In contrast, introverts have lower social engagement and ' +
    'energy levels than extraverts. They tend to seem quiet, low-key, deliberate, and less involved ' +
    'in the social world. Their lack of social involvement should not be interpreted as shyness or ' +
    'depression; instead they are more independent of their social world than extraverts.',
  Agreeableness:
    'The agreeableness trait reflects individual differences in general concern for social harmony. ' +
    'Agreeable individuals value getting along with others. They are generally considerate, kind, ' +
    'generous, trusting and trustworthy, helpful, and willing to compromise their interests with ' +
    'others. Agreeable people also have an optimistic view of human nature.',
  Neuroticism:
    'Neuroticism is the tendency to experience emotions such as anxiety, worry, fear, anger, ' +
    'frustration, envy, jealousy, guilt, depressed mood, and loneliness. It is sometimes called ' +
    'emotional instability, or is reversed and referred to as emotional stability. Those who score ' +
    'high in neuroticism are emotionally reactive and vulnerable to stress, they also tend to be ' +
    'flippant in the way they express emotion.',
  Realistic:
    'People with Realistic interests like work activities that include practical, hands-on problems and ' +
    'answers. They like working with plants and animals, and real-world materials, like wood, tools, and ' +
    'machinery. They enjoy outside work.',
  Investigative:
    'People with Investigative interests like work that has to do with ideas and thinking rather than' +
    ' physical activity or leading people. They like to search for facts and figure out problems.',
  Artistic:
    'People with Artistic interests like work that deals with the artistic side of things, such as acting,' +
    ' music, art, and design. They like creativity in their work and work that can be done without ' +
    'following a set of rules.',
  Social:
    'People with Social interests like working with others to help them learn and grow. They like working ' +
    'with people more than working with objects, machines, or information. They like teaching, giving advice,' +
    ' and helping and being of service to people.',
  Enterprising:
    'People with Enterprising interests like work that has to do with starting up and carrying out ' +
    'business projects. These people like taking action rather than thinking about things. They like ' +
    'persuading and leading people, making decisions, and taking risks for profit.',
  Conventional:
    'People with Conventional interests like work that follows set procedures and routines. They ' +
    'prefer working with information and paying attention to detail rather than working with ideas. ' +
    'They like working with clear rules and following a strong leader.',
  Hierarchy:
    'People who value hierarchy prefer being told what to do and how, and prefer not to have too much ' +
    'responsibility. People who score low in this, prefer flat hierarchies.',
  Excellence:
    "People who value excellence in their work enjoy delivering great service at it's best. People who " +
    'score low in this feel pressured by others who strive for perfection.',
  Commitment:
    'People who are committed will go the extra mile and show initiative when required. People who score ' +
    'low on commitment prefer not being asked for too much at work.',
  'Team Player':
    "People who value team play, enjoy working in teams, are generous, and contribute. People who score low on 'team player' will prefer to work alone",
  Resilience:
    'People who value resilience try to stay positive in tough situations and will try to find solutions. ' +
    'People who score low on resilience, may struggle to hide their negative emotions.',
};
