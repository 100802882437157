import { useMemo } from 'react';
import { setYear, getYear, isDate, endOfDay, startOfDay } from 'date-fns';
import { ShiftType } from 'pages/ReportingHours/service';
import { SupportWorker, Shift, Absence, Maybe } from '__generated__/graphql';
import { activityTypes } from 'types/types';
import { EventTypeType } from './types';

export type EventType = {
  cross: boolean;
  end: Date;
  id: string;
  start: Date;
  title: string;
  type: EventTypeType;
};

const useGenerateEvents = (visits: Shift[], leave: Absence[], supportWorker: SupportWorker | undefined) => {
  const getActivityType = (activityType: Maybe<string> | undefined) => {
    return activityTypes.find((at) => at.value === activityType);
  };

  const events: EventType[] = useMemo(() => {
    const visitEvents = visits.map(({ id, type, activityType, customer, mileage, startDateTime: visitStartDateTime, endDateTime, teamId }: Shift) => {
      const activityTitle = type === ShiftType.activity ? getActivityType(activityType)?.label : null;
      return {
        id,
        title: activityTitle || `${customer?.firstName} ${customer?.lastName} - travel mileage: ${mileage}mins` || '',
        start: new Date(visitStartDateTime),
        end: new Date(endDateTime),
        cross: teamId !== supportWorker?.teamId,
        type: type === ShiftType.activity ? EventTypeType.Activity : EventTypeType.Unassigned,
      };
    });

    const leaveEvents: EventType[] = [];
    leave.forEach(({ id, absenceReason, absenceDate, returnDate, teamId }) => {
      let type = EventTypeType.OtherLeave;
      if (absenceReason === 'Absence') type = EventTypeType.Absence;
      if (absenceReason === 'Sick Leave') type = EventTypeType.SickLeave;
      if (absenceReason === 'Annual Leave') type = EventTypeType.SickLeave;

      if (absenceDate && returnDate) {
        leaveEvents.push({
          id: id || '',
          title: absenceReason || '',
          start: new Date(absenceDate),
          end: new Date(returnDate),
          cross: teamId !== supportWorker?.teamId,
          type,
        });
      }
    });

    const birthDate = supportWorker?.dob ? new Date(supportWorker?.dob) : null;
    const startedAt = supportWorker?.startedAt ? new Date(supportWorker?.startedAt) : null;

    if (birthDate && isDate(birthDate)) {
      const currentYearBirthdayDate = setYear(birthDate, getYear(new Date()));

      visitEvents.push({
        id: supportWorker?.id || '',
        title: 'Birthday',
        start: startOfDay(currentYearBirthdayDate),
        end: endOfDay(currentYearBirthdayDate),
        cross: false,
        type: EventTypeType.Birthday,
      });
    }

    if (startedAt && isDate(startedAt)) {
      const currentYearStartDate = setYear(startedAt, getYear(new Date()));

      visitEvents.push({
        id: supportWorker?.id || '',
        title: 'Start Date',
        start: startOfDay(currentYearStartDate),
        end: endOfDay(currentYearStartDate),
        cross: false,
        type: EventTypeType.StartDate,
      });
    }

    return [...visitEvents, ...leaveEvents];
  }, [supportWorker, leave, visits]);

  return events;
};

export default useGenerateEvents;
