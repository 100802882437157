import { EmojiPeople } from '@mui/icons-material';
import { useState } from 'react';
import { Message, LocationBar, Loading } from 'components';
import Helmet from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useUpdateAscotExpectedOutcome, useGetAscotExpectedOutcome } from 'api/hooks/useAscotExpectedOutcomes';
import { useGetAscotReview } from 'api/hooks/useAscotReviews';
import AscotExpectedOutcomeForm, { AscotExpectedOutcomeFormInput } from './AscotExpectedOutcomeForm';

const AscotExpectedOutcomeUpdate = () => {
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const { supportedPersonId, ascotExpectedOutcomeId, ascotReviewId } = useParams<{
    supportedPersonId?: string;
    ascotExpectedOutcomeId?: string;
    ascotReviewId?: string;
  }>();

  const { ascotExpectedOutcome, loading } = useGetAscotExpectedOutcome({ id: ascotExpectedOutcomeId });
  const { ascotReview, loading: ascotReviewLoading } = useGetAscotReview({ id: ascotReviewId });
  const { updateAscotExpectedOutcome, mutationUpdateAscotExpectedOutcome } = useUpdateAscotExpectedOutcome({ customerId: supportedPersonId });

  const navigate = useNavigate();

  const onSubmit = async (data: AscotExpectedOutcomeFormInput) => {
    const input = data;
    delete input.foodAndDrink.current;
    delete input.personalSafety.current;
    delete input.occupation.current;
    delete input.personalCleanlinessAndComfort.current;
    delete input.socialParticipationAndInvolvement.current;
    delete input.controlOverDailyLife.current;
    delete input.accomodationAndComfort.current;

    await updateAscotExpectedOutcome({
      awaitRefetchQueries: true,

      variables: {
        input: {
          ...input,
        },
      },
    });
    setOpenSnack(true);
  };
  if (loading || ascotReviewLoading) {
    return <Loading />;
  }
  return (
    <>
      <Helmet>
        <title>Ascot Expected Outcome</title>
      </Helmet>
      <LocationBar section="Ascot" page="Update Ascot Expected Outcome" Icon={EmojiPeople} />
      <Message response={[mutationUpdateAscotExpectedOutcome]} openSnack={openSnack} setOpenSnack={setOpenSnack} />
      <div className="my-10 px-4 md:px-[5%]">
        <button type="button" className="text-gray-500 font-semibold text-md leading-md flex items-center" onClick={() => navigate(-1)}>
          <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
        </button>

        <AscotExpectedOutcomeForm ascotExpectedOutcome={ascotExpectedOutcome} onSubmit={onSubmit} ascotReview={ascotReview} />
      </div>
    </>
  );
};

export default AscotExpectedOutcomeUpdate;
