import Alert from '@mui/material/Alert';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import { Shift as ShiftType } from '__generated__/graphql';

interface TbcProps {
  tbcs: ShiftType[];
}

const Tbc = ({ tbcs }: TbcProps) => {
  if (tbcs?.length === 0) return null;

  return (
    <Alert
      data-cy="tbc-button"
      sx={{
        fontSize: 'inherit',
        padding: 1.4,
        marginTop: 0.1,
        width: '100%',
        backgroundColor: 'red',
        color: '#fff',
        display: 'flex',
        justifyContent: 'center',
      }}
      color="warning"
      icon={<RunningWithErrorsIcon />}
    >
      This rota has {tbcs.length} TBCs, please assign cover to these visits.
    </Alert>
  );
};

export default Tbc;
