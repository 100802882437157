import { Dispatch, SetStateAction } from 'react';
import SupportWorkerSelect from 'components/SupportWorkerSelect';
import { Controller, useFormContext } from 'react-hook-form';
import { TeamSelector } from 'components';

const SupportWorkerField = ({
  name,
  setTeamId,
  teamId,
  onClickAttendee,
  swIds,
}: {
  name: string;
  setTeamId: Dispatch<SetStateAction<string>>;
  teamId: string;
  swIds: string[];
  onClickAttendee: (id: string) => void;
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: 'This is required.' }}
      render={() => {
        return (
          <>
            <TeamSelector selectTeam={setTeamId} teamId={teamId} />
            <SupportWorkerSelect teamId={teamId} onClick={onClickAttendee} selectedSupportWorkers={swIds as string[]} isSelectMultiple={true} />
          </>
        );
      }}
    />
  );
};

export { SupportWorkerField };
