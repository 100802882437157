import { useState } from 'react';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import { ElderlyOutlined } from '@mui/icons-material';
import { ArrowLeftIcon, LinkIcon } from '@heroicons/react/24/outline';
import { Loading, LocationBar, Message } from 'components';
import TabSwitcher from 'components/TabSwitcher';
import { useGetCustomerPackage, useGetCustomerPackages, useSaveCustomerPackage } from 'api/hooks/useCustomerPackages';
import { CustomerPackage, Maybe } from '__generated__/graphql';
import PackageUpdate from './PackageUpdate';
import { PackagePriceList } from './PackagePriceList';
import { MapPackage } from './Mapper';
import PackageEnd from './PackageEnd';

export default function PackageDetail() {
  const navigate = useNavigate();
  const { customerId, packageId } = useParams();
  const [openEndPackageModal, setOpenEndPackageModal] = useState(false);
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const { customerPackages } = useGetCustomerPackages({});
  const { loading, customerPackage } = useGetCustomerPackage({ customerId, packageId });
  const { saveCustomerPackage, mutationSaveCustomerPackage } = useSaveCustomerPackage({ customerId, packageId });

  if (loading) return <Loading />;

  const onSavePackage = async (data: Maybe<CustomerPackage>) => {
    const update = MapPackage({ ...customerPackage, ...data });
    await saveCustomerPackage({
      variables: {
        input: {
          ...update,
        },
      },
    });
    setOpenEndPackageModal(false);
  };

  const tabs = [
    { label: 'Details', component: <PackageUpdate customerPackage={customerPackage} onUpdate={onSavePackage} customerPackages={customerPackages} /> },
    { label: 'Prices', component: <PackagePriceList customerPackage={customerPackage} /> },
  ];

  return (
    <>
      <Helmet>
        <title>Package Detail</title>
      </Helmet>
      <LocationBar section="Package Detail" page="Package Detail" Icon={ElderlyOutlined} />
      <Message response={[mutationSaveCustomerPackage]} openSnack={openSnack} setOpenSnack={setOpenSnack} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex justify-between place-items-center mb-8">
          <button type="button" className="text-gray-500 font-semibold text-md leading-md flex items-center" onClick={() => navigate('/developer/packages/')}>
            <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
          </button>
          {!customerPackage?.endDate && (
            <button
              type="button"
              data-cy="close-package-button"
              className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md hidden md:block"
              onClick={() => setOpenEndPackageModal(true)}
            >
              End Package
            </button>
          )}
        </div>
        <div className="flex items-start justify-between mt-16">
          <div>
            <div className="text-black text-xl sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1">{customerPackage?.name}</div>
            {customerPackage?.externalId && (
              <div className="text-gray-600 text-md leading-md my-2 flex items-center">
                <LinkIcon className="w-5 h-5 mr-2 text-gray-600" />
                <a href={`https://app.hubspot.com/contacts/6461446/record/0-1/${customerPackage?.externalId}`} target="_blank" rel="noreferrer">
                  View in Hub spot
                </a>
              </div>
            )}
            {customerPackage?.customerId && (
              <div className="text-gray-600 text-md leading-md my-2 flex items-center">
                <LinkIcon className="w-5 h-5 mr-2 text-gray-600" />
                <a href={`/admin-people/${customerPackage?.customerId}`}>Manage</a>
              </div>
            )}
          </div>
          <div className="flex-col justify-between self-stretch hidden sm:flex">
            <div>
              <div className="text-gray-400 text-xs font-medium leading-xs mb-2 flex items-center">
                Start date: {customerPackage?.startDate ? format(customerPackage?.startDate, 'dd/MM/yyyy') : 'Unknown'}
              </div>
              <div className="text-gray-400 text-xs font-medium leading-xs mb-2 flex items-center">
                End date: {customerPackage?.endDate ? format(customerPackage?.endDate ?? 0, 'dd/MM/yyyy') : 'Active'}
              </div>
              <div className="text-gray-400 text-xs font-medium leading-xs mb-2 flex items-center">Funding Source: {customerPackage?.fundingSource}</div>
            </div>
          </div>
        </div>
        <div className="mt-10 sm:mt-16">
          <TabSwitcher tabs={tabs} />
        </div>
        {openEndPackageModal && customerPackage && (
          <PackageEnd customerPackage={customerPackage} onClose={() => setOpenEndPackageModal(false)} onUpdate={onSavePackage} />
        )}
      </div>
    </>
  );
}
