import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { format, getTime } from 'date-fns';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import Helmet from 'react-helmet';
import { DeleteModal } from 'components/modals/DeleteModal';
import { LocationBar, Loading, Table, Message, Search } from 'components';
import { Group } from '@mui/icons-material';
import { PencilIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useGetCustomerPackages, useSaveCustomerPackage, useDeleteCustomerPackage } from 'api/hooks/useCustomerPackages';
import { CustomerPackage } from '__generated__/graphql';
import CreatePackage from './PackageCreate';

export default function PackageList() {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState<CustomerPackage>();
  const { saveCustomerPackage, mutationSaveCustomerPackage } = useSaveCustomerPackage({});
  const { deleteCustomerPackage, mutationDeleteCustomerPackage } = useDeleteCustomerPackage({});
  const { customerPackages, loading } = useGetCustomerPackages({});
  const [openCreatePackageModal, setOpenCreatePackageModal] = useState(false);
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const columnVisibility = {
    dateUpdated: window.innerWidth > 768,
    name: true,
    subject: window.innerWidth > 640,
    type: true,
  };

  if (loading) return <Loading />;

  const onDeleteClose = () => {
    setDeleteModalOpen(false);
  };

  const onSelectPackage = (t: CustomerPackage) => {
    setDeleteModalOpen(true);
    setSelectedPackage(t);
  };

  const onAddPackage = async (data: CustomerPackage) => {
    if (data?.startDate) {
      const id = uuidv4();
      await saveCustomerPackage({
        variables: {
          input: {
            id,
            startDate: getTime(data.startDate),
            endDate: data.endDate ? getTime(data.endDate) : null,
            customerId: data.customerId,
            externalId: data.externalId,
            fundingSource: data.fundingSource,
            name: data.name,
            prices: [],
          },
        },
      });
      navigate(`/developer/packages/${data.customerId}/${id}`);
    }
  };

  const edit = (t: CustomerPackage) => {
    if (t) {
      navigate(`/developer/packages/${t?.customerId}/${t?.id}`);
    }
  };

  const deletePackage = async () => {
    if (selectedPackage) {
      await deleteCustomerPackage({
        variables: {
          input: {
            packageId: selectedPackage.id,
            customerId: selectedPackage.customerId,
          },
        },
      });
    }
    setDeleteModalOpen(false);
  };

  const RowActions = ({ row }: { row: CustomerPackage }) => {
    // if (row?.endDate) return null;
    return (
      <div className="flex items-center gap-3">
        <button data-cy="list-edit-button" type="button" onClick={() => edit(row)} aria-label="Edit">
          <PencilIcon className="w-6 h-6 text-gray-500 hover:text-primary-600" />
        </button>
        <button data-cy="list-delete-button" type="button" onClick={() => onSelectPackage(row)} aria-label="Delete">
          <XCircleIcon className="w-6 h-6 text-gray-500 hover:text-primary-600" />
        </button>
      </div>
    );
  };

  const columnHelper = createColumnHelper<CustomerPackage>();

  const columns1 = [
    columnHelper.accessor('name', {
      header: () => 'Supported Person',
      cell: (info) => {
        return <div data-cy="list-name">{info.renderValue()}</div>;
      },
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.accessor('startDate', {
      header: () => 'Start Date',
      cell: (info) => <div data-cy="list-startDate">{format(info.renderValue() ?? 0, 'dd/MM/yyyy')}</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('endDate', {
      header: () => 'End Date',
      cell: (info) => <div data-cy="list-endDate">{info.renderValue() ? format(info.renderValue() || 0, 'dd/MM/yyyy') : 'Active'}</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('prices', {
      header: () => 'Prices',
      cell: (info) => <div data-cy="list-prices">{info.renderValue()?.length}</div>,
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.accessor('fundingSource', {
      header: () => 'Funding Source',
      cell: (info) => <div data-cy="list-funding">{info.renderValue()}</div>,
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (props) => <RowActions row={props.row.original} />,
    }),
  ] as Array<ColumnDef<CustomerPackage, unknown>>;

  const filterByName = (cp: CustomerPackage) => {
    if (cp?.name?.toLowerCase().includes(search.toLowerCase())) return true;
    return false;
  };

  const rows = customerPackages.filter((cp) => filterByName(cp));
  const sorted = [...(rows || [])].sort((x, y) => (y?.startDate || 0) - (x?.startDate || 0));

  return (
    <>
      <Helmet>
        <title>Packages</title>
      </Helmet>
      <LocationBar section="Packages" page="Packages List" Icon={Group} />
      <Message response={[mutationSaveCustomerPackage, mutationDeleteCustomerPackage]} openSnack={openSnack} setOpenSnack={setOpenSnack} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex justify-between place-items-center mb-8">
          <div className="text-display-sm md:text-display-lg leading-display-sm md:leading-display-lg font-semibold md:tracking-display-lg text-black">
            Packages
          </div>
          <button
            type="button"
            data-cy="create-package-button"
            className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md hidden md:block"
            onClick={() => setOpenCreatePackageModal(true)}
          >
            Create Package
          </button>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-between mt-8 md:mt-16">
          <div className="flex flex-col md:flex-row items-center gap-4 md:gap-9 w-full md:w-fit">
            <Search value={search} onSearch={setSearch} />
          </div>
        </div>
        <div className="mt-3 md:mt-8 mb-10">
          {sorted && sorted.length > 0 && <Table<CustomerPackage> data={sorted} columns={columns1} columnVisibility={columnVisibility} />}
        </div>
      </div>
      {openCreatePackageModal && <CreatePackage onClose={() => setOpenCreatePackageModal(false)} onCreate={onAddPackage} customerPackages={customerPackages} />}
      <DeleteModal onClose={onDeleteClose} open={deleteModalOpen} onConfirm={deletePackage} isDisabled={mutationDeleteCustomerPackage.loading} />
    </>
  );
}
