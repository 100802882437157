import { Input, FormLabel, Message, TeamSelector } from 'components';
import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import Button from 'components/Button';
import { SelectField } from 'components/Fields/SelectField';
import { CheckboxField } from 'components/Fields/CheckboxField';
import { useGetSupportWorkerRoleOptions, useSaveSupportWorkerProfile } from 'api/hooks/useSupportWorkers';
import { SupportWorker } from '../../__generated__/graphql';
import { SupportWorkerStateTransition } from './types';
import { User } from '../../types/types';

interface ManageSupportWorkerProps {
  user: User | null | undefined;
  supportWorker: SupportWorker | null | undefined;
  onChangeAccount: (id: string, type: string) => void;
  teamChangeLoading: boolean;
}

const ManageSupportWorker = ({ user, supportWorker, onChangeAccount, teamChangeLoading }: ManageSupportWorkerProps) => {
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const [teamId, setTeam] = useState<string>(supportWorker?.teamId || '');
  const { suportWorkerRoles, supportWorkerRolesLoading } = useGetSupportWorkerRoleOptions();
  const { saveSupportWorkerProfile, mutationSaveSupportWorkerProfile } = useSaveSupportWorkerProfile();
  const supportWorkerRoleOptions = suportWorkerRoles.map((sw) => {
    return { value: sw.id, label: sw.name };
  });
  const methods = useForm<{
    roleId: string;
    admin: boolean;
    developer: boolean;
  }>({
    mode: 'onSubmit',
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    reset({
      roleId: supportWorker?.roleId || '',
      admin: supportWorker?.permissions?.admin || false,
      developer: supportWorker?.permissions?.developer || false,
    });
  }, [reset, supportWorker]);

  const onSubmit = async (data: { roleId: string; admin: boolean; developer: boolean }) => {
    await saveSupportWorkerProfile({
      awaitRefetchQueries: true,

      variables: {
        input: {
          roleId: data?.roleId || supportWorker?.roleId,
          id: supportWorker?.id,
          permissions: { admin: data.admin, developer: data.developer },
        },
      },
    });
    setOpenSnack(true);
  };

  if (!supportWorker || !user) return null;

  return (
    <FormProvider {...methods}>
      <Message response={[mutationSaveSupportWorkerProfile]} openSnack={openSnack} setOpenSnack={setOpenSnack} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-8 sm:mt-12">
          <FormLabel label="Phone">
            <div className="w-full lg:w-1/2">
              <Input
                disabled={true}
                value={supportWorker.phone}
                placeholder="Phone"
                error={!supportWorker.phone ? 'Support worker has no phone number, please check the phone number in HR Partner' : undefined}
              />
            </div>
          </FormLabel>
          <FormLabel label="Maps Coordinates">
            <div className="w-full lg:w-1/2">
              <Input
                disabled={true}
                value={supportWorker?.lat !== 0 || supportWorker?.lng !== 0 ? `${supportWorker?.lat.toFixed(2)}/${supportWorker?.lng.toFixed(2)}` : ''}
                placeholder="Maps Coordinates"
                error={supportWorker?.lat === 0 || supportWorker?.lng === 0 ? 'Please Activate Maps' : undefined}
              />
            </div>
          </FormLabel>
          <FormLabel label="Circle">
            <div className="w-full lg:w-1/2">
              <Input disabled={true} value={supportWorker?.circleName || ''} placeholder="Circle" />
            </div>
          </FormLabel>
          <FormLabel label="Team">
            <div className="w-full lg:w-1/2">
              <TeamSelector selectTeam={setTeam} teamId={teamId} allTeams />
              {!supportWorker?.teamId ||
                (!supportWorker?.teamName && (
                  <p>
                    Supported person has no team&ensp;
                    <a className="underline" target="_blank" rel="noreferrer" href="https://www.hrpartner.com/">
                      please check the team in hrpartner
                    </a>
                  </p>
                ))}
              {user?.permissions?.developer && (
                <div className="mt-4">
                  <Button
                    loading={teamChangeLoading}
                    label="Change Team"
                    color="bg-primary-700"
                    onClick={() => onChangeAccount(SupportWorkerStateTransition.changeTeam, teamId)}
                    selector="change-team-button"
                  />
                </div>
              )}
            </div>
          </FormLabel>
          <SelectField
            testId="select-a-role"
            isRequired={false}
            label="Role"
            name="roleId"
            options={supportWorkerRoleOptions}
            loading={supportWorkerRolesLoading}
            searchable={true}
          />
          <FormLabel label="Address">
            <div className="w-full lg:w-1/2 flex flex-col gap-2">
              <Input
                disabled={true}
                value={supportWorker.address || ''}
                placeholder="Address"
                error={!supportWorker.address ? 'Support worker has no address, please check the address in in HR Partner' : undefined}
              />
              <Input
                disabled={true}
                value={supportWorker.city || ''}
                placeholder="City"
                error={!supportWorker.city ? 'Support worker has no city, please check the address in in HR Partner' : undefined}
              />
              <Input
                disabled={true}
                value={supportWorker.postcode || ''}
                placeholder="Postcode"
                error={!supportWorker.postcode ? 'Support worker has no postcode, please check the address in in HR Partner' : undefined}
              />
            </div>
          </FormLabel>
          <CheckboxField disabled={!user?.permissions?.admin} label="Admin" name="admin" testId="set-support-worker-admin" />
          <CheckboxField disabled={!user?.permissions?.admin} label="Developer" name="developer" testId="set-support-worker-developer" />
          <button data-cy="save-support-worker" type="submit" className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
            Save
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

export default ManageSupportWorker;
