import { useState, useMemo, useRef, useCallback, useEffect } from 'react';
import Helmet from 'react-helmet';
import { useUser } from 'context/userContext';
import { TrashIcon, EyeIcon, XMarkIcon, AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { LocationBar, Table, Message, Filters, Loading } from 'components';
import { useNavigate } from 'react-router-dom';
import { CalendarToday } from '@mui/icons-material';
import { formatTime } from 'services/helpers';
import { useDeleteRota, useGetRotas } from 'api/hooks/useRota';
import { Rota } from '__generated__/graphql';
import { SelectOption } from 'types/types';
import { GenericContextProps, useGeneric } from 'context/genericContext';
import DeleteModal from './DeleteModal';

interface RotaItem extends Rota {
  type: string;
}
const RotaList = () => {
  const navigate = useNavigate();
  const { user, userLoading } = useUser();
  const tableRef = useRef(null);
  const [team, setTeam] = useState(user?.teamId || '');

  const { teams: genericTeam, teamsLoading } = useGeneric() as GenericContextProps;
  const teams = genericTeam?.map((t) => ({ value: t.teamId, label: t.teamName })) || [];
  const selectedTeam = teams.find((t) => t.value === team);
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
  const [filters, setFilters] = useState([
    {
      name: 'teams',
      selectedValue: selectedTeam ? { value: selectedTeam?.value, label: selectedTeam?.label } : { value: 'all', label: 'All' },
      values: [...(teams || [])],
    },
  ]);
  const { refetch, rotaData } = useGetRotas({ teamId: team });
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [selectedRota, setRota] = useState<RotaItem | null>(null);
  const onClose = () => {
    setOpen(!open);
  };
  const [deleting, setDeleting] = useState<boolean>(false);
  const { deleteRota, deleteRotaRepsonse } = useDeleteRota({ teamId: team });
  useEffect(() => {
    if (rotaData?.current?.teamId && rotaData?.current?.teamId !== team) {
      refetch();
    }
  }, [refetch, rotaData, team]);

  const onSelectRota = useCallback(
    (selectRota: RotaItem) => {
      if (selectRota.status === 'Complete') {
        localStorage.setItem('teamName', filters[0].selectedValue.label);
        localStorage.setItem('teamId', filters[0].selectedValue.value);
        localStorage.setItem('rotaId', selectRota.id || '');
        localStorage.setItem('startRotaDateTime', selectRota.startRotaDateTime?.toString() || '');
        localStorage.setItem('endRotaDateTime', selectRota.endRotaDateTime?.toString() || '');
        navigate('/team-shifts');
      }
    },
    [navigate, filters],
  );

  const onDelete = useCallback(
    async (deleteItem: RotaItem) => {
      setDeleting(true);
      await deleteRota({
        variables: {
          input: { id: deleteItem.id, teamId: user?.teamId },
        },
      });

      localStorage.setItem('rotaId', rotaData?.current?.id || '');
      localStorage.setItem('startRotaDateTime', rotaData?.current?.startRotaDateTime?.toString() || '');
      localStorage.setItem('endRotaDateTime', rotaData?.current?.endRotaDateTime?.toString() || '');
      setDeleting(false);
      setOpenSnack(true);
    },
    [rotaData, deleteRota, user?.teamId],
  );

  const onClickDelete = useCallback(
    (r: RotaItem) => {
      setOpen(!open);
      setRota(r);
    },
    [open],
  );

  const RowActions = useCallback(
    ({ row }: { row: RotaItem }) => {
      return (
        <div className="flex flex-row items-center gap-5">
          {row.type === 'Future' && (
            <button type="button" disabled={deleting} onClick={() => onClickDelete(row)} aria-label="Edit" className="m-0 p-0">
              <TrashIcon className="w-6 h-6  text-error-600 hover:text-primary600" />
            </button>
          )}
          <button type="button" onClick={() => onSelectRota(row)} aria-label="View" className="m-0 p-0">
            <EyeIcon className="w-6 h-6 text-gray-500 hover:text-primary600" />
          </button>
        </div>
      );
    },
    [deleting, onClickDelete, onSelectRota],
  );

  const onSaveFilters = () => {
    setTeam(filters[0].selectedValue.value);
    setShowFiltersModal(false);
  };

  const onFilterChange = (name: string, value: SelectOption) => {
    const newFilters = [...filters];
    const filterIndex = newFilters.findIndex((f) => f.name === name);
    newFilters[filterIndex].selectedValue = value;
    setFilters(newFilters);
  };
  const columnVisibility = {
    start: true,
    end: true,
    status: window.innerWidth > 640,
    type: true,
    actions: true,
  };

  const columnHelper = createColumnHelper<RotaItem>();

  const columns1 = useMemo(() => {
    return [
      columnHelper.accessor('startRotaDateTime', {
        header: () => 'Start',
        cell: (info) => (
          <div data-cy={`${info.row.id}-start-date`} className="text-md leading-md font-semibold text-gray-700">{`${formatTime(
            info.row.original?.startRotaDateTime || 0,
            'd MMMM yyyy',
          )}`}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('endRotaDateTime', {
        header: () => 'End',
        cell: (info) => (
          <div data-cy={`${info.row.id}-end-date`} className="text-md leading-md font-semibold text-gray-700">{`${formatTime(
            info.row.original.endRotaDateTime || 0,
            'd MMMM yyyy',
          )}`}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('status', {
        header: () => 'Status',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('type', {
        header: () => 'Type',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),

      columnHelper.display({
        id: 'actions',
        cell: (props) => <RowActions row={props.row.original} />,
      }),
    ] as Array<ColumnDef<RotaItem[], unknown>>;
  }, [columnHelper, RowActions]);

  const rotas = useMemo(() => {
    const sortedRotas: RotaItem[] = [];
    rotaData?.future?.forEach((r) => {
      sortedRotas.push({ ...r, type: 'Future' });
    });
    if (rotaData?.current) {
      sortedRotas.push({ ...rotaData?.current, type: 'Current' });
    }
    rotaData?.previous?.forEach((r) => {
      sortedRotas.push({ ...r, type: 'Past' });
    });
    return sortedRotas;
  }, [rotaData]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const rows = rotas?.map((c: any) => ({ ...c }));

  if (userLoading || teamsLoading) {
    return <Loading />;
  }

  return (
    <div>
      <Helmet>
        <title>Rotas</title>
      </Helmet>
      <LocationBar section="Rota" page="Manage Rotas" Icon={CalendarToday} />
      <Message response={[deleteRotaRepsonse]} openSnack={openSnack} setOpenSnack={setOpenSnack} />

      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-display-sm sm:text-display-lg leading-display-sm sm:leading-display-lg sm:tracking-display-lg text-black font-medium">
            Manage Rotas
          </div>
        </div>
        {user?.permissions?.admin && (
          <div className="flex flex-col lg:flex-row items-center justify-between mt-8 md:mt-16">
            <div className="flex flex-col md:flex-row items-center gap-4 md:gap-9 w-full md:w-fit">
              <div className="flex flex-col md:flex-row items-center gap-3 w-full md:w-fit">
                <button
                  type="button"
                  className={`relative border ${
                    team === 'all' ? 'border-gray-300' : 'border-gray-900'
                  }  text-gray-700 text-md leading-md flex items-center px-4 py-2.5 rounded-lg shadow-xs justify-center w-full md:w-fit`}
                  onClick={() => setShowFiltersModal(true)}
                >
                  <AdjustmentsHorizontalIcon className="w-5 h-5 mr-2" />
                  Filters
                  {team !== 'all' && (
                    <span className="absolute bg-gray-900 h-5 w-5 text-white rounded-full -top-2 -right-2 flex items-center justify-center text-xs font-semibold">
                      1
                    </span>
                  )}
                </button>
                {team !== 'all' && (
                  <div className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center">
                    {teams.find((t) => t.value === team)?.label}
                    <button
                      type="button"
                      aria-label="remove team filter"
                      className="text-gray-500"
                      onClick={() => {
                        onFilterChange('teams', { value: 'all', label: 'All' });
                        setTeam('all');
                      }}
                    >
                      <XMarkIcon className="w-4 h-4 ml-2" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="mt-3 md:mt-12 mb-10" ref={tableRef}>
          {rows && rows.length > 0 && <Table<RotaItem[]> data={rows} columns={columns1} columnVisibility={columnVisibility} />}
        </div>
      </div>
      {showFiltersModal && user?.permissions?.admin && (
        <Filters filters={filters} onFilterChange={onFilterChange} onCloseModal={() => setShowFiltersModal(false)} onSaveFilters={onSaveFilters} />
      )}
      <DeleteModal open={open} rotaItem={selectedRota} onDelete={onDelete} onClose={onClose} />
    </div>
  );
};

export default RotaList;
