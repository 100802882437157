import { getTime } from 'date-fns';
import { Shift, ShiftRun } from '__generated__/graphql';
import { ShiftRunTypeEnum, VisitStatus } from 'pages/ReportingHours/service';
import { differenceBetweenDates, getShiftLength } from 'services/helpers';

export const ShiftLengthMessageError = (shiftType: string) =>
  `Please reduce the length of this ${shiftType}, this shift now exceeds the 5 hour limit for fixed shifts`;

export const filterByMoved = (sh: Shift) => {
  return sh.status !== VisitStatus.moved;
};

export const filterByCancelledVisit = (sh: Shift) => {
  return sh.status !== VisitStatus.cancelled;
};

export const getActiveVisitsForShift = (allShifts: Shift[]) => {
  return allShifts.filter((sh: Shift) => filterByMoved(sh) && filterByCancelledVisit(sh));
};

export const getShiftStatus = (allShifts: Shift[], shiftRun: ShiftRun | null | undefined) => {
  const allActiveItems = getActiveVisitsForShift(allShifts);
  const isAgreedShift = shiftRun?.type === ShiftRunTypeEnum.agreed && shiftRun?.ownerId;
  const shiftLength = allActiveItems.length;
  const allActiveItemsDurations = lengthOfShift(allActiveItems);
  const allActiveItemsLength = getShiftLength(allActiveItemsDurations);
  const shiftIsFull = isAgreedShift && allActiveItemsDurations >= 300;
  return { shiftIsFull, isAgreedShift, shiftLength, allActiveItemsDurations, allActiveItemsLength };
};

export const assignNewDateToVisitInShift = (shift: Shift, startTime: Date | number, endTime: Date | number) => {
  return { ...shift, startDateTime: getTime(startTime), endDateTime: getTime(endTime) };
};

export const lengthOfShift = (data: Shift[]): number => {
  return data.map((x) => differenceBetweenDates(x.startDateTime, x.endDateTime)).reduce((a, b) => a + b, 0);
};

export const shiftExceedsMaximumLength = (shifts: Shift[]) => {
  const { shiftRun } = shifts[0];
  const isAgreedShift = shiftRun?.type === ShiftRunTypeEnum.agreed && shiftRun?.ownerId;
  return isAgreedShift && lengthOfShift(shifts) > 300;
};
