import { gql, useQuery, useMutation } from '@apollo/client';
import {
  ChatMessageSearch,
  ChatMessage,
  VisitType,
  ChatRoomSearch,
  VisitSearch,
  VisitWithObservationsSearch,
  ChatRoom,
  VisitWithObservationsType,
  NewChatMessagesSearch,
  NewChatMessages,
} from '__generated__/graphql';

export const GET_CHAT_ROOMS = gql`
  query GetChatRooms($query: ChatRoomSearch) {
    GetChatRooms(query: $query) {
      id
      teamId
      active
      topic
      customer {
        id
        name
        avatar
        type
      }
      members {
        id
        name
        avatar
        type
      }
    }
  }
`;

export const GET_CHAT_MESSAGES_BY_ROOM = gql`
  query GetChatMessagesByRoom($query: ChatMessageSearch) {
    GetChatMessagesByRoom(query: $query) {
      _id
      teamId
      roomId
      text
      createdAt
      user {
        _id
        name
        avatar
      }
      image
      video
      audio
      system
      sent
      received
      pending
      file {
        url
        name
        type
      }
    }
  }
`;

export const SAVE_CHAT_MESSAGE = gql`
  mutation CreateChatMessage($input: CreateChatMessageInput) {
    CreateChatMessage(input: $input) {
      _id
      teamId
      roomId
      text
      createdAt
      user {
        _id
        name
        avatar
      }
      image
      video
      audio
      system
      sent
      received
      pending
      file {
        url
        name
        type
      }
    }
  }
`;

export const OnCreateMessageByRoomId = `
  subscription OnCreateMessageByRoomId($roomId: String!) {
    OnCreateMessageByRoomId(roomId: $roomId) {
      _id
      teamId
      roomId
      text
      createdAt
      user {
        _id
        name
        avatar
      }
      image
      video
      audio
      system
      sent
      received
      pending
    }
  }
`;

export const GET_BIRDIE_VISITS = gql`
  query GetBirdieVisits($query: VisitSearch) {
    GetBirdieVisits(query: $query) {
      id
      caregivers {
        user {
          id
          firstname
          lastname
          image_url
          agency_id
          country_code
          email
          is_active
          phone_number
          username
          address
        }
        actual_start_at
        actual_end_at
      }
      actual_start_at
      actual_end_at
      start_at
      end_at
      status
    }
  }
`;

export const GET_VISIT_WITH_OBSERVATIONS = gql`
  query GetVisitWithObservations($query: VisitWithObservationsSearch) {
    GetVisitWithObservations(query: $query) {
      observations {
        id
        visit_id
        caregiver_id
        timestamp
        care_recipient_id
        event_type
        note
      }
      medication_task_instances {
        product {
          id
          full_name
        }
        events {
          timestamp
          note
          dose_size
          medical_product_id
        }
      }
    }
  }
`;

export const EDIT_CHAT_ROOM = gql`
  mutation EditChatRoom($input: EditChatRoomInput) {
    EditChatRoom(input: $input) {
      id
      date
      message
    }
  }
`;

export const CREATE_CHAT_ROOM = gql`
  mutation CreateChatRoom($input: CreateChatRoomInput) {
    CreateChatRoom(input: $input) {
      id
      date
      message
    }
  }
`;

export const DELETE_CHAT_ROOM = gql`
  mutation DeleteChatRoom($input: DeleteChatRoomInput) {
    DeleteChatRoom(input: $input) {
      id
      date
      message
    }
  }
`;

export const GET_NEW_CHAT_MESSAGES = gql`
  query GetNewChatMessages($query: NewChatMessagesSearch) {
    GetNewChatMessages(query: $query) {
      roomId
      newMessages
    }
  }
`;

export const READ_CHAT_MESSAGES = gql`
  mutation ReadChatMessages($input: ReadChatMessagesInput) {
    ReadChatMessages(input: $input) {
      id
      date
      message
    }
  }
`;

interface GetChatRoomsResponse {
  GetChatRooms: ChatRoom[];
}

interface GetChatMessagesByRoomResponse {
  GetChatMessagesByRoom: ChatMessage[];
}

interface GetCompletedVisitsResponse {
  GetBirdieVisits: VisitType[];
}

interface GetVisitWithObservationsResponse {
  GetVisitWithObservations: VisitWithObservationsType;
}

interface GetNewChatMessagesResponse {
  GetNewChatMessages: NewChatMessages[];
}

export const useGetChatRooms = (query: ChatRoomSearch) => {
  const { loading, data } = useQuery<GetChatRoomsResponse>(GET_CHAT_ROOMS, {
    variables: {
      query: {
        ...query,
      },
    },
  });
  return { loading, chatRooms: data?.GetChatRooms || [] };
};

export const useGetChatMessagesByRoom = (query: ChatMessageSearch) => {
  const { data, refetch } = useQuery<GetChatMessagesByRoomResponse>(GET_CHAT_MESSAGES_BY_ROOM, {
    variables: {
      query: {
        ...query,
      },
    },
  });
  const messages = data?.GetChatMessagesByRoom || [];
  return { chatMessages: [...messages].reverse() || [], refetch };
};

export const useSaveChatMessage = (query: ChatMessageSearch) => {
  const [saveMessage, mutationSaveMessage] = useMutation(SAVE_CHAT_MESSAGE, {
    refetchQueries: [
      {
        query: GET_CHAT_MESSAGES_BY_ROOM,
        variables: {
          query: {
            ...query,
          },
        },
      },
    ],
  });

  return { saveMessage, mutationSaveMessage };
};

export const useGetCompletedVisits = (query: VisitSearch) => {
  const { data, loading } = useQuery<GetCompletedVisitsResponse>(GET_BIRDIE_VISITS, {
    variables: {
      query: {
        ...query,
      },
    },
  });
  const newCompletedVisits: VisitType[] = [];
  const birdieVisits = data?.GetBirdieVisits || [];
  [...birdieVisits]?.reverse()?.forEach((visit: VisitType) => {
    if (visit.status === 'COMPLETED') {
      newCompletedVisits.push(visit);
    }
  });

  return { completedVisits: newCompletedVisits || [], loading };
};

export const useGetVisitObservations = (query: VisitWithObservationsSearch) => {
  const { data, loading } = useQuery<GetVisitWithObservationsResponse>(GET_VISIT_WITH_OBSERVATIONS, {
    variables: {
      query: {
        ...query,
      },
    },
  });

  return { visits: data?.GetVisitWithObservations || {}, loading };
};

export const useEditChatRoom = (query: ChatRoomSearch) => {
  const [editChatRoom, mutationEditChatRoom] = useMutation(EDIT_CHAT_ROOM, {
    refetchQueries: [
      {
        query: GET_CHAT_ROOMS,
        variables: {
          query: {
            ...query,
          },
        },
      },
    ],
  });

  return { editChatRoom, mutationEditChatRoom };
};

export const useCreateChatRoom = (query: ChatRoomSearch) => {
  const [createChatRoom, mutationCreateChatRoom] = useMutation(CREATE_CHAT_ROOM, {
    refetchQueries: [
      {
        query: GET_CHAT_ROOMS,
        variables: {
          query: {
            ...query,
          },
        },
      },
    ],
  });

  return { createChatRoom, mutationCreateChatRoom };
};

export const useDeleteChatRoom = (query: ChatRoomSearch) => {
  const [deleteChatRoom, mutationDeleteChatRoom] = useMutation(DELETE_CHAT_ROOM, {
    refetchQueries: [
      {
        query: GET_CHAT_ROOMS,
        variables: {
          query: {
            ...query,
          },
        },
      },
    ],
  });

  return { deleteChatRoom, mutationDeleteChatRoom };
};

export const useGetNewChatMessages = (query: NewChatMessagesSearch) => {
  const { data, loading } = useQuery<GetNewChatMessagesResponse>(GET_NEW_CHAT_MESSAGES, {
    variables: {
      query: {
        ...query,
      },
    },
  });

  return { newChatMessages: data?.GetNewChatMessages || [], newChatMessagesLoading: loading };
};

export const useReadChatMessages = (query: NewChatMessagesSearch) => {
  const [readChatMessages] = useMutation(READ_CHAT_MESSAGES, {
    refetchQueries: [
      {
        query: GET_NEW_CHAT_MESSAGES,
        variables: {
          query: {
            ...query,
          },
        },
      },
    ],
  });

  return { readChatMessages };
};
