import Loading from 'components/Loading';
import { useEffect, useMemo, useState } from 'react';
import { AgreedShift, AgreedShiftRun, Maybe } from '__generated__/graphql';
import {
  useGetAgreedShifts,
  useSaveAgreedShift,
  useSaveAgreedShiftRun,
  useEditAgreedShift,
  useRemoveAgreedShiftRun,
  useEditAgreeedShiftRun,
  useRemoveAgreedShift,
} from 'api/hooks/useAgreedShifts';
import ReactSelect from 'components/Select';
import { Add } from '@mui/icons-material';
import { EditShiftRunData } from '../types';
import AgreedShiftHeader from './AgreedShiftHeader';
import EditShiftRun from './EditShiftRun';
import EditSupportWorker from './EditSupportWorker';
import SelectTime from './SelectTime';
import ShiftRun from './ShiftRun';

interface AgreedShiftsProps {
  selectedTeam: string;
  selectedAgreedRota: string;
  date: number;
  isDisable: boolean;
}

const AgreedShifts = ({ selectedTeam, selectedAgreedRota, date, isDisable }: AgreedShiftsProps) => {
  const { agreedShifts, agreedShiftsLoading } = useGetAgreedShifts({
    teamId: selectedTeam,
    agreedRotaId: selectedAgreedRota,
  });
  const { saveAgreedShiftRun, mutationSaveAgreedShiftRun } = useSaveAgreedShiftRun({
    teamId: selectedTeam,
    agreedRotaId: selectedAgreedRota,
  });

  const { saveAgreedShift, mutationSaveAgreedShift } = useSaveAgreedShift({
    teamId: selectedTeam,
    agreedRotaId: selectedAgreedRota,
  });
  const { editAgreeedShiftRun, mutationEditAgreedShiftRun } = useEditAgreeedShiftRun({
    teamId: selectedTeam,
    agreedRotaId: selectedAgreedRota,
  });

  const { removeAgreeedShiftRun, mutationRemoveAgreedShiftRun } = useRemoveAgreedShiftRun({
    teamId: selectedTeam,
    agreedRotaId: selectedAgreedRota,
  });

  const { editAgreeedShift, mutationEditAgreedShift } = useEditAgreedShift({
    teamId: selectedTeam,
    agreedRotaId: selectedAgreedRota,
  });

  const { removeAgreeedShift, mutationRemoveAgreedShift } = useRemoveAgreedShift({
    teamId: selectedTeam,
    agreedRotaId: selectedAgreedRota,
  });
  const [editSupportWorkerModalState, toggleSupportWorkerModal] = useState(false);
  const [selectTimeModalState, toggleSelectTimeModal] = useState(false);
  const [editRunModalState, toggleEditRunModal] = useState(false);
  const [selectedWeek, setSelectedWeek] = useState({ value: '1', label: 'Week 1' });
  const [selectedPeriod, setSelectedPeriod] = useState({ value: 'All', label: 'All' });
  const [selectedRun, setSelectedRun] = useState({ value: '', label: '' });
  const [editShiftRunData, setEditShiftRunData] = useState<EditShiftRunData>({
    id: '',
    startDateTime: 0,
    endDateTime: 0,
    week: 1,
    status: 'active',
    canEdit: true,
  });
  const [addShiftData, setAddShiftData] = useState<Partial<AgreedShift>>({
    id: '',
    ownerId: '',
    agreedShiftRunId: '',
    fullName: '',
    day: 0,
  });
  const selectedAgreedWeek: Maybe<AgreedShiftRun>[] = useMemo(() => {
    return selectedWeek.value === '1' ? agreedShifts.week1 || [] : agreedShifts.week2 || [];
  }, [agreedShifts.week1, agreedShifts.week2, selectedWeek]);
  const weekOptions = [
    { value: '1', label: 'Week 1' },
    { value: '2', label: 'Week 2' },
  ];
  const runOptions = selectedAgreedWeek?.map((ag) => ({ value: ag?.period ?? '', label: ag?.period ?? '' })) ?? [];
  const periodOptions = [
    { value: 'All', label: 'All' },
    { value: 'AM', label: 'AM' },
    { value: 'PM', label: 'PM' },
  ];

  useEffect(() => {
    if (agreedShifts && selectedAgreedWeek?.length > 0) {
      setSelectedRun({ value: selectedAgreedWeek[0]?.period || '', label: selectedAgreedWeek[0]?.period || '' });
    } else {
      setSelectedRun({ value: '', label: '' });
    }
  }, [agreedShifts, selectedAgreedWeek, selectedWeek]);

  const onCellClick = (newShiftData: Partial<AgreedShift>) => {
    setAddShiftData(newShiftData);
    toggleSupportWorkerModal(true);
  };

  const onSaveSupportWorker = async (newShiftData: Partial<AgreedShift>) => {
    if (newShiftData.id === '') {
      await saveAgreedShift({
        variables: {
          input: {
            teamId: selectedTeam,
            ownerId: newShiftData.ownerId,
            agreedRotaId: selectedAgreedRota,
            agreedShiftRunId: newShiftData.agreedShiftRunId,
            fullName: newShiftData.fullName,
            day: newShiftData.day,
          },
        },
      });
    } else {
      await editAgreeedShift({
        variables: {
          input: {
            id: newShiftData.id,
            teamId: selectedTeam,
            ownerId: newShiftData.ownerId,
            agreedRotaId: selectedAgreedRota,
            agreedShiftRunId: newShiftData.agreedShiftRunId,
            fullName: newShiftData.fullName,
            day: newShiftData.day,
          },
        },
      });
    }
  };

  const onAddRun = async (startTime: number, endTime: number, week: number) => {
    toggleSelectTimeModal(false);
    await saveAgreedShiftRun({
      variables: {
        input: {
          teamId: selectedTeam,
          agreedRotaId: selectedAgreedRota,
          endDateTime: endTime,
          startDateTime: startTime,
          hours: 5,
          week,
        },
      },
    });
  };

  const onEditRun = async (editRunData: EditShiftRunData) => {
    toggleEditRunModal(false);
    await editAgreeedShiftRun({
      variables: {
        input: {
          id: editRunData.id,
          teamId: selectedTeam,
          agreedRotaId: selectedAgreedRota,
          endDateTime: editRunData.endDateTime,
          startDateTime: editRunData.startDateTime,
          hours: 5,
          week: editRunData.week,
          status: editRunData.status,
        },
      },
    });
  };

  const onRemoveRun = async (editRunData: EditShiftRunData) => {
    toggleEditRunModal(false);
    await removeAgreeedShiftRun({
      variables: {
        input: {
          id: editRunData.id,
          teamId: selectedTeam,
          agreedRotaId: selectedAgreedRota,
        },
      },
    });
  };

  const onRemoveShift = async (id: string) => {
    await removeAgreeedShift({
      variables: {
        input: {
          id,
          teamId: selectedTeam,
          agreedRotaId: selectedAgreedRota,
        },
      },
    });
  };

  const onEdit = (data: EditShiftRunData) => {
    setEditShiftRunData(data);
    toggleEditRunModal(true);
  };

  const onAddShiftRun = (week: number) => {
    setSelectedWeek({ value: week.toString(), label: `Week ${week}` });
    toggleSelectTimeModal(true);
  };

  if (
    mutationSaveAgreedShiftRun.loading ||
    mutationSaveAgreedShift.loading ||
    mutationEditAgreedShiftRun.loading ||
    mutationRemoveAgreedShiftRun.loading ||
    mutationEditAgreedShift.loading ||
    mutationRemoveAgreedShift.loading ||
    agreedShiftsLoading
  ) {
    return <Loading />;
  }

  const renderFilters = () => (
    <>
      <div className="xl:hidden">
        <div className="mt-5">
          <div className="text-sm font-medium text-gray-700 capitalize w-auto">Select a week</div>
          <div className="mt-2">
            <ReactSelect
              selectedValue={selectedWeek}
              options={weekOptions}
              onChange={(option) => {
                setSelectedWeek(option);
                const week = option.value === '1' ? agreedShifts.week1 || [] : agreedShifts.week2 || [];
                setSelectedRun({ value: week[0]?.period || '', label: week[0]?.period || '' });
              }}
            />
          </div>
        </div>
        {selectedAgreedWeek?.length > 0 && (
          <div className="mt-3">
            <div className="text-sm font-medium text-gray-700 capitalize w-auto">Select a Run</div>
            <div className="mt-2 flex items-center gap-2">
              <div className="w-full">
                <ReactSelect selectedValue={selectedRun} options={runOptions} onChange={setSelectedRun} />
              </div>
              <button
                className="p-2.5 rounded-lg border border-primary-700 text-primary-700 font-medium"
                type="button"
                aria-label="add a run"
                onClick={() => toggleSelectTimeModal(true)}
              >
                <Add />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="hidden xl:block">
        <div className="mt-5">
          <div className="text-sm font-medium text-gray-700 capitalize w-auto">Select a Period</div>
          <div className="mt-2">
            <ReactSelect searchable testId="select-period" selectedValue={selectedPeriod} options={periodOptions} onChange={setSelectedPeriod} />
          </div>
        </div>
      </div>
    </>
  );

  const renderWeek = (week: number, agreedWeek: Maybe<Maybe<AgreedShiftRun>[]> | undefined) => (
    <div className={`${selectedWeek.value === week.toString() ? 'flex' : 'hidden'} xl:flex items-stretch border border-gray-100 flex-wrap mt-5`}>
      <div className="hidden xl:flex flex-wrap w-full">
        <AgreedShiftHeader date={date} week={week} onAddShiftRun={onAddShiftRun} isDisable={isDisable} name={`add-week-${week}`} />
      </div>
      {agreedShifts &&
        agreedWeek?.map((row: Maybe<AgreedShiftRun>, index: number) => {
          return (
            <div
              key={index}
              className={`${row?.period === selectedRun.value ? 'flex' : 'hidden'} ${
                (row?.period?.includes('AM') && selectedPeriod.value === 'AM') ||
                (row?.period?.includes('PM') && selectedPeriod.value === 'PM') ||
                selectedPeriod.value === 'All'
                  ? 'xl:flex'
                  : 'xl:hidden'
              } w-full border-t border-gray-100`}
            >
              {row && (
                <ShiftRun
                  date={date}
                  row={row}
                  onEdit={onEdit}
                  onCellClick={onCellClick}
                  week={week}
                  onAddShiftRun={onAddShiftRun}
                  isDisable={isDisable}
                  name={`edit-week-${week}`}
                />
              )}
            </div>
          );
        })}
    </div>
  );

  return (
    <>
      {editSupportWorkerModalState && (
        <EditSupportWorker
          toggleSupportWorkerModal={toggleSupportWorkerModal}
          shiftData={addShiftData}
          onSaveSupportWorker={onSaveSupportWorker}
          teamId={selectedTeam}
          onRemoveShift={onRemoveShift}
        />
      )}
      {editRunModalState && (
        <EditShiftRun editShiftRunData={editShiftRunData} onEditRun={onEditRun} onClose={() => toggleEditRunModal(false)} onRemoveRun={onRemoveRun} />
      )}
      {selectTimeModalState && (
        <SelectTime
          period={selectedPeriod.label}
          date={date}
          onAddRun={onAddRun}
          toggleSelectTimeModal={toggleSelectTimeModal}
          selectedWeek={parseInt(selectedWeek.value, 10)}
        />
      )}
      {renderFilters()}
      {renderWeek(1, agreedShifts?.week1)}
      {renderWeek(2, agreedShifts?.week2)}
    </>
  );
};

export default AgreedShifts;
