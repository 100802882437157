import FormLabel from 'components/FormLabel';
import { useFieldArray, Controller, useFormContext } from 'react-hook-form';
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import Input from 'components/Input';

interface TextListFieldProps {
  label: string;
  name: string;
  disabled?: boolean;
}
const TextListField = ({ name, label, disabled = false }: TextListFieldProps) => {
  const { control, register } = useFormContext(); // retrieve all hook methods

  const { fields, append, remove } = useFieldArray({
    name, // unique name for your Field Array
    rules: { minLength: 1, required: 'This is required' },
  });

  return (
    <FormLabel label={label}>
      <div className="flex flex-col w-full lg:w-[48%]">
        <div>
          <div className="flex flex-row justify-between ">
            <div className="flex flex-col min-w-[90%] lg:w-1/2" />

            <button type="button" onClick={() => append(' ')} aria-label="Add an action" className="px-5 py-2 ">
              <PlusCircleIcon className="w-6 h-6 text-gray-500 hover:text-primary600" />
            </button>
          </div>
          {fields?.map((field, index: number) => {
            return (
              <div className="flex flex-row justify-between" key={field.id}>
                <Controller
                  name={`${name}.${index}`}
                  control={control}
                  rules={{
                    required: 'Please fill in or delete',
                  }}
                  render={({ field: { value, onChange }, fieldState: { error: fieldError } }) => {
                    return (
                      <>
                        <div className="flex flex-col min-w-[90%] lg:w-1/2">
                          <Input formRef={register(`${name}.${index}`).ref} onChange={onChange} value={value} disabled={disabled} />
                          <p className="mt-2 text-sm text-error-600 dark:text-green-500">
                            <span className="font-medium">{fieldError?.message}</span>
                          </p>
                        </div>
                        <div className="flex flex-row items-center">
                          <button type="button" onClick={() => remove(index)} aria-label="Remove this action" className="px-5 py-2 ">
                            <TrashIcon className="w-6 h-6 text-gray-500 hover:text-primary600" />
                          </button>
                        </div>
                      </>
                    );
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </FormLabel>
  );
};

export { TextListField };
