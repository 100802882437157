import { useState } from 'react';
import { CustomerNote, Maybe } from '__generated__/graphql';
import { Add } from '@mui/icons-material';
import EditWellbeingNote from './EditWellbeingNote';
import WellbeingBoardNote from './WellbeingBoardNote';

interface WellbeingBoardNotesProps {
  notes: Maybe<Maybe<CustomerNote>[]> | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  notesRef?: any;
}

const WellbeingBoardNotes = ({ notes, notesRef }: WellbeingBoardNotesProps) => {
  const [showEditWellbeingNote, setShowEditWellbeingNote] = useState(false);
  const [selectedNote, setSelectedNote] = useState<Maybe<CustomerNote>>();

  const onEditNote = (note: Maybe<CustomerNote>) => {
    setSelectedNote(note);
    setShowEditWellbeingNote(true);
  };

  return (
    <div ref={notesRef}>
      {showEditWellbeingNote && <EditWellbeingNote wellbeingNote={selectedNote} onClose={() => setShowEditWellbeingNote(false)} />}
      <div className="flex flex-col sm:flex-row justify-between sm:items-end mt-2 mb-5">
        <button
          type="button"
          onClick={() => {
            setSelectedNote(undefined);
            setShowEditWellbeingNote(true);
          }}
          className="border border-primary-700 text-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center gap-2 w-fit mt-2 sm:mt-0 self-end sm:self-auto"
          data-cy="wellbeing-board-add-note-button"
        >
          <Add className="!w-5 !h-5" />
          Add a note
        </button>
      </div>
      {notes?.map((note: Maybe<CustomerNote>, index: number) => (
        <div key={note?.id}>
          <WellbeingBoardNote note={note} onEditNote={onEditNote} index={index} />
        </div>
      ))}
    </div>
  );
};

export default WellbeingBoardNotes;
