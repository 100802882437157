/* eslint-disable react/no-danger */
import { Edit } from '@mui/icons-material';
import { Customer, Maybe } from '__generated__/graphql';
import { FormLabel } from 'components';
import { useNavigate } from 'react-router-dom';

interface WellbeingBoardAboutProps {
  customer: Customer | undefined;
}

const WellbeingBoardAbout = ({ customer }: WellbeingBoardAboutProps) => {
  const navigate = useNavigate();
  return (
    <div className=" mt-5">
      <FormLabel label="What people like and admire about me">
        <ul className="w-full lg:w-1/2 p-4 md:p-0">
          {customer?.profileData?.aboutMe?.map((aM: Maybe<string>, index: number) => (
            <li key={index} className="list list-disc text-gray-900 text-md leading-md ">
              {aM}
            </li>
          ))}
        </ul>
      </FormLabel>

      <FormLabel label=" What matters to me">
        <div className="w-full lg:w-1/2 text-gray-900 text-md leading-md" dangerouslySetInnerHTML={{ __html: customer?.profileData?.matters || '' }} />
      </FormLabel>
      <FormLabel label="How best to support me">
        <div className="w-full lg:w-1/2 text-gray-900 text-md leading-md" dangerouslySetInnerHTML={{ __html: customer?.profileData?.support || '' }} />
      </FormLabel>
      <button
        type="button"
        className="text-gray-500 font-semibold text-md leading-md flex items-center"
        onClick={() => navigate(`/supported-people/${customer?.id}/update`)}
      >
        <Edit className="mr-2 w-5 h-5" /> Update this profile
      </button>
    </div>
  );
};

export default WellbeingBoardAbout;
