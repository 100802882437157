import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import { Template, MessageType } from '__generated__/graphql';
import { Input } from 'components';
import 'react-quill/dist/quill.snow.css';
import ReactSelect from 'components/Select';
import { SelectOption } from 'types/types';
import TextArea from 'components/TextArea';

interface SaveTemplateProps {
  template: Template | null;
  onUpdate: (t: Template) => void;
  onClose: () => void;
}

export default function SaveTemplate({ template, onUpdate, onClose }: SaveTemplateProps) {
  const [name, setName] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [type, setType] = useState<SelectOption>({ value: MessageType.Email, label: MessageType.Email });

  useEffect(() => {
    setName(template?.name || '');
    setSubject(template?.subject || '');
    setMessage(template?.message || '');
    setType({ value: template?.type || MessageType.Email, label: template?.type || MessageType.Email });
  }, [template]);

  const modules = {
    toolbar: [['bold', 'italic', 'underline', 'strike'], [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }], ['clean']],
  };

  const onSaveTemplate = () => {
    onUpdate({
      id: template ? template.id : null,
      name,
      subject,
      message,
      type: type.value as MessageType,
    });
  };

  const isValidTemplate = () => {
    if (!name) return true;
    if (!subject) return true;
    if (!message) return true;
  };

  const messageTypes = Object.values(MessageType).map((c) => ({ value: c, label: c }));

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-[1200] outline-none focus:outline-none">
        <div className="relative w-auto mx-auto max-w-5xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none p-6 max-h-screen md:max-h-[90vh] overflow-y-auto md:w-[480px] lg:w-[680px]">
            <div className="text-gray-900 text-lg leading-lg font-semibold mb-5">{template ? `Edit ${type.value} Template` : `Add ${type.value} Template`}</div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Name</div>
              <div className="mt-2">
                <Input value={name} onChange={setName} testId="template-name" />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Template</div>
              <div className="mt-2">
                <ReactSelect searchable={true} testId="message-type" options={messageTypes} onChange={setType} selectedValue={type} />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Subject</div>
              <div className="mt-2">
                <Input value={subject} onChange={setSubject} testId="template-subject" />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Message</div>
              <div className="mt-2">
                {type.value === MessageType.Sms ? (
                  <TextArea testId="template-sms-body" value={message} onChange={setMessage} limit={160} />
                ) : (
                  <ReactQuill data-cy="template-email-body" theme="snow" value={message} onChange={setMessage} modules={modules} style={{ height: '18em' }} />
                )}
              </div>
            </div>
            <div className="flex justify-between items-center mt-16 md:mt-12">
              <button type="button" className="text-gray-500 font-semibold text-md leading-md" onClick={onClose}>
                Cancel
              </button>
              <button
                data-cy="save-template-button"
                type="button"
                className="text-white disabled:opacity-50 bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
                onClick={onSaveTemplate}
                disabled={isValidTemplate()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-gray-900" />
    </>
  );
}
