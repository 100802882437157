import { SetStateAction, Dispatch } from 'react';
import { User } from 'types/types';
import Badge from '@mui/material/Badge';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import useGetTeams from 'api/hooks/useGetTeams';
import { camelCaseToNormalString } from 'services/strings';
import { ShiftType as ShiftTypeEnum, VisitStatus } from 'pages/ReportingHours/service';
import { formatTime } from '../../../services/helpers';
import { Shift as ShiftType, Team, VisitMove } from '../../../__generated__/graphql';

interface ShiftVisitProps {
  index: string;
  shift: ShiftType;
  setCustomer: (customer: string) => void;
  user: User;
  toggleEditModal: (toggle: boolean) => void;
  toggleRemoveModal: (toggle: boolean) => void;
  toggleCancelModal: (toggle: boolean) => void;
  toggleEnableModal: (toggle: boolean) => void;
  toggleEditActivityModal: (toggle: boolean) => void;
  onRefreshVisitForBirdie: (id: string) => void;
  setSelectedShift: Dispatch<SetStateAction<ShiftType | null>>;
  toggleRemoveActivityModal: (toggle: boolean) => void;
  onChangeSupportWorker: (shift: ShiftType) => void;
  onRevertMoveVisit: (teamId: string, rotaId: string, visitId: string) => void;
}

const ShiftVisit = ({
  index,
  shift,
  setCustomer,
  user,
  toggleEditModal,
  toggleRemoveModal,
  toggleCancelModal,
  toggleEnableModal,
  toggleEditActivityModal,
  onRefreshVisitForBirdie,
  setSelectedShift,
  toggleRemoveActivityModal,
  onChangeSupportWorker,
  onRevertMoveVisit,
}: ShiftVisitProps) => {
  const { teams } = useGetTeams();

  const getActivity = () => {
    return camelCaseToNormalString(shift?.activityType || '');
  };

  const isVisit = (visit: ShiftType) => visit.type === ShiftTypeEnum.visit || visit.type === ShiftTypeEnum.oneoff;
  const isActivity = (visit: ShiftType) => visit.type === ShiftTypeEnum.activity;

  const getTeamFromId = (moved: VisitMove) => {
    const id = moved?.teamId;
    const team = teams?.find((t: Team) => t.teamId === id);
    return team?.teamName || '';
  };

  const onEditVisit = () => {
    setSelectedShift(shift);
    toggleEditModal(true);
  };

  const onDeleteVisit = () => {
    setSelectedShift(shift);
    toggleRemoveModal(true);
  };

  const onCancelVisit = () => {
    setSelectedShift(shift);
    toggleCancelModal(true);
  };

  const onEnableVisit = () => {
    setSelectedShift(shift);
    toggleEnableModal(true);
  };

  const onDeleteActivity = () => {
    setSelectedShift(shift);
    toggleRemoveActivityModal(true);
  };

  const onEditActivity = () => {
    setSelectedShift(shift);
    toggleEditActivityModal(true);
  };

  const isCrossTeam = (move: VisitMove) => {
    return move && shift?.teamId !== move?.teamId;
  };

  const refreshBirdie = () => {
    navigator?.clipboard?.writeText(JSON.stringify(shift));
    onRefreshVisitForBirdie(shift.id);
  };

  const applyVisitClass = (s: ShiftType) => {
    if (isActivity(s)) return 'bg-sky-300';
    if (s?.status === VisitStatus.moved) return 'bg-slate-300';
    if (s?.status === VisitStatus.cancelled) return 'bg-red-300';
    else return 'whitebg';
  };

  const revertMoveVisit = (teamId?: string | null, rotaId?: string | null, visitId?: string | null) => {
    if (teamId && rotaId && visitId) {
      setSelectedShift(shift);
      onRevertMoveVisit(teamId, rotaId, visitId);
    }
  };

  const getEditSupportWorkerMessage = (sh: ShiftType) => {
    if (sh?.origin) {
      return `${sh?.secondarySupportWorker?.fullName} is currently supporting this visit, this visit was moved from another shift, revert this first to change the support worker covering this visit `;
    }

    return `${sh?.secondarySupportWorker?.fullName} is currently supporting this visit, click here to change or remove the support worker carrying out the visit`;
  };

  return (
    <div key={`shift${index}`} className={`visit ${applyVisitClass(shift)}`}>
      {isActivity(shift) ? `Banked Hours: ${getActivity()}` : ''}
      {isVisit(shift) ? (
        <Tooltip title={`View ${shift?.customer?.firstName} ${shift?.customer?.lastName} visits`} sx={{ padding: '0.2em', cursor: 'pointer' }}>
          <Button
            data-cy={`shift-${index}-visit-customer`}
            onClick={() => setCustomer(shift?.customer?.id || '')}
            sx={{ marginLeft: '-0.5em', textTransform: 'none' }}
          >
            {shift?.customer?.firstName} {shift?.customer?.lastName} {shift?.status === VisitStatus.cancelled ? '(Cancelled)' : ''}
          </Button>
        </Tooltip>
      ) : null}
      <p>
        <span data-cy={`shift-${index}-visit-time`}>
          {formatTime(shift?.startDateTime || 0, 'E')}, {formatTime(shift?.startDateTime, 'HH:mm')} - {formatTime(shift?.endDateTime, 'HH:mm')}
        </span>
        <p>{shift?.destination?.shiftId && `Visit moved to: ${shift?.destination?.fullName || 'TBC'}`}</p>
        {shift?.status === VisitStatus.active && !shift.activityType && (
          <p>
            Travel to visit: <b>{`${parseFloat((shift?.mileage || 0).toFixed(1)) || 0} miles`} </b>
            <br />
          </p>
        )}
        {shift?.origin?.shiftId && <p>Visit moved from: {shift?.origin?.fullName || 'TBC'}</p>}
        {shift?.origin && isCrossTeam(shift?.origin) && (
          <Typography data-cy={`shift-${index}-cross-team-visit`}>X-Team: {getTeamFromId(shift?.origin)}</Typography>
        )}
        {shift?.destination && isCrossTeam(shift?.destination) && (
          <Typography data-cy={`shift-${index}-cross-team-visit`}>X-Team: {getTeamFromId(shift?.destination)}</Typography>
        )}
      </p>
      {shift.type === ShiftTypeEnum.oneoff && (
        <p>
          <Chip sx={{ mt: '1em' }} size="small" label="One Off" color="secondary" />
        </p>
      )}
      <Box sx={{ display: { xs: 'flex', md: 'flex', marginLeft: '-1em' } }}>
        {!user?.isGuest && shift?.status === VisitStatus.active && isVisit(shift) ? (
          <Tooltip data-cy={`shift-${index}-edit-visit`} title="Edit the visits start and end time" sx={{ marginRight: '0.2em', cursor: 'pointer' }}>
            <EditIcon onClick={() => onEditVisit()} />
          </Tooltip>
        ) : null}
        {!user?.isGuest && shift?.status === VisitStatus.active && shift.type === ShiftTypeEnum.activity ? (
          <Tooltip data-cy={`shift-${index}-edit-activity`} title="Edit the activity" sx={{ marginRight: '0.2em', cursor: 'pointer' }}>
            <EditIcon onClick={() => onEditActivity()} />
          </Tooltip>
        ) : null}
        {!user?.isGuest && shift?.status === VisitStatus.active && isVisit(shift) && !shift.origin ? (
          <Tooltip
            data-cy={`shift-${index}-delete-visit`}
            title="Delete this visit, remove the visit completely"
            sx={{ marginRight: '0.2em', cursor: 'pointer' }}
          >
            <DeleteIcon onClick={() => onDeleteVisit()} />
          </Tooltip>
        ) : null}
        {!user?.isGuest && shift?.status === VisitStatus.active && shift.type === ShiftTypeEnum.activity ? (
          <Tooltip
            data-cy={`shift-${index}-delete-activity`}
            title="Delete this activity, remove the activity completely"
            sx={{ marginRight: '0.2em', cursor: 'pointer' }}
          >
            <DeleteIcon onClick={() => onDeleteActivity()} />
          </Tooltip>
        ) : null}
        {!user?.isGuest && shift?.status === VisitStatus.active && isVisit(shift) ? (
          <Tooltip
            data-cy={`shift-${index}-cancel-visit`}
            title="Cancel this visit, sets the status of the visit to cancelled"
            sx={{ marginRight: '0.2em', cursor: 'pointer' }}
          >
            <CancelIcon onClick={() => onCancelVisit()} />
          </Tooltip>
        ) : null}
        {!user?.isGuest && shift?.status === VisitStatus.cancelled && isVisit(shift) ? (
          <Tooltip data-cy={`shift-${index}-enable-visit`} title="Re-enable this cancelled visit" sx={{ marginRight: '0.2em', cursor: 'pointer' }}>
            <ArrowCircleUpIcon onClick={() => onEnableVisit()} />
          </Tooltip>
        ) : null}
        {shift.birdieId && shift.secondarySupportWorker ? (
          <Tooltip title="Birdie synced" sx={{ cursor: 'pointer', marginRight: '0.2em' }}>
            <CheckCircleIcon color="info" onClick={() => navigator?.clipboard?.writeText(JSON.stringify(shift))} />
          </Tooltip>
        ) : null}
        {!shift.birdieId &&
        shift.secondarySupportWorker &&
        shift?.status !== VisitStatus.cancelled &&
        shift?.status !== VisitStatus.moved &&
        shift.type === 'visit' ? (
          <Tooltip title="Birdie not synced" sx={{ cursor: 'pointer', marginRight: '0.2em' }}>
            <SyncProblemIcon color="error" onClick={() => refreshBirdie()} />
          </Tooltip>
        ) : null}
        {shift.destination ? (
          <Tooltip title="Revert this visit back to this shift" sx={{ cursor: 'pointer', marginRight: '0.2em' }}>
            <SettingsBackupRestoreIcon color="primary" onClick={() => revertMoveVisit(shift?.teamId, shift?.rotaId, shift?.id)} />
          </Tooltip>
        ) : null}
        {shift.origin ? (
          <Tooltip title="Revert this visit back to the original shift" sx={{ cursor: 'pointer', marginRight: '0.2em' }}>
            <SettingsBackupRestoreIcon color="primary" onClick={() => revertMoveVisit(shift?.origin?.teamId, shift?.origin?.rotaId, shift?.origin?.visitId)} />
          </Tooltip>
        ) : null}
        {user?.permissions?.developer && (
          <Tooltip title="Information" sx={{ cursor: 'pointer', marginRight: '0.2em' }}>
            <InfoIcon color="primary" onClick={() => navigator?.clipboard?.writeText(JSON.stringify(shift))} />
          </Tooltip>
        )}
        {shift.secondarySupportWorker &&
        shift?.status !== VisitStatus.cancelled &&
        shift?.status !== VisitStatus.moved &&
        shift?.type !== ShiftTypeEnum.activity ? (
          <Tooltip
            title={getEditSupportWorkerMessage(shift)}
            onClick={() => (!user?.isGuest && shift.type !== ShiftTypeEnum.activity && !shift.origin ? onChangeSupportWorker(shift) : null)}
            sx={{ padding: '0.2em', marginLeft: 'auto', marginTop: '0.45em', cursor: 'pointer' }}
          >
            <Badge
              color={shift.secondarySupportWorker?.teamId !== user?.rota?.teamId ? 'error' : 'info'}
              overlap="rectangular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={shift?.secondarySupportWorker?.fullName?.replace('-', ' ').split(' ')[0]}
            >
              <Avatar data-cy={`shift-${index}-sw-assigned-visit`} className="colleague" variant="circular" src={shift?.secondarySupportWorker?.avatar} />
            </Badge>
          </Tooltip>
        ) : null}
        {shift.secondarySupportWorker && shift?.type === ShiftTypeEnum.activity ? (
          <Tooltip title={`Activity ${shift.activityType} carried out by ${shift.secondarySupportWorker.fullName} `} sx={{ mt: '0.2em', ml: 'auto' }}>
            <Badge
              color="info"
              overlap="rectangular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={shift?.secondarySupportWorker?.fullName?.replace('-', ' ').split(' ')[0]}
            >
              <Avatar data-cy={`shift-${index}-sw-assigned-visit`} className="colleague" variant="circular" src={shift?.secondarySupportWorker?.avatar} />
            </Badge>
          </Tooltip>
        ) : null}
        {!shift.secondarySupportWorker && shift?.status === VisitStatus.active ? (
          <Tooltip
            title="This TBC visit has no cover, click here to add cover"
            sx={{ padding: '0.2em', marginLeft: 'auto', marginTop: '0.45em', cursor: 'pointer' }}
          >
            <Chip
              data-cy={`shift-${index}-tbc-visit`}
              size="small"
              label="TBC"
              color="primary"
              onClick={() => (!user?.isGuest ? onChangeSupportWorker(shift) : null)}
            />
          </Tooltip>
        ) : null}
      </Box>
    </div>
  );
};

export default ShiftVisit;
