import { ChevronLeftIcon, ChevronRightIcon, ExclamationCircleIcon, InformationCircleIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import { SupportWorker, TeamAnswer } from '__generated__/graphql';
import { Avatar } from 'components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ImageSizeType } from 'types/types';

interface YourTeamProps {
  supportWorkers: SupportWorker[];
  teamCPStatus: TeamAnswer[];
  testId: string;
}

const YourTeam = ({ supportWorkers, teamCPStatus, testId }: YourTeamProps) => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState(1);
  const [teamIndex, setTeamIndex] = useState(0);

  const nbPages = Math.ceil(supportWorkers.length / 5);

  return (
    <div className="bg-background2 border border-black border-opacity-[0.13] p-6 rounded-3xl" data-cy={testId}>
      <div className="flex flex-row items-center justify-between mb-4">
        <div className="flex flex-row items-center gap-3">
          <div className="border border-black border-opacity-20 w-10 h-10 rounded-full flex items-center justify-center">
            <UserGroupIcon className="!w-5 !h-5 text-content-secondary" />
          </div>
          <div className="text-xl leading-xl font-semibold text-black">Your Team</div>
        </div>
        <button type="button" onClick={() => navigate('/team-members')} aria-label="more informations">
          <InformationCircleIcon className="w-6 h-6 text text-content-secondary" />
        </button>
      </div>
      <div className="bg-background3 px-4 rounded-2xl flex flex-col divide-y divide-gray-200">
        {supportWorkers.slice(teamIndex, teamIndex + 5).map((sw) => {
          const isOverdue = teamCPStatus.some((cp) => cp.id === sw.id && cp.status === 'overdue');
          return (
            <button type="button" onClick={() => navigate(`/team-members/${sw.id}`)} className="flex flex-row items-center justify-between gap-4 py-4">
              <div className="flex flex-row items-center gap-3">
                <Avatar alt={sw.fullName} avatar={sw.avatar} size={ImageSizeType.LG} />
                <div className="text-gray-900 font-semibold text-md leading-md">{sw.fullName}</div>
              </div>
              {isOverdue && (
                <div className="bg-content-warning rounded-full w-6 h-6 flex justify-center items-center">
                  <ExclamationCircleIcon className="!w-4 !h-4 text-white" />
                </div>
              )}
            </button>
          );
        })}
      </div>
      <div className="flex flex-row items-center justify-between mt-5">
        <div className="text-content-gray text-md leading-md font-medium">
          Page {pagination} of {nbPages}
        </div>
        <div className="flex flex-row items-center gap-4">
          <button
            type="button"
            aria-label="previous team page"
            onClick={() => {
              if (pagination > 1) {
                setPagination(pagination - 1);
                setTeamIndex(teamIndex - 5);
              }
            }}
            className="w-9 h-9 rounded-lg border border-black border-opacity-20 shadow-sm p-2 flex items-center justify-center"
          >
            <ChevronLeftIcon className="text-content-gray w-5 h-5" />
          </button>
          <button
            type="button"
            aria-label="next team page"
            onClick={() => {
              if (pagination < nbPages) {
                setPagination(pagination + 1);
                setTeamIndex(teamIndex + 5);
              }
            }}
            className="w-9 h-9 rounded-lg border border-black border-opacity-20 shadow-sm p-2 flex items-center justify-center"
          >
            <ChevronRightIcon className="text-content-gray w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default YourTeam;
