import { useQuery, gql, useMutation } from '@apollo/client';
import { CancelVisitInput, Shift, ShiftRunChangeTypeInput, ShiftSearch } from '__generated__/graphql';
import { GET_SUPPORT_WORKER, GET_SUPPORT_WORKERS } from './useSupportWorkers';
import { GET_NOTIFICATIONS } from './useNotifications';

export const SAVE_SHIFT = gql`
  mutation SaveShift($input: ShiftInput) {
    SaveShift(input: $input) {
      id
      message
      date
    }
  }
`;

export const UPDATE_SHIFT = gql`
  mutation updateShift($input: ShiftUpdate) {
    UpdateShift(input: $input) {
      message
      date
    }
  }
`;

export const GET_TEAM_SHIFTS = gql`
  query GetTeamShifts($query: ShiftSearch) {
    GetShifts(query: $query) {
      id
      shiftId
      visitId
      startDateTime
      endDateTime
      travelTime
      teamId
      week
      status
      type
      mileage
      travelWithCustomerMileage
      travelWithCustomerDestination
      notes
      whoCancelled
      cancelledDateTime
      oneOffVisitReason
      oneOffVisitNote
      isToBeRescheduled
      activityType
      rotaId
      birdieId
      secondarySupportWorkerId
      supportWorkerId
      origin {
        rotaId
        teamId
        visitId
        supportWorkerId
        fullName
        shiftId
      }
      destination {
        rotaId
        teamId
        visitId
        supportWorkerId
        fullName
        shiftId
      }
      customer {
        id
        firstName
        lastName
        address
        phone
        lat
        lng
        birdieId
      }
      supportWorker {
        fullName
        avatar
        position
        contractedHours
        employmentStatus
        id
        teamId
        birdieId
        lat
        lng
      }
      secondarySupportWorker {
        fullName
        avatar
        contractedHours
        employmentStatus
        id
        teamId
        birdieId
        lat
        lng
      }
      shiftRun {
        id
        startDateTime
        endDateTime
        type
        ownerId
        teamId
        rotaId
      }
    }
  }
`;

export const GET_TEAM_SHIFTS_VISITS = gql`
  query GetTeamShifts($query: ShiftSearch) {
    GetShifts(query: $query) {
      id
      type
      activityType
      customer {
        firstName
        lastName
      }
      mileage
      startDateTime
      endDateTime
      teamId
    }
  }
`;

export const GET_TEAM_SHIFTS_TBC = gql`
  query GetTeamShifts($query: ShiftSearch) {
    GetShifts(query: $query) {
      startDateTime
      endDateTime
      status
      secondarySupportWorker {
        id
      }
      week
    }
  }
`;

interface GetTeamShiftsResponse {
  GetShifts: Shift[];
}

export const ADD_VISIT_TO_SHIFT = gql`
  mutation AddVisitToShift($input: AddVisitToShift) {
    AddVisitToShift(input: $input) {
      id
      message
      date
    }
  }
`;
export const ADD_ACTIVITY_TO_SHIFT = gql`
  mutation AddActivityToShift($input: AddActivityToShiftInput) {
    AddActivityToShift(input: $input) {
      message
      date
    }
  }
`;

export const DELETE_ACTIVITY_FROM_SHIFT = gql`
  mutation DeleteActivityFromShift($input: DeleteActivityFromShiftInput) {
    DeleteActivityFromShift(input: $input) {
      message
      date
    }
  }
`;

export const UPDATE_ACTIVITY = gql`
  mutation UpdateActivity($input: UpdateActivityInput) {
    UpdateActivity(input: $input) {
      message
      date
    }
  }
`;

export const MOVE_VISIT_TO_SHIFT = gql`
  mutation MoveVisitToShift($input: MoveVisitToShiftInput) {
    MoveVisitToShift(input: $input) {
      message
      date
    }
  }
`;

export const REVERT_MOVE_VISIT = gql`
  mutation RevertMoveVisit($input: RevertVisit) {
    RevertMoveVisit(input: $input) {
      date
      message
    }
  }
`;

export const CHANGE_SHIFT_TYPE = gql`
  mutation ChangeShiftType($input: ShiftRunChangeTypeInput) {
    ChangeShiftType(input: $input) {
      id
      message
      date
    }
  }
`;

export const CHANGE_SHIFT_TIME = gql`
  mutation ChangeShiftTime($input: ShiftRunChangeTimeInput) {
    ChangeShiftTime(input: $input) {
      id
      message
      date
    }
  }
`;

export const CANCEL_VISIT = gql`
  mutation CancelVisit($input: CancelVisitInput) {
    CancelVisit(input: $input) {
      message
      date
    }
  }
`;

const refetch = (input: Record<string, unknown>) => ({
  refetchQueries: [
    {
      query: GET_TEAM_SHIFTS,
      variables: {
        query: {
          ...input,
        },
      },
    },
  ],
});

const refetchWithNotifications = (input: { teamId: string; rotaId: string; supportWorkerId: string }) => ({
  refetchQueries: [
    {
      query: GET_TEAM_SHIFTS,
      variables: {
        query: {
          teamId: input.teamId,
          rotaId: input.rotaId,
        },
      },
    },
    {
      query: GET_SUPPORT_WORKERS,
      variables: {
        query: {
          teamId: input.teamId,
          rotaId: input.rotaId,
        },
      },
    },
    {
      query: GET_NOTIFICATIONS,
      variables: {
        query: {
          ...input,
        },
      },
    },
  ],
});

export const useGetTeamShifts = (query: ShiftSearch, skip?: boolean) => {
  const { data, loading, error } = useQuery<GetTeamShiftsResponse>(GET_TEAM_SHIFTS, {
    variables: {
      skip,
      query: {
        ...query,
      },
    },
  });

  return { teamShifts: data?.GetShifts || [], loading, error };
};

export const useGetTeamShiftVisits = (query: ShiftSearch, skip?: boolean) => {
  const { data, loading, error } = useQuery<GetTeamShiftsResponse>(GET_TEAM_SHIFTS_VISITS, {
    variables: {
      skip,
      query: {
        ...query,
      },
    },
  });

  return { teamShifts: data?.GetShifts || [], loading, error };
};

export const useGetTeamShiftTBC = (query: ShiftSearch, skip?: boolean) => {
  const { data, loading, error } = useQuery<GetTeamShiftsResponse>(GET_TEAM_SHIFTS_TBC, {
    variables: {
      skip,
      query: {
        ...query,
      },
    },
  });

  return { teamShifts: data?.GetShifts || [], loading, error };
};

export const useSaveShift = (query: { teamId: string; rotaId: string; supportWorkerId: string }) => {
  const [saveShift, mutationCreate] = useMutation(SAVE_SHIFT, {
    refetchQueries: [
      {
        query: GET_TEAM_SHIFTS,
        variables: {
          query: {
            teamId: query.teamId,
            rotaId: query.rotaId,
          },
        },
      },
      {
        query: GET_SUPPORT_WORKER,
        variables: {
          query: {
            teamId: query.teamId,
            rotaId: query.rotaId,
          },
        },
      },
      {
        query: GET_NOTIFICATIONS,
        variables: {
          query: {
            ...query,
          },
        },
      },
    ],
  });
  return { saveShift, mutationCreate };
};

export const useAddVisitToShift = (query: { teamId: string; rotaId: string; supportWorkerId: string }) => {
  const [addVisitToShift, mutationAddVisit] = useMutation(ADD_VISIT_TO_SHIFT, refetchWithNotifications(query));
  return { addVisitToShift, mutationAddVisit };
};

export const useUpdateShift = (query: { teamId: string; rotaId: string; supportWorkerId: string }) => {
  const [updateShift, mutationUpdate] = useMutation(UPDATE_SHIFT, refetchWithNotifications(query));
  return { updateShift, mutationUpdate };
};

export const useAddActivityToShift = (query: ShiftSearch) => {
  const [addActivityToShift, mutationAddActivityToShift] = useMutation(ADD_ACTIVITY_TO_SHIFT, refetch(query));
  return { addActivityToShift, mutationAddActivityToShift };
};

export const useDeleteActivityToShift = (query: ShiftSearch) => {
  const [deleteActivityToShift, mutationDeleteActivityToShift] = useMutation(DELETE_ACTIVITY_FROM_SHIFT, refetch(query));
  return { deleteActivityToShift, mutationDeleteActivityToShift };
};

export const useUpdateActivity = (query: ShiftSearch) => {
  const [updateActivity, mutationUpdateActivity] = useMutation(UPDATE_ACTIVITY, refetch(query));
  return { updateActivity, mutationUpdateActivity };
};

export const useMoveVisitToShift = (query: ShiftSearch) => {
  const [moveVisitToShift, mutationMoveVisitToShift] = useMutation(MOVE_VISIT_TO_SHIFT, refetch(query));
  return { moveVisitToShift, mutationMoveVisitToShift };
};

export const useRevertVisit = (query: ShiftSearch) => {
  const [revertMoveVisit, mutationRevertMoveVisit] = useMutation(REVERT_MOVE_VISIT, refetch(query));
  return { revertMoveVisit, mutationRevertMoveVisit };
};

export const useChangeShiftType = (query: ShiftRunChangeTypeInput) => {
  const [changeShiftType, mutationChangeShiftType] = useMutation(CHANGE_SHIFT_TYPE, refetch(query));
  return { changeShiftType, mutationChangeShiftType };
};

export const useCancelVisit = (query: CancelVisitInput) => {
  const [cancelVisit, mutationCancelVisit] = useMutation(CANCEL_VISIT, refetch(query));
  return { cancelVisit, mutationCancelVisit };
};

export const useChangeShiftTime = (query: ShiftRunChangeTypeInput) => {
  const [changeShiftTime, mutationChangeShiftTime] = useMutation(CHANGE_SHIFT_TIME, refetch(query));
  return { changeShiftTime, mutationChangeShiftTime };
};
