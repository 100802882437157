const Video = ({ fileName }: { fileName: string }) => {
  return (
    <div className="aspect-video  pl-4 min-w-[50%]">
      <iframe
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen={true}
        width="100%"
        height="100%"
        title={fileName}
        scrolling="no"
        src={fileName}
      />
    </div>
  );
};
export default Video;
