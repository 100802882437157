import { Controller, useFormContext } from 'react-hook-form';
import FormLabel from '../FormLabel';
import Input from '../Input';

interface InputFieldProps {
  label: string;
  placeholder: string;
  name: string;
  disabled?: boolean;
  type?: string;
  validate?: (value: string) => boolean | string;
  testId?: string;
  isRequired?: boolean;
  asRow?: boolean;
}

interface InputFieldWithoutLabelProps {
  placeholder: string;
  name: string;
  disabled?: boolean;
  type?: string;
  validate?: (value: string) => boolean | string;
  testId?: string;
  isRequired?: boolean;
}

const InputFieldWithoutLabel = ({ placeholder, name, type, disabled = false, validate, testId, isRequired = false }: InputFieldWithoutLabelProps) => {
  const { control, register } = useFormContext(); // retrieve all hook methods

  return (
    <Controller
      name={name}
      control={control}
      rules={isRequired ? { required: 'This is required.', validate } : {}}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <>
            <Input formRef={register(name).ref} testId={testId} type={type} placeholder={placeholder} onChange={onChange} value={value} disabled={disabled} />
            <p className="mt-2 text-sm text-error-600 dark:text-green-500">
              <span className="font-medium">{error?.message}</span>
            </p>
          </>
        );
      }}
    />
  );
};

const InputField = (props: InputFieldProps) => {
  return (
    <FormLabel asRow={props.asRow} label={props.label}>
      <div className="w-full lg:w-1/2">
        <InputFieldWithoutLabel {...props} />
      </div>
    </FormLabel>
  );
};

export { InputField, InputFieldWithoutLabel };
