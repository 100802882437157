import { useQuery, useLazyQuery, gql } from '@apollo/client';
import { ThriveBooking, ThriveBookingSearch } from '../../__generated__/graphql';

export const GET_THRIVE_BOOKINGS = gql`
  query GetThriveBookings($query: ThriveBookingSearch) {
    GetThriveBookings(query: $query) {
      id
      endDateTime
      startDateTime
      status
      reminderSent
      supportedPersonId
      partnerName
      isComplete
      isFirstBooking
      isRecurring
      amITheSupportedPerson
      address {
        addressLine1
        postcode
      }
      category {
        name
      }
      customer {
        id
        firstName
        email
        surname
      }
      partnerPrice {
        basePrice
        memberPrice
        firstBookingPrice
        duration
      }
      product {
        name
        description
      }
      partner {
        businessName
        firstName
        email
        surname
      }
    }
  }
`;

interface GetBookingsResponse {
  GetThriveBookings: ThriveBooking[];
}

export const useGetBookings = (query: ThriveBookingSearch) => {
  const { data, loading, error } = useQuery<GetBookingsResponse>(GET_THRIVE_BOOKINGS, {
    variables: {
      query: {
        ...query,
      },
    },
  });

  return { bookings: data?.GetThriveBookings || [], loading, error };
};

export const useGetBookingsLazy = () => {
  const [getBookingsForThrive, { loading, error, data }] = useLazyQuery<GetBookingsResponse>(GET_THRIVE_BOOKINGS);

  return { getBookingsForThrive, loading, error, bookings: data?.GetThriveBookings || [] };
};
