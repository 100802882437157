import React from 'react';
import { CalendarDaysIcon } from '@heroicons/react/24/outline';
import {
  BaseSingleInputFieldProps,
  DateValidationError,
  FieldSection,
  LocalizationProvider,
  DateTimePicker as MUIDateTimePicker,
  DateTimePickerProps,
  UseDateFieldProps,
  renderTimeViewClock,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';

interface TWDatePickerProps {
  date: Date | undefined | null;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  onChange?: (date: Date | null) => void;
}

interface ButtonFieldProps extends UseDateFieldProps<Date>, BaseSingleInputFieldProps<Date | null, Date, FieldSection, DateValidationError> {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

function ButtonField(props: ButtonFieldProps) {
  const { setOpen, value, id, disabled, InputProps: { ref } = {}, inputProps: { 'aria-label': ariaLabel } = {} } = props;

  return (
    <button
      type="button"
      data-cy="date-time-picker"
      className={`rounded-lg border border-gray-300 shadow-xs flex place-items-center px-3.5 py-2.5 w-full ${disabled ? 'text-gray-300' : 'text-gray-500'}`}
      onClick={() => setOpen?.((prev) => !prev)}
      id={id}
      disabled={disabled}
      ref={ref}
      aria-label={ariaLabel}
    >
      <CalendarDaysIcon className="w-5 h-5 mr-2" /> {value ? format(value ?? 0, 'EEEE dd MMM: HH:mm') : 'Pick a date and time'}
    </button>
  );
}

function ButtonDateTimePicker(props: Omit<DateTimePickerProps<Date>, 'open' | 'onOpen' | 'onClose'>) {
  const [open, setOpen] = React.useState(false);

  return (
    <MUIDateTimePicker
      ampm={false}
      minutesStep={5}
      slots={{ field: ButtonField, ...props.slots }}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      slotProps={{ field: { setOpen } as any }}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      viewRenderers={{
        hours: renderTimeViewClock,
        minutes: renderTimeViewClock,
        seconds: renderTimeViewClock,
      }}
    />
  );
}

const DateTimePicker = ({ date, minDate, maxDate, disabled = false, onChange }: TWDatePickerProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ButtonDateTimePicker value={date} onChange={onChange} minDate={minDate} maxDate={maxDate} disabled={disabled} />
    </LocalizationProvider>
  );
};

export default DateTimePicker;
