import React, { useState, useEffect } from 'react';
import { getTime, set, add } from 'date-fns';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ShiftRunTypeEnum } from 'pages/ReportingHours/service';
import { Shift } from '__generated__/graphql';
import { Loading } from 'components';

interface ChangeShiftTypeProps {
  shifts: Shift[];
  shift: Shift | null;
  apiLoading: boolean;
  toggleChangeShiftTypeModal: (changeShiftTypeModalState: boolean) => void;
  changeShiftTypeModalState: boolean;
  onChangeShiftType: (teamId: string, rotaId: string, id: string, startDateTime: number, endDateTime: number) => Promise<void>;
}

export default function ChangeShiftType({
  shifts,
  shift,
  toggleChangeShiftTypeModal,
  changeShiftTypeModalState,
  onChangeShiftType,
  apiLoading,
}: ChangeShiftTypeProps) {
  const [validation, setValidation] = useState<string | null>(null);
  const [shiftStartDateTime, setShiftStartDateTime] = useState<Date>();

  useEffect(() => {
    const shiftsForThis = shifts.filter((s) => s.shiftId === shift?.shiftId);

    if (shiftsForThis?.length > 0) {
      setShiftStartDateTime(set(shiftsForThis[0]?.startDateTime, { hours: 9, minutes: 0 }));
    } else {
      setShiftStartDateTime(set(new Date(), { hours: 9, minutes: 0 }));
    }
  }, [shifts, shift]);

  const onChangeShiftStartDate = (time: Date | null) => {
    if (time) {
      setShiftStartDateTime(time);
      setValidation(null);
    }
  };

  const onSaveShiftType = () => {
    setValidation(null);

    if (!shift?.shiftRun?.teamId) {
      setValidation('Please provide a shiftrun');
      return;
    }

    if (shift?.shiftRun.type === ShiftRunTypeEnum.oneoff && !shiftStartDateTime) {
      setValidation('Please assign a shift start date');
      return;
    }

    const shiftsForThis = shifts?.filter((s) => s.shiftId === shift.shiftId && s.week === shift.week);
    const startDateTime = shift?.shiftRun.type === ShiftRunTypeEnum.oneoff ? shiftStartDateTime : shiftsForThis[0].startDateTime;
    const endDateTime =
      shift?.shiftRun.type === ShiftRunTypeEnum.oneoff ? add(shiftStartDateTime || 0, { hours: 5 }) : shiftsForThis[shiftsForThis.length - 1].endDateTime;

    if (startDateTime && endDateTime) {
      onChangeShiftType(shift?.shiftRun?.teamId, shift?.shiftRun?.rotaId, shift?.shiftRun?.id, getTime(startDateTime), getTime(endDateTime));
    }
  };

  return (
    <Dialog data-cy="create-shift-dialog" open={changeShiftTypeModalState} onClose={() => toggleChangeShiftTypeModal(false)}>
      <DialogTitle sx={{ fontSize: '1.6em', padding: { xs: '0.8em', sm: '1.2em' } }}>Change Shift Type</DialogTitle>
      <DialogContent sx={{ width: '100%', marginLeft: '0.2em', marginBottom: '1em', padding: { xs: '0', sm: '1em' } }}>
        {validation && (
          <Alert variant="filled" severity="error" sx={{ ml: '1em', mb: '1em', padding: { xs: '0.6', sm: '1em' }, width: { xs: '90%', sm: '90%' } }}>
            {validation}
          </Alert>
        )}
        <Typography sx={{ m: '1em' }}>
          Are you sure you want to change this {shift?.shiftRun?.type === ShiftRunTypeEnum.oneoff ? 'Banked Hours shift' : 'Fixed shift'} to a{' '}
          {shift?.shiftRun?.type === ShiftRunTypeEnum.oneoff ? 'Fixed shift' : 'Banked Hours shift'}{' '}
        </Typography>
        {shift?.shiftRun?.type === ShiftRunTypeEnum.oneoff && (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FormControl sx={{ width: '90%', ml: '1em', mb: '1em' }}>
              <Typography>Start time</Typography>
              <TimePicker value={shiftStartDateTime} ampm={false} minutesStep={5} onChange={onChangeShiftStartDate} />
            </FormControl>
          </LocalizationProvider>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: '1em' }}>
        <Button data-cy="close-shift-button" variant="contained" onClick={() => toggleChangeShiftTypeModal(false)}>
          Close
        </Button>
        {apiLoading ? (
          <Loading isComponent />
        ) : (
          <Button data-cy="change-shift-button" variant="contained" onClick={() => onSaveShiftType()}>
            Change Shift Type
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
