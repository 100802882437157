import { SectionStoryblok } from 'types/components-sb';

interface FileSectionsProps {
  sections: SectionStoryblok[];
}

const FileSections = ({ sections }: FileSectionsProps) => {
  return (
    <div>
      {sections?.map((section, index) => (
        <div key={index}>
          <h2 className="text-primary-700 text-display-md leading-display-md tracking-md border-b border-primary-700 pb-2 mt-10 mb-5">{section.title}</h2>
          {section.contents.map((content, i) => (
            <ul className="ml-4 space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400" key={i}>
              <li className="mt-2.5">
                <a target="_blank" rel="noreferrer" href={content.link.cached_url} className="font-medium hover:underline">
                  {content.title}
                </a>
              </li>
            </ul>
          ))}
        </div>
      ))}
    </div>
  );
};

export default FileSections;
