/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import Helmet from 'react-helmet';
import Papa from 'papaparse';
import { DataArray } from '@mui/icons-material';
import { LocationBar } from 'components';
import TextArea from 'components/TextArea';

export default function Convertor() {
  const [json, setJson] = useState<string>('');
  const [csv, setCsv] = useState<string>('');

  function getArray(data: any): any[] {
    return Object.values(data)[0] as any[];
  }

  const convertToCsv = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const data = JSON.parse(json);
    const asArray = Array.isArray(data) ? data : getArray(data.data);
    const response = Papa.unparse(asArray);
    setCsv(response);
  };

  return (
    <>
      <Helmet>
        <title>Developer - Data Convertor</title>
      </Helmet>
      <LocationBar section="Developers" page="Data Convertor" Icon={DataArray} />
      <div className="m-10">
        <button
          disabled={!json}
          type="button"
          className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
          onClick={convertToCsv}
        >
          Convert to csv
        </button>
      </div>
      <div className="m-10 gap-8 columns-2">
        <div className="">
          <div>JSON</div>
          <div>
            <TextArea value={json} onChange={setJson} rows={30} />
          </div>
        </div>
        <div>
          <div>CSV</div>
          <div>
            <TextArea value={csv} onChange={setCsv} rows={30} />
          </div>
        </div>
      </div>
    </>
  );
}
