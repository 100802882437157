import { useGetTeamMeetings } from 'api/hooks/useTeamMeetings';
import { TeamMeeting } from '__generated__/graphql';
import { Filters, Loading, Table } from 'components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUser } from 'context/userContext';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useGetSupportWorkers } from 'api/hooks/useSupportWorkers';
import AvatarGroup from 'components/AvatarGroup';
import { ImageSizeType, SelectOption } from 'types/types';
import { GenericContextProps, useGeneric } from 'context/genericContext';
import { useEffect, useMemo, useState } from 'react';
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';
import TeamMeetingsNav from './TeamMeetingsNav';

const RowActions = ({ row }: { row: TeamMeeting }) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col md:flex-row md:items-center justify-end gap-2">
      <button
        type="button"
        onClick={() => navigate(`/team-meetings/${row.id}/update?teamId=${row.teamId}`)}
        aria-label="View"
        data-cy="list-edit-button"
        className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md mt-0.5"
      >
        View
      </button>
    </div>
  );
};

const TeamMeetingList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useUser();
  const selectedTeam = searchParams.get('teamId') || user?.teamId || '';
  const [team, setTeam] = useState(selectedTeam);

  const { meetings } = useGetTeamMeetings({ teamId: team });
  const { loading, supportWorkers } = useGetSupportWorkers({
    teamId: team,
  });
  const columnVisibility = {
    teamId: true,
    date: true,
    apologies: window.innerWidth > 768,
    attendees: window.innerWidth > 768,
  };
  const { teams: genericTeam, teamsLoading } = useGeneric() as GenericContextProps;

  const teams = useMemo(() => genericTeam?.map((t) => ({ value: t.teamId, label: t.teamName })) || [], [genericTeam]);
  const defaultTeam = teams.find((t) => t.value === team) || { label: '', value: '' };
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
  const [filters, setFilters] = useState([{ name: 'teams', selectedValue: defaultTeam, values: [...teams] }]);

  useEffect(() => {
    const newFilters = filters;
    newFilters[0].values = [...teams];

    setFilters(newFilters);
  }, [filters, selectedTeam, teams]);

  const columnHelper = createColumnHelper<TeamMeeting>();

  const columns1 = [
    columnHelper.accessor('teamId', {
      header: () => 'Team',
      cell: (info) => {
        const foundTeam = teams.find((t) => t.value === info.row.original?.teamId);
        return (
          <span data-cy="list-name" className="text-md leading-md text-gray-900 font-semibold">
            {foundTeam?.label}
          </span>
        );
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('date', {
      header: () => 'Date',
      cell: (info) => (
        <span data-cy="list-date" className="text-md leading-md text-gray-900 font-semibold">
          {format(info.row.original?.date ?? 0, 'dd/MM/yy')}
        </span>
      ),
      footer: (info) => info.column.id,
    }),

    columnHelper.accessor('apologies', {
      header: () => 'Apologies',
      cell: (info) => {
        const apologyIds = info.row.original?.apologies.map((a) => a?.supportWorkerId);
        const supportWorkerApologies = supportWorkers.filter((sw) => apologyIds.includes(sw.id));
        return (
          <div className="flex-row">
            <AvatarGroup
              avatars={supportWorkerApologies?.map((lw) => ({ avatar: lw?.avatar ?? '', alt: lw?.fullName ?? '', tooltip: lw?.fullName ?? '' })) ?? []}
              size={ImageSizeType.LG}
              max={4}
              border
            />
          </div>
        );
      },
      footer: (info) => info.column.id,
    }),

    columnHelper.accessor('attendees', {
      header: () => 'Attendees',
      cell: (info) => {
        const supportWorkerAttendees = supportWorkers.filter((sw) => info.row.original?.attendees.includes(sw.id));
        return (
          <div className="flex-row">
            <AvatarGroup
              avatars={supportWorkerAttendees?.map((lw) => ({ avatar: lw?.avatar ?? '', alt: lw?.fullName ?? '', tooltip: lw?.fullName ?? '' })) ?? []}
              size={ImageSizeType.LG}
              max={4}
              border
            />
          </div>
        );
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (props) => <RowActions row={props.row.original} />,
    }),
  ] as Array<ColumnDef<TeamMeeting, unknown>>;
  const filterByTeam = (sw: TeamMeeting) => {
    if (team === 'all') return true;
    if (team && sw.teamId === team) return true;
    return false;
  };
  const onSaveFilters = () => {
    setTeam(filters[0].selectedValue?.value || '');
    setSearchParams({ teamId: filters[0].selectedValue?.value || ' ' });

    setShowFiltersModal(false);
  };

  const filteredTeamMeetings = () => {
    return meetings.filter((sw) => filterByTeam(sw));
  };

  const onFilterChange = (name: string, value: SelectOption) => {
    const newFilters = [...filters];
    const filterIndex = newFilters.findIndex((f) => f.name === name);
    newFilters[filterIndex].selectedValue = value;
    setFilters(newFilters);
  };
  const rows = filteredTeamMeetings();
  if (loading || teamsLoading) {
    return <Loading />;
  }
  return (
    <>
      <TeamMeetingsNav />

      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-col lg:flex-row items-center justify-between mt-8 md:mt-16">
          <div className="flex flex-col md:flex-row items-center gap-4 md:gap-9 w-full md:w-fit">
            {user?.permissions?.admin && (
              <div className="flex flex-col md:flex-row items-center gap-3 w-full md:w-fit">
                <button
                  data-cy="show-filters-button"
                  type="button"
                  className={`relative border ${
                    team === 'all' ? 'border-gray-300' : 'border-gray-900'
                  }  text-gray-700 text-md leading-md flex items-center px-4 py-2.5 rounded-lg shadow-xs justify-center w-full md:w-fit`}
                  onClick={() => setShowFiltersModal(true)}
                >
                  <AdjustmentsHorizontalIcon className="w-5 h-5 mr-2" />
                  Filters
                  {team !== 'all' && (
                    <span className="absolute bg-gray-900 h-5 w-5 text-white rounded-full -top-2 -right-2 flex items-center justify-center text-xs font-semibold">
                      1
                    </span>
                  )}
                </button>
                {team !== 'all' && (
                  <div className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center">
                    {teams.find((t) => t.value === team)?.label}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="mt-3 md:mt-12 mb-10">
          {rows && rows.length > 0 && <Table<TeamMeeting> columnVisibility={columnVisibility} data={rows} columns={columns1} />}
        </div>
      </div>

      {showFiltersModal && user?.permissions?.admin && (
        <Filters
          filters={filters}
          showClearFilters={false}
          onFilterChange={onFilterChange}
          onCloseModal={() => setShowFiltersModal(false)}
          onSaveFilters={onSaveFilters}
        />
      )}
    </>
  );
};

export default TeamMeetingList;
