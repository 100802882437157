import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import Helmet from 'react-helmet';
import { LocationBar, Loading, ToggleButtons, Table } from 'components';
import { useGetWorkshops, useSaveWorkshop } from 'api/hooks/useWorkshop';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { Workshop } from '__generated__/graphql';
import { format, isAfter } from 'date-fns';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { SizeType } from 'types/types';
import { PencilIcon, PlusIcon } from '@heroicons/react/24/outline';
import { WorkshopStatus } from './types';
import Status from './components/Status';
import CreateWorkshop from './components/CreateWorkshop';

export default function WorkshopList() {
  const navigate = useNavigate();
  const { saveWorkshop, mutationSaveWorkshop } = useSaveWorkshop('');
  const { workshops, workshopsLoading } = useGetWorkshops();
  const [filterStatus, setFilterStatus] = useState('All');
  const [openCreateWorkshopModal, setOpenCreateWorkshopModal] = useState(false);
  const columnVisibility = {
    date: true,
    time: window.innerWidth > 768,
    candidates: window.innerWidth > 768,
    currentStatus: window.innerWidth > 768,
  };
  if (workshopsLoading || mutationSaveWorkshop.loading) return <Loading />;

  const changeFilter = (f: string) => {
    setFilterStatus(f);
  };

  const onAddWorkshop = async (date: Date) => {
    const id = uuidv4();
    await saveWorkshop({
      variables: {
        input: {
          id,
          date: date.getTime(),
          subject: '',
          body: '',
          templateId: '',
          candidates: [],
        },
      },
    });
    navigate(`/workshops/${id}`);
  };

  const onButtonClick = (workshop: Workshop) => {
    navigate(`/workshops/${workshop.id}`);
  };

  const filterByStatus = (workshop: Workshop) => {
    if (filterStatus === 'All') return true;
    if (filterStatus === 'Scheduled' && isAfter(workshop.date ?? 0, new Date())) return true;
    if (filterStatus === 'Complete' && isAfter(new Date(), workshop.date ?? 0)) return true;

    return false;
  };

  const rows = workshops.filter((sw) => filterByStatus(sw));

  const RowActions = ({ row }: { row: Workshop }) => {
    return (
      <button type="button" data-cy={`workshop-edit-${row.id}-button`} onClick={() => onButtonClick(row)} aria-label="Edit" className="m-0 p-0">
        <PencilIcon className="w-6 h-6 text-gray-500 hover:text-primary600" />
      </button>
    );
  };

  const MobileWorkshop = ({ row }: { row: Workshop }) => {
    return (
      <div className="flex items-center">
        <Status status={isAfter(new Date(), row.date ?? 0) ? WorkshopStatus.Complete : WorkshopStatus.Scheduled} small />
        <div className="ml-2">
          <div className="text-gray-900 text-sm leading-sm font-semibold">{format(row.date ?? 0, 'eeee dd MMMM')}</div>
          <div className="text-gray-700 text-sm leading-sm">{format(row.date ?? 0, 'kk:mm a')}</div>
        </div>
      </div>
    );
  };

  const columnHelper = createColumnHelper<Workshop>();

  const columns1 = [
    columnHelper.accessor('date', {
      header: () => (window.innerWidth > 768 ? 'Date' : 'Workshop'),
      cell: (info) =>
        window.innerWidth > 768 ? (
          <span data-cy="list-date"> {format(info.renderValue() ?? 0, 'eeee dd MMMM yyyy')} </span>
        ) : (
          <MobileWorkshop row={info.row.original} />
        ),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('time', {
      header: () => 'Time',
      cell: (info) => <span data-cy="list-time"> {format(info.row.original.date ?? 0, 'kk:mm a')}</span>,
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.accessor('candidates', {
      header: () => 'Candidates',
      cell: (info) => <span data-cy="list-candidates">{info.row.original.candidates?.length}</span>,
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.accessor('currentStatus', {
      header: () => 'Status',
      cell: (info) => <Status status={isAfter(new Date(), info.row.original.date ?? 0) ? WorkshopStatus.Complete : WorkshopStatus.Scheduled} />,
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (props) => <RowActions row={props.row.original} />,
    }),
  ] as Array<ColumnDef<Workshop, unknown>>;

  return (
    <>
      <Helmet>
        <title>Workshops</title>
      </Helmet>
      <LocationBar section="Workshops" page="Workshop List" Icon={WorkspacesIcon} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex justify-between place-items-center mb-8">
          <div className="text-display-sm md:text-display-lg leading-display-sm md:leading-display-lg font-semibold md:tracking-display-lg text-black">
            Workshops
          </div>
          <button
            type="button"
            data-cy="create-workshop-button"
            className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md hidden md:block"
            onClick={() => setOpenCreateWorkshopModal(true)}
          >
            Create workshop
          </button>
          <button
            type="button"
            aria-label="Create workshop"
            className="text-white bg-primary-700 rounded-lg p-2 font-semibold text-md leading-md md:hidden"
            onClick={() => setOpenCreateWorkshopModal(true)}
          >
            <PlusIcon className="w-5 h-5 text-white" />
          </button>
        </div>
        <ToggleButtons
          buttons={['All', 'Scheduled', 'Complete']}
          selectedButton={filterStatus}
          onSelectButton={changeFilter}
          size={window.innerWidth > 768 ? SizeType.Fit : SizeType.Half}
        />
        <div className="mt-3 md:mt-8 mb-10">
          {rows && rows.length > 0 && <Table<Workshop> data={rows} columns={columns1} columnVisibility={columnVisibility} />}
        </div>
      </div>
      {openCreateWorkshopModal && <CreateWorkshop onClose={() => setOpenCreateWorkshopModal(false)} onCreate={onAddWorkshop} />}
    </>
  );
}
