import React, { Dispatch, SetStateAction } from 'react';
import { styled } from '@mui/material/styles';
import Rating, { IconContainerProps } from '@mui/material/Rating';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

interface ScoreProps {
  score: number;
  setScore: Dispatch<SetStateAction<number>>;
}

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    fontSize: '1.8em',
  },
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

const icons = [
  <SentimentVeryDissatisfiedIcon color="error" />,
  <SentimentDissatisfiedIcon color="error" />,
  <SentimentSatisfiedIcon color="warning" />,
  <SentimentSatisfiedAltIcon color="success" />,
  <SentimentVerySatisfiedIcon color="success" />,
];

function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props;
  return <span {...other}>{icons[value - 1]}</span>;
}

export default function Score({ score, setScore }: ScoreProps) {
  return <StyledRating value={score} IconContainerComponent={IconContainer} highlightSelectedOnly onChange={(e, d) => setScore(d || 2)} />;
}
