import { SelectOption } from 'types/types';

type FilterLabelsProps = {
  filters: Record<string, SelectOption>;
};

const FilterLabel = ({ name, label }: { name: string; label: string }) => {
  return (
    <span
      key={name}
      data-cy={`filter-label-${name}`}
      className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center justify-between"
    >
      {label}
    </span>
  );
};

const FilterLabels = ({ filters }: FilterLabelsProps) => {
  const filterKeys = Object.keys(filters);
  const allValuesKeys = ['assignment', 'day', 'week', 'visitType'];

  return (
    <div className="flex flex-wrap lg:flex-row items-center gap-3 w-full md:w-fit">
      {filterKeys.map((key: string) => {
        if (filters[key].value !== 'all' || (filters[key].value === 'all' && allValuesKeys.includes(key))) {
          return <FilterLabel name={key} label={filters[key]?.label} />;
        } else {
          return null;
        }
      })}
    </div>
  );
};
export default FilterLabels;
