import React, { useState } from 'react';
import Helmet from 'react-helmet';
import MapIcon from '@mui/icons-material/Map';
import { LocationBar, Message, Map, Loading, Search } from 'components';
import { Switch } from 'components/Switch';
import TeamSelector from 'components/TeamSelector';
import { useGetCustomersMarker } from 'api/hooks/useCustomers';
import { useGetSupportWorkerMarker } from 'api/hooks/useSupportWorkers';
import { useUser, UserContextProps } from 'context/userContext';
import { Autocomplete, useLoadScript } from '@react-google-maps/api';
import type { LoadScriptProps } from '@react-google-maps/api';

const Maps = () => {
  const { user, userLoading } = useUser() as UserContextProps;
  const [libraries] = useState<LoadScriptProps['libraries']>(['places']);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    libraries,
  });

  const [searchResults, setSearchResult] = useState<google.maps.places.Autocomplete>();

  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setSearchResult(autocomplete);
  };
  const [openSnack, setOpenSnack] = useState(false);
  const [team, setTeam] = useState<string>(user?.teamId || '');
  const [toggles, setToggles] = useState({
    showCustomers: true,
    showSupportWorkers: false,
  });

  const [postcodeData, setPostcodeData] = useState({
    postCode: '',
    showPostCodeMarker: false,
    postCodeSearchError: false,
    postCodeLat: 0,
    postCodeLng: 0,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToggles({ ...toggles, [e.target.name]: e.target.checked });
  };

  const getLatLngFromPostCode = () => {
    fetch(`https://api.postcodes.io/postcodes/${postcodeData.postCode}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status !== 200) {
          setPostcodeData({ ...postcodeData, postCodeSearchError: true });
        } else {
          setPostcodeData({ ...postcodeData, postCodeLat: data.result.latitude, postCodeLng: data.result.longitude, showPostCodeMarker: true });
        }
      });
  };

  const onPlaceChanged = () => {
    if (searchResults != null) {
      const addressObject = searchResults.getPlace();
      const lat = addressObject.geometry?.location?.lat();
      const long = addressObject.geometry?.location?.lng();

      if (addressObject) {
        setPostcodeData({ ...postcodeData, postCode: addressObject.name || '', postCodeLat: lat || 0, postCodeLng: long || 0, showPostCodeMarker: true });
      }
    }
  };
  const onChangePostCode = (newPostCode: string) => {
    setPostcodeData({ ...postcodeData, postCode: newPostCode, postCodeSearchError: false, showPostCodeMarker: false });
  };

  const { error: colleaguesError, supportWorkers } = useGetSupportWorkerMarker({
    teamId: team === 'all' ? undefined : team,
  });

  const { customers, error: customersError } = useGetCustomersMarker({
    teamId: team === 'all' ? undefined : team,
  });

  if (!isLoaded || userLoading) return <Loading />;

  return (
    <>
      <Helmet>
        <title>Maps</title>
      </Helmet>
      <LocationBar section="Analytics" page="Maps" Icon={MapIcon} />

      <div className="my-10 px-4 md:px-[5%]">
        <Message response={[colleaguesError, customersError]} openSnack={openSnack} setOpenSnack={setOpenSnack} />
        <div className="p-4">
          {user?.permissions?.admin && <TeamSelector selectTeam={setTeam} teamId={team} showAll />}
          <div className="flex flex-col md:flex-row p-2 gap-2">
            <Switch isChecked={toggles.showCustomers} name="showCustomers" label="Show people we support" onChange={handleChange} />
            <Switch isChecked={toggles.showSupportWorkers} name="showSupportWorkers" label=" Show support workers" onChange={handleChange} />
          </div>
          <div className="pb-4">
            <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad} types={['postal_code']} restrictions={{ country: 'gb' }}>
              <Search
                placeholder="Search a specific postcode"
                value={postcodeData.postCode}
                error={postcodeData.postCodeSearchError ? 'Incorrect postcode' : undefined}
                onSearch={onChangePostCode}
                onConfirm={getLatLngFromPostCode}
              />
            </Autocomplete>
          </div>
          <Map
            customers={customers}
            workers={supportWorkers}
            showCustomers={toggles.showCustomers}
            showSupportWorkers={toggles.showSupportWorkers}
            zoom={10}
            showPostCodeMarker={postcodeData.showPostCodeMarker}
            postCodeLat={postcodeData.postCodeLat}
            postCodeLng={postcodeData.postCodeLng}
            postCode={postcodeData.postCode}
          />
        </div>
      </div>
    </>
  );
};

export default Maps;
