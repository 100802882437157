import { useGetSupportWorkerOptions } from 'api/hooks/useSupportWorkers';
import { SupportWorker } from '__generated__/graphql';
import { SelectOption } from 'types/types';
import ReactSelect from './Select';

interface SelectSupportWorkerProps {
  onSelectedSupportWorker: (supportWorkerId: string) => void;
  selectedSupportWorkerId: string;
  teamId: string;
  isContained?: boolean;
  showAll?: boolean;
  placeholder?: string;
  showLeavers?: boolean;
}

const SelectSupportWorker = ({
  onSelectedSupportWorker,
  selectedSupportWorkerId,
  placeholder,
  teamId,
  isContained = false,
  showAll = false,
  showLeavers = false,
}: SelectSupportWorkerProps) => {
  const { supportWorkers, loading } = useGetSupportWorkerOptions({
    teamId,
    showAll: showLeavers ? true : undefined,
  });

  const supportWorkerOptions = supportWorkers.filter((sw) => sw.teamId === teamId).map((s: SupportWorker) => ({ value: s.id, label: s.fullName }));
  const value = supportWorkerOptions?.find((supportWorker: SelectOption) => supportWorker.value === selectedSupportWorkerId) || undefined;

  const onChangeSupportWorker = (supportWorker: SelectOption) => {
    onSelectedSupportWorker(supportWorker.value);
  };

  return (
    <div className="mb-1">
      {!isContained && <div className="text-sm font-bold p-2 text-gray-700 capitalize w-auto">Select a support worker</div>}
      <div className="mt-2">
        <ReactSelect
          isLoading={loading}
          isContained={isContained}
          placeholder={placeholder}
          options={showAll ? [{ value: 'all', label: 'All' }, ...supportWorkerOptions] : supportWorkerOptions}
          onChange={(option) => onChangeSupportWorker(option)}
          selectedValue={value}
        />
      </div>
    </div>
  );
};

export default SelectSupportWorker;
