import FormLabel from 'components/FormLabel';
import Input from 'components/Input';
import ToggleButtons from 'components/ToggleButtons';
import { ReactNode } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { SizeType } from 'types/types';

interface RadioGroupFieldProps {
  name: string;
  radioOptions?: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validate?: (value: any) => boolean | string;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isRequired?: boolean;
}

interface RadioFieldProps {
  name: string;
  radioOptions?: string[];
  icons?: ReactNode[];
  disabled?: boolean;
  isRequired?: boolean;
  testId?: string;
  label?: string;
}
export const RadioField = ({ label, name, icons, radioOptions = ['Yes', 'No'], disabled = false, isRequired, testId }: RadioFieldProps) => {
  const { control, register } = useFormContext(); // retrieve all hook methods
  const validate = (value: { answer: string | undefined; question: string }) => {
    if (!value.answer) {
      return 'Answer is required';
    } else {
      return true;
    }
  };
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: isRequired ? validate : undefined,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <FormLabel label={label ?? value?.question ?? ''}>
            {value.questionType !== 'string' ? (
              <ToggleButtons
                testId={testId}
                formRef={register(name).ref}
                icons={icons}
                disabled={disabled}
                buttons={radioOptions}
                size={SizeType.Half}
                selectedButton={value?.answer || value}
                onSelectButton={(selected: string) => onChange({ ...value, answer: selected })}
              />
            ) : (
              <div className="w-full lg:w-1/2">
                <Input placeholder="" onChange={(selected: string) => onChange({ ...value, answer: selected })} value={value?.answer} disabled={disabled} />
              </div>
            )}
            <p className="mt-2 text-sm text-error-600 dark:text-green-500">
              <span className="font-medium">{error?.message}</span>
            </p>
          </FormLabel>
        );
      }}
    />
  );
};

const RadioGroupField = ({ name, validate, radioOptions = ['Yes', 'No'], disabled = false, isRequired = false }: RadioGroupFieldProps) => {
  const { fields } = useFieldArray({
    rules: {
      validate,
    },
    name, // unique name for your Field Array
  });

  return (
    <>
      {(fields || []).map((_, index: number) => {
        return (
          <RadioField
            testId={`toggle-${index}`}
            key={index}
            name={`${name}.${index}`}
            radioOptions={radioOptions}
            disabled={disabled}
            isRequired={isRequired}
          />
        );
      })}
    </>
  );
};

export { RadioGroupField };
