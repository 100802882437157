import { addDays, addWeeks, differenceInHours, differenceInMinutes, format, subMinutes } from 'date-fns';
import { getHoursAndMinutes } from 'services/helpers';

interface AgreedVisitHeaderProps {
  date: number;
  week: number;
  startDateTime: number;
  endDateTime: number;
}

interface AgreedVisitHeaderCellProps {
  date: number;
  index: number;
  day: string;
  numberOfHoursInShift: number;
  startDateTime: number;
  numberOfMinutesRemainingInShift: number;
  endDateSubMinutes: Date;
  endDateTime: number;
  week: number;
}

const AgreedVisitHeaderCell = ({
  date,
  index,
  day,
  numberOfHoursInShift,
  startDateTime,
  numberOfMinutesRemainingInShift,
  endDateSubMinutes,
  endDateTime,
  week,
}: AgreedVisitHeaderCellProps) => {
  const startDate = week === 2 ? addWeeks(date, 1) : new Date(date);
  return (
    <div className="px-2 h-auto xl:h-[76px] xl:w-[13%] border-t xl:border-t-0 xl:border-l border-gray-100">
      <div className="flex items-center justify-end xl:justify-center gap-2 h-[76px] text-md md:text-lg text-gray-800 font-medium xl:font-semibold text-center">
        {day} {format(addDays(startDate, index), 'dd/MM')}
      </div>

      <div className="xl:hidden">
        {[...Array(numberOfHoursInShift)].map((_, i: number) => {
          const start = new Date(startDateTime);
          const end = new Date(startDateTime);
          start.setHours(start.getHours() + i);
          end.setHours(end.getHours() + i + 1);
          return (
            <div className="flex items-center justify-end h-[60px]" key={i}>
              {getHoursAndMinutes(start.getTime())} - {getHoursAndMinutes(end.getTime())}
            </div>
          );
        })}
        {numberOfMinutesRemainingInShift > 0 && (
          <div className="flex items-center justify-end" style={{ height: numberOfMinutesRemainingInShift }}>
            {getHoursAndMinutes(new Date(endDateSubMinutes).getTime())} - {getHoursAndMinutes(new Date(endDateTime).getTime())}
          </div>
        )}
      </div>
    </div>
  );
};

const AgreedVisitHeader = ({ date, week, startDateTime, endDateTime }: AgreedVisitHeaderProps) => {
  const numberOfHoursInShift = differenceInHours(new Date(endDateTime), new Date(startDateTime));
  const numberOfMinutesRemainingInShift = differenceInMinutes(new Date(endDateTime), new Date(startDateTime)) - numberOfHoursInShift * 60;
  const endDateSubMinutes = subMinutes(endDateTime, numberOfMinutesRemainingInShift);
  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  return (
    <>
      <div className="h-[70px] xl:w-[9%] px-2 py-6 border-r xl:border-r-0 border-gray-100">
        <div className="text-lg text-gray-800 font-semibold text-center">Week {week}</div>
      </div>
      {days.map((day, index) => (
        <AgreedVisitHeaderCell
          date={date}
          key={index}
          index={index}
          day={day}
          numberOfHoursInShift={numberOfHoursInShift}
          startDateTime={startDateTime}
          numberOfMinutesRemainingInShift={numberOfMinutesRemainingInShift}
          endDateSubMinutes={endDateSubMinutes}
          endDateTime={endDateTime}
          week={week}
        />
      ))}
    </>
  );
};

export default AgreedVisitHeader;
