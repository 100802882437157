import { gql, useMutation, useQuery } from '@apollo/client';
import { AgreedShiftRunWeeks, AgreedShiftRunSearch, OptimisedSearch, AgreedShiftRunWeeksOptimised } from '__generated__/graphql';

export const EDIT_AGREED_SHIFT = gql`
  mutation EditAgreedShift($input: EditAgreedShiftInput) {
    EditAgreedShift(input: $input) {
      id
      message
      date
    }
  }
`;
export const GET_AGREED_SHIFTS_OPTIMISED = gql`
  query GetAgreedVisitsOptimised($query: OptimisedSearch) {
    GetAgreedVisitsOptimised(query: $query) {
      week1 {
        id
        teamId
        agreedRotaId
        startDateTime
        endDateTime
        period
        hours
        week
        status
        shifts {
          agreedRotaId
          teamId
          ownerId
          fullName
          day
          visits {
            id
            startDateTime
            endDateTime
            customerId
            customer {
              firstName
              lastName
            }
          }
        }
      }
      week2 {
        id
        teamId
        agreedRotaId
        startDateTime
        endDateTime
        period
        hours
        week
        status
        shifts {
          agreedRotaId
          teamId
          ownerId
          fullName
          day
          visits {
            id
            startDateTime
            endDateTime
            customerId
            customer {
              firstName
              lastName
            }
          }
        }
      }
      unsolvedDays
    }
  }
`;

export const GET_AGREED_SHIFTS = gql`
  query GetAgreedShifts($query: AgreedShiftRunSearch) {
    GetAgreedShifts(query: $query) {
      week1 {
        id
        teamId
        agreedRotaId
        startDateTime
        endDateTime
        period
        hours
        week
        status
        shifts {
          id
          agreedRotaId
          agreedShiftRunId
          teamId
          ownerId
          fullName
          day
          visits {
            id
            startDateTime
            endDateTime
            customerId
            customer {
              firstName
              lastName
            }
          }
        }
      }
      week2 {
        id
        teamId
        agreedRotaId
        startDateTime
        endDateTime
        period
        hours
        week
        status
        shifts {
          id
          agreedRotaId
          agreedShiftRunId
          teamId
          ownerId
          fullName
          day
          visits {
            id
            startDateTime
            endDateTime
            customerId
            customer {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

export const SAVE_AGREED_SHIFT_RUN = gql`
  mutation SaveAgreedShiftRun($input: AddAgreedShiftRunInput) {
    SaveAgreedShiftRun(input: $input) {
      date
      id
      message
    }
  }
`;

export const SAVE_AGREED_SHIFT = gql`
  mutation SaveAgreedShift($input: AddAgreedShiftInput) {
    SaveAgreedShift(input: $input) {
      id
      message
      date
    }
  }
`;

export const EDIT_AGREED_SHIFT_RUN = gql`
  mutation EditAgreedShiftRun($input: EditAgreedShiftRunInput) {
    EditAgreedShiftRun(input: $input) {
      id
      message
      date
    }
  }
`;

export const REMOVE_AGREED_SHIFT_RUN = gql`
  mutation RemoveAgreedShiftRun($input: RemoveAgreedShiftRunInput) {
    RemoveAgreedShiftRun(input: $input) {
      message
      date
    }
  }
`;

export const REMOVE_AGREED_SHIFT = gql`
  mutation RemoveAgreedShift($input: RemoveAgreedShiftInput) {
    RemoveAgreedShift(input: $input) {
      message
      date
    }
  }
`;

export const SAVE_AGREED_VISIT = gql`
  mutation SaveAgreedvisit($input: AddAgreedVisitInput) {
    SaveAgreedvisit(input: $input) {
      id
      message
      date
    }
  }
`;

export const SAVE_MULTIPLE_AGREED_VISIT = gql`
  mutation SaveMultipleAgreedVisit($input: AddMultipleAgreedVisitInput) {
    SaveMultipleAgreedVisit(input: $input) {
      message
      date
    }
  }
`;

export const EDIT_AGREED_VISIT = gql`
  mutation EditAgreedVisit($input: EditAgreedVisitInput) {
    EditAgreedVisit(input: $input) {
      message
      date
    }
  }
`;

export const DELETE_MULTIPLE_AGREED_VISIT = gql`
  mutation DeleteMultipleAgreedVisit($input: DeleteMultipleAgreedVisitInput) {
    DeleteMultipleAgreedVisit(input: $input) {
      message
      date
    }
  }
`;

export const DELETE_AGREED_VISIT = gql`
  mutation DeleteAgreedVisit($input: DeleteAgreedVisitInput) {
    DeleteAgreedVisit(input: $input) {
      message
      date
    }
  }
`;

export const UPDATE_AGREED_VISITS = gql`
  mutation UpdateAgreedVisits($input: UpdateAgreedVisitInput) {
    UpdateAgreedVisits(input: $input) {
      message
      date
    }
  }
`;

interface GetAgreedShiftsResponse {
  GetAgreedShifts: AgreedShiftRunWeeks;
}

interface GetAgreedShiftsOptimisedResponse {
  GetAgreedVisitsOptimised: AgreedShiftRunWeeksOptimised;
}

const refetch = (input: Record<string, unknown>) => ({
  refetchQueries: [
    {
      query: GET_AGREED_SHIFTS,
      variables: {
        query: {
          ...input,
        },
      },
    },
  ],
});

export const useGetAgreedShifts = (query: AgreedShiftRunSearch) => {
  const { data, loading: agreedShiftsLoading } = useQuery<GetAgreedShiftsResponse>(GET_AGREED_SHIFTS, {
    variables: {
      query: {
        ...query,
      },
    },
  });

  return { agreedShifts: data?.GetAgreedShifts || { week1: [], week2: [] }, agreedShiftsLoading };
};

export const useGetAgreedShiftsOptimised = (query: OptimisedSearch) => {
  const { data, loading: agreedShiftsOptimisedLoading } = useQuery<GetAgreedShiftsOptimisedResponse>(GET_AGREED_SHIFTS_OPTIMISED, {
    variables: {
      query: {
        ...query,
      },
    },
  });

  return { agreedShiftsOptimised: data?.GetAgreedVisitsOptimised || { week1: [], week2: [] }, agreedShiftsOptimisedLoading };
};

export const useSaveAgreedShiftRun = (query: AgreedShiftRunSearch) => {
  const [saveAgreedShiftRun, mutationSaveAgreedShiftRun] = useMutation(SAVE_AGREED_SHIFT_RUN, refetch(query));

  return { saveAgreedShiftRun, mutationSaveAgreedShiftRun };
};

export const useSaveAgreedShift = (query: AgreedShiftRunSearch) => {
  const [saveAgreedShift, mutationSaveAgreedShift] = useMutation(SAVE_AGREED_SHIFT, refetch(query));
  return { saveAgreedShift, mutationSaveAgreedShift };
};

export const useEditAgreeedShiftRun = (query: AgreedShiftRunSearch) => {
  const [editAgreeedShiftRun, mutationEditAgreedShiftRun] = useMutation(EDIT_AGREED_SHIFT_RUN, refetch(query));
  return { editAgreeedShiftRun, mutationEditAgreedShiftRun };
};

export const useRemoveAgreedShiftRun = (query: AgreedShiftRunSearch) => {
  const [removeAgreeedShiftRun, mutationRemoveAgreedShiftRun] = useMutation(REMOVE_AGREED_SHIFT_RUN, refetch(query));
  return { removeAgreeedShiftRun, mutationRemoveAgreedShiftRun };
};

export const useEditAgreedShift = (query: AgreedShiftRunSearch) => {
  const [editAgreeedShift, mutationEditAgreedShift] = useMutation(EDIT_AGREED_SHIFT, refetch(query));
  return { editAgreeedShift, mutationEditAgreedShift };
};

export const useRemoveAgreedShift = (query: AgreedShiftRunSearch) => {
  const [removeAgreeedShift, mutationRemoveAgreedShift] = useMutation(REMOVE_AGREED_SHIFT, refetch(query));
  return { removeAgreeedShift, mutationRemoveAgreedShift };
};

export const useAddAgreedVisit = (query: AgreedShiftRunSearch) => {
  const [addAgreedVisit, mutationAddAgreedVisit] = useMutation(SAVE_AGREED_VISIT, refetch(query));
  return { addAgreedVisit, mutationAddAgreedVisit };
};

export const useAddMultipleAgreedVisit = (query: AgreedShiftRunSearch) => {
  const [addMultipleAgreedVisit, mutationAddMultipleAgreedVisit] = useMutation(SAVE_MULTIPLE_AGREED_VISIT, refetch(query));
  return { addMultipleAgreedVisit, mutationAddMultipleAgreedVisit };
};

export const useEditAgreedVisit = (query: AgreedShiftRunSearch) => {
  const [editAgreedVisit, mutationEditAgreedVisit] = useMutation(EDIT_AGREED_VISIT, refetch(query));
  return { editAgreedVisit, mutationEditAgreedVisit };
};

export const useDeleteMultipleAgreedVisit = (query: AgreedShiftRunSearch) => {
  const [deleteMultipleAgreedVisit, mutationDeleteMultipleAgreedVisit] = useMutation(DELETE_MULTIPLE_AGREED_VISIT, refetch(query));
  return { deleteMultipleAgreedVisit, mutationDeleteMultipleAgreedVisit };
};

export const useDeleteAgreedVisit = (query: AgreedShiftRunSearch) => {
  const [deleteAgreedVisit, mutationDeleteAgreedVisit] = useMutation(DELETE_AGREED_VISIT, refetch(query));
  return { deleteAgreedVisit, mutationDeleteAgreedVisit };
};

export const useUpdateAgreedVisits = (query: AgreedShiftRunSearch) => {
  const [updateAgreedVisits, mutationUpdateAgreedVisit] = useMutation(UPDATE_AGREED_VISITS, refetch(query));
  return { updateAgreedVisits, mutationUpdateAgreedVisit };
};
