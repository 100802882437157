import { AscotExpectedOutcome, AscotReview } from '__generated__/graphql';

export enum WellbeingStatus {
  ON_TRACK = 'On Track',
  OFF_TRACK = 'Off Track',
  SLIGHTLY_OFF_TRACK = 'Slightly Off Track',
  PAUSED = 'Paused',
}

export interface CustomerAscotReview extends AscotReview {
  total: string;
}

export interface CustomerAscotExpectedOutcome extends AscotExpectedOutcome {
  total: string;
}

export interface CustomerReviewData {
  id: string;
  date: number;
  requestedBy: string;
  note: string;
  ascotReviewId: string | undefined;
  ascotReviewTotal: string;
  ascotExpectedOutcomeId: string | undefined;
  ascotExpectedOutcomeTotal: string;
}
