import { Cancel, CheckCircle, Warning, PollOutlined, ArrowBack } from '@mui/icons-material';
import { Helmet } from 'react-helmet';
import { Loading, LocationBar } from 'components';
import { useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useGetSurveyReport } from 'api/hooks/useSurveyReports';
import { AnnotationType } from '../types';
import DimensionGroup from './DimensionGroup';
import QuestionGroup from './QuestionGroup';

const ReportAnnotations = ({ surveyAnnotations, candidateName }: { surveyAnnotations: AnnotationType[]; candidateName: string }) => {
  if (surveyAnnotations.length > 0) {
    return (
      <div className="mt-8 sm:mt-12 ">
        <div className="features-title text-black text-xl sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1">
          During the interview, please consider:
        </div>
        {surveyAnnotations.map((annotation: AnnotationType, index: number) => (
          <div className="flex flex-row items-center gap-2 text-gray-800 text-xl leading-xl mt-4 features print:mb-6" key={index}>
            <Warning style={{ color: '#E86F51' }} />
            <div>
              {annotation.variable && `${annotation.variable}: `}
              {annotation.text}
            </div>
          </div>
        ))}
      </div>
    );
  } else {
    return (
      <div className="mt-8 sm:mt-12">
        <div className="flex flex-row items-center gap-2 text-gray-800 text-xl leading-xl mt-4">
          <CheckCircle style={{ color: '#93CEC7' }} />
          {candidateName} seems to fit in well with the team. We did not find anything out of the ordinary.
        </div>
      </div>
    );
  }
};

const Report = () => {
  const { surveyId } = useParams();
  const navigate = useNavigate();

  const surveyRef = useRef<HTMLDivElement | null>(null);

  const { surveyReport, loading } = useGetSurveyReport({
    id: surveyId,
  });

  const handlePrint = useReactToPrint({
    content: () => surveyRef.current,
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Survey</title>
      </Helmet>
      <LocationBar section="Analytics" page="Survey" Icon={PollOutlined} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-col md:flex-row gap-2">
          <button
            data-cy="survey-detail-back"
            type="button"
            onClick={() => navigate('/survey')}
            className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center gap-2 mb-5"
          >
            <ArrowBack />
            <div className="sm:hidden">Go back</div>
            <div className="hidden md:block">Go back to the list of surveys</div>
          </button>
          <button
            data-cy="survey-detail-download"
            type="button"
            onClick={handlePrint}
            className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center gap-2 mb-5"
          >
            Download PDF
          </button>
          {surveyReport?.candidate_profile?.candidateId && (
            <button
              data-cy="survey-detail-view-candidate"
              type="button"
              onClick={() => navigate(`/candidates/${surveyReport?.candidate_profile?.candidateId}`)}
              className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center gap-2 mb-5"
            >
              Go back to the candidate
            </button>
          )}
        </div>
        <div ref={surveyRef} className="m-10 ">
          <div className="header flex items-start flex-col md:flex-row justify-between mt-16">
            <div className="flex flex-col print:break-before-page">
              <div data-cy="survey-detail-name" className="text-black text-xl sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1">
                Summary for {surveyReport?.candidate_name}
              </div>
              <div className="text-black text-xl sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1" data-cy="survey-detail-score">
                Overall Score : {surveyReport?.score}
              </div>
              <div className="flex flex-row items-center gap-2 text-gray-800 text-xl leading-xl mt-4">{`Circle: ${surveyReport.candidate_profile.circle}`}</div>
              <div className="flex flex-row items-center gap-2 text-gray-800 text-xl leading-xl mt-4">{`Postcode: ${surveyReport.candidate_profile.postcode}`}</div>
              <div className="flex flex-row items-center gap-2 text-gray-800 text-xl leading-xl mt-4">
                {surveyReport.candidate_profile.hasDrivingLicense ? <CheckCircle style={{ color: '#93CEC7' }} /> : <Cancel style={{ color: '#E86F51' }} />} Has
                a driving license
              </div>
              <div className="flex flex-row items-center gap-2 text-gray-800 text-xl leading-xl mt-4">
                {surveyReport.candidate_profile.hasCar ? <CheckCircle style={{ color: '#93CEC7' }} /> : <Cancel style={{ color: '#E86F51' }} />}
                Has a car
              </div>
              <div className="flex flex-row items-center gap-2 text-gray-800 text-xl leading-xl mt-4">
                {surveyReport.candidate_profile.canWorkUnsociableHours ? <CheckCircle style={{ color: '#93CEC7' }} /> : <Cancel style={{ color: '#E86F51' }} />}
                Can work unsociable hours
              </div>
              <div className="flex flex-row items-center gap-2 text-gray-800 text-xl leading-xl mt-4">
                {surveyReport.candidate_profile.canWorkWeekends ? <CheckCircle style={{ color: '#93CEC7' }} /> : <Cancel style={{ color: '#E86F51' }} />}
                Can work weekends
              </div>
            </div>
            <div>
              {surveyReport.commute_times.map((cT: string[], index: number) => (
                <div className="flex flex-row items-center gap-2 text-gray-800 text-xl leading-xl mt-4" key={index}>
                  {`${cT[0]} : ${cT[1]}`}
                </div>
              ))}
            </div>
          </div>
          <div className="mt-10 sm:mt-16 	">
            <ReportAnnotations surveyAnnotations={surveyReport.all_annotations} candidateName={surveyReport.candidate_name} />

            <div className="mt-8 sm:mt-12">
              {surveyReport.dimension_groups && (
                <DimensionGroup
                  dimensionGroup={surveyReport.dimension_groups}
                  hideCompanyAverage={surveyReport.id === 'ecda2755-625b-4edb-85bf-daff2346e6f3'}
                />
              )}
              {surveyReport.question_groups && <QuestionGroup questionGroup={surveyReport.question_groups} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Report;
