import { Maybe } from '__generated__/graphql';
import Tooltip from 'components/Tooltip';
import { useRef } from 'react';
import { ImageSizeType } from 'types/types';

interface AvatarProps {
  avatar: Maybe<string> | undefined;
  alt: string;
  size?: ImageSizeType;
  tooltip?: string;
  border?: boolean;
  borderWhite?: boolean;
  largeBorder?: boolean;
  label?: string;
  testId?: string;
}

const Avatar = ({ testId, avatar, alt, size, tooltip, border, borderWhite, largeBorder, label }: AvatarProps) => {
  const ref = useRef<HTMLImageElement>(null);
  function handleFallback() {
    // Nullify the error event for subsequent calls
    if (ref?.current) {
      ref.current.onerror = null;
      ref.current.src = '/defaultProfile.jpg';
    }
  }

  return (
    <div data-cy={testId}>
      {tooltip ? (
        <Tooltip tooltip={tooltip} right>
          <img
            src={avatar || '/defaultProfile.jpg'}
            alt={alt}
            ref={ref}
            className={`${largeBorder ? 'border-4' : ''} ${border ? 'border border-primary-500' : ''} ${
              borderWhite ? 'border-2 border-primary-100 shadow-lg' : ''
            } relative rounded-full hover:z-10 ${
              size === ImageSizeType.SM
                ? 'w-6 h-6'
                : size === ImageSizeType.MD
                ? 'w-10 h-10'
                : size === ImageSizeType.LG
                ? 'w-14 h-14'
                : size === ImageSizeType.LG2
                ? 'w-20 h-20'
                : size === ImageSizeType.XL
                ? 'w-40 h-40'
                : 'w-8 h-8'
            }`}
            onError={handleFallback}
          />
          {label && <div className="text-md font-medium text-secondary-500">{label}</div>}
        </Tooltip>
      ) : (
        <>
          <img
            src={avatar || '/defaultProfile.jpg'}
            alt={alt}
            ref={ref}
            className={`${largeBorder ? 'border-4' : ''} ${border ? 'border border-primary-500' : ''} ${
              borderWhite ? 'border-2 border-primary-100 shadow-lg' : ''
            } rounded-full hover:z-10 ${
              size === ImageSizeType.SM
                ? 'w-6 h-6'
                : size === ImageSizeType.MD
                ? 'w-10 h-10'
                : size === ImageSizeType.LG
                ? 'w-14 h-14'
                : size === ImageSizeType.LG2
                ? 'w-20 h-20'
                : size === ImageSizeType.XL
                ? 'w-40 h-40'
                : 'w-8 h-8'
            }`}
            onError={handleFallback}
          />
          {label && <div className="text-md font-medium text-secondary-500">{label}</div>}
        </>
      )}
    </div>
  );
};

export default Avatar;
