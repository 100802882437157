import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { Auth } from 'aws-amplify';
import { Voyager, voyagerIntrospectionQuery } from 'graphql-voyager';
import { LocationBar } from 'components';
import { Api } from '@mui/icons-material';
import 'graphql-voyager/dist/voyager.css';

const getData = async () => {
  const session = await Auth.currentSession();
  const accessToken = session.getIdToken();
  const jwt = accessToken.getJwtToken();
  const response = await fetch(process.env.REACT_APP_API_URL || '', {
    method: 'post',
    headers: { Authorization: jwt },
    body: JSON.stringify({ query: voyagerIntrospectionQuery }),
  });
  return response.json();
};

const GraphQlVisualiser = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [introspection, setIntrospection] = useState<any>();

  useEffect(() => {
    setIntrospection(getData());
  }, []);

  return (
    <>
      <Helmet>
        <title>Developer - GraphQl Visualiser</title>
      </Helmet>
      <LocationBar section="Developers" page="GraphQl Visualiser" Icon={Api} />
      <Voyager hideVoyagerLogo={true} introspection={introspection} displayOptions={{ sortByAlphabet: true, skipRelay: false, showLeafFields: true }} />
    </>
  );
};

export default GraphQlVisualiser;
