import React from 'react';
import { ClockIcon } from '@heroicons/react/24/outline';
import {
  BaseSingleInputFieldProps,
  DateValidationError,
  FieldSection,
  LocalizationProvider,
  TimePicker as MUITimePicker,
  TimePickerProps,
  UseDateFieldProps,
  renderTimeViewClock,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';

interface TWDatePickerProps {
  date: Date | undefined | null;
  minTime?: Date;
  maxTime?: Date;
  disabled?: boolean;
  onChange?: (date: Date | null) => void;
}

interface ButtonFieldProps extends UseDateFieldProps<Date>, BaseSingleInputFieldProps<Date | null, Date, FieldSection, DateValidationError> {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

function ButtonField(props: ButtonFieldProps) {
  const { setOpen, value, id, disabled, InputProps: { ref } = {}, inputProps: { 'aria-label': ariaLabel } = {} } = props;

  return (
    <button
      type="button"
      className={`rounded-lg border bg-white border-gray-300 shadow-xs flex place-items-center px-3.5 py-2.5 w-full ${
        disabled ? 'text-gray-300' : 'text-gray-700'
      }`}
      onClick={() => setOpen?.((prev) => !prev)}
      id={id}
      disabled={disabled}
      ref={ref}
      aria-label={ariaLabel}
    >
      <ClockIcon className="w-5 h-5 mr-2" /> {value ? format(value ?? 0, 'kk:mm') : 'Pick a time'}
    </button>
  );
}

function ButtonTimePicker(props: Omit<TimePickerProps<Date>, 'open' | 'onOpen' | 'onClose'>) {
  const [open, setOpen] = React.useState(false);

  return (
    <MUITimePicker
      ampm={false}
      minutesStep={5}
      slots={{ field: ButtonField, ...props.slots }}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      slotProps={{ field: { setOpen } as any }}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      viewRenderers={{
        hours: renderTimeViewClock,
        minutes: renderTimeViewClock,
        seconds: renderTimeViewClock,
      }}
    />
  );
}

const TimePicker = ({ date, minTime, maxTime, disabled = false, onChange }: TWDatePickerProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ButtonTimePicker value={date} onChange={onChange} minTime={minTime} maxTime={maxTime} disabled={disabled} />
    </LocalizationProvider>
  );
};

export default TimePicker;
