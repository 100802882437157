import { useGetCustomers } from 'api/hooks/useCustomers';
import { SelectField } from './SelectField';

interface SupportedPersonSelectFieldProps {
  label: string;
  name: string;
  disabled?: boolean;
  testId?: string;
  searchable?: boolean;
}

const SupportedPersonSelectField = ({ label, name, disabled = false, searchable, testId }: SupportedPersonSelectFieldProps) => {
  const { customers } = useGetCustomers({});
  const supportedPersonOptions = customers.map((s, index) => {
    return { label: `${s.firstName} ${s.lastName}`, value: s.id, id: index.toString() };
  });

  return (
    <SelectField isRequired={true} searchable={searchable} testId={testId} label={label} name={name} options={supportedPersonOptions} disabled={disabled} />
  );
};

export { SupportedPersonSelectField };
