import TrackingItem from './components/TrackingItem';

export default function CandidateTrackingUpdate() {
  return (
    <div className="mt-8 sm:mt-12">
      <div className="flex flex-col gap-1">
        <TrackingItem name="tracking.applicationUploaded" number={1} />
        <TrackingItem name="tracking.autoWelcomeTextAndEmailSent" number={2} />
        <TrackingItem name="tracking.initialCallArranged" number={3} />
        <TrackingItem name="tracking.initialCallDone" number={4} />
        <TrackingItem name="tracking.workshopInviteSent" number={5} />
        <TrackingItem name="tracking.workshopAttended" number={6} />
        <TrackingItem name="tracking.shadowAgreed" number={7} />
        <TrackingItem name="tracking.shadowSuccess" number={8} />
        <TrackingItem name="tracking.jobOfferAccepted" number={9} />
      </div>
      <div className="flex justify-end">
        <button type="submit" className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
          Save
        </button>
      </div>
    </div>
  );
}
