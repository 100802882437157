import { Warning } from '@mui/icons-material';
import { AnnotationType } from '../types';
import { DimensionDescriptions } from '../Constants';

interface DimensionDescriptionProps {
  dimensions: string[];
  annotations: AnnotationType[];
}

const DimensionDescription = ({ dimensions, annotations }: DimensionDescriptionProps) => {
  const dimensionAnnotations: Record<string, AnnotationType> = annotations.reduce((a, v) => ({ ...a, [v.variable ?? '']: v }), {});

  return (
    <div className="mt-5 print:break-before-page">
      {dimensions.map((d: string, index: number) => {
        let dimensionAnnotation = '';
        if (dimensionAnnotations[d]) {
          dimensionAnnotation = dimensionAnnotations[d].text;
        }
        const color = dimensionAnnotation !== '' ? 'error-500' : 'gray';
        return (
          <div key={index} className="mt-5">
            <div className="flex justify-between items-center">
              <div className={`text-2xl mb-1.5 text-${color} leading-4	font-medium	`}>{d}</div>
              {dimensionAnnotation !== '' && <Warning className="text-error-500" />}
            </div>
            {dimensionAnnotation !== '' && <div className="text-error">{dimensionAnnotation}</div>}
            <div className="mt-1 text-gray-500 leading-6	">{DimensionDescriptions[d as keyof typeof DimensionDescriptions]}</div>
          </div>
        );
      })}
    </div>
  );
};

export default DimensionDescription;
