import { useMemo, useState, useEffect, Suspense } from 'react';
import { useUser, UserContextProps } from 'context/userContext';
import { useGeneric, GenericContextProps } from 'context/genericContext';
import { useNavigate } from 'react-router-dom';
import Helmet from 'react-helmet';
import { LocationBar, Loading, MenuButton } from 'components';
import { useGetforms } from 'api/hooks/useForms';
import { useGetSupportWorkers } from 'api/hooks/useSupportWorkers';
import { Form } from '__generated__/graphql';
import { ListAlt } from '@mui/icons-material';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import Table from 'components/Table/copy';
import { MagnifyingGlassCircleIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';

const currentTypes = [
  { value: 'all', label: 'All' },
  { value: 'MED_OPS', label: 'Medical Observations' },
  { value: 'INCIDENT', label: 'Incident' },
];

export default function FormList() {
  const navigate = useNavigate();
  const { user, userLoading } = useUser() as UserContextProps;
  const { teams: genericTeams, teamsLoading } = useGeneric() as GenericContextProps;
  const { forms, loading: formLoading } = useGetforms({});
  const [search, setSearch] = useState('');
  const { supportWorkers, loading: supportWorkerLoading } = useGetSupportWorkers({ showAll: true });
  const teams = useMemo(() => genericTeams?.map((t) => ({ value: t.teamId, label: t.teamName })) || [], [genericTeams]);
  const [filters, setFilters] = useState([
    { name: 'teams', selectedValue: { value: 'all', label: 'All' }, values: [{ value: 'all', label: 'All' }, ...teams] },
    { name: 'currentType', selectedValue: { value: 'all', label: 'All' }, values: currentTypes },
  ]);

  useEffect(() => {
    const myTeam = teams?.find((team) => team.value === user?.teamId);
    const ts = !user?.permissions?.admin && myTeam ? [myTeam] : teams;
    const newFilters = filters;
    newFilters[0].values = [{ value: 'all', label: 'All' }, ...ts];
    setFilters(newFilters);
  }, [filters, teams, user?.permissions?.admin, user?.teamId]);

  const RowActions = ({ row, index }: { row: Form; index: number }) => {
    const getUrl = (f: Form) => (f?.formType === 'MED_OPS' ? `/forms/medical-obvs/${f.id}` : `/forms/incident/${f.id}`);
    return (
      <div className="flex flex-row items-center gap-4">
        <button type="button" onClick={() => navigate(getUrl(row))} aria-label="Edit" className="m-0 p-0">
          <MagnifyingGlassCircleIcon data-cy={`form-view-details-${index}`} className="w-6 h-6 text-gray-500 hover:text-primary600" />
        </button>
      </div>
    );
  };

  const columnHelper = createColumnHelper<Form>();
  const findWorkerName = (id: string) => supportWorkers.find((s) => s.id === id)?.fullName;
  const findTeam = (id: string) => teams.find((t) => t.value === id)?.label;

  const columns = [
    columnHelper.accessor('formType', {
      header: () => 'Type',
      cell: (info) => <span data-cy="list-type">{info.renderValue()}</span>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('supportWorkerId', {
      header: () => 'Support Worker',
      cell: (props) => <span data-cy="list-name">{`${findWorkerName(props.getValue())}`}</span>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('teamId', {
      header: () => 'Team',
      cell: (props) => <span data-cy="list-team">{`${findTeam(props.getValue() || '')}`}</span>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('observingPersonId', {
      header: () => 'Observing Person',
      cell: (props) => <span data-cy="list-observingPerson">{`${findWorkerName(props.getValue())}`}</span>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('dateTimeOfObservation', {
      header: () => 'Observation Date',
      cell: (props) => <span data-cy="list-dateTimeOfObservation">{`${format(props.getValue(), 'dd/MM/yyyy')}`}</span>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('createdAt', {
      header: () => 'Created Date',
      cell: (props) => <span data-cy="list-createdAt">{`${format(props.getValue(), 'dd/MM/yyyy')}`}</span>,
      footer: (info) => info.column.id,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (props) => <RowActions row={props.row.original} index={props.row.index} />,
    }),
  ] as Array<ColumnDef<Form, unknown>>;

  const filterByName = (form: Form) => {
    if (findWorkerName(form.supportWorkerId)?.toLowerCase()?.includes(search.toLowerCase())) return true;
    if (findWorkerName(form.observingPersonId)?.toLowerCase()?.includes(search.toLowerCase())) return true;
    return false;
  };

  const filterByType = (form: Form) => {
    const currentType = filters.find((f) => f.name === 'currentType')?.selectedValue?.value;
    if (currentType?.toLowerCase() === 'all') return true;
    if (form && form.formType?.toLowerCase() === currentType?.toLowerCase()) return true;
    return false;
  };

  const filterByTeam = (form: Form) => {
    const team = filters.find((f) => f.name === 'teams')?.selectedValue?.value;
    if (team === 'all') return true;
    if (form && team && form.teamId === team) return true;
    return false;
  };

  const csv = forms.map((c) => {
    return {
      ...c,
      // competencyChecklist: JSON.stringify(c.medicalObvs?.competencyChecklist).replace(/"/g, "'"),
    };
  });

  const results = forms.filter(filterByTeam).filter(filterByName).filter(filterByType);

  if (formLoading || userLoading || teamsLoading || supportWorkerLoading) return <Loading />;

  return (
    <>
      <Helmet>
        <title>Forms</title>
      </Helmet>
      <LocationBar section="Forms" page="Form List" Icon={ListAlt} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-display-lg leading-display-lg tracking-display-lg text-black font-medium">Forms</div>
          <div className="flex items-center gap-5">
            <MenuButton
              text="Create Form"
              testId="form-menu"
              items={[
                { url: '/forms/medical-obvs/create', text: 'Medical Observations' },
                { url: '/forms/incident/create', text: 'Incident' },
              ]}
            />
          </div>
        </div>
        <div>
          <Suspense fallback={<Loading />}>
            <Table<Form>
              search={search}
              setSearch={setSearch}
              filters={filters}
              csv={{ data: csv, fileName: 'formList' }}
              toggleButtonKey="dueStatus"
              setFilters={setFilters}
              data={results}
              columns={columns}
            />
          </Suspense>
        </div>
      </div>
    </>
  );
}
