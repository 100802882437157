import { Maybe, CustomerPackage, CustomerPackagePrice, PackagePriceTypeEnum, PackagePriceFundingSourceEnum } from '__generated__/graphql';
import { camelCaseToNormalString } from 'services/strings';

export const MapPackage = (customerPackage: Maybe<CustomerPackage> | undefined) => {
  return MapPackageWithPrice(customerPackage, undefined);
};

export const MapPackageWithPrice = (customerPackage: Maybe<CustomerPackage> | undefined, data: CustomerPackagePrice | undefined) => {
  const tmp = customerPackage?.prices || [];
  const tmpPrices = data ? [...tmp, data] : tmp;

  const prices: CustomerPackagePrice[] = (tmpPrices || [])?.map((p) => ({
    endDate: p?.endDate,
    id: p?.id,
    monthlyValue: p?.monthlyValue,
    hourlyValue: p?.hourlyValue,
    numberOfHours: p?.numberOfHours,
    note: p?.note,
    startDate: p?.startDate,
    type: p?.type,
    patientId: p?.patientId,
    localAuthorityId: p?.localAuthorityId,
    localAuthorityName: p?.localAuthorityName,
    dayRate: p?.dayRate,
  }));

  return {
    id: customerPackage?.id,
    customerId: customerPackage?.customerId,
    externalId: customerPackage?.externalId,
    startDate: customerPackage?.startDate,
    endDate: customerPackage?.endDate,
    name: customerPackage?.name,
    fundingSource: customerPackage?.fundingSource,
    prices,
  };
};

const PriceTypes = Object.values(PackagePriceTypeEnum).map((c) => ({ value: c, label: camelCaseToNormalString(c) }));
export const priceTypes = Object.values(PriceTypes);

const FundingSources = Object.values(PackagePriceFundingSourceEnum).map((c) => ({ value: c, label: camelCaseToNormalString(c) }));
export const fundingSources = Object.values(FundingSources);
