import { Auth } from 'aws-amplify';
import { CognitoIdentity } from '@aws-sdk/client-cognito-identity';

const cognitoidentity = new CognitoIdentity({
  region: process.env.REACT_APP_AWS_REGION,
});

export const getCredentials = async () => {
  const userSession = await Auth.currentSession();
  const cognitoUserpool = `cognito-idp.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${process.env.REACT_APP_USERPOOL_ID}`;
  const identityToken = userSession.getIdToken().getJwtToken();

  const getIdResult = await cognitoidentity.getId({
    IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    Logins: { [cognitoUserpool]: identityToken },
  });

  const cognitoCredentialsResult = await cognitoidentity.getCredentialsForIdentity({
    IdentityId: getIdResult.IdentityId,
    Logins: { [cognitoUserpool]: identityToken },
  });
  return {
    accessKeyId: cognitoCredentialsResult.Credentials?.AccessKeyId || '',
    secretAccessKey: cognitoCredentialsResult.Credentials?.SecretKey || '',
    sessionToken: cognitoCredentialsResult.Credentials?.SessionToken,
  };
};
