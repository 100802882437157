import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { getCredentials } from './aws';

type s3OptionsType = {
  bucket: string | undefined;
  region: string | undefined;
  cloudfront: string | undefined;
};

export const uploadFile = async (file: File, filename: string, options: s3OptionsType) => {
  if (!file) {
    return;
  }

  const s3 = new S3Client({
    region: options.region,
    credentials: await getCredentials(),
  });

  const params = {
    Bucket: options.bucket,
    Key: filename,
    Body: file,
  };

  try {
    const command = new PutObjectCommand(params);
    await s3.send(command);
    return `${options.cloudfront}/${filename}`;
  } catch (error) {
    console.error('Error uploading file:', error);
    return '';
  }
};

export const getFilename = (name: string, path: string, file: File | null) => {
  try {
    return `${path}${name}.${file?.name?.split('.')[1]}`;
  } catch (error) {
    console.error('Error uploading file:', error);
    return null;
  }
};

export const hyphenateString = (input: string): string => {
  const lowercased = input.toLowerCase();
  const hyphenated = lowercased.replace(/[\s\W]+/g, '-');
  return hyphenated.replace(/^-+|-+$/g, '');
};

export const getFilenameForChat = (id: string, path: string, file: File) => {
  try {
    const parts = file?.name?.split('.');
    const name = `${id}:${hyphenateString(parts[0] || '')}`;
    return `${path}${name}.${parts[1] || ''}`;
  } catch (error) {
    console.error('Error uploading file:', error);
    return null;
  }
};
