import Select from 'react-select';
import { SelectOption } from 'types/types';

interface ReactMultiSelectProps {
  options: SelectOption[];
  onChange: (options: SelectOption[]) => void;
  selectedValue: SelectOption[];
  disabled?: boolean;
  searchable?: boolean;
  isLoading?: boolean;
  testId?: string;
}

const ReactMultiSelect = ({ testId, options, onChange, selectedValue, disabled = false, searchable = false, isLoading = false }: ReactMultiSelectProps) => {
  return (
    <Select
      isMulti
      options={options}
      isLoading={isLoading}
      placeholder="Please select..."
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#737E8C',
        },
      })}
      className={testId}
      classNamePrefix="multi-select"
      onChange={(o) => onChange(o as SelectOption[])}
      value={selectedValue}
      isSearchable={searchable}
      menuPortalTarget={document.body}
      menuPosition="absolute"
      isClearable={false}
      isDisabled={disabled}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          padding: '5px 10px',
          fontSize: '16px',
          borderRadius: '8px',
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
          hover: {
            borderColor: '#737E8C',
          },
        }),
        singleValue: (baseStyles) => ({
          ...baseStyles,
          color: '#454C54',
        }),
        multiValue: (baseStyles) => ({
          ...baseStyles,
          borderRadius: '16px',
          backgroundColor: '#F9FAFB',
          border: '1px solid #EAECF0',
          padding: '2px 8px',
        }),
        multiValueLabel: (baseStyles) => ({
          ...baseStyles,
          fontSize: '12px',
          color: '#344054',
          fontWeight: 500,
          lineHeight: '18px',
        }),
        multiValueRemove: (baseStyles) => ({
          ...baseStyles,
          color: '#344054',
        }),
        placeholder: (baseStyles) => ({
          ...baseStyles,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          color: '#737E8C',
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        dropdownIndicator: (baseStyles) => ({
          ...baseStyles,
          color: '#737E8C',
          marginLeft: window.innerWidth > 1536 ? '50px' : '10px',
        }),
        indicatorSeparator: (baseStyles) => ({
          ...baseStyles,
          display: 'none',
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          backgroundColor: '#FFF',
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          backgroundColor: state.isSelected ? '#2D5B6C' : '#FFF',
          cursor: 'pointer',
          ':hover': {
            backgroundColor: state.isSelected ? '#2D5B6C' : '#EDF5F7',
          },
          color: state.isSelected ? '#FFF' : '#2D5B6C',
          fontWeight: 'bold',
        }),
      }}
    />
  );
};

export default ReactMultiSelect;
