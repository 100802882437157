import { useState, useMemo, useRef, useCallback } from 'react';
import Helmet from 'react-helmet';
import { useUser, UserContextProps } from 'context/userContext';
import { useGeneric, GenericContextProps } from 'context/genericContext';
import { AdjustmentsHorizontalIcon, PencilIcon, XMarkIcon, EyeIcon } from '@heroicons/react/24/outline';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { LocationBar, Loading, Search, Table, Filters } from 'components';
import { useNavigate } from 'react-router-dom';
import { Customer } from '__generated__/graphql';
import { ElderlyOutlined, SelfImprovement } from '@mui/icons-material';
import { useGetCustomers } from 'api/hooks/useCustomers';
import { ImageSizeType, SelectOption } from 'types/types';
import AvatarGroup from 'components/AvatarGroup';

const SupportedPeopleList = () => {
  const { user, userLoading } = useUser() as UserContextProps;
  const { teams: genericTeam, teamsLoading } = useGeneric() as GenericContextProps;

  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [team, setTeam] = useState(user?.teamId || 'all');
  const teams = genericTeam?.map((t) => ({ value: t.teamId, label: t.teamName })) || [];
  const tableRef = useRef(null);
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
  const [filters, setFilters] = useState([
    { name: 'teams', selectedValue: { value: 'all', label: 'All' }, values: [{ value: 'all', label: 'All' }, ...teams] },
  ]);
  const { customers, loading } = useGetCustomers({ showAll: false });

  const filterByName = (customer: Customer) => {
    if (`${customer.firstName} ${customer.lastName}`.toLowerCase().includes(search.toLowerCase())) return true;
    return false;
  };

  const filterByTeam = (sw: Customer) => {
    if (team === 'all') return true;
    if (team && sw.teamId === team) return true;
    return false;
  };

  const filteredSupportedPeople = () => {
    return customers
      ?.filter((sw: Customer) => filterByTeam(sw))
      .filter((sw: Customer) => filterByName(sw))
      .sort((x: Customer, y: Customer) => (y.dateUpdated || 0) - (x.dateUpdated || 0));
  };

  const onButtonClick = useCallback(
    (sw: Customer, link?: string) => {
      navigate(link ? `/supported-people/${sw.id}/${link}` : `/supported-people/${sw.id}`);
    },
    [navigate],
  );

  const RowActions = useCallback(
    ({ row }: { row: Customer }) => {
      return (
        <div className="flex flex-col sm:flex-row items-center gap-5">
          <button type="button" onClick={() => onButtonClick(row)} aria-label="Edit" className="m-0 p-0">
            <EyeIcon className="w-6 h-6 text-gray-500 hover:text-primary600" />
          </button>
          <button type="button" onClick={() => onButtonClick(row, 'update')} aria-label="Edit" className="m-0 p-0">
            <PencilIcon className="w-6 h-6 text-gray-500 hover:text-primary600" />
          </button>
        </div>
      );
    },
    [onButtonClick],
  );

  const onFilterChange = (name: string, value: SelectOption) => {
    const newFilters = [...filters];
    const filterIndex = newFilters.findIndex((f) => f.name === name);
    newFilters[filterIndex].selectedValue = value;
    setFilters(newFilters);
  };

  const columnVisibility = {
    firstName: true,
    teamName: true,
    linkWorkers: window.innerWidth > 640,
    wellbeing: true,
    actions: true,
  };

  const columnHelper = createColumnHelper<Customer>();

  const columns1 = useMemo(() => {
    return [
      columnHelper.accessor('firstName', {
        header: () => 'Name',
        cell: (info) => (
          <div className="text-md leading-md font-semibold text-gray-700">
            <div className="flex flex-col xl:flex-row items-center font-medium text-gray-900 whitespace-nowrap dark:text-white">
              <img
                className="w-12 h-12 rounded-full"
                src={info.row.original.image?.url || '/defaultProfile.jpg'}
                alt={`Link worker ${info.row.original.firstName}`}
              />
              <div className="ps-3">
                <div className="text-md leading-md font-semibold">{`${info.row.original.firstName} ${info.row.original.lastName}`}</div>
              </div>
            </div>
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('teamName', {
        header: () => 'Team',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('linkWorkers', {
        header: () => 'Link Workers',
        cell: (info) => {
          return (
            <div className="flex-row">
              <AvatarGroup
                avatars={info.row.original.linkWorkers?.map((lw) => ({ avatar: lw?.avatar ?? '', alt: lw?.fullName ?? '', tooltip: lw?.fullName ?? '' })) ?? []}
                size={ImageSizeType.LG}
                max={2}
                border
              />
            </div>
          );
        },
        footer: (info) => info.column.id,
        enableSorting: false,
      }),
      columnHelper.display({
        id: 'wellbeing',
        cell: (props) => {
          return (
            <button
              type="button"
              onClick={() => navigate(`/wellbeing-board/${props.row.original.id}?teamId=${props.row.original.teamId}`)}
              className="text-white bg-primary-600 font-medium rounded-lg text-sm p-2 lg:px-5 lg:py-2.5 text-center inline-flex items-center"
            >
              <SelfImprovement />
              <span className="ml-2 hidden lg:block">See wellbeing board</span>
            </button>
          );
        },
      }),
      columnHelper.display({
        id: 'actions',
        cell: (props) => <RowActions row={props.row.original} />,
      }),
    ] as Array<ColumnDef<Customer, unknown>>;
  }, [columnHelper, RowActions, navigate]);

  const onSaveFilters = () => {
    setTeam(filters[0].selectedValue.value);
    setShowFiltersModal(false);
  };

  if (loading || userLoading || teamsLoading) {
    return <Loading />;
  }

  const rows = filteredSupportedPeople()?.map((c: Customer) => {
    return {
      ...c,
    };
  });
  return (
    <div>
      <Helmet>
        <title>Supported People</title>
      </Helmet>
      <LocationBar section="People" page="Supported People List" Icon={ElderlyOutlined} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-display-sm sm:text-display-lg leading-display-sm sm:leading-display-lg sm:tracking-display-lg text-black font-medium">
            Find people currently supported by Bellevie
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-between mt-8 md:mt-16">
          <div className="flex flex-col md:flex-row items-center gap-4 md:gap-9 w-full md:w-fit">
            {/* <ToggleButtons buttons={['All', 'New', 'Active', 'Terminated']} selectedButton={filterStatus} onSelectButton={changeFilter} /> */}
            <Search value={search} onSearch={setSearch} />
            <div className="flex flex-col md:flex-row items-center gap-3 w-full md:w-fit">
              <button
                type="button"
                className={`relative border ${
                  team === 'all' ? 'border-gray-300' : 'border-gray-900'
                }  text-gray-700 text-md leading-md flex items-center px-4 py-2.5 rounded-lg shadow-xs justify-center w-full md:w-fit`}
                onClick={() => setShowFiltersModal(true)}
              >
                <AdjustmentsHorizontalIcon className="w-5 h-5 mr-2" />
                Filters
                {team !== 'all' && (
                  <span className="absolute bg-gray-900 h-5 w-5 text-white rounded-full -top-2 -right-2 flex items-center justify-center text-xs font-semibold">
                    1
                  </span>
                )}
              </button>
              {team !== 'all' && (
                <div className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center">
                  {teams.find((t) => t.value === team)?.label}
                  <button
                    type="button"
                    aria-label="remove team filter"
                    className="text-gray-500"
                    onClick={() => {
                      onFilterChange('teams', { value: 'all', label: 'All' });
                      setTeam('all');
                    }}
                  >
                    <XMarkIcon className="w-4 h-4 ml-2" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="mt-3 md:mt-12 mb-10" ref={tableRef}>
          {rows && rows.length > 0 && <Table<Customer> data={rows} columns={columns1} columnVisibility={columnVisibility} />}
        </div>
      </div>

      {showFiltersModal && (
        <Filters filters={filters} onFilterChange={onFilterChange} onCloseModal={() => setShowFiltersModal(false)} onSaveFilters={onSaveFilters} />
      )}
    </div>
  );
};

export default SupportedPeopleList;
