const ContractedHoursHeader = () => {
  return (
    <>
      <div className="h-[80px] lg:ml-[15%] mt-20 lg:mt-0 lg:w-[10%] p-2 border-t border-l border-gray-100 flex flex-col justify-center items-center">
        <div className="text-md lg:text-lg leading-md lg:leading-lg text-gray-800 font-semibold text-center">Hours / week</div>
      </div>
      <div className="h-[80px] lg:w-[13%] p-2 border-t border-l border-gray-100 flex flex-col justify-center items-center">
        <div className="text-md lg:text-lg leading-md lg:leading-lg text-gray-800 font-semibold text-center">Two team meetings / rota</div>
      </div>
      <div className="h-[80px] lg:w-[13%] p-2 border-t border-l border-gray-100 flex flex-col justify-center items-center">
        <div className="text-md lg:text-lg leading-md lg:leading-lg text-gray-800 font-semibold text-center">Hours available for shifts / rota</div>
      </div>
      <div className="h-[80px] lg:w-[12%] p-2 border-t border-l border-gray-100 flex flex-col justify-center items-center">
        <div className="text-md lg:text-lg leading-md lg:leading-lg text-gray-800 font-semibold text-center">Week 1 agreed shifts</div>
      </div>
      <div className="h-[80px] lg:w-[12%] p-2 border-t border-l border-gray-100 flex flex-col justify-center items-center">
        <div className="text-md lg:text-lg leading-md lg:leading-lg text-gray-800 font-semibold text-center">Week 2 agreed shifts</div>
      </div>
      <div className="h-[80px] lg:w-[12%] p-2 border-t border-l border-gray-100 flex flex-col justify-center items-center">
        <div className="text-md lg:text-lg leading-md lg:leading-lg text-gray-800 font-semibold text-center">Agreed shifts / rota</div>
      </div>
      <div className="h-[80px] lg:w-[13%] p-2 border-t border-l lg:border-r border-gray-100 flex flex-col justify-center items-center">
        <div className="text-md lg:text-lg leading-md lg:leading-lg text-gray-800 font-semibold text-center">Hours to make up all paid time</div>
      </div>
    </>
  );
};

export default ContractedHoursHeader;
