import { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Helmet from 'react-helmet';
import { useGeneric, GenericContextProps } from 'context/genericContext';
import { useGetCustomers } from 'api/hooks/useCustomers';
import { ElderlyOutlined } from '@mui/icons-material';
import { SelectOption } from 'types/types';
import { LocationBar, Loading, ToggleButtons, Search, Table, Filters } from 'components';
import { AdjustmentsHorizontalIcon, ArrowUpTrayIcon, PencilIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { ColumnDef, RowSelectionState, createColumnHelper } from '@tanstack/react-table';
import { dateIs7DaysOld } from 'services/helpers';
import { format } from 'date-fns';
import { CSVLink } from 'react-csv';
import { Customer } from '../../__generated__/graphql';

const SupportedPeopleList = () => {
  const { teams: genericTeam, teamsLoading } = useGeneric() as GenericContextProps;

  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [filterStatus, setFilterStatus] = useState('All');
  const [team, setTeam] = useState('all');
  const teams = useMemo(() => genericTeam?.map((t) => ({ value: t.teamId, label: t.teamName })) || [], [genericTeam]);
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState([
    { name: 'teams', selectedValue: { value: 'all', label: 'All' }, values: [{ value: 'all', label: 'All' }, ...teams] },
  ]);
  const columnVisibility = {
    firstName: true,
    teamName: window.innerWidth > 640,
    active: true,
    birdieId: window.innerWidth > 1024,
    lat: window.innerWidth > 1024,
    dateCreated: window.innerWidth > 1280,
  };

  const { customers, loading } = useGetCustomers({ showAll: true });

  useEffect(() => {
    const teamSearch = searchParams.get('teams');
    const newFilters = filters;
    if (teamSearch) {
      setTeam(teamSearch);
      const teamOption = genericTeam?.map((t) => ({ value: t.teamId, label: t.teamName })).find((t) => t.value === teamSearch);
      if (teamOption) {
        newFilters[0].selectedValue = teamOption;
      }
    }
    setFilters(newFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genericTeam]);

  useEffect(() => {
    const newFilters = filters;
    newFilters[0].values = [{ value: 'all', label: 'All' }, ...teams];
    setFilters(newFilters);
  }, [filters, teams]);

  const filterByStatus = useCallback(
    (c: Customer) => {
      if (filterStatus === 'All') return true;
      if (filterStatus === 'New' && c.dateCreated && dateIs7DaysOld(c.dateCreated)) return true;
      if (filterStatus === 'Active' && c.active) return true;
      if (filterStatus === 'Inactive' && !c.active) return true;
      return false;
    },
    [filterStatus],
  );

  const filterByTeam = useCallback(
    (c: Customer) => {
      if (team === 'all') return true;
      if (team && c.teamId === team) return true;
      return false;
    },
    [team],
  );

  const filterByName = useCallback(
    (c: Customer) => {
      if (`${c.firstName} ${c.lastName}`.toLowerCase().includes(search.toLowerCase())) return true;
      return false;
    },
    [search],
  );

  const filteredCustomers = useCallback(() => {
    return customers
      .filter((c) => filterByTeam(c))
      .filter((c) => filterByName(c))
      .filter((c) => filterByStatus(c))
      .sort((x, y) => (y.dateUpdated || 0) - (x.dateUpdated || 0));
  }, [customers, filterByName, filterByStatus, filterByTeam]);

  const onButtonClick = useCallback(
    (sw: Customer) => {
      navigate(`/admin-people/${sw.id}`);
    },
    [navigate],
  );

  const RowActions = useCallback(
    ({ row }: { row: Customer }) => {
      return (
        <button data-cy="list-edit-button" type="button" onClick={() => onButtonClick(row)} aria-label="Edit" className="m-0 p-0">
          <PencilIcon className="w-6 h-6 text-gray-500 hover:text-primary600" />
        </button>
      );
    },
    [onButtonClick],
  );

  const onFilterChange = (name: string, value: SelectOption) => {
    const newFilters = [...filters];
    const filterIndex = newFilters.findIndex((f) => f.name === name);
    newFilters[filterIndex].selectedValue = value;
    setFilters(newFilters);
  };
  const columnHelper = createColumnHelper<Customer>();

  const columns1 = useMemo(() => {
    return [
      {
        id: 'select-col',
        header: ({ table }) => (
          <div className="flex items-center">
            <input
              onChange={table.getToggleAllRowsSelectedHandler()}
              checked={table.getIsAllRowsSelected()}
              type="checkbox"
              className="w-4 h-4 text-primary-700  bg-gray-100 border-gray-300 rounded cursor-pointer"
            />
          </div>
        ),
        cell: ({ row }) => (
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={row.getIsSelected()}
              disabled={!row.getCanSelect()}
              onChange={row.getToggleSelectedHandler()}
              className="w-4 h-4 text-primary-700  bg-gray-100 border-gray-300 rounded cursor-pointer"
            />
          </div>
        ),
      },
      columnHelper.accessor('firstName', {
        header: () => 'Name',
        cell: (info) => {
          const imageHash = Date.now();

          return (
            <div className="text-md leading-md font-semibold text-gray-700">
              <div className="flex items-center font-medium text-gray-900 dark:text-white">
                <img
                  className="w-12 h-12 rounded-full"
                  src={info.row.original.image?.url ? `${info.row.original.image?.url}?${imageHash}` : '/defaultProfile.jpg'}
                  alt={`Link worker ${info.row.original.firstName}`}
                />
                <div className="ps-3">
                  <div data-cy="list-name" className="text-md leading-md font-semibold">{`${info.row.original.firstName} ${info.row.original.lastName}`}</div>
                </div>
              </div>
            </div>
          );
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('teamName', {
        header: () => 'Team',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('active', {
        header: () => 'Team Hub Status',
        cell: (props) => {
          if (!props.row.original.active) {
            return (
              <div
                data-cy="list-inactive"
                className="bg-error-50 border border-error-700 rounded-2xl text-error-700 text-sm leading-sm font-medium px-2.5 py-0.5 w-fit"
              >
                Inactive
              </div>
            );
          } else if (props.row.original.active) {
            return (
              <div
                data-cy="list-active"
                className="bg-success-50 border border-success-700 rounded-2xl text-success-700 text-sm leading-sm font-medium px-2.5 py-0.5 w-fit"
              >
                Active
              </div>
            );
          }
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('birdieId', {
        header: () => 'Birdie',
        cell: (props) => {
          if (!props.row.original.birdieId && !props.row.original.phone) {
            return (
              <div
                data-cy="list-birdie-no-phone"
                className="bg-error-50 border border-error-700 rounded-2xl text-error-700 text-sm leading-sm font-medium px-2.5 py-0.5 w-fit"
              >
                No Phone
              </div>
            );
          }
          if (!props.row.original.birdieId && props.row.original.phone) {
            return (
              <div
                data-cy="list-birdie-inactive"
                className="bg-error-50 border border-error-700 rounded-2xl text-error-700 text-sm leading-sm font-medium px-2.5 py-0.5 w-fit"
              >
                Inactive
              </div>
            );
          }
          if (props.row.original.birdieId && props.row.original.active) {
            return (
              <div
                data-cy="list-birdie-active"
                className="bg-success-50 border border-success-700 rounded-2xl text-success-700 text-sm leading-sm font-medium px-2.5 py-0.5 w-fit"
              >
                Active
              </div>
            );
          }
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('lat', {
        header: () => 'Team Hub Maps',
        cell: (props) => {
          if ((props.row.original.lat === 0 || props.row.original.lng === 0) && !props.row.original.active) {
            return (
              <div
                data-cy="list-maps-inactive"
                className="bg-error-50 border border-error-700 rounded-2xl text-error-700 text-sm leading-sm font-medium px-2.5 py-0.5 w-fit"
              >
                Inactive
              </div>
            );
          } else if (props.row.original.lat !== 0 && props.row.original.lng !== 0 && props.row.original.active) {
            return (
              <div
                data-cy="list-maps-active"
                className="bg-success-50 border border-success-700 rounded-2xl text-success-700 text-sm leading-sm font-medium px-2.5 py-0.5 w-fit"
              >
                Active
              </div>
            );
          }
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('dateCreated', {
        header: () => 'Date Created',
        cell: (info) => format(info.renderValue() ?? 0, 'dd/MM/yy'),
        footer: (info) => info.column.id,
      }),
      columnHelper.display({
        id: 'actions',
        cell: (props) => <RowActions row={props.row.original} />,
      }),
    ] as Array<ColumnDef<Customer, unknown>>;
  }, [columnHelper, RowActions]);

  const onSaveFilters = () => {
    const newSearchParamsArray = filters.map((f) => ({ [f.name]: f.selectedValue.value }));
    setSearchParams(Object.assign({}, ...newSearchParamsArray));
    setTeam(filters[0].selectedValue.value);
    setShowFiltersModal(false);
  };

  const rows = filteredCustomers().map((c) => {
    return {
      ...c,
    };
  });

  const csv = useMemo(() => {
    const selectedIndexes = Object.keys(rowSelection);
    const csvCandidate = selectedIndexes?.length > 0 ? selectedIndexes.map((i: string) => filteredCustomers()[parseInt(i, 10)]) : filteredCustomers();
    return csvCandidate.map((c) => {
      const notes = c.notes?.map((n) => {
        return { id: n?.id, date: format(n?.date ?? 0, 'dd/MM/yy'), note: n?.note };
      });

      const reviews = c.reviews?.map((r) => {
        return { id: r?.id, date: format(r?.date ?? 0, 'dd/MM/yy'), note: r?.note, requestedBy: r?.requestedBy };
      });
      return {
        firstName: c.firstName,
        lastName: c.lastName,
        nhsNumber: c.nhsNumber,
        address: c.address,
        city: c.city,
        phone: c.phone,
        postcode: c.postcode,
        profileData: JSON.stringify(c.profileData)?.replace(/"/g, "'"),
        teamName: c.teamName,
        linkWorkers: JSON.stringify(c.linkWorkers)?.replace(/"/g, "'"),
        wellbeingStatus: c.wellbeingStatus,
        notes: JSON.stringify(notes)?.replace(/"/g, "'"),
        reviews: JSON.stringify(reviews)?.replace(/"/g, "'"),
        familyMembers: JSON.stringify(c.familyMembers)?.replace(/"/g, "'"),
        firstVisitDate: format(c.firstVisitDate ?? 0, 'dd/MM/yy'),
        lat: c.lat,
        lng: c.lng,
        birdieId: c.birdieId,
        active: c.active,
        dateUpdated: format(c.dateUpdated ?? 0, 'dd/MM/yy'),
        dateCreated: format(c.dateCreated ?? 0, 'dd/MM/yy'),
        teamId: c.teamId,
        circleId: c.circleId,
        circleName: c.circleName,
        birdie: c?.birdieId && c?.active ? 'Active' : 'Inactive',
        teamHub: c?.active ? 'Active' : 'Inactive',
        displayedOnMaps: !c?.active && (c.lat !== 0 || c.lng !== 0),
        computeTravelTime: !c?.active && (c.lat !== 0 || c.lng !== 0),
        id: c.id,
        image: c.image?.url,
      };
    });
  }, [filteredCustomers, rowSelection]);

  if (loading || teamsLoading) {
    return <Loading />;
  }

  return (
    <div>
      <Helmet>
        <title>Supported People</title>
      </Helmet>
      <LocationBar section="Admin" page="Supported People List" Icon={ElderlyOutlined} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-display-sm sm:text-display-lg leading-display-sm sm:leading-display-lg sm:tracking-display-lg text-black font-medium">
            Supported People
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-between mt-8 md:mt-16">
          <div className="flex flex-col md:flex-row items-center gap-4 md:gap-9 w-full md:w-fit">
            <ToggleButtons
              testId="status-filter"
              buttons={['All', 'New', 'Active', 'Inactive']}
              selectedButton={filterStatus}
              onSelectButton={setFilterStatus}
            />
            <Search value={search} onSearch={setSearch} />
            <div className="flex flex-col md:flex-row items-center gap-3 w-full md:w-fit">
              <button
                data-cy="show-filters-button"
                type="button"
                className={`relative border ${
                  team === 'all' && filterStatus === 'all' ? 'border-gray-300' : 'border-gray-900'
                }  text-gray-700 text-md leading-md flex items-center px-4 py-2.5 rounded-lg shadow-xs justify-center w-full md:w-fit`}
                onClick={() => setShowFiltersModal(true)}
              >
                <AdjustmentsHorizontalIcon className="w-5 h-5 mr-2" />
                Filters
                {team !== 'all' && (
                  <span className="absolute bg-gray-900 h-5 w-5 text-white rounded-full -top-2 -right-2 flex items-center justify-center text-xs font-semibold">
                    1
                  </span>
                )}
              </button>
              {team !== 'all' && (
                <div className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center">
                  {teams.find((t) => t.value === team)?.label}
                  <button
                    type="button"
                    aria-label="remove team filter"
                    className="text-gray-500"
                    onClick={() => {
                      onFilterChange('teams', { value: 'all', label: 'All' });
                      const newSearchParamsArray = filters.map((f) => ({ [f.name]: f.selectedValue.value }));
                      setSearchParams(Object.assign({}, ...newSearchParamsArray));
                      setTeam('all');
                    }}
                  >
                    <XMarkIcon className="w-4 h-4 ml-2" />
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between lg:justify-normal gap-3 md:gap-9 w-full lg:w-fit mt-6 lg:mt-0">
            <CSVLink filename="supportedPerson.csv" data={csv} className="text-gray-500 text-sm leading-sm font-semibold flex items-center hover:no-underline">
              <ArrowUpTrayIcon className="w-5 h-5 mr-2" />
              Export CSV
            </CSVLink>
          </div>
        </div>

        <div className="mt-3 md:mt-12 mb-10 overflow-x-auto overflow-y-hidden">
          {rows && rows.length > 0 && (
            <Table<Customer> data={rows} columns={columns1} columnVisibility={columnVisibility} rowSelection={rowSelection} setRowSelection={setRowSelection} />
          )}
        </div>
      </div>

      {showFiltersModal && (
        <Filters filters={filters} onFilterChange={onFilterChange} onCloseModal={() => setShowFiltersModal(false)} onSaveFilters={onSaveFilters} />
      )}
    </div>
  );
};

export default SupportedPeopleList;
