import { useState } from 'react';
import DatePicker from 'components/DatePicker';
import { CustomerPackage } from '__generated__/graphql';
import { packageOverlapping, setStartOfDay } from './service';

interface PackageUpdateProps {
  customerPackages: CustomerPackage[];
  customerPackage: CustomerPackage | undefined;
  onUpdate: (startDate: CustomerPackage) => void;
}

export default function UpdatePackage({ customerPackage, customerPackages, onUpdate }: PackageUpdateProps) {
  const [validation, setValidation] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(new Date(customerPackage?.startDate || 0));

  const onSavePackage = () => {
    if (!customerPackage?.id) return;
    setValidation(null);

    const newDate = setStartOfDay(startDate);
    const hasOverlap = packageOverlapping(customerPackages, customerPackage?.customerId || '', newDate, customerPackage?.id);

    if (hasOverlap) {
      setValidation('This date overlaps with an existing package');
      return;
    }

    if (startDate) {
      onUpdate({
        ...customerPackage,
        startDate: newDate,
      });
    }
  };

  return (
    <div className="mt-8 sm:mt-12">
      {validation && <div className="text-sm leading-lg mt-1 mb-4 text-red-800">{validation}</div>}
      <DatePicker testId="package-start-date" value={startDate ?? undefined} onChange={setStartDate} disabled={customerPackage?.endDate !== null} />
      <div className="flex justify-end mt-6">
        {!customerPackage?.endDate && (
          <button
            type="button"
            data-cy="save-package-button"
            className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
            onClick={onSavePackage}
          >
            Save
          </button>
        )}
      </div>
    </div>
  );
}
