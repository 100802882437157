// import { format } from 'date-fns';
import { Absence, Customer, ReportingHoursPeriod, SupportWorker } from '__generated__/graphql';
import { getHoursToDaysHours } from 'services/helpers';
import { useReviewStats } from 'pages/ReviewStats/useReviewStats';

interface KpiStatsProps {
  customers: Customer[];
  supportWorkers: SupportWorker[];
  leave: Absence[];
  reportingHoursPeriod: ReportingHoursPeriod[];
  previousReportingHoursPeriod: ReportingHoursPeriod[];
  startDateTime: number;
  endDateTime: number;
  previousDateTime: number;
}

interface KpiStatItemProps {
  title: string;
  total: number | string;
  previous?: number | string;
}

const KpiStatItem = ({ title, total, previous }: KpiStatItemProps) => {
  return (
    <div className="rounded-lg bg-primary-600 px-6 py-4 w-full">
      <div className="text-sm leading-sm font-semibold text-white flex items-center">{title}</div>
      <div className="text-white text-display-md leading-display-md tracking-display-md font-medium mt-2">{total}</div>
      {previous && (
        <>
          <div className="text-sm leading-sm text-white flex items-center mt-2">Previous</div>
          <div className="text-white text-display-xs leading-display-md tracking-display-md mt-2">{previous}</div>
        </>
      )}
    </div>
  );
};

export const calculateLeave = (data: Absence[]): number => {
  return data.map((x) => x.durationInPeriod || 0).reduce((a, b) => a + b, 0);
};

export const calculateBankedHours = (data: ReportingHoursPeriod[]): number => {
  return data.map((x) => x.bankedHours || 0).reduce((a, b) => a + b, 0);
};

const KpiStats = ({
  customers,
  supportWorkers,
  leave,
  reportingHoursPeriod,
  previousReportingHoursPeriod,
  startDateTime,
  endDateTime,
  previousDateTime,
}: KpiStatsProps) => {
  const leaveTotal = getHoursToDaysHours(calculateLeave(leave));
  const { overdue } = useReviewStats(customers);
  const reviewsOverdue = overdue;

  const sickOrHoliday = `${leaveTotal.days} days ${leaveTotal.hours} hrs`;
  const supportWorkersOnboarding = 0;

  const bankedTotal = calculateBankedHours(reportingHoursPeriod);
  const bankedHoursTotal = bankedTotal > 0 ? getHoursToDaysHours(bankedTotal / 60) : getHoursToDaysHours(0);
  const bankedHoursInccured = `${bankedHoursTotal.days} days ${bankedHoursTotal.hours} hrs`;

  const previousBankedTotal = calculateBankedHours(previousReportingHoursPeriod);
  const previousBankedHoursTotal = previousBankedTotal > 0 ? getHoursToDaysHours(previousBankedTotal / 60) : getHoursToDaysHours(0);
  const previousBankedHoursInccured = `${previousBankedHoursTotal.days} days ${previousBankedHoursTotal.hours} hrs`;

  const customersTotal = customers.filter((s) => (s?.dateCreated || 0) <= endDateTime).length.toString();
  const newPeopleWeSupport = customers.filter((c) => (c?.dateCreated || 0) >= startDateTime && (c?.dateCreated || 0) <= endDateTime).length.toString();
  const previousNewPeopleWeSupport = customers
    .filter((c) => (c?.dateCreated || 0) >= previousDateTime && (c?.dateCreated || 0) <= startDateTime)
    .length.toString();
  const previousSp = customers.filter((s) => (s?.dateCreated || 0) <= startDateTime).length.toString();

  const supportWorkersTotal = supportWorkers.filter((s) => (s?.dateCreated || 0) <= endDateTime).length.toString();
  const newWswStarters = supportWorkers.filter((s) => (s?.dateCreated || 0) >= startDateTime && (s?.dateCreated || 0) <= endDateTime).length.toString();
  const previousNewWswStarters = supportWorkers
    .filter((s) => (s?.dateCreated || 0) >= previousDateTime && (s?.dateCreated || 0) <= startDateTime)
    .length.toString();
  const previousSw = supportWorkers.filter((s) => (s?.dateCreated || 0) <= startDateTime).length.toString();

  return (
    <>
      <div className="flex flex-row gap-4 md:gap-6 w-full mt-7 md:mt-12 overflow-auto">
        <KpiStatItem title="Total support workers" total={supportWorkersTotal} previous={previousSw} />
        <KpiStatItem title="Total supported people" total={customersTotal} previous={previousSp} />
        <KpiStatItem title="People on holiday/sick leave" total={sickOrHoliday} />
        <KpiStatItem title="Support workers in onboarding" total={supportWorkersOnboarding} />
      </div>
      <div className="flex flex-row gap-4 md:gap-6 w-full mt-7 md:mt-12 overflow-auto">
        <KpiStatItem title="New support worker starters" total={newWswStarters} previous={previousNewWswStarters} />
        <KpiStatItem title="New people we support" total={newPeopleWeSupport} previous={previousNewPeopleWeSupport} />
        <KpiStatItem title="Banked hours inccured" total={bankedHoursInccured} previous={previousBankedHoursInccured} />
        <KpiStatItem title="Reviews overdue" total={reviewsOverdue} />
      </div>
    </>
  );
};

export default KpiStats;
