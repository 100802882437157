import { RekognitionClient, DetectTextCommand, DetectTextCommandOutput } from '@aws-sdk/client-rekognition';
import { useState } from 'react';
import FileInput from 'components/FileInput';
import { getCredentials } from 'services/aws';
import { useFormContext } from 'react-hook-form';
import { TextAreaField } from 'components/Fields/TextAreaField';
import { FormLabel } from 'components';
import Loading from '../../../components/Loading';

const UploadImageButton = ({ name }: { name: string }) => {
  const [fileError, setFileError] = useState('');
  const [imageLoader, setImageLoader] = useState(false);
  const { setValue, getValues } = useFormContext(); // retrieve all hook methods
  const getAsByteArray = async (file: File) => {
    if (file) {
      const buff = await file.arrayBuffer();
      return new Uint8Array(buff);
    }
    return null;
  };

  const getTextFromDetection = (detection: DetectTextCommandOutput) => {
    const textArray: string[] = [];
    detection.TextDetections?.forEach((textDetection) => {
      if (textDetection.Type === 'LINE' && textDetection.Confidence && textDetection.Confidence > 90 && textDetection.DetectedText) {
        textArray.push(textDetection.DetectedText);
      }
    });
    return textArray.join(' ');
  };

  const handleChange = async (file: File | null) => {
    if (file) {
      setImageLoader(true);
      const byteFile = await getAsByteArray(file);
      if (byteFile) {
        const command = new DetectTextCommand({
          Image: {
            Bytes: byteFile,
          },
        });
        try {
          const client = new RekognitionClient({
            region: 'eu-west-2',
            credentials: await getCredentials(),
          });

          const data = await client.send(command);
          client.destroy();
          const values = getValues();
          const notes = values[name] || '';
          setValue(name, notes.concat(' ', getTextFromDetection(data)));
          setImageLoader(false);
        } catch (error) {
          setFileError(error as string);
          setImageLoader(false);
        }
      }
    }
  };

  return (
    <FormLabel label="Import a photo of your meeting notes">
      <FileInput accept="image/*" handleUpload={handleChange} placeholder="Import a photo of your meeting notes" />

      {imageLoader && <Loading />}
      <p className="mt-2 text-sm text-error-600 dark:text-green-500">
        <span className="font-medium">{fileError}</span>
      </p>
    </FormLabel>
  );
};

const ImageToTextField = ({ name, label, placeholder, testId }: { name: string; label: string; placeholder: string; testId: string }) => {
  return (
    <div className="flex flex-col gap-2 ">
      <UploadImageButton name={name} />
      <TextAreaField name={name} label={label} placeholder={placeholder} testId={testId} />
    </div>
  );
};

export { ImageToTextField };
