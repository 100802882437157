/* eslint-disable max-len */
import { useState } from 'react';
import { Check, ContentCopy } from '@mui/icons-material';
import { TextAreaField } from 'components/Fields/TextAreaField';
import { InputField } from 'components/Fields/InputField';
import { DateField } from 'components/Fields/DateField';
import { SupportWorkerSelectField } from 'components/Fields/SupportWorkerSelectField';

export default function CandidateShadowFeedback() {
  const [copySuccess, setCopySuccess] = useState(false);

  const copyShadowFeedbackUrl = async () => {
    await navigator.clipboard.writeText(`${window.location.href}/shadow-feedback`);
    setCopySuccess(true);
  };

  return (
    <div className="mt-4">
      <button
        type="button"
        className={`flex flex-row ${copySuccess ? 'text-success-700' : 'text-gray-700'} text-lg leading-lg mb-6 sm:mb-8`}
        onClick={copyShadowFeedbackUrl}
      >
        {copySuccess ? <Check className="mr-2" /> : <ContentCopy className="mr-2" />} {copySuccess ? 'Copied!' : 'Copy public url'}
      </button>

      <SupportWorkerSelectField isRequired={false} label="Person carrying out observation" name="shadowFeedback.personObserving" />
      <DateField label="Date of observation" name="shadowFeedback.observationDate" />
      <InputField
        name="shadowFeedback.communication"
        placeholder="Communication"
        label="Communication skills – did they communicate with people we support successfully, and appropriately? Did they generate rapport?"
      />
      <InputField
        name="shadowFeedback.listening"
        placeholder="Listening skills"
        label="Listening skills – was there a balance of speaking and listening? Did they listen actively?"
      />
      <InputField
        name="shadowFeedback.engagementWillingness"
        placeholder="Engagement and willingness"
        label="Engagement and willingness – did they show a willingness to 'get stuck in' with tasks? Was there any squeamishness about tasks? Can you see this person bringing their whole self to work?"
      />
      <InputField
        name="shadowFeedback.conscientiousness"
        placeholder="Conscientiousness"
        label="Conscientiousness – did they remain attentive and engaged throughout the shift?*"
      />
      <InputField
        name="shadowFeedback.teamPlayer"
        placeholder="Team Work"
        label="Team – are they a team player? Can you imagine them fitting in well with existing team members?"
      />
      <InputField
        name="shadowFeedback.values"
        placeholder="Values"
        label="Values – do their values chime with ours? (We build trusted relationships, have compassion, ambition, creativity; we strive to be the best, share accountability and make life worth living)"
      />
      <TextAreaField
        label="Any other observations? – was there anything that troubled you? Even slightly?Was there anything that particularly impressed you?"
        name="shadowFeedback.other"
        placeholder="Any other observations?"
        isRequired={false}
      />
      <div className="flex justify-end">
        <button type="submit" className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
          Save
        </button>
      </div>
    </div>
  );
}
