import { Download } from '@mui/icons-material';
import { ChatMessage } from '__generated__/graphql';
import { format, isSameDay } from 'date-fns';
import { useEffect, useState } from 'react';
import { ImageSizeType } from 'types/types';
import Linkify from 'react-linkify';
import Avatar from 'components/Avatar';

interface BubbleProps {
  message: ChatMessage;
  lastDate: number;
  userId: string;
  setLastDate: (value: number) => void;
}

const Bubble = ({ message, lastDate, userId, setLastDate }: BubbleProps) => {
  const [showDate, setShowDate] = useState(false);
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    if (!isSameDay(message.createdAt ?? 0, lastDate)) {
      setLastDate(message.createdAt ?? 0);
      setShowDate(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const componentDecorator = (href: string, text: string, key: number) => (
    <a className="underline" href={href} key={key} target="_blank" rel="noreferrer">
      {text}
    </a>
  );

  return (
    <div>
      {showDate && <div className="text-center text-gray-500 text-md leading-md my-6">{format(message.createdAt ?? 0, 'MMM dd, yyyy')}</div>}
      <div className={`flex ${message.user?._id === userId ? 'flex-row-reverse' : 'flex-row'} items-end gap-2`}>
        {message.user && message.user?._id !== userId && (
          <div className="ml-6">
            <Avatar size={ImageSizeType.LG} avatar={message.user?.avatar ? message.user?.avatar : '/defaultProfile.jpg'} alt={message.user?.name ?? ''} />
          </div>
        )}
        <div className={`flex ${message.user?._id === userId ? 'justify-end' : 'justify-start'} w-1/2`}>
          <div className={`${message.user?._id === userId ? 'bg-primary-700 mr-2' : 'bg-gray-50'} px-3 py-2 rounded-lg w-fit shadow-xs`}>
            {message.image && !imageError && (
              <div className="relative">
                <img
                  src={message.image}
                  alt={message.text ?? 'chat image'}
                  className="mb-2 rounded-lg"
                  onError={() => {
                    setImageError(true);
                  }}
                />
                <a
                  className="absolute bottom-2 right-2 bg-primary-700 rounded-md p-1"
                  aria-label={message.image}
                  href={message.image}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Download className="text-white" />
                </a>
              </div>
            )}
            {message.file && (
              <a
                aria-label={message.file.name ?? ''}
                href={message.file.url ?? ''}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white flex justify-between gap-2 items-center border-b border-white pb-2 mb-2"
              >
                <div className="text-lg leading-lg">{message.file.name}</div>
                <Download className="text-white" />
              </a>
            )}
            <div className={`${message.user?._id === userId ? 'text-white' : 'text-gray-800'} text-lg leading-lg mb-1 break-words`}>
              <Linkify componentDecorator={componentDecorator}>{message.text}</Linkify>
            </div>

            <div className="flex justify-between items-center gap-4">
              <div className={`${message.user?._id === userId ? 'text-white' : 'text-gray-500'}  text-xs leading-xs`}>{message.user?.name}</div>
              <div className={`${message.user?._id === userId ? 'text-white' : 'text-gray-500'}  text-xs leading-xs`}>
                {format(message.createdAt ?? 0, 'HH:mm')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bubble;
