import { useQuery, useMutation, gql } from '@apollo/client';
import { Workshop, WorkshopSearch } from '../../__generated__/graphql';

export const GET_WORKSHOPS = gql`
  query GetWorkshops {
    GetWorkshops {
      id
      date
      templateId
      subject
      body
      complete
      candidates
    }
  }
`;

export const GET_WORKSHOP = gql`
  query GetWorkshop($query: WorkshopSearch) {
    GetWorkshop(query: $query) {
      id
      date
      templateId
      subject
      body
      complete
      candidates
    }
  }
`;

export const SAVE_WORKSHOP = gql`
  mutation SaveWorkshop($input: SaveWorkshopInput) {
    SaveWorkshop(input: $input) {
      id
      date
      message
    }
  }
`;

export const SEND_WORKSHOP_EMAILS = gql`
  mutation SendWorkshopEmail($input: WorkshopSendEmailsInput) {
    SendWorkshopEmail(input: $input) {
      id
      date
      message
    }
  }
`;

interface GetWorkshopsResponse {
  GetWorkshops: Workshop[];
}

interface GetWorkshopResponse {
  GetWorkshop: Workshop;
}

export const useGetWorkshops = () => {
  const { data, loading, error } = useQuery<GetWorkshopsResponse>(GET_WORKSHOPS);
  return { workshops: data?.GetWorkshops || [], workshopsLoading: loading, workshopsError: error };
};

export const useGetWorkshop = (query: WorkshopSearch) => {
  const { data, loading, error } = useQuery<GetWorkshopResponse>(GET_WORKSHOP, {
    variables: {
      query: { ...query },
    },
  });

  return { workshop: data?.GetWorkshop || null, workshopLoading: loading, workshopError: error };
};

export const useSaveWorkshop = (id: string) => {
  const [saveWorkshop, mutationSaveWorkshop] = useMutation(SAVE_WORKSHOP, {
    refetchQueries: [
      {
        query: GET_WORKSHOP,
        variables: {
          query: { id },
        },
      },
    ],
  });
  return { saveWorkshop, mutationSaveWorkshop };
};

export const useSendWorkshopEmails = (id: string) => {
  const [sendWorkshopEmails, mutationSendWorkshopEmails] = useMutation(SEND_WORKSHOP_EMAILS, {
    refetchQueries: [
      {
        query: GET_WORKSHOP,
        variables: {
          query: { id },
        },
      },
    ],
  });
  return { sendWorkshopEmails, mutationSendWorkshopEmails };
};
