import Helmet from 'react-helmet';
import { Policy } from '@mui/icons-material';
import { Loading, LocationBar } from 'components';
import { useGetPolicies } from 'api/hooks/useGetPolicies';
import FileSections from 'components/FileSections';

const Policies = () => {
  const { policies, loading } = useGetPolicies();

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>BelleVie Policies</title>
      </Helmet>
      <LocationBar section="Policies & Practices" page="Policies" Icon={Policy} />
      <div className="my-10 px-4 md:px-[5%]">
        <FileSections sections={policies ?? []} />
      </div>
    </>
  );
};

export default Policies;
