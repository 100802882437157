import { BedrockAgentRuntimeClient, RetrieveAndGenerateCommand, RetrieveAndGenerateCommandOutput } from '@aws-sdk/client-bedrock-agent-runtime';
import { getCredentials } from './aws';

export const sendPromptToBedrock = async (prompt: string, sessionId: string | undefined): Promise<RetrieveAndGenerateCommandOutput | null> => {
  try {
    const client = new BedrockAgentRuntimeClient({
      region: 'us-east-1',
      credentials: await getCredentials(),
    });

    const command = await new RetrieveAndGenerateCommand({
      input: { text: `provide a summary ${prompt}` },
      sessionId,
      retrieveAndGenerateConfiguration: {
        type: 'KNOWLEDGE_BASE',
        knowledgeBaseConfiguration: {
          knowledgeBaseId: process.env.REACT_APP_TEAMHUB_BEDROCK_BASE,
          modelArn: process.env.REACT_APP_TEAMHUB_BEDROCK_MODEL,
        },
      },
    });

    return await client.send(command);
  } catch (error) {
    console.log('Unable to chat to llm', error);
    return null;
  }
};
