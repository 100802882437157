import { Controller, useFormContext } from 'react-hook-form';
import { SelectOption } from 'types/types';
import { FormLabel } from 'components';
import ReactSelect from 'components/Select';
import { isValueRequired } from 'components/Fields/validators';

interface NHSSelectFieldProps<T> {
  label: string;
  name: string;
  asRow?: boolean;
  options: SelectOption<T>[];
  disabled?: boolean;
  loading?: boolean;
  searchable?: boolean;
  testId?: string;
  isRequired?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function NHSSelectField(props: NHSSelectFieldProps<any>) {
  const { control, register } = useFormContext(); // retrieve all hook methods

  return (
    <FormLabel asRow={props.asRow} label={props.label}>
      <div className="w-full lg:w-1/2">
        <Controller
          name={props.name}
          control={control}
          rules={{ validate: props.isRequired ? isValueRequired : undefined }}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            const selectedValue = props.options.find((option) => JSON.stringify(option.value) === JSON.stringify(value));

            return (
              <>
                <ReactSelect
                  formRef={register(props.name).ref}
                  testId={props.testId}
                  isLoading={props.loading}
                  disabled={props.disabled}
                  options={props.options}
                  onChange={(e) => onChange(e.value)}
                  selectedValue={selectedValue}
                  searchable={props.searchable}
                />
                <p className="mt-2 text-sm text-error-600 dark:text-green-500">
                  <span className="font-medium">{error?.message}</span>
                </p>
              </>
            );
          }}
        />
      </div>
    </FormLabel>
  );
}

export { NHSSelectField };
