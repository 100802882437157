export const getNumberAdjective = (index: number) => {
  switch (index) {
    case 0:
      return 'st';
    case 1:
      return 'nd';
    case 2:
      return 'rd';
    default:
      return 'th';
  }
};

export const getReviewType = (index: number | null | undefined) => {
  const reviewType = index !== null && typeof index !== 'undefined' ? `${index + 1}${getNumberAdjective(index)} review` : '';
  return reviewType;
};
