/* eslint-disable @typescript-eslint/no-explicit-any */
import { Controller, useFormContext } from 'react-hook-form';
import FormLabel from '../FormLabel';
import DatePicker from '../DatePicker';

interface DateFieldProps {
  label: string;
  name: string;
  disabled?: boolean;
  testId?: string;
  isRequired?: boolean;
  minDate?: Date;
  validate?: (value: Date) => boolean | string;
}

const DateField = ({ label, minDate, name, disabled = false, isRequired = false, testId, validate }: DateFieldProps) => {
  const { control } = useFormContext(); // retrieve all hook methods

  return (
    <FormLabel label={label}>
      <div className="w-full lg:w-1/2">
        <Controller
          name={name}
          control={control}
          rules={{
            required: isRequired,
            validate,
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <DatePicker minDate={minDate} testId={testId} value={value} onChange={(e) => onChange(e?.getTime())} disabled={disabled} error={error?.message} />
            );
          }}
        />
      </div>
    </FormLabel>
  );
};
export { DateField };
