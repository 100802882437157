/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable comma-dangle */
export const groupByKey = (list: Array<any>, key: string) =>
  list
    .slice()
    .sort((a, b) => a.startDateTime - b.startDateTime)
    .reduce(
      (hash, obj) => ({
        ...hash,
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      }),
      {},
    );

export const removeProperty = (name: string, data: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [name]: removed, ...rest } = data;
  return rest;
};
