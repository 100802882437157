import { useQuery, gql, useMutation } from '@apollo/client';
import { Team } from '__generated__/graphql';

const GET_TEAMS = gql`
  query GetTeams {
    GetTeams {
      teamId
      teamName
      circleId
      circleName
      status
      activeTeam
      prefix
      dateUpdated
      locations
    }
  }
`;

export const GET_ALL_TEAMS = gql`
  query GetTeams($query: TeamSearch) {
    GetTeams(query: $query) {
      teamId
      teamName
      circleId
      circleName
      status
      activeTeam
      prefix
      dateUpdated
      locations
    }
  }
`;

const GET_TEAMS__OPTIONS = gql`
  query GetTeams($query: TeamSearch) {
    GetTeams(query: $query) {
      teamId
      teamName
    }
  }
`;

export const SAVE_TEAM = gql`
  mutation SaveTeam($input: TeamInput) {
    SaveTeam(input: $input) {
      id
      message
      date
    }
  }
`;

export const PUBLISH_TEAM = gql`
  mutation PublishTeam($input: PublishTeamInput) {
    PublishTeam(input: $input) {
      id
      message
      date
    }
  }
`;

interface GetTeamsResponse {
  GetTeams: Team[];
}

const useGetTeams = () => {
  const { data, loading: teamsLoading, error } = useQuery<GetTeamsResponse>(GET_TEAMS);

  return { teams: data?.GetTeams || [], teamsLoading, error };
};

export const useGetAllTeams = () => {
  const { data, loading, error } = useQuery<GetTeamsResponse>(GET_ALL_TEAMS, {
    variables: {
      query: {
        showAll: true,
      },
    },
  });

  return { teams: data?.GetTeams || [], error, loading };
};

export const useGetTeamOptions = (startDateTime?: number | null, showAll = false) => {
  const {
    data,
    loading: teamsLoading,
    error,
  } = useQuery<GetTeamsResponse>(GET_TEAMS__OPTIONS, {
    variables: {
      query: {
        showAll,
        startDateTime,
      },
    },
  });

  return { teams: data?.GetTeams || [], teamsLoading, error };
};

export default useGetTeams;

export const useSaveTeam = () => {
  const [saveTeam, mutationSaveTeam] = useMutation(SAVE_TEAM, {
    refetchQueries: [
      {
        query: GET_ALL_TEAMS,
        variables: {
          query: {
            showAll: true,
          },
        },
      },
    ],
  });

  return { saveTeam, mutationSaveTeam };
};

export const usePublishTeam = () => {
  const [publishTeam, mutationPublishTeam] = useMutation(PUBLISH_TEAM, {
    refetchQueries: [
      {
        query: GET_ALL_TEAMS,
        variables: {
          query: {
            showAll: true,
          },
        },
      },
    ],
  });

  return { publishTeam, mutationPublishTeam };
};
