import React from 'react';
import Helmet from 'react-helmet';
import { Quiz } from '@mui/icons-material';
import { Loading, LocationBar } from 'components';
import { useUser, UserContextProps } from 'context/userContext';

interface IRegionalPay {
  [key: string]: { enhanced: string; standard: string };
}

export default function Faq() {
  const { user, userLoading } = useUser() as UserContextProps;

  const regionalPay: IRegionalPay = {
    'North East Circle': { enhanced: '£11.30 per hour', standard: '£10.50 per hour' },
    'Thames Valley Circle': { enhanced: '£12.00 per hour', standard: '£11.00 per hour' },
  };

  const enhancedPay = (user?.circleName && regionalPay[user.circleName]?.enhanced) || '[This is only visible to WSWs in Thames Valley or Northumberland]';
  const standardRate = (user?.circleName && regionalPay[user.circleName]?.standard) || '[This is only visible to WSWs in Thames Valley or Northumberland]';
  if (userLoading) {
    return <Loading />;
  }
  return (
    <>
      <Helmet>
        <title>Frequently Asked Questions</title>
      </Helmet>
      <LocationBar section="Policies & Practices" page="Faqs" Icon={Quiz} />

      <main className="my-10 px-4 md:px-[5%] text-lg leading-lg text-gray-700">
        <p className="py-2">Not sure what you can claim as expenses or need some help logging your overtime? Hopefully we have the answer here.</p>

        <div className="my-5">
          <div className="text-primary-700 text-display-md leading-display-md tracking-md border-b border-primary-700 pb-2">New "What if...?" checklist</div>
          <div className="mt-3">
            <a href="https://docs.google.com/document/d/1LuiYXIloJer7o-XFSweqxzZPqZwzNFKwowIRr5XtsXg/edit?usp=sharing" className="text-warning-600 underline">
              We have a new "What if...?" checklist available
            </a>{' '}
            to help you all if the unforeseen happens
          </div>
        </div>

        <h2 className="text-primary-700 text-display-md leading-display-md tracking-md border-b border-primary-700 pb-2 mt-10 mb-5">Overtime</h2>
        <p className="py-2">
          Overtime is work you do on top of your contracted hours (including your “banked hours”). Overtime will automatically be calculated on your reporting
          hour spreadsheet for all the work you have done that goes above your banked hours.
        </p>
        <p className="py-2">
          When you cover visits for a colleague, you will need to record it from the start of the first visit until the end of the last visit (+travel time if
          applicable).
        </p>
        <p className="py-2">
          You should include travel time (and mileage) from home to first visit and from end of visits to your home if the cover shift is less than 2 hours
        </p>
        <h3 className="text-gray-800 text-display-sm leading-display-sm mt-5 mb-2.5">Missing hours and Overtime</h3>
        <p className="py-2">
          You may have noticed in the agreed shift tab on your rota that there is an area called “missing hours” This shows any hours that you are contracted to
          that vary from the total hours of your agreed shifts.
        </p>
        <p className="py-2">
          <strong>My missing hours are “0”</strong>
        </p>
        <p className="ml-4 py-2">
          This means that you are on shift for your contracted hours (taking into account 1.5 hrs/week for team meeting) so you don’t need to worry about this.
        </p>
        <p className="py-2">
          <strong>My missing hours are a negative number</strong>
        </p>
        <p className="ml-4 py-2">This means your agreed shifts add up to more than your contracted hours and you will be paid overtime for this.</p>
        <p className="py-2">
          <strong>My missing hours are a positive number</strong>
        </p>
        <p className="ml-4 py-2">
          This means that you have less hours on shift than you are contracted to. You are expected to be proactive about picking up overtime shifts. The
          missing hours will be deducted from your total overtime.
        </p>

        <h2 className="text-primary-700 text-display-md leading-display-md tracking-md border-b border-primary-700 pb-2 mt-10 mb-5">Team and buddy meetings</h2>
        <p className="py-2">You will be paid to attend 1.5 hours/week for your team meeting so you must attend these.</p>
        <p className="py-2">
          Buddy meetings are recorded under your banked hours, and are an opportunity for you and your buddy to reflect on your working week and support each
          other, prioritising what you feel is important for BelleVie and the people you support.
        </p>
        <p className="py-2">If you miss a team meeting due to absence this should be recorded (eg sick leave/annual leave).</p>

        <h2 className="text-primary-700 text-display-md leading-display-md tracking-md border-b border-primary-700 pb-2 mt-10 mb-5">Expenses</h2>
        <p className="py-2">
          You can claim for any purchases you have made that are directly related to your work or in the case where you have had to purchase something for
          someone we support.
        </p>
        <p className="py-2">Where possible, the person we support should be paying for their own purchases however sometimes this cannot be avoided.</p>
        <p className="py-2">
          Please keep a copy of all receipts for any expenses, and upload it to HR Partner. If you do not upload a copy of the receipt on HR Partner for any
          purchases you have made then we cannot reimburse you.
        </p>

        <h2 className="text-primary-700 text-display-md leading-display-md tracking-md border-b border-primary-700 pb-2 mt-10 mb-5">Mileage</h2>
        <p className="py-2">
          You will be reimbursed for any mileage completed between visits whilst on shift. If it is a normal shift you will not be paid for the mileage from
          your home to first visit and from your last visit to home unless agreed otherwise or if it is an overtime shift of less than 2 hours.
        </p>

        <h2 className="text-primary-700 text-display-md leading-display-md tracking-md border-b border-primary-700 pb-2 mt-10 mb-5">Holiday and leave</h2>
        <p className="py-2">
          You are entitled to 6 weeks (pro-rata) annual leave per tax year (April-March). Your annual leave balances will be available to see on HR Partner. We
          ask that you take 60% of your annual leave allowance between April and November. As part of a self-managing team you are responsible for managing your
          own annual leave. Please look to take your annual leave when there is cover available and you have agreed with your team on the annual leave channel
          in Slack. You should talk to your rota champion about annual leave.
        </p>
        <p className="py-2">
          You will calculate the amount of annual leave taken using the hours on shift you would normally work on the rota. If you are off for a whole week it
          will be taken as your weekly contracted hours.
        </p>

        <h2 className="text-primary-700 text-display-md leading-display-md tracking-md border-b border-primary-700 pb-2 mt-10 mb-5">Sickness and absence</h2>
        <p className="py-2">
          Once you have passed probation you are entitled to one week paid sick leave on a 12 months rolling basis. After this period, if you have received a
          sick note from your doctor, you will receive statutory sick pay (SSP) or if agreed in advance you may take this as unpaid/annual leave.
        </p>
        <p className="py-2">
          Please record sick leave in the same way as annual leave but identify this as sick leave. You can enter the total amount of hours taken off sick in
          the area marked “number of hours sickness” and again please record any missed team meetings.
        </p>
        <p className="py-2">Any unpaid leave should be agreed in advance.</p>

        <h2 className="text-primary-700 text-display-md leading-display-md tracking-md border-b border-primary-700 pb-2 mt-10 mb-5">New Pay Structure</h2>
        <p className="py-2">
          <strong>What is the rate per hour of pay in my region?</strong>
        </p>
        <ul className="py-2 ml-4">
          <li>
            ENHANCED PAY <em>(Care visits, ICs, and reviews)</em>
            <br />
            {enhancedPay}
          </li>
          <li>
            STANDARD RATE <em>(Training, team meetings, and banked hours)</em>
            <br />
            {standardRate}
          </li>
        </ul>
        <p className="py-2">
          <strong>What is covered in the enhanced rate of pay?</strong>
        </p>
        <p className="ml-4 py-2">
          The enhanced rate of pay will be paid for all activities that relate directly to the people we support, this includes: care visits, initial
          consultations (IC’s), and Reviews
        </p>
        <p className="py-2">
          <strong>What is covered in the standard rate of pay?</strong>
        </p>
        <p className="ml-4 py-2">
          The standard rate of pay will be paid for all of the following activities: training, travel, meetings, and banked hours. Essentially everything else
          you do that is not actually spending time with the people we support.
        </p>
        <p className="py-2">
          <strong>Why do we need two rates of pay?</strong>
        </p>
        <p className="ml-4 py-2">Most of our competitors don’t pay for gaps between visits. At BelleVie we don’t believe this is a fair way to pay you.</p>
        <p className="ml-4 py-2">
          We would like to pay you a fair wage for the support you give to the wonderful people we support and pay you for all the other elements that
          indirectly help us to deliver great care. However, we do need to ensure that we as a business can support the wage increase, we feel the fair way to
          do this is the 2 tier pay structure.
        </p>
        <p className="ml-4 py-2">
          We are well aware that many other Care companies advertise £13/hr which looks a higher rate than BelleVie, however, if you have 2 hours of gaps in a
          5-hour shift, you’d only get paid for 3hrs. With BelleVie we pay for all 5 hours.
        </p>
        <p className="py-2">
          <strong>What will my contracted hours be?</strong>
        </p>
        <p className="ml-4 py-2">
          Contracted hours will be in multiples of 5-hour shifts + 1.5 hour for your team meeting. The buddy meeting will become part of the banked hours.
        </p>
        <p className="ml-4 py-2">
          When we reviewed your weekly meetings it became clear that most can be achieved in 1.5 hours. In practice, this change means that everyone’s contract
          will be reduced by 30 min weekly.
        </p>
        <p className="py-2">
          <strong>Are there any changes to covering visits for other Wellbeing support workers?</strong>
        </p>
        <p className="ml-4 py-2">
          Firstly, if you cover visits for other Wellbeing support workers, the hours will be ‘taken’ from your banked hours, and when these run out they will
          be paid as overtime. Secondly, time spent with people we support as part of covering visits in your ‘banked hours’ will be paid at the enhanced rate
          of pay.
        </p>
        <p className="py-2">
          <strong>Are there any changes to the way we report our banked hours?</strong>
        </p>
        <p className="ml-4 py-2">
          In order for the new pay structure to be fair and lead to good outcomes for all, we would ask colleagues to report their hours, at least weekly, so
          you can monitor the balance of your banked hours and adjust activities accordingly.
        </p>
        <p className="py-2">
          <strong>What can or can’t I use banked hours for?</strong>
        </p>
        <p className="ml-4 py-2">Covering visits and rota work should be the main source of overtime.</p>
        <p className="ml-4 py-2">
          Trust is at the heart of BelleVie, and we will not edit a full list of eligible work under banked hours, however, we would ask that you don’t use your
          banked hours for Covid testing or actions that are part of you getting ready to work.
        </p>
        <p className="ml-4 py-2">
          Team members are best positioned to know what needs doing and how to do it efficiently. We can share ideas for the use of banked hours or you can
          contact Rachel Gregan (Marketing Lead) or David Ross Gillick (Recruitment Lead) if you’d like to get involved particularly in these business areas.
        </p>
        <p className="py-2">
          <strong>How will overtime work?</strong>
        </p>
        <p className="ml-4 py-2">
          Covering visits will be part of banked hours, and when there won’t be any banked hours available anymore, it will all become overtime. Time spent with
          people we support as part of covering visits will be paid at the enhanced rate.
        </p>
        <p className="py-2">
          <strong>Are we still doing Buddy meetings each month?</strong>
        </p>
        <p className="ml-4 py-2">
          Yes. Everyone has to meet their buddy and complete their Confirmation Practices at least once a month to make sure we are compliant with CQC’s rules.{' '}
        </p>
        <p className="py-2">
          <strong>If I’ve used all my Banked Hours can I claim overtime for my buddy time?</strong>
        </p>
        <p className="ml-4 py-2">Yes you can, but we would ask you to use your time efficiently.</p>
        <p className="py-2">
          <strong>I have a visit from 9 to 9:45, and another one from 11 to 11:30. Is the time between 09:45 and 11am banked hours?</strong>
        </p>
        <p className="ml-4 py-2">
          No, banked hours are only the time before the first visit and after the last one. We encourage you to use this time to catch up on Slack, or spend
          more time with people we support.
        </p>
        <p className="py-2">
          <strong>I am on a flexi contract, what's changing for me?</strong>
        </p>
        <p className="ml-4 py-2">The only change affecting you is the introduction of a new enhanced rate for the time spent doing visits.</p>
        <p className="py-2">
          <strong>I am worried about how this new pay structure will affect my benefits.</strong>
        </p>
        <p className="ml-4 py-2">
          Please reach out to Violaine on BelleVie for All Slack by sending a direct message, to discuss your personal circumstances. We want this new pay
          structure to benefit everyone, so we will look for solutions.
        </p>
      </main>
    </>
  );
}
