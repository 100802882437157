import 'react-big-calendar/lib/css/react-big-calendar.css';
import { View } from 'react-big-calendar';
import DatePicker from 'components/DatePicker';
import { ToggleButtons, SelectSupportWorker, TeamSelector } from 'components';
import { SizeType } from 'types/types';
import { addWeeks, format } from 'date-fns';

interface VisitsToolBarProps {
  // label: string;
  currentView: { label: string; value: View };
  viewOptions: { label: string; value: View }[];
  startDateTime: number;
  onSelectDateRange: (date: Date) => void;
  teamId: string;
  setSelectedSupportWorker: (supportSupport: string) => void;
  setSelectedTeam: (team: string) => void;
  onChangeView: (view: string) => void;
  selectedSupportWorker: string | undefined;
}

const CalendarLegand = () => {
  return (
    <div className="flex flex-col items-center	 xl:flex-row ">
      <span className="flex items-center text-md font-medium text-gray-900 dark:text-white me-3">
        <p> Legend: </p>
      </span>
      <span className="flex items-center text-md font-medium text-gray-900 dark:text-white me-3">
        <span className="flex w-2.5 h-2.5 bg-yellow-300 rounded-full me-1.5 flex-shrink-0" />
        Cross Team
      </span>
      <span className="flex items-center text-md font-medium text-gray-900 dark:text-white me-3">
        <span className="flex w-2.5 h-2.5 bg-orange-300 rounded-full me-1.5 flex-shrink-0" />
        Birthday
      </span>
      <span className="flex items-center text-md font-medium text-gray-900 dark:text-white me-3">
        <span className="flex w-2.5 h-2.5 bg-red-500 rounded-full me-1.5 flex-shrink-0" />
        Activity
      </span>
      <span className="flex items-center text-md font-medium text-gray-900 dark:text-white me-3">
        <span className="flex w-2.5 h-2.5 bg-purple-600 rounded-full me-1.5 flex-shrink-0" /> Absence
      </span>
      <span className="flex items-center text-md font-medium text-gray-900 dark:text-white me-3">
        <span className="flex w-2.5 h-2.5 bg-blue-600 rounded-full me-1.5 flex-shrink-0" /> Sick Leave
      </span>
      <span className="flex items-center text-md font-medium text-gray-900 dark:text-white me-3">
        <span className="flex w-2.5 h-2.5 bg-red-900 rounded-full me-1.5 flex-shrink-0" /> Other Leave
      </span>
    </div>
  );
};

const VisitsToolBar = ({
  // label,
  currentView,
  teamId,
  viewOptions,
  selectedSupportWorker,
  startDateTime,
  onSelectDateRange,
  setSelectedTeam,
  setSelectedSupportWorker,
  onChangeView,
}: VisitsToolBarProps) => {
  let label = format(startDateTime, 'd MMMM yyyy');
  if (currentView.value === 'week' || currentView.value === 'agenda') {
    label = `${format(startDateTime, 'd MMM yyyy')} - ${format(addWeeks(startDateTime, 1), 'd MMM yyyy')}`;
  }
  return (
    <div className="py-5">
      <div className="text-display-sm sm:text-display-lg leading-display-sm sm:leading-display-lg sm:tracking-display-lg text-black font-medium">{label}</div>

      <div className="flex flex-col lg:flex-row items-center justify-between mt-8 md:mt-16">
        <div className="flex flex-col md:flex-row items-center gap-4 md:gap-9 w-full md:w-fit">
          <DatePicker
            value={new Date(startDateTime)}
            onChange={(date) => {
              if (date) onSelectDateRange(date);
            }}
          />
        </div>
        <div className="flex flex-col md:flex-row items-center gap-4 md:gap-9 w-full md:w-fit">
          <TeamSelector isContained={true} selectTeam={setSelectedTeam} teamId={teamId} showAll={false} />
          <SelectSupportWorker
            onSelectedSupportWorker={setSelectedSupportWorker}
            selectedSupportWorkerId={selectedSupportWorker || ''}
            teamId={teamId}
            isContained={true}
          />
        </div>
      </div>
      <div className="mt-6">
        <ToggleButtons
          disabled={false}
          buttons={viewOptions.map((v) => v.label)}
          size={SizeType.Half}
          selectedButton={currentView.label}
          onSelectButton={onChangeView}
        />
      </div>
      <br />
      <CalendarLegand />
    </div>
  );
};

export default VisitsToolBar;
