import { useState } from 'react';
import { format } from 'date-fns';
import Helmet from 'react-helmet';
import { Message, LocationBar, Loading, Table, ToggleButtons, Search } from 'components';
import { Group } from '@mui/icons-material';
import TWModal from 'components/TWModal';
import { useGetAllTeams, useSaveTeam, usePublishTeam } from 'api/hooks/useGetTeams';
import { Team } from '__generated__/graphql';
import { PencilIcon, CloudArrowUpIcon } from '@heroicons/react/24/outline';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { SizeType } from 'types/types';
import SaveTeam from './SaveTeam';

export default function TeamList() {
  const { teams, loading } = useGetAllTeams();
  const { saveTeam, mutationSaveTeam } = useSaveTeam();
  const { publishTeam, mutationPublishTeam } = usePublishTeam();
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const [showSaveTeam, setShowSaveTeam] = useState<boolean>(false);
  const [showPublishTeam, setShowPublishTeam] = useState<boolean>(false);
  const [teamSelected, setTeam] = useState<Team | null>(null);
  const [teamStatus, setTeamStatus] = useState('All');
  const [search, setSearch] = useState('');
  const columnVisibility = {
    dateUpdated: window.innerWidth > 1280,
    prefix: window.innerWidth > 1024,
    activeTeam: window.innerWidth > 1024,
    circleName: window.innerWidth > 768,
    // status: window.innerWidth > 640,
    locations: window.innerWidth > 640,
    name: true,
    type: true,
  };

  if (loading) return <Loading />;

  const onSaveTeam = (t: Team) => {
    setOpenSnack(true);
    saveTeam({ variables: { input: { ...t } } });
    setShowSaveTeam(false);
    setTimeout(() => setOpenSnack(false), 2000);
  };

  const onPublishTeam = () => {
    setOpenSnack(true);
    publishTeam({ variables: { input: { teamId: teamSelected?.teamId } } });
    setShowPublishTeam(false);
    setTimeout(() => setOpenSnack(false), 2000);
  };

  const edit = (t: Team) => {
    if (t) {
      setTeam(t);
      setShowSaveTeam(true);
    }
  };

  const onCancel = () => {
    setOpenSnack(false);
    setShowSaveTeam(false);
    setShowPublishTeam(false);
  };

  const publish = (t: Team) => {
    if (t) {
      setTeam(t);
      setShowPublishTeam(true);
    }
  };

  const RowActions = ({ row }: { row: Team }) => {
    return (
      <div className="flex items-center gap-3">
        <button data-cy="list-edit-button" type="button" onClick={() => edit(row)} aria-label="Edit">
          <PencilIcon className="w-6 h-6 text-gray-500 hover:text-primary-600" />
        </button>
        <button data-cy="list-publish-button" type="button" onClick={() => publish(row)} aria-label="Publish">
          <CloudArrowUpIcon className="w-6 h-6 text-gray-500 hover:text-primary-600" />
        </button>
      </div>
    );
  };

  const columnHelper = createColumnHelper<Team>();

  const columns1 = [
    columnHelper.accessor('teamName', {
      header: () => 'Team Name',
      cell: (info) => {
        return <div data-cy="list-name">{info.renderValue()}</div>;
      },
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.accessor('circleName', {
      header: () => 'Circle Name',
      cell: (info) => <div data-cy="list-circle">{info.renderValue()}</div>,
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.accessor('status', {
      header: () => 'Status',
      cell: (info) => <div data-cy="list-status">{info.renderValue() ? 'Active' : 'InActive'}</div>,
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.accessor('prefix', {
      header: () => 'Prefix',
      cell: (info) => <div data-cy="list-prefix">{info.renderValue()}</div>,
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.accessor('activeTeam', {
      header: () => 'Active Team',
      cell: (info) => <div data-cy="list-active">{info.renderValue() ? 'Active' : 'InActive'}</div>,
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.accessor('dateUpdated', {
      header: () => 'Date updated',
      cell: (info) => <div data-cy="list-dateUpdated">{format(info.renderValue() ?? 0, 'dd/MM/yyyy')}</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('locations', {
      header: () => 'Locations',
      cell: (info) => <div data-cy="list-locations">{info.renderValue()?.length}</div>,
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (props) => <RowActions row={props.row.original} />,
    }),
  ] as Array<ColumnDef<Team, unknown>>;

  const changeFilter = (f: string) => {
    setTeamStatus(f);
  };

  const filterStatus = (t: Team) => {
    if (teamStatus === 'All') return true;
    if (teamStatus === 'Active' && t.status) return true;
    if (teamStatus === 'Inactive' && !t.status) return true;
    return false;
  };

  const filterByName = (t: Team) => {
    if (t?.teamName?.toLowerCase().includes(search.toLowerCase())) return true;
    return false;
  };

  const rows = teams.filter((t) => filterStatus(t)).filter((t) => filterByName(t));
  const circles = teams
    .map((t) => ({ label: t.circleName, value: t.circleId }))
    .filter((a, i) => teams.findIndex((s) => a.label && a.value === s.circleId) === i);

  return (
    <>
      <Helmet>
        <title>Teams</title>
      </Helmet>
      <LocationBar section="Teams" page="Team List" Icon={Group} />
      <Message response={[mutationSaveTeam, mutationPublishTeam]} openSnack={openSnack} setOpenSnack={setOpenSnack} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex justify-between place-items-center mb-8">
          <div className="text-display-sm md:text-display-lg leading-display-sm md:leading-display-lg font-semibold md:tracking-display-lg text-black">
            Teams
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-between mt-8 md:mt-16">
          <div className="flex flex-col md:flex-row items-center gap-4 md:gap-9 w-full md:w-fit">
            <ToggleButtons
              testId="status-filter"
              buttons={['All', 'Active', 'Inactive']}
              selectedButton={teamStatus}
              onSelectButton={changeFilter}
              size={window.innerWidth > 768 ? SizeType.Fit : SizeType.Half}
            />
            <Search value={search} onSearch={setSearch} />
          </div>
        </div>
        <div className="mt-3 md:mt-8 mb-10">
          {rows && rows.length > 0 && <Table<Team> data={rows} columns={columns1} columnVisibility={columnVisibility} />}
        </div>
      </div>
      {showSaveTeam && <SaveTeam circles={circles} team={teamSelected} onUpdate={onSaveTeam} onClose={onCancel} />}
      {showPublishTeam && (
        <TWModal buttonText="Publish" title="Publish Team" onClose={onCancel} onSave={onPublishTeam}>
          <div>Are you sure you would like to publish team {teamSelected?.teamName || ''} to birdie?</div>
        </TWModal>
      )}
    </>
  );
}
