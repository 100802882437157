import { useState } from 'react';
import { SupportWorkerGrid } from 'components';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useGetSupportWorkers } from 'api/hooks/useSupportWorkers';
import TWModal from 'components/TWModal';
import { ApologyReasonField } from './ApologyReasonField';
import { SupportWorkerField } from './SupportWorkerField';
import { TeamMeetingFormInput } from '../types';

interface AttendeesFieldProps {
  team: string;
  toggleAttendeesModal: (state: boolean) => void;
  assignAttendeesModalState: boolean;
  toggleApologiesModal: (state: boolean) => void;
  assignApologiesModalState: boolean;
}

export default function AttendeesField({
  team,
  toggleAttendeesModal,
  assignAttendeesModalState,
  toggleApologiesModal,
  assignApologiesModalState,
}: AttendeesFieldProps) {
  const [teamId, setTeamId] = useState<string>(team);
  const { control } = useFormContext<TeamMeetingFormInput>();
  const onCloseModal = () => {
    toggleAttendeesModal(false);
  };

  const onCloseApologiesModal = () => {
    toggleApologiesModal(false);
  };

  const { fields, append, remove } = useFieldArray({
    name: 'attendees', // unique name for your Field Array
    control,
  });
  const {
    fields: apologies,
    append: apologyAppend,
    remove: apologyRemove,
  } = useFieldArray({
    name: 'apologies', // unique name for your Field Array
    control,
  });
  const { supportWorkers } = useGetSupportWorkers({
    showAll: true,
  });

  const onClickAttendee = (id: string) => {
    const index = fields.findIndex((field) => field.supportWorkerId === id);
    const apologyIndex = apologies.findIndex((field) => field.supportWorkerId === id);
    const found = supportWorkers.find((sw) => sw.id === id);
    if (index !== -1) {
      remove(index);
      if (found?.teamId === team) apologyAppend({ supportWorkerId: id, reason: '' });
    } else {
      append({ supportWorkerId: id });
      if (apologyIndex !== -1) apologyRemove(apologyIndex);
    }
  };
  const onClickApologies = (id: string) => {
    const index = apologies.findIndex((field) => field.supportWorkerId === id);
    const attendeeIndex = fields.findIndex((field) => field.supportWorkerId === id);

    if (index !== -1) {
      apologyRemove(index);
    } else {
      apologyAppend({ supportWorkerId: id, reason: '' });
      if (attendeeIndex !== -1) remove(attendeeIndex);
    }
  };

  const apologyIds = apologies.map((field) => field.supportWorkerId);
  const teamMeetingApologies = supportWorkers?.filter((sw) => apologyIds.includes(sw.id));

  const attendeeIds = fields.map((field) => field.supportWorkerId);
  const teamMeetingAttendees = supportWorkers?.filter((sw) => attendeeIds.includes(sw.id));

  return (
    <>
      <>
        <div className=" text-gray-700 text-md py-2 leading-sm font-bold"> Remove any team members not at the meeting:</div>

        {assignAttendeesModalState && (
          <TWModal onSave={onCloseModal} onClose={onCloseModal} title="Add Attendees">
            <SupportWorkerField name="attendees" swIds={attendeeIds} onClickAttendee={onClickAttendee} setTeamId={setTeamId} teamId={teamId} />
          </TWModal>
        )}

        <div>
          <SupportWorkerGrid supportWorkers={teamMeetingAttendees} gridType="attendees" />
        </div>
        <button
          type="button"
          className="text-white bg-primary-700 rounded-lg px-5 py-2.5 my-3 font-semibold text-md leading-md"
          onClick={() => toggleAttendeesModal(true)}
        >
          Update attendees
        </button>
      </>
      <div className="flex flex-col gap-y-4">
        {assignApologiesModalState && (
          <TWModal buttonType="button" onSave={onCloseApologiesModal} onClose={onCloseApologiesModal} title="Add Absentees" testId="absentee-save-button">
            <SupportWorkerField name="apologies" swIds={apologyIds} onClickAttendee={onClickApologies} setTeamId={setTeamId} teamId={teamId} />
          </TWModal>
        )}

        {fields?.length > 0 && <ApologyReasonField teamMeetingApologies={teamMeetingApologies} />}
        <button
          data-cy="remove-attendees-button"
          type="button"
          className="text-white w-fit my-3   bg-primary-700 rounded-lg px-5 py-3 font-semibold text-md leading-md"
          onClick={() => toggleApologiesModal(true)}
        >
          Update absentees
        </button>
      </div>
    </>
  );
}
