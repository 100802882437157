import { useQuery, gql, useMutation } from '@apollo/client';
import { Form, FormListSearch } from '../../__generated__/graphql';

interface FormResponse {
  GetForms: Form[];
}

export const GET_FORMS = gql`
  query GetForms($query: FormListSearch) {
    GetForms(query: $query) {
      id
      teamId
      circleId
      createdAt
      formType
      observingPersonId
      supportWorkerId
      dateTimeOfObservation
      medicalObvs {
        emailAddress
        digitalSignatureGiven
        status
        anyOtherNotes
        competencyChecklist {
          question
          answer
        }
      }
      incident {
        incidentNumber
        descriptionOfAccident
        resultingAction
        isPrivate
        postCode
        address
      }
    }
  }
`;

export const SAVE_FORM = gql`
  mutation SaveForm($input: FormInput) {
    SaveForm(input: $input) {
      date
      id
      message
    }
  }
`;

export const useGetforms = (query: FormListSearch) => {
  const { data, loading, error } = useQuery<FormResponse>(GET_FORMS, {
    variables: {
      query: { ...query },
    },
  });

  return { forms: data?.GetForms || [], loading, error };
};

export const useSaveForm = () => {
  const [saveForm, { loading: saveFormLoading, error: saveFormError }] = useMutation(SAVE_FORM, {
    fetchPolicy: 'network-only',
    refetchQueries: [
      {
        query: GET_FORMS,
        variables: {
          query: {},
        },
      },
    ],
  });

  return {
    saveForm,
    saveFormLoading,
    saveFormError,
  };
};
