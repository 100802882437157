import React, { useState } from 'react';
import ReactSelect from 'components/Select';
import { formatTime, getPeriodsForYear } from 'services/helpers';
import { SelectOption } from 'types/types';
// import { Expense } from '__generated__/graphql';
// import { MapFile } from './FileMapper';

interface ImportExpensesProps {
  onClose: () => void;
  onSaveExpenses: (period: number) => void;
}

export default function ImportExpenses({ onClose, onSaveExpenses }: ImportExpensesProps) {
  const periods = getPeriodsForYear().map((period) => {
    return { label: formatTime(period, 'dd MMMM, yyyy'), value: period.toString() };
  });

  const [period, setPeriod] = useState<SelectOption>(periods[0]);
  // const [expensesToImport, setExpensesToImport] = useState<Expense[]>([]);

  // const handleFileUpload = (file: File) => {
  //   const reader = new FileReader();
  //   reader.onload = (e) => {
  //     if (e?.target && e?.target?.result) {
  //       const payrollStartDate = getFirstDayOfPayroll(parseFloat(period.value.toString()));
  //       const payrollEndDate = getLastDayOfPayroll(payrollStartDate);
  //       const expenses = MapFile(e?.target?.result?.toString(), payrollStartDate, payrollEndDate);
  //       console.log(expenses);
  //       setExpensesToImport(expenses || []);
  //     }
  //   };
  //   reader.readAsText(file);
  // };

  // const handleImportFile = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const fileList = event.target.files;

  //   if (fileList && fileList.length > 0) {
  //     handleFileUpload(fileList[0]);
  //   }
  // };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-[1200] outline-none focus:outline-none">
        <div className="relative w-auto mx-auto max-w-5xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none p-6 max-h-screen md:max-h-[70vh] overflow-y-auto">
            <div className="text-gray-900 text-lg leading-lg font-semibold mb-5">Import Expenses</div>
            {/* <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 w-auto">Select a csv file </div>
              <div className="mt-2">
                <label htmlFor="custom-input" className="text-gray-500 text-sm leading-sm font-semibold hover:no-underline cursor-pointer">
                  <input type="file" id="custom-input" onChange={handleImportFile} hidden />
                  Import CSV
                </label>
              </div>
            </div> */}
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Period</div>
              <div className="mt-2">
                <ReactSelect testId="invoice-period" options={periods} onChange={setPeriod} selectedValue={period} searchable={true} />
              </div>
            </div>
            <div className="flex justify-between items-center mt-5">
              <button type="button" className="text-gray-500 font-semibold text-md leading-md" onClick={onClose}>
                Cancel
              </button>
              <button
                type="button"
                data-cy="save-package-button"
                className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
                onClick={() => onSaveExpenses(parseFloat(period.value))}
              >
                Import File
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-gray-900" />
    </>
  );
}
