import { CurrentStatus, CandidateStatus } from '__generated__/graphql';
import { NoSymbolIcon, RocketLaunchIcon, ShieldCheckIcon } from '@heroicons/react/24/outline';
import { camelCaseToNormalString } from 'services/strings';
import { SelectField } from 'components/Fields/SelectField';
import { InputField } from 'components/Fields/InputField';
import { DateField } from 'components/Fields/DateField';
import { TeamSelectField } from 'components/Fields/TeamSelectField';
import { SupportWorkerSelectField } from 'components/Fields/SupportWorkerSelectField';
import { RadioField } from 'components/Fields/RadioField';
import { AddressField } from 'components/Fields/AddressField';
import { TextAreaField } from 'components/Fields/TextAreaField';

export default function CandidateUpdate() {
  const currentStatuses = Object.values(CurrentStatus).map((c) => ({ value: c, label: camelCaseToNormalString(c) }));
  const candidateStatuses = Object.values(CandidateStatus);
  const candidateStatusesIcons = [
    <NoSymbolIcon className="w-5 h-5 mr-2" />,
    <RocketLaunchIcon className="w-5 h-5 mr-2" />,
    <ShieldCheckIcon className="w-5 h-5 mr-2" />,
  ];

  return (
    <div className="mt-8 sm:mt-12">
      <RadioField radioOptions={candidateStatuses} testId="candidate-status" label="status" name="status" icons={candidateStatusesIcons} />
      <SelectField options={currentStatuses} label="Application Status" name="currentStatus" />
      <TeamSelectField label="Team" name="teamId" showAll={true} />
      <InputField label="First Name" placeholder="First name" name="firstName" />
      <InputField label="Last Name" placeholder="Last name" name="lastName" />
      <InputField label="Email" placeholder="Email" name="email" />
      <InputField label="Job role" placeholder="Job role" name="jobRole" />
      <SupportWorkerSelectField adminOnly={true} label="Owner" name="owner" />
      <AddressField />
      <InputField label="Phone number" placeholder="Phone" name="phone" />
      <InputField label="Source" placeholder="Source" name="source" />
      <DateField label="Date of birth" name="dob" />
      <TextAreaField
        label="What about this role appeals to you?"
        name="roleAppeal"
        placeholder="What about this role appeals to you?"
        isRequired={false}
        rows={6}
      />
      <TextAreaField label="Why are you the person we want?" name="whyYou" placeholder="Why are you the person we want?" isRequired={false} rows={6} />
      <div className="flex justify-end">
        <button type="submit" className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
          Save
        </button>
      </div>
    </div>
  );
}
