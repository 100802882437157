import { AgreedShiftRunWeeks, Maybe, AgreedShiftRun } from '__generated__/graphql';
import { isEqual } from 'date-fns';

export const doDateRangesOverlap = (start1: Date, start2: Date, end1: Date, end2: Date) => {
  return (
    (start1 > start2 && start1 < end2) ||
    (end1 > start2 && end1 < end2) ||
    (start1 < start2 && end1 > end2) ||
    (isEqual(start1, start2) && isEqual(end1, end2)) ||
    (isEqual(start1, start2) && end1 > end2) ||
    (start1 < start2 && isEqual(end1, end2))
  );
};

export const createDateWithTime = (date: number, time: number) => {
  const d = new Date(date);

  d.setHours(new Date(time).getHours(), new Date(time).getMinutes(), 0, 0);
  return d;
};

export const calculateMagicNumberForecast = (agreedShifts: AgreedShiftRunWeeks, selectedWeek?: string): number => {
  let totalVisitsTime = 0;
  let totalShiftTime = 0;

  const processWeek = (week: Maybe<Maybe<AgreedShiftRun>[]> | undefined) => {
    week?.forEach((shiftRun) => {
      if (shiftRun?.startDateTime && shiftRun?.endDateTime) {
        const shiftRunDuration = shiftRun.endDateTime - shiftRun.startDateTime;
        const numberOfShifts = shiftRun.shifts?.length || 0;
        totalShiftTime += shiftRunDuration * numberOfShifts;

        shiftRun.shifts?.forEach((shift) => {
          shift?.visits?.forEach((visit) => {
            if (visit?.startDateTime && visit?.endDateTime) {
              totalVisitsTime += visit.endDateTime - visit.startDateTime;
            }
          });
        });
      }
    });
  };

  if (selectedWeek && selectedWeek !== '0') {
    processWeek(selectedWeek === '1' ? agreedShifts.week1 : agreedShifts.week2);
  } else {
    processWeek(agreedShifts.week1);
    processWeek(agreedShifts.week2);
  }

  return totalShiftTime > 0 ? (totalVisitsTime / totalShiftTime) * 100 : 0;
};
