import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline';
import {
  ColumnDef,
  RowSelectionState,
  SortingState,
  Table as TableCoreType,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Dispatch, SetStateAction, useState } from 'react';
import { TablePagination } from './TablePagination';

interface TableProps<TableType> {
  columns: ColumnDef<TableType>[];
  data: TableType[];
  pageSize?: number;
  columnVisibility?: VisibilityState;
  rowSelection?: RowSelectionState;
  setRowSelection?: Dispatch<SetStateAction<RowSelectionState>>;
}

export const TableContent = <TableType,>({ table }: { table: TableCoreType<TableType> }) => {
  return (
    <div className="overflow-hidden border-b border-gray-200">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} className="px-2 sm:px-4  md:px-6 py-3 text-left text-sm font-semibold text-gray-800">
                  <div
                    {...{
                      className: header.column.getCanSort() ? 'cursor-pointer select-none flex items-center' : '',
                      onClick: header.column.getToggleSortingHandler(),
                    }}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: (
                        <div className="ml-2">
                          <ArrowUpIcon className="w-3 h-3 text-gray-900" />
                        </div>
                      ),
                      desc: (
                        <div className="ml-2">
                          <ArrowDownIcon className="w-3 h-3 text-gray-900" />
                        </div>
                      ),
                    }[header.column.getIsSorted() as string] ?? null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="divide-y divide-gray-200">
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => {
                return (
                  <td key={cell.id} className="px-2 sm:px-4 md:px-6 py-4 sm:whitespace-nowrap text-gray-700 text-md leading-md print:text-wrap">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Table = <TableType,>({ columns, pageSize, data, columnVisibility, rowSelection, setRowSelection }: TableProps<TableType>) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const table = useReactTable({
    data,
    columns,
    autoResetAll: false,
    state: rowSelection
      ? {
          sorting,
          rowSelection,
        }
      : {
          sorting,
        },

    initialState: {
      pagination: { pageIndex: 0, pageSize: pageSize || 10 },
      columnVisibility: columnVisibility ?? {},
    },
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <div className="mt-2 flex flex-col">
      <div className="-my-2">
        <div className="py-2 align-middle inline-block min-w-full print:flex ">
          <TableContent<TableType> table={table} />
          {pageSize !== data.length && (
            <TablePagination
              isNextDisabled={!table.getCanNextPage()}
              isPreviousDisabled={!table.getCanPreviousPage()}
              onClickPrevious={table.previousPage}
              onClickNext={table.nextPage}
              pageCount={table.getPageCount()}
              pageIndex={table.getState().pagination.pageIndex}
              setIndex={table.setPageIndex}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Table;
