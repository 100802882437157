import { Maybe, MedicationTaskInstancesType, VisitObservationType } from '__generated__/graphql';
import { useGetVisitObservations } from 'api/hooks/useChat';
import { Loading } from 'components';
import format from 'date-fns/format';
import { useEffect, useState } from 'react';

interface CareNoteProps {
  visitId: string;
  start: string;
  end: string;
  caregivers: string[];
}

const CareNote = ({ visitId, start, end, caregivers }: CareNoteProps) => {
  const { visits, loading } = useGetVisitObservations({
    visitId,
  });
  const [note, setNote] = useState('');
  const [medications, setMedications] = useState<Maybe<MedicationTaskInstancesType>[]>([]);

  useEffect(() => {
    if (visits) {
      visits.observations?.forEach((observation: Maybe<VisitObservationType>) => {
        if (observation?.event_type === 'general_observation') {
          setNote(observation.note ?? '');
        }
      });
      setMedications(visits?.medication_task_instances || []);
    }
  }, [visits]);

  if (loading) {
    <Loading isComponent />;
  }

  return (
    <div className="mb-10">
      <div className="bg-primary-700 text-white px-4 py-2">
        <div className="flex items-center gap-2">
          <div className="text-md leading-md font-semibold">Visit:</div>
          <div className="text-md leading-md">{format(new Date(start), 'do MMMM yyyy')}</div>
        </div>
        <div className="flex items-center gap-2">
          <div className="text-md leading-md font-semibold">Check in:</div>
          <div className="text-md leading-md">{format(new Date(start), 'HH:mm aaa')}</div>
        </div>
        <div className="flex items-center gap-2">
          <div className="text-md leading-md font-semibold">Check out:</div>
          <div className="text-md leading-md">{format(new Date(end), 'HH:mm aaa')}</div>
        </div>
      </div>
      <div className="px-4 mt-4">
        <div className="text-md leading-md text-gray-800">
          <span className="font-semibold mr-2">WSW{caregivers?.length > 1 ? 's' : ''}:</span>
          {caregivers?.join(', ')}
        </div>
        {note && (
          <div className="text-md leading-md mt-2 text-gray-800">
            <span className="font-semibold mr-2">Note:</span>
            {note}
          </div>
        )}
        {medications && medications.length > 0 && (
          <div>
            <div className="text-md leading-md mt-2 text-gray-800 font-semibold">Medications administered:</div>
            {medications.map((medication, index) => {
              if (medication?.events && medication?.events.length > 0 && medication.product) {
                return (
                  <div className="ml-2 flex items-start gap-2 mt-1" key={index}>
                    <div className="bg-primary-700 rounded-full w-2 h-2 shrink-0 mt-2.5" />
                    <div className="text-gray-800 text-md leading-md">
                      <div>
                        {medication?.events[0]?.dose_size} of {medication?.product.full_name}
                      </div>
                      <div>
                        <span className="font-medium mr-1">Note:</span>
                        {medication?.events[0]?.note}
                      </div>
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default CareNote;
