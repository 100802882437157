import React, { ReactNode } from 'react';

interface InputProps {
  value: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  error?: string | ReactNode;
  type?: string;
  testId?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formRef?: any;
}

const Input = ({ formRef, type = 'text', value, placeholder, onChange, disabled, error, testId }: InputProps) => {
  return (
    <>
      <input
        ref={formRef}
        disabled={disabled}
        type={type}
        value={value}
        onChange={(event) => (onChange ? onChange(event.target.value) : null)}
        className="bg-white px-5 py-2  disabled:bg-slate-50 disabled:text-slate-500 rounded-lg text-md leading-md text-gray-700 placeholder:text-gray-500 border border-gray-300 shadow-xs w-full"
        placeholder={placeholder ?? ''}
        data-cy={testId}
      />
      {error && (
        <p className="mt-2 text-sm text-error-600 dark:text-green-500">
          <span className="font-medium">{error}</span>
        </p>
      )}
    </>
  );
};

export default Input;
