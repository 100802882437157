import React, { Dispatch, SetStateAction } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextBox from 'components/TextBox';
import { formatTime } from 'services/helpers';
import Score from './Score';

// todo fix checkList
interface ConfirmationPracticesFormProps {
  title: string;
  question: string;
  checkList: { name: string }[];
  whyScore: string;
  focus: string;
  score: number;
  dateTime: number;
  setWhyScore: Dispatch<SetStateAction<string>>;
  setFocus: Dispatch<SetStateAction<string>>;
  setScore: Dispatch<SetStateAction<number>>;
  goBack: () => void;
}

export default function Form({
  title,
  question,
  checkList,
  whyScore,
  setWhyScore,
  focus,
  setFocus,
  score,
  setScore,
  dateTime,
  goBack,
}: ConfirmationPracticesFormProps) {
  return (
    <div>
      <Accordion onChange={() => goBack()}>
        <AccordionSummary>
          <Typography>Confirmation Practices - {formatTime(dateTime, 'do MMMM')}</Typography>
        </AccordionSummary>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>{title}</b>: {question}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            {checkList.map((cl) => {
              return <li>{cl.name}</li>;
            })}
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Score for this period</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Score score={score} setScore={setScore} />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Why I gave myself this score</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextBox value={whyScore} onChange={setWhyScore} limit={1000} />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>If this is an area that needs focus, what am I committing to focus on in the next period</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextBox value={focus} onChange={setFocus} limit={1000} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
