import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useForm, FormProvider } from 'react-hook-form';
import ListAlt from '@mui/icons-material/ListAlt';
import { Form, FormInput } from '__generated__/graphql';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { Loading, LocationBar, Message } from 'components';
import { UserContextProps, useUser } from 'context/userContext';
import { useSaveForm, useGetforms } from 'api/hooks/useForms';
import TabSwitcher from 'components/TabSwitcher';
import { FormPrompt } from 'components/modals/FormPromptModal';
import FormCoreUpdate from '../Shared';
import IncidentUpdate from './IncidentUpdate';

export default function IncidentDetail() {
  const navigate = useNavigate();
  const { user } = useUser() as UserContextProps;
  const { formId, supportWorkerId } = useParams<{ formId?: string; supportWorkerId?: string }>();
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const { forms, loading: formLoading } = useGetforms({});
  const form = forms.find((f: Form) => f.id === formId);
  const { saveForm, saveFormError, saveFormLoading } = useSaveForm();

  const methods = useForm<FormInput>({
    mode: 'onChange',
  });

  const { handleSubmit, formState, reset, watch } = methods;

  useEffect(() => {
    reset(
      {
        dateTimeOfObservation: new Date().getTime(),
        supportWorkerId,
        ...form,
      },
      { keepDirty: true },
    );
  }, [form, supportWorkerId, reset]);

  const onSubmit = (data: FormInput) => {
    saveForm({
      variables: {
        input: {
          dateTimeOfObservation: data.dateTimeOfObservation,
          supportWorkerId: data.supportWorkerId,
          observingPersonId: data.observingPersonId,
          formType: 'INCIDENT',
          incident: {
            ...data.incident,
            incidentNumber: Date.now(),
          },
        },
      },
    });
    reset({}, { keepValues: true });

    if (!saveFormLoading) navigate('/forms');
  };

  const isValidForm = () => {
    const answered = watch('observingPersonId') && watch('supportWorkerId') && watch('incident.descriptionOfAccident');
    return answered || false;
  };

  const onBack = () => {
    navigate(supportWorkerId ? `/admin-workers/${supportWorkerId}` : '/forms');
  };

  const tabs = [
    { label: 'Core', component: <FormCoreUpdate disabled={!!formId} /> },
    { label: 'Details', component: <IncidentUpdate disabled={!!formId} user={user} /> },
  ];

  if (formLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Incident Form</title>
      </Helmet>
      <LocationBar section="Forms" page="Manage Incident Form" Icon={ListAlt} />
      <Message response={[saveFormError]} openSnack={openSnack} setOpenSnack={setOpenSnack} />
      <div className="my-10 px-4 md:px-[5%]">
        <button data-cy="form-detail-back" type="button" className="text-gray-500 font-semibold text-md leading-md flex items-center" onClick={onBack}>
          <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
        </button>

        <div className="mt-10 sm:mt-16">
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TabSwitcher tabs={tabs} />
              {saveFormError && <p>{saveFormError.message}</p>}
              {!formId && isValidForm() && (
                <button data-cy="save-form-button" type="submit" className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
                  Save
                </button>
              )}
            </form>
          </FormProvider>
        </div>
      </div>
      <FormPrompt hasUnsavedChanges={formState.isDirty} />
    </>
  );
}
