interface RemoveTemplateProps {
  id: string;
  onClose: () => void;
  onConfirm: (id: string) => void;
}

export default function RemoveTemplate({ onClose, onConfirm, id }: RemoveTemplateProps) {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-[1200] outline-none focus:outline-none">
        <div className="relative w-auto mx-auto max-w-5xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none p-6 max-h-screen md:max-h-[70vh] overflow-y-auto">
            <div className="text-gray-900 text-lg leading-lg font-semibold mb-5">Remove Template</div>
            <div className="text-gray-700 text-md leading-md mb-5">Are you sure you would like to remove this template?</div>
            <div className="flex justify-between items-center">
              <button type="button" className="text-gray-500 font-semibold text-md leading-md" onClick={onClose}>
                Cancel
              </button>
              <button
                type="button"
                className="text-white disabled:opacity-50 bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
                onClick={() => onConfirm(id)}
              >
                Remove Template
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-gray-900" />
    </>
  );
}
