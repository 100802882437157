import { getStoryblokApi } from '@storyblok/react';
import { useState, useEffect } from 'react';
import { SectionStoryblok } from 'types/components-sb';

const useGetGuides = () => {
  const storyblokApi = getStoryblokApi();
  const [guides, setGuides] = useState<SectionStoryblok[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    async function getGuides() {
      setLoading(true);
      try {
        const { data } = await storyblokApi.get('cdn/stories/guides', {
          version: 'draft',
        });
        setGuides(data.story.content.sections || []);
        setLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        setError(e.message);
        setGuides([]);
        setLoading(false);
      }
    }

    if (!guides) {
      getGuides();
    }
  }, [guides, storyblokApi]);

  return { guides, loading, error };
};

export { useGetGuides };
