import { areIntervalsOverlapping, getTime, startOfDay, endOfDay, add } from 'date-fns';
import { Maybe, CustomerPackage } from '__generated__/graphql';

const checkDate = (newDate: number | Date, startDateCheck: number | null | undefined, endDateCheck: number | null | undefined) => {
  if (!startDateCheck || !endDateCheck) return;
  const interval1 = { start: new Date(startDateCheck), end: new Date(endDateCheck) };
  const interval2 = { start: new Date(newDate), end: add(new Date(newDate), { hours: 1 }) };
  return areIntervalsOverlapping(interval1, interval2) || null;
};

export const packageOverlapping = (packages: CustomerPackage[], person: string, newDate: Date | number, idToEdit: string) => {
  const packagesForSp = packages.filter((cp) => cp.customerId === person && cp.id !== idToEdit);
  return packagesForSp.some((psp) => checkDate(newDate, psp?.startDate, psp.endDate));
};

export const hasValidPackage = (packages: CustomerPackage[], person: string) => {
  return packages.filter((cp) => cp.customerId === person).some((p) => !p.endDate);
};

export const packagePriceOverlapping = (customerPackage: Maybe<CustomerPackage> | undefined, newDate: number | Date, idToEdit: string) => {
  return customerPackage?.prices?.filter((p) => p?.id !== idToEdit).some((psp) => checkDate(newDate, psp?.startDate, psp?.endDate));
};

export const setStartOfDay = (startDate: number | Date | null | undefined) => {
  return getTime(startOfDay(startDate || new Date()));
};

export const setEndOfDay = (startDate: number | Date | null | undefined) => {
  return getTime(endOfDay(startDate || new Date()));
};

export const packagePriceDateDoesNotFitPackageDate = (customerPackage: Maybe<CustomerPackage> | undefined, newDate: number | Date) => {
  const date = add(new Date(), { years: 10 });
  return checkDate(newDate, customerPackage?.startDate, customerPackage?.endDate || getTime(date));
};
