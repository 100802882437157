import Avatar from 'components/Avatar';
import React from 'react';
import { AvatarGroupType, ImageSizeType } from 'types/types';

interface AvatarGroupProps {
  avatars: AvatarGroupType[];
  size?: ImageSizeType;
  max?: number;
  border?: boolean;
  largeBorder?: boolean;
  borderWhite?: boolean;
}

const AvatarGroup = ({ avatars, max, size, border, largeBorder = false, borderWhite = false }: AvatarGroupProps) => {
  return (
    <div className="flex -space-x-2">
      {avatars.slice(0, max || avatars.length).map((avatar) => (
        <Avatar
          key={avatar.avatar}
          avatar={avatar.avatar}
          alt={avatar.alt}
          size={size}
          tooltip={avatar.tooltip}
          border={border}
          borderWhite={borderWhite}
          largeBorder={largeBorder}
        />
      ))}
      {max && avatars.length > max && (
        <div className="group relative w-max">
          <div
            className={`${border ? 'border border-primary-500' : ''} flex justify-center items-center bg-primary-700 relative rounded-full ${
              size === ImageSizeType.SM ? 'w-6 h-6' : size === ImageSizeType.LG ? 'w-10 h-10' : 'w-8 h-8'
            } text-white text-md leading-md font-semibold`}
          >
            +{avatars.length - max}
          </div>
          <span className="z-40 pointer-events-none absolute -top-[4.5rem] right-0 w-max opacity-0 transition-opacity group-hover:opacity-100 bg-gray-900 rounded-lg px-3 py-2 flex flex-col gap-1">
            {avatars.slice(max, avatars.length).map((avatar) => (
              <div key={avatar.avatar} className="text-white font-medium">
                {avatar.tooltip}
              </div>
            ))}
          </span>
        </div>
      )}
    </div>
  );
};

export default AvatarGroup;
