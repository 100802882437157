import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ShiftUpdateItem } from '__generated__/graphql';
import { VisitStatus } from 'pages/ReportingHours/service';
import { Loading } from 'components';

interface EnableVisitProps {
  enableVisitModalState: boolean;
  apiLoading: boolean;
  toggleEnableModal: (enableVisitModalState: boolean) => void;
  onSaveVisit: (data: Partial<ShiftUpdateItem>, event: string) => void;
}

export default function EnableVisit({ toggleEnableModal, enableVisitModalState, onSaveVisit, apiLoading }: EnableVisitProps) {
  const onEnableVisit = () => {
    onSaveVisit(
      {
        status: VisitStatus.active,
        cancelledDateTime: null,
        notes: '',
        whoCancelled: null,
      },
      // eslint-disable-next-line prettier/prettier
      'Enable',
    );
  };

  return (
    <Dialog data-cy="enable-visit-dialog" open={enableVisitModalState} onClose={() => toggleEnableModal(false)}>
      <DialogTitle sx={{ fontSize: '1.6em', padding: { xs: '0.8em', sm: '1.2em' } }}>Enable Visit</DialogTitle>
      <DialogContent>Are you sure you would like to enable this visit?</DialogContent>
      <DialogActions sx={{ padding: '1em' }}>
        <Button data-cy="close-visit-button" variant="contained" onClick={() => toggleEnableModal(false)}>
          Close
        </Button>
        {apiLoading ? (
          <Loading isComponent />
        ) : (
          <Button data-cy="enable-visit-button" variant="contained" onClick={() => onEnableVisit()}>
            Enable Visit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
