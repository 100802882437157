/* eslint-disable react/no-danger */
import { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { ElderlyOutlined } from '@mui/icons-material';
import { FormLabel, Loading, LocationBar } from 'components';
import { Maybe, SupportWorker } from '__generated__/graphql';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetSupportWorkers } from 'api/hooks/useSupportWorkers';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

const TeamMemberDetail = () => {
  const { teamMemberId } = useParams();
  const navigate = useNavigate();
  const [teamMember, setTeamMember] = useState<SupportWorker | null>();
  const { supportWorkers, loading } = useGetSupportWorkers({ showAll: true });

  useEffect(() => {
    setTeamMember(supportWorkers.filter((c: SupportWorker) => c.id === teamMemberId)[0]);
  }, [teamMemberId, supportWorkers]);
  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Helmet>
        <title>Team Member</title>
      </Helmet>
      <LocationBar section="People" page="Team Member Detail" Icon={ElderlyOutlined} />
      <div className="mt-10 sm:mt-16">
        <div className="my-10 px-4 md:px-[5%]">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <button type="button" className="text-gray-500 font-semibold text-md leading-md flex items-center" onClick={() => navigate('/team-members/')}>
              <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
            </button>
          </div>
          <div className="my-10 px-4 md:px-[5%]">
            <div className="flex items-center  py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
              <div className="ps-3">
                <img className="w-20 h-20 rounded-full" src={teamMember?.avatar} alt={`Team member ${teamMember?.fullName}`} />
                <div className="text-black text-xl sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1">{teamMember?.fullName}</div>
              </div>
            </div>
            <div className="mt-8 sm:mt-12 w-full">
              <FormLabel label="What people like and admire about me">
                <ul className="w-full lg:w-1/2">
                  {teamMember?.profileData?.aboutMe?.map((aM: Maybe<string>, index: number) => (
                    <li key={index} className="list list-disc">
                      <p>{aM}</p>
                    </li>
                  ))}
                </ul>
              </FormLabel>

              <FormLabel label=" What matters to me">
                <div className="w-full lg:w-1/2" dangerouslySetInnerHTML={{ __html: teamMember?.profileData?.matters || '' }} />
              </FormLabel>
              <FormLabel label="How best to support me">
                <div className="w-full lg:w-1/2" dangerouslySetInnerHTML={{ __html: teamMember?.profileData?.support || '' }} />
              </FormLabel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamMemberDetail;
