import { useState, useMemo } from 'react';
import Helmet from 'react-helmet';
import { useUser, UserContextProps } from 'context/userContext';
import { Diversity2 } from '@mui/icons-material';
import { LocationBar, Message } from 'components';
import TabSwitcher from 'components/TabSwitcher';
import { BuddieGroup, SupportWorker as SupportWorkerType, Customer as CustomerType, CustomerUpdate } from '__generated__/graphql';
import { useGetSupportWorkers } from 'api/hooks/useSupportWorkers';
import { useGetCustomers, useUpdateCustomerLinkWorker } from 'api/hooks/useCustomers';
import { useGetBuddieGroups, useUpdateBuddieGroups } from 'api/hooks/useBuddies';
import TeamSelect from 'components/TeamSelect';
import LinkWorkers from './LinkWorkers';
import Buddies from './Buddies';
import AssignLinkWorker from './AssignLinkWorker';
import AssignBuddie from './AssignBuddie';

export default function TeamSetup() {
  const { user } = useUser() as UserContextProps;
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const [assignLinkWorkerModalState, toggleLinkWorkerModal] = useState<boolean>(false);
  const [selectedCustomer, setCustomer] = useState<CustomerType>();
  const [selectedBuddieGroup, setBuddieGroup] = useState<BuddieGroup | null>();
  const [assignBuddieModalState, toggleBuddieModal] = useState<boolean>(false);
  const [selectedTeam, setSelectedTeam] = useState(user?.teamId);

  const { supportWorkers } = useGetSupportWorkers({
    teamId: selectedTeam,
  });

  const {
    customers,
    error: customersError,
    refetch,
  } = useGetCustomers({
    teamId: selectedTeam,
  });
  const { buddieGroups, buddiesError } = useGetBuddieGroups({
    teamId: selectedTeam,
  });

  const { updateCustomerLinkWorker, mutationCustomerLinkWorker } = useUpdateCustomerLinkWorker({
    id: selectedCustomer?.id,
    teamId: selectedTeam,
  });
  const { updateBuddieGroup, mutationBuddieGroup } = useUpdateBuddieGroups({
    teamId: selectedTeam,
  });

  const onSaveCustomer = async (data: Partial<CustomerUpdate>) => {
    await updateCustomerLinkWorker({
      variables: {
        input: { ...data, teamId: selectedTeam },
      },
    });
    refetch();
    toggleLinkWorkerModal(false);
    setOpenSnack(true);
  };

  const selectCustomer = (customer: CustomerType) => {
    setCustomer(customer);
    toggleLinkWorkerModal(true);
  };

  const selectBuddieGroup = (group: BuddieGroup | null) => {
    setBuddieGroup(group);
    toggleBuddieModal(true);
  };

  const onSaveBuddieGroup = async (data: unknown) => {
    await updateBuddieGroup({
      variables: {
        input: data,
      },
    });
    toggleBuddieModal(false);
    setOpenSnack(true);
  };

  const onRemoveBuddieGroup = (groupId: string) => {
    onSaveBuddieGroup({ teamId: selectedTeam, groupId, buddies: [] });
  };

  const selectedBuddies: string[] = useMemo(() => {
    const selectedIds: string[] = [];
    selectedBuddieGroup?.buddies?.forEach((lw) => {
      if (lw?.id) selectedIds.push(lw.id);
    });
    return selectedIds;
  }, [selectedBuddieGroup?.buddies]);

  const selectedSupportWorkers: SupportWorkerType[] = useMemo(() => {
    return supportWorkers?.filter((supportWorker: SupportWorkerType) => selectedBuddies?.includes(supportWorker?.id));
  }, [selectedBuddies, supportWorkers]);

  const tabs = [
    { label: 'Link Workers', component: <LinkWorkers customers={customers} selectCustomer={selectCustomer} /> },
    { label: 'Buddies', component: <Buddies buddieGroups={buddieGroups} selectBuddieGroup={selectBuddieGroup} onRemoveBuddieGroup={onRemoveBuddieGroup} /> },
  ];
  return (
    <>
      <Helmet>
        <title>Team Setup</title>
      </Helmet>
      <LocationBar section="People" page="Team Setup" Icon={Diversity2} />

      <div className="my-10 px-4 md:px-[5%]">
        <Message response={[mutationCustomerLinkWorker, mutationBuddieGroup, customersError, buddiesError]} openSnack={openSnack} setOpenSnack={setOpenSnack} />
        {assignLinkWorkerModalState && selectedCustomer && (
          <AssignLinkWorker
            customer={selectedCustomer}
            onClose={() => toggleLinkWorkerModal(false)}
            onSaveCustomer={onSaveCustomer}
            selectedTeam={selectedTeam || ''}
          />
        )}
        {assignBuddieModalState && (
          <AssignBuddie
            selectedBuddies={selectedBuddies}
            groupId={selectedBuddieGroup?.groupId}
            modalBuddies={selectedSupportWorkers}
            onClose={() => toggleBuddieModal(false)}
            onSaveBuddieGroup={onSaveBuddieGroup}
            selectedTeam={selectedTeam || ''}
          />
        )}
        <div>
          <div className="w-fit min-w-[25%] mb-10">
            <TeamSelect onSelectedTeam={setSelectedTeam} selectedTeamId={selectedTeam || ''} showAll={false} isContained />
          </div>
          <TabSwitcher tabs={tabs} />
        </div>
      </div>
    </>
  );
}
