import { Reviews } from '@mui/icons-material';
import { useUser, UserContextProps } from 'context/userContext';
import { useGeneric, GenericContextProps } from 'context/genericContext';
import { useGetCustomers } from 'api/hooks/useCustomers';
import { Loading, LocationBar, Filters, Search } from 'components';
import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { AdjustmentsHorizontalIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { SelectOption } from 'types/types';
import { ReviewStatsCharts } from './ReviewStatsCharts';
import OverdueCustomerTable, { OverdueCustomerTableType } from './OverdueCustomerTable';
import { useReviewStats, isNextReviewDueSoon } from './useReviewStats';

const overdueTimes = [
  { label: 'All', value: 'all' },
  { label: '> 1 month', value: '1' },
  { label: '> 3 months', value: '3' },
  { label: '> 6 months', value: '6' },
];

const dueStatuses = [
  { label: 'All', value: 'all' },
  { label: 'Overdue', value: 'Overdue' },
  { label: 'Due Soon', value: 'Due Soon' },
  { label: 'Up to date', value: 'Up to date' },
];
const ReviewStats = () => {
  const { user, userLoading } = useUser() as UserContextProps;
  const { teams: genericTeams } = useGeneric() as GenericContextProps;
  const [circle, setCircle] = useState('all');
  const [team, setTeam] = useState(user?.teamId);
  const [dueStatus, setDueStatus] = useState('all');
  const [search, setSearch] = useState('');

  const [overdueTime, setOverdueTime] = useState('all');
  const teams = useMemo(() => {
    return genericTeams?.map((t) => ({ value: t.teamId, label: t.teamName })) || [];
  }, [genericTeams]);

  const circles = useMemo(() => {
    const dirtyCircles = genericTeams?.map((t) => t.circleName) || [];
    return [...new Set(dirtyCircles.filter((t) => t !== ''))].map((c) => ({ value: c, label: c }));
  }, [genericTeams]);

  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
  const [filters, setFilters] = useState([
    { name: 'circle', selectedValue: { value: 'all', label: 'All' }, values: [{ value: 'all', label: 'All' }, ...circles] },
    {
      name: 'teams',
      selectedValue: user?.teamName ? { value: user?.teamId, label: user.teamName } : { value: 'all', label: 'All' },
      values: [{ value: 'all', label: 'All' }, ...teams],
    },
    { name: 'dueStatus', selectedValue: { value: 'all', label: 'All' }, values: dueStatuses },
    { isHidden: true, name: 'overdueTime', selectedValue: { value: 'all', label: 'All' }, values: overdueTimes },
  ]);

  const { customers: customersData, loading } = useGetCustomers({});

  const filterByCircle = (c: OverdueCustomerTableType) => {
    if (circle === 'all') return true;
    if (circle && c.circle === circle) return true;
    return false;
  };

  const filterByTeam = (c: OverdueCustomerTableType) => {
    if (team === 'all') return true;
    if (c.circle === team) return true;
    if (team && c.teamId === team) return true;
    return false;
  };

  const filterByName = (sw: OverdueCustomerTableType) => {
    if (sw.name.toLowerCase().includes(search.toLowerCase())) return true;
    return false;
  };
  const filterByOverDue = (sw: OverdueCustomerTableType) => {
    const customer = customersData.filter((customerData) => customerData.id === sw.id)[0];
    if (dueStatus === 'all' && overdueTime === 'all') {
      return true;
    } else if (sw.daysOverdue > 0 && dueStatus === 'Overdue' && overdueTime === 'all') {
      return true;
    } else if (sw.daysOverdue > 30 && dueStatus === 'Overdue' && overdueTime === '1') {
      return true;
    } else if (sw.daysOverdue > 91 && dueStatus === 'Overdue' && overdueTime === '3') {
      return true;
    } else if (sw.daysOverdue > 182 && dueStatus === 'Overdue' && overdueTime === '6') {
      return true;
    } else if (sw.daysOverdue < 0 && dueStatus === 'Due Soon' && isNextReviewDueSoon(customer)) {
      return true;
    } else if (sw.daysOverdue < 0 && !isNextReviewDueSoon(customer) && dueStatus === 'Up to date' && customer.reviews && customer.reviews.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const onFilterChange = (name: string, value: SelectOption) => {
    const newFilters = [...filters];
    const filterIndex = newFilters.findIndex((f) => f.name === name);

    newFilters[filterIndex].selectedValue = value;

    if (name === 'circle') {
      const circleTeams =
        value.value !== 'all' ? genericTeams?.filter((t) => t.circleName === value.value).map((t) => ({ value: t.teamId, label: t.teamName })) || [] : teams;

      newFilters[1].values = [{ value: 'all', label: 'All' }, ...circleTeams];
    }

    if (name === 'dueStatus') {
      const overDueIndex = newFilters.findIndex((f) => f.name === 'overdueTime');
      newFilters[overDueIndex].isHidden = value.value !== 'Overdue';
    }

    setFilters(newFilters);
  };

  const { statCustomersList } = useReviewStats(customersData);
  const filteredCustomers = () => {
    return statCustomersList
      .filter((c) => filterByCircle(c))
      .filter((c) => filterByTeam(c))
      .filter((sw) => filterByName(sw))
      .filter((sw) => filterByOverDue(sw));
  };
  const rows = filteredCustomers().map((c) => {
    return {
      ...c,
    };
  });

  const reviewCustomers = customersData.filter((customer) => rows.map((row) => row.id).includes(customer.id));

  const onSaveFilters = () => {
    setCircle(filters[0].selectedValue.value);
    setTeam(filters[1].selectedValue.value);
    setDueStatus(filters[2].selectedValue.value);
    setOverdueTime(filters[3].selectedValue.value);
    setShowFiltersModal(false);
  };

  if (loading || userLoading) {
    return <Loading />;
  }

  const filtersNumber = () => {
    let nb = 0;
    nb += circle !== 'all' ? 1 : 0;
    nb += team !== 'all' ? 1 : 0;
    nb += dueStatus !== 'all' ? 1 : 0;
    nb += dueStatus === 'Overdue' && overdueTime !== 'all' ? 1 : 0;
    return nb;
  };

  return (
    <>
      <Helmet>
        <title>Review Stats</title>
      </Helmet>
      <LocationBar section="Analytics" page="Review Stats" Icon={Reviews} />
      <div className="my-10 px-4 md:px-[5%]">
        <ReviewStatsCharts rows={reviewCustomers} />
        <div className="flex flex-col lg:flex-row items-center justify-between mt-8 md:mt-16">
          <div className="flex flex-col md:flex-row items-center gap-4 md:gap-9 w-full md:w-fit">
            <Search value={search} onSearch={setSearch} />
            <div className="flex flex-col md:flex-row items-center gap-3 w-full md:w-fit">
              <button
                type="button"
                className={`relative border ${
                  team === 'all' && overdueTime === 'all' ? 'border-gray-300' : 'border-gray-900'
                }  text-gray-700 text-md leading-md flex items-center px-4 py-2.5 rounded-lg shadow-xs justify-center w-full md:w-fit`}
                onClick={() => setShowFiltersModal(true)}
              >
                <AdjustmentsHorizontalIcon className="w-5 h-5 mr-2" />
                Filters
                {(circle !== 'all' || team !== 'all' || dueStatus !== 'all' || overdueTime !== 'all') && (
                  <span className="absolute bg-gray-900 h-5 w-5 text-white rounded-full -top-2 -right-2 flex items-center justify-center text-xs font-semibold">
                    {filtersNumber()}
                  </span>
                )}
              </button>
              {user?.permissions?.admin && circle !== 'all' && (
                <div className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center">
                  {circles.find((t) => t.value === circle)?.label}
                  <button
                    type="button"
                    aria-label="remove team filter"
                    className="text-gray-500"
                    onClick={() => {
                      onFilterChange('circle', { value: 'all', label: 'All' });
                      setCircle('all');
                    }}
                  >
                    <XMarkIcon className="w-4 h-4 ml-2" />
                  </button>
                </div>
              )}
              {user?.permissions?.admin && team !== 'all' && (
                <div className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center">
                  {teams.find((t) => t.value === team)?.label}
                  <button
                    type="button"
                    aria-label="remove team filter"
                    className="text-gray-500"
                    onClick={() => {
                      onFilterChange('teams', { value: 'all', label: 'All' });
                      setTeam('all');
                    }}
                  >
                    <XMarkIcon className="w-4 h-4 ml-2" />
                  </button>
                </div>
              )}
              {dueStatus !== 'all' && (
                <div className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center">
                  {dueStatuses.find((t) => t.value === dueStatus)?.label}
                  <button
                    type="button"
                    aria-label="remove due status filter"
                    className="text-gray-500"
                    onClick={() => {
                      onFilterChange('dueStatus', { value: 'all', label: 'All' });
                      onFilterChange('overdueTime', { value: 'all', label: 'All' });
                      setDueStatus('all');
                      setOverdueTime('all');
                    }}
                  >
                    <XMarkIcon className="w-4 h-4 ml-2" />
                  </button>
                </div>
              )}
              {dueStatus === 'Overdue' && overdueTime !== 'all' && (
                <div className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center">
                  {overdueTimes.find((t) => t.value === overdueTime)?.label}
                  <button
                    type="button"
                    aria-label="remove overdue filter"
                    className="text-gray-500"
                    onClick={() => {
                      onFilterChange('overdueTime', { value: 'all', label: 'All' });
                      setOverdueTime('all');
                    }}
                  >
                    <XMarkIcon className="w-4 h-4 ml-2" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <OverdueCustomerTable overdueCustomers={rows} isOverdue={dueStatus === 'Overdue'} />
      </div>

      {showFiltersModal && (
        <Filters filters={filters} onFilterChange={onFilterChange} onCloseModal={() => setShowFiltersModal(false)} onSaveFilters={onSaveFilters} />
      )}
    </>
  );
};

export default ReviewStats;
