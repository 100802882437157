import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useUser, UserContextProps } from 'context/userContext';
import { Calendar, Views, dateFnsLocalizer, View } from 'react-big-calendar';
import { format, getDay, startOfWeek, startOfYear, parse, getTime } from 'date-fns';
import locale from 'date-fns/locale/en-GB';
import Helmet from 'react-helmet';
import { Loading, Message, LocationBar } from 'components';
import { EventAvailable } from '@mui/icons-material';
import { useGetTeamShifts } from 'api/hooks/useTeamShifts';
import { useGetSupportWorkers } from 'api/hooks/useSupportWorkers';
import { EventType, EventTypeType } from './types';
import VisitsToolBar from './VisitsToolBar';
import useGenerateEvents from './useGenerateEvents';
import { useGetLeave } from '../../api/hooks/useLeave';

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales: { 'en-GB': locale },
});

export default function MyVisits() {
  const { user, userLoading } = useUser() as UserContextProps;

  const { profile, teamId: userTeamId } = user || {};

  const [openSnack, setOpenSnack] = useState(false);
  const [startDateTime, setStartDate] = useState(Date.now());
  const [selectedTeam, setSelectedTeam] = useState(userTeamId);

  const { supportWorkers } = useGetSupportWorkers({ showAll: false, teamId: selectedTeam });

  const [selectedSupportWorker, setSelectedSupportWorker] = useState<string | undefined>(profile || supportWorkers[0].id);

  const supportWorker = supportWorkers.find((sw) => sw.id === selectedSupportWorker);

  useEffect(() => {
    if (supportWorker?.teamId !== selectedTeam) {
      setSelectedSupportWorker(supportWorkers[0]?.id);
    }
  }, [selectedTeam, supportWorker?.teamId, supportWorkers]);

  const {
    teamShifts: visits,
    error: visitsError,
    loading: visitsLoading,
  } = useGetTeamShifts(
    {
      secondarySupportWorkerId: selectedSupportWorker,
      startDateTime: getTime(startOfYear(startDateTime)),
    },
    !selectedSupportWorker,
  );

  const { leave, loading: leaveLoading } = useGetLeave(
    {
      supportWorkerId: selectedSupportWorker,
      absenceDate: getTime(startOfYear(startDateTime)),
    },
    !selectedSupportWorker,
  );

  const events = useGenerateEvents(visits, leave, supportWorker);
  const [currentView, setCurrentView] = useState<{ label: string; value: View }>({ label: 'Month', value: Views.MONTH });
  const viewOptions = useMemo(() => {
    return [
      { label: 'Month', value: Views.MONTH },
      { label: 'Week', value: Views.WEEK },
      { label: 'Day', value: Views.DAY },
      { label: 'Schedule', value: Views.AGENDA },
    ];
  }, []);

  const onChangeView = useCallback(
    (value: string) => {
      const selectedView = viewOptions.find((t) => t.label === value);
      setCurrentView(selectedView || viewOptions[0]);
    },
    [viewOptions],
  );

  const handleSelectEvent = useCallback(
    (props: EventType) => {
      setStartDate(props.start.getTime());

      onChangeView('Day');
    },
    [onChangeView],
  );

  const eventPropGetter = (event: EventType) => {
    if (event.cross) return { style: { backgroundColor: 'yellow', color: 'black' } };
    if (event.type === EventTypeType.Birthday) return { style: { backgroundColor: 'orange', color: 'white' } };
    if (event.type === EventTypeType.Activity) return { style: { backgroundColor: 'red', color: 'white' } };
    if (event.type === EventTypeType.Absence) return { style: { backgroundColor: 'purple', color: 'white' } };
    if (event.type === EventTypeType.SickLeave) return { style: { backgroundColor: 'blue', color: 'white' } };
    if (EventTypeType.OtherLeave) return { style: { backgroundColor: 'darkred', color: 'white' } };

    return {};
  };

  const onSelectDateRange = (date: Date) => {
    setStartDate(getTime(date));
  };

  if (userLoading) return <Loading />;

  return (
    <>
      <Helmet>
        <title>My Visits</title>
      </Helmet>
      <LocationBar section="Rota" page="My Visits" Icon={EventAvailable} />
      <div className="my-10 px-4 md:px-[5%]">
        <Message response={[visitsError]} openSnack={openSnack} setOpenSnack={setOpenSnack} />

        <VisitsToolBar
          viewOptions={viewOptions}
          currentView={currentView}
          onChangeView={onChangeView}
          setSelectedSupportWorker={setSelectedSupportWorker}
          teamId={selectedTeam || ''}
          setSelectedTeam={setSelectedTeam}
          selectedSupportWorker={selectedSupportWorker}
          startDateTime={startDateTime}
          onSelectDateRange={onSelectDateRange}
        />
        <div className={leaveLoading || visitsLoading ? 'opacity-50' : ''}>
          <Calendar
            date={new Date(startDateTime)}
            defaultDate={new Date(startDateTime)}
            min={new Date(1667973600000)}
            max={new Date(1641765600000)}
            views={['month', 'week', 'day', 'agenda']}
            onSelectEvent={handleSelectEvent}
            view={currentView.value}
            culture="en-GB"
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 800 }}
            selectable={true}
            popup
            step={30}
            timeslots={2}
            scrollToTime={new Date(startDateTime)}
            enableAutoScroll
            showAllEvents
            showMultiDayTimes
            eventPropGetter={eventPropGetter}
            onNavigate={onSelectDateRange}
            components={{
              toolbar: () => {
                return <div />;
              },
            }}
          />
        </div>
      </div>
    </>
  );
}
