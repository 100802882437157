import * as React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import { Shift, SupportWorker as SupportWorkerType } from '__generated__/graphql';
import { ShiftRunTypeEnum, VisitStatus } from 'pages/ReportingHours/service';

interface SupportWorkerProps {
  supportWorker: SupportWorkerType;
  shifts: Shift[];
}

export default function SupportWorker({ supportWorker, shifts }: SupportWorkerProps) {
  if (!supportWorker) {
    return null;
  }

  const agreed = shifts
    .filter((s) => s.status === VisitStatus.active && s.shiftRun?.ownerId && s.shiftRun?.type === ShiftRunTypeEnum.agreed)
    .map((s) => s.shiftId);
  const oneoff = shifts
    .filter((s) => s.status === VisitStatus.active && s.shiftRun?.ownerId && s.shiftRun?.type === ShiftRunTypeEnum.oneoff)
    .map((s) => s.shiftId);

  return (
    <Card sx={{ my: '1em' }}>
      <Grid container spacing={2} sx={{ padding: '1em' }} alignItems="center" justifyContent={{ xs: 'center', sm: 'left' }}>
        <Grid item>
          <Avatar
            sx={{
              marginTop: '0.6em',
              marginBottom: '0.6em',
              width: '5em',
              height: '5em',
            }}
            alt={supportWorker.fullName}
            src={supportWorker.avatar}
          />
        </Grid>
        <Grid item xs={8} sm={9} md={10}>
          <div className="text-2xl	text-primary sm:text-left	xs: text-center" data-cy="sw-name">
            {supportWorker.fullName}
          </div>
          <div className="text-base	text-secondary sm:text-left	xs:text-center">
            <a data-cy="sw-contact" href={`tel:${supportWorker.phone}`}>
              {supportWorker.phone}
            </a>
          </div>
          <div className="text-base	text-secondary sm:text-left	xs:text-center">Fixed Shifts: {[...new Set(agreed)].length}</div>
          <div className="text-base	text-secondary sm:text-left	xs:text-center"> Banked Hours Shifts: {[...new Set(oneoff)].length}</div>
        </Grid>
      </Grid>
    </Card>
  );
}
