import { Candidate, CurrentStatus } from '__generated__/graphql';
import ReactSelect from 'components/Select';
import { SelectOption } from 'types/types';
import { currentStatuses } from '../types';

const StatusSelect = ({
  status,
  onCandidateStatusUpdate,
  candidate,
}: {
  status: CurrentStatus;
  onCandidateStatusUpdate: (candidate: Candidate, status: string) => void;
  candidate: Candidate;
}) => {
  const selected = currentStatuses.find((c) => c.value === status);
  const onChange = async (s: SelectOption) => {
    await onCandidateStatusUpdate(candidate, s.value);
  };
  let primaryColor = '#F1F2F4';
  let secondaryColor = '#454C54';

  if (
    status === CurrentStatus.Applied ||
    status === CurrentStatus.ContactedForInitialCall ||
    status === CurrentStatus.InitialCallArranged ||
    status === CurrentStatus.InitialCallDone ||
    status === CurrentStatus.WorkshopBooked ||
    status === CurrentStatus.WorkshopAttended ||
    status === CurrentStatus.ShadowingAgreed ||
    status === CurrentStatus.SuccessfulShadowing ||
    status === CurrentStatus.FullAppFormSubmitted ||
    status === CurrentStatus.JobOfferAccepted
  ) {
    primaryColor = '#ECFDF3';
    secondaryColor = '#027A48';
  } else if (status === CurrentStatus.DidNotAnswer || status === CurrentStatus.DidNotShowUp) {
    primaryColor = '#FFFAEB';
    secondaryColor = '#B54708';
  } else if (
    status === CurrentStatus.UnsuccessfulAfterWorkshop ||
    status === CurrentStatus.UnsuccessfulAfterShadowing ||
    status === CurrentStatus.DidNotMeetEligibilityCriteria ||
    status === CurrentStatus.WithdrawnApplication
  ) {
    primaryColor = '#FEF3F2';
    secondaryColor = '#B42318';
  }

  return (
    <ReactSelect
      isTableCol={true}
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
      options={currentStatuses}
      isContained={true}
      selectedValue={selected}
      onChange={onChange}
    />
  );
};

export default StatusSelect;
