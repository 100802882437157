import { useQuery, useMutation, gql } from '@apollo/client';
import { AscotExpectedOutcome, AscotExpectedOutcomeByIdSearch, AscotExpectedOutcomeListSearch } from '../../__generated__/graphql';

export const GET_ASCOT_EXPECTED_OUTCOME = gql`
  query GetAscotExpectedOutcome($query: AscotExpectedOutcomeByIdSearch) {
    GetAscotExpectedOutcome(query: $query) {
      id
      supportedPersonId
      supportWorkerId
      reviewDate
      reviewId
      foodAndDrink {
        score
        answer
      }
      personalSafety {
        score
        answer
      }
      occupation {
        score
        answer
      }
      accomodationAndComfort {
        score
        answer
      }
      personalCleanlinessAndComfort {
        score
        answer
      }
      socialParticipationAndInvolvement {
        score
        answer
      }
      controlOverDailyLife {
        score
        answer
      }
    }
  }
`;

export const GET_ASCOT_EXPECTED_OUTCOMES = gql`
  query GetAscotExpectedOutcomes($query: AscotExpectedOutcomeListSearch) {
    GetAscotExpectedOutcomes(query: $query) {
      id
      supportedPersonId
      supportWorkerId
      reviewDate
      reviewId
      foodAndDrink {
        score
        answer
      }
      personalSafety {
        score
        answer
      }
      occupation {
        score
        answer
      }
      accomodationAndComfort {
        score
        answer
      }
      personalCleanlinessAndComfort {
        score
        answer
      }
      socialParticipationAndInvolvement {
        score
        answer
      }
      controlOverDailyLife {
        score
        answer
      }
    }
  }
`;

export const CREATE_ASCOT_EXPECTED_OUTCOME = gql`
  mutation CreateAscotExpectedOutcome($input: AscotExpectedOutcomeInput) {
    CreateAscotExpectedOutcome(input: $input) {
      message
      date
    }
  }
`;

export const UPDATE_ASCOT_EXPECTED_OUTCOME = gql`
  mutation UpdateAscotExpectedOutcome($input: AscotExpectedOutcomeInput) {
    UpdateAscotExpectedOutcome(input: $input) {
      message
      date
    }
  }
`;

interface GetAscotExpectedOutcomeResponse {
  GetAscotExpectedOutcome: AscotExpectedOutcome;
}
interface GetAscotExpectedOutcomesResponse {
  GetAscotExpectedOutcomes: AscotExpectedOutcome[];
}

const refetchAscotExpectedOutomes = (input: AscotExpectedOutcomeListSearch) => ({
  refetchQueries: [
    {
      query: GET_ASCOT_EXPECTED_OUTCOMES,
      variables: {
        query: { ...input },
      },
    },
  ],
});

export const useGetAscotExpectedOutcome = (query: AscotExpectedOutcomeByIdSearch) => {
  const { loading, data, error } = useQuery<GetAscotExpectedOutcomeResponse>(GET_ASCOT_EXPECTED_OUTCOME, {
    skip: !query.id && !query.reviewId,
    variables: {
      query: {
        ...query,
      },
    },
  });

  return { ascotExpectedOutcome: data?.GetAscotExpectedOutcome, loading, error };
};

export const useGetAscotExpectedOutcomes = (query: AscotExpectedOutcomeListSearch) => {
  const { loading, data, error } = useQuery<GetAscotExpectedOutcomesResponse>(GET_ASCOT_EXPECTED_OUTCOMES, {
    skip: !query.customerId,
    variables: {
      query: {
        ...query,
      },
    },
  });

  return { ascotExpectedOutcomes: data?.GetAscotExpectedOutcomes, loading, error };
};

export const useCreateAscotExpectedOutcome = (query: AscotExpectedOutcomeListSearch) => {
  const [createAscotExpectedOutcome, mutationCreateAscotExpectedOutcome] = useMutation(CREATE_ASCOT_EXPECTED_OUTCOME, refetchAscotExpectedOutomes(query));
  return { createAscotExpectedOutcome, mutationCreateAscotExpectedOutcome };
};

export const useUpdateAscotExpectedOutcome = (query: AscotExpectedOutcomeListSearch) => {
  const [updateAscotExpectedOutcome, mutationUpdateAscotExpectedOutcome] = useMutation(UPDATE_ASCOT_EXPECTED_OUTCOME, refetchAscotExpectedOutomes(query));
  return { updateAscotExpectedOutcome, mutationUpdateAscotExpectedOutcome };
};
