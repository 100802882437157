import { isNextReviewOverdue, isNextReviewDueSoon, getLastAndNextReview } from 'pages/ReviewStats/useReviewStats';
import { format } from 'date-fns';
import { Customer } from '__generated__/graphql';

const WellbeingReviewText = ({ customer }: { customer: Customer }) => {
  const { lastReviewDate, nextReviewDate } = getLastAndNextReview(customer);

  const isOverdue = isNextReviewOverdue(customer);
  const nextReviewIsDue = isNextReviewDueSoon(customer);

  if (lastReviewDate && nextReviewDate) {
    return (
      <div className="text-gray-800 md:text-right text-lg leading-lg font-medium">
        <div>
          Last review:
          <span className="font-bold" data-cy="last-review-date">
            {format(lastReviewDate, 'dd/MM/yy')}
          </span>
        </div>
        <div>
          Next review:
          <span className={`font-bold ${isOverdue ? 'text-error-600' : nextReviewIsDue ? 'text-warning-600' : ''}`} data-cy="next-review-date">
            {format(nextReviewDate, 'dd/MM/yy')}
          </span>
        </div>
        {isOverdue ? (
          <div className="bg-error-600 text-white px-4 py-2 text-lg leading-lg font-medium rounded-lg mt-2 w-full sm:w-fit text-center">
            Client review is overdue
          </div>
        ) : nextReviewIsDue ? (
          <div className="bg-warning-600 text-white px-4 py-2 text-lg leading-lg font-medium mt-2 w-full sm:w-fit text-center">Client review is due soon</div>
        ) : null}
      </div>
    );
  } else if (nextReviewDate && customer?.firstVisitDate) {
    return (
      <div className="text-gray-800 md:text-right text-lg leading-lg font-medium">
        <div>
          First visit: <span className="font-bold">{format(customer?.firstVisitDate, 'dd/MM/yy')}</span>
        </div>
        <div>
          First review:{' '}
          <span className={`font-bold ${isOverdue ? 'text-error-600' : nextReviewIsDue ? 'text-warning-600' : ''}`} data-cy="first-review-date">
            {format(nextReviewDate, 'dd/MM/yy')}
          </span>
        </div>
        {isOverdue ? (
          <div className="bg-error-600 text-white px-4 py-2 text-lg leading-lg font-medium rounded-lg mt-2 w-full sm:w-fit text-center">
            Client review is overdue
          </div>
        ) : nextReviewIsDue ? (
          <div className="bg-warning-600 text-white px-4 py-2 text-lg leading-lg font-medium mt-2 w-full sm:w-fit text-center">Client review is due soon</div>
        ) : null}
      </div>
    );
  } else {
    return (
      <div className="text-gray-800 md:text-right text-lg leading-lg font-medium">
        {customer.firstName} {customer.lastName} didn't had his first visit
      </div>
    );
  }
};

export { WellbeingReviewText };
