import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import EditIcon from '@mui/icons-material/Edit';
import { ConfirmationPracticeGroup } from '__generated__/graphql';
import { formatTime } from '../../services/helpers';
import questions from './questions.json';

interface HistorysProps {
  groups: ConfirmationPracticeGroup[];
  onEditVisit: (data: ConfirmationPracticeGroup) => void;
}

export default function History({ groups, onEditVisit }: HistorysProps) {
  return (
    <Accordion expanded>
      <AccordionSummary>Confirmation Practices History</AccordionSummary>
      <AccordionDetails>
        <List>
          {groups.map((group) => {
            const edit = <EditIcon sx={{ paddingLeft: '0.2em' }} onClick={() => onEditVisit(group)} />;
            const myBuddies = group?.buddies && group?.buddies?.length > 0 ? `${group.buddies?.map((b) => ` ${b?.fullName}`)}` : 'NA';
            return (
              <>
                <ListItem onClick={() => onEditVisit(group)} secondaryAction={edit} disablePadding className="my-3">
                  <ListItemButton sx={{ padding: 0 }}>
                    <ListItemText
                      primary={`${formatTime(group.createdDateTime || 0, 'do MMMM yyyy')} - ${group?.answers?.length} of ${
                        questions.length
                      } - Buddies: ${myBuddies}`}
                    />
                  </ListItemButton>
                </ListItem>
                <Divider />
              </>
            );
          })}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}
