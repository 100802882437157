import { Controller, useFormContext } from 'react-hook-form';
import { SizeType } from 'types/types';
import { ReactNode } from 'react';
import FormLabel from '../FormLabel';
import ToggleButtons from '../ToggleButtons';

interface RadioFieldProps {
  name: string;
  radioOptions?: string[];
  icons?: ReactNode[];
  disabled?: boolean;
  isRequired?: boolean;
  testId?: string;
  label: string;
}
const RadioField = ({ label, name, icons, radioOptions = ['Yes', 'No'], disabled = false, isRequired, testId }: RadioFieldProps) => {
  const { control, register } = useFormContext(); // retrieve all hook methods
  const validate = (value: string) => {
    if (!value) {
      return 'Answer is required';
    } else {
      return true;
    }
  };
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: isRequired ? validate : undefined,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <FormLabel label={label}>
            <ToggleButtons
              formRef={register(name).ref}
              testId={testId}
              icons={icons}
              disabled={disabled}
              buttons={radioOptions}
              size={SizeType.Half}
              selectedButton={value}
              onSelectButton={(selected: string) => onChange(selected)}
            />
            <p className="mt-2 text-sm text-error-600 dark:text-green-500">
              <span className="font-medium">{error?.message}</span>
            </p>
          </FormLabel>
        );
      }}
    />
  );
};

export { RadioField };
