import { Maybe, Permissions, RotaGroup } from '__generated__/graphql';

export type Days = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export type RotaUserType = {
  data: RotaGroup;
  endRotaDateTime: number;
  id: string;
  startRotaDateTime: number;
  teamId: string;
};

export type User = {
  avatar?: string;
  circleId?: string;
  circleName?: string;
  contractedHours?: number;
  email?: string;
  email_verified?: boolean;
  firstName?: string;
  fullName?: string;
  isGuest: boolean;
  isTeamMember: boolean;
  lastName?: string;
  dob?: Maybe<string> | undefined;
  lat?: number;
  lng?: number;
  name?: string;
  permissions?: Maybe<Permissions> | undefined;
  picture?: string;
  position?: string;
  profile?: string;
  rota: RotaUserType;
  sub?: string;
  teamId: string;
  teamName?: string;
  employmentStatus?: string;
  __typename?: string;
};

export type OptionType = {
  id: string;
  label: string;
};

export type SelectOption<T = string> = {
  value: T;
  label: string;
  id?: string;
};

export type AvatarGroupType = {
  avatar: string;
  alt: string;
  tooltip?: string;
};

export enum SizeType {
  Half = 'half',
  Full = 'full',
  Fit = 'fit',
}

export enum ImageSizeType {
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
  LG2 = 'LG2',
  XL = 'XL',
}

export const activityTypes = [
  { value: 'BirdieCarePlans', label: 'Birdie Care Plans' },
  { value: 'BuddyMeeting', label: 'Buddy Meeting' },
  // { value: 'CoveringAVisit', label: 'Covering a visit' },
  { value: 'CommunityOfPractice', label: 'Community of Practice' },
  { value: 'InductionTracking', label: 'Induction' },
  { value: 'InitialConversation', label: 'Initial conversation' },
  { value: 'LinkWorkerJobs', label: 'Link Worker Jobs' },
  { value: 'Marketing', label: 'Marketing' },
  { value: 'Meeting', label: 'Meeting' },
  { value: 'Recruitment', label: 'Recruitment' },
  { value: 'ReviewsOfPeopleWeSupport', label: 'Reviews of people we support' },
  { value: 'RotaPlanning', label: 'Rota Planning' },
  { value: 'Shadowing', label: 'Shadowing' },
  { value: 'SlackUpdates', label: 'Slack Updates' },
  { value: 'Storytelling', label: 'Storytelling' },
  { value: 'TheBellevieWay', label: 'The Bellevie Way' },
  { value: 'Thrive', label: 'Thrive' },
  { value: 'TimeWithPeopleWeSupport', label: 'Time with People we Support' },
  { value: 'Training', label: 'Training' },
];
