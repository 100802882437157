import { Marker, OverlayView, TrafficLayer } from '@react-google-maps/api';

interface MapMarkerProps {
  index?: number;
  markerLabel: string;
  background: string;
  lat: number;
  lng: number;
  zIndex?: number;
  color?: string;
}

const MapMarker = ({ index, markerLabel, background, lat, lng, color, zIndex }: MapMarkerProps) => {
  return (
    <div key={index}>
      <Marker
        animation={window.google.maps.Animation.DROP}
        title={markerLabel}
        zIndex={zIndex}
        position={{
          lat,
          lng,
        }}
      />
      <OverlayView
        position={{
          lat,
          lng,
        }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <div style={{ border: '1px solid #ccc', padding: 4, fontSize: '1em', zIndex, color: color || 'white', background, width: 'max-content' }}>
          {markerLabel}
        </div>
      </OverlayView>
      <TrafficLayer />
    </div>
  );
};

export { MapMarker };
