import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import { ElderlyOutlined } from '@mui/icons-material';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { Loading, Message, LocationBar } from 'components';
import { GenericContextProps, useGeneric } from 'context/genericContext';
import { useGetHubspotSupportedPerson, useCreateSupportedPerson } from 'api/hooks/useCustomers';
import TabSwitcher from 'components/TabSwitcher';
import HubspotExistingTeamContact from './HubspotExistingTeamContact';
import HubspotNewTeamContact from './HubspotNewTeamContact';
import { CreateCustomerInput } from '../../__generated__/graphql';

function ManageHubspotContact() {
  const navigate = useNavigate();
  const { teams } = useGeneric() as GenericContextProps;
  const { supportedPersonId } = useParams();
  const { loading, customer } = useGetHubspotSupportedPerson({ id: supportedPersonId });
  const { createSupportedPerson, mutationCreateSupoprtedPerson } = useCreateSupportedPerson();
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const [validation, setValidation] = useState<string | null>(null);

  const onSubmit = async (data: CreateCustomerInput) => {
    setOpenSnack(true);

    if (teams?.find((t) => t.teamName === data.teamName)) {
      setValidation('A team with this name already exists.');
      return;
    }

    const person = await createSupportedPerson({
      variables: {
        input: data,
      },
    });

    setOpenSnack(false);
    if (person?.data?.CreateSupportedPerson?.id) {
      navigate(`/admin-people/${person?.data?.CreateSupportedPerson?.id}`);
    }
  };

  const tabs = [
    {
      label: 'Existing Team',
      component: <HubspotExistingTeamContact customer={customer} teams={teams || []} onSubmit={onSubmit} />,
    },
    {
      label: 'New Team',
      component: <HubspotNewTeamContact customer={customer} teams={teams || []} onSubmit={onSubmit} />,
    },
  ];

  if (loading || mutationCreateSupoprtedPerson.loading) return <Loading />;

  return (
    <>
      <Helmet>
        <title>Manage Hubspot Contact</title>
      </Helmet>
      <LocationBar section="Hubspot Contacts" page="Manage Hubspot Contact" Icon={ElderlyOutlined} />
      <Message response={[mutationCreateSupoprtedPerson]} openSnack={openSnack} setOpenSnack={setOpenSnack} />
      <div className="my-10 px-4 md:px-[5%]">
        <button type="button" className="text-gray-500 font-semibold text-md leading-md flex items-center" onClick={() => navigate('/admin-people/hubspot/')}>
          <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
        </button>
        <div className="mt-10 sm:mt-16">
          {validation && <div className="mb-5 text-md text-error-600 font-medium">{validation}</div>}
          <TabSwitcher tabs={tabs} />
        </div>
      </div>
    </>
  );
}

export default ManageHubspotContact;
