import { NoSymbolIcon, ShieldExclamationIcon, TrophyIcon, UserIcon } from '@heroicons/react/24/outline';
import { Candidate, CandidateStatus } from '__generated__/graphql';

interface CandidateStatsProps {
  candidates: Candidate[];
}

const CandidatesStats = ({ candidates }: CandidateStatsProps) => {
  const all = candidates.length;
  const open = candidates.filter((c) => c.status === CandidateStatus.Open).length;
  const closed = candidates.filter((c) => c.status === CandidateStatus.Closed).length;
  const hired = candidates.filter((c) => c.status === CandidateStatus.Hired).length;

  return (
    <div className="flex flex-row gap-4 md:gap-6 w-full mt-7 md:mt-12 overflow-auto">
      <div className="rounded-lg bg-primary-600 px-6 py-4 w-full">
        <div className="text-sm leading-sm font-semibold text-white flex items-center">
          <div className="p-1 border border-white bg-gray-300 rounded-full mr-2">
            <UserIcon className="w-4 h-4" />
          </div>
          All Candidates
        </div>
        <div className="text-white text-display-md leading-display-md tracking-display-md font-medium mt-2">{all}</div>
      </div>
      <div className="rounded-lg bg-primary-600 px-6 py-4 w-full">
        <div className="text-sm leading-sm font-semibold text-white flex items-center">
          <div className="p-1 border border-white bg-warning-500 rounded-full mr-2">
            <ShieldExclamationIcon className="w-4 h-4" />
          </div>
          Open
        </div>
        <div className="text-white text-display-md leading-display-md tracking-display-md font-medium mt-2">{open}</div>
      </div>
      <div className="rounded-lg bg-primary-600 px-6 py-4 w-full">
        <div className="text-sm leading-sm font-semibold text-white flex items-center">
          <div className="p-1 border border-white bg-gray-300 rounded-full mr-2">
            <NoSymbolIcon className="w-4 h-4" />
          </div>
          Closed
        </div>
        <div className="text-white text-display-md leading-display-md tracking-display-md font-medium mt-2">{closed}</div>
      </div>
      <div className="rounded-lg bg-primary-600 px-6 py-4 w-full">
        <div className="text-sm leading-sm font-semibold text-white flex items-center">
          <div className="p-1 border border-white bg-success-500 rounded-full mr-2">
            <TrophyIcon className="w-4 h-4" />
          </div>
          Hired
        </div>
        <div className="text-white text-display-md leading-display-md tracking-display-md font-medium mt-2">{hired}</div>
      </div>
    </div>
  );
};

export default CandidatesStats;
