import { Controller, useFormContext } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import FormLabel from '../FormLabel';

interface FormatedNumberFieldProps {
  label: string;
  placeholder: string;
  name: string;
  disabled?: boolean;
  format?: string;
  validate?: (value: string) => boolean | string;
  isRequired?: boolean;
}
const FormatedNumberField = ({ label, placeholder, name, format, disabled = false, validate, isRequired = false }: FormatedNumberFieldProps) => {
  const { control, register } = useFormContext(); // retrieve all hook methods

  return (
    <FormLabel label={label}>
      <div className="w-full lg:w-1/2 ">
        <Controller
          name={name}
          control={control}
          rules={isRequired ? { required: 'This is required.', validate } : {}}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <div>
                <PatternFormat
                  getInputRef={register(name).ref}
                  disabled={disabled}
                  placeholder={placeholder}
                  // eslint-disable-next-line max-len
                  className="bg-white px-5 py-2  disabled:bg-slate-50 disabled:text-slate-500 rounded-lg text-md leading-md text-gray-700 placeholder:text-gray-500 border border-gray-300 shadow-xs w-full" // eslint-disable-next-line max-len
                  format={format || '#### #### #### ####'}
                  name={name}
                  value={value}
                  onChange={onChange}
                />
                <p className="mt-2 text-sm text-error-600 dark:text-green-500">
                  <span className="font-medium">{error?.message}</span>
                </p>
              </div>
            );
          }}
        />
      </div>
    </FormLabel>
  );
};

export { FormatedNumberField };
