import { gql, useMutation, useQuery } from '@apollo/client';
import { RotaGroup, RotaSearch } from '__generated__/graphql';

export const GET_ROTAS = gql`
  query GetRotas($query: RotaSearch) {
    GetRotas(query: $query) {
      future {
        id
        teamId
        startRotaDateTime
        endRotaDateTime
        status
      }
      current {
        id
        teamId
        startRotaDateTime
        endRotaDateTime
        status
      }
      previous {
        id
        teamId
        startRotaDateTime
        endRotaDateTime
        status
      }
    }
  }
`;

export const SAVE_ROTA = gql`
  mutation SaveRota($input: RotaInput) {
    SaveRota(input: $input) {
      message
      date
    }
  }
`;

export const DELETE_ROTA = gql`
  mutation DeleteRota($input: RotaDelete) {
    DeleteRota(input: $input) {
      message
      date
    }
  }
`;

export const refetchRota = (query: RotaSearch) => {
  return {
    skip: !query.teamId,
    refetchQueries: [
      {
        query: GET_ROTAS,
        variables: {
          query: {
            ...query,
          },
        },
      },
    ],
  };
};

interface GetRotasResponse {
  GetRotas: RotaGroup;
}

export const useGetRotas = (query: RotaSearch) => {
  const {
    loading: rotaLoading,
    error: rotaError,
    data,
    refetch,
  } = useQuery<GetRotasResponse>(GET_ROTAS, {
    skip: !query.teamId,
    variables: {
      query: {
        ...query,
      },
    },
  });
  return { refetch, rotaLoading, rotaError, rotaData: data?.GetRotas };
};
export const useSaveRota = (query: RotaSearch) => {
  const [saveRota, saveRotaResponse] = useMutation(SAVE_ROTA, refetchRota(query));
  return { saveRota, saveRotaResponse };
};

export const useDeleteRota = (query: RotaSearch) => {
  const [deleteRota, deleteRotaRepsonse] = useMutation(DELETE_ROTA, refetchRota(query));
  return { deleteRota, deleteRotaRepsonse };
};
