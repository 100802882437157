import { useState } from 'react';
import { useUser, UserContextProps } from 'context/userContext';
import { Helmet } from 'react-helmet';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Loading, LocationBar, TabPanel, Tabs } from 'components';
import { Schedule } from '@mui/icons-material';
import AgreedShifts from './AgreedShift/AgreedShifts';
import AgreedVisits from './AgreedVisit/AgreedVisits';
import ContractedHours from './ContractedHours/ContractedHours';
import SelectAgreedRota from './AgreedRota/SelectAgreedRota';
import Availabilities from './Availabilities/Availabilities';
import RotaStats from './RotaStats';
import Holidays from './Holidays';
import AgreedVisitsOptimised from './AgreedVisitOptimised';

const AgreedShiftsContainer = () => {
  const { user, userLoading } = useUser() as UserContextProps;
  const [selectedTeam, setSelectedTeam] = useState<string>(user?.teamId || '');
  const [selectedAgreedRota, setSelectedAgreedRota] = useState('0');
  const [date, setDate] = useState(0);
  const [isDuplicateVisits, setIsDuplicateVisits] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [currentTab, setTab] = useState<number>(0);
  const tabs = ['Shifts Template', 'Visits Template', 'Optimised Visits Template', 'Availabilities Template', 'Contracted Hours', 'Statistics', 'Holidays'];

  const onSelectTeam = (teamId: string, teamName: string) => {
    setIsDuplicateVisits(false);
    setSelectedTeam(teamId);
    localStorage.setItem('teamId', teamId);
    localStorage.setItem('teamName', teamName);
    setSelectedAgreedRota('0');
  };

  const onAgreedRotaChange = (agreedRotaId: string, newDate: number) => {
    setIsDuplicateVisits(false);
    setSelectedAgreedRota(agreedRotaId);
    setDate(newDate);
  };
  if (userLoading) return <Loading />;

  return (
    <>
      <Helmet>
        <title>Agreed Shifts</title>
      </Helmet>
      <LocationBar section="Rota" page="Rota templates" Icon={Schedule} />
      <DndProvider backend={HTML5Backend}>
        <div className="my-10 px-4 md:px-[5%]">
          <div className="text-lg leading-lg text-gray-800 mb-4" data-cy="rota-message">
            Here you can plan shifts and visits. Changes here do not affect the live rota. When you create a brand new live rota, it will use these templates.
          </div>
          {user && (
            <SelectAgreedRota
              user={user}
              selectedTeamId={selectedTeam || ''}
              selectedAgreedRotaId={selectedAgreedRota}
              isDuplicateVisits={isDuplicateVisits}
              onSelectedTeam={onSelectTeam}
              onSelectedRota={onAgreedRotaChange}
              setIsDisable={setIsDisable}
            />
          )}
          {user && selectedTeam !== '' && selectedAgreedRota !== '0' && (
            <div className="mt-10">
              <Tabs tabs={tabs} selectedTab={currentTab} onChange={setTab} />
              <TabPanel value={currentTab} index={0} data-cy="agreed-shifts-panel">
                <AgreedShifts selectedTeam={selectedTeam} selectedAgreedRota={selectedAgreedRota} date={date} isDisable={isDisable} />
              </TabPanel>
              <TabPanel value={currentTab} index={1} data-cy="agreed-visits-panel">
                <AgreedVisits
                  date={date}
                  selectedTeam={selectedTeam}
                  selectedAgreedRota={selectedAgreedRota}
                  isDisable={isDisable}
                  isDuplicateVisit={setIsDuplicateVisits}
                />
              </TabPanel>
              <TabPanel value={currentTab} index={2} data-cy="optimised-agreed-visits-panel">
                <AgreedVisitsOptimised
                  date={date}
                  selectedTeam={selectedTeam}
                  selectedAgreedRota={selectedAgreedRota}
                  isDisable={true}
                  isDuplicateVisit={setIsDuplicateVisits}
                />
              </TabPanel>
              <TabPanel value={currentTab} index={3} data-cy="agreed-availabilities-panel">
                <Availabilities date={date} selectedTeam={selectedTeam} selectedAgreedRota={selectedAgreedRota} />
              </TabPanel>
              <TabPanel value={currentTab} index={4} data-cy="agreed-contractedhours-panel">
                <ContractedHours teamId={selectedTeam} user={user} selectedAgreedRota={selectedAgreedRota} />
              </TabPanel>
              <TabPanel value={currentTab} index={5} data-cy="agreed-rotastats-panel">
                <RotaStats selectedTeam={selectedTeam} selectedAgreedRota={selectedAgreedRota} />
              </TabPanel>
              <TabPanel value={currentTab} index={6} data-cy="agreed-holidays-panel">
                <Holidays selectedTeam={selectedTeam} startDate={date} />
              </TabPanel>
            </div>
          )}
        </div>
      </DndProvider>
    </>
  );
};

export default AgreedShiftsContainer;
