import { AddOutlined } from '@mui/icons-material';
import { format } from 'date-fns';
import { useState } from 'react';
import { DeleteModal } from 'components/modals/DeleteModal';
import { CustomerReviewData } from 'pages/WellbeingBoard/types';
import { CustomerReview, Maybe } from '__generated__/graphql';
import { Message } from 'components';
import { useParams } from 'react-router-dom';
import { useSaveCustomerReviews } from 'api/hooks/useCustomers';
import { useDeleteAscotReview } from 'api/hooks/useAscotReviews';
import { WellbeingBoardReview } from './WellbeingBoardReview';
import EditWellbeingReview from './EditWellbeingReview';

interface WellbeingBoardReviewsProps {
  firstVisitDate: number | undefined | null;
  reviewsList: CustomerReviewData[];
  teamId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reveiewRef?: any;
}

export default function WellbeingBoardReviews({ reveiewRef, reviewsList, firstVisitDate, teamId }: WellbeingBoardReviewsProps) {
  const { supportedPersonId } = useParams();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedReviewId, setSelectedReviewId] = useState<string | undefined>();

  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const { deleteAscotReview, mutationDeleteAscotReview } = useDeleteAscotReview({
    teamId,
    id: supportedPersonId,
  });
  const { saveCustomerReviews, mutationSaveCustomerReviews } = useSaveCustomerReviews({
    teamId,
    id: supportedPersonId,
  });

  const onDeleteReview = async (id: string) => {
    const newReviews = mappedReviews.filter((item) => {
      return item.id !== id;
    });
    await deleteAscotReview({
      variables: {
        input: {
          reviewId: id,
          customerId: supportedPersonId,
        },
      },
    });
    onSaveCustomerReviews(newReviews);
    onDeleteClose();
  };

  const onDeleteClose = () => {
    setDeleteModalOpen(false);
  };
  const onDeleteModalOpen = (id: string) => {
    setDeleteModalOpen(true);
    setSelectedReviewId(id);
  };

  const onConfirm = () => {
    onDeleteReview(selectedReviewId || '');
  };

  const [showEditWellbeingReview, setShowEditWellbeingReview] = useState(false);
  const mappedReviews = reviewsList.map((review) => {
    return {
      id: review.id,
      date: review.date,
      requestedBy: review.requestedBy,
      note: review.note,
    };
  });
  const [selectedReview, setSelectedReview] = useState<Maybe<CustomerReview>>();
  const onEditReview = (note: Maybe<CustomerReview>) => {
    setSelectedReview(note);
    setShowEditWellbeingReview(true);
  };

  const onSaveCustomerReviews = async (reviews: CustomerReview[]) => {
    if (supportedPersonId && teamId) {
      const reviewsInput = {
        id: supportedPersonId ?? '',
        teamId: teamId ?? '',
        reviews,
      };
      await saveCustomerReviews({
        variables: {
          input: reviewsInput,
        },
      });
      setOpenSnack(true);
    }
  };

  const onEditReviews = async (data: CustomerReview) => {
    const newReviews = [...mappedReviews];

    const objIndex = mappedReviews.findIndex((r) => r?.id === data?.id);
    if (objIndex > -1) {
      (newReviews[objIndex] as CustomerReview) = data;
    } else {
      newReviews.push(data);
    }
    onSaveCustomerReviews(newReviews);
    setShowEditWellbeingReview(false);
  };

  return (
    <div ref={reveiewRef}>
      <Message response={[mutationSaveCustomerReviews, mutationDeleteAscotReview]} openSnack={openSnack} setOpenSnack={setOpenSnack} />

      <div className="flex flex-col    mt-2 mb-5">
        {firstVisitDate && (
          <div className="flex flex-row items-center gap-2">
            <div className="text-gray-800 font-medium text-xl leading-xl">First visit:</div>
            <div className="text-gray-800 font-semibold text-lg leading-lg">{format(firstVisitDate, 'dd/MM/yyyy')}</div>
          </div>
        )}
        <button
          type="button"
          onClick={() => {
            setSelectedReview(undefined);
            setShowEditWellbeingReview(true);
          }}
          className="border border-primary-700 text-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center gap-2 w-fit mt-2  "
        >
          <AddOutlined className="w-5 h-5" />
          Add a review
        </button>
      </div>
      {reviewsList.map((review, index) => {
        return (
          <WellbeingBoardReview
            onDeleteModalOpen={onDeleteModalOpen}
            onEditReview={onEditReview}
            number={reviewsList.length - (index + 1)}
            review={review}
            teamId={teamId}
          />
        );
      })}
      {showEditWellbeingReview && (
        <EditWellbeingReview review={selectedReview} onClose={() => setShowEditWellbeingReview(false)} onEditReviews={onEditReviews} />
      )}
      <DeleteModal onClose={onDeleteClose} open={deleteModalOpen} onConfirm={onConfirm} isDisabled={mutationDeleteAscotReview.loading} />
    </div>
  );
}
