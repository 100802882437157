import React, { useState, useEffect } from 'react';
import getTime from 'date-fns/getTime';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Alert from '@mui/material/Alert';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { clockMessage } from 'config/messages';
import { getEndDateTime, getStartDateTime } from 'services/helpers';
import { CancelVisitInput, Shift } from '__generated__/graphql';
import { Loading } from 'components';
import { RotaUserType } from 'types/types';

interface CancelVisitProps {
  shift: Shift | null;
  cancelVisitModalState: boolean;
  apiLoading: boolean;
  rota: RotaUserType;
  toggleCancelModal: (cancelVisitModalState: boolean) => void;
  onCancelVisit: (update: Partial<CancelVisitInput>) => void;
}

export default function CancelVisit({ rota, shift, toggleCancelModal, cancelVisitModalState, onCancelVisit, apiLoading }: CancelVisitProps) {
  const [notes, setNotes] = useState<string>('');
  const [whoCancelled, setWhoCancelled] = useState<string>('0');
  const [cancelledDateTime, setCancelledDateTime] = useState<Date | null>(null);
  const [isToBeRescheduled, setIsToBeRescheduled] = useState<string>('isNotToBeRescheduled');
  const [deleteMultiple, setDeleteMultiple] = useState<string>('deleteSingle');
  const [periodDateTime, setPeriodDateTime] = useState<Date | null>(null);
  const [validation, setValidation] = useState<string | null>(null);

  useEffect(() => {
    setWhoCancelled('0');
    setCancelledDateTime(null);
    setValidation(null);
  }, []);

  const CancelAVisit = () => {
    setValidation(null);

    if (whoCancelled === '0') {
      setValidation('Please specify who cancelled this visit');
      return;
    }

    if (!cancelledDateTime) {
      setValidation('Please specify a cancellation date');
      return;
    }

    if (!notes) {
      setValidation('Please specify a cancellation notes');
      return;
    }

    if (deleteMultiple === 'deleteMultiple' && !periodDateTime) {
      setValidation('Please specify a cancellation date until date');
      return;
    }

    onCancelVisit({
      cancelledDateTime: cancelledDateTime ? getTime(cancelledDateTime) : null,
      whoCancelled,
      notes,
      isToBeRescheduled,
      visitId: shift?.id,
      teamId: shift?.teamId,
      rotaId: shift?.rotaId,
      periodDateTime: periodDateTime ? getTime(periodDateTime) : null,
    });
  };

  const onChangeWhoCancelled = (e: SelectChangeEvent) => {
    setWhoCancelled(e.target.value);
  };

  const onChangeCancelledDateTime = (dateTime: Date | null) => {
    if (dateTime) {
      setCancelledDateTime(dateTime);
    }
  };

  return (
    <Dialog data-cy="cancel-visit-dialog" open={cancelVisitModalState} onClose={() => toggleCancelModal(false)}>
      <DialogTitle sx={{ fontSize: '1.6em', padding: { xs: '0.8em', sm: '1.2em' } }}>Cancel Visit</DialogTitle>
      <DialogContent>
        {validation && (
          <Alert variant="filled" severity="error" sx={{ width: '90%', marginLeft: '0em', marginBottom: '1em' }}>
            {validation}
          </Alert>
        )}
        <Typography sx={{ ml: { xs: '0em', sm: '0.4' } }}>
          Are you sure you would like to cancel this visit? This will mark it as cancelled on the rota.
        </Typography>
        <Typography sx={{ mt: '1em', ml: { xs: '0em', sm: '0.4' } }}>
          <b>If this visit was added in error or the person has passed away, please close this box and remove the visit in your rota template.</b>
        </Typography>
        <FormControl data-cy="who-cancelled-selector" sx={{ width: '90%', ml: '0em', mb: '1em', mt: '1em' }}>
          <Typography>Why is the visit to be cancelled??</Typography>
          <Select size="small" id="who-cancelled" value={whoCancelled} onChange={onChangeWhoCancelled}>
            <MenuItem data-cy="who-cancelled-item-0" value="0">
              Please specify who cancelled this visit
            </MenuItem>
            <MenuItem data-cy="who-cancelled-item-1" value="BelleVie">
              BelleVie could not do the visit
            </MenuItem>
            <MenuItem data-cy="who-cancelled-item-2" value="Family">
              The family or person we support cancelled it
            </MenuItem>
            <MenuItem data-cy="who-cancelled-item-3" value="Client">
              The person we support is in hospital/respite care
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ width: '90%', ml: '0em', mb: '1em', mt: '0.4em' }}>
          <Typography>Date we received the cancellation</Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              format="PPPP HH:mm"
              ampm={false}
              closeOnSelect
              minutesStep={5}
              value={cancelledDateTime}
              onChange={onChangeCancelledDateTime}
              slotProps={{ textField: { helperText: clockMessage, placeholder: 'Select a date' } }}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl data-cy="notes" sx={{ width: '90%', ml: '0em', mb: '1em', mt: '0.4em' }}>
          <Typography>Notes</Typography>
          <textarea style={{ width: '100%', border: '1px solid #ddd', padding: '1em' }} rows={5} value={notes} onChange={(e) => setNotes(e.target.value)} />
        </FormControl>
        <FormControl sx={{ width: '90%', ml: '1em', mb: '1em', mt: '1em' }}>
          <FormLabel>To be rescheduled</FormLabel>
          <RadioGroup row name="isToBeRescheduled" value={isToBeRescheduled} onChange={(e) => setIsToBeRescheduled(e.target.value)}>
            <FormControlLabel value="isNotToBeRescheduled" control={<Radio />} label="Will not reschedule visit" />
            <FormControlLabel value="isToBeRescheduled" control={<Radio />} label="Will reschedule visit" />
          </RadioGroup>
        </FormControl>
        <FormControl sx={{ width: '90%', ml: '1em', mb: '1em', mt: '1em' }}>
          <FormLabel>Delete Multiple Visits</FormLabel>
          <RadioGroup row name="isToBeRescheduled" value={deleteMultiple} onChange={(e) => setDeleteMultiple(e.target.value)}>
            <FormControlLabel value="deleteSingle" control={<Radio />} label="Delete Single" />
            <FormControlLabel value="deleteMultiple" control={<Radio />} label="Delete Multiple" />
          </RadioGroup>
        </FormControl>

        {deleteMultiple === 'deleteMultiple' && (
          <FormControl sx={{ width: '90%', ml: '0em', mb: '1em', mt: '0.4em' }}>
            <Typography>Cancel visits until</Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                format="PPPP HH:mm"
                ampm={false}
                closeOnSelect
                minutesStep={5}
                value={periodDateTime}
                onChange={setPeriodDateTime}
                minDate={getStartDateTime(shift?.startDateTime || 0)}
                maxDate={getEndDateTime(rota?.endRotaDateTime || 0)}
                slotProps={{ textField: { helperText: clockMessage, placeholder: 'Select a date' } }}
              />
            </LocalizationProvider>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: '1em' }}>
        <Button data-cy="close-visit-button" variant="contained" onClick={() => toggleCancelModal(false)}>
          Close
        </Button>
        {apiLoading ? (
          <Loading isComponent />
        ) : (
          <Button data-cy="cancel-visit-button" variant="contained" onClick={() => CancelAVisit()}>
            Cancel Visit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
