import { createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom';
import Layout from 'components/Layout';
import NotFound from 'components/NotFound';
import { adminRoutes } from './adminRoutes';
import { publicRoutes } from './publicRoutes';
import { devRoutes } from './devRoutes';

const routes: RouteObject[] = [
  {
    element: <Layout />,
    children: [...publicRoutes, ...adminRoutes, ...devRoutes, { path: '*', element: <NotFound /> }],
  },
];

const router = createBrowserRouter(routes);

const Routes = () => {
  return <RouterProvider router={router} />;
};

export default Routes;
