import { RouteObject } from 'react-router-dom';
import CandidateDetail from 'pages/Candidates/CandidateDetail';
import CandidateList from 'pages/Candidates/CandidateList';
import ManageSupportWorkerDetail from 'pages/ManageSupportWorkers/ManageSupportWorkerDetail';
import SupportWorkerList from 'pages/ManageSupportWorkers/SupportWorkerList';
import ManageSupportedPeopleList from 'pages/ManageSupportedPeople/SupportedPeopleList';
import MangageSupportedPeopleDetail from 'pages/ManageSupportedPeople/MangageSupportedPeopleDetail';
import ConfirmationPracticesReport from 'pages/Reports/ConfirmationPracticesReport';
import MagicNumberReport from 'pages/Reports/RotaReport';
import SupportWorkerReport from 'pages/Reports/SupportWorkerReport';
import Survey from 'pages/Survey';
import TemplateList from 'pages/Templates/TemplateList';
import WorkshopDetail from 'pages/Workshops/WorkshopDetail';
import WorkshopList from 'pages/Workshops/WorkshopList';
import Report from 'pages/Survey/Report';
import CandidateShadowFeedbackStandalone from 'pages/Candidates/CandidateShadowFeedbackStandalone';
import CandidateCreate from 'pages/Candidates/CandidateCreate';
import AnnualVisitCancellationReport from 'pages/Reports/AnnualVisitCancellationReport';
import TeamList from 'pages/Teams/TeamList';
import Kpi from 'pages/Kpi/index';
import CancellationVisitsReport from 'pages/Reports/CancelletionVisitsReport';
import HubSpotContactList from 'pages/ManageSupportedPeople/HubSpotContactList';
import ManageHubspotContact from 'pages/ManageSupportedPeople/ManageHubspotContact';
import EventLog from 'pages/EventLog';
import GenerateRotaReport from 'pages/Reports/GenerateRotaReport';
import IncidentDetail from 'pages/Forms/Incident/IncidentDetail';
import MedicalObvsDetail from 'pages/Forms/MedicalObvs/MedicalObvsDetail';
import AscotReviewCreate from 'pages/WellbeingBoard/AscotReview/AscotReviewCreate';
import AscotReviewUpdate from 'pages/WellbeingBoard/AscotReview/AscotReviewUpdate';
import AscotExpectedOutcomeCreate from 'pages/WellbeingBoard/AscotExpectedOutcome/AscotExpectedOutcomeCreate';
import AscotExpectedOutcomeUpdate from 'pages/WellbeingBoard/AscotExpectedOutcome/AscotExpectedOutcomeUpdate';

export const adminRoutes: RouteObject[] = [
  {
    path: '/teams',
    children: [{ index: true, element: <TeamList /> }],
  },
  {
    path: '/performance-metrics',
    children: [{ index: true, element: <Kpi /> }],
  },
  {
    path: '/admin-people/hubspot',
    children: [
      { index: true, element: <HubSpotContactList /> },
      { path: ':supportedPersonId', element: <ManageHubspotContact /> },
    ],
  },
  {
    path: '/admin-people',
    children: [
      { index: true, element: <ManageSupportedPeopleList /> },
      { path: ':supportedPersonId', element: <MangageSupportedPeopleDetail /> },
      { path: ':supportedPersonId/review/:reviewId/ascot/create', element: <AscotReviewCreate /> },
      { path: ':supportedPersonId/ascot/:ascotReviewId', element: <AscotReviewUpdate /> },
      { path: ':supportedPersonId/ascot/:ascotReviewId/expected-outcome/create', element: <AscotExpectedOutcomeCreate /> },
      { path: ':supportedPersonId/ascot/:ascotReviewId/expected-outcome/:ascotExpectedOutcomeId/update', element: <AscotExpectedOutcomeUpdate /> },
    ],
  },
  {
    path: '/admin-workers',
    children: [
      { index: true, element: <SupportWorkerList /> },
      { path: ':supportWorkerId', element: <ManageSupportWorkerDetail /> },
      { path: ':supportWorkerId/forms/medical-obvs/create', element: <MedicalObvsDetail /> },
      { path: ':supportWorkerId/forms/incident/create', element: <IncidentDetail /> },
    ],
  },

  {
    path: '/candidates',
    children: [
      { index: true, element: <CandidateList /> },
      { path: 'create', element: <CandidateCreate /> },
      { path: ':candidateId', element: <CandidateDetail /> },
      { path: ':candidateId/shadow-feedback', element: <CandidateShadowFeedbackStandalone /> },
    ],
  },

  { path: '/reports/confirmation-practices', element: <ConfirmationPracticesReport /> },
  { path: '/reports/annual-visit-cancellation', element: <AnnualVisitCancellationReport /> },
  { path: '/reports/rota/create', element: <GenerateRotaReport /> },
  { path: '/reports/rota', element: <MagicNumberReport /> },
  { path: '/reports/support-worker', element: <SupportWorkerReport /> },
  { path: '/reports/cancellations', element: <CancellationVisitsReport /> },
  {
    path: '/survey',
    children: [
      { index: true, element: <Survey /> },
      { path: ':surveyId', element: <Report /> },
    ],
  },
  { path: '/templates', element: <TemplateList /> },

  {
    path: '/workshops',
    children: [
      { index: true, element: <WorkshopList /> },
      { path: ':workshopId', element: <WorkshopDetail /> },
    ],
  },
  { path: '/event-log', element: <EventLog /> },
];
