import { useEffect, useState } from 'react';
import { SupportWorker as SupportWorkerType, AgreedShift } from '__generated__/graphql';
import { ImageSizeType } from 'types/types';
import { useGetSupportWorkers } from 'api/hooks/useSupportWorkers';
import { Loading } from 'components';
import Avatar from 'components/Avatar';

interface EditSupportWorkerProps {
  shiftData: Partial<AgreedShift>;
  toggleSupportWorkerModal: (state: boolean) => void;
  onSaveSupportWorker: (shiftData: Partial<AgreedShift>) => void;
  teamId: string;
  onRemoveShift: (id: string) => void;
}

export default function EditSupportWorker({ shiftData, toggleSupportWorkerModal, onSaveSupportWorker, teamId, onRemoveShift }: EditSupportWorkerProps) {
  const [newShiftData, setNewShiftData] = useState({
    id: shiftData.id,
    ownerId: shiftData.ownerId,
    agreedShiftRunId: shiftData.agreedShiftRunId,
    fullName: shiftData.fullName,
    day: shiftData.day,
  });

  useEffect(() => {
    setNewShiftData({
      id: shiftData.id,
      ownerId: shiftData.ownerId,
      agreedShiftRunId: shiftData.agreedShiftRunId,
      fullName: shiftData.fullName,
      day: shiftData.day,
    });
  }, [shiftData]);

  const { supportWorkers, loading } = useGetSupportWorkers({
    teamId,
  });

  const onCloseModal = () => {
    toggleSupportWorkerModal(false);
  };

  const onUpdateSupportWorker = () => {
    onSaveSupportWorker(newShiftData);
    toggleSupportWorkerModal(false);
  };

  const onUpdateSupportWorkerAsTBC = () => {
    if (shiftData.agreedShiftRunId) {
      onSaveSupportWorker({
        id: shiftData?.id,
        ownerId: '',
        agreedShiftRunId: shiftData?.agreedShiftRunId,
        fullName: '',
        day: shiftData?.day,
      });
    }
    toggleSupportWorkerModal(false);
  };

  const onRemoveSupportWorker = () => {
    if (shiftData?.id) onRemoveShift(shiftData?.id);
    toggleSupportWorkerModal(false);
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto mx-auto max-w-5xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6 max-h-screen md:max-h-[90vh] overflow-y-auto md:w-[780px]">
            <div className="text-gray-900 text-lg leading-lg font-semibold mb-5">Assign Support Worker</div>
            {loading && <Loading isComponent />}
            <div className="grid grid-cols-4 gap-2 overflow-y-auto">
              {supportWorkers.map((sw: SupportWorkerType) => (
                <div
                  className={`${newShiftData.ownerId === sw?.id ? 'text-white bg-primary-700' : 'text-gray-800'} rounded-lg cursor-pointer p-2.5`}
                  onClick={() =>
                    setNewShiftData({
                      id: shiftData.id,
                      ownerId: sw.id,
                      agreedShiftRunId: shiftData.agreedShiftRunId,
                      fullName: sw.fullName,
                      day: shiftData.day,
                    })
                  }
                  key={sw.id}
                  data-cy={`assign-support-worker-${sw.id}`}
                >
                  <Avatar avatar={sw.avatar} alt={sw.fullName} size={ImageSizeType.LG} />
                  <div className="text-md leading-md font-medium">{sw.fullName}</div>
                  <div className="text-sm leading-sm">{sw.contractedHours} hours per week</div>
                </div>
              ))}
            </div>
            <div className="flex justify-between items-center mt-7">
              <button type="button" className="text-gray-500 font-semibold text-md leading-md" onClick={onCloseModal}>
                Cancel
              </button>
              <div className="flex items-center gap-2">
                {shiftData.ownerId === newShiftData.ownerId && shiftData.id !== '' && (
                  <button
                    type="button"
                    className="text-white bg-error-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
                    onClick={onRemoveSupportWorker}
                    data-cy="remove-support-worker-button"
                  >
                    Unassign
                  </button>
                )}
                <button
                  type="button"
                  className="text-gray-700 border border-gray-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
                  onClick={onUpdateSupportWorkerAsTBC}
                  data-cy="assign-tbc-support-worker-button"
                >
                  Assign as TBC
                </button>
                <button
                  type="button"
                  className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md disabled:opacity-50"
                  onClick={onUpdateSupportWorker}
                  disabled={newShiftData.ownerId === ''}
                  data-cy="assign-support-worker-button"
                >
                  Assign
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-gray-900" />
    </>
  );
}
