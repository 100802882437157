import { ArrowDownTrayIcon, ArrowPathIcon, PlusIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { CandidatePreviousApplications as CandidatePreviousApplicationsType, CandidateTrackingItem } from '__generated__/graphql';
import { camelCaseToNormalString } from 'services/strings';
import { useGetSupportWorkers } from 'api/hooks/useSupportWorkers';
import { Cancel, CheckCircle } from '@mui/icons-material';

export default function CandidatePreviousApplications() {
  const { supportWorkers } = useGetSupportWorkers({ showAll: true });
  const { control } = useFormContext();

  const { fields } = useFieldArray({
    control,
    name: 'previousApplications',
  });

  if (fields && fields.length === 0) {
    return <div className="mt-8 sm:mt-12 text-lg leading-lg font-medium text-gray-800">No Previous Applications</div>;
  }
  return (
    <div className="mt-4 sm:mt-8">
      <div className="text-lg leading-lg font-medium text-white mb-8 bg-primary-700 px-3 py-5 w-fit rounded-lg text-center">
        <div className="text-display-md leading-display-md font-semibold">{fields.length}</div>
        <div>previous application{fields.length > 1 && 's'}</div>
      </div>
      {fields.map((previousApplication: CandidatePreviousApplicationsType, index) => {
        const ownerName = supportWorkers.find((sw) => sw.id === previousApplication.owner)?.fullName;
        const shadowWithName = supportWorkers.find((sw) => sw.id === previousApplication.workshop?.shadowWithId)?.fullName;
        const workshopPersonObservingName = supportWorkers.find((sw) => sw.id === previousApplication.workshopFeedback?.personObserving)?.fullName;
        const shadowPersonObservingName = supportWorkers.find((sw) => sw.id === previousApplication.shadowFeedback?.personObserving)?.fullName;

        return (
          <div className="mt-6">
            <div className="flex flex-row justify-between gap-2 items-end border-b border-primary-700 pb-2">
              <div className="text-display-xs sm:text-display-sm leading-display-xs  sm:leading-display-sm font-semibold text-primary-700">
                Application {index + 1}: {format(previousApplication?.dateImported ?? 0, 'dd/MM/yyyy')}
              </div>
            </div>
            <div className="flex flex-row justify-end mt-2">
              <div>
                <div className="text-gray-400 text-xs font-medium leading-xs mb-2 flex items-center">
                  <ArrowDownTrayIcon className="w-3.5 h-3.5 mr-2" />
                  Imported: {format(previousApplication?.dateImported ?? 0, 'dd/MM/yyyy')}
                </div>
                <div className="text-gray-400 text-xs font-medium leading-xs mb-2 flex items-center">
                  <PlusIcon className="w-3.5 h-3.5 mr-2" />
                  Created: {format(previousApplication?.dateCreated ?? 0, 'dd/MM/yyyy')}
                </div>
                <div className="text-gray-400 text-xs font-medium leading-xs mb-2 flex items-center">
                  <ArrowPathIcon className="w-3.5 h-3.5 mr-2" />
                  Updated: {format(previousApplication?.dateUpdated ?? 0, 'dd/MM/yyyy')}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-4">
              <div className="bg-primary-100 rounded-lg px-8 py-4">
                <div className="text-display-xs leading-display-xs text-gray-800 font-medium mb-4">Information</div>
                <div className="mt-2 text-lg leading-lg text-gray-800 flex flex-row gap-1 items-center">
                  Job Role: <div className="font-medium">{previousApplication.jobRole}</div>
                </div>
                <div className="mt-2 text-lg leading-lg text-gray-800 flex flex-row gap-1 items-center">
                  Campaign: <div className="font-medium">{previousApplication.campaign}</div>
                </div>
                <div className="mt-2 text-lg leading-lg text-gray-800 flex flex-row gap-1 items-center">
                  Source: <div className="font-medium">{previousApplication.source}</div>
                </div>
                <div className="mt-2 text-lg leading-lg text-gray-800 flex flex-row gap-1 items-center">
                  Owner: <div className="font-medium">{ownerName}</div>
                </div>
                <div className="mt-2 text-lg leading-lg text-gray-800 flex flex-row gap-1 items-center">
                  Can drive: <div className="font-medium">{previousApplication.canDrive ? 'Yes' : 'No'}</div>
                </div>
                <div className="mt-2 text-lg leading-lg text-gray-800 flex flex-row gap-1 items-center">
                  Reason not Hired:{' '}
                  <div className="font-medium">
                    {camelCaseToNormalString(previousApplication.reasonNotHired ?? '')}
                    {previousApplication.reasonNotHiredOther !== '' && `: ${previousApplication.reasonNotHiredOther}`}
                  </div>
                </div>
                <div className="mt-2 text-lg leading-lg text-gray-800 flex flex-row gap-1 items-center">
                  Eligible To Work: <div className="font-medium">{previousApplication.eligibleToWork ? 'Yes' : 'No'}</div>
                </div>
              </div>
              <div className="bg-primary-100 rounded-lg px-8 py-4">
                <div className="text-display-xs leading-display-xs text-gray-800 font-medium mb-4">Application Tracking</div>
                {previousApplication &&
                  previousApplication.tracking &&
                  Object.entries(previousApplication.tracking).map(([key, value]) => (
                    <div className="mt-2 text-lg leading-lg text-gray-800 flex flex-row gap-2 justify-between items-center capitalize">
                      {camelCaseToNormalString(key)}
                      {(value as CandidateTrackingItem)?.status ? (
                        <CheckCircle className="!w-7 !h-7 text-success-600" />
                      ) : (
                        <Cancel className="!w-7 !h-7 text-error-600" />
                      )}
                    </div>
                  ))}
              </div>
            </div>
            <div className="mt-2 bg-primary-100 rounded-lg px-8 py-4">
              <div className="text-display-xs leading-display-xs text-gray-800 font-medium mb-4">Scorecard</div>
              {previousApplication.scorecard?.map((s) => {
                if (s?.answer !== '') {
                  return (
                    <div className="mt-2 text-lg leading-lg text-gray-800">
                      <div>{s?.question}</div>
                      <div className="font-medium">{s?.answer}</div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
            {previousApplication.history && previousApplication.history.length > 0 && (
              <div className="mt-2 bg-primary-100 rounded-lg px-8 py-4">
                <div className="text-display-xs leading-display-xs text-gray-800 font-medium mb-4">History</div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-2">
                  {previousApplication.history?.map((h) => (
                    <div className="bg-white rounded-lg px-4 py-2">
                      <div className="flex flex-row items-center justify-between">
                        <div className="text-xl leading-xl text-gray-800 font-medium">{h?.contactType}</div>
                        <div className="text-gray-400 text-sm font-medium leading-sm">{format(h?.date ?? 0, 'dd/MM/yyyy')}</div>
                      </div>
                      {h?.from && h.from !== '' && (
                        <div className="mt-2 text-lg leading-lg text-gray-800 flex flex-row gap-1 items-center">
                          From: <div className="font-medium">{h.from}</div>
                        </div>
                      )}
                      <div className="mt-2 text-md leading-md text-gray-800 ">{h?.message}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="bg-primary-100 rounded-lg px-8 py-4 mt-2">
              <div className="text-display-xs leading-display-xs text-gray-800 font-medium mb-4">Workshop</div>
              <div className="mt-2 text-lg leading-lg text-gray-800 flex flex-row gap-1 items-center">
                Attended: <div className="font-medium">{previousApplication.workshop?.attended ? 'Yes' : 'No'}</div>
              </div>
              {previousApplication.workshop?.attended && (
                <>
                  <div className="mt-2 text-lg leading-lg text-gray-800 flex flex-row gap-1 items-center">
                    Note: <div className="font-medium">{previousApplication.workshop?.note}</div>
                  </div>
                  <div className="mt-2 text-lg leading-lg text-gray-800 flex flex-row gap-1 items-center">
                    Passed: <div className="font-medium">{previousApplication.workshop?.passed ? 'Yes' : 'No'}</div>
                  </div>
                  <div className="mt-2 text-lg leading-lg text-gray-800 flex flex-row gap-1 items-center">
                    Shadow with: <div className="font-medium">{shadowWithName}</div>
                  </div>
                </>
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-2">
              {previousApplication.workshopFeedback?.personObserving !== '' && (
                <div className="bg-primary-100 rounded-lg px-8 py-4">
                  <div className="text-display-xs leading-display-xs text-gray-800 font-medium mb-4">Workshop Feedback</div>
                  <div className="mt-2 text-lg leading-lg text-gray-800 flex flex-row gap-1 items-center">
                    Person Observing: <div className="font-medium">{workshopPersonObservingName}</div>
                  </div>
                  <div className="mt-2 text-lg leading-lg text-gray-800 flex flex-row gap-1 items-center">
                    Date: <div className="font-medium">{format(previousApplication.workshopFeedback?.observationDate ?? 0, 'dd/MM/yyyy')}</div>
                  </div>
                  <div className="mt-2 text-lg leading-lg text-gray-800 flex flex-row gap-1 items-center">
                    Communication: <div className="font-medium">{previousApplication.workshopFeedback?.communication}</div>
                  </div>
                  <div className="mt-2 text-lg leading-lg text-gray-800 flex flex-row gap-1 items-center">
                    Team Work: <div className="font-medium">{previousApplication.workshopFeedback?.teamWork}</div>
                  </div>
                  <div className="mt-2 text-lg leading-lg text-gray-800 flex flex-row gap-1 items-center">
                    Personability: <div className="font-medium">{previousApplication.workshopFeedback?.personability}</div>
                  </div>
                  <div className="mt-2 text-lg leading-lg text-gray-800 flex flex-row gap-1 items-center">
                    Patience: <div className="font-medium">{previousApplication.workshopFeedback?.patience}</div>
                  </div>
                  <div className="mt-2 text-lg leading-lg text-gray-800 flex flex-row gap-1 items-center">
                    Empathy: <div className="font-medium">{previousApplication.workshopFeedback?.empathy}</div>
                  </div>
                  <div className="mt-2 text-lg leading-lg text-gray-800 flex flex-row gap-1 items-center">
                    Overall Feedback: <div className="font-medium">{previousApplication.workshopFeedback?.overallFeedback}</div>
                  </div>
                  <div className="mt-2 text-lg leading-lg text-gray-800 flex flex-row gap-1 items-center">
                    Progress Shadow: <div className="font-medium">{previousApplication.workshopFeedback?.progressShadow}</div>
                  </div>
                </div>
              )}
              {previousApplication.shadowFeedback?.personObserving !== '' && (
                <div className="bg-primary-100 rounded-lg px-8 py-4">
                  <div className="text-display-xs leading-display-xs text-gray-800 font-medium mb-4">Shadow Feedback</div>
                  <div className="mt-2 text-lg leading-lg text-gray-800 flex flex-row gap-1 items-center">
                    Person Observing: <div className="font-medium">{shadowPersonObservingName}</div>
                  </div>
                  <div className="mt-2 text-lg leading-lg text-gray-800 flex flex-row gap-1 items-center">
                    Date: <div className="font-medium">{format(previousApplication.shadowFeedback?.observationDate ?? 0, 'dd/MM/yyyy')}</div>
                  </div>
                  <div className="mt-2 text-lg leading-lg text-gray-800 flex flex-row gap-1 items-center">
                    Communication: <div className="font-medium">{previousApplication.shadowFeedback?.communication}</div>
                  </div>
                  <div className="mt-2 text-lg leading-lg text-gray-800 flex flex-row gap-1 items-center">
                    Team Player: <div className="font-medium">{previousApplication.shadowFeedback?.teamPlayer}</div>
                  </div>
                  <div className="mt-2 text-lg leading-lg text-gray-800 flex flex-row gap-1 items-center">
                    Listening: <div className="font-medium">{previousApplication.shadowFeedback?.listening}</div>
                  </div>
                  <div className="mt-2 text-lg leading-lg text-gray-800 flex flex-row gap-1 items-center">
                    Engagement Willingness: <div className="font-medium">{previousApplication.shadowFeedback?.engagementWillingness}</div>
                  </div>
                  <div className="mt-2 text-lg leading-lg text-gray-800 flex flex-row gap-1 items-center">
                    Conscientiousness: <div className="font-medium">{previousApplication.shadowFeedback?.conscientiousness}</div>
                  </div>
                  <div className="mt-2 text-lg leading-lg text-gray-800 flex flex-row gap-1 items-center">
                    Values: <div className="font-medium">{previousApplication.shadowFeedback?.values}</div>
                  </div>
                  <div className="mt-2 text-lg leading-lg text-gray-800 flex flex-row gap-1 items-center">
                    Other: <div className="font-medium">{previousApplication.shadowFeedback?.other}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
