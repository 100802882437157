import { getHoursAndMinutes } from 'services/helpers';
import { Days } from 'types/types';
import { AgreedShift, AgreedShiftRun, AgreedShiftRunWeeks, Maybe, PlaceholderVisit } from '__generated__/graphql';
import { differenceInHours, differenceInMinutes, subMinutes } from 'date-fns';
import DayCell from './DayCell';
import AgreedVisitHeader from '../AgreedVisit/AgreedVisitHeader';

interface VisitRunProps {
  agreedShifts: AgreedShiftRunWeeks;
  row: AgreedShiftRun;
  week: number;
  placeholderVisits: PlaceholderVisit[];
}

const VisitRun = ({ agreedShifts, row, week, placeholderVisits }: VisitRunProps) => {
  const days: Record<Days, AgreedShift> = {
    0: { id: '', ownerId: '', agreedShiftRunId: row.id || '', agreedRotaId: row.agreedRotaId, teamId: row.teamId || '', fullName: '', visits: [], day: 0 },
    1: { id: '', ownerId: '', agreedShiftRunId: row.id || '', agreedRotaId: row.agreedRotaId, teamId: row.teamId || '', fullName: '', visits: [], day: 1 },
    2: { id: '', ownerId: '', agreedShiftRunId: row.id || '', agreedRotaId: row.agreedRotaId, teamId: row.teamId || '', fullName: '', visits: [], day: 2 },
    3: { id: '', ownerId: '', agreedShiftRunId: row.id || '', agreedRotaId: row.agreedRotaId, teamId: row.teamId || '', fullName: '', visits: [], day: 3 },
    4: { id: '', ownerId: '', agreedShiftRunId: row.id || '', agreedRotaId: row.agreedRotaId, teamId: row.teamId || '', fullName: '', visits: [], day: 4 },
    5: { id: '', ownerId: '', agreedShiftRunId: row.id || '', agreedRotaId: row.agreedRotaId, teamId: row.teamId || '', fullName: '', visits: [], day: 5 },
    6: { id: '', ownerId: '', agreedShiftRunId: row.id || '', agreedRotaId: row.agreedRotaId, teamId: row.teamId || '', fullName: '', visits: [], day: 6 },
  };

  row.shifts?.forEach((shift: Maybe<AgreedShift>) => {
    if (shift) {
      const day = shift.day as Days;
      days[day].id = shift.id;
      days[day].ownerId = shift.ownerId || '';
      days[day].fullName = shift.ownerId === '' ? 'TBC' : shift.fullName || '';
      days[day].visits = shift.visits || [];
    }
  });

  const numberOfHoursInShift = differenceInHours(new Date(row.endDateTime || 0), new Date(row.startDateTime || 0));
  const numberOfMinutesRemainingInShift = differenceInMinutes(new Date(row.endDateTime || 0), new Date(row.startDateTime || 0)) - numberOfHoursInShift * 60;
  const endDateSubMinutes = subMinutes(row.endDateTime || 0, numberOfMinutesRemainingInShift);

  return (
    <div className="flex w-full">
      <div className="flex flex-col xl:hidden shrink-0">
        <AgreedVisitHeader date={row.startDateTime || 0} week={week} startDateTime={row.startDateTime || 0} endDateTime={row.endDateTime || 0} />
      </div>
      <div className="flex flex-col xl:flex-row w-full">
        <div className="h-[70px] xl:h-auto xl:w-[9%] px-2">
          <div className="flex items-center justify-center h-[70px] xl:h-[38px] text-lg text-gray-800 font-semibold xl:font-medium">{row.period}</div>

          <div className="hidden xl:block">
            {[...Array(numberOfHoursInShift)].map((_, index: number) => {
              const start = new Date(row.startDateTime || 0);
              const end = new Date(row.startDateTime || 0);
              start.setHours(start.getHours() + index);
              end.setHours(end.getHours() + index + 1);

              return (
                <div className="flex items-center justify-end h-[60px]" key={index}>
                  {getHoursAndMinutes(start.getTime())} - {getHoursAndMinutes(end.getTime())}
                </div>
              );
            })}
            {numberOfMinutesRemainingInShift > 0 && (
              <div className="flex items-center justify-end" style={{ height: numberOfMinutesRemainingInShift }}>
                {getHoursAndMinutes(new Date(endDateSubMinutes).getTime())} - {getHoursAndMinutes(new Date(row.endDateTime || 0).getTime())}
              </div>
            )}
          </div>
        </div>
        {Object.values(days).map((shift, index) => (
          <DayCell
            agreedShifts={agreedShifts}
            placeholderVisits={placeholderVisits}
            shift={shift}
            startDateTime={row.startDateTime || 0}
            endDateTime={row.endDateTime || 0}
            week={week}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

export default VisitRun;
