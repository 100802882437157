import { CSVLink } from 'react-csv';
import { ArrowUpTrayIcon, PrinterIcon } from '@heroicons/react/24/outline';
import { SelectType } from 'components/Filters';
import ToggleButtons from '../ToggleButtons';
import Search from '../Search/index';
import { FilterLabelButtons } from './FilterLabelButtons';

interface TableFilterPanelProps {
  search?: string;
  setSearch?: (search: string) => void;
  filters: SelectType[];
  filterWithoutToggle: SelectType[];
  setShowFiltersModal: (status: boolean) => void;
  onFilterChange: (name: string, value: { value: string; label: string }) => void;
  handlePrint?: () => void;
  csv?: { data: string | object[] | (() => string | object[]); fileName: string };
  toggleButtonKey?: string;
}

const TableFilterPanel = ({
  search,
  setSearch,
  filters,
  setShowFiltersModal,
  onFilterChange,
  handlePrint,
  csv,
  toggleButtonKey,
  filterWithoutToggle,
}: TableFilterPanelProps) => {
  const toggleFilter = filters.find((f) => f.name === toggleButtonKey);
  const toggleOptions = toggleFilter?.values.map((v) => v.label);
  const setToogle = (button: string) => {
    const option = toggleFilter?.values.find((v) => v.label === button);
    if (toggleButtonKey && option) onFilterChange(toggleButtonKey, option);
  };

  return (
    <>
      <div className="flex flex-col lg:flex-row items-center justify-between mt-8 md:mt-16">
        <div className="flex flex-col md:flex-row items-center gap-4 md:gap-9 w-full md:w-fit">
          {setSearch && <Search value={search || ''} onSearch={setSearch} />}
          <FilterLabelButtons filters={filterWithoutToggle} setShowFiltersModal={setShowFiltersModal} onFilterChange={onFilterChange} />
        </div>
        <div className="flex items-center justify-between lg:justify-normal gap-3 md:gap-9 w-full lg:w-fit mt-6 lg:mt-0">
          {handlePrint && (
            <button type="button" className="text-gray-500 text-sm leading-sm font-semibold flex items-center hover:no-underline" onClick={handlePrint}>
              <PrinterIcon className="w-5 h-5 mr-2" />
              Print
            </button>
          )}
          {csv && (
            <CSVLink
              filename={`${csv.fileName || 'file'}.csv`}
              data={csv.data}
              className="text-gray-500 text-sm leading-sm font-semibold flex items-center hover:no-underline"
            >
              <ArrowUpTrayIcon className="w-5 h-5 mr-2" />
              Export CSV
            </CSVLink>
          )}
        </div>
      </div>

      <div className="mt-6">
        {toggleOptions && toggleOptions?.length > 0 && (
          <ToggleButtons testId="table-filter" buttons={toggleOptions} selectedButton={toggleFilter?.selectedValue.label || ''} onSelectButton={setToogle} />
        )}
      </div>
    </>
  );
};

export { TableFilterPanel };
