import { useEffect, useState } from 'react';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { Modal } from './Modal';

interface FormPromptModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

function FormPromptModal({ open, onClose, onConfirm }: FormPromptModalProps) {
  return (
    <Modal onConfirm={onConfirm} open={open} onClose={onClose} isDisabled={false} confirmLabel="Yes">
      <div className="flex flex-col items-center">
        <ExclamationCircleIcon className="w-10 h-10" />
        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">You have unsaved changes, are you sure you want to leave?</h3>
      </div>
    </Modal>
  );
}

export const FormPrompt = ({ hasUnsavedChanges }: { hasUnsavedChanges: boolean }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmed, setConfirm] = useState(false);

  const onClose = () => {
    setModalOpen(false);
  };
  const blocker = useBlocker(hasUnsavedChanges);

  const onConfirm = () => {
    setConfirm(true);

    if (blocker.state === 'blocked') {
      blocker?.proceed();
      setModalOpen(false);
      blocker?.reset();
    }
  };

  useEffect(() => {
    if (hasUnsavedChanges && blocker.state === 'blocked') {
      setModalOpen(true);
    } else if (!hasUnsavedChanges && blocker.state === 'blocked') {
      blocker?.proceed();

      blocker?.reset();
    }
  }, [blocker, hasUnsavedChanges, confirmed]);

  return <FormPromptModal onConfirm={onConfirm} open={modalOpen} onClose={onClose} />;
};

export { FormPromptModal };
