/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import { Loading, LocationBar, Message } from 'components';
import { useParams } from 'react-router-dom';
import { useGetCandidate, useSaveCandidate } from 'api/hooks/useCandidates';
import { Helmet } from 'react-helmet';
import { Feedback } from '@mui/icons-material';
import { SaveCandidateInput } from '__generated__/graphql';
import { FormProvider, useForm } from 'react-hook-form';
import { SupportWorkerSelectField } from 'components/Fields/SupportWorkerSelectField';
import { InputField } from 'components/Fields/InputField';
import { TextAreaField } from 'components/Fields/TextAreaField';
import { DateField } from 'components/Fields/DateField';
import { FormPrompt } from 'components/modals/FormPromptModal';
import { MapCandidate } from './utils/FileMapper';

export default function CandidateShadowFeedbackStandalone() {
  const { candidateId } = useParams<{ candidateId?: string }>();
  const { candidate, candidateLoading } = useGetCandidate({ id: candidateId });
  const { saveCandidate, mutationSaveCandidate } = useSaveCandidate({ id: candidateId });

  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const methods = useForm<SaveCandidateInput>({
    mode: 'onChange',
  });
  const { handleSubmit, formState, reset } = methods;

  useEffect(() => {
    reset({
      ...MapCandidate(candidate),
    });
  }, [candidate, reset]);

  const onSubmit = (data: SaveCandidateInput) => {
    setOpenSnack(true);

    saveCandidate({
      variables: {
        input: {
          ...data,
        },
      },
    });
    setTimeout(() => {
      setOpenSnack(false);
    }, 2000);
  };

  if (candidateLoading || mutationSaveCandidate.loading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Candidates</title>
      </Helmet>
      <LocationBar section="Candidates" page="Shadow feedback" Icon={Feedback} />
      <Message response={[mutationSaveCandidate]} openSnack={openSnack} setOpenSnack={setOpenSnack} />
      <div className="my-10 px-4 md:px-[5%]">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="text-black text-xl sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-8">
              Shadow feedback for {candidate?.firstName} {candidate?.lastName}
            </div>
            <SupportWorkerSelectField isRequired={false} label="Person carrying out observation" name="shadowFeedback.personObserving" />

            <DateField label="Date of observation" name="shadowFeedback.observationDate" />
            <InputField
              placeholder="Communication"
              label="Communication skills – did they communicate with people we support successfully, and appropriately? Did they generate rapport?"
              name="shadowFeedback.communication"
            />
            <InputField
              placeholder="Listening skills"
              label="Listening skills – was there a balance of speaking and listening? Did they listen actively?"
              name="shadowFeedback.listening"
            />
            <InputField
              placeholder="Engagement and willingness"
              label="Engagement and willingness – did they show a willingness to 'get stuck in' with tasks? Was there any squeamishness about tasks? Can you see this person bringing their whole self to work?"
              name="shadowFeedback.engagementWillingness"
            />
            <InputField
              placeholder="Conscientiousness"
              label="Conscientiousness – did they remain attentive and engaged throughout the shift?*"
              name="shadowFeedback.conscientiousness"
            />
            <InputField
              placeholder="Team Work"
              label="Team – are they a team player? Can you imagine them fitting in well with existing team members?"
              name="shadowFeedback.teamPlayer"
            />
            <InputField
              placeholder="Values"
              label="Values – do their values chime with ours? (We build trusted relationships, have compassion, ambition, creativity; we strive to be the best, share accountability and make life worth living)"
              name="shadowFeedback.values"
            />
            <TextAreaField
              name="shadowFeedback.other"
              placeholder="Any other observations?"
              label="Any other observations? – was there anything that troubled you? Even slightly?Was there anything that particularly impressed you?"
              isRequired={false}
            />
            <div className="flex justify-end">
              <button type="submit" className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
                Save
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
      <FormPrompt hasUnsavedChanges={formState.isDirty} />
    </>
  );
}
