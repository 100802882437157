import { useState } from 'react';
import TWModal from 'components/TWModal';
import { FormProvider, useForm } from 'react-hook-form';
import { Message } from 'components';
import { useUpdateFamilyMember } from 'api/hooks/useFamily';
import { camelCaseToNormalString } from 'services/strings';
import { RadioField } from '../../WellbeingBoard/AscotReview/RadioField';
import { Family, InvoiceType, Maybe } from '../../../__generated__/graphql';

interface EditFamilyMemberProps {
  familyMember: Maybe<Family> | undefined;
  customerId: string;
  teamId: string;
  onClose: () => void;
}

const EditFamilyMember = ({ familyMember, customerId, teamId, onClose }: EditFamilyMemberProps) => {
  const methods = useForm<{
    id: string;
    invoice: string;
    isActive: boolean;
  }>({
    mode: 'onSubmit',
    defaultValues: {
      id: familyMember?.id || '',
      invoice: familyMember?.invoice || InvoiceType.None,
    },
  });
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const { handleSubmit } = methods;
  const { updateFamilyMember, mutationUpdateFamilyMember } = useUpdateFamilyMember({ id: customerId, teamId });
  const InvoiceTypes = Object.values(InvoiceType).map((c) => ({ value: camelCaseToNormalString(c), label: camelCaseToNormalString(c) }));
  const invoiceTypes = Object.values(InvoiceTypes);

  const onSubmit = async (member: Family) => {
    if (member) {
      await updateFamilyMember({
        variables: {
          input: {
            id: member?.id,
            invoice: member.invoice,
          },
        },
      });
    }
    setOpenSnack(true);
    onClose();
  };

  return (
    <>
      <Message response={[mutationUpdateFamilyMember]} openSnack={openSnack} setOpenSnack={setOpenSnack} />
      <FormProvider {...methods}>
        <form>
          <TWModal title="Edit Family Member" onClose={onClose} onSave={handleSubmit(onSubmit)} wFull testId="save-family-member-button">
            <RadioField options={invoiceTypes} data-cy="family-member-invoiceType" label="Invoice Type" name="invoice" />
          </TWModal>
        </form>
      </FormProvider>
    </>
  );
};

export default EditFamilyMember;
