import { useState, useEffect } from 'react';
import getTime from 'date-fns/getTime';
import setMilliseconds from 'date-fns/setMilliseconds';
import setSeconds from 'date-fns/setSeconds';
import { Customer, AgreedVisitType } from '__generated__/graphql';
import { getDayName, getHoursAndMinutes } from 'services/helpers';
import { WarningAmber } from '@mui/icons-material';
import { format } from 'date-fns';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import ReactSelect from 'components/Select';
import { SelectOption } from 'types/types';
import TimePicker from 'components/TimePicker';
import Checkbox from 'components/Checkbox';
import DatePicker from 'components/DatePicker';
import { AddAgreedVisitModalType, AddMultipleAgreedVisitModalType } from '../types';
import { createDateWithTime } from '../utils';

interface AddAgreedVisitProps {
  onClose: () => void;
  onAddAgreedVisit: (addVisit: AddAgreedVisitModalType) => void;
  onAddMultipleAgreedVisit: (addVisit: AddMultipleAgreedVisitModalType) => void;
  customers: Customer[];
  agreedShiftRunStartTime: number;
  agreedShiftRunEndTime: number;
  isAbleToAdd: boolean;
  isFutureShiftNotAbleToAdd: boolean;
  conflictedVisits: AgreedVisitType[];
  setIsAbleToAdd: (state: boolean) => void;
  isDnDEditing: boolean;
  shiftDate: Date;
}

export default function AddAgreedVisit({
  onClose,
  onAddAgreedVisit,
  onAddMultipleAgreedVisit,
  customers,
  agreedShiftRunStartTime,
  agreedShiftRunEndTime,
  isAbleToAdd,
  isFutureShiftNotAbleToAdd,
  conflictedVisits,
  setIsAbleToAdd,
  isDnDEditing,
  shiftDate,
}: AddAgreedVisitProps) {
  const [customer, setCustomer] = useState<SelectOption>({ value: '', label: '' });
  const [startDateTime, setStartDateTime] = useState<Date>(new Date());
  const [endDateTime, setEndDateTime] = useState<Date>(new Date());
  const [validation, setValidation] = useState<string | null>(null);
  const [isMultipleRecurringSelected, setIsMultipleRecurringSelected] = useState(false);
  const [isEveryDaySelected, setIsEveryDaySelected] = useState(false);
  const [isAddUntilSelected, setIsAddUntilSelected] = useState(false);
  const [selectedRecurringDays, setSelectedRecurringDays] = useState<number[]>([]);
  const [recurringDaysChecked, setRecurringDaysChecked] = useState([false, false, false, false, false, false, false]);
  const [addUntilDate, setAddUntilDate] = useState<Date | null>(() => {
    const newDate = shiftDate;
    newDate.setDate(newDate.getDate() + 1);
    return new Date(newDate);
  });
  const customersOptions = customers.map((c) => ({ value: c.id, label: `${c.firstName} ${c.lastName}` }));

  useEffect(() => {
    setStartDateTime(setMilliseconds(setSeconds(agreedShiftRunStartTime, 0), 0));
    setEndDateTime(setMilliseconds(setSeconds(agreedShiftRunEndTime, 0), 0));
    setValidation(null);
    setIsAbleToAdd(true);
  }, [agreedShiftRunEndTime, agreedShiftRunStartTime, setIsAbleToAdd]);

  useEffect(() => {
    if (!isAbleToAdd) {
      setValidation(
        isFutureShiftNotAbleToAdd
          ? 'The visit start time and/or end time is not inside a future shift run start and end time. Becareful visits will be added for all the next shift run period in week 1 AND week 2.'
          : 'There is already a visit at this time. Visits cannot overlap.',
      );
    }
  }, [isAbleToAdd, isFutureShiftNotAbleToAdd]);

  useEffect(() => {
    if (validation !== null) {
      const element = document.getElementById('add-visit-modal-content');
      if (element) {
        element.scrollTo(0, element.scrollHeight);
      }
    }
  }, [validation]);

  const onChangeCustomer = (value: SelectOption) => {
    setCustomer(value);
    setValidation(null);
    setIsAbleToAdd(true);
  };

  const onChangeStartDateTime = (dateTime: Date | null) => {
    if (dateTime) {
      setStartDateTime(dateTime);
    }
    setValidation(null);
    setIsAbleToAdd(true);
  };

  const onChangeEndDateTime = (dateTime: Date | null) => {
    if (dateTime) {
      setEndDateTime(dateTime);
    }
    setValidation(null);
    setIsAbleToAdd(true);
  };

  const onAddVisit = () => {
    const agreedShiftRunStartDateTime = createDateWithTime(Date.now(), agreedShiftRunStartTime);
    const agreedShiftRunEndDateTime = createDateWithTime(Date.now(), agreedShiftRunEndTime);
    const visitStartDateTime = createDateWithTime(Date.now(), startDateTime.getTime());
    const visitEndDateTime = createDateWithTime(Date.now(), endDateTime.getTime());

    setValidation(null);
    setIsAbleToAdd(true);

    if (customer.value === '') {
      setValidation('Please select a person');
      return;
    }

    if (getTime(visitStartDateTime) >= getTime(visitEndDateTime)) {
      setValidation('Please provide a valid date time; end time should be greater than start time');
      return;
    }
    if (getTime(visitStartDateTime) < getTime(agreedShiftRunStartDateTime) || getTime(visitEndDateTime) > getTime(agreedShiftRunEndDateTime)) {
      setValidation(
        `The start time and end time must be between ${getHoursAndMinutes(agreedShiftRunStartDateTime)} and ${getHoursAndMinutes(agreedShiftRunEndDateTime)}`,
      );
      return;
    }
    if (isMultipleRecurringSelected && !isEveryDaySelected && recurringDaysChecked.length === 0) {
      setValidation('Please select at least one day');
      return;
    }

    if (isMultipleRecurringSelected) {
      onAddMultipleAgreedVisit({
        customerId: customer.value,
        startDateTime: getTime(startDateTime),
        endDateTime: getTime(endDateTime),
        days: selectedRecurringDays,
        untilDate: isAddUntilSelected && addUntilDate ? getTime(addUntilDate) : null,
      });
    } else {
      onAddAgreedVisit({
        customerId: customer.value,
        startDateTime: getTime(startDateTime),
        endDateTime: getTime(endDateTime),
      });
    }
  };

  const handleIsMultipleRecurringSelectedChange = (value: boolean) => {
    setValidation(null);
    setIsAbleToAdd(true);
    setIsMultipleRecurringSelected(value);
    setIsEveryDaySelected(false);
    setRecurringDaysChecked([false, false, false, false, false, false, false]);
    setSelectedRecurringDays([]);
  };

  const handleIsEveryDaySelected = (value: boolean) => {
    setValidation(null);
    setIsAbleToAdd(true);
    setIsEveryDaySelected(value);
    setIsMultipleRecurringSelected(false);
    if (value) {
      setRecurringDaysChecked([false, false, false, false, false, false, false]);
      setSelectedRecurringDays([]);
    }
  };

  const handleSpecificDaySelected = (value: boolean, index: number) => {
    setValidation(null);
    setIsAbleToAdd(true);
    const newRecurringDaysChecked = [...recurringDaysChecked];

    newRecurringDaysChecked[index] = value;
    if (value) {
      setSelectedRecurringDays([...selectedRecurringDays, index]);
    } else {
      const newSelectedRecurringDays = [...selectedRecurringDays];
      const i = newSelectedRecurringDays.indexOf(index);
      newSelectedRecurringDays.splice(i, 1);
      setSelectedRecurringDays(newSelectedRecurringDays);
    }
    if (newRecurringDaysChecked.includes(true) && newRecurringDaysChecked.includes(false)) {
      setIsEveryDaySelected(false);
    } else {
      setIsEveryDaySelected(true);
    }
    setRecurringDaysChecked(newRecurringDaysChecked);
  };

  const handleIsAddUntilSelected = (value: boolean) => {
    setValidation(null);
    setIsAbleToAdd(true);
    setIsAddUntilSelected(value);
  };

  const getMultipleVisitText = () => {
    if (isAddUntilSelected) {
      if (isEveryDaySelected) {
        return `This visit will be added at this same time and day every week from ${format(shiftDate, 'PP')} until ${format(addUntilDate || 0, 'PP')}`;
      }
      return `This visit will be added on the selected days from ${format(shiftDate, 'PP')} until ${format(addUntilDate || 0, 'PP')}`;
    }
    if (isEveryDaySelected) {
      return `This visit will be added at this same time and day every week from ${format(shiftDate, 'PP')} for all of the rota templates`;
    }
    return `This visit will be added on the selected days from ${format(shiftDate, 'PP')} for all of the rota templates`;
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto mx-auto max-w-5xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6 max-h-screen md:max-h-[90vh] overflow-y-auto md:w-[680px]">
            <div className="text-gray-900 text-lg leading-lg font-semibold mb-5">Add visit to shift</div>
            {isDnDEditing && (
              <div className="flex items-center gap-3 text-md display-md text-warning-700 font-medium">
                <WarningAmber />
                Becareful your drag and drop changes will be lost. Please save your changes before adding this visit.
              </div>
            )}
            <div className="mb-5">
              <div className="text-md font-medium text-gray-700 w-auto">Select a supported person</div>
              <div className="mt-2">
                <ReactSelect searchable testId="select-supported-person" options={customersOptions} onChange={onChangeCustomer} selectedValue={customer} />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-md font-medium text-gray-700 w-auto">Start time</div>
              <div className="mt-2">
                <TimePicker date={startDateTime} onChange={onChangeStartDateTime} />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-md font-medium text-gray-700 w-auto">End time</div>
              <div className="mt-2">
                <TimePicker date={endDateTime} onChange={onChangeEndDateTime} />
              </div>
            </div>
            <Checkbox label="At this same time and day every week" checked={isEveryDaySelected} onChange={handleIsEveryDaySelected} />
            <Checkbox
              label="Set multiple recurring visit"
              checked={isMultipleRecurringSelected}
              onChange={handleIsMultipleRecurringSelectedChange}
              data-cy="add-agreed-visit-multiple"
            />
            {isMultipleRecurringSelected && (
              <>
                <div className="ml-5">
                  {[...Array(7)].map((_, index: number) => {
                    return (
                      <Checkbox
                        key={index}
                        label={getDayName(index)}
                        checked={recurringDaysChecked[index]}
                        onChange={(value: boolean) => handleSpecificDaySelected(value, index)}
                        data-cy={`add-agreed-visit-multiple-day${index}`}
                      />
                    );
                  })}
                </div>
                <div className="flex items-center gap-4">
                  <Checkbox label="Add until:" checked={isAddUntilSelected} onChange={handleIsAddUntilSelected} />
                  <div className="mb-5">
                    <DatePicker
                      value={addUntilDate}
                      onChange={(newValue) => {
                        setValidation(null);
                        setIsAbleToAdd(true);
                        setAddUntilDate(newValue);
                      }}
                      minDate={shiftDate}
                    />
                  </div>
                </div>
                <div className="text-md leading-md text-gray-800">{getMultipleVisitText()}</div>
              </>
            )}
            {validation && (
              <>
                <div className="text-error-700 text-lg leading-lg flex items-center gap-2" data-cy="add-agreed-visit-validation-error">
                  <ExclamationTriangleIcon className="w-5 h-5" />
                  {validation}
                </div>
                {conflictedVisits.length > 0 && <div className="text-gray-800 text-lg leading-lg font-semibold">Conflicted visits :</div>}
                {conflictedVisits.map((visit, index) => (
                  <div className="text-gray-800 text-md leading-md ml-2" key={index}>
                    {visit?.customer?.firstName} {visit?.customer?.lastName}: {format(visit.startDateTime ?? 0, 'EEEE dd MMMM kk:mm')} -{' '}
                    {format(visit.endDateTime ?? 0, 'kk:mm')}
                  </div>
                ))}
              </>
            )}
            <div className="flex justify-between items-center mt-7">
              <button
                type="button"
                className="text-gray-500 font-semibold text-md leading-md"
                onClick={() => {
                  setIsAbleToAdd(true);
                  onClose();
                }}
              >
                Cancel
              </button>
              <div className="flex items-center gap-3">
                <button
                  type="button"
                  disabled={validation !== null}
                  className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
                  onClick={onAddVisit}
                  data-cy="add-agreed-visit-button"
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-gray-900" />
    </>
  );
}
