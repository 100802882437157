import { useState } from 'react';
import getTime from 'date-fns/getTime';
import Alert from '@mui/material/Alert';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Rota, Shift as ShiftType } from '__generated__/graphql';
import { getEndDateTime, getStartDateTime } from 'services/helpers';
import { PlusCircleIcon } from '@heroicons/react/24/solid';
import { Input } from 'components';
import ReactSelect from 'components/Select';
import { SelectOption, activityTypes } from 'types/types';
import TimePicker from 'components/TimePicker';
import DateTimePicker from 'components/DateTimePicker';

interface ActivityItemNoProps {
  saveActivityNoShift: (activity: Partial<ShiftType>) => void;
  rota: Rota;
}

export default function ActivityItemNoShift({ saveActivityNoShift, rota }: ActivityItemNoProps) {
  const [startDateTime, setStartDateTime] = useState<Date>(new Date(getTime(getStartDateTime(rota.startRotaDateTime || 0))));
  const [endDateTime, setEndDateTime] = useState<Date>(new Date(getTime(getStartDateTime(rota.startRotaDateTime || 0))));
  const [activityType, setActivityType] = useState<SelectOption>({ value: 'InitialConversation', label: 'Initial Conversation' });
  const [notes, setNotes] = useState('');
  const [validation, setValidation] = useState<string>('');

  const onChangeStartDateTime = (dateTime: Date | null) => {
    if (dateTime) {
      setStartDateTime(dateTime);
      setEndDateTime(dateTime);
      setValidation('');
    }
  };

  const onChangeEndDateTime = (dateTime: Date | null) => {
    if (dateTime) {
      setEndDateTime(dateTime);
      setValidation('');
    }
  };

  const onChangeActivity = (a: SelectOption) => {
    setActivityType(a);
    setValidation('');
  };

  const onChangeNotes = (note: string) => {
    setNotes(note);
    setValidation('');
  };

  const onSaveActivity = () => {
    if (getTime(startDateTime) >= getTime(endDateTime) || getTime(startDateTime) === getTime(endDateTime)) {
      setValidation('Please provide a valid shift date time; end time should be greater than start time');
      return;
    }

    saveActivityNoShift({
      startDateTime: getTime(startDateTime),
      endDateTime: getTime(endDateTime),
      shiftId: 'new',
      activityType: activityType.value,
      notes,
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="mt-10">
        <div className="text-display-xs leading-display-xs text-gray-900">Add banked hours activity outside of shift</div>
        <div className="mt-5">
          {validation && (
            <Alert variant="filled" severity="error" sx={{ width: '84%', marginLeft: '1em', marginBottom: '1em' }}>
              {validation}
            </Alert>
          )}
          <div className="flex flex-col lg:flex-row gap-4 justify-between items-center border border-gray-50 shadow-lg rounded-lg p-4 sm:px-5 sm:py-2.5">
            <div className="flex flex-col gap-2 w-full">
              <div className="flex flex-col sm:flex-row sm:items-center gap-2">
                <div className="mb-2">
                  <div className="text-sm font-medium text-gray-700 capitalize w-auto">Start time</div>
                  <div className="mt-2">
                    <DateTimePicker
                      minDate={getStartDateTime(rota?.startRotaDateTime || 0)}
                      maxDate={getEndDateTime(rota?.endRotaDateTime || 0)}
                      date={startDateTime}
                      onChange={onChangeStartDateTime}
                    />
                  </div>
                </div>
                <div className="mb-2">
                  <div className="text-sm font-medium text-gray-700 capitalize w-auto">End time</div>
                  <div className="mt-2 sm:w-fit">
                    <TimePicker date={endDateTime} onChange={onChangeEndDateTime} />
                  </div>
                </div>
              </div>
              <div className="flex flex-col sm:flex-row sm:items-center gap-2">
                <div className="mb-2">
                  <div className="text-sm font-medium text-gray-700 capitalize w-auto">Activity</div>
                  <div className="mt-2">
                    <ReactSelect options={activityTypes} onChange={onChangeActivity} selectedValue={activityType} />
                  </div>
                </div>
                <div className="mb-2">
                  <div className="text-sm font-medium text-gray-700 capitalize w-auto">Notes</div>
                  <div className="mt-2">
                    <Input value={notes} onChange={onChangeNotes} />
                  </div>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="text-white disabled:opacity-50 bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-end justify-center w-full sm:w-auto sm:self-end mb-2"
              onClick={onSaveActivity}
            >
              <PlusCircleIcon className="w-5 h-5 mr-2" />
              Add
            </button>
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
}
