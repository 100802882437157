// eslint-disable-next-line import/no-extraneous-dependencies
import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker';

interface TWDatePickerProps {
  value: DateValueType;
  onChange: (value: DateValueType) => void;
  disabled?: boolean;
  error?: string;
}

const DateRangePicker = ({ value, onChange, disabled = false, error }: TWDatePickerProps) => {
  return (
    <>
      <Datepicker
        data-cy="date-picker"
        value={value}
        placeholder="Select a Period"
        onChange={onChange}
        displayFormat="DD/MM/YYYY"
        inputClassName={`date-picker bg-white pl-12 pr-5 py-2.5 rounded-lg text-md leading-md  placeholder:text-gray-500 border border-gray-300 shadow-xs w-full focus:border-gray-300  ${
          disabled ? 'text-gray-300' : 'text-gray-700 cursor-pointer'
        }`}
        toggleClassName="	 bg-blue-300 rounded-r-lg text-white right-0  px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed"
        containerClassName="flex mt-8"
      />
      {error && (
        <p className="mt-2 text-sm text-error-600 dark:text-green-500">
          <span className="font-medium">{error}</span>
        </p>
      )}
    </>
  );
};

export default DateRangePicker;
