import { EmojiPeople } from '@mui/icons-material';
import { useState } from 'react';
import { Message, LocationBar } from 'components';
import Helmet from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { InputField } from 'components/Fields/InputField';
import { isValidDOB, isValidEmail } from 'components/Fields/validators';
import { DateField } from 'components/Fields/DateField';
import { AddressField } from 'components/Fields/AddressField';
import { useCreateCandidate } from 'api/hooks/useCandidates';
import { FormPrompt } from 'components/modals/FormPromptModal';
import { subYears } from 'date-fns';

const CandidateCreate = () => {
  const { createCandidate, mutationCreateCandidate } = useCreateCandidate();
  const [openSnack, setOpenSnack] = useState<boolean>(false);

  const methods = useForm<{
    firstName: string;
    lastName: string;
    email: string;
    jobRole: string;
    dob: number;
    postCode: string;
    address: string;
    state: string;
    country: string;
    phone: string;
  }>({
    mode: 'onChange',
    defaultValues: {
      dob: subYears(new Date(), 16).getTime(),
    },
  });

  const { handleSubmit, formState, reset } = methods;

  const navigate = useNavigate();

  const onSubmit = async (data: {
    firstName: string;
    lastName: string;
    email: string;
    jobRole: string;
    dob: number;
    postCode: string;
    address: string;
    state: string;
    country: string;
    phone: string;
  }) => {
    const candidate = await createCandidate({
      awaitRefetchQueries: true,

      variables: {
        input: {
          ...data,
        },
      },
    });
    setOpenSnack(true);

    if (candidate?.data?.CreateCandidate?.message) {
      navigate('/candidates');
      reset(undefined, { keepValues: true, keepDirty: false, keepDefaultValues: false });
    }
  };

  return (
    <>
      <Helmet>
        <title>Candidates</title>
      </Helmet>
      <LocationBar section="Candidates" page="Create Candidate" Icon={EmojiPeople} />
      <Message response={[mutationCreateCandidate]} openSnack={openSnack} setOpenSnack={setOpenSnack} />
      <div className="my-10 px-4 md:px-[5%]">
        <button type="button" className="text-gray-500 font-semibold text-md leading-md flex items-center" onClick={() => navigate('/candidates/')}>
          <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
        </button>

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-10 sm:mt-16">
              <div className="mt-8 sm:mt-12">
                <InputField label="First Name" name="firstName" testId="candidate-firstName" placeholder="First name" isRequired={true} />
                <InputField label="Last Name" name="lastName" testId="candidate-lastName" placeholder="Last name" isRequired={true} />
                <DateField name="dob" testId="candidate-dob" label="Date of birth" validate={isValidDOB} isRequired={true} />
                <InputField label="Email" testId="candidate-email" name="email" placeholder="Email" validate={isValidEmail} isRequired={true} />
                <InputField label="Job role" testId="candidate-jobRole" name="jobRole" placeholder="Job role" isRequired={true} />
                <InputField label="Phone" testId="candidate-phone" name="phone" placeholder="phone" isRequired={true} />
                <AddressField testId="candidate-address" />
              </div>
              <button
                type="submit"
                data-cy="save-candidate-button"
                className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
              >
                Save
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
      <FormPrompt hasUnsavedChanges={formState.isDirty} />
    </>
  );
};

export default CandidateCreate;
