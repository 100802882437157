import { DimensionGroupType } from '../types';
import { SpiderPlot, StripPlot } from './Plot';
import DimensionDescription from './DimensionDescription';

interface DimensionGroupProps {
  dimensionGroup: DimensionGroupType[];
  hideCompanyAverage?: boolean;
}

const DimensionGroup = ({ dimensionGroup, hideCompanyAverage = false }: DimensionGroupProps) => {
  return (
    <div className="mt-8 sm:mt-12">
      {dimensionGroup.map((group) => {
        const filteredMeanScores = group.mean_scores_per_cohort.filter((g) => (hideCompanyAverage ? g.cohort_name !== 'BelleVie Team' : true));
        const filteredScores = group.scores_per_cohort.filter((g) => (hideCompanyAverage ? g.cohort_name !== 'BelleVie Team' : true));

        return (
          <div className="print:break-before-page">
            <div className="text-black text-xl sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1">{group.group_name}</div>

            {group?.scores_per_cohort?.length > 0 && (
              <>
                <div className="flex flex-col xl:flex-row print:flex-col">
                  <SpiderPlot meanScoresPerCohort={filteredMeanScores} />
                  <StripPlot scoresPerCohort={filteredScores} />
                </div>
                <DimensionDescription dimensions={group?.scores_per_cohort[0].variables} annotations={group.scores_per_cohort[0].annotations} />
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default DimensionGroup;
