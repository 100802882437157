import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { Code, Send } from '@mui/icons-material';
import { LocationBar, Loading } from 'components';
import { sendPromptToBedrock } from 'services/bedrock';

type Message = { question: string; response: string };

export default function Llm() {
  const prompts = [
    // eslint-disable-next-line max-len
    {
      question: '',
      response:
        'Hi, how can i help you today, try asking me anything about working at Bellevie and I will try my best to answer.  I can make mistakes, so please double check my responses.',
    },
  ];
  const [currentSessionId, setSessionId] = useState<string | undefined>(undefined);
  const [question, setUserQuestion] = useState('');
  const [loading, setLoading] = useState(false);
  const [messages, setMessageList] = useState<Message[]>(prompts);

  const run = async () => {
    setUserQuestion('');
    setMessageList([...messages, { response: '', question }]);
    setLoading(true);
    const response = await sendPromptToBedrock(question, currentSessionId);
    setMessageList([...messages, { response: response?.output?.text || '', question }]);
    setSessionId(response?.sessionId || undefined);
    setLoading(false);
  };

  useEffect(() => {
    const llmScroll = document.getElementById('llm-scroll');
    if (llmScroll) {
      llmScroll?.scrollTo(0, llmScroll?.scrollHeight);
    }
  }, [messages]);

  return (
    <>
      <Helmet>
        <title>Bellevie AI</title>
      </Helmet>
      <LocationBar section="AI" page="LLM Chat" Icon={Code} />

      <main className="my-10 px-4 md:px-[5%] text-lg leading-lg text-gray-700">
        <div className="my-5">
          <div className="text-primary-700 text-display-md leading-display-md tracking-md border-b border-primary-700 pb-2">Chat with Bellevie</div>
          <div className="overflow-y-auto h-[calc(100vh-18.5em)] xl:h-[calc(100vh-18.5em)] flex flex-col gap-2" id="llm-scroll">
            {messages.map((m: { question: string; response: string }) => (
              <div className="items-center gap-3 mt-4">
                {m.question && (
                  <div className="bg-primary-700 text-semibold text-md px-3 py-1.5 rounded-lg text-white float-right">
                    <div className="p-2">{m.question}</div>
                  </div>
                )}
                {m.response && (
                  <div className="bg-primary-200 text-semibold text-md px-3 py-1.5 rounded-lg text-black mt-8 float-left">
                    <div className="p-2 italic">{m.response}</div>
                  </div>
                )}
              </div>
            ))}
            {loading && (
              <div className="m-20 float-left">
                <Loading isComponent />
              </div>
            )}
          </div>
          <div className="flex justify-between items-center gap-4 w-full px-4 py-2 shadow-chatInput rounded-xl h-[6em]" id="chat-input">
            <div className="w-full mt-4">
              <textarea
                value={question}
                onChange={(event) => setUserQuestion(event.target.value)}
                className="bg-white px-5 py-2 rounded-lg text-md leading-md text-gray-700 placeholder:text-gray-500 border border-gray-300 shadow-xs w-full max-h-full"
                placeholder="Message"
              />
            </div>
            <button type="button" aria-label="send message" className="text-primary-700 disabled:opacity-50" onClick={run}>
              <Send />
            </button>
          </div>
        </div>
      </main>
    </>
  );
}
