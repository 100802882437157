import { useQuery, gql } from '@apollo/client';
import { ShiftRun, GetDayShiftsForTeamSearch } from '../../__generated__/graphql';

export const GET_DAY_SHIFTS_FOR_TEAM = gql`
  query GetDayShiftsForTeam($query: GetDayShiftsForTeamSearch) {
    GetDayShiftsForTeam(query: $query) {
      id
      startDateTime
      endDateTime
      teamId
      rotaId
      warning
      supportWorker {
        id
        fullName
        avatar
        contractedHours
      }
    }
  }
`;

interface GetDayShiftsForTeamResponse {
  GetDayShiftsForTeam: ShiftRun[];
}

export const useGetDayShiftsForTeam = (query: GetDayShiftsForTeamSearch) => {
  const { data, loading, error } = useQuery<GetDayShiftsForTeamResponse>(GET_DAY_SHIFTS_FOR_TEAM, {
    variables: {
      query: { ...query },
    },
  });

  return { ShiftsForTeam: data?.GetDayShiftsForTeam || [], loading, error };
};
