import { useQuery, gql, useMutation } from '@apollo/client';
import { Buddie, BuddieForSupportWorkerSearch, BuddieGroup, BuddieGroupSearch } from '../../__generated__/graphql';

export const GET_BUDDIES_FOR_SUPPORT_WORKER = gql`
  query GetBuddiesForSupportWorker($query: BuddieForSupportWorkerSearch) {
    GetBuddiesForSupportWorker(query: $query) {
      id
      fullName
      avatar
    }
  }
`;

export const GET_BUDDIE_GROUPS = gql`
  query GetBuddieGroups($query: BuddieGroupSearch) {
    GetBuddieGroups(query: $query) {
      teamId
      groupId
      buddies {
        id
        fullName
        avatar
      }
    }
  }
`;

export const SAVE_BUDDIE_GROUP = gql`
  mutation SaveBuddieGroup($input: BuddieGroupUpdate) {
    SaveBuddieGroup(input: $input) {
      id
      message
      date
    }
  }
`;

interface Response {
  GetBuddiesForSupportWorker: Buddie[];
}

interface GetBuddieGroupsResponse {
  GetBuddieGroups: BuddieGroup[];
}

export const useGetBuddiesForSupportWorker = (query: BuddieForSupportWorkerSearch) => {
  const { data, loading, error } = useQuery<Response>(GET_BUDDIES_FOR_SUPPORT_WORKER, {
    variables: {
      query: { ...query },
    },
  });

  return { buddies: data?.GetBuddiesForSupportWorker || [], buddiesLoading: loading, buddiesError: error };
};

export const useGetBuddieGroups = (query: BuddieGroupSearch) => {
  const {
    loading: buddiesLoading,
    error: buddiesError,
    data,
  } = useQuery<GetBuddieGroupsResponse>(GET_BUDDIE_GROUPS, {
    variables: {
      query: {
        ...query,
      },
    },
  });
  return { buddieGroups: data?.GetBuddieGroups || [], buddiesLoading, buddiesError };
};

export const useUpdateBuddieGroups = (query: BuddieGroupSearch) => {
  const [updateBuddieGroup, mutationBuddieGroup] = useMutation(SAVE_BUDDIE_GROUP, {
    refetchQueries: [
      {
        query: GET_BUDDIE_GROUPS,
        variables: {
          query: {
            ...query,
          },
        },
      },
    ],
  });
  return { updateBuddieGroup, mutationBuddieGroup };
};
