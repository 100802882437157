import { useQuery, useMutation, gql } from '@apollo/client';
import { Template } from '../../__generated__/graphql';

export const GET_TEMPLATES = gql`
  query GetTemplates {
    GetTemplates {
      id
      name
      subject
      message
      dateUpdated
      type
    }
  }
`;

export const SAVE_TEMPLATE = gql`
  mutation SaveTemplate($input: SaveTemplateInput) {
    SaveTemplate(input: $input) {
      id
      date
      message
    }
  }
`;

export const REMOVE_TEMPLATE = gql`
  mutation RemoveTemplate($input: RemoveTemplateInput) {
    RemoveTemplate(input: $input) {
      id
      date
      message
    }
  }
`;

interface GetTemplatesResponse {
  GetTemplates: Template[];
}

export const useGetTemplates = () => {
  const { data, loading, error } = useQuery<GetTemplatesResponse>(GET_TEMPLATES);
  return { templates: data?.GetTemplates || [], templatesLoading: loading, error };
};

export const useSaveTemplate = () => {
  const [saveTemplate, mutationSaveTemplate] = useMutation(SAVE_TEMPLATE, {
    refetchQueries: [
      {
        query: GET_TEMPLATES,
      },
    ],
  });
  return { saveTemplate, mutationSaveTemplate };
};

export const useRemoveTemplate = () => {
  const [removeTemplate, mutationRemoveTemplate] = useMutation(REMOVE_TEMPLATE, {
    refetchQueries: [
      {
        query: GET_TEMPLATES,
      },
    ],
  });
  return { removeTemplate, mutationRemoveTemplate };
};
