import { useEffect, useState, useMemo } from 'react';
import Helmet from 'react-helmet';
import { SelectOption } from 'types/types';
import { formatTime, getFinancialYears, formatHoursAndMinutes } from 'services/helpers';
import { getTime } from 'date-fns';
import { AdjustmentsHorizontalIcon, XMarkIcon, ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Loading, Filters, Table } from 'components';
import { VisitCancellation } from '__generated__/graphql';
import { useGeneric, GenericContextProps } from 'context/genericContext';
import { CSVLink } from 'react-csv';
import { useGetVisitCancellationReport } from 'api/hooks/useReports';
import { useSearchParams } from 'react-router-dom';
import Navigation from './Navigation';

export default function AnnualVisitCancellationReport() {
  const { teams: genericTeams } = useGeneric() as GenericContextProps;
  const [searchParams, setSearchParams] = useSearchParams();

  const periods = getFinancialYears().map((year: number) => {
    return { label: formatTime(year, 'MMMM, yyyy'), value: year.toString() };
  });

  const teams = useMemo(() => {
    return genericTeams?.map((t) => ({ value: t.teamId, label: t.teamName })) || [];
  }, [genericTeams]);

  const [period, setPeriod] = useState<string>(periods[0].value);
  const [team, setTeam] = useState<string>('all');
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
  const [filters, setFilters] = useState([
    { name: 'period', selectedValue: periods[0], values: periods },
    { name: 'team', selectedValue: { value: 'all', label: 'All' }, values: [{ value: 'all', label: 'All' }, ...teams] },
  ]);

  useEffect(() => {
    const teamSearch = searchParams.get('team');
    const periodSearch = searchParams.get('period');
    const newFilters = filters;
    if (teamSearch) {
      setTeam(teamSearch);
      const teamOption = genericTeams?.map((t) => ({ value: t.teamId, label: t.teamName })).find((t) => t.value === teamSearch);
      if (teamOption) {
        newFilters[1].selectedValue = teamOption;
      }
    }
    if (periodSearch) {
      setPeriod(periodSearch);
      const periodOption = periods.find((t) => t.value === periodSearch);
      if (periodOption) {
        newFilters[0].selectedValue = periodOption;
      }
    }
    setFilters(newFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genericTeams]);

  useEffect(() => {
    filters[1].values = [{ value: 'all', label: 'All' }, ...teams];
    setFilters(filters);
  }, [filters, teams]);

  const onFilterChange = (name: string, value: SelectOption) => {
    const newFilters = [...filters];
    const filterIndex = newFilters.findIndex((f) => f.name === name);
    newFilters[filterIndex].selectedValue = value;
    setFilters(newFilters);
  };

  const { getVisitCancellationReport, reportLoading, reportError, report } = useGetVisitCancellationReport();

  useEffect(() => {
    getVisitCancellationReport({
      variables: {
        skip: !period,
        query: {
          teamId: team === 'all' ? '0' : team,
          startDateTime: getTime(new Date(new Date(parseInt(period, 10)).setFullYear(new Date(parseInt(period, 10)).getFullYear() - 1))),
          endDateTime: parseInt(period, 10),
        },
      },
    });
  }, [getVisitCancellationReport, period, team]);

  const columnHelper = createColumnHelper<VisitCancellation>();

  const columns1 = useMemo(() => {
    return [
      columnHelper.accessor('customerName', {
        header: () => 'Customer',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('totalNumberOfVisits', {
        header: () => 'Number Of Visits',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('totalVisitTime', {
        header: () => 'Total Visit Time',
        cell: (info) => {
          const time = formatHoursAndMinutes(info.renderValue() || 0);
          return `${time.hours} hours : ${time.minutes} minutes`;
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('totalCancelledVisits', {
        header: () => 'Number Of Cancelled Visits',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('totalCancelledVisitTime', {
        header: () => 'Total Cancelled Visit Time',
        cell: (info) => {
          const time = formatHoursAndMinutes(info.renderValue() || 0);
          return `${time.hours} hours : ${time.minutes} minutes`;
        },
        footer: (info) => info.column.id,
      }),
    ] as Array<ColumnDef<VisitCancellation, unknown>>;
  }, [columnHelper]);

  const onSaveFilters = () => {
    const newSearchParamsArray = filters.map((f) => ({ [f.name]: f.selectedValue.value }));
    setSearchParams(Object.assign({}, ...newSearchParamsArray));
    setTeam(filters[1].selectedValue.value);
    setPeriod(filters[0].selectedValue.value);
    setShowFiltersModal(false);
  };

  if (reportLoading || reportError) {
    return <Loading />;
  }

  const csvReport = report.map((r) => {
    const totalCancelledVisitTime = formatHoursAndMinutes(r.totalCancelledVisitTime || 0);
    const totalVisitTime = formatHoursAndMinutes(r.totalVisitTime || 0);

    return {
      customerName: r.customerName,
      totalNumberOfVisits: r.totalNumberOfVisits,
      totalVisitTime: `${totalVisitTime.hours} hours : ${totalVisitTime.minutes} minutes`,
      totalCancelledVisits: r.totalCancelledVisits,
      totalCancelledVisitTime: `${totalCancelledVisitTime.hours} hours : ${totalCancelledVisitTime.minutes} minutes`,
    };
  });

  return (
    <>
      <Helmet>
        <title>Annual Visit Cancellation Report</title>
      </Helmet>
      <Navigation report="AnnualCancellation Report" />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-display-sm sm:text-display-lg leading-display-sm sm:leading-display-lg sm:tracking-display-lg text-black font-medium">
            Annual Visit Cancellation Report
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-between mt-8">
          <div className="flex flex-col md:flex-row items-center gap-4 md:gap-9 w-full md:w-fit">
            <div className="flex flex-col md:flex-row items-center gap-3 w-full md:w-fit">
              <button
                data-cy="show-filters-button"
                type="button"
                className={`relative border ${
                  team === 'all' && period === '0' ? 'border-gray-300' : 'border-gray-900'
                }  text-gray-700 text-md leading-md flex items-center px-4 py-2.5 rounded-lg shadow-xs justify-center w-full md:w-fit`}
                onClick={() => setShowFiltersModal(true)}
              >
                <AdjustmentsHorizontalIcon className="w-5 h-5 mr-2" />
                Filters
                {team !== 'all' && period !== '0' && (
                  <span className="absolute bg-gray-900 h-5 w-5 text-white rounded-full -top-2 -right-2 flex items-center justify-center text-xs font-semibold">
                    {team !== 'all' && period !== '0' ? '2' : '1'}
                  </span>
                )}
              </button>

              {team !== 'all' && (
                <div
                  data-cy="tag-2"
                  className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center"
                >
                  {teams.find((type) => type.value === team)?.label}
                  <button
                    data-cy="remove-tag-2"
                    type="button"
                    aria-label="remove overdue filter"
                    className="text-gray-500"
                    onClick={() => {
                      onFilterChange('team', { value: 'all', label: 'All' });
                      const newSearchParamsArray = filters.map((f) => ({ [f.name]: f.selectedValue.value }));
                      setSearchParams(Object.assign({}, ...newSearchParamsArray));
                      setTeam('all');
                    }}
                  >
                    <XMarkIcon className="w-4 h-4 ml-2" />
                  </button>
                </div>
              )}
              {period !== '0' && (
                <div
                  data-cy="tag-1"
                  className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center"
                >
                  {periods.find((type) => type.value === period)?.label}
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between lg:justify-normal gap-3 md:gap-9 w-full lg:w-fit mt-6 lg:mt-0">
            <CSVLink
              filename="annualCancellationReport.csv"
              data={csvReport}
              className="text-gray-500 text-sm leading-sm font-semibold flex items-center hover:no-underline"
            >
              <ArrowUpTrayIcon className="w-5 h-5 mr-2" />
              Export CSV
            </CSVLink>
          </div>
        </div>
        <div className="mt-3 md:mt-12 mb-10 overflow-x-auto overflow-y-hidden">
          {report && report?.length > 0 && <Table<VisitCancellation> data={report} columns={columns1} />}
        </div>
      </div>
      {showFiltersModal && (
        <Filters
          filters={filters}
          onFilterChange={onFilterChange}
          onCloseModal={() => setShowFiltersModal(false)}
          onSaveFilters={onSaveFilters}
          showClearFilters={false}
        />
      )}
    </>
  );
}
