import { useEffect, useState, useMemo } from 'react';
import Helmet from 'react-helmet';
import { SelectOption } from 'types/types';
import { Maybe, Shift } from '__generated__/graphql';
import { formatTime, getPeriodsForYear, formatAmPm, getDurationInMinutes } from 'services/helpers';
import { getTime } from 'date-fns';
import intervalToDuration from 'date-fns/intervalToDuration';
import { AdjustmentsHorizontalIcon, XMarkIcon, ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Loading, Filters, Table } from 'components';
import { useGeneric, GenericContextProps } from 'context/genericContext';
import { CSVLink } from 'react-csv';
import { useGetCancellationVisitsReport } from 'api/hooks/useReports';
import { useSearchParams } from 'react-router-dom';
import Navigation from './Navigation';

type CancellationReportType = {
  visitId: string;
  shiftId: string;
  teamId: string;
  teamName: Maybe<string> | undefined;
  customer: string;
  customerId: Maybe<string> | undefined;
  owner: string;
  period: string;
  supportWorker: string;
  supportWorkerId: Maybe<string> | undefined;
  type: Maybe<string> | undefined;
  employmentStatus: Maybe<string> | undefined;
  contractedHours: string | number;
  visitDate: string;
  visitStartTime: string;
  visitEndTime: string;
  visitLength: string;
  ampm: string;
  status: string;
  week: string | number;
  notes: Maybe<string> | undefined;
  whoCancelled: Maybe<string> | undefined;
  cancelledDateTime: string | null;
  daysBeforeVisitCancelled: string | null;
  oneOffVisitReason: Maybe<string> | undefined;
  oneOffVisitNote: Maybe<string> | undefined;
  isToBeRescheduled: string;
  origin: Maybe<string> | undefined;
  destination: Maybe<string> | undefined;
};

export default function CancellationVisitsReport() {
  const { teams: genericTeams } = useGeneric() as GenericContextProps;
  const [searchParams, setSearchParams] = useSearchParams();

  const periods = getPeriodsForYear().map((period) => {
    return { label: formatTime(period, 'MMMM, yyyy'), value: period.toString() };
  });

  const teams = useMemo(() => {
    return genericTeams?.map((t) => ({ value: t.teamId, label: t.teamName })) || [];
  }, [genericTeams]);

  const [period, setPeriod] = useState<string>(periods[0].value);
  const [team, setTeam] = useState<string>('all');
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
  const [filters, setFilters] = useState([
    { name: 'period', selectedValue: periods[0], values: periods },
    { name: 'team', selectedValue: { value: 'all', label: 'All' }, values: [{ value: 'all', label: 'All' }, ...teams] },
  ]);

  useEffect(() => {
    const teamSearch = searchParams.get('team');
    const periodSearch = searchParams.get('period');
    const newFilters = filters;
    if (teamSearch) {
      setTeam(teamSearch);
      const teamOption = genericTeams?.map((t) => ({ value: t.teamId, label: t.teamName })).find((t) => t.value === teamSearch);
      if (teamOption) {
        newFilters[1].selectedValue = teamOption;
      }
    }
    if (periodSearch) {
      setPeriod(periodSearch);
      const periodOption = periods.find((t) => t.value === periodSearch);
      if (periodOption) {
        newFilters[0].selectedValue = periodOption;
      }
    }
    setFilters(newFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genericTeams]);

  useEffect(() => {
    filters[1].values = [{ value: 'all', label: 'All' }, ...teams];
    setFilters(filters);
  }, [filters, teams]);

  const onFilterChange = (name: string, value: SelectOption) => {
    const newFilters = [...filters];
    const filterIndex = newFilters.findIndex((f) => f.name === name);
    newFilters[filterIndex].selectedValue = value;
    setFilters(newFilters);
  };

  const { getCancellationVisitsReport, visitsLoading, visitsError, visits } = useGetCancellationVisitsReport();

  useEffect(() => {
    getCancellationVisitsReport({
      variables: {
        skip: !period,
        query: {
          teamId: team === 'all' ? '0' : team,
          startDateTime: getTime(new Date(new Date(parseInt(period, 10)).setMonth(new Date(parseInt(period, 10)).getMonth() - 1))),
          endDateTime: parseInt(period, 10),
        },
      },
    });
  }, [period, getCancellationVisitsReport, team]);

  const mappedShifts = visits?.map((sh: Shift) => {
    let cancellation = '';
    const cancelledDays = intervalToDuration({ start: new Date(sh.cancelledDateTime || 0), end: new Date(sh.startDateTime) });

    if (sh.cancelledDateTime) {
      cancellation = `${cancelledDays.months} months - ${cancelledDays.days} days, ${cancelledDays.hours} hours`;
    }

    const { supportWorker, customer, secondarySupportWorker } = sh;
    return {
      visitId: sh.id,
      shiftId: sh.shiftId,
      teamId: sh.teamId,
      teamName: sh.teamName,
      customer: customer ? `${customer?.firstName} ${customer?.lastName}` : '',
      customerId: customer?.birdieId,
      owner: supportWorker?.fullName || 'Not agreed',
      supportWorker: secondarySupportWorker?.fullName || 'tbc',
      supportWorkerId: secondarySupportWorker?.birdieId,
      type: sh.type,
      period: formatAmPm(sh.startDateTime),
      employmentStatus: secondarySupportWorker?.employmentStatus,
      contractedHours: secondarySupportWorker?.contractedHours ? secondarySupportWorker?.contractedHours : '',
      visitDate: formatTime(sh.startDateTime, 'dd/MM/yyyy'),
      visitStartTime: formatTime(sh.startDateTime, 'k:mm'),
      visitEndTime: formatTime(sh.endDateTime, 'k:mm'),
      visitLength: `${getDurationInMinutes(sh.startDateTime, sh.endDateTime)}mins`,
      ampm: formatAmPm(sh.startDateTime),
      status: sh.status,
      week: sh?.week ? parseInt(sh?.week, 10) : '',
      notes: sh.notes,
      whoCancelled: sh.whoCancelled,
      cancelledDateTime: sh.cancelledDateTime ? formatTime(sh.cancelledDateTime, 'PPPP: HH:mm') : null,
      daysBeforeVisitCancelled: sh.cancelledDateTime ? cancellation : null,
      oneOffVisitReason: sh.oneOffVisitReason,
      oneOffVisitNote: sh.oneOffVisitNote,
      isToBeRescheduled: sh.isToBeRescheduled ? 'yes' : 'no',
      origin: sh.origin?.fullName,
      destination: sh.destination?.fullName,
    };
  });

  const columnHelper = createColumnHelper<CancellationReportType>();

  const columns1 = useMemo(() => {
    return [
      columnHelper.accessor('teamName', {
        header: () => 'Team',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('customer', {
        header: () => 'Customer',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('whoCancelled', {
        header: () => 'Who Cancelled',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('supportWorker', {
        header: () => 'SupportWorker',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('type', {
        header: () => 'Type',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('visitDate', {
        header: () => 'Visit Date',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),

      columnHelper.accessor('visitStartTime', {
        header: () => 'Visit Start Time',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('visitEndTime', {
        header: () => 'Visit End Time',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('cancelledDateTime', {
        header: () => 'Cancelled Date',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('daysBeforeVisitCancelled', {
        header: () => 'Days Before Cancellations',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('period', {
        header: () => 'Period',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('visitLength', {
        header: () => 'Visit Length',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('status', {
        header: () => 'Status',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('week', {
        header: () => 'Week',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('isToBeRescheduled', {
        header: () => 'To reschedule',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('notes', {
        header: () => 'Notes',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
    ] as Array<ColumnDef<CancellationReportType, unknown>>;
  }, [columnHelper]);

  const onSaveFilters = () => {
    const newSearchParamsArray = filters.map((f) => ({ [f.name]: f.selectedValue.value }));
    setSearchParams(Object.assign({}, ...newSearchParamsArray));
    setTeam(filters[1].selectedValue.value);
    setPeriod(filters[0].selectedValue.value);
    setShowFiltersModal(false);
  };

  if (visitsLoading || visitsError) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Cancellation Report</title>
      </Helmet>
      <Navigation report="Cancellation Report" />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-display-sm sm:text-display-lg leading-display-sm sm:leading-display-lg sm:tracking-display-lg text-black font-medium">
            Cancellation Report
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-between mt-8">
          <div className="flex flex-col md:flex-row items-center gap-4 md:gap-9 w-full md:w-fit">
            <div className="flex flex-col md:flex-row items-center gap-3 w-full md:w-fit">
              <button
                data-cy="show-filters-button"
                type="button"
                className={`relative border ${
                  team === 'all' && period === '0' ? 'border-gray-300' : 'border-gray-900'
                }  text-gray-700 text-md leading-md flex items-center px-4 py-2.5 rounded-lg shadow-xs justify-center w-full md:w-fit`}
                onClick={() => setShowFiltersModal(true)}
              >
                <AdjustmentsHorizontalIcon className="w-5 h-5 mr-2" />
                Filters
                {team !== 'all' && period !== '0' && (
                  <span className="absolute bg-gray-900 h-5 w-5 text-white rounded-full -top-2 -right-2 flex items-center justify-center text-xs font-semibold">
                    {team !== 'all' && period !== '0' ? '2' : '1'}
                  </span>
                )}
              </button>

              {team !== 'all' && (
                <div
                  data-cy="tag-2"
                  className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center"
                >
                  {teams.find((type) => type.value === team)?.label}
                  <button
                    data-cy="remove-tag-2"
                    type="button"
                    aria-label="remove overdue filter"
                    className="text-gray-500"
                    onClick={() => {
                      onFilterChange('team', { value: 'all', label: 'All' });
                      const newSearchParamsArray = filters.map((f) => ({ [f.name]: f.selectedValue.value }));
                      setSearchParams(Object.assign({}, ...newSearchParamsArray));
                      setTeam('all');
                    }}
                  >
                    <XMarkIcon className="w-4 h-4 ml-2" />
                  </button>
                </div>
              )}
              {period !== '0' && (
                <div
                  data-cy="tag-1"
                  className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center"
                >
                  {periods.find((type) => type.value === period)?.label}
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between lg:justify-normal gap-3 md:gap-9 w-full lg:w-fit mt-6 lg:mt-0">
            <CSVLink
              filename="cancellations.csv"
              data={mappedShifts}
              className="text-gray-500 text-sm leading-sm font-semibold flex items-center hover:no-underline"
            >
              <ArrowUpTrayIcon className="w-5 h-5 mr-2" />
              Export CSV
            </CSVLink>
          </div>
        </div>
        <div className="mt-3 md:mt-12 mb-10 overflow-x-auto overflow-y-hidden">
          {mappedShifts && mappedShifts?.length > 0 && <Table<CancellationReportType> data={mappedShifts} columns={columns1} />}
        </div>
      </div>
      {showFiltersModal && (
        <Filters
          filters={filters}
          onFilterChange={onFilterChange}
          onCloseModal={() => setShowFiltersModal(false)}
          onSaveFilters={onSaveFilters}
          showClearFilters={false}
        />
      )}
    </>
  );
}
