import { useState } from 'react';
import { getTextColor, getTimeInDecimal } from 'services/helpers';
import stc from 'string-to-color';
import { SupportWorker as SupportWorkerType, AgreedShiftRun, AgreedShift, Maybe } from '__generated__/graphql';
import { User } from 'types/types';
import { useGetSupportWorkers } from 'api/hooks/useSupportWorkers';
import { useGetAgreedShifts } from 'api/hooks/useAgreedShifts';
import { Loading } from 'components';
import ReactSelect from 'components/Select';
import ContractedHoursHeader from './ContractedHoursHeader';

interface ContractedHoursProps {
  user: User;
  teamId: string;
  selectedAgreedRota: string;
}

const ContractedHours = ({ user, teamId, selectedAgreedRota }: ContractedHoursProps) => {
  const { agreedShifts } = useGetAgreedShifts({
    teamId,
    agreedRotaId: selectedAgreedRota,
  });
  const { supportWorkers, loading } = useGetSupportWorkers({
    teamId,
    rotaId: user?.rota?.id,
  });
  const supportWorkerOptions = supportWorkers.map((s, index) => {
    return { label: s.fullName, value: s.id, id: index.toString() };
  });
  const [selectedSW, setSelectedSW] = useState(supportWorkerOptions.length > 0 ? supportWorkerOptions[0] : { value: '', label: '' });

  return (
    <div>
      {loading ? (
        <Loading isComponent />
      ) : (
        <div className="mt-5">
          <div className="lg:hidden">
            <div className="text-sm font-medium text-gray-700 capitalize w-auto">Select a support worker</div>
            <div className="mt-2">
              <ReactSelect selectedValue={selectedSW} options={supportWorkerOptions} onChange={setSelectedSW} />
            </div>
          </div>
          <div className="mt-5 border-b border-gray-100">
            <div className="hidden lg:flex">
              <ContractedHoursHeader />
            </div>
            {supportWorkers?.map((supportWorker: SupportWorkerType, index: number) => {
              let hoursInAgreedShifts = 0;
              let week1AgreedShifts = 0;
              let week2AgreedShifts = 0;
              agreedShifts?.week1?.forEach((shiftRun: Maybe<AgreedShiftRun>) => {
                shiftRun?.shifts?.forEach((shift: Maybe<AgreedShift>) => {
                  if (shift?.ownerId === supportWorker.id && shiftRun.startDateTime && shiftRun.endDateTime) {
                    week1AgreedShifts += getTimeInDecimal(shiftRun.startDateTime, shiftRun.endDateTime);
                    hoursInAgreedShifts += getTimeInDecimal(shiftRun.startDateTime, shiftRun.endDateTime);
                  }
                });
              });
              agreedShifts?.week2?.forEach((shiftRun: Maybe<AgreedShiftRun>) => {
                shiftRun?.shifts?.forEach((shift: Maybe<AgreedShift>) => {
                  if (shift?.ownerId === supportWorker.id && shiftRun.startDateTime && shiftRun.endDateTime) {
                    week2AgreedShifts += getTimeInDecimal(shiftRun.startDateTime, shiftRun.endDateTime);
                    hoursInAgreedShifts += getTimeInDecimal(shiftRun.startDateTime, shiftRun.endDateTime);
                  }
                });
              });
              if (agreedShifts) {
                return (
                  <div className={`${supportWorker.id === selectedSW.value ? 'flex' : 'hidden lg:flex'}`} key={index}>
                    <div className="lg:hidden sm:shrink-0">
                      <ContractedHoursHeader />
                    </div>
                    <div className="flex flex-col lg:flex-row w-full">
                      <div
                        className="h-[80px] lg:h-[70px] lg:w-[15%] p-2 border-r lg:border-r-0 border-t border-l border-gray-100 flex flex-col justify-center items-center"
                        style={{ backgroundColor: stc(supportWorker.fullName) }}
                      >
                        <div
                          className="text-md lg:text-lg leading-md lg:leading-lg font-semibold text-center"
                          style={{ color: getTextColor(stc(supportWorker.fullName)) }}
                        >
                          {supportWorker.fullName}
                        </div>
                      </div>
                      <div className="h-[80px] lg:h-[70px] lg:w-[10%] p-2 border-r lg:border-r-0 border-t border-l border-gray-100 flex flex-col justify-center items-center">
                        <div className="text-md lg:text-lg leading-md lg:leading-lg text-gray-800 font-medium text-center">{supportWorker.contractedHours}</div>
                      </div>
                      <div className="h-[80px] lg:h-[70px] lg:w-[13%] p-2 border-r lg:border-r-0 border-t border-l border-gray-100 flex flex-col justify-center items-center">
                        <div className="text-md lg:text-lg leading-md lg:leading-lg text-gray-800 font-medium text-center">3</div>
                      </div>
                      <div className="h-[80px] lg:h-[70px] lg:w-[13%] p-2 border-r lg:border-r-0 border-t border-l border-gray-100 flex flex-col justify-center items-center">
                        <div className="text-md lg:text-lg leading-md lg:leading-lg text-gray-800 font-medium text-center">
                          {supportWorker.contractedHours > 3 ? supportWorker.contractedHours * 2 - 3 : supportWorker.contractedHours}
                        </div>
                      </div>
                      <div className="h-[80px] lg:h-[70px] lg:w-[12%] p-2 border-r lg:border-r-0 border-t border-l border-gray-100 flex flex-col justify-center items-center">
                        <div className="text-md lg:text-lg leading-md lg:leading-lg text-gray-800 font-medium text-center">{week1AgreedShifts}</div>
                      </div>
                      <div className="h-[80px] lg:h-[70px] lg:w-[12%] p-2 border-r lg:border-r-0 border-t border-l border-gray-100 flex flex-col justify-center items-center">
                        <div className="text-md lg:text-lg leading-md lg:leading-lg text-gray-800 font-medium text-center">{week2AgreedShifts}</div>
                      </div>
                      <div className="h-[80px] lg:h-[70px] lg:w-[12%] p-2 border-r lg:border-r-0 border-t border-l border-gray-100 flex flex-col justify-center items-center">
                        <div className="text-md lg:text-lg leading-md lg:leading-lg text-gray-800 font-medium text-center">{hoursInAgreedShifts}</div>
                      </div>
                      <div className="h-[80px] lg:h-[70px] lg:w-[13%] p-2 border-r border-t border-l border-gray-100 flex flex-col justify-center items-center">
                        <div className="text-md lg:text-lg leading-md lg:leading-lg text-gray-800 font-medium text-center">
                          {supportWorker.contractedHours > 3 ? supportWorker.contractedHours * 2 - 3 - hoursInAgreedShifts : supportWorker.contractedHours}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default ContractedHours;
