/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface MenuButtonLink {
  text: string;
  url: string;
}

interface MenuButtonProps {
  items: MenuButtonLink[];
  testId: string;
  text: string;
}

const MenuButton = ({ items, text, testId }: MenuButtonProps) => {
  const navigate = useNavigate();
  return (
    <div className="dropdown inline-block relative z-50">
      <button
        type="button"
        data-cy={`${testId}-button`}
        className="text-white bg-primary-700 px-5 py-2.5 font-semibold text-md leading-md rounded inline-flex items-center"
      >
        <span className="mr-1">{text}</span>
        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{' '}
        </svg>
      </button>
      <ul className="dropdown-menu absolute hidden text-gray-700 pt-1">
        {items.map((mi: MenuButtonLink, i: number) => {
          return (
            <li>
              <a
                data-cy={`${testId}-item-${i}`}
                className="cursor-pointer text-white bg-primary-700 hover:bg-primary-800 py-2 px-4 block whitespace-no-wrap"
                onClick={() => navigate(mi.url)}
              >
                {mi.text}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MenuButton;
