import { useState, useMemo, useRef, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Helmet from 'react-helmet';
// import { format, isValid } from 'date-fns';
import { CSVLink } from 'react-csv';
import { CheckCircleIcon, XCircleIcon, ArrowUpTrayIcon } from '@heroicons/react/24/solid';
import { AdjustmentsHorizontalIcon, XMarkIcon, EyeIcon } from '@heroicons/react/24/outline';
import { LocationBar, Loading, Search, Table, Filters } from 'components';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { SelectOption } from 'types/types';
import { SupportWorker } from '__generated__/graphql';
import { GroupOutlined } from '@mui/icons-material';
import { useGetSupportWorkers } from 'api/hooks/useSupportWorkers';
import { useUser, UserContextProps } from 'context/userContext';
import { useGeneric, GenericContextProps } from 'context/genericContext';
import { useGetAllReportingHoursPeriod } from 'api/hooks/useReportingHours';
import { getFirstDayOfPayroll } from 'services/helpers';

const ReportingHoursStatus = () => {
  const { user, userLoading } = useUser() as UserContextProps;
  const { teams: genericTeam, teamsLoading } = useGeneric() as GenericContextProps;
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [team, setTeam] = useState(user?.teamId);
  const teams = useMemo(() => genericTeam?.map((t) => ({ value: t.teamId, label: t.teamName })) || [], [genericTeam]);
  const tableRef = useRef(null);
  const currentStartDateTime = getFirstDayOfPayroll(Date.now());
  const { reportingHoursPeriod, loading: rhpLoading } = useGetAllReportingHoursPeriod({
    teamId: '0',
    period: currentStartDateTime,
  });

  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
  const [filters, setFilters] = useState([
    {
      name: 'teams',
      selectedValue: user?.teamName ? { value: user?.teamId, label: user.teamName } : { value: 'all', label: 'All' },
      values: [{ value: 'all', label: 'All' }, ...(teams || [])],
    },
  ]);

  useEffect(() => {
    const newFilters = filters;
    newFilters[0].values = [{ value: 'all', label: 'All' }, ...teams];
    setFilters(newFilters);
  }, [filters, teams]);

  const { supportWorkers, loading } = useGetSupportWorkers({ showAll: false });

  const filterByTeam = (sw: SupportWorker) => {
    if (team === 'all') return true;
    if (team && sw.teamId === team) return true;
    return false;
  };

  const filterByName = (sw: SupportWorker) => {
    if (sw.fullName.toLowerCase().includes(search.toLowerCase())) return true;
    return false;
  };

  const filteredTeamMembers = () => {
    return supportWorkers
      ?.filter((sw) => filterByTeam(sw))
      .filter((sw) => filterByName(sw))
      .sort((x, y) => (y.dateUpdated || 0) - (x.dateUpdated || 0));
  };

  const onButtonClick = useCallback(
    (sw: SupportWorker) => {
      const rh = reportingHoursPeriod.find((rhp) => rhp.supportWorkerId === sw.id);
      rh ? navigate(`/reporting-hours/manage/${sw.teamId}/${sw.id}/${rh?.id}`) : navigate('');
    },
    [navigate, reportingHoursPeriod],
  );

  const RowActions = useCallback(
    ({ row }: { row: SupportWorker }) => {
      const rh = reportingHoursPeriod.find((rhp) => rhp.supportWorkerId === row.id);

      return rh ? (
        <div className="flex flex-col sm:flex-row items-center gap-5">
          <button type="button" onClick={() => onButtonClick(row)} aria-label="Edit" className="m-0 p-0">
            <EyeIcon className="w-6 h-6 text-gray-500 hover:text-primary600" />
          </button>
        </div>
      ) : null;
    },
    [onButtonClick, reportingHoursPeriod],
  );

  const onFilterChange = (name: string, value: SelectOption) => {
    const newFilters = [...filters];
    const filterIndex = newFilters.findIndex((f) => f.name === name);
    newFilters[filterIndex].selectedValue = value;
    setFilters(newFilters);
  };

  const columnVisibility = {
    avatar: true,
    position: window.innerWidth > 1024,
    lastLoggedInDate: window.innerWidth > 768,
    actions: true,
  };

  const columnHelper = createColumnHelper<SupportWorker>();

  const columns1 = useMemo(() => {
    return [
      columnHelper.accessor('avatar', {
        header: () => 'Name',
        cell: (info) => (
          <div className="flex items-center font-medium text-gray-900 whitespace-nowrap dark:text-white">
            <img className="w-12 h-12 rounded-full" src={info.row.original.avatar || '/defaultProfile.jpg'} alt={`Link worker ${info.row.original.fullName}`} />
            <div className="ps-3">
              <div className="text-md leading-md font-semibold">{info.row.original.fullName}</div>
              <div className="text-sm leading-sm font-normal text-gray-500 hidden sm:block">{info.row.original.email}</div>
            </div>
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.display({
        id: 'rhp',
        header: () => 'Reporting Hours Synced',
        cell: (info) =>
          reportingHoursPeriod.find((rhp) => rhp.supportWorkerId === info.row.original.id) ? (
            <CheckCircleIcon className="w-8 h-8 text-success-500" />
          ) : (
            <XCircleIcon className="w-8 h-8 text-error-500" />
          ),
      }),
      columnHelper.display({
        id: 'actions',
        cell: (props) => <RowActions row={props.row.original} />,
      }),
    ] as Array<ColumnDef<SupportWorker, unknown>>;
  }, [columnHelper, RowActions, reportingHoursPeriod]);

  const onSaveFilters = () => {
    setTeam(filters[0].selectedValue.value);
    setShowFiltersModal(false);
  };

  if (loading || userLoading || teamsLoading || rhpLoading) {
    return <Loading />;
  }

  const rows = filteredTeamMembers().map((c) => {
    return {
      ...c,
    };
  });

  const csv = rows.map((r) => {
    const rh = reportingHoursPeriod.find((rhp) => rhp.supportWorkerId === r.id);
    return { name: r.fullName, status: !!rh };
  });

  return (
    <div>
      <Helmet>
        <title>Reporting Hours</title>
      </Helmet>
      <LocationBar section="People" page="Team Member List" Icon={GroupOutlined} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-display-sm leading-display-sm text-black font-medium">Reporting Hours Status</div>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-between mt-8 md:mt-16">
          <div className="flex flex-col md:flex-row items-center gap-4 md:gap-9 w-full md:w-fit">
            <Search value={search} onSearch={setSearch} />
            <div className="flex flex-col md:flex-row items-center gap-3 w-full md:w-fit">
              <button
                type="button"
                className={`relative border ${
                  team === 'all' ? 'border-gray-300' : 'border-gray-900'
                }  text-gray-700 text-md leading-md flex items-center px-4 py-2.5 rounded-lg shadow-xs justify-center w-full md:w-fit`}
                onClick={() => setShowFiltersModal(true)}
              >
                <AdjustmentsHorizontalIcon className="w-5 h-5 mr-2" />
                Filters
                {team !== 'all' && (
                  <span className="absolute bg-gray-900 h-5 w-5 text-white rounded-full -top-2 -right-2 flex items-center justify-center text-xs font-semibold">
                    {team !== 'all' ? '2' : '1'}
                  </span>
                )}
              </button>
              {team !== 'all' && (
                <div className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center">
                  {teams?.find((t) => t.value === team)?.label}
                  <button
                    type="button"
                    aria-label="remove team filter"
                    className="text-gray-500"
                    onClick={() => {
                      onFilterChange('teams', { value: 'all', label: 'All' });
                      setTeam('all');
                    }}
                  >
                    <XMarkIcon className="w-4 h-4 ml-2" />
                  </button>
                </div>
              )}
            </div>
            <div className="flex items-center justify-between lg:justify-normal gap-3 md:gap-9 w-full lg:w-fit mt-6 lg:mt-0">
              <CSVLink
                filename="reporting-hours.csv"
                data={csv}
                className="text-gray-500 text-sm leading-sm font-semibold flex items-center hover:no-underline"
              >
                <ArrowUpTrayIcon className="w-5 h-5 mr-2" />
                Export CSV
              </CSVLink>
            </div>
          </div>
        </div>

        <div className="mt-3 md:mt-12 mb-10" ref={tableRef}>
          {rows && rows.length > 0 && <Table<SupportWorker> data={rows} columns={columns1} columnVisibility={columnVisibility} />}
        </div>
      </div>

      {showFiltersModal && (
        <Filters filters={filters} onFilterChange={onFilterChange} onCloseModal={() => setShowFiltersModal(false)} onSaveFilters={onSaveFilters} />
      )}
    </div>
  );
};

export default ReportingHoursStatus;
