import React, { createContext, useContext } from 'react';
import { ApolloError } from '@apollo/client';
import { CognitoUser } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';
import { RotaUserType, User } from 'types/types';
import { client } from 'api';
import { useGetSupportWorker } from 'api/hooks/useSupportWorkers';
import { useGetRotas } from 'api/hooks/useRota';
import { Error } from 'components';

export type UserWithAttributes = CognitoUser & {
  attributes: { [key: string]: string };
  signInUserSession?: {
    accessToken: {
      payload: { [key: string]: string[] };
    };
  };
};

type UserProviderProps = {
  children: React.ReactNode;
  user: UserWithAttributes;
};

export interface UserContextProps {
  user?: User;
  userLoading: boolean;
  userError: ApolloError | undefined;
  rotaLoading: boolean;
  rotaError: ApolloError | undefined;
  signout: () => void;
}

const defaultProps = {
  userLoading: false,
  userError: undefined,
  rotaLoading: false,
  rotaError: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  signout: () => {},
};
const UserContext = createContext<UserContextProps>(defaultProps);

const UserProvider = ({ children, user }: UserProviderProps) => {
  const storageTeamId = localStorage.getItem('teamId');
  const storageRotaId = localStorage.getItem('rotaId');
  const storageStartRotaDateTime = localStorage.getItem('startRotaDateTime');
  const storageEndRotaDateTime = localStorage.getItem('endRotaDateTime');
  const userId = user.attributes.profile;
  const groups = user?.signInUserSession?.accessToken?.payload ? user?.signInUserSession?.accessToken?.payload['cognito:groups'] : [];

  const signout = async () => {
    await Auth.signOut();
    client.resetStore();
    localStorage.clear();
    window.location.assign('/');
  };

  if (!groups || groups.length === 0 || groups.includes('family')) {
    signout();
  }

  const { supportWorker, loading, error } = useGetSupportWorker({ id: userId, rotaId: storageRotaId });

  // use teamId in local storage or the users team
  const teamId = storageTeamId || (supportWorker && supportWorker.teamId);

  const { rotaLoading, rotaError, rotaData } = useGetRotas({
    teamId: teamId || '',
  });

  const isGuest = supportWorker?.teamId !== teamId && !supportWorker?.permissions?.admin;
  const isTeamMember = supportWorker?.teamId === teamId;

  // if a team has not been selected and no teamId in local storage, use the team from api
  if (!storageTeamId && supportWorker?.teamId === teamId) {
    localStorage.setItem('teamId', supportWorker?.teamId || '');
    localStorage.setItem('teamName', supportWorker?.teamName || '');
  }

  const rota = { data: rotaData } as RotaUserType;

  // if local storage contains a rota use it
  if (storageRotaId && storageStartRotaDateTime && storageEndRotaDateTime) {
    rota.id = storageRotaId;
    rota.startRotaDateTime = parseInt(storageStartRotaDateTime, 10);
    rota.endRotaDateTime = parseInt(storageEndRotaDateTime, 10);
    rota.teamId = storageTeamId || '';
  }

  // if local storage does not contain a rota, and we have a current rota for the team use the current rota
  if (!storageRotaId && rota?.data?.current?.id) {
    rota.id = rota.data.current.id;
    rota.startRotaDateTime = rota.data.current.startRotaDateTime || 0;
    rota.endRotaDateTime = rota.data.current.endRotaDateTime || 0;
    rota.teamId = rota.data.current.teamId || '';
    localStorage.setItem('rotaId', rota.data.current.id);
    localStorage.setItem('startRotaDateTime', rota.data.current.startRotaDateTime?.toString() || '');
    localStorage.setItem('endRotaDateTime', rota.data.current.endRotaDateTime?.toString() || '');
  }

  if (error) return <Error />;
  if (rotaError) return <Error />;

  return (
    <UserContext.Provider
      value={{
        user: {
          ...user.attributes,
          ...supportWorker,
          teamId: teamId || '',
          isGuest,
          rota,
          isTeamMember,
        } as User,
        userLoading: loading || rotaLoading,
        userError: error,
        rotaLoading,
        rotaError,
        signout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUser = (): UserContextProps => {
  const context = useContext(UserContext);

  return context;
};

export { UserProvider, useUser };
