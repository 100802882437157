import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

interface ManageSupportWorkerProps {
  modalState: boolean;
  toggleModal: (modalState: boolean) => void;
  onChange: () => void;
  title: string;
}

export default function ManageSupportWorker({ toggleModal, modalState, onChange, title }: ManageSupportWorkerProps) {
  return (
    <Dialog data-cy="enable-visit-dialog" open={modalState} onClose={() => toggleModal(false)}>
      <DialogTitle sx={{ fontSize: '1.6em', padding: { xs: '0.8em', sm: '1.2em' } }}>{title}</DialogTitle>
      <DialogContent>Are you sure you would like to make this change to this account?</DialogContent>
      <DialogActions sx={{ padding: '1em' }}>
        <Button data-cy="close-visit-button" variant="contained" onClick={() => toggleModal(false)}>
          Close
        </Button>
        <Button data-cy="confirm-support-worker-button" variant="contained" onClick={() => onChange()}>
          {title}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
