import { useState } from 'react';
import ReactQuill from 'react-quill';
import { SelectOption } from 'types/types';
import { Candidate, MessageType, SendCandidateMessagesInput, SendSmsInput, Template } from '__generated__/graphql';
import 'react-quill/dist/quill.snow.css';
import ReactMultiSelect from 'components/MultiSelect';
import ReactSelect from 'components/Select';
import TextArea from 'components/TextArea';
import { Input } from 'components';

interface CandidateMessagingProps {
  onClose: () => void;
  candidates: Candidate[];
  selectedCandidates?: Candidate[];
  templates: Template[];
  onSendEmail: (input: SendCandidateMessagesInput) => void;
  onSendSms: (input: SendSmsInput) => void;
  recipientsDisabled?: boolean;
}

export default function CandidateMessaging({
  onClose,
  candidates,
  selectedCandidates,
  templates,
  onSendEmail,
  onSendSms,
  recipientsDisabled = false,
}: CandidateMessagingProps) {
  const [type, setType] = useState<SelectOption>({ value: MessageType.Email, label: MessageType.Email });
  const [template, setTemplate] = useState<SelectOption>({ value: '', label: '' });
  const [selectedRecipients, setSelectedRecipients] = useState<SelectOption[]>(
    selectedCandidates ? selectedCandidates.map((c) => ({ value: c.id || '', label: `${c.firstName} ${c.lastName}` || '' })) : [],
  );
  const [subject, setSubject] = useState<string>('');
  const [body, setBody] = useState<string>('');
  const [smsMessage, setSmsMessage] = useState<string>('');

  const onSend = () => {
    const recipients = candidates
      .filter((s) => selectedRecipients.some((r) => r.value === s.id))
      .map((c) => ({ name: `${c.firstName} ${c.lastName}` || '', email: c.email || '', phone: c.phone || '' }));

    if (type.value === MessageType.Sms) {
      onSendSms({
        message: smsMessage,
        phones: recipients.map((r) => r.phone),
      });
    } else {
      onSendEmail({
        email: {
          template: 'generic',
          subject,
          body,
        },
        selectedRecipients: recipients,
        type: MessageType.Email,
      });
    }
    setSubject('');
    setBody('');
    setSmsMessage('');
    setTemplate({ value: '', label: '' });
    setSelectedRecipients([]);
    setType({ value: MessageType.Email, label: MessageType.Email });
    onClose();
  };

  const onRecipientChange = (recipients: SelectOption[]) => {
    setSelectedRecipients(recipients);
  };

  const onSelectTemplate = (t: SelectOption) => {
    const selectedTemplate = templates.find((temp) => temp.id === t.value);
    if (selectedTemplate) {
      setTemplate(t);
      if (type.value === MessageType.Email) {
        setSubject(selectedTemplate.subject || '');
        setBody(selectedTemplate.message || '');
      } else {
        setSmsMessage(selectedTemplate.message || '');
      }
    }
  };

  const filtered = recipientsDisabled
    ? candidates.map((c) => ({ value: c.id || '', label: `${c.firstName} ${c.lastName}` || '' }))
    : candidates.filter((c) => c.status === 'Open').map((c) => ({ value: c.id || '', label: `${c.firstName} ${c.lastName}` || '' }));

  const emailTemplates = Object.values(templates)
    .filter((x) => x.id && x.name && x.type === MessageType.Email)
    .map((t) => ({ value: t.id || '', label: t.name || '' }));

  const smsTemplates = Object.values(templates)
    .filter((x) => x.id && x.name && x.type === MessageType.Sms)
    .map((t) => ({ value: t.id || '', label: t.name || '' }));

  const modules = {
    toolbar: [['bold', 'italic', 'underline', 'strike'], [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }], ['clean']],
  };

  const messageTypes = Object.values(MessageType).map((c) => ({ value: c, label: c }));

  const isMessageValid = () => {
    if (type.value === MessageType.Email) {
      return selectedRecipients.length === 0 || !subject || !body || !template;
    } else {
      return selectedRecipients.length === 0 || !smsMessage || !template;
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-[1200] outline-none focus:outline-none">
        <div className="relative w-auto mx-auto max-w-5xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none p-6 max-h-screen md:max-h-[70vh] overflow-y-auto md:w-[480px] lg:w-[680px]">
            <div className="text-gray-900 text-lg leading-lg font-semibold mb-5">{template ? `Edit ${type.value} Template` : `Add ${type.value} Template`}</div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Recipients</div>
              <div className="mt-2">
                <ReactMultiSelect options={filtered} onChange={onRecipientChange} selectedValue={selectedRecipients} searchable disabled={recipientsDisabled} />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Type</div>
              <div className="mt-2">
                <ReactSelect options={messageTypes} onChange={setType} selectedValue={type} />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Template</div>
              <div className="mt-2">
                <ReactSelect options={type.value === MessageType.Email ? emailTemplates : smsTemplates} onChange={onSelectTemplate} selectedValue={template} />
              </div>
            </div>
            {type.value === MessageType.Email ? (
              <>
                <div className="mb-5">
                  <div className="text-sm font-medium text-gray-700 capitalize w-auto">Subject</div>
                  <div className="mt-2">
                    <Input value={subject} onChange={setSubject} />
                  </div>
                </div>
                <div className="mb-5">
                  <div className="text-sm font-medium text-gray-700 capitalize w-auto">Subject</div>
                  <div className="mt-2">
                    <ReactQuill theme="snow" value={body} onChange={setBody} modules={modules} style={{ height: '18em' }} />
                  </div>
                </div>
              </>
            ) : (
              <div className="mb-5">
                <div className="text-sm font-medium text-gray-700 capitalize w-auto">Subject</div>
                <div className="mt-2">
                  <TextArea value={smsMessage} onChange={setSmsMessage} limit={160} />
                </div>
              </div>
            )}
            <div className="flex justify-between items-center mt-16 md:mt-12">
              <button type="button" className="text-gray-500 font-semibold text-md leading-md" onClick={onClose}>
                Cancel
              </button>
              <button
                type="button"
                className="text-white disabled:opacity-50 bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
                onClick={onSend}
                disabled={isMessageValid()}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-gray-900" />
    </>
  );
}
