import { useState, useEffect, useMemo, useCallback } from 'react';
import Helmet from 'react-helmet';
import getTime from 'date-fns/getTime';
import DateRangePicker from 'components/DateRange';
import { useGeneric, GenericContextProps } from 'context/genericContext';
import { DateValueType } from 'react-tailwindcss-datepicker';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { AdjustmentsHorizontalIcon, ArrowUpTrayIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { SelectOption } from 'types/types';
import { SupportWorker, ConfirmationPracticesReport as ConfirmationPracticeReportType } from '__generated__/graphql';
import { Loading, Filters, Table } from 'components';
import { CSVLink } from 'react-csv';
import { format } from 'date-fns';
import questions from 'pages/ConfirmationPractices/questions.json';
import { useGetSupportWorkers } from 'api/hooks/useSupportWorkers';
import { useGetConfirmationPracticesReport } from 'api/hooks/useConfirmationPractices';
import { useSearchParams } from 'react-router-dom';
import Navigation from './Navigation';

type RowType = {
  id: number;
  createdDateTime: string | null;
  fullName: string;
  email: string;
  teamName: string;
  title?: string;
  question?: string;
  score: number;
  whyScore: string;
  focus: string;
  numberOfShifts: number;
};

export default function ConfirmationPracticesReport() {
  const { teams: genericTeams, teamsLoading } = useGeneric() as GenericContextProps;
  const [searchParams, setSearchParams] = useSearchParams();

  const teams = useMemo(() => {
    return genericTeams?.map((t) => ({ value: t.teamId, label: t.teamName })) || [];
  }, [genericTeams]);
  const {
    supportWorkers,
    loading: colleaguesLoading,
    error: colleaguesError,
  } = useGetSupportWorkers({
    showAll: true,
  });

  const { getConfirmationPracticesReport, loading, error, reports } = useGetConfirmationPracticesReport();
  const [selectedTeam, setTeam] = useState('all');
  const [dateRange, setDateRange] = useState<DateValueType>({
    startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
    endDate: new Date(),
  });

  const handleValueChange = (value: DateValueType) => {
    setDateRange(value);
  };
  const [selectedSupportWorker, setSelectedSupportWorker] = useState('all');
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
  const supportWorkerOptions = useMemo(() => {
    return (
      supportWorkers
        .filter((sw) => selectedTeam === 'all' || sw.teamId === selectedTeam)
        .map((s: SupportWorker) => {
          return { label: s.fullName, value: s.id };
        }) || []
    );
  }, [supportWorkers, selectedTeam]);

  const [filters, setFilters] = useState([
    { name: 'selectedTeam', selectedValue: { value: 'all', label: 'All' }, values: [{ value: 'all', label: 'All' }, ...teams] },
    { name: 'selectedSupportWorker', selectedValue: { value: 'all', label: 'All' }, values: [{ value: 'all', label: 'All' }, ...supportWorkerOptions] },
  ]);

  useEffect(() => {
    const teamSearch = searchParams.get('selectedTeam');
    const sWSearch = searchParams.get('selectedSupportWorker');
    const newFilters = filters;
    if (teamSearch) {
      setTeam(teamSearch);
      const teamOption = genericTeams?.map((t) => ({ value: t.teamId, label: t.teamName })).find((t) => t.value === teamSearch);
      if (teamOption) {
        newFilters[0].selectedValue = teamOption;
      }
    }
    if (sWSearch) {
      setSelectedSupportWorker(sWSearch);
      const statusOption = supportWorkerOptions.find((t) => t.value === sWSearch);
      if (statusOption) {
        newFilters[1].selectedValue = statusOption;
      }
    }
    setFilters(newFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genericTeams, supportWorkerOptions]);

  useEffect(() => {
    filters[0].values = [{ value: 'all', label: 'All' }, ...teams];

    filters[1].values = [{ value: 'all', label: 'All' }, ...supportWorkerOptions];

    setFilters(filters);
  }, [filters, supportWorkerOptions, teams]);

  const onFilterChange = (name: string, value: SelectOption) => {
    const newFilters = [...filters];
    const filterIndex = newFilters.findIndex((f) => f.name === name);
    if (name === 'selectedTeam') {
      newFilters[1].selectedValue = { value: 'all', label: 'All' };
      const supportWorkerFilterd = supportWorkerOptions.filter((sw) => {
        const fsw = supportWorkers?.find((s: SupportWorker) => s.id === sw.value);
        if (fsw?.teamId === value.value) {
          return sw;
        } else if (value.value === 'all') {
          return sw;
        } else {
          return null;
        }
      });
      newFilters[1].values = supportWorkerFilterd;
    }

    newFilters[filterIndex].selectedValue = value;

    setFilters(newFilters);
  };

  const filterByTeam = useCallback(
    (r: ConfirmationPracticeReportType) => {
      const sw = supportWorkers?.find((s: SupportWorker) => s.id === r.supportWorkerId);

      if (selectedTeam === 'all') {
        return true;
      } else if (sw?.teamId === selectedTeam) {
        return true;
      } else {
        return false;
      }
    },
    [supportWorkers, selectedTeam],
  );

  const filterBySupportWorker = useCallback(
    (r: ConfirmationPracticeReportType) => {
      if (selectedSupportWorker === 'all') {
        return true;
      } else if (selectedSupportWorker === r.supportWorkerId) {
        return true;
      } else {
        return false;
      }
    },
    [selectedSupportWorker],
  );

  const filteredReports = useCallback(() => {
    return reports?.filter((r: ConfirmationPracticeReportType) => filterByTeam(r)).filter((r: ConfirmationPracticeReportType) => filterBySupportWorker(r));
  }, [filterBySupportWorker, filterByTeam, reports]);

  const rows: RowType[] = filteredReports()?.map((r: ConfirmationPracticeReportType, id: number) => {
    const sw = supportWorkers?.find((s: SupportWorker) => s.id === r.supportWorkerId);
    const question = questions.find((q) => q.id === r.questionId);

    return {
      id,
      createdDateTime: r.createdDateTime ? format(new Date(parseFloat(r.createdDateTime)), 'dd LLLL yyyy') : null,
      fullName: sw?.fullName,
      email: sw?.email,
      teamName: sw?.teamName,
      title: question?.title,
      question: question?.question,
      score: r.score,
      whyScore: r.whyScore,
      focus: r.focus,
      numberOfShifts: r.numberOfShifts,
    };
  });

  const filterSummary = useMemo(() => {
    const swArray = rows.reduce((prev: RowType[], t: RowType) => {
      if (prev.find((e: RowType) => e.fullName === t.fullName)) {
        return prev;
      } else {
        prev.push(t);
      }
      return prev;
    }, []);
    const newFilterNumber = swArray?.length;
    const sw = supportWorkers?.find((s: SupportWorker) => s.id === selectedSupportWorker);

    if (selectedSupportWorker !== 'all') {
      return `${sw?.fullName} has completed ${newFilterNumber} Confirmation Practices`;
    } else {
      return `${newFilterNumber}/${supportWorkerOptions.length} support workers have completed their Confirmation Practices`;
    }
  }, [rows, supportWorkers, supportWorkerOptions, selectedSupportWorker]);

  const columnHelper = createColumnHelper<RowType>();

  const columns1 = useMemo(() => {
    return [
      columnHelper.accessor('createdDateTime', {
        header: () => 'Date',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('fullName', {
        header: () => 'Name',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('teamName', {
        header: () => 'Team',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('title', {
        header: () => 'Title',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('question', {
        header: () => 'Question',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('score', {
        header: () => 'Score',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('whyScore', {
        header: () => 'Why Score',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('focus', {
        header: () => 'Focus',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('numberOfShifts', {
        header: () => 'Number Of Shifts',
        cell: (info) => info.renderValue(),
        footer: (info) => info.column.id,
      }),
    ] as Array<ColumnDef<RowType, unknown>>;
  }, [columnHelper]);

  const onSaveFilters = () => {
    const newSearchParamsArray = filters.map((f) => ({ [f.name]: f.selectedValue.value }));
    setSearchParams(Object.assign({}, ...newSearchParamsArray));
    setTeam(filters[0].selectedValue.value);
    setSelectedSupportWorker(filters[1].selectedValue.value);
    setShowFiltersModal(false);
  };

  useEffect(() => {
    getConfirmationPracticesReport({
      variables: {
        query: {
          startDate: getTime(dateRange?.startDate ? new Date(dateRange.startDate) : 0),
          endDate: getTime(dateRange?.endDate ? new Date(dateRange.endDate) : 0),
        },
      },
    });
  }, [dateRange?.endDate, dateRange?.startDate, getConfirmationPracticesReport]);

  if (loading || error || colleaguesLoading || teamsLoading || colleaguesError) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Confirmation Practices Report</title>
      </Helmet>
      <Navigation report="Confirmation Practices Report" />
      <div className="my-10 px-4 md:px-[5%] 	">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-display-sm sm:text-display-lg leading-display-sm sm:leading-display-lg sm:tracking-display-lg text-black font-medium">
            Confirmation Practices Report
          </div>
        </div>
        {filterSummary && <p>{filterSummary}</p>}

        <div className="flex flex-col lg:flex-row items-center md:items-end justify-between mt-8 md:mt-16">
          <div className="flex flex-col md:flex-row items-center md:items-end gap-4 md:gap-9 w-full md:w-fit">
            <div className="flex flex-col sm:flex-row gap-3 items-center sm:items-end">
              <DateRangePicker value={dateRange} onChange={handleValueChange} />
            </div>
            <div className="flex flex-col md:flex-row items-center gap-3 w-full md:w-fit">
              <button
                data-cy="show-filters-button"
                type="button"
                className={`relative border ${
                  selectedTeam === 'all' ? 'border-gray-300' : 'border-gray-900'
                }  text-gray-700 text-md leading-md flex items-center px-4 py-2.5 rounded-lg shadow-xs justify-center w-full md:w-fit`}
                onClick={() => setShowFiltersModal(true)}
              >
                <AdjustmentsHorizontalIcon className="w-5 h-5 mr-2" />
                Filters
                {selectedTeam !== 'all' ||
                  (selectedSupportWorker !== 'all' && (
                    <span className="absolute bg-gray-900 h-5 w-5 text-white rounded-full -top-2 -right-2 flex items-center justify-center text-xs font-semibold">
                      {selectedTeam !== 'all' && selectedSupportWorker !== 'all' ? '2' : '1'}
                    </span>
                  ))}
              </button>

              {selectedTeam !== 'all' && (
                <div
                  data-cy="tag-2"
                  className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center"
                >
                  {teams?.find((type) => type.value === selectedTeam)?.label}
                  <button
                    data-cy="remove-tag-2"
                    type="button"
                    aria-label="remove team filter"
                    className="text-gray-500"
                    onClick={() => {
                      onFilterChange('selectedTeam', { value: 'all', label: 'All' });
                      const newSearchParamsArray = filters.map((f) => ({ [f.name]: f.selectedValue.value }));
                      setSearchParams(Object.assign({}, ...newSearchParamsArray));
                      setTeam('all');
                    }}
                  >
                    <XMarkIcon className="w-4 h-4 ml-2" />
                  </button>
                </div>
              )}
              {selectedSupportWorker !== 'all' && (
                <div
                  data-cy="tag-1"
                  className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center"
                >
                  {supportWorkers.find((type: SupportWorker) => type.id === selectedSupportWorker)?.fullName}
                  <button
                    data-cy="remove-tag-1"
                    type="button"
                    aria-label="remove support worker filter"
                    className="text-gray-500"
                    onClick={() => {
                      onFilterChange('selectedSupportWorker', { value: 'all', label: 'All' });
                      const newSearchParamsArray = filters.map((f) => ({ [f.name]: f.selectedValue.value }));
                      setSearchParams(Object.assign({}, ...newSearchParamsArray));
                      setSelectedSupportWorker('all');
                    }}
                  >
                    <XMarkIcon className="w-4 h-4 ml-2" />
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between lg:justify-normal gap-3 md:gap-9 w-full lg:w-fit mt-6 lg:mt-0">
            <CSVLink
              filename="confirmationPracticesReport.csv"
              data={rows}
              className="text-gray-500 text-sm leading-sm font-semibold flex items-center hover:no-underline"
            >
              <ArrowUpTrayIcon className="w-5 h-5 mr-2" />
              Export CSV
            </CSVLink>
          </div>
        </div>
        <div className="mt-3 md:mt-12 mb-10 overflow-x-auto overflow-y-hidden">
          {rows && rows.length > 0 && <Table<RowType> data={rows} columns={columns1} />}
        </div>
      </div>
      {showFiltersModal && (
        <Filters filters={filters} onFilterChange={onFilterChange} onCloseModal={() => setShowFiltersModal(false)} onSaveFilters={onSaveFilters} />
      )}
    </>
  );
}
