import { Cake, Sick, BeachAccess } from '@mui/icons-material';
import { Absence, SupportWorker } from '__generated__/graphql';
import { differenceInCalendarDays, format } from 'date-fns';
import stc from 'string-to-color';

interface DayCellProps {
  day: Date;
  leave: Absence[];
  supportWorkers: SupportWorker[];
}

const DayCell = ({ day, leave, supportWorkers }: DayCellProps) => {
  return (
    <>
      <div className="flex flex-row xl:hidden">
        <div className="w-1/2">
          <div className="px-2 h-full border-t border-gray-100 flex items-center justify-center gap-2 text-md md:text-lg text-gray-800 font-semibold text-center">
            {format(day, 'EEEE dd/MM')}
          </div>
        </div>
        <div className="w-1/2">
          <div className="flex flex-col xl:w-[13%] border-t xl:border-t-0 border-l border-gray-100">
            <div className="relative">
              {leave.map((l) => {
                const supportWorker = supportWorkers.find((sw) => sw.id === l.supportWorkerId);
                const totalLeaveDays = differenceInCalendarDays(l.returnDate ?? 0, l.absenceDate ?? 0) + 1;
                const leaveDay = differenceInCalendarDays(day, l.absenceDate ?? 0) + 1;
                const isLeaveLessThanADay = !l.absenceReason?.includes('Birthday') && l.duration && l.duration < 5;
                return (
                  <div className="text-white px-2 py-2 border-b border-white" style={{ backgroundColor: stc(supportWorker?.fullName) }}>
                    <div className="font-semibold text-lg leading-lg">{supportWorker?.fullName}</div>

                    {isLeaveLessThanADay ? (
                      <div className="text-md leading-md font-medium">
                        <div>
                          {l.duration} hour{l.duration && l.duration > 1 && 's'}
                        </div>
                        <div className="text-md leading-md font-medium flex flex-row items-center justify-between">
                          {l.description}
                          {l.absenceReason?.includes('Birthday') ? <Cake /> : l.absenceReason?.includes('Sick') ? <Sick /> : <BeachAccess />}
                        </div>
                      </div>
                    ) : (
                      <div className="text-md leading-md font-medium flex flex-row items-center justify-between">
                        Day {leaveDay}/{totalLeaveDays}
                        {l.absenceReason?.includes('Birthday') ? <Cake /> : l.absenceReason?.includes('Sick') ? <Sick /> : <BeachAccess />}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="hidden xl:flex flex-col xl:w-[13%] border-t xl:border-t-0 border-l border-gray-100">
        <div className="relative">
          {leave.map((l) => {
            const supportWorker = supportWorkers.find((sw) => sw.id === l.supportWorkerId);
            const totalLeaveDays = differenceInCalendarDays(l.returnDate ?? 0, l.absenceDate ?? 0) + 1;
            const leaveDay = differenceInCalendarDays(day, l.absenceDate ?? 0) + 1;
            const isLeaveLessThanADay =
              !l.absenceReason?.includes('Birthday') && !l.absenceReason?.includes('Kings Coronation') && l.duration && l.duration < 5;
            return (
              <div className="text-white px-2 py-2 border-b border-white" style={{ backgroundColor: stc(supportWorker?.fullName) }}>
                <div className="font-semibold text-lg leading-lg">{supportWorker?.fullName}</div>
                {isLeaveLessThanADay ? (
                  <div className="text-md leading-md font-medium">
                    <div>
                      {l.duration} hour{l.duration && l.duration > 1 && 's'}
                    </div>
                    <div className="text-md leading-md font-medium flex flex-row items-center justify-between">
                      {l.description}
                      {l.absenceReason?.includes('Birthday') ? <Cake /> : l.absenceReason?.includes('Sick') ? <Sick /> : <BeachAccess />}
                    </div>
                  </div>
                ) : (
                  <div className="text-md leading-md font-medium flex flex-row items-center justify-between">
                    Day {leaveDay}/{totalLeaveDays}
                    {l.absenceReason?.includes('Birthday') ? <Cake /> : l.absenceReason?.includes('Sick') ? <Sick /> : <BeachAccess />}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default DayCell;
