import { Controller, useFormContext } from 'react-hook-form';

interface TextAreaFieldProps {
  label: string;
  questions?: string[];
  placeholder: string;
  name: string;
  disabled?: boolean;
  validate?: (value: string) => boolean | string;
}

const TextAreaFieldWithDescription = ({ label, placeholder, questions, name, disabled = false, validate }: TextAreaFieldProps) => {
  const { control, register } = useFormContext(); // retrieve all hook methods

  return (
    <>
      <div className="flex flex-col md:flex-col items-start gap-3 md:gap-7">
        <div>
          <div className=" text-gray-700 text-md leading-sm font-semibold">{label}</div>
          <ul className="ps-5  py-2 space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
            {questions?.map((question) => {
              return (
                <li key={question} className="text-primary-500 font-semibold">
                  {question}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="w-full">
          <Controller
            name={name}
            control={control}
            rules={{ required: 'This is required.', validate }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <>
                  <textarea
                    ref={register(name).ref}
                    rows={12}
                    disabled={disabled}
                    value={value}
                    onChange={(event) => (onChange ? onChange(event.target.value) : null)}
                    // eslint-disable-next-line max-len
                    className="bg-white px-5 py-2  disabled:bg-slate-50 disabled:text-slate-500 rounded-lg text-md leading-md text-gray-700 placeholder:text-gray-500 border border-gray-300 shadow-xs w-full"
                    placeholder={placeholder ?? ''}
                    data-cy={name}
                  />

                  <p className="mt-2 text-sm text-error-600 dark:text-green-500">
                    <span className="font-medium">{error?.message}</span>
                  </p>
                </>
              );
            }}
          />
        </div>
      </div>
      <div className="h-px w-full bg-gray-100 my-6" />
    </>
  );
};

export { TextAreaFieldWithDescription };
