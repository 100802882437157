/* eslint-disable react/no-danger */
import Edit from '@mui/icons-material/Edit';
import { format } from 'date-fns';
import { CustomerNote, Maybe } from '__generated__/graphql';
import TextArea from 'components/TextArea';
import DatePicker from 'components/DatePicker';

interface WellbeingBoardNoteProps {
  note: Maybe<CustomerNote>;
  onEditNote: (note: Maybe<CustomerNote>) => void;
  index: number;
}

const WellbeingBoardNote = ({ note, onEditNote, index }: WellbeingBoardNoteProps) => {
  const noteDate = typeof note?.date === 'number' ? format(note?.date, 'd MMMM yyyy') : null;
  return (
    <div key={index} className="border border-gray-200 rounded-lg px-4 py-3 mb-3">
      <div className="flex flex-row justify-between items-center">
        <div className="hidden md:flex flex-col md:flex-row items-start gap-4 mt-2">
          <div className="shrink-0 w-full md:w-fit">
            <div className="text-gray-800 text-lg leading-lg font-medium mb-1.5">Date</div>
            <DatePicker value={noteDate ? new Date(noteDate) : null} disabled={true} />
          </div>
        </div>
      </div>
      <div className="md:hidden flex flex-col sm:flex-row gap-3 mt-3">
        <div className="flex flex-col md:flex-row items-start gap-4 mt-4">
          <div className="shrink-0 w-full md:w-fit">
            <div className="text-gray-800 text-lg leading-lg font-medium mb-1.5">Date</div>
            <DatePicker testId="wellbeing-board-note-date" value={noteDate ? new Date(noteDate) : null} disabled={true} />
          </div>
        </div>
      </div>

      <div className="w-full mt-3">
        <div className="text-gray-800 text-lg leading-lg font-medium mb-1">Note</div>
        <TextArea testId="wellbeing-board-note-note" value={note?.note || ''} disabled={true} />
      </div>
      <button
        type="button"
        onClick={() => onEditNote(note)}
        className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center gap-2 mt-3"
        data-cy={`wellbeing-board-review-${index}-edit-button`}
      >
        <Edit className="!w-5 !h-5" />
        Edit
      </button>
    </div>
  );
};

export default WellbeingBoardNote;
