import React from 'react';
import { useNavigate } from 'react-router-dom';
import format from 'date-fns/format';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TeamIcon from '@mui/icons-material/Diversity3';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { Maybe, Rota as RotaType, Team } from '__generated__/graphql';
import { User } from 'types/types';
import useGetTeams from 'api/hooks/useGetTeams';
import Loading from './Loading';
import Error from './Error';
import TopNavButton from './TopNavButton';

interface SelectRotaProps {
  toggleCreateShiftModal: (inputValue: boolean) => void;
  toggleCreateActivityModal: (inputValue: boolean) => void;
  user: User;
  showAddShift: boolean;
}

const getRotaColour = (user: User, rotaId: string) => {
  if (user.isGuest) {
    return 'error';
  }
  if (user?.rota?.data?.current?.id === rotaId) {
    return 'secondary';
  }
  if (user.rota.data?.future?.find((r) => r?.id === rotaId)) {
    return 'primary';
  }
  if (user.rota.data?.previous?.find((r) => r?.id === rotaId)) {
    return 'warning';
  }
  return 'error';
};

const SelectRota = ({ user, toggleCreateShiftModal, toggleCreateActivityModal, showAddShift }: SelectRotaProps) => {
  const navigate = useNavigate();
  const { teams, teamsLoading, error } = useGetTeams();

  const [teamEl, setTeamEl] = React.useState<null | HTMLElement>(null);
  const [rotaEl, setRotaEl] = React.useState<null | HTMLElement>(null);
  const onSelectTeam = (event: React.MouseEvent<HTMLElement>) => setTeamEl(event.currentTarget);
  const onSelectRota = (event: React.MouseEvent<HTMLElement>) => setRotaEl(event.currentTarget);
  const openTeamMenu = Boolean(teamEl);
  const openRotaMenu = Boolean(rotaEl);
  const closeTeamMenu = () => setTeamEl(null);
  const closeRotaMenu = () => setRotaEl(null);

  const rotaId = localStorage.getItem('rotaId') || '';
  const startDate = localStorage.getItem('startRotaDateTime') || '';
  const endDate = localStorage.getItem('endRotaDateTime') || '';
  const team = localStorage.getItem('teamName') || '';

  const selectTeam = (teamId: string, teamName: string) => {
    localStorage.setItem('teamId', teamId);
    localStorage.setItem('teamName', teamName);
    localStorage.removeItem('rotaId');
    localStorage.removeItem('startRotaDateTime');
    localStorage.removeItem('endRotaDateTime');
    navigate(0);
  };

  const selectRota = (selectedRota: RotaType) => {
    localStorage.setItem('rotaId', selectedRota.id || '');
    localStorage.setItem('startRotaDateTime', selectedRota.startRotaDateTime?.toString() || '');
    localStorage.setItem('endRotaDateTime', selectedRota.endRotaDateTime?.toString() || '');
    navigate(0);
  };

  const rotas = [...(user?.rota?.data?.future ?? []), user?.rota?.data?.current, ...(user?.rota?.data?.previous?.slice(0, 5) ?? [])].filter((x) => x);

  if (teamsLoading) return <Loading />;
  if (error) return <Error />;

  const colour = getRotaColour(user, rotaId);

  return (
    <Box>
      <Card variant="outlined">
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={10} sm={10} md={11}>
              <Button data-cy="team-selector" sx={{ margin: '0.4em' }} color={colour} onClick={onSelectTeam} variant="contained" startIcon={<TeamIcon />}>
                {team}
              </Button>
              {rotaId && startDate && (
                <Button
                  data-cy="rota-selector"
                  sx={{ margin: '0.4em' }}
                  color={colour}
                  onClick={onSelectRota}
                  variant="contained"
                  startIcon={<DateRangeIcon />}
                >
                  {format(parseInt(startDate, 10), 'PP')} - {format(parseInt(endDate, 10), 'PP')}
                </Button>
              )}
              {!rotaId && !startDate && (
                <Button data-cy="rota-selector" sx={{ margin: '0.4em' }} color="error" onClick={onSelectRota} variant="contained" startIcon={<DateRangeIcon />}>
                  Select a Rota
                </Button>
              )}
              <Menu
                anchorEl={teamEl}
                open={openTeamMenu}
                onClose={closeTeamMenu}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflowY: 'scroll',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                {teams.map((t: Team, i: number) => (
                  <MenuItem data-cy={`team-item-${i}`} key={t.teamId} value={t.teamId} onClick={() => selectTeam(t.teamId, t.teamName)}>
                    {t.teamName}
                  </MenuItem>
                ))}
              </Menu>
              <Menu
                anchorEl={rotaEl}
                open={openRotaMenu}
                onClose={closeRotaMenu}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                {rotas.map((r: Maybe<RotaType> | undefined, i: number) => {
                  if (r) {
                    return (
                      <MenuItem data-cy={`rota-item-${i}`} key={r.id || ''} value={r.id || ''} onClick={() => selectRota(r)} selected={rotaId === r.id}>
                        {r.startRotaDateTime ? format(r.startRotaDateTime, 'PP') : ''} - {r.endRotaDateTime ? format(r.endRotaDateTime, 'PP') : ''}
                      </MenuItem>
                    );
                  } else {
                    return null;
                  }
                })}
              </Menu>
            </Grid>
            {showAddShift && (
              <Grid item xs={2} sm={1} md={1}>
                <TopNavButton user={user} toggleCreateShiftModal={toggleCreateShiftModal} toggleCreateActivityModal={toggleCreateActivityModal} />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default SelectRota;
