import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Invoice, ThriveBooking } from '__generated__/graphql';
import { useGetBookings } from 'api/hooks/useThriveBookings';
import { Table, Loading } from 'components';
import { columnVisibility, ThriveBookingChecklistItem } from './types';
import { MapInvoice } from './Mapper';

interface InvoiceThriveBookingsProps {
  invoice: Invoice | undefined;
  onSubmit: (invoice: Invoice) => void;
}

export default function InvoiceThriveBookings({ invoice, onSubmit }: InvoiceThriveBookingsProps) {
  const [rowSelection, setRowSelection] = useState<ThriveBookingChecklistItem[]>([]);

  const { bookings, loading } = useGetBookings({
    startDateTime: invoice?.payrollStartDate,
    endDateTime: invoice?.payrollEndDate,
  });

  const setChecked = (b: ThriveBooking, inv?: Invoice) => {
    return inv?.thriveBookingItems?.some((i) => i?.id === b.id && i?.included) || false;
  };

  useEffect(() => {
    if (bookings?.length > 0) {
      const checked = bookings
        .filter((b) => b.status === 'ACTIVE')
        .map((b) => ({
          id: b.id,
          checked: setChecked(b, invoice),
          status: b.status,
          startDateTime: b.startDateTime,
          endDateTime: b.endDateTime,
          customerName: `${b.customer?.firstName} ${b.customer?.surname}`,
          partnerName: `${b.partner?.firstName} ${b.partner?.surname}`,
          price: b.partnerPrice?.memberPrice,
          product: b.product?.name,
        }));
      setRowSelection(checked);
    }
  }, [bookings, invoice]);

  const onSaveBookings = () => {
    onSubmit(
      MapInvoice({
        ...invoice,
        thriveBookingItems: rowSelection
          .filter((r) => r.checked)
          .map((r) => ({
            id: r.id,
            included: r.checked,
            startDateTime: r.startDateTime,
            endDateTime: r.endDateTime,
            customerName: r.customerName,
            partnerName: r.partnerName,
            price: r.price,
            product: r.product,
          })),
      }),
    );
  };

  const onSelectItem = (id: string) => {
    setRowSelection(rowSelection.map((item) => (item.id === id ? { ...item, checked: !item.checked } : item)));
  };

  const RowActions = ({ row }: { row: ThriveBookingChecklistItem }) => {
    // if (row?.type === 'rota' && row?.status === 'active') return null;
    return (
      <input
        onChange={(e) => onSelectItem(e.target.value)}
        checked={row.checked}
        type="checkbox"
        className="w-4 h-4 text-primary-300 bg-gray-100 border-gray-300 rounded cursor-pointer"
        value={row.id || ''}
      />
    );
  };

  const columnHelper = createColumnHelper<ThriveBookingChecklistItem>();

  const columns1 = [
    columnHelper.accessor('id', {
      enableSorting: false,
      header: () => '#',
      cell: (info) => <div data-cy="list-index">{info.row.index + 1}</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('startDateTime', {
      enableSorting: false,
      header: () => 'Date',
      cell: (info) => <div data-cy="list-start-date">{format(info.renderValue() ?? 0, 'PPPP')}</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('endDateTime', {
      enableSorting: false,
      header: () => 'Time',
      cell: (info) => (
        <div data-cy="list-end-date">
          {format(info.row.original.startDateTime ?? 0, 'HH:mm')} - {format(info.renderValue() ?? 0, 'HH:mm')}
        </div>
      ),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('customerName', {
      enableSorting: false,
      header: () => 'Customer',
      cell: (info) => <div data-cy="list-customer">{info.renderValue()}</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('partnerName', {
      enableSorting: false,
      header: () => 'Partner',
      cell: (info) => <div data-cy="list-partner">{info.renderValue()}</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('product', {
      enableSorting: false,
      header: () => 'Product',
      cell: (info) => {
        return <div data-cy="list-product">{info.renderValue()}</div>;
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('price', {
      enableSorting: false,
      header: () => 'Price',
      cell: (info) => {
        return <div data-cy="list-price">{info.renderValue()}</div>;
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (props) => <RowActions row={props.row.original} />,
      header: () => (
        <button type="button" onClick={onSaveBookings} className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
          Save
        </button>
      ),
    }),
  ] as Array<ColumnDef<ThriveBookingChecklistItem, unknown>>;

  return (
    <div className="mt-8">
      <div className="flex flex-col mt-2">
        {loading && <Loading isComponent />}
        {!loading && rowSelection && rowSelection.length > 0 && (
          <Table<ThriveBookingChecklistItem> pageSize={500} data={rowSelection} columns={columns1} columnVisibility={columnVisibility} />
        )}
        {!loading && (!rowSelection || (rowSelection.length === 0 && <div>There are no thrive bookings to consider.</div>))}
      </div>
    </div>
  );
}
