import { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Group } from '@mui/icons-material';
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';
import { useGetExpenses, useSaveExpenses } from 'api/hooks/useExpense';
import { LocationBar, Filters, Loading, Table, Search, Message } from 'components';
import { Expense } from '__generated__/graphql';
import { formatTime, getPeriodsForYear } from 'services/helpers';
import { SelectOption } from 'types/types';
import { toTitleCase } from 'services/strings';
import ImportExpenses from './ImportExpenses';

export default function ExpenseList() {
  const periods = getPeriodsForYear().map((period) => {
    return { label: formatTime(period, 'MMMM, yyyy'), value: period.toString() };
  });
  const expensesStatus = [
    { label: 'All', value: 'All' },
    { label: 'Approved', value: 'approved' },
    { label: 'Rejected', value: 'rejected' },
    { label: 'Submitted', value: 'submitted' },
  ];

  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const periodFromStorage = localStorage.getItem('invoice-filter-date');
  const { saveExpenses, mutationSaveExpenses } = useSaveExpenses({ period: parseFloat(periodFromStorage || '0') });

  const filterDateFromStroage = periods.find((p) => p.value === periodFromStorage) || periods[0];
  const [filters, setFilters] = useState([
    { name: 'period', selectedValue: filterDateFromStroage, values: periods },
    { name: 'status', selectedValue: { label: 'All', value: 'All' }, values: expensesStatus },
  ]);
  const [search, setSearch] = useState('');
  const [period, setPeriod] = useState<string>(filterDateFromStroage.value);
  const [status, setStatus] = useState<string>('All');
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
  const { expenses, loading } = useGetExpenses({ period: parseFloat(periodFromStorage || '0') });
  const [openImportExpensesModal, setOpenImportExpensesModal] = useState(false);

  const columnVisibility = {
    dateUpdated: window.innerWidth > 768,
    name: true,
    subject: window.innerWidth > 640,
    type: true,
  };

  useEffect(() => {
    setFilters(filters);
  }, [filters]);

  const onSaveFilters = () => {
    setPeriod(filters[0].selectedValue.value);
    setStatus(filters[1].selectedValue.value);
    setShowFiltersModal(false);
  };

  const onSaveExpenses = async (p: number) => {
    setOpenSnack(true);
    saveExpenses({ variables: { input: { period: p } } });
    setOpenImportExpensesModal(false);
    setTimeout(() => {
      setOpenSnack(false);
    }, 5000);
  };

  const onFilterChange = (name: string, value: SelectOption) => {
    const newFilters = [...filters];
    const filterIndex = newFilters.findIndex((f) => f.name === name);
    newFilters[filterIndex].selectedValue = value;
    setFilters(newFilters);

    if (name === 'period') {
      localStorage.setItem('invoice-filter-date', value.value);
    }
  };

  if (loading || mutationSaveExpenses.loading) {
    return <Loading />;
  }

  const columnHelper = createColumnHelper<Expense>();

  const columns1 = [
    columnHelper.accessor('employeeFullName', {
      header: () => 'Support Worker',
      cell: (info) => {
        return <div data-cy="list-fullname">{info.renderValue()}</div>;
      },
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.accessor('teamName', {
      header: () => 'Team',
      cell: (info) => <div data-cy="list-teamName">{info.renderValue()}</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('status', {
      header: () => 'Status',
      cell: (info) => <div data-cy="list-status">{toTitleCase(info.renderValue() || '')}</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('totalAmount', {
      header: () => 'Total Ammount',
      cell: (info) => <div data-cy="list-totalAmount">£{info.renderValue()?.toFixed(2)}</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('expenseDate', {
      header: () => 'Expense Date',
      cell: (info) => <div data-cy="list-totalAmount">{info.renderValue()}</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('comment', {
      header: () => 'Comment',
      cell: (info) => <div data-cy="list-comment">{info.renderValue()}</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('description', {
      header: () => 'Description',
      cell: (info) => <div data-cy="list-comment">{info.renderValue()}</div>,
      footer: (info) => info.column.id,
    }),
  ] as Array<ColumnDef<Expense, unknown>>;

  const filterByName = (expense: Expense) => {
    if (expense?.employeeFullName?.toLowerCase().includes(search.toLowerCase())) return true;
    return false;
  };

  const filterByStatus = (expense: Expense) => {
    if (status === 'All') return true;
    if (expense?.status?.toLowerCase() === status.toLowerCase()) return true;
    return false;
  };

  const rows = expenses?.filter((expense: Expense) => filterByName(expense)).filter((expense: Expense) => filterByStatus(expense));
  const sorted = [...(rows || [])].sort((x, y) => (y?.payrollStartDate || 0) - (x?.payrollStartDate || 0));

  return (
    <>
      <Helmet>
        <title>Expenses</title>
      </Helmet>
      <LocationBar section="Invoices" page="Expenses List" Icon={Group} />
      <Message response={[mutationSaveExpenses]} openSnack={openSnack} setOpenSnack={setOpenSnack} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-row justify-between items-center">
          <div className="text-display-sm sm:text-display-lg leading-display-sm sm:leading-display-lg sm:tracking-display-lg text-black font-medium">
            Expenses
          </div>
          <div className="hidden md:flex items-center gap-3 mt-4 sm:mt-0">
            <button
              type="button"
              data-cy="create-invoice-button"
              className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md hidden md:block"
              onClick={() => setOpenImportExpensesModal(true)}
            >
              Import Expenses
            </button>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-between mt-8 md:mt-16">
          <div className="flex flex-col md:flex-row items-center gap-4 md:gap-9 w-full md:w-fit">
            <Search value={search} onSearch={setSearch} />
            <button
              data-cy="show-filters-button"
              type="button"
              className={`relative border ${
                period === '0' ? 'border-gray-300' : 'border-gray-900'
              }  text-gray-700 text-md leading-md flex items-center px-4 py-2.5 rounded-lg shadow-xs justify-center w-full md:w-fit`}
              onClick={() => setShowFiltersModal(true)}
            >
              <AdjustmentsHorizontalIcon className="w-5 h-5 mr-2" />
              Filters
              {period !== '0' && (
                <span className="absolute bg-gray-900 h-5 w-5 text-white rounded-full -top-2 -right-2 flex items-center justify-center text-xs font-semibold">
                  {period !== '0' ? '2' : '1'}
                </span>
              )}
            </button>
            {period !== '0' && (
              <div
                data-cy="tag-1"
                className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center"
              >
                {periods.find((type) => type.value === period)?.label}
              </div>
            )}
            {status !== 'All' && (
              <div
                data-cy="tag-1"
                className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center"
              >
                {expensesStatus.find((type) => type.value.toLowerCase() === status.toLowerCase())?.label}
              </div>
            )}
          </div>
        </div>
        <div className="mt-3 md:mt-8 mb-10">
          {sorted && sorted.length > 0 && <Table<Expense> data={sorted} columns={columns1} columnVisibility={columnVisibility} />}
        </div>
      </div>
      {showFiltersModal && (
        <Filters
          filters={filters}
          onFilterChange={onFilterChange}
          onCloseModal={() => setShowFiltersModal(false)}
          onSaveFilters={onSaveFilters}
          showClearFilters={false}
        />
      )}
      {openImportExpensesModal && <ImportExpenses onSaveExpenses={onSaveExpenses} onClose={() => setOpenImportExpensesModal(false)} />}
    </>
  );
}
