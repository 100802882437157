import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Loading } from 'components';

interface RemoveActivityProps {
  removeActivityVisitModalState: boolean;
  toggleRemoveActivityModal: (removeActivityVisitModalState: boolean) => void;
  onDeleteActivity: (item: null) => void;
  apiLoading: boolean;
}

export default function RemoveActivity({ toggleRemoveActivityModal, removeActivityVisitModalState, onDeleteActivity, apiLoading }: RemoveActivityProps) {
  return (
    <Dialog open={removeActivityVisitModalState} onClose={() => toggleRemoveActivityModal(false)}>
      <DialogTitle sx={{ fontSize: '1.6em', padding: { xs: '0.8em', sm: '1.2em' } }}>Remove Activity</DialogTitle>
      <DialogContent>Are you sure you would like to remove this activity?</DialogContent>
      <DialogActions sx={{ padding: '1em' }}>
        <Button variant="contained" onClick={() => toggleRemoveActivityModal(false)}>
          Close
        </Button>
        {apiLoading ? (
          <Loading isComponent />
        ) : (
          <Button variant="contained" onClick={() => onDeleteActivity(null)}>
            Remove Activity
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
