import { getTextColor } from 'services/helpers';
import stc from 'string-to-color';
import { AgreedShift, AgreedShiftRun, AgreedShiftRunWeeks, AgreedVisitType, Maybe, PlaceholderVisit, SavePlaceholderVisitInput } from '__generated__/graphql';
import { differenceInHours, differenceInMinutes } from 'date-fns';
import { useState } from 'react';
import { AddCircle } from '@mui/icons-material';
import { useRemovePlaceholderVisit, useSavePlaceholderVisit } from 'api/hooks/usePlaceholderVisits';
import { Loading } from 'components';
import Tooltip from 'components/Tooltip';
import Visit from './Visit';
import AddPlaceholderVisit from './AddPlaceholderVisit';
import PHVisit from './PlaceholderVisit';
import { createDateWithTime, doDateRangesOverlap } from '../utils';

interface DayCellProps {
  agreedShifts: AgreedShiftRunWeeks;
  placeholderVisits: PlaceholderVisit[];
  shift: AgreedShift;
  startDateTime: number;
  endDateTime: number;
  week: number;
}

const DayCell = ({ agreedShifts, placeholderVisits, shift, startDateTime, endDateTime, week }: DayCellProps) => {
  const [addPlaceholderVisitModalState, toggleAddPlaceholderVisitModal] = useState(false);
  const [isAbleToAdd, setIsAbleToAdd] = useState(true);
  const [placeHolderVisitEdit, setPlaceholderVisitEdit] = useState<PlaceholderVisit | null>(null);
  const [conflictedVisits, setConflictedVisits] = useState<{ customer: string; startDate: number; endDate: number }[]>([]);
  const numberOfHoursInShift = differenceInHours(new Date(endDateTime), new Date(startDateTime));
  const numberOfMinutesRemainingInShift = differenceInMinutes(new Date(endDateTime), new Date(startDateTime)) - numberOfHoursInShift * 60;
  const { savePlaceholderVisit, savePlaceholderVisitLoading } = useSavePlaceholderVisit({ agreedRotaId: shift.agreedRotaId, teamId: shift.teamId });
  const { removePlaceholderVisit, removePlaceholderVisitLoading } = useRemovePlaceholderVisit({ agreedRotaId: shift.agreedRotaId, teamId: shift.teamId });

  const getExactVisitDate = (visitStartTime: number, visitEndTime: number) => {
    const startDate = createDateWithTime(startDateTime, visitStartTime);
    const endDate = createDateWithTime(startDateTime, visitEndTime);
    const daysToAdd = week === 1 ? shift.day : shift.day + 7;

    startDate.setDate(startDate.getDate() + daysToAdd);
    endDate.setDate(endDate.getDate() + daysToAdd);

    return { startDate, endDate };
  };

  const checkIfVisitCanBeAdded = (edit: boolean, visitId: string, startTime: number, endTime: number) => {
    let isVisitCanBeAdded = true;
    const newConflictedVisits: { customer: string; startDate: number; endDate: number }[] = [];
    const visitStartDate = createDateWithTime(Date.now(), startTime);
    const visitEndDate = createDateWithTime(Date.now(), endTime);

    [agreedShifts.week1, agreedShifts.week2].forEach((w) => {
      w?.forEach((shiftRun: Maybe<AgreedShiftRun>) => {
        const matchingShift = shiftRun?.shifts?.find((s: Maybe<AgreedShift>) => s?.id === shift.id);

        if (matchingShift) {
          matchingShift?.visits?.forEach((visit: Maybe<AgreedVisitType>) => {
            const startDate = createDateWithTime(Date.now(), visit?.startDateTime ?? 0);
            const endDate = createDateWithTime(Date.now(), visit?.endDateTime ?? 0);

            if (((edit && visit?.id !== visitId) || !edit) && doDateRangesOverlap(visitStartDate, startDate, visitEndDate, endDate)) {
              if (visit) {
                newConflictedVisits.push({
                  customer: `${visit.customer?.firstName} ${visit.customer?.lastName}`,
                  startDate: visit.startDateTime ?? 0,
                  endDate: visit.endDateTime ?? 0,
                });
              }
              isVisitCanBeAdded = false;
            }
          });
        }
      });
    });
    placeholderVisits.forEach((visit) => {
      const startDate = createDateWithTime(Date.now(), visit?.startDate ?? 0);
      const endDate = createDateWithTime(Date.now(), visit?.endDate ?? 0);
      const isOverlap =
        visit?.agreedShiftRunId === shift.agreedShiftRunId && visit.day === shift.day && doDateRangesOverlap(visitStartDate, startDate, visitEndDate, endDate);

      if (isOverlap && (edit ? visit?.id !== visitId : true)) {
        if (visit) {
          newConflictedVisits.push({
            customer: visit.name ?? '',
            startDate: visit.startDate ?? 0,
            endDate: visit.endDate ?? 0,
          });
        }
        isVisitCanBeAdded = false;
      }
    });
    setConflictedVisits(newConflictedVisits);
    return isVisitCanBeAdded;
  };

  const onAddVisit = (input: SavePlaceholderVisitInput) => {
    const { startDate, endDate } = getExactVisitDate(input.startDate ?? 0, input.endDate ?? 0);
    if (checkIfVisitCanBeAdded(!!input.id, input.id ?? '', input.startDate ?? 0, input.endDate ?? 0)) {
      savePlaceholderVisit({
        variables: {
          input: {
            ...input,
            startDate: startDate.getTime(),
            endDate: endDate.getTime(),
            teamId: shift.teamId,
            agreedRotaId: shift.agreedRotaId,
            agreedShiftRunId: shift.agreedShiftRunId,
            day: shift.day,
          },
        },
      });
      toggleAddPlaceholderVisitModal(false);
    } else {
      setIsAbleToAdd(false);
    }
  };

  const onRemoveVisit = (id: string) => {
    removePlaceholderVisit({
      variables: {
        input: {
          teamId: shift.teamId,
          agreedRotaId: shift.agreedRotaId,
          id,
        },
      },
    });
    toggleAddPlaceholderVisitModal(false);
  };

  if (savePlaceholderVisitLoading || removePlaceholderVisitLoading) {
    return <Loading />;
  }

  const shiftHeight = numberOfHoursInShift * 60 + numberOfMinutesRemainingInShift + 40;

  return (
    <div className="flex flex-col xl:w-[13%] border-t xl:border-t-0 border-l border-gray-100" style={{ height: shiftHeight }}>
      {shift.fullName !== '' && (
        <>
          <div
            className="flex items-center justify-between h-10 p-2"
            style={{ backgroundColor: shift.fullName === 'TBC' ? '#D32F2F' : shift.fullName !== '' ? stc(shift.fullName) : '#ffffff' }}
          >
            <div className="text-md leading-xs font-semibold" style={{ color: shift.fullName === 'TBC' ? 'white' : getTextColor(stc(shift.fullName)) }}>
              {shift.fullName}
            </div>

            <Tooltip tooltip="Add Placeholder Visit" right>
              <button type="button" aria-label="Add Placeholder Visit" onClick={() => toggleAddPlaceholderVisitModal(true)}>
                <AddCircle style={{ color: shift.fullName === 'TBC' ? 'white' : getTextColor(stc(shift.fullName)) }} />
              </button>
            </Tooltip>
          </div>
          <div className="relative">
            {shift.visits?.map((visit: Maybe<AgreedVisitType>, index: number) => {
              if (visit) {
                return <Visit visit={visit} startDateTime={startDateTime} index={index} day={shift.day} key={index} />;
              } else {
                return null;
              }
            })}
            {placeholderVisits?.map((visit: PlaceholderVisit, index: number) => {
              if (visit.agreedShiftRunId === shift.agreedShiftRunId && visit.day === shift.day) {
                return (
                  <PHVisit
                    visit={visit}
                    startDateTime={startDateTime}
                    index={index}
                    key={index}
                    onEdit={() => {
                      setPlaceholderVisitEdit(visit);
                      toggleAddPlaceholderVisitModal(true);
                    }}
                  />
                );
              } else {
                return null;
              }
            })}
            {[...Array(numberOfHoursInShift)].map((_, index: number) => {
              return <div key={index} className="bg-success-400 opacity-40 border-b border-gray-100 h-[60px]" />;
            })}
            {numberOfMinutesRemainingInShift > 0 && (
              <div className="bg-success-200 border-b border-gray-100" style={{ height: numberOfMinutesRemainingInShift }} />
            )}
          </div>
        </>
      )}
      {addPlaceholderVisitModalState && (
        <AddPlaceholderVisit
          placeHolderVisit={placeHolderVisitEdit}
          startDateTime={startDateTime}
          endDateTime={endDateTime}
          isAbleToAdd={isAbleToAdd}
          conflictedVisits={conflictedVisits}
          setIsAbleToAdd={setIsAbleToAdd}
          onClose={() => {
            setIsAbleToAdd(true);
            toggleAddPlaceholderVisitModal(false);
            setPlaceholderVisitEdit(null);
          }}
          onAdd={onAddVisit}
          onRemove={onRemoveVisit}
        />
      )}
    </div>
  );
};

export default DayCell;
