import { Tooltip } from '@mui/material';
import { useRef } from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import { getHoursAndMinutes, msToTime } from 'services/helpers';
import { AgreedVisitType } from '__generated__/graphql';
import { createDateWithTime } from '../utils';

interface VisitProps {
  visit: AgreedVisitType;
  startDateTime: number;
  agreedShiftRunId: string;
  agreedShiftId: string;
  isDuplicateVisit: boolean;
  onEditVisit: (visit: AgreedVisitType) => void;
  isDisable: boolean;
  index: number;
  day: number;
  isOptimised?: boolean;
}

const Visit = ({
  visit,
  startDateTime,
  agreedShiftRunId,
  agreedShiftId,
  isDuplicateVisit,
  onEditVisit,
  isDisable,
  index,
  day,
  isOptimised = false,
}: VisitProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [, dragRef] = useDrag({
    type: 'visit',
    item: { visit, agreedShiftRunId, agreedShiftId },
    collect: (
      monitor: DragSourceMonitor<{
        visit: AgreedVisitType;
        agreedShiftRunId: string;
        agreedShiftId: string;
      }>,
    ) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const runStartDate = createDateWithTime(Date.now(), startDateTime);
  const visitStartDate = createDateWithTime(Date.now(), visit.startDateTime ?? Date.now());
  const visitEndDate = createDateWithTime(Date.now(), visit.endDateTime ?? Date.now());
  const startTimeDifference = Math.abs(visitStartDate.getTime() - runStartDate.getTime());
  const visitDuration = Math.abs(visitEndDate.getTime() - visitStartDate.getTime());
  const { hours: startHours, minutes: startMinutes } = msToTime(startTimeDifference);
  const { hours: endHours, minutes: endMinutes } = msToTime(visitDuration);

  const visitPosition = (hours: number, minutes: number) => {
    return hours * 60 + minutes;
  };

  const renderVisit = () => (
    <div
      style={{ top: visitPosition(startHours, startMinutes), height: visitPosition(endHours, endMinutes) - 2 }}
      className={`absolute left-0 right-0 ${
        isDuplicateVisit ? 'bg-red-700' : isOptimised ? 'bg-warning-800' : 'bg-primary-600'
      } overflow-hidden z-10 px-2 py-1 ${isDisable ? 'cursor-default' : 'cursor-pointer'}`}
      key={index}
      ref={dragRef}
      onClick={() => {
        if (!isDisable) onEditVisit(visit);
      }}
      data-cy={`shift${day}-visit${index}-edit-visit-button`}
    >
      <div className="text-md leading-md text-white font-medium" data-cy={`shift${day}-visit${index}-customer`}>
        {visit?.customer?.firstName} {visit?.customer?.lastName}
      </div>
      <div className="text-sm leading-sm text-white" data-cy={`shift${day}-visit${index}-time`}>
        {visit.startDateTime ? getHoursAndMinutes(visit.startDateTime) : ''} - {visit.endDateTime ? getHoursAndMinutes(visit.endDateTime) : ''}
      </div>
    </div>
  );

  return (
    <div ref={ref}>
      {visitPosition(endHours, endMinutes) < 60 ? (
        <Tooltip
          title={`${visit?.customer?.firstName} ${visit?.customer?.lastName}: ${visit.startDateTime ? getHoursAndMinutes(visit.startDateTime) : ''} - ${
            visit.endDateTime ? getHoursAndMinutes(visit.endDateTime) : ''
          }`}
          style={{ flex: 1 }}
        >
          {renderVisit()}
        </Tooltip>
      ) : (
        renderVisit()
      )}
    </div>
  );
};

export default Visit;
