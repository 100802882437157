import React, { useState, useEffect } from 'react';
import { getTime } from 'date-fns';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { User } from 'types/types';
import { ShiftRunTypeEnum, ShiftType } from 'pages/ReportingHours/service';
import { formatTime, getAmOrPmStartShiftTime, setTimeOnDate } from 'services/helpers';
import { Shift, SupportWorker } from '__generated__/graphql';
import { useGetSupportWorkers } from 'api/hooks/useSupportWorkers';
import { useGetDayShiftsForTeam } from 'api/hooks/useShifts';
import { Loading } from 'components';
import TeamSelector from '../../../../components/TeamSelector';

interface MoveVisitToNewShiftProps {
  selectedShift: Shift | null;
  user: User;
  showTeam: boolean;
  onChangeSw: (sw: SupportWorker | null) => void;
  title: string;
  onClose: () => void;
  onMoveVisitToFixedShift: (shiftType: string, shiftStartDateTime: number) => void;
  onMoveVisitToNewFlexiShift: (shiftType: string) => void;
  apiLoading?: boolean;
}

export default function MoveVisitToNewShift({
  selectedShift,
  user,
  onChangeSw,
  showTeam,
  title,
  onClose,
  onMoveVisitToFixedShift,
  onMoveVisitToNewFlexiShift,
  apiLoading,
}: MoveVisitToNewShiftProps) {
  const [validation, setValidation] = useState<string | null>(null);
  const [supportWorker, setSupportWorker] = useState<SupportWorker | null>(null);
  const [team, setTeam] = useState<string>(selectedShift?.secondarySupportWorker?.teamId ? selectedShift?.secondarySupportWorker?.teamId : user?.teamId);
  const [shiftStartDateTime, setShiftStartDateTime] = useState<Date | null>(
    setTimeOnDate(new Date(selectedShift?.startDateTime || 0), getAmOrPmStartShiftTime(selectedShift?.startDateTime || 0), 0),
  );
  const [newShiftType, setNewShiftType] = useState<string | null>('visit');

  const { ShiftsForTeam } = useGetDayShiftsForTeam({
    teamId: selectedShift?.teamId,
    rotaId: user?.rota?.id,
    shiftId: selectedShift?.shiftId,
    visitId: selectedShift?.id,
    teamIdToMoveTo: team,
  });

  const sws = ShiftsForTeam.map((sw) => sw.supportWorker?.id);

  useEffect(() => {
    if (selectedShift) {
      setSupportWorker(selectedShift?.secondarySupportWorker || null);
    }
  }, [selectedShift]);

  const { supportWorkers } = useGetSupportWorkers({
    teamId: team || selectedShift?.teamId,
    rotaId: user?.rota?.id,
  });

  const changeSw = (sw: SupportWorker | null) => {
    if (sw?.id) {
      setSupportWorker(sw);
      onChangeSw(sw);
      setValidation(null);
    }
  };

  const onChangeShiftStartDate = (time: Date | null) => {
    if (time) {
      setShiftStartDateTime(time);
      setValidation(null);
    }
  };

  const onChangeShiftType = (shiftType: string | null) => {
    setNewShiftType(shiftType);
    setValidation(null);
  };

  const hightlightIfSelected = (sw: SupportWorker) => supportWorker?.id === sw?.id;

  const moveVisitToFixedShift = (visit: Shift | null, type: string, date: number) => {
    if (visit && type === ShiftRunTypeEnum.agreed) {
      if (visit?.startDateTime < date) {
        setValidation('Shift start date should begin earlier than the visit start date');
      } else {
        onMoveVisitToFixedShift(type, date);
        setValidation(null);
      }
    }
  };

  return (
    <>
      <h1 className="text-lg font-bold">{title}</h1>
      {validation && <div className="mb-4 mt-4 text-md text-error-600 font-medium">{validation}</div>}
      <div className="mt-2">
        Customer: {selectedShift?.customer?.firstName} {selectedShift?.customer?.lastName}
      </div>
      <div>
        Date: {formatTime(selectedShift?.startDateTime || 0, 'PPP HH:mm:aaa')} - {formatTime(selectedShift?.endDateTime || 0, 'HH:mm:aaa')}
      </div>
      <Typography sx={{ p: '1em', mt: '1em', mb: '1em', backgroundColor: 'red', color: 'white' }}>
        NOTE: Please move this visit to an existing agreed shift if one is available.
      </Typography>
      <FormControl sx={{ width: '100%', mb: '1em', mt: '1em', ml: '-0.4em' }}>
        <div className="text-sm font-bold p-2 text-gray-700 capitalize w-auto">What type of shift</div>
        <RadioGroup sx={{ ml: '1.2em' }} row name="isToBeRescheduled" value={newShiftType} onChange={(e) => onChangeShiftType(e.target.value)}>
          <FormControlLabel value="visit" control={<Radio />} label="5hr Fixed Shift" />
          <FormControlLabel value="oneoff" control={<Radio />} label="Banked Hours Shift" />
        </RadioGroup>
      </FormControl>
      {newShiftType === 'visit' && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <FormControl sx={{ width: '100%', mb: '1em' }}>
            <div className="text-sm font-bold p-2 text-gray-700 capitalize w-auto">Start Time</div>
            <TimePicker value={shiftStartDateTime} ampm={false} minutesStep={5} onChange={onChangeShiftStartDate} />
          </FormControl>
        </LocalizationProvider>
      )}
      {showTeam && (
        <div className="mt-2 mb-8">
          <TeamSelector selectTeam={setTeam} teamId={team} startDateTime={selectedShift?.startDateTime} />
        </div>
      )}
      <Grid container spacing={2} sx={{ padding: { sm: '1em' }, width: '100%' }}>
        {supportWorkers.length === 0 ? (
          <>
            <Grid item xs={5} md={5} />
            <Grid item xs={5} md={5}>
              <CircularProgress />
            </Grid>
          </>
        ) : (
          supportWorkers
            .filter((sw) => !sws.includes(sw.id))
            .map((sw: SupportWorker, i: number) => (
              <Grid
                data-cy={`sw-item-${i}`}
                key={`sw${i}`}
                item
                xs={5}
                sm={3}
                md={2.4}
                p={1}
                mb={2}
                ml={{ xs: '1em', sm: '0em' }}
                sx={{
                  color: hightlightIfSelected(sw) ? '#fff' : '',
                  bgcolor: hightlightIfSelected(sw) ? 'primary.main' : '',
                  borderRadius: 2,
                  cursor: 'pointer',
                }}
                onClick={() => changeSw(sw)}
              >
                <Tooltip title={sw.fullName}>
                  <Avatar
                    sx={{
                      marginBottom: '0.2em',
                      cursor: 'pointer',
                      width: { xs: '2.5em', sm: '3.5em' },
                      height: { xs: '2.5em', sm: '3.5em' },
                      border: hightlightIfSelected(sw) ? '3px solid' : '',
                    }}
                    alt={sw.fullName}
                    src={sw.avatar}
                  />
                </Tooltip>
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: 16,
                        sm: 18,
                        md: 20,
                      },
                    }}
                  >
                    {sw.fullName}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      display: {
                        xs: 'none',
                        md: 'block',
                      },
                    }}
                  >
                    {sw.contractedHours} hours per week
                  </Typography>
                </Grid>
              </Grid>
            ))
        )}
      </Grid>
      {apiLoading ? (
        <Loading isComponent />
      ) : (
        <div className="flex">
          <div className="mr-2 flex-none w-20">
            <Button data-cy="close-sw-button" variant="contained" onClick={onClose} disabled={apiLoading}>
              Close
            </Button>
          </div>
          <div className="mr-2 flex-initial w-64">
            {newShiftType === ShiftType.oneoff && (
              <Button
                data-cy="assign-sw-button"
                disabled={!supportWorker?.id || apiLoading}
                variant="contained"
                onClick={() => onMoveVisitToNewFlexiShift(ShiftRunTypeEnum.oneoff)}
              >
                Create new Banked Hours Shift
              </Button>
            )}
            {newShiftType === ShiftType.visit && (
              <Button
                data-cy="assign-sw-button"
                disabled={!supportWorker?.id || apiLoading}
                variant="contained"
                color="secondary"
                onClick={() => moveVisitToFixedShift(selectedShift, ShiftRunTypeEnum.agreed, getTime(shiftStartDateTime || 0))}
              >
                Create new Fixed Shift
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
}
