import { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { CSVLink } from 'react-csv';
import { Group } from '@mui/icons-material';
import { PencilIcon, AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';
import { useGetCustomerInvoices } from 'api/hooks/useInvoice';
import { LocationBar, Filters, Loading, Table, Search } from 'components';
import { Invoice, InvoiceCsvItem } from '__generated__/graphql';
import { formatTime, getPeriodsForYear } from 'services/helpers';
import { SelectOption } from 'types/types';
import InvoiceCreateAll from './InvoiceCreateAll';

export default function InvoiceList() {
  const navigate = useNavigate();
  const periods = getPeriodsForYear().map((period) => {
    return { label: formatTime(period, 'MMMM, yyyy'), value: period.toString() };
  });
  const invoiceTypes = [
    { label: 'All', value: 'All' },
    { label: 'Care', value: 'Care' },
    { label: 'Thrive', value: 'Thrive' },
  ];

  const periodFromStorage = localStorage.getItem('invoice-filter-date');
  const filterDateFromStroage = periods.find((p) => p.value === periodFromStorage) || periods[0];
  const [filters, setFilters] = useState([
    { name: 'period', selectedValue: filterDateFromStroage, values: periods },
    { name: 'type', selectedValue: { label: 'All', value: 'All' }, values: invoiceTypes },
  ]);
  const [search, setSearch] = useState('');
  const [period, setPeriod] = useState<string>(filterDateFromStroage.value);
  const [invoiceType, setInvoiceType] = useState<string>('All');
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
  const { GetCustomerInvoices, loading, invoices } = useGetCustomerInvoices();
  const [openCreateAllInvoiceModal, setOpenCreateAllInvoiceModal] = useState(false);

  useEffect(() => {
    GetCustomerInvoices({
      variables: {
        skip: !period,
        query: {
          date: parseInt(period, 10),
        },
      },
    });
  }, [period, GetCustomerInvoices]);

  const columnVisibility = {
    dateUpdated: window.innerWidth > 768,
    name: true,
    subject: window.innerWidth > 640,
    type: true,
  };

  useEffect(() => {
    setFilters(filters);
  }, [filters]);

  const onSaveFilters = () => {
    setPeriod(filters[0].selectedValue.value);
    setInvoiceType(filters[1].selectedValue.value);
    setShowFiltersModal(false);
  };

  const onFilterChange = (name: string, value: SelectOption) => {
    const newFilters = [...filters];
    const filterIndex = newFilters.findIndex((f) => f.name === name);
    newFilters[filterIndex].selectedValue = value;
    setFilters(newFilters);

    if (name === 'period') {
      localStorage.setItem('invoice-filter-date', value.value);
    }
  };

  if (loading) {
    return <Loading />;
  }

  const edit = (t: Invoice) => {
    if (t) {
      navigate(`/developer/invoices/${t.indexKey}/${t?.teamId}/${t?.customerId}`);
    }
  };

  const createInvoice = () => {
    navigate('/developer/invoices/create');
  };

  const RowActions = ({ row }: { row: Invoice }) => {
    return (
      <div className="flex items-center gap-3">
        <button data-cy="list-edit-button" type="button" onClick={() => edit(row)} aria-label="Edit">
          <PencilIcon className="w-6 h-6 text-gray-500 hover:text-primary-600" />
        </button>
      </div>
    );
  };

  const columnHelper = createColumnHelper<Invoice>();

  const columns1 = [
    columnHelper.accessor('name', {
      header: () => 'Owner',
      cell: (info) => {
        return <div data-cy="list-name">{info.renderValue()}</div>;
      },
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.accessor('invoiceNumber', {
      header: () => 'Invoice Number',
      cell: (info) => <div data-cy="list-invoiceNumber">INV-{info.renderValue() ?? 0}</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('invoiceType', {
      header: () => 'Invoice Type',
      cell: (info) => <div data-cy="list-invoiceType">{info.renderValue()}</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('invoiceDate', {
      header: () => 'Invoice Date',
      cell: (info) => <div data-cy="list-invoiceDate">{format(info.renderValue() ?? 0, 'dd/MM/yyyy')}</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('customerPackage', {
      header: () => 'Funding Source',
      cell: (info) => <div data-cy="list-invoiceDate">{info.renderValue()?.fundingSource}</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (props) => <RowActions row={props.row.original} />,
    }),
  ] as Array<ColumnDef<Invoice, unknown>>;

  const filterByName = (invoice: Invoice) => {
    if (invoice?.name?.toLowerCase().includes(search.toLowerCase())) return true;
    return false;
  };

  const filterByInvoiceType = (invoice: Invoice) => {
    if (invoiceType === 'All') return true;
    if (invoice?.invoiceType?.toLowerCase() === invoiceType.toLowerCase()) return true;
    return false;
  };

  const rows = invoices.filter((invoice: Invoice) => filterByName(invoice)).filter((invoice: Invoice) => filterByInvoiceType(invoice));
  const sorted = [...(rows || [])].sort((x, y) => (y?.invoiceDate || 0) - (x?.invoiceDate || 0));

  const createItem = (data: InvoiceCsvItem, invoice: Invoice) => {
    return {
      ContactName: invoice?.name,
      EmailAddress: '',
      POAddressLine1: '',
      POAddressLine2: '',
      POAddressLine3: '',
      POAddressLine4: '',
      POCity: '',
      PORegion: '',
      POPostalCode: '',
      POCountry: '',
      InvoiceNumber: data?.InvoiceNumber,
      Reference: '',
      InvoiceDate: data.InvoiceDate,
      DueDate: data.DueDate,
      Total: data?.Total,
      Description: data?.Description,
      Quantity: data?.Quantity,
      UnitAmount: data?.UnitAmount,
      Discount: '',
      TaxAmount: '',
      TrackingName1: data?.TrackingName1,
      TrackingOption1: data?.TrackingOption1,
    };
  };

  const getCsv = () => {
    const data = [] as InvoiceCsvItem[];
    sorted.forEach((item) => {
      if (item.care) {
        data.push(createItem(item.care, item));
      }
      if (item.cancelledVisits) {
        data.push(createItem(item.cancelledVisits, item));
      }
      if (item.oneOffVisits) {
        data.push(createItem(item.oneOffVisits, item));
      }
      if (item.mileage) {
        data.push(createItem(item.mileage, item));
      }
      if (item.thrive) {
        data.push(createItem(item.thrive, item));
      }
      if (item.thriveVat) {
        data.push(createItem(item.thriveVat, item));
      }
      if (item.proRata) {
        data.push(createItem(item.proRata, item));
      }
      if (item.expenses) {
        data.push(createItem(item.expenses, item));
      }
    });

    return data;
  };

  const csv = getCsv();

  return (
    <>
      <Helmet>
        <title>Invoices</title>
      </Helmet>
      <LocationBar section="Invoices" page="Invoices List" Icon={Group} />
      {/* <Message response={[mutationSaveInvoice]} openSnack={openSnack} setOpenSnack={setOpenSnack} /> */}
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-row justify-between items-center">
          <div className="text-display-sm sm:text-display-lg leading-display-sm sm:leading-display-lg sm:tracking-display-lg text-black font-medium">
            Invoices
          </div>
          <div className="hidden md:flex items-center gap-3 mt-4 sm:mt-0">
            <CSVLink
              filename={`invoices${Date.now()}.csv`}
              data={csv}
              className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md hidden md:block"
            >
              Export CSV
            </CSVLink>
            <button
              type="button"
              data-cy="create-invoice-button"
              className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md hidden md:block"
              onClick={() => createInvoice()}
            >
              Create Invoice
            </button>
            <button
              type="button"
              data-cy="create-invoice-button"
              className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md hidden md:block"
              onClick={() => setOpenCreateAllInvoiceModal(true)}
            >
              Create Invoice For All
            </button>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-between mt-8 md:mt-16">
          <div className="flex flex-col md:flex-row items-center gap-4 md:gap-9 w-full md:w-fit">
            <Search value={search} onSearch={setSearch} />
            <button
              data-cy="show-filters-button"
              type="button"
              className={`relative border ${
                period === '0' ? 'border-gray-300' : 'border-gray-900'
              }  text-gray-700 text-md leading-md flex items-center px-4 py-2.5 rounded-lg shadow-xs justify-center w-full md:w-fit`}
              onClick={() => setShowFiltersModal(true)}
            >
              <AdjustmentsHorizontalIcon className="w-5 h-5 mr-2" />
              Filters
              {period !== '0' && (
                <span className="absolute bg-gray-900 h-5 w-5 text-white rounded-full -top-2 -right-2 flex items-center justify-center text-xs font-semibold">
                  {period !== '0' ? '2' : '1'}
                </span>
              )}
            </button>
            {period !== '0' && (
              <div
                data-cy="tag-1"
                className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center"
              >
                {periods.find((type) => type.value === period)?.label}
              </div>
            )}
            {invoiceType !== 'All' && (
              <div
                data-cy="tag-1"
                className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center"
              >
                {invoiceTypes.find((type) => type.value === invoiceType)?.label}
              </div>
            )}
          </div>
        </div>
        <div className="mt-3 md:mt-8 mb-10">
          {sorted && sorted.length > 0 && <Table<Invoice> data={sorted} columns={columns1} columnVisibility={columnVisibility} />}
        </div>
      </div>
      {showFiltersModal && (
        <Filters
          filters={filters}
          onFilterChange={onFilterChange}
          onCloseModal={() => setShowFiltersModal(false)}
          onSaveFilters={onSaveFilters}
          showClearFilters={false}
        />
      )}
      {openCreateAllInvoiceModal && <InvoiceCreateAll onClose={() => setOpenCreateAllInvoiceModal(false)} />}
    </>
  );
}
