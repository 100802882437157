import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { User } from 'types/types';
import { formatTime } from 'services/helpers';
import { Shift, ShiftRun } from '__generated__/graphql';
import { useGetDayShiftsForTeam } from 'api/hooks/useShifts';
import { Loading } from 'components';
import { VisitStatus } from 'pages/ReportingHours/service';
import { shiftExceedsMaximumLength } from 'pages/TeamShifts/service';
import TeamSelector from '../../../../components/TeamSelector';

interface MoveVisitToAgreedShiftProps {
  shifts: Shift[];
  setSelectedShift: (shift: ShiftRun | null) => void;
  selectedShift: ShiftRun | null;
  user: User;
  visit: Shift | null;
  title: string;
  onClose: () => void;
  onMoveVisitToAgreedShift: () => void;
  onSetVisitToTbc: () => void;
  onSetVisitToOwner: () => void;
  apiLoading?: boolean;
}

export default function MoveVisitToAgreedShift({
  shifts,
  selectedShift,
  user,
  title,
  visit,
  setSelectedShift,
  onClose,
  onMoveVisitToAgreedShift,
  onSetVisitToTbc,
  onSetVisitToOwner,
  apiLoading,
}: MoveVisitToAgreedShiftProps) {
  const [team, setTeam] = useState<string>(user?.teamId);

  const { ShiftsForTeam, loading } = useGetDayShiftsForTeam({
    teamId: visit?.teamId,
    rotaId: user?.rota?.id,
    shiftId: visit?.shiftId,
    visitId: visit?.id,
    teamIdToMoveTo: team,
  });

  const changeTeam = (teamId: string | null) => {
    if (teamId) {
      setTeam(teamId);
    }
  };

  const hightlightIfSelected = (s: ShiftRun) => selectedShift?.id === s?.id;

  const isShiftFull = (sr: ShiftRun) => {
    if (sr && visit) {
      const shiftsForRun = shifts.filter((s: Shift) => s.shiftId === sr?.id);
      const newShift = [...shiftsForRun, { ...visit, id: '', visitId: '', status: VisitStatus.active, teamId: sr?.teamId || '' }].sort(
        (x, y) => (x?.startDateTime || 0) - (y?.startDateTime || 0),
      );

      return !shiftExceedsMaximumLength(newShift);
    }
  };

  return (
    <>
      <h1 className="text-lg font-bold">{title}</h1>
      <div className="mt-2">
        Customer: {visit?.customer?.firstName} {visit?.customer?.lastName}
      </div>
      <div className="mt-1">
        Date: {formatTime(visit?.startDateTime || 0, 'PPP HH:mm:aaa')} - {formatTime(visit?.endDateTime || 0, 'HH:mm:aaa')}
      </div>
      <div className="mt-4">
        <TeamSelector selectTeam={changeTeam} teamId={team} />
      </div>
      {ShiftsForTeam.length === 0 && (
        <Typography sx={{ mt: '2em', mb: '2em' }}>There are no shifts available to move this visit. Please create a new shift.</Typography>
      )}
      <Typography sx={{ p: '1em', mt: '2em', mb: '2em', backgroundColor: 'red', color: 'white' }}>
        If you own a shift below please move the visit to your shift by selecting it, and click `Move Visit`.
      </Typography>
      <Grid container spacing={2} sx={{ padding: { sm: '1em' }, width: '100%' }}>
        {loading ? (
          <>
            <Grid item xs={5} md={5} />
            <Grid item xs={5} md={5}>
              <CircularProgress />
            </Grid>
          </>
        ) : (
          ShiftsForTeam?.filter((s) => isShiftFull(s)).map((sh: ShiftRun, i: number) => (
            <Grid
              data-cy={`sw-item-${i}`}
              key={`sw${i}`}
              item
              xs={12}
              sm={8}
              md={3}
              p={1}
              ml={{ xs: '1em', sm: '0em' }}
              mr={{ xs: '1em', sm: '1em' }}
              mt={3}
              mb={2}
              sx={{
                color: hightlightIfSelected(sh) ? '#fff' : '',
                bgcolor: hightlightIfSelected(sh) ? 'primary.main' : '',
                border: user.profile === sh.supportWorker?.id ? '3px solid' : '',
                borderRadius: 2,
                cursor: 'pointer',
              }}
              onClick={() => setSelectedShift(sh)}
            >
              <div className="flex">
                <div className="mr-2 flex-none w-20">
                  <Tooltip title={sh?.supportWorker?.fullName}>
                    <Avatar
                      sx={{
                        mb: '0.6em',
                        cursor: 'pointer',
                        width: { xs: '2.5em', sm: '3.5em' },
                        height: { xs: '2.5em', sm: '3.5em' },
                        border: user.profile === sh.supportWorker?.id ? 'solid black 2px' : '',
                      }}
                      alt={sh?.supportWorker?.fullName}
                      src={sh?.supportWorker?.avatar}
                    />
                  </Tooltip>
                </div>
                <div className="mr-2 flex-initial w-30">
                  {user.profile === sh.supportWorker?.id && (
                    <Typography sx={{ fontSize: '0.9em', p: '0.4em', color: 'white', backgroundColor: 'red' }}>You own this shift, select it.</Typography>
                  )}
                </div>
              </div>
              <Grid item>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 16,
                      sm: 18,
                      md: 20,
                    },
                  }}
                >
                  {sh?.supportWorker?.fullName}
                </Typography>
                <Typography>{formatTime(sh?.startDateTime || 0, 'PP')}</Typography>
                <Typography>
                  {formatTime(sh?.startDateTime || 0, 'HH:mm:aaa')} - {formatTime(sh?.endDateTime || 0, 'HH:mm:aaa')}
                </Typography>
                {sh?.warning && <Typography sx={{ color: sh?.warning === 'I am the original owner' ? 'yellow' : '' }}>{sh?.warning}</Typography>}
              </Grid>
            </Grid>
          ))
        )}
      </Grid>
      {apiLoading ? (
        <Loading isComponent />
      ) : (
        <div className="flex">
          <div className="mr-2 flex-none w-20">
            <Button data-cy="close-sw-button" variant="contained" onClick={onClose} disabled={apiLoading}>
              Close
            </Button>
          </div>
          <div className="mr-2 flex-initial w-30">
            <Button data-cy="movex-sw-button" disabled={!visit || apiLoading} variant="contained" onClick={() => onMoveVisitToAgreedShift()}>
              Move Visit
            </Button>
          </div>
          {visit?.secondarySupportWorker && visit?.supportWorker && (
            <div className="mr-2 flex-initial w-30">
              <Button data-cy="assign-sw-button" disabled={!visit || apiLoading} variant="contained" onClick={() => onSetVisitToTbc()}>
                Assign to TBC
              </Button>
            </div>
          )}
          {!visit?.secondarySupportWorker && visit?.supportWorker && (
            <div className="mr-2 flex-initial w-30">
              <Button data-cy="assign-sw-button" disabled={!visit || apiLoading} variant="contained" onClick={() => onSetVisitToOwner()}>
                Assign to shift owner
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
}
