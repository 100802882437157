import DatePicker from 'components/DatePicker';
import ReactSelect from 'components/Select';
import TextArea from 'components/TextArea';
import { CheckCircleIcon, TrashIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { getReviewType } from 'pages/WellbeingBoard/utils';
import { CustomerReview, Maybe } from '__generated__/graphql';
import { Edit } from '@mui/icons-material';
import { CustomerReviewData } from 'pages/WellbeingBoard/types';
import { AscotButtons } from './AscotButtons';

const WellbeingBoardReview = ({
  number,
  review,
  onEditReview,
  teamId,
  onDeleteModalOpen,
}: {
  number: number;
  onEditReview: (note: Maybe<CustomerReview>) => void;
  onDeleteModalOpen: (id: string) => void;
  review: CustomerReviewData;
  teamId: string;
}) => {
  return (
    <div key={number} className="border border-gray-200 rounded-lg px-4 py-3 mb-3">
      <div className="flex flex-row justify-between items-center">
        <div className="text-primary-700 text-xl leading-xl font-semibold">{getReviewType(number)}</div>
      </div>

      <div className="flex flex-col md:flex-row items-start gap-4 mt-4">
        <div className="shrink-0 w-full md:w-fit">
          <div className="text-gray-800 text-lg leading-lg font-medium mb-1.5">Date</div>
          <DatePicker value={new Date(review.date)} disabled={true} maxDate={new Date()} />
        </div>
        <div className="w-full md:w-fit">
          <div className="text-gray-800 text-lg leading-lg font-medium mb-1">Requested By</div>
          <ReactSelect
            selectedValue={{ value: review?.requestedBy || '', label: review?.requestedBy || '' }}
            options={[
              { label: 'Bellevie', value: 'Bellevie' },
              { label: 'Family member', value: 'Family member' },
              { label: 'Social Worker', value: 'Social Worker' },
              { label: 'Other', value: 'Other' },
            ]}
            disabled={true}
          />
        </div>

        {review.ascotReviewId && (
          <div>
            <div className="text-gray-800 text-lg leading-lg font-medium mb-1">ASCOT score</div>
            <div className="text-lg leading-lg text-gray-800 font-medium mt-3">{review.ascotReviewTotal}</div>
          </div>
        )}
        {review.ascotExpectedOutcomeId && (
          <div>
            <div className="text-gray-800 text-lg leading-lg font-medium mb-1">ASCOT EXPECTED OUTCOME score</div>
            <div className="text-lg leading-lg text-gray-800 font-medium mt-3">{review.ascotExpectedOutcomeTotal}</div>
          </div>
        )}
      </div>
      <div className="w-full mt-3">
        <div className="text-gray-800 text-lg leading-lg font-medium mb-1">Note</div>
        <TextArea value={review?.note} disabled={true} />
      </div>
      <div className="flex flex-row gap-3 justify-between mt-3">
        <div className="flex flex-row gap-2">
          <button
            type="button"
            onClick={() => onDeleteModalOpen(review.id)}
            data-cy={`wellbeing-board-review-${number}-delete-button`}
            className="hidden
            md:flex text-white bg-error-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md  items-center gap-2"
          >
            <TrashIcon className="!w-5 !h-5" />
            Delete
          </button>
          <button
            type="button"
            onClick={() => onEditReview(review)}
            className="hidden
            md:flex text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md  items-center gap-2"
            data-cy={`wellbeing-board-review-${number}-edit-button`}
          >
            <Edit className="!w-5 !h-5" />
            Edit
          </button>
          <div className="hidden md:flex gap-3">
            <AscotButtons reviewId={review.id} ascotReviewId={review?.ascotReviewId} ascotExpectedOutcomeId={review?.ascotExpectedOutcomeId} teamId={teamId} />
          </div>
        </div>
        <div
          className="hidden
            md:flex flex-row items-center	gap-2"
        >
          <div className="text-gray-800 text-lg leading-lg font-medium">ASCOT complete</div>
          <div>{review?.ascotReviewId ? <CheckCircleIcon className="text-success-600 w-10 h-10" /> : <XCircleIcon className="text-error-600 w-10 h-10" />}</div>
        </div>
        <div
          className="hidden
            md:flex flex-row items-center	gap-2"
        >
          <div className="text-gray-800 text-lg leading-lg font-medium">ASCOT expected outcome complete</div>
          <div>
            {review?.ascotExpectedOutcomeId ? <CheckCircleIcon className="text-success-600 w-10 h-10" /> : <XCircleIcon className="text-error-600 w-10 h-10" />}
          </div>
        </div>
      </div>
      <div className="md:hidden flex flex-col sm:flex-row gap-3 mt-3">
        <div className="flex flex-row items-center	gap-2">
          <div className="text-gray-800 text-lg leading-lg font-medium ">ASCOT complete</div>
          <div>{review?.ascotReviewId ? <CheckCircleIcon className="text-success-600 w-10 h-10" /> : <XCircleIcon className="text-error-600 w-10 h-10" />}</div>
        </div>
        <div className="flex flex-row items-center	gap-2">
          <div className="text-gray-800 text-lg leading-lg font-medium ">ASCOT expected outcome complete</div>
          <div>
            {review?.ascotExpectedOutcomeId ? <CheckCircleIcon className="text-success-600 w-10 h-10" /> : <XCircleIcon className="text-error-600 w-10 h-10" />}
          </div>
        </div>
        <button
          type="button"
          onClick={() => onDeleteModalOpen(review.id)}
          data-cy={`wellbeing-board-review-${number}-delete-button`}
          className="text-white bg-error-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center gap-2"
        >
          <TrashIcon className="!w-5 !h-5" />
          Delete
        </button>
        <button
          type="button"
          onClick={() => onEditReview(review)}
          className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center gap-2"
          data-cy={`wellbeing-board-review-${number}-edit-button`}
        >
          <Edit className="!w-5 !h-5" />
          Edit
        </button>

        <AscotButtons reviewId={review.id} ascotReviewId={review?.ascotReviewId} ascotExpectedOutcomeId={review?.ascotExpectedOutcomeId} teamId={teamId} />
      </div>
    </div>
  );
};

export { WellbeingBoardReview };
