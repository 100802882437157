import { getStoryblokApi } from '@storyblok/react';
import { useState, useEffect } from 'react';
import { SectionStoryblok } from 'types/components-sb';

const useGetPolicies = () => {
  const storyblokApi = getStoryblokApi();
  const [policies, setPolicies] = useState<SectionStoryblok[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    async function getPolicies() {
      setLoading(true);
      try {
        const { data } = await storyblokApi.get('cdn/stories/policies', {
          version: 'draft',
        });
        setPolicies(data.story.content.sections || []);
        setLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        setError(e.message);
        setPolicies([]);
        setLoading(false);
      }
    }

    if (!policies) {
      getPolicies();
    }
  }, [policies, storyblokApi]);

  return { policies, loading, error };
};

export { useGetPolicies };
