import { useState, useEffect } from 'react';
import { Customer as CustomerType, CustomerUpdate } from '__generated__/graphql';
import SupportWorkerSelect from 'components/SupportWorkerSelect';
import TWModal from 'components/TWModal';

interface AssignLinkWorkerProps {
  customer: CustomerType;
  onClose: () => void;
  onSaveCustomer: (data: Partial<CustomerUpdate>) => void;
  selectedTeam: string;
}

export default function AssignLinkWorker({ customer, onClose, onSaveCustomer, selectedTeam }: AssignLinkWorkerProps) {
  const [linkWorkers, setLinkWorkers] = useState<string[]>([]);

  useEffect(() => {
    const selectedLinkWorkers: string[] = [];
    customer?.linkWorkers?.forEach((lw) => {
      if (lw?.id) selectedLinkWorkers.push(lw.id);
    });
    setLinkWorkers(selectedLinkWorkers);
  }, [customer]);

  const onUpdateLinkWorker = () => {
    onSaveCustomer({ id: customer.id, linkWorkers });
    onClose();
  };

  const onClickLinkWorker = (id: string) => {
    if (linkWorkers.includes(id)) {
      const workers = linkWorkers?.filter((lw) => lw !== id);
      setLinkWorkers(workers);
    } else {
      setLinkWorkers([...linkWorkers, id]);
    }
  };

  return (
    <TWModal title="Assign Link Worker" onClose={onClose} onSave={onUpdateLinkWorker} testId="save-link-workers-button">
      <SupportWorkerSelect selectedSupportWorkers={linkWorkers} teamId={selectedTeam} onClick={onClickLinkWorker} isSelectMultiple showContractedHours />
    </TWModal>
  );
}
