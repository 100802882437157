import { useGetforms } from 'api/hooks/useForms';
import { FormLabel, Input, MenuButton } from 'components';
import { format } from 'date-fns';
import { SupportWorker, Form } from '__generated__/graphql';
import { useMemo } from 'react';
import { addYearsToDate } from 'services/helpers';

interface ManageSupportWorkerFormListProps {
  supportWorker: SupportWorker | null | undefined;
}

export const getNextReviewDate = (supportWorkerForm: Form) => {
  return supportWorkerForm.medicalObvs?.status === 'NOT_PASSED' ? new Date() : addYearsToDate(supportWorkerForm.dateTimeOfObservation, 1);
};

const ManageSupportWorkerFormList = ({ supportWorker }: ManageSupportWorkerFormListProps) => {
  const { forms, loading: formLoading } = useGetforms({ supportWorkerId: supportWorker?.id, teamId: supportWorker?.teamId });
  const sortedForms = useMemo(() => {
    return [...forms].sort((a, b) => a.dateTimeOfObservation - b.dateTimeOfObservation);
  }, [forms]);
  const nextReview = useMemo(() => {
    if (!formLoading && forms.length !== 0) {
      return getNextReviewDate(sortedForms[sortedForms.length === 1 ? 0 : sortedForms.length - 1]);
    }
  }, [forms, formLoading, sortedForms]);

  return (
    <div className="mt-3 md:mt-6 mb-6 ">
      <FormLabel label="Start Date">
        <div className="w-full lg:w-1/2">
          <Input disabled={true} value={supportWorker?.dateCreated ? format(supportWorker?.dateCreated, 'dd/MM/yy') : 'unknown'} placeholder="Start Date" />
        </div>
      </FormLabel>
      <FormLabel label="Next Review Date">
        <div className="w-full lg:w-1/2">
          <Input disabled={true} value={nextReview ? format(nextReview, 'dd/MM/yy') : 'unknown'} placeholder="Start Date" />
        </div>
      </FormLabel>
      <div className="py-7">
        <MenuButton
          text="Create Form"
          testId="form-menu"
          items={[
            { url: `/admin-workers/${supportWorker?.id}/forms/medical-obvs/create`, text: 'Medical Observations' },
            { url: `/admin-workers/${supportWorker?.id}/forms/incident/create`, text: 'Incident' },
          ]}
        />
      </div>
      <div className="mt-3 md:mt-12 mb-10 scroll-m-3 overflow-x-auto overflow-y-hidden">
        <table className="table-auto min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <td className="px-2 sm:px-4  md:px-6 py-3 text-left text-sm font-semibold text-gray-800"> Attempt number</td>
              <td className="px-2 sm:px-4  md:px-6 py-3 text-left text-sm font-semibold text-gray-800">Type</td>
              <td className="px-2 sm:px-4  md:px-6 py-3 text-left text-sm font-semibold text-gray-800">Status</td>
              <td className="px-2 sm:px-4  md:px-6 py-3 text-left text-sm font-semibold text-gray-800">Date Observed</td>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {sortedForms.length > 0 &&
              sortedForms.map((form, index) => {
                return (
                  <tr>
                    <td className="px-2 sm:px-4 md:px-6 py-4 sm:whitespace-nowrap text-gray-700 text-md leading-md"> {index + 1}</td>

                    <td className="px-2 sm:px-4 md:px-6 py-4 sm:whitespace-nowrap text-gray-700 text-md leading-md"> {form.formType}</td>
                    <td className="px-2 sm:px-4 md:px-6 py-4 sm:whitespace-nowrap text-gray-700 text-md leading-md">{form.medicalObvs?.status}</td>
                    <td className="px-2 sm:px-4 md:px-6 py-4 sm:whitespace-nowrap text-gray-700 text-md leading-md">
                      {format(form.dateTimeOfObservation ?? 0, 'dd/MM/yy')}
                    </td>
                    <td className="px-2 sm:px-4 md:px-6 py-4 sm:whitespace-nowrap text-gray-700 text-md leading-md">
                      <a
                        href={form.medicalObvs ? `/forms/medical-obvs/${form.id}` : `/forms/incident/${form.id}`}
                        className="text-white bg-primary-600 font-medium rounded-lg text-sm p-2 lg:px-5 lg:py-2.5 text-center inline-flex items-center"
                      >
                        View Form
                      </a>
                    </td>
                  </tr>
                );
              })}
            {forms.length === 0 && <div> No Forms </div>}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export { ManageSupportWorkerFormList };
