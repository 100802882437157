import React, { ReactInstance } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PrintIcon from '@mui/icons-material/Print';
import { useReactToPrint } from 'react-to-print';
import { Map } from 'components';
import { Customer as CustomerType, SupportWorker } from '__generated__/graphql';

interface CustomerProps {
  customer: CustomerType;
  workers: SupportWorker[];
  componentRef: React.MutableRefObject<ReactInstance | null>;
  enablePrint: boolean;
}

export default function Customer({ customer, workers, componentRef, enablePrint }: CustomerProps) {
  const onPrintSchedule = useReactToPrint({
    content: () => componentRef.current,
  });

  if (!customer) {
    return null;
  }

  return (
    <Card sx={{ display: 'flex', marginTop: '1em', padding: '1em' }}>
      <Grid container spacing={2}>
        <Grid xs={8} sm={3}>
          <CardContent>
            <Typography data-cy="customer-name" component="div" variant="h5" sx={{ marginBottom: '0.4em' }}>
              {customer?.firstName} {customer?.lastName}
            </Typography>
            <Typography data-cy="customer-address" variant="subtitle1" color="text.secondary" component="div" sx={{ marginBottom: '0.4em' }}>
              {customer?.address} {customer?.postcode}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" component="div" sx={{ marginBottom: '0.4em' }}>
              <a data-cy="customer-contact" href={`tel:${customer?.phone}`}>
                {customer?.phone}
              </a>
            </Typography>
          </CardContent>
        </Grid>
        <Grid xs={4} sm={1}>
          <CardContent>
            {enablePrint && (
              <Tooltip
                title={`Print a schedule for ${customer?.firstName} ${customer?.lastName}`}
                sx={{ marginLeft: { xs: '3em', sm: '0em' }, cursor: 'pointer' }}
              >
                <PrintIcon onClick={() => onPrintSchedule()} />
              </Tooltip>
            )}
          </CardContent>
        </Grid>
        <Grid xs={12} sm={8}>
          <CardContent data-cy="customer-map">
            <Map customers={[customer]} workers={workers} showCustomers showSupportWorkers centreOnCustomer zoom={14} />
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
}
