import { Add } from '@mui/icons-material';
import Tooltip from 'components/Tooltip';
import { addDays, addWeeks, format } from 'date-fns';

interface AgreedShiftHeaderProps {
  date: number;
  week: number;
  onAddShiftRun: (week: number) => void;
  isDisable: boolean;
  name: string;
}

const AgreedShiftHeader = ({ date, week, onAddShiftRun, isDisable, name }: AgreedShiftHeaderProps) => {
  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const startDate = week === 2 ? addWeeks(date, 1) : new Date(date);

  return (
    <>
      <div className="h-[70px] xl:w-[9%] p-2 border-gray-100 flex flex-col justify-center items-center">
        <div className="text-lg text-gray-800 font-semibold text-center">Week {week}</div>
      </div>
      <div className="h-[70px] xl:w-[8%] p-2 border-t xl:border-t-0 xl:border-l border-gray-100 flex flex-col justify-center items-center">
        <div className="text-lg leading-lg text-gray-800 font-semibold text-center">Start</div>
      </div>
      <div className="h-[70px] xl:w-[8%] p-2 border-t xl:border-t-0 xl:border-l border-gray-100 flex flex-col justify-center items-center">
        <div className="text-lg text-gray-800 font-semibold text-center">End</div>
      </div>
      {days.map((day, index) => (
        <div className="h-[70px] xl:w-[10%] p-2 border-t xl:border-t-0 xl:border-l border-gray-100 flex flex-col justify-center items-center">
          <div className="text-lg text-gray-800 font-semibold text-center">{day}</div>
          <div className="text-md leading-md text-gray-800 text-center">{format(addDays(startDate, index), 'dd/MM')}</div>
        </div>
      ))}
      <div className="xl:hidden flex h-[70px] xl:w-[5%] px-2 py-6  border-t xl:border-t-0" />
      <div className="hidden xl:flex h-[70px] xl:w-[5%] p-2  border-t xl:border-t-0 xl:border-l border-gray-100 items-center justify-center">
        <Tooltip tooltip={`Create a new run in week ${week}`} right>
          <button
            data-cy={`add-shift-run-button-week${week}-${name}`}
            type="button"
            aria-label={`Create a new run in week ${week}`}
            className="text-gray-800 disabled:text-gray-400"
            onClick={() => {
              onAddShiftRun(week);
            }}
            disabled={isDisable}
          >
            <Add />
          </button>
        </Tooltip>
      </div>
    </>
  );
};

export default AgreedShiftHeader;
