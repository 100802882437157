import { ElderlyOutlined } from '@mui/icons-material';
import { useState, useCallback, useEffect } from 'react';
import { Loading, Message, LocationBar } from 'components';
import Helmet from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { Customer, Maybe } from '__generated__/graphql';
import { useGetCustomers, useSaveCustomerProfile } from 'api/hooks/useCustomers';
import { useForm, FormProvider } from 'react-hook-form';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { QuillField } from 'components/Fields/QuillField';
import { TextListField } from 'components/Fields/TextListField';
import { FormPrompt } from 'components/modals/FormPromptModal';

const EditProfile = () => {
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const { supportedPersonId } = useParams();
  const { customers, loading } = useGetCustomers({ showAll: true });
  const { saveCustomerProfile, mutationSaveCustomerProfile } = useSaveCustomerProfile();
  const customer = customers.filter((c: Customer) => c.id === supportedPersonId)[0];
  const methods = useForm<{
    profileData: {
      matters: string;
      support: string;
      aboutMe: Maybe<string>[];
    };
  }>({
    mode: 'onChange',
  });

  const { handleSubmit, reset, formState } = methods;

  useEffect(() => {
    reset(
      {
        profileData: {
          matters: customer?.profileData?.matters ? customer?.profileData?.matters.replace(/<br\s*\/?>/g, '\r\n') : '',
          support: customer?.profileData?.support ? customer?.profileData?.support.replace(/<br\s*\/?>/g, '\r\n') : '',
          aboutMe: customer?.profileData?.aboutMe ? customer?.profileData?.aboutMe : [],
        },
      },
      { keepDirty: true },
    );
  }, [reset, customer?.profileData?.aboutMe, customer?.profileData?.support, customer?.profileData?.matters]);

  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (data: {
      profileData: {
        matters: string;
        support: string;
        aboutMe: Maybe<string>[] | { value: Maybe<string> }[];
      };
    }) => {
      const map = data.profileData.aboutMe?.map((am) => {
        if (typeof am === 'string') {
          return am;
        } else {
          return am?.value;
        }
      });
      await saveCustomerProfile({
        awaitRefetchQueries: true,

        variables: {
          input: {
            id: customer?.id,
            teamId: customer?.teamId,
            profileData: {
              aboutMe: map || [],
              matters: data.profileData?.matters?.replace(/\n\r?/g, '<br />'),
              support: data.profileData?.support?.replace(/\n\r?/g, '<br />'),
            },
          },
        },
      });

      reset(
        {
          profileData: {
            aboutMe: map || [],
            matters: data.profileData?.matters?.replace(/\n\r?/g, '<br />'),
            support: data.profileData?.support?.replace(/\n\r?/g, '<br />'),
          },
        },
        { keepValues: true, keepDirty: false },
      );
      setOpenSnack(true);
    },
    [customer?.id, reset, customer?.teamId, saveCustomerProfile],
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Supported People</title>
      </Helmet>
      <LocationBar section="People" page="Supported People Manage Profile" Icon={ElderlyOutlined} />
      <Message response={[mutationSaveCustomerProfile]} openSnack={openSnack} setOpenSnack={setOpenSnack} />
      <div className="my-10 px-4 md:px-[5%]">
        <button type="button" className="text-gray-500 font-semibold text-md leading-md flex items-center" onClick={() => navigate('/supported-people/')}>
          <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
        </button>

        <div className="mt-10 sm:mt-16">
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="my-10 px-4 md:px-[5%]">
                <div className="flex items-center  py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  <div className="ps-3">
                    <div className="text-black text-xl sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1">
                      {customer?.firstName} {customer?.lastName}
                    </div>
                  </div>
                </div>
                <div className="mt-8 sm:mt-12 w-full">
                  <TextListField label={`What people like and admire about ${customer?.firstName}`} name="profileData.aboutMe" />
                  <QuillField label={`What's important to ${customer?.firstName}`} name="profileData.matters" />
                  <QuillField label={`How best to support ${customer?.firstName}`} name="profileData.support" />
                </div>
                <button type="submit" className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
                  Save
                </button>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
      <FormPrompt hasUnsavedChanges={formState.isDirty} />
    </>
  );
};

export default EditProfile;
