import { VisitType } from '__generated__/graphql';
import { Loading } from 'components';
import { useGetCompletedVisits } from 'api/hooks/useChat';

import { useState } from 'react';
import DatePicker from 'components/DatePicker';
import { InfoRounded } from '@mui/icons-material';
import { isAfter } from 'date-fns';
import CareNote from './CareNote';

interface CareNotesProps {
  customerId: string;
  teamId: string;
}

const CareNotes = ({ customerId, teamId }: CareNotesProps) => {
  const [fromDate, setFormDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 3)));
  const [toDate, setToDate] = useState(new Date());
  const { completedVisits, loading } = useGetCompletedVisits({
    customerId,
    teamId,
    fromDate: fromDate.toISOString(),
    toDate: toDate.toISOString(),
  });

  return (
    <div>
      <div className="px-4">
        <div className="text-gray-800 text-sm leading-sm font-medium mb-1.5">From</div>
        <DatePicker
          value={fromDate}
          onChange={(newValue) => {
            if (newValue) {
              setFormDate(newValue);
              if (isAfter(new Date(new Date(newValue).setMonth(newValue.getMonth() + 3)), new Date())) {
                setToDate(new Date());
              } else {
                setToDate(new Date(new Date(newValue).setMonth(newValue.getMonth() + 3)));
              }
            }
          }}
          maxDate={new Date()}
        />
        <div className="text-gray-800 text-sm leading-sm font-medium mt-2 mb-1.5">To</div>
        <DatePicker
          value={toDate}
          onChange={(newValue) => {
            if (newValue) {
              setToDate(newValue);
            }
          }}
          maxDate={new Date(new Date(fromDate).setMonth(fromDate.getMonth() + 3))}
          minDate={fromDate}
        />
        <div className="flex items-center gap-3 mt-2">
          <InfoRounded className="text-primary-700 !w-5 !h-5" />
          <div className="text-xs leading-xs text-gray-800">Maximum date range is 3 months.</div>
        </div>
      </div>
      <div className="mt-4">
        {loading ? (
          <Loading isComponent />
        ) : (
          <>
            {completedVisits.length > 0 &&
              completedVisits.map((completedVisit: VisitType, index: number) => {
                return (
                  <CareNote
                    visitId={completedVisit.id ?? ''}
                    start={completedVisit.actual_start_at ?? ''}
                    end={completedVisit.actual_end_at ?? ''}
                    caregivers={completedVisit.caregivers?.map((caregiver) => `${caregiver?.user?.firstname} ${caregiver?.user?.lastname}`) ?? []}
                    key={index}
                  />
                );
              })}
            {completedVisits.length === 0 && <div className="text-center px-4 text-md leading-md font-medium text-gray-800">No visits in this date range.</div>}
          </>
        )}
      </div>
    </div>
  );
};

export default CareNotes;
