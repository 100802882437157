import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Rota } from '__generated__/graphql';
import { formatRotaDateTimeString } from './utils';

interface RotaItem extends Rota {
  type: string;
}

interface DeleteModalProps {
  rotaItem: RotaItem | null;
  open: boolean;
  onClose: () => void;
  onDelete: (rotaData: RotaItem) => void;
}

export default function DeleteModal({ rotaItem, open, onClose, onDelete }: DeleteModalProps) {
  const date =
    rotaItem?.startRotaDateTime && rotaItem?.endRotaDateTime
      ? formatRotaDateTimeString(rotaItem?.startRotaDateTime.toString(), rotaItem.endRotaDateTime.toString(), '')
      : '';
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle data-cy="confirmation-dialog-text" sx={{ fontSize: '1.6em', padding: { xs: '0.8em', sm: '1.2em' } }}>
        Delete Rota
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{`Are you sure you want to delete rota ${date}`}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: '1em' }}>
        <Button data-cy="reject-button" variant="contained" onClick={onClose}>
          Cancel
        </Button>
        {onDelete && (
          <Button
            data-cy="confirm-button"
            variant="contained"
            onClick={() => {
              if (rotaItem) {
                onDelete(rotaItem);
                onClose();
              }
            }}
          >
            Delete
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
