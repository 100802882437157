import { Controller, useFormContext } from 'react-hook-form';
import { SelectOption } from 'types/types';
import FormLabel from '../FormLabel';
import ReactSelect from '../Select';
import { isValueRequired } from './validators';

interface SelectFieldProps<T> {
  label: string;
  name: string;
  asRow?: boolean;
  options: SelectOption<T>[];
  disabled?: boolean;
  loading?: boolean;
  searchable?: boolean;
  testId?: string;
  isRequired?: boolean;
}

interface SelectFieldWithOutLabelProps<T> {
  name: string;
  options: SelectOption<T>[];
  disabled?: boolean;
  loading?: boolean;
  searchable?: boolean;
  testId?: string;
  isRequired?: boolean;
}

function SelectFieldWithoutLabel<T = void>({
  name,
  options,
  disabled = false,
  loading = false,
  searchable,
  testId,
  isRequired,
}: SelectFieldWithOutLabelProps<T>) {
  const { control, register } = useFormContext(); // retrieve all hook methods

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate: isRequired ? isValueRequired : undefined }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const selectedValue = options.find((option) => option.value === value);
        return (
          <>
            <ReactSelect
              formRef={register(name).ref}
              testId={testId}
              isLoading={loading}
              disabled={disabled}
              options={options}
              onChange={(e) => onChange(e.value)}
              selectedValue={selectedValue}
              searchable={searchable}
            />
            <p className="mt-2 text-sm text-error-600 dark:text-green-500">
              <span className="font-medium">{error?.message}</span>
            </p>
          </>
        );
      }}
    />
  );
}
function SelectField<T = void>(props: SelectFieldProps<T>) {
  return (
    <FormLabel asRow={props.asRow} label={props.label}>
      <div className="w-full lg:w-1/2">
        <SelectFieldWithoutLabel<T> {...props} />
      </div>
    </FormLabel>
  );
}

export { SelectField, SelectFieldWithoutLabel };
