import { camelCaseToNormalString } from 'services/strings';
import { format } from 'date-fns';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { Controller, useFormContext } from 'react-hook-form';

interface TrackingItemProps {
  number: number;
  name: string;
}

export default function TrackingItem({ name, number }: TrackingItemProps) {
  const { control } = useFormContext(); // retrieve all hook methods

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: 'This is required.' }}
      render={({ field: { onChange, value } }) => {
        const tracking = value;
        const isChecked = tracking?.status || false;
        return (
          <div className={`${isChecked ? 'bg-primary-100 rounded-lg' : 'border-b border-gray-100'} p-4 flex items-center justify-between gap-1`}>
            <div className="flex items-center gap-4">
              <div
                className={`${
                  isChecked ? 'bg-primary-700' : 'bg-primary-400'
                }  rounded text-white text-xl leading-xl font-medium w-10 h-10 flex items-center justify-center shrink-0`}
              >
                {number}
              </div>
              <div>
                <div className="text-gray-700 text-sm sm:text-md leading-sm sm:leading-md font-semibold">
                  {camelCaseToNormalString(tracking?.stage?.toString() || '')}
                </div>
                {isChecked && tracking?.dateUpdated && (
                  <div className="text-gray-600 text-xs sm:text-sm leading-xs sm:leading-sm">
                    Updated <span className="font-medium">{format(tracking?.dateUpdated ?? 0, 'dd/MM/yyyy')}</span>
                  </div>
                )}
                {tracking?.canManuallyUpdate && isChecked && (
                  <button
                    type="button"
                    className="text-gray-500 font-semibold text-sm leading-sm sm:hidden"
                    onClick={() => onChange({ ...tracking, status: !isChecked, dateUpdated: Date.now() })}
                  >
                    Uncomplete
                  </button>
                )}
              </div>
            </div>
            <div className="flex items-center gap-4">
              {tracking?.canManuallyUpdate && (
                <button
                  type="button"
                  className={`${
                    isChecked
                      ? 'text-gray-500 font-semibold text-sm leading-sm hidden sm:block'
                      : 'border border-gray-300 rounded-lg py-2 px-3.5 shadow-xs text-gray-700 text-sm leading-sm font-semibold'
                  }`}
                  onClick={() => onChange({ ...tracking, status: !isChecked, dateUpdated: Date.now() })}
                >
                  {isChecked ? 'Uncomplete' : 'Complete'}
                </button>
              )}
              {isChecked && <CheckCircleIcon className="w-8 h-8 text-success-500" />}
            </div>
          </div>
        );
      }}
    />
  );
}
