import { useState, useEffect } from 'react';
import { Candidate, CurrentStatus, SupportWorker } from '__generated__/graphql';
import { MapCandidateWorkshop } from 'pages/Candidates/utils/FileMapper';
import { ToggleButtons } from 'components';
import { SelectOption, SizeType } from 'types/types';
import ReactSelect from 'components/Select';
import TextArea from 'components/TextArea';

interface WorkshopCandidateUpdateProps {
  onClose: (state: boolean) => void;
  onSaveCandidate: (candidate: Candidate) => void;
  candidate: Candidate;
  supportWorkers: SupportWorker[];
}

export default function WorkshopCandidateUpdate({ onClose, candidate, supportWorkers, onSaveCandidate }: WorkshopCandidateUpdateProps) {
  const [attended, setAttended] = useState<boolean>(false);
  const [passed, setPassed] = useState<boolean>(false);
  const [note, setNote] = useState<string>('');
  const [shadowWithId, setShadowWithId] = useState<SelectOption>({ value: '', label: '' });
  const booleanButtons = ['Yes', 'No'];
  const workers = supportWorkers.map((t) => ({ value: t.id, label: t.fullName }));

  useEffect(() => {
    if (candidate) {
      setAttended(candidate.workshop?.attended || false);
      setPassed(candidate.workshop?.passed || false);
      setNote(candidate.workshop?.note || '');
      setShadowWithId({
        value: candidate.workshop?.shadowWithId || '',
        label: supportWorkers.find((w) => w.id === candidate.workshop?.shadowWithId)?.fullName ?? '',
      });
    }
  }, [candidate, supportWorkers]);

  const onUpdateCandidate = () => {
    if (candidate) {
      let status = candidate.currentStatus;
      if (attended) {
        status = CurrentStatus.WorkshopAttended;
      }
      if (passed) {
        status = CurrentStatus.ShadowingAgreed;
      }
      const update = {
        id: candidate?.id,
        currentStatus: status,
        workshop: MapCandidateWorkshop({ ...candidate.workshop, attended, passed, note, shadowWithId: shadowWithId.value }),
      };
      onSaveCandidate(update);
      onClose(false);
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto mx-auto max-w-5xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6 max-h-screen md:max-h-[90vh] overflow-y-auto">
            <div className="text-gray-900 text-lg leading-lg font-semibold mb-5">Update candidate</div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Did candidate attend workshop?</div>
              <div className="w-60 sm:w-96 mt-2">
                <ToggleButtons
                  buttons={booleanButtons}
                  size={SizeType.Full}
                  selectedButton={attended ? 'Yes' : 'No'}
                  onSelectButton={(button) => {
                    if (button === 'Yes') {
                      setAttended(true);
                    } else {
                      setAttended(false);
                    }
                  }}
                />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Did candidate pass workshop?</div>
              <div className="w-60 sm:w-96 mt-2">
                <ToggleButtons
                  buttons={booleanButtons}
                  size={SizeType.Full}
                  selectedButton={passed ? 'Yes' : 'No'}
                  onSelectButton={(button) => {
                    if (button === 'Yes') {
                      setPassed(true);
                    } else {
                      setPassed(false);
                    }
                  }}
                />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Shadow with</div>
              <div className="w-60 sm:w-96 mt-2">
                <ReactSelect options={workers} onChange={setShadowWithId} selectedValue={shadowWithId} />
              </div>
            </div>
            <div className="mb-7">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Note</div>
              <div className="w-60 sm:w-96 mt-2">
                <TextArea value={note} onChange={setNote} />
              </div>
            </div>
            <div className="flex justify-between items-center">
              <button type="button" className="text-gray-500 font-semibold text-md leading-md" onClick={() => onClose(false)}>
                Cancel
              </button>
              <button type="button" className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md" onClick={onUpdateCandidate}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-gray-900" />
    </>
  );
}
