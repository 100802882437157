import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import TWModal from 'components/TWModal';
import FormLabel from 'components/FormLabel';
import FileInput from 'components/FileInput';
import { useSaveDocumentForSupportedPerson } from 'api/hooks/useDocuments';
import { getFilename, uploadFile } from 'services/file';

const bucket = process.env.REACT_APP_TEAMHUB_S3_BUCKET;
const cloudfront = process.env.REACT_APP_TEAMHUB_CLOUDFRONT;
const region = process.env.REACT_APP_AWS_REGION;

interface UploadDocumentProps {
  onClose: () => void;
  ownerId: string | undefined;
  teamId: string | undefined;
  ownerType: string;
}

const UploadDocument = ({ onClose, ownerId, teamId, ownerType }: UploadDocumentProps) => {
  const [fileType, setFileType] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);
  const { saveDocument, mutationSaveDocument } = useSaveDocumentForSupportedPerson({ teamId, id: ownerId });

  const uploadToS3 = async (f: File) => {
    if (!f) return;
    setFileType(f.type);
    setTitle(f.name);
    setFile(f);
  };

  const onSaveDocument = async () => {
    if (file && file?.name) {
      const id = uuidv4();
      const filename = getFilename(id, 'documents/sp/', file);

      if (filename) {
        const url = await uploadFile(file, filename, { bucket, region, cloudfront });

        if (url) {
          await saveDocument({
            variables: {
              input: {
                ownerId,
                ownerType,
                url,
                title,
                type: fileType,
              },
            },
          });
        }
      }
    }
    setFileType('');
    setTitle('');
    setFile(null);
    onClose();
  };

  return (
    <TWModal
      title="Upload Document"
      onClose={onClose}
      onSave={file ? onSaveDocument : undefined}
      loading={mutationSaveDocument.loading}
      wFull={false}
      testId="save-document-button"
    >
      <div className="mt-2 mb-2">
        <FileInput accept="*" handleUpload={uploadToS3} placeholder="Upload Document" />
      </div>
      {file && (
        <div className="mt-8 ml-2">
          <FormLabel label="Title">
            <input
              className="bg-white px-4 py-2 rounded-lg text-md leading-md text-gray-700 placeholder:text-gray-500 border border-gray-300 shadow-xs w-full"
              placeholder="Title"
              type="text"
              onChange={(event) => setTitle(event.target.value)}
              value={title}
              data-cy="document-title"
            />
          </FormLabel>
          <FormLabel label="Type">
            <input
              className="bg-white px-4 py-2 rounded-lg text-md leading-md text-gray-700 placeholder:text-gray-500 border border-gray-300 shadow-xs w-full"
              placeholder="File Type"
              type="text"
              disabled
              onChange={(event) => setFileType(event.target.value)}
              value={fileType}
            />
          </FormLabel>
        </div>
      )}
    </TWModal>
  );
};

export default UploadDocument;
