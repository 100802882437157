import { getHoursAndMinutes } from 'services/helpers';
import { AgreedShift, AgreedShiftRun, Maybe } from '__generated__/graphql';
import { EditOutlined } from '@mui/icons-material';
import { Days } from 'types/types';
import { EditShiftRunData } from '../types';
import AgreedShiftHeader from './AgreedShiftHeader';
import DayCell from './DayCell';

interface ShiftRunProps {
  date: number;
  row: AgreedShiftRun;
  onEdit: (data: EditShiftRunData) => void;
  onCellClick: (data: Partial<AgreedShift>) => void;
  week: number;
  onAddShiftRun: (week: number) => void;
  isDisable: boolean;
  name: string;
}

const ShiftRun = ({ date, row, onEdit, onCellClick, week, onAddShiftRun, isDisable, name }: ShiftRunProps) => {
  const defaultDayProps = {
    id: '',
    ownerId: '',
    agreedShiftRunId: row.id || '',
    fullName: '',
  };

  const days = {
    0: { ...defaultDayProps, day: 0 },
    1: { ...defaultDayProps, day: 1 },
    2: { ...defaultDayProps, day: 2 },
    3: { ...defaultDayProps, day: 3 },
    4: { ...defaultDayProps, day: 4 },
    5: { ...defaultDayProps, day: 5 },
    6: { ...defaultDayProps, day: 6 },
  };

  row.shifts?.forEach((shift: Maybe<AgreedShift>) => {
    if (shift?.day !== undefined) {
      const day = days[shift?.day as Days];
      day.id = shift?.id || day.id;
      day.ownerId = shift?.ownerId || day.ownerId;
      day.fullName = shift?.ownerId === '' ? 'TBC' : shift?.fullName || day.fullName;
    }
  });

  return (
    <div className="flex w-full">
      <div className="flex flex-col xl:hidden shrink-0">
        <AgreedShiftHeader date={date} week={week} onAddShiftRun={onAddShiftRun} isDisable={isDisable} name={name} />
      </div>
      <div className="flex flex-col xl:flex-row w-full">
        <div className="h-[70px] xl:h-auto xl:w-[9%] px-2 flex flex-col items-center justify-center">
          <div className="text-lg text-gray-800 font-semibold xl:font-medium" data-cy={`shift-run-period-week${week}`}>
            {row.period}
          </div>
          {row.status === 'paused' && (
            <div className="text-gray-500 text-md leading-md font-medium" data-cy={`shift-run-${week}-paused`}>
              Paused
            </div>
          )}
        </div>
        <div className="h-[70px] xl:h-auto xl:w-[8%] px-2 flex flex-col items-center justify-center border-l border-gray-100 border-t xl:border-t-0">
          <div className="text-lg text-gray-800 font-semibold xl:font-medium" data-cy={`shift-run-start-time-week${week}`}>
            {row.startDateTime ? getHoursAndMinutes(row.startDateTime) : ''}
          </div>
        </div>
        <div className="h-[70px] xl:h-auto xl:w-[8%] px-2 flex flex-col items-center justify-center border-l border-gray-100 border-t xl:border-t-0">
          <div className="text-lg text-gray-800 font-semibold xl:font-medium" data-cy={`shift-run-end-time-week${week}`}>
            {row.endDateTime ? getHoursAndMinutes(row.endDateTime) : ''}
          </div>
        </div>
        {Object.entries(days).map(([key, _], index) => {
          return <DayCell shiftData={days[parseInt(key, 10) as Days]} onCellClick={onCellClick} status={row.status || ''} isDisable={isDisable} key={index} />;
        })}
        <div className="h-[70px] xl:h-auto xl:w-[5%] px-2 flex flex-col items-center justify-center border-l border-gray-100 border-t xl:border-t-0">
          <button
            type="button"
            aria-label="edit shift run"
            data-cy="edit-shift-run-button"
            className="text-gray-800 disabled:text-gray-400"
            disabled={isDisable}
            onClick={() => {
              let canEdit = true;
              row.shifts?.forEach((shift) => {
                if (shift?.visits?.length && shift?.visits?.length > 0) {
                  canEdit = false;
                }
              });
              onEdit({
                id: row.id || '',
                startDateTime: row.startDateTime || 0,
                endDateTime: row.endDateTime || 0,
                week: row.week || 0,
                status: row.status || '',
                canEdit,
              });
            }}
          >
            <EditOutlined />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShiftRun;
