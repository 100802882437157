import { useQuery, gql, useMutation } from '@apollo/client';
import { CustomerPackage, CustomerPackageSearch, LocalAuthority } from '__generated__/graphql';

export const GET_LOCAL_AUTHORITIES = gql`
  query GetLocalAuthorities {
    GetLocalAuthorities {
      id
      name
      clientNumber
      contactName
    }
  }
`;

export const GET_CUSTOMER_PACKAGES = gql`
  query GetCustomerPackages($query: CustomerPackageSearch) {
    GetCustomerPackages(query: $query) {
      id
      customerId
      startDate
      endDate
      name
      externalId
      fundingSource
      prices {
        id
        startDate
        endDate
        type
        monthlyValue
        hourlyValue
        numberOfHours
        note
        dayRate
        patientId
        localAuthorityId
        localAuthorityName
      }
    }
  }
`;

export const GET_CUSTOMER_PACKAGE = gql`
  query GetCustomerPackage($query: CustomerPackageSearch) {
    GetCustomerPackage(query: $query) {
      id
      customerId
      startDate
      endDate
      name
      externalId
      fundingSource
      prices {
        id
        startDate
        endDate
        type
        monthlyValue
        hourlyValue
        numberOfHours
        note
        dayRate
        patientId
        localAuthorityId
        localAuthorityName
      }
    }
  }
`;

export const DELETE_CUSTOMER_PACKAGE = gql`
  mutation DeleteCustomerPackage($input: CustomerPackageDeleteInput) {
    DeleteCustomerPackage(input: $input) {
      message
      date
    }
  }
`;

export const SAVE_CUSTOMER_PACKAGE = gql`
  mutation SaveCustomerPackage($input: CustomerPackageSaveInput) {
    SaveCustomerPackage(input: $input) {
      message
      date
    }
  }
`;

interface GetCustomerPackagesResponse {
  GetCustomerPackages: CustomerPackage[];
}

interface GetCustomerPackageResponse {
  GetCustomerPackage: CustomerPackage;
}

interface GetLocalAuthorities {
  GetLocalAuthorities: LocalAuthority[];
}

export const useGetCustomerPackages = (query: CustomerPackageSearch) => {
  const { data, loading, error } = useQuery<GetCustomerPackagesResponse>(GET_CUSTOMER_PACKAGES, {
    variables: {
      query,
    },
  });

  return { customerPackages: data?.GetCustomerPackages || [], loading, error };
};

export const useGetCustomerPackage = (query: CustomerPackageSearch) => {
  const { data, loading, error } = useQuery<GetCustomerPackageResponse>(GET_CUSTOMER_PACKAGE, {
    variables: {
      query,
    },
  });

  return { customerPackage: data?.GetCustomerPackage, loading, error };
};

export const useSaveCustomerPackage = (query: CustomerPackageSearch) => {
  const [saveCustomerPackage, mutationSaveCustomerPackage] = useMutation(SAVE_CUSTOMER_PACKAGE, {
    refetchQueries: [
      {
        query: GET_CUSTOMER_PACKAGE,
        variables: {
          query,
        },
      },
    ],
  });

  return { saveCustomerPackage, mutationSaveCustomerPackage };
};

export const useDeleteCustomerPackage = (query: CustomerPackageSearch) => {
  const [deleteCustomerPackage, mutationDeleteCustomerPackage] = useMutation(DELETE_CUSTOMER_PACKAGE, {
    refetchQueries: [
      {
        query: GET_CUSTOMER_PACKAGES,
        variables: {
          query,
        },
      },
    ],
  });

  return { deleteCustomerPackage, mutationDeleteCustomerPackage };
};

export const useGetLocalAuthorities = () => {
  const { data, loading, error } = useQuery<GetLocalAuthorities>(GET_LOCAL_AUTHORITIES);
  return { localAuthorities: data?.GetLocalAuthorities || [], loading, error };
};
