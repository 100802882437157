import { useState } from 'react';
import { Input, FormLabel, TeamSelector } from 'components';
import Button from 'components/Button';
import { DateField } from 'components/Fields/DateField';
import { FormatedNumberField } from 'components/Fields/FormatedNumberField';
import { SelectField } from 'components/Fields/SelectField';
import { InputField } from 'components/Fields/InputField';
import { UserContextProps, useUser } from 'context/userContext';
import { Customer } from '../../__generated__/graphql';
import { SupportedPeopleStateTransition } from './types';
import { NHSSelectField } from './NHSSelectField';

interface ManageSupportedPeopleProps {
  customer: Customer | null | undefined;
  onChangeAccount: (type: string, teamId: string) => void;
  teamChangeLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  detailRef: any;
}

const ManageSupportedPeople = ({ detailRef, customer, teamChangeLoading, onChangeAccount }: ManageSupportedPeopleProps) => {
  const { user } = useUser() as UserContextProps;
  const [teamId, setTeam] = useState<string>(customer?.teamId || '');

  if (!customer) return null;
  const titleOptions = [
    { value: 'Mr', label: 'Mr' },
    { value: 'Mrs', label: 'Mrs' },
    { value: 'Master', label: 'Master' },
    { value: 'Miss', label: 'Miss' },
    { value: 'Ms', label: 'Ms' },
    { value: 'Mx', label: 'Mx' },
    { value: 'Sir', label: 'Sir' },
    { value: 'Lady', label: 'Lady' },
    { value: 'Lord', label: 'Lord' },
    { value: 'Dame', label: 'Dame' },
    { value: 'Dr.', label: 'Dr.' },
    { value: 'Prof.', label: 'Prof.' },
  ];

  const nameSuffixOptions = [
    { value: 'OBE', label: 'OBE' },
    { value: 'MBE', label: 'MBE' },
    { value: 'BSc', label: 'BSc' },
    { value: 'JP', label: 'JP' },
    { value: 'GM', label: 'GM' },
    { value: 'BA', label: 'BA' },
  ];

  const religionOptions = [
    { value: { code: 'A1', description: "Baha'i" }, label: "Baha'i" },
    { value: { code: 'B1', description: 'Buddhist' }, label: 'Buddhist' },
    { value: { code: 'B2', description: 'Mahayana Buddhist' }, label: 'Mahayana Buddhist' },
    { value: { code: 'B3', description: 'New Kadampa Tradition Buddhist' }, label: 'New Kadampa Tradition Buddhist' },
    { value: { code: 'B4', description: 'Nichiren Buddhist' }, label: 'Nichiren Buddhist' },
    { value: { code: 'B5', description: 'Pure Land Buddhist' }, label: 'Pure Land Buddhist' },
    { value: { code: 'B6', description: 'Theravada Buddhist' }, label: 'Theravada Buddhist' },
    { value: { code: 'B7', description: 'Tibetan Buddhist' }, label: 'Tibetan Buddhist' },
    { value: { code: 'B8', description: 'Zen Buddhist' }, label: 'Zen Buddhist' },
    { value: { code: 'C1', description: 'Christian' }, label: 'Christian' },
    { value: { code: 'C2', description: 'Amish' }, label: 'Amish' },
    { value: { code: 'C3', description: 'Anabaptist' }, label: 'Anabaptist' },
    { value: { code: 'C4', description: 'Anglican' }, label: 'Anglican' },
    { value: { code: 'C5', description: 'Apostolic Pentecostalist' }, label: 'Apostolic Pentecostalist' },
    { value: { code: 'C6', description: 'Armenian Catholic' }, label: 'Armenian Catholic' },
    { value: { code: 'C7', description: 'Armenian Orthodox' }, label: 'Armenian Orthodox' },
    { value: { code: 'C8', description: 'Baptist' }, label: 'Baptist' },
    { value: { code: 'C9', description: 'Brethren' }, label: 'Brethren' },
    { value: { code: 'C10', description: 'Bulgarian Orthodox' }, label: 'Bulgarian Orthodox' },
    { value: { code: 'C11', description: 'Calvinist' }, label: 'Calvinist' },
    { value: { code: 'C12', description: 'Catholic: Not Roman Catholic' }, label: 'Catholic: Not Roman Catholic' },
    { value: { code: 'C13', description: 'Celtic Christian' }, label: 'Celtic Christian' },
    { value: { code: 'C14', description: 'Celtic Orthodox Christian' }, label: 'Celtic Orthodox Christian' },
    { value: { code: 'C15', description: 'Chinese Evangelical Christian' }, label: 'Chinese Evangelical Christian' },
    { value: { code: 'C16', description: 'Christadelphian' }, label: 'Christadelphian' },
    { value: { code: 'C17', description: 'Christian Existentialist' }, label: 'Christian Existentialist' },
    { value: { code: 'C18', description: 'Christian Humanist' }, label: 'Christian Humanist' },
    { value: { code: 'C19', description: 'Christian Scientists' }, label: 'Christian Scientists' },
    { value: { code: 'C20', description: 'Christian Spiritualist' }, label: 'Christian Spiritualist' },
    { value: { code: 'C21', description: 'Church in Wales' }, label: 'Church in Wales' },
    { value: { code: 'C22', description: 'Church of England' }, label: 'Church of England' },
    { value: { code: 'C23', description: 'Church of God of Prophecy' }, label: 'Church of God of Prophecy' },
    { value: { code: 'C24', description: 'Church of Ireland' }, label: 'Church of Ireland' },
    { value: { code: 'C25', description: 'Church of Scotland' }, label: 'Church of Scotland' },
    { value: { code: 'C26', description: 'Congregationalist' }, label: 'Congregationalist' },
    { value: { code: 'C27', description: 'Coptic Orthodox' }, label: 'Coptic Orthodox' },
    { value: { code: 'C28', description: 'Eastern Catholic' }, label: 'Eastern Catholic' },
    { value: { code: 'C29', description: 'Eastern Orthodox' }, label: 'Eastern Orthodox' },
    { value: { code: 'C30', description: 'Elim Pentecostalist' }, label: 'Elim Pentecostalist' },
    { value: { code: 'C31', description: 'Ethiopian Orthodox' }, label: 'Ethiopian Orthodox' },
    { value: { code: 'C32', description: 'Evangelical Christian' }, label: 'Evangelical Christian' },
    { value: { code: 'C33', description: 'Exclusive Brethren' }, label: 'Exclusive Brethren' },
    { value: { code: 'C34', description: 'Free Church' }, label: 'Free Church' },
    { value: { code: 'C35', description: 'Free Church of Scotland' }, label: 'Free Church of Scotland' },
    { value: { code: 'C36', description: 'Free Evangelical Presbyterian' }, label: 'Free Evangelical Presbyterian' },
    { value: { code: 'C37', description: 'Free Methodist' }, label: 'Free Methodist' },
    { value: { code: 'C38', description: 'Free Presbyterian' }, label: 'Free Presbyterian' },
    { value: { code: 'C39', description: 'French Protestant' }, label: 'French Protestant' },
    { value: { code: 'C40', description: 'Greek Catholic' }, label: 'Greek Catholic' },
    { value: { code: 'C41', description: 'Greek Orthodox' }, label: 'Greek Orthodox' },
    { value: { code: 'C42', description: 'Independent Methodist' }, label: 'Independent Methodist' },
    { value: { code: 'C43', description: 'Indian Orthodox' }, label: 'Indian Orthodox' },
    { value: { code: 'C44', description: "Jehovah's Witness" }, label: "Jehovah's Witness" },
    { value: { code: 'C45', description: 'Judaic Christian' }, label: 'Judaic Christian' },
    { value: { code: 'C46', description: 'Lutheran' }, label: 'Lutheran' },
    { value: { code: 'C47', description: 'Mennonite' }, label: 'Mennonite' },
    { value: { code: 'C48', description: 'Messianic Jew' }, label: 'Messianic Jew' },
    { value: { code: 'C49', description: 'Methodist' }, label: 'Methodist' },
    { value: { code: 'C50', description: 'Moravian' }, label: 'Moravian' },
    { value: { code: 'C51', description: 'Mormon' }, label: 'Mormon' },
    { value: { code: 'C52', description: 'Nazarene Church Synonym: Nazarene' }, label: 'Nazarene Church Synonym: Nazarene' },
    { value: { code: 'C53', description: 'New Testament Pentacostalist' }, label: 'New Testament Pentacostalist' },
    { value: { code: 'C54', description: 'Nonconformist' }, label: 'Nonconformist' },
    { value: { code: 'C55', description: 'Old Catholic' }, label: 'Old Catholic' },
    { value: { code: 'C56', description: 'Open Brethren' }, label: 'Open Brethren' },
    { value: { code: 'C57', description: 'Orthodox Christian' }, label: 'Orthodox Christian' },
    { value: { code: 'C58', description: 'Pentecostalist Synonym: Pentacostal Christian' }, label: 'Pentecostalist Synonym: Pentacostal Christian' },
    { value: { code: 'C59', description: 'Presbyterian' }, label: 'Presbyterian' },
    { value: { code: 'C60', description: 'Protestant' }, label: 'Protestant' },
    { value: { code: 'C61', description: 'Plymouth Brethren' }, label: 'Plymouth Brethren' },
    { value: { code: 'C62', description: 'Quaker' }, label: 'Quaker' },
    { value: { code: 'C63', description: 'Rastafari' }, label: 'Rastafari' },
    { value: { code: 'C64', description: 'Reformed Christian' }, label: 'Reformed Christian' },
    { value: { code: 'C65', description: 'Reformed Presbyterian' }, label: 'Reformed Presbyterian' },
    { value: { code: 'C66', description: 'Reformed Protestant' }, label: 'Reformed Protestant' },
    { value: { code: 'C67', description: 'Roman Catholic' }, label: 'Roman Catholic' },
    { value: { code: 'C68', description: 'Romanian Orthodox' }, label: 'Romanian Orthodox' },
    { value: { code: 'C69', description: 'Russian Orthodox' }, label: 'Russian Orthodox' },
    { value: { code: 'C70', description: 'Salvation Army Member' }, label: 'Salvation Army Member' },
    { value: { code: 'C71', description: 'Scottish Episcopalian' }, label: 'Scottish Episcopalian' },
    { value: { code: 'C72', description: 'Serbian Orthodox' }, label: 'Serbian Orthodox' },
    { value: { code: 'C73', description: 'Seventh Day Adventist' }, label: 'Seventh Day Adventist' },
    { value: { code: 'C74', description: 'Syrian Orthodox' }, label: 'Syrian Orthodox' },
    { value: { code: 'C75', description: 'Ukrainian Catholic' }, label: 'Ukrainian Catholic' },
    { value: { code: 'C76', description: 'Ukrainian Orthodox' }, label: 'Ukrainian Orthodox' },
    { value: { code: 'C77', description: 'Uniate Catholic' }, label: 'Uniate Catholic' },
    { value: { code: 'C78', description: 'Unitarian' }, label: 'Unitarian' },
    { value: { code: 'C79', description: 'United Reformed' }, label: 'United Reformed' },
    { value: { code: 'C80', description: 'Zwinglian' }, label: 'Zwinglian' },
    { value: { code: 'D1', description: 'Hindu' }, label: 'Hindu' },
    { value: { code: 'D2', description: 'Advaitin Hindu' }, label: 'Advaitin Hindu' },
    { value: { code: 'D3', description: 'Arya Samaj Hindu' }, label: 'Arya Samaj Hindu' },
    { value: { code: 'D4', description: 'Shakti Hindu' }, label: 'Shakti Hindu' },
    { value: { code: 'D5', description: 'Shiva Hindu' }, label: 'Shiva Hindu' },
    { value: { code: 'D6', description: 'Vaishnava Hindu Synonym: Hare Krishna' }, label: 'Vaishnava Hindu Synonym: Hare Krishna' },
    { value: { code: 'E1', description: 'Jain' }, label: 'Jain' },
    { value: { code: 'F1', description: 'Jewish' }, label: 'Jewish' },
    { value: { code: 'F2', description: 'Ashkenazi Jew' }, label: 'Ashkenazi Jew' },
    { value: { code: 'F3', description: 'Haredi Jew' }, label: 'Haredi Jew' },
    { value: { code: 'F4', description: 'Hasidic Jew' }, label: 'Hasidic Jew' },
    { value: { code: 'F5', description: 'Liberal Jew' }, label: 'Liberal Jew' },
    { value: { code: 'F6', description: 'Masorti Jew' }, label: 'Masorti Jew' },
    { value: { code: 'F7', description: 'Orthodox Jew' }, label: 'Orthodox Jew' },
    { value: { code: 'F8', description: 'Reform Jew' }, label: 'Reform Jew' },
    { value: { code: 'G1', description: 'Muslim' }, label: 'Muslim' },
    { value: { code: 'G2', description: 'Ahmadi' }, label: 'Ahmadi' },
    { value: { code: 'G3', description: 'Druze' }, label: 'Druze' },
    { value: { code: 'G4', description: 'Ismaili Muslim' }, label: 'Ismaili Muslim' },
    { value: { code: 'G5', description: "Shi'ite Muslim" }, label: "Shi'ite Muslim" },
    { value: { code: 'G6', description: 'Sunni Muslim' }, label: 'Sunni Muslim' },
    { value: { code: 'H1', description: 'Pagan' }, label: 'Pagan' },
    { value: { code: 'H2', description: 'Asatruar' }, label: 'Asatruar' },
    { value: { code: 'H3', description: 'Celtic Pagan' }, label: 'Celtic Pagan' },
    { value: { code: 'H4', description: 'Druid' }, label: 'Druid' },
    { value: { code: 'H5', description: 'Goddess' }, label: 'Goddess' },
    { value: { code: 'H6', description: 'Heathen' }, label: 'Heathen' },
    { value: { code: 'H7', description: 'Occultist' }, label: 'Occultist' },
    { value: { code: 'H8', description: 'Shaman' }, label: 'Shaman' },
    { value: { code: 'H9', description: 'Wiccan' }, label: 'Wiccan' },
    { value: { code: 'I1', description: 'Sikh' }, label: 'Sikh' },
    { value: { code: 'J1', description: 'Zoroastrian' }, label: 'Zoroastrian' },
    { value: { code: 'K1', description: 'Agnostic' }, label: 'Agnostic' },
    { value: { code: 'K2', description: 'Ancestral Worship' }, label: 'Ancestral Worship' },
    { value: { code: 'K3', description: 'Animist' }, label: 'Animist' },
    { value: { code: 'K4', description: 'Anthroposophist' }, label: 'Anthroposophist' },
    { value: { code: 'K5', description: 'Black Magic' }, label: 'Black Magic' },
    { value: { code: 'K6', description: 'Brahma Kumari' }, label: 'Brahma Kumari' },
    { value: { code: 'K7', description: 'British Israelite' }, label: 'British Israelite' },
    { value: { code: 'K8', description: 'Chondogyo' }, label: 'Chondogyo' },
    { value: { code: 'K9', description: 'Confucianist' }, label: 'Confucianist' },
    { value: { code: 'K10', description: 'Deist' }, label: 'Deist' },
    { value: { code: 'K11', description: 'Humanist' }, label: 'Humanist' },
    { value: { code: 'K12', description: 'Infinite Way' }, label: 'Infinite Way' },
    { value: { code: 'K13', description: 'Kabbalist' }, label: 'Kabbalist' },
    { value: { code: 'K14', description: 'Lightworker' }, label: 'Lightworker' },
    { value: { code: 'K15', description: 'New Age Practitioner' }, label: 'New Age Practitioner' },
    { value: { code: 'K16', description: 'Native American Religion' }, label: 'Native American Religion' },
    { value: { code: 'K17', description: 'Pantheist' }, label: 'Pantheist' },
    { value: { code: 'K18', description: 'Peyotist' }, label: 'Peyotist' },
    { value: { code: 'K19', description: 'Radha Soami Synonym: Sant Mat' }, label: 'Radha Soami Synonym: Sant Mat' },
    { value: { code: 'K20', description: 'Religion (Other Not Listed)' }, label: 'Religion (Other Not Listed)' },
    { value: { code: 'K21', description: 'Santeri' }, label: 'Santeri' },
    { value: { code: 'K22', description: 'Satanist' }, label: 'Satanist' },
    { value: { code: 'K23', description: 'Scientologist' }, label: 'Scientologist' },
    { value: { code: 'K24', description: 'Secularist' }, label: 'Secularist' },
    { value: { code: 'K25', description: 'Shumei' }, label: 'Shumei' },
    { value: { code: 'K26', description: 'Shinto' }, label: 'Shinto' },
    { value: { code: 'K27', description: 'Spiritualist' }, label: 'Spiritualist' },
    { value: { code: 'K28', description: 'Swedenborgian Synonym: Neo-Christian' }, label: 'Swedenborgian Synonym: Neo-Christian' },
    { value: { code: 'K29', description: 'Taoist' }, label: 'Taoist' },
    { value: { code: 'K30', description: 'Unitarian-Universalist' }, label: 'Unitarian-Universalist' },
    { value: { code: 'K31', description: 'Universalist' }, label: 'Universalist' },
    { value: { code: 'K32', description: 'Vodun' }, label: 'Vodun' },
    { value: { code: 'K33', description: 'Yoruba' }, label: 'Yoruba' },
    { value: { code: 'L1', description: 'Atheist' }, label: 'Atheist' },
    { value: { code: 'L2', description: 'Not Religious' }, label: 'Not Religious' },
    { value: { code: 'M1', description: 'Religion not given - PATIENT refused' }, label: 'Religion not given - PATIENT refused' },
    { value: { code: 'N1', description: 'PATIENT Religion Unknown' }, label: 'PATIENT Religion Unknown' },
  ];

  const ethnicOptions = [
    { value: { code: 'A', description: 'White - British' }, label: 'White - British' },
    { value: { code: 'B', description: 'White - Irish' }, label: 'White - Irish' },
    { value: { code: 'C', description: 'White - Any other White background' }, label: 'White - Any other White background' },
    { value: { code: 'D', description: 'Mixed - White and Black Caribbean' }, label: 'Mixed - White and Black Caribbean' },
    { value: { code: 'E', description: 'Mixed - White and Black African' }, label: 'Mixed - White and Black African' },
    { value: { code: 'F', description: 'Mixed - White and Asian' }, label: 'Mixed - White and Asian' },
    { value: { code: 'G', description: 'Mixed - Any other mixed background' }, label: 'Mixed - Any other mixed background' },
    { value: { code: 'H', description: 'Asian or Asian British - Indian' }, label: 'Asian or Asian British - Indian' },
    { value: { code: 'J', description: 'Asian or Asian British - Pakistani' }, label: 'Asian or Asian British - Pakistani' },
    { value: { code: 'K', description: 'Asian or Asian British - Bangladeshi' }, label: 'Asian or Asian British - Bangladeshi' },
    { value: { code: 'L', description: 'Asian or Asian British - Any other Asian background' }, label: 'Asian or Asian British - Any other Asian background' },
    { value: { code: 'M', description: 'Black or Black British - Caribbean' }, label: 'Black or Black British - Caribbean' },
    { value: { code: 'N', description: 'Black or Black British - African' }, label: 'Black or Black British - African' },
    { value: { code: 'P', description: 'Black or Black British - Any other Black background' }, label: 'Black or Black British - Any other Black background' },
    { value: { code: 'R', description: 'Other Ethnic Groups - Chinese' }, label: 'Other Ethnic Groups - Chinese' },
    { value: { code: 'S', description: 'Other Ethnic Groups - Any other ethnic group' }, label: 'Other Ethnic Groups - Any other ethnic group' },
    { value: { code: 'Z', description: 'Not stated' }, label: 'Not stated' },
  ];

  const sexOptions = [
    { value: { code: '1', description: 'Male' }, label: 'Male' },
    { value: { code: '2', description: 'Female' }, label: 'Female' },
    {
      value: { code: '9', description: 'Indeterminate (unable to be classified as either male or female)' },
      label: 'Indeterminate (unable to be classified as either male or female)',
    },
  ];

  const countryOptions = [
    { value: { englishShortName: 'AFG', alpha3Code: 'Afghanistan' }, label: 'Afghanistan' },
    { value: { englishShortName: 'ALB', alpha3Code: 'Albania' }, label: 'Albania' },
    { value: { englishShortName: 'DZA', alpha3Code: 'Algeria' }, label: 'Algeria' },
    { value: { englishShortName: 'ASM', alpha3Code: 'American Samoa' }, label: 'American Samoa' },
    { value: { englishShortName: 'AND', alpha3Code: 'Andorra' }, label: 'Andorra' },
    { value: { englishShortName: 'AGO', alpha3Code: 'Angola' }, label: 'Angola' },
    { value: { englishShortName: 'AIA', alpha3Code: 'Anguilla' }, label: 'Anguilla' },
    { value: { englishShortName: 'ATA', alpha3Code: 'Antarctica' }, label: 'Antarctica' },
    { value: { englishShortName: 'ATG', alpha3Code: 'Antigua and Barbuda' }, label: 'Antigua and Barbuda' },
    { value: { englishShortName: 'ARG', alpha3Code: 'Argentina' }, label: 'Argentina' },
    { value: { englishShortName: 'ARM', alpha3Code: 'Armenia' }, label: 'Armenia' },
    { value: { englishShortName: 'ABW', alpha3Code: 'Aruba' }, label: 'Aruba' },
    { value: { englishShortName: 'AUS', alpha3Code: 'Australia' }, label: 'Australia' },
    { value: { englishShortName: 'AUT', alpha3Code: 'Austria' }, label: 'Austria' },
    { value: { englishShortName: 'AZE', alpha3Code: 'Azerbaijan' }, label: 'Azerbaijan' },
    { value: { englishShortName: 'BHS', alpha3Code: 'Bahamas (the)' }, label: 'Bahamas (the)' },
    { value: { englishShortName: 'BHR', alpha3Code: 'Bahrain' }, label: 'Bahrain' },
    { value: { englishShortName: 'BGD', alpha3Code: 'Bangladesh' }, label: 'Bangladesh' },
    { value: { englishShortName: 'BRB', alpha3Code: 'Barbados' }, label: 'Barbados' },
    { value: { englishShortName: 'BLR', alpha3Code: 'Belarus' }, label: 'Belarus' },
    { value: { englishShortName: 'BEL', alpha3Code: 'Belgium' }, label: 'Belgium' },
    { value: { englishShortName: 'BLZ', alpha3Code: 'Belize' }, label: 'Belize' },
    { value: { englishShortName: 'BEN', alpha3Code: 'Benin' }, label: 'Benin' },
    { value: { englishShortName: 'BMU', alpha3Code: 'Bermuda' }, label: 'Bermuda' },
    { value: { englishShortName: 'BTN', alpha3Code: 'Bhutan' }, label: 'Bhutan' },
    { value: { englishShortName: 'BOL', alpha3Code: 'Bolivia (Plurinational State of)' }, label: 'Bolivia (Plurinational State of)' },
    { value: { englishShortName: 'BES', alpha3Code: 'Bonaire, Sint Eustatius and Saba' }, label: 'Bonaire, Sint Eustatius and Saba' },
    { value: { englishShortName: 'BIH', alpha3Code: 'Bosnia and Herzegovina' }, label: 'Bosnia and Herzegovina' },
    { value: { englishShortName: 'BWA', alpha3Code: 'Botswana' }, label: 'Botswana' },
    { value: { englishShortName: 'BVT', alpha3Code: 'Bouvet Island' }, label: 'Bouvet Island' },
    { value: { englishShortName: 'BRA', alpha3Code: 'Brazil' }, label: 'Brazil' },
    { value: { englishShortName: 'IOT', alpha3Code: 'British Indian Ocean Territory (the)' }, label: 'British Indian Ocean Territory (the)' },
    { value: { englishShortName: 'BRN', alpha3Code: 'Brunei Darussalam' }, label: 'Brunei Darussalam' },
    { value: { englishShortName: 'BGR', alpha3Code: 'Bulgaria' }, label: 'Bulgaria' },
    { value: { englishShortName: 'BFA', alpha3Code: 'Burkina Faso' }, label: 'Burkina Faso' },
    { value: { englishShortName: 'BDI', alpha3Code: 'Burundi' }, label: 'Burundi' },
    { value: { englishShortName: 'CPV', alpha3Code: 'Cabo Verde' }, label: 'Cabo Verde' },
    { value: { englishShortName: 'KHM', alpha3Code: 'Cambodia' }, label: 'Cambodia' },
    { value: { englishShortName: 'CMR', alpha3Code: 'Cameroon' }, label: 'Cameroon' },
    { value: { englishShortName: 'CAN', alpha3Code: 'Canada' }, label: 'Canada' },
    { value: { englishShortName: 'CYM', alpha3Code: 'Cayman Islands (the)' }, label: 'Cayman Islands (the)' },
    { value: { englishShortName: 'CAF', alpha3Code: 'Central African Republic (the)' }, label: 'Central African Republic (the)' },
    { value: { englishShortName: 'TCD', alpha3Code: 'Chad' }, label: 'Chad' },
    { value: { englishShortName: 'CHL', alpha3Code: 'Chile' }, label: 'Chile' },
    { value: { englishShortName: 'CHN', alpha3Code: 'China' }, label: 'China' },
    { value: { englishShortName: 'CXR', alpha3Code: 'Christmas Island' }, label: 'Christmas Island' },
    { value: { englishShortName: 'CCK', alpha3Code: 'Cocos (Keeling) Islands (the)' }, label: 'Cocos (Keeling) Islands (the)' },
    { value: { englishShortName: 'COL', alpha3Code: 'Colombia' }, label: 'Colombia' },
    { value: { englishShortName: 'COM', alpha3Code: 'Comoros (the)' }, label: 'Comoros (the)' },
    { value: { englishShortName: 'COD', alpha3Code: 'Congo (the Democratic Republic of the)' }, label: 'Congo (the Democratic Republic of the)' },
    { value: { englishShortName: 'COG', alpha3Code: 'Congo (the)' }, label: 'Congo (the)' },
    { value: { englishShortName: 'COK', alpha3Code: 'Cook Islands (the)' }, label: 'Cook Islands (the)' },
    { value: { englishShortName: 'CRI', alpha3Code: 'Costa Rica' }, label: 'Costa Rica' },
    { value: { englishShortName: 'HRV', alpha3Code: 'Croatia' }, label: 'Croatia' },
    { value: { englishShortName: 'CUB', alpha3Code: 'Cuba' }, label: 'Cuba' },
    { value: { englishShortName: 'CUW', alpha3Code: 'Curaçao' }, label: 'Curaçao' },
    { value: { englishShortName: 'CYP', alpha3Code: 'Cyprus' }, label: 'Cyprus' },
    { value: { englishShortName: 'CZE', alpha3Code: 'Czechia' }, label: 'Czechia' },
    { value: { englishShortName: 'CIV', alpha3Code: "Côte d'Ivoire" }, label: "Côte d'Ivoire" },
    { value: { englishShortName: 'DNK', alpha3Code: 'Denmark' }, label: 'Denmark' },
    { value: { englishShortName: 'DJI', alpha3Code: 'Djibouti' }, label: 'Djibouti' },
    { value: { englishShortName: 'DMA', alpha3Code: 'Dominica' }, label: 'Dominica' },
    { value: { englishShortName: 'DOM', alpha3Code: 'Dominican Republic (the)' }, label: 'Dominican Republic (the)' },
    { value: { englishShortName: 'ECU', alpha3Code: 'Ecuador' }, label: 'Ecuador' },
    { value: { englishShortName: 'EGY', alpha3Code: 'Egypt' }, label: 'Egypt' },
    { value: { englishShortName: 'SLV', alpha3Code: 'El Salvador' }, label: 'El Salvador' },
    { value: { englishShortName: 'GNQ', alpha3Code: 'Equatorial Guinea' }, label: 'Equatorial Guinea' },
    { value: { englishShortName: 'ERI', alpha3Code: 'Eritrea' }, label: 'Eritrea' },
    { value: { englishShortName: 'EST', alpha3Code: 'Estonia' }, label: 'Estonia' },
    { value: { englishShortName: 'SWZ', alpha3Code: 'Eswatini' }, label: 'Eswatini' },
    { value: { englishShortName: 'ETH', alpha3Code: 'Ethiopia' }, label: 'Ethiopia' },
    { value: { englishShortName: 'FLK', alpha3Code: 'Falkland Islands (the) [Malvinas]' }, label: 'Falkland Islands (the) [Malvinas]' },
    { value: { englishShortName: 'FRO', alpha3Code: 'Faroe Islands (the)' }, label: 'Faroe Islands (the)' },
    { value: { englishShortName: 'FJI', alpha3Code: 'Fiji' }, label: 'Fiji' },
    { value: { englishShortName: 'FIN', alpha3Code: 'Finland' }, label: 'Finland' },
    { value: { englishShortName: 'FRA', alpha3Code: 'France' }, label: 'France' },
    { value: { englishShortName: 'GUF', alpha3Code: 'French Guiana' }, label: 'French Guiana' },
    { value: { englishShortName: 'PYF', alpha3Code: 'French Polynesia' }, label: 'French Polynesia' },
    { value: { englishShortName: 'ATF', alpha3Code: 'French Southern Territories (the)' }, label: 'French Southern Territories (the)' },
    { value: { englishShortName: 'GAB', alpha3Code: 'Gabon' }, label: 'Gabon' },
    { value: { englishShortName: 'GMB', alpha3Code: 'Gambia (the)' }, label: 'Gambia (the)' },
    { value: { englishShortName: 'GEO', alpha3Code: 'Georgia' }, label: 'Georgia' },
    { value: { englishShortName: 'DEU', alpha3Code: 'Germany' }, label: 'Germany' },
    { value: { englishShortName: 'GHA', alpha3Code: 'Ghana' }, label: 'Ghana' },
    { value: { englishShortName: 'GIB', alpha3Code: 'Gibraltar' }, label: 'Gibraltar' },
    { value: { englishShortName: 'GRC', alpha3Code: 'Greece' }, label: 'Greece' },
    { value: { englishShortName: 'GRL', alpha3Code: 'Greenland' }, label: 'Greenland' },
    { value: { englishShortName: 'GRD', alpha3Code: 'Grenada' }, label: 'Grenada' },
    { value: { englishShortName: 'GLP', alpha3Code: 'Guadeloupe' }, label: 'Guadeloupe' },
    { value: { englishShortName: 'GUM', alpha3Code: 'Guam' }, label: 'Guam' },
    { value: { englishShortName: 'GTM', alpha3Code: 'Guatemala' }, label: 'Guatemala' },
    { value: { englishShortName: 'GGY', alpha3Code: 'Guernsey' }, label: 'Guernsey' },
    { value: { englishShortName: 'GIN', alpha3Code: 'Guinea' }, label: 'Guinea' },
    { value: { englishShortName: 'GNB', alpha3Code: 'Guinea-Bissau' }, label: 'Guinea-Bissau' },
    { value: { englishShortName: 'GUY', alpha3Code: 'Guyana' }, label: 'Guyana' },
    { value: { englishShortName: 'HTI', alpha3Code: 'Haiti' }, label: 'Haiti' },
    { value: { englishShortName: 'HMD', alpha3Code: 'Heard Island and McDonald Islands' }, label: 'Heard Island and McDonald Islands' },
    { value: { englishShortName: 'VAT', alpha3Code: 'Holy See (the)' }, label: 'Holy See (the)' },
    { value: { englishShortName: 'HND', alpha3Code: 'Honduras' }, label: 'Honduras' },
    { value: { englishShortName: 'HKG', alpha3Code: 'Hong Kong' }, label: 'Hong Kong' },
    { value: { englishShortName: 'HUN', alpha3Code: 'Hungary' }, label: 'Hungary' },
    { value: { englishShortName: 'ISL', alpha3Code: 'Iceland' }, label: 'Iceland' },
    { value: { englishShortName: 'IND', alpha3Code: 'India' }, label: 'India' },
    { value: { englishShortName: 'IDN', alpha3Code: 'Indonesia' }, label: 'Indonesia' },
    { value: { englishShortName: 'IRN', alpha3Code: 'Iran (Islamic Republic of)' }, label: 'Iran (Islamic Republic of)' },
    { value: { englishShortName: 'IRQ', alpha3Code: 'Iraq' }, label: 'Iraq' },
    { value: { englishShortName: 'IRL', alpha3Code: 'Ireland' }, label: 'Ireland' },
    { value: { englishShortName: 'IMN', alpha3Code: 'Isle of Man' }, label: 'Isle of Man' },
    { value: { englishShortName: 'ISR', alpha3Code: 'Israel' }, label: 'Israel' },
    { value: { englishShortName: 'ITA', alpha3Code: 'Italy' }, label: 'Italy' },
    { value: { englishShortName: 'JAM', alpha3Code: 'Jamaica' }, label: 'Jamaica' },
    { value: { englishShortName: 'JPN', alpha3Code: 'Japan' }, label: 'Japan' },
    { value: { englishShortName: 'JEY', alpha3Code: 'Jersey' }, label: 'Jersey' },
    { value: { englishShortName: 'JOR', alpha3Code: 'Jordan' }, label: 'Jordan' },
    { value: { englishShortName: 'KAZ', alpha3Code: 'Kazakhstan' }, label: 'Kazakhstan' },
    { value: { englishShortName: 'KEN', alpha3Code: 'Kenya' }, label: 'Kenya' },
    { value: { englishShortName: 'KIR', alpha3Code: 'Kiribati' }, label: 'Kiribati' },
    { value: { englishShortName: 'PRK', alpha3Code: "Korea (the Democratic People's Republic of)" }, label: "Korea (the Democratic People's Republic of)" },
    { value: { englishShortName: 'KOR', alpha3Code: 'Korea (the Republic of)' }, label: 'Korea (the Republic of)' },
    { value: { englishShortName: 'KWT', alpha3Code: 'Kuwait' }, label: 'Kuwait' },
    { value: { englishShortName: 'KGZ', alpha3Code: 'Kyrgyzstan' }, label: 'Kyrgyzstan' },
    { value: { englishShortName: 'LAO', alpha3Code: "Lao People's Democratic Republic (the)" }, label: "Lao People's Democratic Republic (the)" },
    { value: { englishShortName: 'LVA', alpha3Code: 'Latvia' }, label: 'Latvia' },
    { value: { englishShortName: 'LBN', alpha3Code: 'Lebanon' }, label: 'Lebanon' },
    { value: { englishShortName: 'LSO', alpha3Code: 'Lesotho' }, label: 'Lesotho' },
    { value: { englishShortName: 'LBR', alpha3Code: 'Liberia' }, label: 'Liberia' },
    { value: { englishShortName: 'LBY', alpha3Code: 'Libya' }, label: 'Libya' },
    { value: { englishShortName: 'LIE', alpha3Code: 'Liechtenstein' }, label: 'Liechtenstein' },
    { value: { englishShortName: 'LTU', alpha3Code: 'Lithuania' }, label: 'Lithuania' },
    { value: { englishShortName: 'LUX', alpha3Code: 'Luxembourg' }, label: 'Luxembourg' },
    { value: { englishShortName: 'MAC', alpha3Code: 'Macao' }, label: 'Macao' },
    { value: { englishShortName: 'MDG', alpha3Code: 'Madagascar' }, label: 'Madagascar' },
    { value: { englishShortName: 'MWI', alpha3Code: 'Malawi' }, label: 'Malawi' },
    { value: { englishShortName: 'MYS', alpha3Code: 'Malaysia' }, label: 'Malaysia' },
    { value: { englishShortName: 'MDV', alpha3Code: 'Maldives' }, label: 'Maldives' },
    { value: { englishShortName: 'MLI', alpha3Code: 'Mali' }, label: 'Mali' },
    { value: { englishShortName: 'MLT', alpha3Code: 'Malta' }, label: 'Malta' },
    { value: { englishShortName: 'MHL', alpha3Code: 'Marshall Islands (the)' }, label: 'Marshall Islands (the)' },
    { value: { englishShortName: 'MTQ', alpha3Code: 'Martinique' }, label: 'Martinique' },
    { value: { englishShortName: 'MRT', alpha3Code: 'Mauritania' }, label: 'Mauritania' },
    { value: { englishShortName: 'MUS', alpha3Code: 'Mauritius' }, label: 'Mauritius' },
    { value: { englishShortName: 'MYT', alpha3Code: 'Mayotte' }, label: 'Mayotte' },
    { value: { englishShortName: 'MEX', alpha3Code: 'Mexico' }, label: 'Mexico' },
    { value: { englishShortName: 'FSM', alpha3Code: 'Micronesia (Federated States of)' }, label: 'Micronesia (Federated States of)' },
    { value: { englishShortName: 'MDA', alpha3Code: 'Moldova (the Republic of)' }, label: 'Moldova (the Republic of)' },
    { value: { englishShortName: 'MCO', alpha3Code: 'Monaco' }, label: 'Monaco' },
    { value: { englishShortName: 'MNG', alpha3Code: 'Mongolia' }, label: 'Mongolia' },
    { value: { englishShortName: 'MNE', alpha3Code: 'Montenegro' }, label: 'Montenegro' },
    { value: { englishShortName: 'MSR', alpha3Code: 'Montserrat' }, label: 'Montserrat' },
    { value: { englishShortName: 'MAR', alpha3Code: 'Morocco' }, label: 'Morocco' },
    { value: { englishShortName: 'MOZ', alpha3Code: 'Mozambique' }, label: 'Mozambique' },
    { value: { englishShortName: 'MMR', alpha3Code: 'Myanmar' }, label: 'Myanmar' },
    { value: { englishShortName: 'NAM', alpha3Code: 'Namibia' }, label: 'Namibia' },
    { value: { englishShortName: 'NRU', alpha3Code: 'Nauru' }, label: 'Nauru' },
    { value: { englishShortName: 'NPL', alpha3Code: 'Nepal' }, label: 'Nepal' },
    { value: { englishShortName: 'NLD', alpha3Code: 'Netherlands (Kingdom of the)' }, label: 'Netherlands (Kingdom of the)' },
    { value: { englishShortName: 'NCL', alpha3Code: 'New Caledonia' }, label: 'New Caledonia' },
    { value: { englishShortName: 'NZL', alpha3Code: 'New Zealand' }, label: 'New Zealand' },
    { value: { englishShortName: 'NIC', alpha3Code: 'Nicaragua' }, label: 'Nicaragua' },
    { value: { englishShortName: 'NER', alpha3Code: 'Niger (the)' }, label: 'Niger (the)' },
    { value: { englishShortName: 'NGA', alpha3Code: 'Nigeria' }, label: 'Nigeria' },
    { value: { englishShortName: 'NIU', alpha3Code: 'Niue' }, label: 'Niue' },
    { value: { englishShortName: 'NFK', alpha3Code: 'Norfolk Island' }, label: 'Norfolk Island' },
    { value: { englishShortName: 'MKD', alpha3Code: 'North Macedonia' }, label: 'North Macedonia' },
    { value: { englishShortName: 'MNP', alpha3Code: 'Northern Mariana Islands (the)' }, label: 'Northern Mariana Islands (the)' },
    { value: { englishShortName: 'NOR', alpha3Code: 'Norway' }, label: 'Norway' },
    { value: { englishShortName: 'OMN', alpha3Code: 'Oman' }, label: 'Oman' },
    { value: { englishShortName: 'PAK', alpha3Code: 'Pakistan' }, label: 'Pakistan' },
    { value: { englishShortName: 'PLW', alpha3Code: 'Palau' }, label: 'Palau' },
    { value: { englishShortName: 'PSE', alpha3Code: 'Palestine, State of' }, label: 'Palestine, State of' },
    { value: { englishShortName: 'PAN', alpha3Code: 'Panama' }, label: 'Panama' },
    { value: { englishShortName: 'PNG', alpha3Code: 'Papua New Guinea' }, label: 'Papua New Guinea' },
    { value: { englishShortName: 'PRY', alpha3Code: 'Paraguay' }, label: 'Paraguay' },
    { value: { englishShortName: 'PER', alpha3Code: 'Peru' }, label: 'Peru' },
    { value: { englishShortName: 'PHL', alpha3Code: 'Philippines (the)' }, label: 'Philippines (the)' },
    { value: { englishShortName: 'PCN', alpha3Code: 'Pitcairn' }, label: 'Pitcairn' },
    { value: { englishShortName: 'POL', alpha3Code: 'Poland' }, label: 'Poland' },
    { value: { englishShortName: 'PRT', alpha3Code: 'Portugal' }, label: 'Portugal' },
    { value: { englishShortName: 'PRI', alpha3Code: 'Puerto Rico' }, label: 'Puerto Rico' },
    { value: { englishShortName: 'QAT', alpha3Code: 'Qatar' }, label: 'Qatar' },
    { value: { englishShortName: 'ROU', alpha3Code: 'Romania' }, label: 'Romania' },
    { value: { englishShortName: 'RUS', alpha3Code: 'Russian Federation (the)' }, label: 'Russian Federation (the)' },
    { value: { englishShortName: 'RWA', alpha3Code: 'Rwanda' }, label: 'Rwanda' },
    { value: { englishShortName: 'REU', alpha3Code: 'Réunion' }, label: 'Réunion' },
    { value: { englishShortName: 'BLM', alpha3Code: 'Saint Barthélemy' }, label: 'Saint Barthélemy' },
    { value: { englishShortName: 'SHN', alpha3Code: 'Saint Helena, Ascension and Tristan da Cunha' }, label: 'Saint Helena, Ascension and Tristan da Cunha' },
    { value: { englishShortName: 'KNA', alpha3Code: 'Saint Kitts and Nevis' }, label: 'Saint Kitts and Nevis' },
    { value: { englishShortName: 'LCA', alpha3Code: 'Saint Lucia' }, label: 'Saint Lucia' },
    { value: { englishShortName: 'MAF', alpha3Code: 'Saint Martin (French part)' }, label: 'Saint Martin (French part)' },
    { value: { englishShortName: 'SPM', alpha3Code: 'Saint Pierre and Miquelon' }, label: 'Saint Pierre and Miquelon' },
    { value: { englishShortName: 'VCT', alpha3Code: 'Saint Vincent and the Grenadines' }, label: 'Saint Vincent and the Grenadines' },
    { value: { englishShortName: 'WSM', alpha3Code: 'Samoa' }, label: 'Samoa' },
    { value: { englishShortName: 'SMR', alpha3Code: 'San Marino' }, label: 'San Marino' },
    { value: { englishShortName: 'STP', alpha3Code: 'Sao Tome and Principe' }, label: 'Sao Tome and Principe' },
    { value: { englishShortName: 'SAU', alpha3Code: 'Saudi Arabia' }, label: 'Saudi Arabia' },
    { value: { englishShortName: 'SEN', alpha3Code: 'Senegal' }, label: 'Senegal' },
    { value: { englishShortName: 'SRB', alpha3Code: 'Serbia' }, label: 'Serbia' },
    { value: { englishShortName: 'SYC', alpha3Code: 'Seychelles' }, label: 'Seychelles' },
    { value: { englishShortName: 'SLE', alpha3Code: 'Sierra Leone' }, label: 'Sierra Leone' },
    { value: { englishShortName: 'SGP', alpha3Code: 'Singapore' }, label: 'Singapore' },
    { value: { englishShortName: 'SXM', alpha3Code: 'Sint Maarten (Dutch part)' }, label: 'Sint Maarten (Dutch part)' },
    { value: { englishShortName: 'SVK', alpha3Code: 'Slovakia' }, label: 'Slovakia' },
    { value: { englishShortName: 'SVN', alpha3Code: 'Slovenia' }, label: 'Slovenia' },
    { value: { englishShortName: 'SLB', alpha3Code: 'Solomon Islands' }, label: 'Solomon Islands' },
    { value: { englishShortName: 'SOM', alpha3Code: 'Somalia' }, label: 'Somalia' },
    { value: { englishShortName: 'ZAF', alpha3Code: 'South Africa' }, label: 'South Africa' },
    { value: { englishShortName: 'SGS', alpha3Code: 'South Georgia and the South Sandwich Islands' }, label: 'South Georgia and the South Sandwich Islands' },
    { value: { englishShortName: 'SSD', alpha3Code: 'South Sudan' }, label: 'South Sudan' },
    { value: { englishShortName: 'ESP', alpha3Code: 'Spain' }, label: 'Spain' },
    { value: { englishShortName: 'LKA', alpha3Code: 'Sri Lanka' }, label: 'Sri Lanka' },
    { value: { englishShortName: 'SDN', alpha3Code: 'Sudan (the)' }, label: 'Sudan (the)' },
    { value: { englishShortName: 'SUR', alpha3Code: 'Suriname' }, label: 'Suriname' },
    { value: { englishShortName: 'SJM', alpha3Code: 'Svalbard and Jan Mayen' }, label: 'Svalbard and Jan Mayen' },
    { value: { englishShortName: 'SWE', alpha3Code: 'Sweden' }, label: 'Sweden' },
    { value: { englishShortName: 'CHE', alpha3Code: 'Switzerland' }, label: 'Switzerland' },
    { value: { englishShortName: 'SYR', alpha3Code: 'Syrian Arab Republic (the)' }, label: 'Syrian Arab Republic (the)' },
    { value: { englishShortName: 'TWN', alpha3Code: 'Taiwan (Province of China)' }, label: 'Taiwan (Province of China)' },
    { value: { englishShortName: 'TJK', alpha3Code: 'Tajikistan' }, label: 'Tajikistan' },
    { value: { englishShortName: 'TZA', alpha3Code: 'Tanzania, the United Republic of' }, label: 'Tanzania, the United Republic of' },
    { value: { englishShortName: 'THA', alpha3Code: 'Thailand' }, label: 'Thailand' },
    { value: { englishShortName: 'TLS', alpha3Code: 'Timor-Leste' }, label: 'Timor-Leste' },
    { value: { englishShortName: 'TGO', alpha3Code: 'Togo' }, label: 'Togo' },
    { value: { englishShortName: 'TKL', alpha3Code: 'Tokelau' }, label: 'Tokelau' },
    { value: { englishShortName: 'TON', alpha3Code: 'Tonga' }, label: 'Tonga' },
    { value: { englishShortName: 'TTO', alpha3Code: 'Trinidad and Tobago' }, label: 'Trinidad and Tobago' },
    { value: { englishShortName: 'TUN', alpha3Code: 'Tunisia' }, label: 'Tunisia' },
    { value: { englishShortName: 'TKM', alpha3Code: 'Turkmenistan' }, label: 'Turkmenistan' },
    { value: { englishShortName: 'TCA', alpha3Code: 'Turks and Caicos Islands (the)' }, label: 'Turks and Caicos Islands (the)' },
    { value: { englishShortName: 'TUV', alpha3Code: 'Tuvalu' }, label: 'Tuvalu' },
    { value: { englishShortName: 'TUR', alpha3Code: 'Türkiye' }, label: 'Türkiye' },
    { value: { englishShortName: 'UGA', alpha3Code: 'Uganda' }, label: 'Uganda' },
    { value: { englishShortName: 'UKR', alpha3Code: 'Ukraine' }, label: 'Ukraine' },
    { value: { englishShortName: 'ARE', alpha3Code: 'United Arab Emirates (the)' }, label: 'United Arab Emirates (the)' },
    {
      value: { englishShortName: 'GBR', alpha3Code: 'United Kingdom of Great Britain and Northern Ireland (the)' },
      label: 'United Kingdom of Great Britain and Northern Ireland (the)',
    },
    { value: { englishShortName: 'UMI', alpha3Code: 'United States Minor Outlying Islands (the)' }, label: 'United States Minor Outlying Islands (the)' },
    { value: { englishShortName: 'USA', alpha3Code: 'United States of America (the)' }, label: 'United States of America (the)' },
    { value: { englishShortName: 'URY', alpha3Code: 'Uruguay' }, label: 'Uruguay' },
    { value: { englishShortName: 'UZB', alpha3Code: 'Uzbekistan' }, label: 'Uzbekistan' },
    { value: { englishShortName: 'VUT', alpha3Code: 'Vanuatu' }, label: 'Vanuatu' },
    { value: { englishShortName: 'VEN', alpha3Code: 'Venezuela (Bolivarian Republic of)' }, label: 'Venezuela (Bolivarian Republic of)' },
    { value: { englishShortName: 'VNM', alpha3Code: 'Viet Nam' }, label: 'Viet Nam' },
    { value: { englishShortName: 'VGB', alpha3Code: 'Virgin Islands (British)' }, label: 'Virgin Islands (British)' },
    { value: { englishShortName: 'VIR', alpha3Code: 'Virgin Islands (U.S.)' }, label: 'Virgin Islands (U.S.)' },
    { value: { englishShortName: 'WLF', alpha3Code: 'Wallis and Futuna' }, label: 'Wallis and Futuna' },
    { value: { englishShortName: 'ESH', alpha3Code: 'Western Sahara*' }, label: 'Western Sahara*' },
    { value: { englishShortName: 'YEM', alpha3Code: 'Yemen' }, label: 'Yemen' },
    { value: { englishShortName: 'ZMB', alpha3Code: 'Zambia' }, label: 'Zambia' },
    { value: { englishShortName: 'ZWE', alpha3Code: 'Zimbabwe' }, label: 'Zimbabwe' },
    { value: { englishShortName: 'ALA', alpha3Code: 'Åland Islands' }, label: 'Åland Islands' },
  ];

  return (
    <div ref={detailRef} className="mt-8 sm:mt-12">
      <div className="text-black text-md underline sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1 invisible  print:visible">Detail</div>
      <DateField name="dob" label="Date of birth" />
      <SelectField label="Title" name="title" options={titleOptions} />
      <SelectField label="Name Suffix" name="nameSuffix" options={nameSuffixOptions} />
      <InputField label="Preferred Name" name="preferredName" placeholder="Preferred Name" />
      <NHSSelectField label="Religion" name="religion" options={religionOptions} searchable />
      <NHSSelectField label="Ethnicity" name="ethnicity" options={ethnicOptions} searchable />
      <NHSSelectField label="Sex" name="sex" options={sexOptions} />
      <NHSSelectField label="Place of birth" name="placeOfBirth" options={countryOptions} searchable />
      <InputField label="Contact email address" name="contactEmailAddress" placeholder="Contact email address" />
      <FormatedNumberField isRequired={false} format="### ### ####" label="NHS number" placeholder="NHS number" name="nhsNumber" />
      <DateField name="firstVisitDate" label="First Visit Date" />
      <FormLabel label="Phone">
        <div className="w-full lg:w-1/2">
          <Input
            disabled={true}
            value={customer?.phone || ''}
            placeholder="Phone"
            error={
              !customer?.phone ? (
                <p>
                  Support worker has no phone number &ensp;
                  <a className="underline" target="_blank" rel="noreferrer" href="https://www.hubspot.com/">
                    please check the phone number in hubspot
                  </a>
                </p>
              ) : undefined
            }
          />
        </div>
      </FormLabel>
      <FormLabel label="Maps Coordinates">
        <div className="w-full lg:w-1/2">
          <Input
            disabled={true}
            value={customer?.lat !== 0 || customer?.lng !== 0 ? `${customer?.lat.toFixed(2)}/${customer?.lng.toFixed(2)}` : ''}
            placeholder="Maps Coordinates"
            error={customer?.lat === 0 || customer?.lng === 0 ? 'Please Activate Maps' : undefined}
          />
        </div>
      </FormLabel>
      <FormLabel label="Team">
        <div className="w-full lg:w-1/2">
          <TeamSelector selectTeam={setTeam} teamId={teamId} allTeams />
          {!customer?.teamId ||
            (!customer?.teamName && (
              <p>
                Supported person has no team&ensp;
                <a className="underline" target="_blank" rel="noreferrer" href="https://www.hubspot.com/">
                  please check the team in hubspot
                </a>
              </p>
            ))}
          {user?.permissions?.developer && (
            <div className="mt-4 print:hidden">
              <Button
                loading={teamChangeLoading}
                label="Change Team"
                color="bg-primary-700"
                onClick={() => onChangeAccount(SupportedPeopleStateTransition.changeTeam, teamId)}
                selector="change-team-button"
              />
            </div>
          )}
        </div>
      </FormLabel>
      <FormLabel label="Address">
        <div className="w-full lg:w-1/2 flex flex-col gap-2">
          <Input
            disabled={true}
            value={customer?.address || ''}
            placeholder="Address"
            error={
              !customer?.address ? (
                <p>
                  Support worker has no address &ensp;
                  <a className="underline" target="_blank" rel="noreferrer" href="https://www.hubspot.com/">
                    please check the address in hubspot
                  </a>
                </p>
              ) : undefined
            }
          />
          <Input
            disabled={true}
            value={customer?.city || ''}
            placeholder="City"
            error={
              !customer?.city ? (
                <p>
                  Support worker has no city &ensp;
                  <a className="underline" target="_blank" rel="noreferrer" href="https://www.hubspot.com/">
                    please check the address in hubspot
                  </a>
                </p>
              ) : undefined
            }
          />
          <Input
            disabled={true}
            value={customer?.postcode || ''}
            placeholder="Postcode"
            error={
              !customer?.postcode ? (
                <p>
                  Support worker has no postcode &ensp;
                  <a className="underline" target="_blank" rel="noreferrer" href="https://www.hubspot.com/">
                    please check the address in hubspot
                  </a>
                </p>
              ) : undefined
            }
          />
        </div>
      </FormLabel>
      <button type="submit" className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md print:hidden">
        Save
      </button>
    </div>
  );
};

export default ManageSupportedPeople;
