import React, { useState, useEffect } from 'react';
import getTime from 'date-fns/getTime';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import setMilliseconds from 'date-fns/setMilliseconds';
import setSeconds from 'date-fns/setSeconds';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { clockMessage } from 'config/messages';
import { Shift } from '__generated__/graphql';
import { SelectOption } from 'types/types';
import TextBox from 'components/TextBox';
import { ShiftRunTypeEnum, ShiftType, VisitStatus } from 'pages/ReportingHours/service';
import { Loading } from 'components';
import { getActiveVisitsForShift, shiftExceedsMaximumLength, ShiftLengthMessageError } from '../service';

interface AddVisitToShiftProps {
  shifts: Shift[];
  addVisitToShiftModalState: boolean;
  toggleAddVisitToShiftModal: (addVisitToShiftModalState: boolean) => void;
  onAddVisitToShift: (item: Partial<Shift>) => Promise<void>;
  selectedShift: Shift | null;
  customers: SelectOption[];
  apiLoading: boolean;
}

export default function AddVisitToShift({
  shifts,
  toggleAddVisitToShiftModal,
  addVisitToShiftModalState,
  selectedShift,
  onAddVisitToShift,
  customers,
  apiLoading,
}: AddVisitToShiftProps) {
  const [customerId, setCustomer] = useState<string | null>(null);
  const [startDateTime, setStartDateTime] = useState<Date | null>(null);
  const [endDateTime, setEndDateTime] = useState<Date | null>(null);
  const [validation, setValidation] = useState<string | null>(null);
  const [oneOffVisitNote, setOneOffVisitNote] = useState<string | null>('');
  const [oneOffVisitReason, setOneOffVisitReason] = useState<string | null>('0');

  const shiftsForRun = shifts.filter((s: Shift) => s.shiftId === selectedShift?.shiftId);
  const activeShifts = getActiveVisitsForShift(shiftsForRun);

  useEffect(() => {
    const cleanDate = setMilliseconds(setSeconds(selectedShift?.startDateTime || 0, 0), 0);
    setStartDateTime(setHours(setMinutes(cleanDate, 0), 13));
    setEndDateTime(setHours(setMinutes(cleanDate, 0), 14));
    setValidation(null);
  }, [selectedShift]);

  if (!selectedShift) {
    return null;
  }

  const onChangeCustomer = (customer: string | null) => {
    setCustomer(customer);
    setValidation(null);
  };

  const onChangeStartDateTime = (dateTime: Date | null) => {
    setStartDateTime(dateTime);
    setValidation(null);
  };

  const onChangeEndDateTime = (dateTime: Date | null) => {
    setEndDateTime(dateTime);
    setValidation(null);
  };

  const onChangeOneOffVisitReason = (reason: string | null) => {
    setOneOffVisitReason(reason);
    setValidation(null);
  };

  const onChangeOneOffVisitNote = (note: string | null) => {
    setOneOffVisitNote(note);
    setValidation(null);
  };

  const onAddVisit = () => {
    setValidation(null);
    if (!selectedShift) return;

    if (!customerId) {
      setValidation('Please select a person');
      return;
    }

    if (!startDateTime || !endDateTime) {
      setValidation('Please select a shift date time and end time');
      return;
    }

    if (getTime(startDateTime) >= getTime(endDateTime)) {
      setValidation('Please provide a valid shift date time; end time should be greater than start time');
      return;
    }

    if (selectedShift?.shiftRun?.type === ShiftRunTypeEnum.agreed && getTime(startDateTime) < getTime(selectedShift?.shiftRun?.startDateTime || 0)) {
      setValidation('Please provide a visit start time that is not less than the shift start time');
      return;
    }

    if (selectedShift?.shiftRun?.type === ShiftRunTypeEnum.agreed && getTime(endDateTime) > getTime(selectedShift?.shiftRun?.endDateTime || 0)) {
      setValidation('Please provide a visit end time that is not greater than the shift end time');
      return;
    }

    if (oneOffVisitReason === '0') {
      setValidation('Please select a reason for this one off visit');
      return;
    }

    if (oneOffVisitNote === '') {
      setValidation('Please add a note for this one off visit');
      return;
    }

    const newVisit = {
      customerId,
      shiftId: selectedShift.shiftId,
      startDateTime: getTime(startDateTime),
      endDateTime: getTime(endDateTime),
      type: ShiftType.oneoff,
      oneOffVisitReason,
      oneOffVisitNote,
    };

    const newShift = [...activeShifts, { ...newVisit, id: '', visitId: '', status: VisitStatus.active, teamId: selectedShift.teamId }].sort(
      (x, y) => (x?.startDateTime || 0) - (y?.startDateTime || 0),
    );

    if (shiftExceedsMaximumLength(newShift)) {
      setValidation(ShiftLengthMessageError(ShiftType.visit));
      return;
    }

    onAddVisitToShift(newVisit);
  };

  return (
    <Dialog data-cy="add-visit-dialog" open={addVisitToShiftModalState} onClose={() => toggleAddVisitToShiftModal(false)}>
      <DialogTitle sx={{ fontSize: '1.6em', padding: { xs: '0.8em', sm: '1.2em' } }}>Add one-off visit to shift</DialogTitle>
      <DialogContent sx={{ padding: { xs: '0', sm: '1em' }, width: '100%' }}>
        {validation && (
          <Alert variant="filled" severity="error" sx={{ width: '90%', marginLeft: '1em', marginBottom: '1em' }}>
            {validation}
          </Alert>
        )}
        <FormControl data-cy="person-selector" sx={{ width: '90%', ml: '1em', mb: '1em' }}>
          <Typography>Select a person</Typography>
          <Select labelId="customer" id="customer" value={customerId} onChange={(e) => onChangeCustomer(e.target.value)}>
            {customers.map((option: SelectOption, i: number) => (
              <MenuItem data-cy={`person-item-${i}`} key={option.value} value={option.value.toString()}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <br />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <FormControl sx={{ width: '90%', ml: '1em', mb: '1em' }}>
            <Typography>Start time</Typography>
            <TimePicker
              ampm={false}
              minutesStep={5}
              value={startDateTime}
              onChange={onChangeStartDateTime}
              slotProps={{ textField: { helperText: clockMessage } }}
            />
          </FormControl>
          &nbsp;
          <FormControl sx={{ width: '90%', ml: '1em', mb: '1em' }}>
            <Typography>End time</Typography>
            <TimePicker
              ampm={false}
              minutesStep={5}
              value={endDateTime}
              onChange={onChangeEndDateTime}
              slotProps={{ textField: { helperText: clockMessage } }}
            />
          </FormControl>
        </LocalizationProvider>
        <FormControl data-cy="reason-selector" sx={{ width: '90%', ml: '1em', mb: '1em', mt: '1em' }}>
          <Typography>What is the reason for this one off visit</Typography>
          <Select size="small" id="reason" value={oneOffVisitReason} onChange={(e) => onChangeOneOffVisitReason(e.target.value)}>
            <MenuItem value="0">What is the reason for this one off visit</MenuItem>
            <MenuItem data-cy="reason-item-0" value="Is a reschedule of a cancelled job (covered by package)">
              Is a reschedule of a cancelled job (covered by package)
            </MenuItem>
            <MenuItem data-cy="reason-item-1" value="Is an emergency visit (not chargeable)">
              Is an emergency visit (not chargeable)
            </MenuItem>
            <MenuItem data-cy="reason-item-2" value="Is an pre-agreed extra visit (chargeable)">
              Is an pre-agreed extra visit (chargeable)
            </MenuItem>
            <MenuItem data-cy="reason-item-3" value="Is an additional complementary visit using banked hours">
              Is an additional complementary visit using banked hours
            </MenuItem>
            <MenuItem data-cy="reason-item-4" value="New package starting at short notice (chargeable)">
              New package starting at short notice (chargeable)
            </MenuItem>
            <MenuItem data-cy="reason-item-5" value="Package increase (chargeable)">
              Package increase (chargeable)
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl data-cy="reason-for-visit" sx={{ width: '90%', ml: '1em', mb: '1em', mt: '0.4em' }}>
          <Typography>One off visit note</Typography>
          <TextBox value={oneOffVisitNote} onChange={onChangeOneOffVisitNote} limit={1000} />
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ padding: { xs: '2em', sm: '1em' } }}>
        <Button data-cy="close-visit-button" variant="contained" onClick={() => toggleAddVisitToShiftModal(false)}>
          Close
        </Button>
        {!validation && (
          <div>
            {apiLoading ? (
              <Loading isComponent />
            ) : (
              <Button data-cy="add-visit-button" variant="contained" onClick={() => onAddVisit()}>
                Add
              </Button>
            )}
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
}
