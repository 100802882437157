import { Controller, useFormContext } from 'react-hook-form';

const RadioField = ({
  label,
  note,
  options,
  name,
  disabled,
  isRequired = false,
}: {
  label: string;
  note?: string;
  disabled?: boolean;
  options: { id?: string; label: string; value: number | string }[];
  name: string;
  isRequired?: boolean;
}) => {
  const { control, register } = useFormContext(); // retrieve all hook methods

  return (
    <>
      <p className=" text-primary-500  text-md leading-sm font-bold">{label}</p>
      <br />

      {note && (
        <>
          <div className=" text-gray-700 text-md leading-sm italic">{note}</div>
          <br />
        </>
      )}
      <Controller
        name={name}
        control={control}
        rules={isRequired ? { required: 'This is required.' } : {}}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <ul className="ps-5  py-2  text-gray-500  list-inside dark:text-gray-400">
              {options.map((option) => {
                return (
                  <label htmlFor={option.id} className="flex items-center mb-4 space-x-2 ">
                    <input
                      className="w-4 h-4 text-primary-500 bg-gray-100 border-gray-300  "
                      type="radio"
                      ref={register(name).ref}
                      disabled={disabled}
                      onChange={onChange}
                      id={option.id}
                      name={name}
                      checked={value?.toString() === option.value.toString()}
                      value={option.value}
                    />
                    <div className=" text-gray-500">{option.label}</div>
                  </label>
                );
              })}
              {error && (
                <p className="mt-2 text-sm text-error-600 dark:text-green-500">
                  <span className="font-medium">{error?.message}</span>
                </p>
              )}
            </ul>
          );
        }}
      />
    </>
  );
};

export { RadioField };
