import { useQuery, useMutation, gql } from '@apollo/client';
import { CustomerSearch, Family, FamilyMembersSearch } from '../../__generated__/graphql';
import { refetchCustomer } from './useCustomers';

export const GET_FAMILY_MEMBERS_BY_TEAM = gql`
  query GetFamilyMembersByTeam($query: FamilyMembersSearch) {
    GetFamilyMembersByTeam(query: $query) {
      id
      externalId
      teamId
      email
      name
      isActive
      cognito
      token
      invoice
    }
  }
`;

export const UPDATE_FAMILY_MEMBER = gql`
  mutation UpdateFamilyMember($input: UpdateFamilyMemberInput) {
    UpdateFamilyMember(input: $input) {
      id
      message
      date
    }
  }
`;

interface Response {
  GetFamilyMembersByTeam: Family[];
}

export const useGetFamilyMembers = (query: FamilyMembersSearch) => {
  const { data, loading } = useQuery<Response>(GET_FAMILY_MEMBERS_BY_TEAM, {
    variables: {
      query: { ...query },
    },
  });

  return { familyMembers: data?.GetFamilyMembersByTeam || [], familyMembersLoading: loading };
};

export const useUpdateFamilyMember = (query: CustomerSearch) => {
  const [updateFamilyMember, mutationUpdateFamilyMember] = useMutation(UPDATE_FAMILY_MEMBER, refetchCustomer(query));
  return { updateFamilyMember, mutationUpdateFamilyMember };
};
