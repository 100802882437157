import { useEffect, useState } from 'react';
import { Delete, WarningAmber } from '@mui/icons-material';
import ReactSelect from 'components/Select';
import TimePicker from 'components/TimePicker';
import { EditShiftRunData } from '../types';

interface EditShiftRunProps {
  onEditRun: (editShiftRunData: EditShiftRunData) => void;
  editShiftRunData: EditShiftRunData;
  onClose: () => void;
  onRemoveRun: (editShiftRunData: EditShiftRunData) => void;
}

export default function EditShiftRun({ onEditRun, editShiftRunData, onClose, onRemoveRun }: EditShiftRunProps) {
  const [startDateTime, setStartDateTime] = useState(new Date(editShiftRunData.startDateTime));
  const [endDateTime, setEndDateTime] = useState(new Date(editShiftRunData.endDateTime));
  const [week, setWeek] = useState({ value: editShiftRunData.week.toString(), label: `Week ${editShiftRunData.week}` });
  const weekOptions = [
    { value: '1', label: 'Week 1' },
    { value: '2', label: 'Week 2' },
  ];

  useEffect(() => {
    if (editShiftRunData) {
      setStartDateTime(new Date(editShiftRunData.startDateTime));
      setEndDateTime(new Date(editShiftRunData.endDateTime));
      setWeek({ value: editShiftRunData.week.toString(), label: `Week ${editShiftRunData.week}` });
    }
  }, [editShiftRunData.startDateTime, editShiftRunData.endDateTime, editShiftRunData.week, editShiftRunData]);

  const onChangeStartDateTime = (dateTime: Date | null) => {
    if (dateTime) {
      setStartDateTime(dateTime);
      if (editShiftRunData.canEdit) setEndDateTime(new Date(new Date(dateTime).setHours(dateTime.getHours() + 5)));
    }
  };

  const onChangeEndDateTime = (dateTime: Date | null) => {
    if (dateTime) {
      setEndDateTime(dateTime);
    }
  };

  const onEditShiftRun = () => {
    const newEditShiftRun = editShiftRunData;
    newEditShiftRun.startDateTime = startDateTime.getTime();
    newEditShiftRun.endDateTime = endDateTime.getTime();
    newEditShiftRun.week = parseInt(week.value, 10);
    onEditRun(newEditShiftRun);
  };

  const onChangeStatusShiftRun = () => {
    const newEditShiftRun = editShiftRunData;
    if (newEditShiftRun.status === 'paused') {
      newEditShiftRun.status = 'active';
    } else {
      newEditShiftRun.status = 'paused';
    }
    onEditRun(newEditShiftRun);
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto mx-auto max-w-5xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6 max-h-screen md:max-h-[90vh] overflow-y-auto md:w-[680px]">
            <div className="text-gray-900 text-lg leading-lg font-semibold mb-5">Edit a run</div>
            {!editShiftRunData.canEdit && (
              <>
                <div className="flex items-center gap-3 text-md display-md text-warning-700 font-medium">
                  <WarningAmber />
                  You are not able to edit the week or the time because there is already visits inside this shift run.
                </div>
                <div className="flex items-center gap-3 text-md display-md text-warning-700 font-medium mt-2">
                  <WarningAmber />
                  Be careful removing this shift run will delete all the visits inside.
                </div>
              </>
            )}
            <div className="my-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Week</div>
              <div className="mt-2">
                <ReactSelect selectedValue={week} options={weekOptions} onChange={(option) => setWeek(option)} disabled={!editShiftRunData.canEdit} />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-md font-medium text-gray-700 w-auto">Start time</div>
              <div className="mt-2">
                <TimePicker date={startDateTime} onChange={onChangeStartDateTime} disabled={!editShiftRunData.canEdit} />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-md font-medium text-gray-700 w-auto">End time</div>
              <div className="mt-2">
                <TimePicker
                  date={endDateTime}
                  onChange={onChangeEndDateTime}
                  minTime={new Date(new Date(startDateTime).setHours(startDateTime.getHours() + 5))}
                  maxTime={new Date(new Date(startDateTime).setHours(startDateTime.getHours() + 5))}
                  disabled={!editShiftRunData.canEdit}
                />
              </div>
            </div>
            <div className="flex justify-between items-center mt-7">
              <button type="button" className="text-gray-500 font-semibold text-md leading-md" onClick={onClose}>
                Cancel
              </button>
              <div className="flex items-center gap-2">
                <button
                  type="button"
                  aria-label="delete run"
                  className="text-white bg-error-700 rounded-lg px-3 py-2.5 font-semibold text-md leading-md"
                  onClick={() => onRemoveRun(editShiftRunData)}
                  data-cy="remove-shift-run"
                >
                  <Delete />
                </button>
                <button
                  type="button"
                  className="text-gray-700 border border-gray-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
                  onClick={onChangeStatusShiftRun}
                  data-cy="edit-shift-run-pause-button"
                >
                  {editShiftRunData.status === 'paused' ? 'Restart run' : 'Pause run'}
                </button>
                {editShiftRunData.canEdit && (
                  <button
                    type="button"
                    className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
                    onClick={onEditShiftRun}
                    data-cy="save-shift-run-button"
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-gray-900" />
    </>
  );
}
