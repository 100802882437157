import { ExclamationCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

interface OverdueProps {
  reviewsOverdue: number;
  confirmationPractices: boolean;
  testId: string;
}

const Overdue = ({ reviewsOverdue, confirmationPractices, testId }: OverdueProps) => {
  const navigate = useNavigate();

  return (
    <div className="bg-background2 border border-black border-opacity-[0.13] p-6 rounded-3xl" data-cy={testId}>
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-3">
          <div className="border border-black border-opacity-20 w-10 h-10 rounded-full flex items-center justify-center">
            <ExclamationTriangleIcon className="!w-5 !h-5 text-content-secondary" />
          </div>
          <div className="text-xl leading-xl font-semibold text-black">Overdue</div>
        </div>
      </div>
      {reviewsOverdue > 0 && (
        <button
          type="button"
          className="bg-background3 rounded-lg p-3 flex items-center justify-between w-full mt-4 text-content-primary font-semibold text-md leading-md hover:bg-primary-25"
          onClick={() => navigate('/review-stats')}
        >
          Reviews
          <div className="bg-content-warning text-white rounded-full w-6 h-6 font-semibold text-md leading-md">{reviewsOverdue}</div>
        </button>
      )}
      {confirmationPractices && (
        <button
          type="button"
          className="bg-background3 rounded-lg p-3 flex items-center justify-between w-full mt-1 text-content-primary font-semibold text-md leading-md hover:bg-primary-25"
          onClick={() => navigate('/confirmation-practices')}
        >
          Confirmation practices
          <div className="bg-content-warning rounded-full w-6 h-6 flex justify-center items-center">
            <ExclamationCircleIcon className="!w-4 !h-4 text-white" />
          </div>
        </button>
      )}
    </div>
  );
};

export default Overdue;
