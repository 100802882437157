import { useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { CSVLink } from 'react-csv';
import Helmet from 'react-helmet';
import { Event } from '@mui/icons-material';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { AdjustmentsHorizontalIcon, XMarkIcon, ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import { LocationBar, Loading, Table, Filters, Avatar } from 'components';
import { Notification } from '__generated__/graphql';
import { ImageSizeType, SelectOption } from 'types/types';
import { useGetNotificationsLazy } from 'api/hooks/useNotifications';
import { toTitleCase } from 'services/strings';
import { formatTime, getFirstDayOfPayroll, getLastDayOfPayroll, getPeriodsForYear } from 'services/helpers';
import { GenericContextProps, useGeneric } from 'context/genericContext';

export default function EventLog() {
  const periods = getPeriodsForYear().map((period) => {
    return { label: formatTime(period, 'MMMM, yyyy'), value: period.toString() };
  });

  const { teams: genericTeams, teamsLoading } = useGeneric() as GenericContextProps;
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
  const [period, setPeriod] = useState<string>(periods[0].value);
  const [team, setTeam] = useState<string>('all');
  const { getNotifications, notificationsLoading, notifications } = useGetNotificationsLazy();

  const teams = useMemo(() => {
    return genericTeams?.map((t) => ({ value: t.teamId, label: t.teamName })) || [];
  }, [genericTeams]);

  const [filters, setFilters] = useState([
    { name: 'period', selectedValue: periods[0], values: periods },
    { name: 'team', selectedValue: { value: 'all', label: 'All' }, values: [{ value: 'all', label: 'All' }, ...teams] },
  ]);

  const getTeam = (teamId: string) => {
    const tt = teams?.find((t) => t.value === teamId);
    return tt?.label || '';
  };

  const columnVisibility = {
    dateUpdated: window.innerWidth > 768,
    name: true,
    subject: window.innerWidth > 640,
    type: true,
  };

  useEffect(() => {
    filters[1].values = [{ value: 'all', label: 'All' }, ...teams];
    setFilters(filters);

    const startDateTime = getFirstDayOfPayroll(parseInt(period, 10));
    const endDateTime = getLastDayOfPayroll(parseInt(period, 10));

    getNotifications({
      variables: {
        skip: !period,
        query: {
          startDateTime,
          endDateTime,
        },
      },
    });
  }, [filters, teams, period, getNotifications]);

  const columnHelper = createColumnHelper<Notification>();

  const columns1 = [
    columnHelper.accessor('avatar', {
      header: () => 'Admin',
      cell: (info) => (
        <div data-cy="list-avatar">
          <Avatar size={ImageSizeType.MD} avatar={info.renderValue() || '/defaultProfile.jpg'} alt={info.row.original.fullName ?? ''} />
          <div data-cy="list-name" className="text-xs">
            {info.row.original.fullName}
          </div>
        </div>
      ),
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.accessor('teamId', {
      header: () => 'Team',
      cell: (info) => <div data-cy="list-team">{getTeam(info.renderValue() || '')}</div>,
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.accessor('description', {
      header: () => 'Description',
      cell: (info) => <div data-cy="list-description">{toTitleCase(info.renderValue() || '')}</div>,
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.accessor('type', {
      header: () => 'Type',
      cell: (info) => <div data-cy="list-type">{info.renderValue()}</div>,
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.accessor('dateCreated', {
      header: () => 'Date Created',
      cell: (info) => <div data-cy="list-dateCreated">{format(info.renderValue() ?? 0, 'PPPP: HH:mm')}</div>,
      footer: (info) => info.column.id,
    }),
  ] as Array<ColumnDef<Notification, unknown>>;

  const onFilterChange = (name: string, value: SelectOption) => {
    const newFilters = [...filters];
    const filterIndex = newFilters.findIndex((f) => f.name === name);
    newFilters[filterIndex].selectedValue = value;
    setFilters(newFilters);
  };

  const onSaveFilters = () => {
    setTeam(filters[1].selectedValue.value);
    setPeriod(filters[0].selectedValue.value);
    setShowFiltersModal(false);

    const startDateTime = getFirstDayOfPayroll(parseInt(period, 10));
    const endDateTime = getLastDayOfPayroll(parseInt(period, 10));

    getNotifications({
      variables: {
        skip: !period,
        query: {
          startDateTime,
          endDateTime,
        },
      },
    });
  };

  const filterByTeam = (n: Notification) => {
    if (team === 'all') return true;
    if (team === n?.teamId) return true;
    return false;
  };

  const rows = notifications.filter((n) => filterByTeam(n));

  const csvReport = rows.map((r) => {
    return {
      team: getTeam(r.teamId || ''),
      dateCreated: formatTime(r.dateCreated || 0, 'PPPP'),
      admin: r.fullName,
      description: toTitleCase(r.description || ''),
      type: r.type,
    };
  });

  if (teamsLoading || notificationsLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Event Log</title>
      </Helmet>
      <LocationBar section="Events" page="Event Log" Icon={Event} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex justify-between place-items-center mb-8">
          <div className="text-display-sm md:text-display-lg leading-display-sm md:leading-display-lg font-semibold md:tracking-display-lg text-black">
            Events
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-between mt-8">
          <div className="flex flex-col md:flex-row items-center gap-4 md:gap-9 w-full md:w-fit">
            <div className="flex flex-col md:flex-row items-center gap-3 w-full md:w-fit">
              <button
                data-cy="show-filters-button"
                type="button"
                className={`relative border ${
                  team === 'all' && period === '0' ? 'border-gray-300' : 'border-gray-900'
                }  text-gray-700 text-md leading-md flex items-center px-4 py-2.5 rounded-lg shadow-xs justify-center w-full md:w-fit`}
                onClick={() => setShowFiltersModal(true)}
              >
                <AdjustmentsHorizontalIcon className="w-5 h-5 mr-2" />
                Filters
                {team !== 'all' && period !== '0' && (
                  <span className="absolute bg-gray-900 h-5 w-5 text-white rounded-full -top-2 -right-2 flex items-center justify-center text-xs font-semibold">
                    {team !== 'all' && period !== '0' ? '2' : '1'}
                  </span>
                )}
              </button>

              {team !== 'all' && (
                <div
                  data-cy="tag-2"
                  className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center"
                >
                  {teams?.find((type) => type?.value === team)?.label}
                  <button
                    data-cy="remove-tag-2"
                    type="button"
                    aria-label="remove overdue filter"
                    className="text-gray-500"
                    onClick={() => {
                      onFilterChange('team', { value: 'all', label: 'All' });
                      setTeam('all');
                    }}
                  >
                    <XMarkIcon className="w-4 h-4 ml-2" />
                  </button>
                </div>
              )}
              {period !== '0' && (
                <div
                  data-cy="tag-1"
                  className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center"
                >
                  {periods.find((type) => type.value === period)?.label}
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between lg:justify-normal gap-3 md:gap-9 w-full lg:w-fit mt-6 lg:mt-0">
            <CSVLink filename="eventlog.csv" data={csvReport} className="text-gray-500 text-sm leading-sm font-semibold flex items-center hover:no-underline">
              <ArrowUpTrayIcon className="w-5 h-5 mr-2" />
              Export CSV
            </CSVLink>
          </div>
        </div>
        <div className="mt-3 md:mt-8 mb-10">
          {rows && rows.length > 0 && <Table<Notification> data={rows} columns={columns1} columnVisibility={columnVisibility} />}
        </div>
      </div>
      {showFiltersModal && (
        <Filters
          filters={filters}
          onFilterChange={onFilterChange}
          onCloseModal={() => setShowFiltersModal(false)}
          onSaveFilters={onSaveFilters}
          showClearFilters={false}
        />
      )}
    </>
  );
}
