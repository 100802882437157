import { SelectOption } from 'types/types';
import { Team } from '__generated__/graphql';
import { useGetTeamOptions } from 'api/hooks/useGetTeams';
import ReactSelect from './Select';

interface TeamSelectorProps {
  selectTeam: (teamId: string) => void;
  teamId?: string;
  showAll?: boolean;
  isContained?: boolean;
  startDateTime?: number | null;
  allTeams?: boolean;
}

export default function TeamSelector({ selectTeam, teamId, isContained = false, showAll = false, startDateTime = null, allTeams }: TeamSelectorProps) {
  const { teams, teamsLoading } = useGetTeamOptions(startDateTime, allTeams);
  const teamOptions = teams.map((t: Team) => ({ value: t.teamId, label: t.teamName }));
  const options = showAll ? [{ value: 'all', label: 'All' }, ...teamOptions] : teamOptions;
  const value = options?.find((team: SelectOption) => team.value === teamId);

  const onChangeTeam = (team: SelectOption) => {
    selectTeam(team.value);
  };

  return (
    <div className="mb-1">
      {!isContained && <div className="text-sm font-bold p-2 text-gray-700 capitalize w-auto print:hidden">Select a team</div>}
      <div className="mt-2">
        <ReactSelect
          testId="select-team"
          isLoading={teamsLoading}
          isContained={isContained}
          options={options}
          onChange={(option) => onChangeTeam(option)}
          selectedValue={value}
          searchable={true}
        />
      </div>
    </div>
  );
}
