import { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useUser, UserContextProps } from 'context/userContext';
import { ChatRoom, ChatUserInput, CreateChatRoomInput, NewChatMessages } from '__generated__/graphql';
import { Loading, LocationBar, Message, Search } from 'components';
import { ArrowBack, InfoOutlined, Chat as ChatIcon, Add, Edit } from '@mui/icons-material';
import { useCreateChatRoom, useGetChatRooms, useEditChatRoom, useDeleteChatRoom, useGetNewChatMessages, useReadChatMessages } from 'api/hooks/useChat';
import Avatar from 'components/Avatar';
import { ImageSizeType } from 'types/types';
import TeamSelect from 'components/TeamSelect';
import Chat from './Chat';
import CareNotes from './CareNotes';
import EditChatMembers from './components/EditChatMembers';
import CreateChatRoom from './components/CreateChatRoom';
import EditChatRoom from './components/EditChatRoom';

const Room = () => {
  const { user, userLoading } = useUser() as UserContextProps;
  const [selectedTeam, setSelectedTeam] = useState('all');
  const { newChatMessages } = useGetNewChatMessages({ userId: user?.profile });
  const { loading, chatRooms } = useGetChatRooms(
    user?.permissions?.admin && selectedTeam !== 'all'
      ? {
          teamId: selectedTeam,
        }
      : {
          supportWorkerId: user?.profile,
          teamId: user?.teamId,
        },
  );
  const { editChatRoom, mutationEditChatRoom } = useEditChatRoom(
    user?.permissions?.admin && selectedTeam !== 'all'
      ? {
          teamId: selectedTeam,
        }
      : {
          supportWorkerId: user?.profile,
          teamId: user?.teamId,
        },
  );
  const { createChatRoom, mutationCreateChatRoom } = useCreateChatRoom(
    user?.permissions?.admin && selectedTeam !== 'all'
      ? {
          teamId: selectedTeam,
        }
      : {
          supportWorkerId: user?.profile,
          teamId: user?.teamId,
        },
  );
  const { deleteChatRoom, mutationDeleteChatRoom } = useDeleteChatRoom(
    user?.permissions?.admin && selectedTeam !== 'all'
      ? {
          teamId: selectedTeam,
        }
      : {
          supportWorkerId: user?.profile,
          teamId: user?.teamId,
        },
  );
  const { readChatMessages } = useReadChatMessages({ userId: user?.profile });
  const [selectedRoom, setSelectedRoom] = useState<ChatRoom>();
  const [displayChat, setDisplayChat] = useState(false);
  const [displayMembers, setDisplayMembers] = useState(window.innerWidth > 1280);
  const [displayNotes, setDisplayNotes] = useState(false);
  const [showEditMembersModal, setShowEditMembersModal] = useState(false);
  const [showCreateMembersModal, setShowCreateMembersModal] = useState(false);
  const [showEditChatRoomModal, setShowEditChatRoomModal] = useState(false);
  const [search, setSearch] = useState('');
  const [openSnack, setOpenSnack] = useState<boolean>(false);

  useEffect(() => {
    if (chatRooms && chatRooms.length > 0) {
      setSelectedRoom(chatRooms[0]);
      onReadChatMessages(chatRooms[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatRooms]);

  const onReadChatMessages = async (room: ChatRoom) => {
    await readChatMessages({
      variables: {
        input: {
          teamId: room?.teamId,
          roomId: room?.id,
          userId: user?.profile,
        },
      },
    });
  };

  const onSelectRoom = async (room: ChatRoom) => {
    setSelectedRoom(room);
    onReadChatMessages(room);
    setDisplayChat(true);
  };

  const onEditMembers = async (members: ChatUserInput[]) => {
    setOpenSnack(true);
    setShowEditMembersModal(false);
    await editChatRoom({
      variables: {
        input: {
          teamId: selectedRoom?.teamId,
          roomId: selectedRoom?.id,
          members,
        },
      },
    });
    setTimeout(() => {
      setOpenSnack(false);
    }, 2000);
  };

  const onEditChatRoom = async (members: ChatUserInput[], topic: string) => {
    setOpenSnack(true);
    setShowEditChatRoomModal(false);
    await editChatRoom({
      variables: {
        input: {
          teamId: selectedRoom?.teamId,
          roomId: selectedRoom?.id,
          topic,
          members,
        },
      },
    });
    setTimeout(() => {
      setOpenSnack(false);
    }, 2000);
  };

  const onDeleteChatRoom = async () => {
    setOpenSnack(true);
    setShowEditChatRoomModal(false);
    await deleteChatRoom({
      variables: {
        input: {
          teamId: selectedRoom?.teamId,
          customerId: selectedRoom?.customer?.id,
          roomId: selectedRoom?.id,
        },
      },
    });
    setTimeout(() => {
      setOpenSnack(false);
    }, 2000);
  };

  const onCreateRoom = async (room: CreateChatRoomInput) => {
    setOpenSnack(true);
    setShowCreateMembersModal(false);
    await createChatRoom({
      variables: {
        input: room,
      },
    });
    setTimeout(() => {
      setOpenSnack(false);
    }, 2000);
  };

  if (userLoading || mutationEditChatRoom.loading || mutationCreateChatRoom.loading || mutationDeleteChatRoom.loading) {
    return <Loading />;
  }

  const filteredChats = chatRooms.filter((chatRoom) => chatRoom.customer?.name?.toLowerCase().includes(search.toLowerCase()));

  return (
    <>
      <Helmet>
        <title>Chat</title>
      </Helmet>
      <LocationBar section="People" page="Family Chat" Icon={ChatIcon} />
      <Message response={[mutationEditChatRoom, mutationCreateChatRoom, mutationDeleteChatRoom]} openSnack={openSnack} setOpenSnack={setOpenSnack} />
      {!user?.permissions?.admin && chatRooms.length === 0 ? (
        <div className="px-10 mt-48 text-center font-semibold text-display-xs leading-display-xs text-gray-800">
          You are not in any chat rooms. Ask your team if you think you should be in one.
        </div>
      ) : (
        <div className="overflow-y-hidden">
          <div className="flex">
            <div
              className={`${
                displayChat ? 'hidden' : 'block'
              } xl:block xl:border-r border-gray-100 pt-5 xl:shrink-0 w-full xl:w-1/5 overflow-y-auto h-[calc(100vh-7em)] xl:h-[calc(100vh-6.5em)] pb-5`}
            >
              {user?.permissions?.admin && (
                <>
                  <div className="px-4 mb-5 flex justify-end">
                    <button
                      type="button"
                      className="text-primary-700 flex gap-2 border rounded-lg border-primary-700 bg-white px-4 py-2 hover:text-white hover:bg-primary-700 font-medium"
                      onClick={() => setShowCreateMembersModal(true)}
                    >
                      <Add />
                      Add
                    </button>
                  </div>
                  <div className="px-4 mb-5">
                    <TeamSelect onSelectedTeam={setSelectedTeam} selectedTeamId={selectedTeam || ''} showAll={true} allLabel="My chats" />
                  </div>
                </>
              )}
              <div className="px-4 mb-5">
                <Search value={search} onSearch={setSearch} />
              </div>
              {loading && <Loading isComponent />}
              {!loading &&
                filteredChats &&
                filteredChats?.map((room: ChatRoom, index: number) => {
                  return (
                    <div>
                      <button
                        type="button"
                        onClick={() => onSelectRoom(room)}
                        className={`${selectedRoom?.id === room?.id ? 'xl:bg-primary-700 xl:text-white' : 'text-primary-700'} text-primary-700 w-full ${
                          index === 0 ? 'border-t' : ''
                        } border-b border-gray-100 px-4 py-2 flex justify-between items-center text-left`}
                        key={index}
                      >
                        <div>
                          <div className="text-lg leading-lg font-medium">{room.customer?.name}</div>
                          <div className="text-sm leading-sm">{room.topic}</div>
                        </div>
                        <div className="flex items-center gap-3">
                          {newChatMessages &&
                            newChatMessages.length > 0 &&
                            newChatMessages?.find((newChatMessage: NewChatMessages) => newChatMessage.roomId === room.id)?.newMessages !== 0 && (
                              <div className="bg-primary-700 text-semibold text-md px-3 py-1.5 rounded-lg text-white">
                                {newChatMessages.find((newChatMessage: NewChatMessages) => newChatMessage.roomId === room.id)?.newMessages}
                              </div>
                            )}
                          {user?.permissions?.admin && (
                            <button type="button" aria-label="edit chat room" onClick={() => setShowEditChatRoomModal(true)}>
                              <Edit />
                            </button>
                          )}
                        </div>
                      </button>
                    </div>
                  );
                })}
            </div>
            <div className={`${displayChat && !displayMembers && !displayNotes ? 'block' : 'hidden'} xl:block w-full xl:w-3/5 border-r border-gray-100`}>
              <div className="flex justify-between items-center rounded-b-lg xl:hidden shadow-lg px-4 py-2">
                <button type="button" aria-label="go back" className="text-primary-700" onClick={() => setDisplayChat(false)}>
                  <ArrowBack />
                </button>
                <div className="text-gray-800 text-center">
                  <div className="text-lg display-lg font-medium">{selectedRoom?.customer?.name}</div>
                  <div className="text-sm display-sm">{selectedRoom?.topic}</div>
                </div>
                <button type="button" aria-label="display infos" className="text-primary-700" onClick={() => setDisplayMembers(true)}>
                  <InfoOutlined />
                </button>
              </div>
              {user && <Chat room={selectedRoom} user={user} />}
            </div>
            {selectedRoom && (
              <div
                className={`${
                  displayMembers || displayNotes ? 'block' : 'hidden'
                } xl:block overflow-y-auto h-[calc(100vh-7em)] xl:h-[calc(100vh-6.5em)] w-full xl:w-1/5 pb-5`}
              >
                <button
                  type="button"
                  aria-label="go back"
                  className="px-4 my-5 text-primary-700 xl:hidden"
                  onClick={() => {
                    setDisplayMembers(false);
                    setDisplayNotes(false);
                    setDisplayChat(true);
                  }}
                >
                  <ArrowBack />
                </button>
                <div className="flex items-center py-5">
                  <button
                    type="button"
                    className={`${displayMembers ? 'bg-primary-700 text-white' : 'text-primary-700'} w-1/2 px-4 py-2 border border-primary-700`}
                    onClick={() => {
                      setDisplayMembers(true);
                      setDisplayNotes(false);
                    }}
                  >
                    Members
                  </button>
                  <button
                    type="button"
                    className={`${displayNotes ? 'bg-primary-700 text-white' : 'text-primary-700'} w-1/2 px-4 py-2 border border-primary-700`}
                    onClick={() => {
                      setDisplayMembers(false);
                      setDisplayNotes(true);
                    }}
                  >
                    Care Notes
                  </button>
                </div>
                <div className="px-4">
                  {selectedRoom && displayMembers && (
                    <>
                      {selectedRoom.members?.map((member, index: number) => {
                        return (
                          <div className="flex items-center mb-2 gap-2" key={index}>
                            <Avatar size={ImageSizeType.MD} avatar={member?.avatar || '/defaultProfile.jpg'} alt={member?.name ?? ''} />
                            <div className="text-gray-800 text-lg leading-lg font-medium">{member?.name}</div>
                          </div>
                        );
                      })}
                      {user?.permissions?.admin && (
                        <button
                          type="button"
                          // eslint-disable-next-line max-len
                          className="mt-5 text-white flex items-center justify-center gap-2 border rounded-lg border-primary-700 bg-primary-700 px-4 py-2 w-full hover:bg-white hover:text-primary-700 font-medium"
                          onClick={() => setShowEditMembersModal(true)}
                        >
                          <Edit />
                          Edit
                        </button>
                      )}
                    </>
                  )}
                </div>
                {displayNotes && <CareNotes customerId={selectedRoom.customer?.id ?? ''} teamId={selectedRoom.teamId ?? ''} />}
              </div>
            )}
          </div>
        </div>
      )}
      {showEditMembersModal && (
        <EditChatMembers
          members={selectedRoom?.members ?? []}
          teamId={selectedRoom?.teamId || ''}
          onCloseModal={() => setShowEditMembersModal(false)}
          onEdit={onEditMembers}
        />
      )}
      {showCreateMembersModal && <CreateChatRoom teamId={selectedTeam || ''} onCloseModal={() => setShowCreateMembersModal(false)} onCreate={onCreateRoom} />}
      {showEditChatRoomModal && (
        <EditChatRoom
          members={selectedRoom?.members ?? []}
          topic={selectedRoom?.topic ?? ''}
          teamId={selectedRoom?.teamId || ''}
          onCloseModal={() => setShowEditChatRoomModal(false)}
          onEdit={onEditChatRoom}
          onDelete={onDeleteChatRoom}
        />
      )}
    </>
  );
};

export default Room;
