import { RouteObject } from 'react-router-dom';
import GraphQlInspector from 'pages/Developer/GraphQlInspector';
import GraphQlVisualiser from 'pages/Developer/GraphQlVisualiser';
import DataConvertor from 'pages/Developer/DataConvertor';
import Llm from 'pages/Llm';
import PackageList from 'pages/Packages/PackageList';
import PackageDetail from 'pages/Packages/PackageDetail';
import InvoiceList from 'pages/Invoices/InvoiceList';
import InvoiceCreate from 'pages/Invoices/InvoiceCreate';
import InvoiceDetail from 'pages/Invoices/InvoiceDetail';
import ExpenseList from 'pages/Expense/ExpenseList';

export const devRoutes: RouteObject[] = [
  { path: '/developer/graphql-visualiser', element: <GraphQlVisualiser /> },
  { path: '/developer/graphql-inspector', element: <GraphQlInspector /> },
  { path: '/developer/data-convertor', element: <DataConvertor /> },
  { path: '/developer/llm', element: <Llm /> },
  { path: '/developer/packages', element: <PackageList /> },
  { path: '/developer/packages/:customerId/:packageId', element: <PackageDetail /> },
  { path: '/developer/invoices', element: <InvoiceList /> },
  { path: '/developer/invoices/create', element: <InvoiceCreate /> },
  { path: '/developer/invoices/:date/:teamId/:customerId', element: <InvoiceDetail /> },
  { path: '/developer/expenses', element: <ExpenseList /> },
];
