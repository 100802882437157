import { Maybe, Candidate, SupportWorker, CurrentStatus } from '__generated__/graphql';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { CheckCircleIcon as CheckCircleIconSolid, XCircleIcon as XCircleIconSolid } from '@heroicons/react/24/solid';

import { CheckCircleIcon, PencilIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { Table } from 'components';
import { useNavigate } from 'react-router-dom';
import { MapCandidateWorkshop } from 'pages/Candidates/utils/FileMapper';

interface WorkshopCandidateListProps {
  candidates: Candidate[];
  onSelectAndUpdateCandidate: (c: Candidate) => void;
  supportWorkers: SupportWorker[];
  onSaveCandidate: (candidate: Candidate) => void;
}

type CandidateWorkshop = Candidate & {
  attended: Maybe<boolean> | undefined;
  passed: Maybe<boolean> | undefined;
  shadowWith: string | undefined;
  note: Maybe<string> | undefined;
};

function DisplayBoolean({ row, onToggle, value }: { row: Candidate; onToggle: (candidate: Candidate) => void; value: Maybe<boolean> | undefined }) {
  const onChange = () => {
    onToggle(row);
  };

  return (
    <>
      <div className="shadow-xs border border-gray-300 rounded-lg items-center w-fit hidden md:flex">
        <button
          type="button"
          aria-label="True button"
          disabled={value === true}
          onClick={onChange}
          className={`${value ? 'bg-success-500 text-white' : 'bg-white text-gray-500'} px-3 py-2.5 border-r border-gray-300 rounded-l-lg`}
        >
          <CheckCircleIcon className="w-5 h-5" />
        </button>
        <button
          type="button"
          aria-label="False button"
          disabled={value === false}
          onClick={onChange}
          className={`${value === false ? 'bg-error-500 text-white' : 'bg-white text-gray-500'} px-3 py-2.5 rounded-r-lg`}
        >
          <XCircleIcon className="w-5 h-5" />
        </button>
      </div>

      <div className="md:hidden">
        {value === true ? (
          <button type="button" aria-label="True button" onClick={onChange}>
            <CheckCircleIconSolid className="w-6 h-6 text-success-500" />
          </button>
        ) : (
          <button type="button" aria-label="False button" onClick={onChange}>
            <XCircleIconSolid className="w-6 h-6 text-error-500" />
          </button>
        )}
      </div>
    </>
  );
}

export default function WorkshopCandiateList({ candidates, supportWorkers, onSelectAndUpdateCandidate, onSaveCandidate }: WorkshopCandidateListProps) {
  const navigate = useNavigate();
  const columnVisibility = {
    name: true,
    attended: true,
    passed: true,
    phone: window.innerWidth > 1280,
    shadowWith: window.innerWidth > 1024,
    teamName: window.innerWidth > 1024,
  };

  const onAttendedToggle = (candidate: Candidate) => {
    const status = candidate?.workshop?.attended ? CurrentStatus.WorkshopAttended : candidate.currentStatus;
    const update = { id: candidate?.id, currentStatus: status, workshop: MapCandidateWorkshop(candidate?.workshop || null) };
    update.workshop.attended = !candidate.workshop?.attended;
    onSaveCandidate(update);
  };

  const onPassedToggle = (candidate: Candidate) => {
    const status = candidate?.workshop?.passed ? CurrentStatus.ShadowingAgreed : candidate.currentStatus;
    const update = { id: candidate?.id, currentStatus: status, workshop: MapCandidateWorkshop(candidate?.workshop || null) };
    update.workshop.passed = !candidate.workshop?.passed;
    onSaveCandidate(update);
  };

  const RowActions = ({ row }: { row: CandidateWorkshop }) => {
    return (
      <button type="button" onClick={() => onSelectAndUpdateCandidate(row)} aria-label="Edit" className="m-0 p-0">
        <PencilIcon className="w-6 h-6 text-gray-500 hover:text-primary600" />
      </button>
    );
  };

  const FeedbackActions = ({ row }: { row: CandidateWorkshop }) => {
    return (
      <div className="flex flex-col gap-2">
        <button
          type="button"
          onClick={() => navigate(`/candidates/${row.id}`)}
          className="px-1 py-0.5 rounded-lg border border-gray-700 text-gray-700 text-md leading-md"
        >
          Workshop feedback
        </button>
        <button
          type="button"
          onClick={() => navigate(`/candidates/${row.id}/shadow-feedback`)}
          className="px-1 py-0.5 rounded-lg border border-gray-700 text-gray-700 text-md leading-md"
        >
          Shadow feedback
        </button>
      </div>
    );
  };

  const columnHelper = createColumnHelper<CandidateWorkshop>();

  const columns1 = [
    columnHelper.accessor('firstName', {
      header: () => 'Candidate',
      cell: (info) => (
        <div>
          <div className="text-sm leading-sm md:text-md md:leading-md text-gray-900 font-semibold" data-cy="list-workshop-candidate-name">
            {info.renderValue()} {info.row.original.lastName}
          </div>
          <div className="text-xs leading-sm md:text-sm md:leading-sm text-gray-700 hidden sm:block" data-cy="list-workshop-candidate-email">
            {info.row.original.email}
          </div>
        </div>
      ),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('phone', {
      header: () => 'Phone number',
      cell: (info) => <span data-cy="list-workshop-candidate-phone"> {info.renderValue()}</span>,
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.accessor('shadowWith', {
      header: () => 'Shadow with',
      cell: (info) => info.renderValue(),
      footer: (info) => info.column.id,
      enableSorting: false,
    }),
    columnHelper.accessor('attended', {
      header: () => (window.innerWidth > 768 ? 'Attended' : 'Att'),
      cell: (info) => <DisplayBoolean row={info.row.original} value={info.row.original.attended} onToggle={onAttendedToggle} />,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('passed', {
      header: () => (window.innerWidth > 768 ? 'Passed' : 'Pass'),
      cell: (info) => <DisplayBoolean row={info.row.original} value={info.row.original.passed} onToggle={onPassedToggle} />,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('teamName', {
      header: () => 'Team',
      cell: (info) => <span data-cy="list-workshop-candidate-teamName"> {info.renderValue()}</span>,
      footer: (info) => info.column.id,
    }),
    columnHelper.display({
      id: 'feedback',
      header: () => 'Feedback',
      cell: (props) => <FeedbackActions row={props.row.original} />,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (props) => <RowActions row={props.row.original} />,
    }),
  ] as Array<ColumnDef<CandidateWorkshop, unknown>>;

  const rows = candidates?.map((c: Maybe<Candidate>) => {
    const worker = supportWorkers.find((s) => s.id === c?.workshop?.shadowWithId);
    return {
      ...c,
      attended: c?.workshop?.attended,
      passed: c?.workshop?.passed,
      shadowWith: worker?.fullName,
      note: c?.workshop?.note,
    };
  });

  return <div>{rows && rows.length > 0 && <Table<CandidateWorkshop> data={rows} columns={columns1} columnVisibility={columnVisibility} />}</div>;
}
