import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';

interface SearchProps {
  value: string;
  placeholder?: string;
  onSearch: (value: string) => void;
  error?: string;
  onConfirm?: () => void;
}

const Search = ({ value, placeholder, onSearch, error, onConfirm }: SearchProps) => {
  const keyEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && onConfirm) {
      onConfirm();
    }
  };
  return (
    <>
      <div className="relative w-full md:w-auto">
        <input
          data-cy="search-text-box"
          type="text"
          value={value}
          onKeyDown={(e) => {
            keyEvent(e);
          }}
          onChange={(event) => onSearch(event.target.value)}
          className="bg-white px-10 py-2 rounded-lg text-md leading-md text-gray-700 placeholder:text-gray-500 border border-gray-300 shadow-xs w-full"
          placeholder={placeholder || 'Search'}
        />
        <div className="absolute inset-y-0 left-0 flex items-center px-4">
          <MagnifyingGlassIcon
            className="w-5 h-5 text-gray-500"
            onClick={() => {
              if (onConfirm) {
                onConfirm();
              }
            }}
          />
        </div>
        {value.length > 0 && (
          <button type="button" aria-label="reset" className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-500" onClick={() => onSearch('')}>
            <XMarkIcon className="w-5 h-5" />
          </button>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-error-600 dark:text-green-500">
          <span className="font-medium">{error}</span>
        </p>
      )}
    </>
  );
};

export default Search;
