import { useMemo } from 'react';
import { Customer } from '__generated__/graphql';
import { differenceInDays, differenceInMonths } from 'date-fns';
import { OverdueCustomerTableType } from './OverdueCustomerTable';

export const getLastAndNextReview = (customer: Customer) => {
  let lastReviewDate;
  let nextReviewDate;

  const mappedReviews = customer.reviews?.filter((r) => (r?.date || 0) > (customer?.firstVisitDate || 0));
  const sortedReviews = mappedReviews?.slice().sort((a, b) => (b?.date || 0) - (a?.date || 0));

  if (sortedReviews && sortedReviews?.length > 0) {
    const review = sortedReviews[0];
    lastReviewDate = new Date(review?.date ?? 0);
    nextReviewDate = new Date(new Date(review?.date ?? 0).setMonth(new Date(review?.date ?? 0).getMonth() + 6));
  } else if (customer && customer.firstVisitDate) {
    const firstVisitDate = new Date(customer.firstVisitDate);
    nextReviewDate = new Date(firstVisitDate.setMonth(firstVisitDate.getMonth() + 3));
  }

  return { lastReviewDate, nextReviewDate };
};

export const getDaysOverDue = (customer: Customer) => {
  let daysOverDue = 0;
  const { nextReviewDate } = getLastAndNextReview(customer);

  if (nextReviewDate) daysOverDue = differenceInDays(Date.now(), nextReviewDate);

  return daysOverDue;
};

export const isNextReviewDueSoon = (customer: Customer) => {
  const { nextReviewDate } = getLastAndNextReview(customer);
  let diffMonth = 2;
  if (nextReviewDate) diffMonth = differenceInMonths(nextReviewDate, Date.now());
  if (diffMonth < 1) {
    return true;
  }

  return false;
};

export const isNextReviewOverdue = (customer: Customer) => {
  const daysOverDue = getDaysOverDue(customer);
  return daysOverDue > 0;
};

const useReviewStats = (customers: Customer[]) => {
  let overdue = 0;
  let dueSoon = 0;
  let notYetDue = 0;
  let upToDate = 0;

  const reviewStats = useMemo(() => {
    const statCustomersList: OverdueCustomerTableType[] = [];

    customers?.forEach((customer: Customer) => {
      const daysOverDue = getDaysOverDue(customer);
      statCustomersList.push({
        id: customer.id,
        teamId: customer.teamId,
        circle: customer.circleName,
        team: customer.teamName,
        name: `${customer.firstName} ${customer.lastName}`,
        daysOverdue: daysOverDue,
      });
      if (daysOverDue > 0) {
        overdue++;
      } else if (isNextReviewDueSoon(customer)) {
        dueSoon++;
      } else if (customer && customer.firstVisitDate) {
        notYetDue++;
      } else if (customer && customer.reviews && customer.reviews.length > 0) {
        upToDate++;
      } else {
        notYetDue++;
      }
    });

    return {
      dueSoon,
      notYetDue,
      overdue,
      upToDate,
      statCustomersList,
    };
  }, [customers, dueSoon, notYetDue, overdue, upToDate]);
  return {
    ...reviewStats,
  };
};

export { useReviewStats };
