import { useState } from 'react';
import { useUser, UserContextProps } from 'context/userContext';
import { useNavigate } from 'react-router-dom';
import Helmet from 'react-helmet';
import PunchClock from '@mui/icons-material/PunchClock';
import { LocationBar, Loading, SelectSupportWorker, TeamSelector, Table } from 'components';
import { useGetReportingHoursPeriod, useSaveReportingHours, useSubmitReportingHour, useSyncReportingHour } from 'api/hooks/useReportingHours';
import { ReportingHoursPeriod } from '__generated__/graphql';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { PlusIcon } from '@heroicons/react/24/outline';
import { ReportingHourStatus } from './service';
import CreateReportingHoursPeriod from './CreateReportingHoursPeriod';

const ReportingHoursList = () => {
  const { user, userLoading } = useUser() as UserContextProps;
  const navigate = useNavigate();
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [selectedTeam, setSelectedTeam] = useState<string>(user?.teamId || '');
  const [selectedSupportWorker, setSelectedSupportWorkerId] = useState<string>(user?.profile || '');
  const [openCreateRHPModal, setOpenCreateRHPModal] = useState(false);
  const query = { teamId: selectedTeam, supportWorkerId: selectedSupportWorker };
  const { reportingHoursPeriod, loading: rhpLoading } = useGetReportingHoursPeriod(query);
  const { saveReportingHours, mutationSaveReportingHours } = useSaveReportingHours(query);
  const { submitReportingHour, mutationSubmitReportingHour } = useSubmitReportingHour(query);
  const { syncReportingHour, mutationSyncReportingHour } = useSyncReportingHour(query, null);

  const onChangeTeam = (teamId: string) => {
    setSelectedTeam(teamId);
    setSelectedSupportWorkerId('');
  };

  const createReportingHoursPeriod = () => {
    setOpenCreateRHPModal(true);
  };

  const saveReportingHoursPeriod = async (startDateTime: number, endDateTime: number) => {
    setApiLoading(true);

    if (selectedSupportWorker && selectedTeam) {
      await saveReportingHours({
        variables: {
          input: {
            supportWorkerId: selectedSupportWorker,
            teamId: selectedTeam,
            startDateTime,
            endDateTime,
          },
        },
      });
    }
    setApiLoading(false);
  };

  const navigateReportingHoursPeriod = (rhp: ReportingHoursPeriod) => {
    navigate(`/reporting-hours/manage/${rhp.teamId}/${rhp.supportWorkerId}/${rhp.id}`);
  };

  const navigateSubmitReportingHours = (rhp: ReportingHoursPeriod) => {
    navigate(`/reporting-hours/submit/${rhp.teamId}/${rhp.supportWorkerId}/${rhp.id}`);
  };

  const updateReportingHours = (rhp: ReportingHoursPeriod, status: string) => {
    setApiLoading(true);
    submitReportingHour({
      variables: {
        input: {
          supportWorkerId: rhp.supportWorkerId,
          teamId: rhp.teamId,
          period: rhp.id,
          status,
        },
      },
    });
    setApiLoading(false);
  };

  const syncReportingHours = (rhp: ReportingHoursPeriod) => {
    setApiLoading(true);
    syncReportingHour({
      variables: {
        input: {
          supportWorkerId: rhp.supportWorkerId,
          teamId: rhp.teamId,
          period: rhp.id,
        },
      },
    });
    setApiLoading(false);
  };

  if (
    userLoading ||
    apiLoading ||
    rhpLoading ||
    mutationSaveReportingHours.loading ||
    mutationSubmitReportingHour.loading ||
    mutationSyncReportingHour.loading
  ) {
    return <Loading />;
  }

  const RowActions = ({ row }: { row: ReportingHoursPeriod }) => {
    return (
      <div className="flex flex-col md:flex-row md:items-center justify-end gap-2">
        {(user?.profile === selectedSupportWorker || user?.permissions?.admin) && (
          <button
            type="button"
            onClick={() => navigateSubmitReportingHours(row)}
            // onClick={() => updateReportingHours(row, ReportingHourStatus.submitted)}
            disabled={row.status === ReportingHourStatus.submitted}
            aria-label="Submit"
            className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md mt-0.5 disabled:opacity-40"
          >
            Submit
          </button>
        )}
        {(user?.profile === selectedSupportWorker || user?.permissions?.admin) && (
          <button
            type="button"
            onClick={() => syncReportingHours(row)}
            aria-label="Sync"
            className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md mt-0.5"
          >
            Sync
          </button>
        )}
        {(user?.profile === selectedSupportWorker || user?.permissions?.admin) && (
          <button
            type="button"
            onClick={() => updateReportingHours(row, ReportingHourStatus.inProgress)}
            disabled={row.status === ReportingHourStatus.inProgress || row.status === ReportingHourStatus.rejected}
            aria-label="Reject"
            className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md mt-0.5 disabled:opacity-40"
          >
            Reject
          </button>
        )}
        <button
          type="button"
          onClick={() => navigateReportingHoursPeriod(row)}
          aria-label="Edit"
          className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md mt-0.5"
        >
          View
        </button>
      </div>
    );
  };

  const columnHelper = createColumnHelper<ReportingHoursPeriod>();

  const columns1 = [
    columnHelper.accessor('startDateTime', {
      header: () => 'Period',
      cell: (info) => (
        <div className="text-md leading-md text-gray-900 font-semibold">
          {format(info.row.original.startDateTime ?? 0, 'dd/MM/yy')} - {format(info.row.original.endDateTime ?? 0, 'dd/MM/yy')}
        </div>
      ),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('status', {
      header: () => 'Status',
      cell: (info) => info.renderValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (props) => <RowActions row={props.row.original} />,
    }),
  ] as Array<ColumnDef<ReportingHoursPeriod, unknown>>;

  return (
    <>
      <Helmet>
        <title>List Reporting Hours</title>
      </Helmet>
      <LocationBar section="Reporting" page="Reporting Hours Periods" Icon={PunchClock} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex items-center justify-between gap-4">
          <div className="flex flex-col md:flex-row md:items-center md:gap-2">
            <TeamSelector selectTeam={onChangeTeam} teamId={selectedTeam} showAll={false} isContained />
            <SelectSupportWorker
              onSelectedSupportWorker={setSelectedSupportWorkerId}
              selectedSupportWorkerId={selectedSupportWorker}
              teamId={selectedTeam}
              isContained
              showLeavers
            />
          </div>
          {(user?.profile === selectedSupportWorker || user?.permissions?.admin) && (
            <button
              type="button"
              className="text-white bg-primary-700 rounded-lg p-2.5 md:px-5 md:py-2.5 font-semibold text-md leading-md mt-0.5 flex items-center disabled:opacity-40"
              // disabled={hasActiveReportingHours}
              onClick={createReportingHoursPeriod}
            >
              <PlusIcon className="w-5 h-5 md:mr-2" />
              <div className="hidden md:block">Create New Reporting Hours Period</div>
            </button>
          )}
        </div>
        <div className="mt-3 md:mt-12 mb-10">
          {reportingHoursPeriod && reportingHoursPeriod.length > 0 && <Table<ReportingHoursPeriod> data={reportingHoursPeriod} columns={columns1} />}
        </div>
        {openCreateRHPModal && selectedSupportWorker && (
          <CreateReportingHoursPeriod
            reportingHoursPeriod={reportingHoursPeriod}
            onClose={() => setOpenCreateRHPModal(false)}
            onUpdate={saveReportingHoursPeriod}
          />
        )}
      </div>
    </>
  );
};

export default ReportingHoursList;
