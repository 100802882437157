/* eslint-disable @typescript-eslint/no-explicit-any */
import Papa from 'papaparse';
import {
  Candidate,
  CandidateHistory,
  CandidateShadowFeedback,
  CandidateTracking,
  CandidateWorkshop,
  CandidateWorkshopFeedback,
  CurrentStatus,
  Maybe,
  Scorecard,
  CandidatePreviousApplications as CandidatePreviousApplicationsType,
} from '__generated__/graphql';
import { parse } from 'date-fns';

export type FacebookType = {
  Name: string;
  Emailaddress: string;
  Phonenumber: string;
  Created: string;
  Source: string;
  Form: string;
  Channel: string;
  Stage: string;
  Owner: string;
  Labels: string;
};

export type IndeedType = {
  name: string;
  email: string;
  phone: string;
  Status: string;
  candidateLocation: string;
  currentLastRole: string;
  education: string;
  jobTitle: string;
  joblocation: string;
  date: string;
  interestLevel: string;
  source: string;
  qualification1: string;
  qualification1Answer: string;
  qualification1Match: string;
  qualification2: string;
  qualification2Answer: string;
  qualification2Match: string;
  qualification3: string;
  qualification3Answer: string;
  qualification3Match: string;
  qualification4: string;
};

const splitName = (name: string, index: number) => {
  const items = name?.split(/ (.*)/s);
  const first = items?.length > 0 ? items[0] : '';
  const last = items?.length > 1 ? items[1] : '';
  return index === 0 ? first : last;
};

export const MapIndeed = (file: IndeedType[], user: string): Candidate[] => {
  return file
    .filter((f) => f.email)
    .map((data: IndeedType) => ({
      owner: user,
      firstName: splitName(data?.name, 0),
      lastName: splitName(data?.name, 1),
      email: data.email,
      phone: data.phone,
      campaign: data.joblocation,
      dateCreated: data.date ? parse(data.date, 'dd/mm/yyyy', new Date()).getTime() : Date.now(),
      source: 'Indeed',
      jobRole: 'Support Worker',
      currentStatus: 'Applied',
      status: 'Open',
      eligibleToWork: false,
      canDrive: false,
      postCode: '',
    }));
};

export const MapFacebook = (file: FacebookType[], user: string): Candidate[] => {
  return file
    .filter((f) => f.Emailaddress)
    .map((data: FacebookType) => ({
      owner: user,
      firstName: splitName(data?.Name, 0),
      lastName: splitName(data?.Name, 1),
      email: data.Emailaddress,
      phone: data.Phonenumber,
      source: 'Facebook',
      dateCreated: data.Created ? parse(data.Created.split(',')[0], 'dd/MM/yyyy', new Date()).getTime() : Date.now(),
      jobRole: 'Support Worker',
      currentStatus: 'Applied',
      status: 'Open',
      eligibleToWork: false,
      canDrive: false,
      campaign: data.Form,
    }));
};

export const MapFile = (file: any, user: string) => {
  const candidatesToImport = Papa.parse<FacebookType | IndeedType>(file, {
    header: true,
    transformHeader(header: any) {
      return header.trim().replaceAll(' ', '').replaceAll('/', '');
    },
  });

  if (candidatesToImport && candidatesToImport.data.length > 0) {
    const firstRecord = candidatesToImport.data[0];

    if ('email' in firstRecord) {
      return MapIndeed(candidatesToImport.data as IndeedType[], user);
    }

    if ('Emailaddress' in firstRecord) {
      return MapFacebook(candidatesToImport.data as FacebookType[], user);
    }
  }

  return null;
};

export const MapCandidate = (candidate: Candidate | null) => {
  let status = candidate?.currentStatus;

  if (candidate?.tracking?.initialCallArranged?.status) {
    status = CurrentStatus.InitialCallArranged;
  }
  if (candidate?.tracking?.initialCallDone?.status) {
    status = CurrentStatus.InitialCallDone;
  }
  if (candidate?.tracking?.workshopAttended?.status) {
    status = CurrentStatus.WorkshopAttended;
  }
  if (candidate?.tracking?.shadowAgreed?.status) {
    status = CurrentStatus.ShadowingAgreed;
  }
  if (candidate?.tracking?.shadowSuccess?.status) {
    status = CurrentStatus.SuccessfulShadowing;
  }
  if (candidate?.tracking?.jobOfferAccepted?.status) {
    status = CurrentStatus.JobOfferAccepted;
  }
  return {
    id: candidate?.id,
    firstName: candidate?.firstName,
    lastName: candidate?.lastName,
    email: candidate?.email,
    phone: candidate?.phone,
    source: candidate?.source,
    eligibleToWork: candidate?.eligibleToWork,
    currentStatus: status,
    canDrive: candidate?.canDrive,
    teamId: candidate?.teamId,
    circleId: candidate?.circleId,
    jobRole: candidate?.jobRole,
    owner: candidate?.owner,
    status: candidate?.status,
    dob: candidate?.dob,
    address: candidate?.address,
    city: candidate?.city,
    state: candidate?.state,
    postCode: candidate?.postCode,
    country: candidate?.country,
    campaign: candidate?.campaign,
    price: candidate?.price,
    reasonNotHired: candidate?.reasonNotHired,
    reasonNotHiredOther: candidate?.reasonNotHiredOther,
    scorecard: MapScorecard(candidate?.scorecard || []),
    history: MapHistory(candidate?.history || []),
    workshop: MapCandidateWorkshop(candidate?.workshop || {}),
    tracking: MapTracking(candidate?.tracking),
    workshopFeedback: MapCandidateWorkshopFeedback(candidate?.workshopFeedback || {}),
    shadowFeedback: MapCandidateShadowFeedback(candidate?.shadowFeedback || {}),
    previousApplications: MapPreviousApplications(candidate?.previousApplications || []),
    whyYou: candidate?.whyYou,
    roleAppeal: candidate?.roleAppeal,
    file: candidate?.file,
  };
};

export const MapCandidateWorkshop = (workshop: Maybe<CandidateWorkshop>) => {
  return {
    attended: workshop?.attended || false,
    note: workshop?.note,
    passed: workshop?.passed,
    shadowWithId: workshop?.shadowWithId,
  };
};

export const MapCandidateWorkshopFeedback = (workshopFeedback: Maybe<CandidateWorkshopFeedback>) => {
  return {
    personObserving: workshopFeedback?.personObserving || '',
    observationDate: workshopFeedback?.observationDate,
    communication: workshopFeedback?.communication,
    teamWork: workshopFeedback?.teamWork,
    personability: workshopFeedback?.personability,
    patience: workshopFeedback?.patience,
    empathy: workshopFeedback?.empathy,
    overallFeedback: workshopFeedback?.overallFeedback,
    progressShadow: workshopFeedback?.progressShadow,
  };
};

export const MapCandidateShadowFeedback = (shadowFeedback: Maybe<CandidateShadowFeedback>) => {
  return {
    personObserving: shadowFeedback?.personObserving || '',
    observationDate: shadowFeedback?.observationDate,
    communication: shadowFeedback?.communication,
    teamPlayer: shadowFeedback?.teamPlayer,
    listening: shadowFeedback?.listening,
    engagementWillingness: shadowFeedback?.engagementWillingness,
    conscientiousness: shadowFeedback?.conscientiousness,
    values: shadowFeedback?.values,
    other: shadowFeedback?.other,
  };
};

export const MapHistory = (history: Maybe<CandidateHistory>[]) => {
  return (history || []).map((h) => ({
    id: h?.id,
    date: h?.date,
    contactType: h?.contactType,
    message: h?.message,
    from: h?.from,
  }));
};

export const MapPreviousApplications = (previousApplications: Maybe<CandidatePreviousApplicationsType>[]) => {
  return (previousApplications || []).map((p) => ({
    dateCreated: p?.dateCreated,
    dateImported: p?.dateImported,
    dateUpdated: p?.dateUpdated,
    id: p?.id,
    firstName: p?.firstName,
    lastName: p?.lastName,
    email: p?.email,
    phone: p?.phone,
    source: p?.source,
    eligibleToWork: p?.eligibleToWork,
    currentStatus: p?.status,
    canDrive: p?.canDrive,
    teamId: p?.teamId,
    circleId: p?.circleId,
    jobRole: p?.jobRole,
    owner: p?.owner,
    status: p?.status,
    dob: p?.dob,
    address: p?.address,
    city: p?.city,
    state: p?.state,
    postCode: p?.postCode,
    country: p?.country,
    campaign: p?.campaign,
    price: p?.price,
    reasonNotHired: p?.reasonNotHired,
    reasonNotHiredOther: p?.reasonNotHiredOther,
    scorecard: MapScorecard(p?.scorecard || []),
    history: MapHistory(p?.history || []),
    workshop: MapCandidateWorkshop(p?.workshop || {}),
    tracking: MapTracking(p?.tracking),
    workshopFeedback: MapCandidateWorkshopFeedback(p?.workshopFeedback || {}),
    shadowFeedback: MapCandidateShadowFeedback(p?.shadowFeedback || {}),
  }));
};

export const MapScorecard = (scorecard: Maybe<Scorecard>[]) => {
  return (scorecard || []).map((s) => ({
    question: s?.question,
    answer: s?.answer,
    questionType: s?.questionType,
  }));
};

export const MapTracking = (tracking: Maybe<CandidateTracking> | undefined): CandidateTracking => {
  return {
    applicationUploaded: {
      stage: tracking?.applicationUploaded?.stage,
      canManuallyUpdate: tracking?.applicationUploaded?.canManuallyUpdate,
      status: tracking?.applicationUploaded?.status,
      dateUpdated: tracking?.applicationUploaded?.dateUpdated,
    },
    autoWelcomeTextAndEmailSent: {
      stage: tracking?.autoWelcomeTextAndEmailSent?.stage,
      canManuallyUpdate: tracking?.autoWelcomeTextAndEmailSent?.canManuallyUpdate,
      status: tracking?.autoWelcomeTextAndEmailSent?.status,
      dateUpdated: tracking?.autoWelcomeTextAndEmailSent?.dateUpdated,
    },
    initialCallArranged: {
      stage: tracking?.initialCallArranged?.stage,
      canManuallyUpdate: tracking?.initialCallArranged?.canManuallyUpdate,
      status: tracking?.initialCallArranged?.status,
      dateUpdated: tracking?.initialCallArranged?.dateUpdated,
    },
    initialCallDone: {
      stage: tracking?.initialCallDone?.stage,
      canManuallyUpdate: tracking?.initialCallDone?.canManuallyUpdate,
      status: tracking?.initialCallDone?.status,
      dateUpdated: tracking?.initialCallDone?.dateUpdated,
    },
    workshopInviteSent: {
      stage: tracking?.workshopInviteSent?.stage,
      canManuallyUpdate: tracking?.workshopInviteSent?.canManuallyUpdate,
      status: tracking?.workshopInviteSent?.status,
      dateUpdated: tracking?.workshopInviteSent?.dateUpdated,
    },
    workshopAttended: {
      stage: tracking?.workshopAttended?.stage,
      canManuallyUpdate: tracking?.workshopAttended?.canManuallyUpdate,
      status: tracking?.workshopAttended?.status,
      dateUpdated: tracking?.workshopAttended?.dateUpdated,
    },
    shadowAgreed: {
      stage: tracking?.shadowAgreed?.stage,
      canManuallyUpdate: tracking?.shadowAgreed?.canManuallyUpdate,
      status: tracking?.shadowAgreed?.status,
      dateUpdated: tracking?.shadowAgreed?.dateUpdated,
    },
    shadowSuccess: {
      stage: tracking?.shadowSuccess?.stage,
      canManuallyUpdate: tracking?.shadowSuccess?.canManuallyUpdate,
      status: tracking?.shadowSuccess?.status,
      dateUpdated: tracking?.shadowSuccess?.dateUpdated,
    },
    jobOfferAccepted: {
      stage: tracking?.jobOfferAccepted?.stage,
      canManuallyUpdate: tracking?.jobOfferAccepted?.canManuallyUpdate,
      status: tracking?.jobOfferAccepted?.status,
      dateUpdated: tracking?.jobOfferAccepted?.dateUpdated,
    },
  };
};
