import createPlotlyComponent from 'react-plotly.js/factory';
import { MeanScoresPerCohortType, ScoresPerCohortType } from '../types';

const Plot = createPlotlyComponent(window.Plotly);

const color_sequence = [
  '#df714a', // red
  '#495e6c', // gray/blue
  '#185d6f', // green
];
const color_sequence_weak = [
  '#edb8a5', // red
  '#bfc6cb', // gray/blue
  '#185d6f99', // green
];

const color_ticks = '#185d6f';

/**
 * This object renders the spider plot. This component expects the following properties:
 *
 *  data: an array containing objects with 'dimensions', 'values', and a name. See below for an example
 *
 *  <pre>
 *    data = [
 *      {
 *           variables: ['Artistic', 'Conventional', 'Enterprising', 'Investigative', 'Realistic', 'Social'],
 *           values: [44.240720, 53.504508, 52.6887782, 46.28056033, 43.39920183, 60.261307],
 *           cohort_name: "Lorenz Fischer"
 *         },
 *      {
 *           variables: ['Artistic', 'Conventional', 'Enterprising', 'Investigative', 'Realistic', 'Social'],
 *           values: [44.240720, 33.504508, 52.6887782, 96.28056033, 43.39920183, 60.261307],
 *           cohort_name: "Your Team"
 *      }
 *    ]
 *  </pre>
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

interface SpiderPlotProps {
  meanScoresPerCohort: MeanScoresPerCohortType[];
}

export const SpiderPlot = ({ meanScoresPerCohort }: SpiderPlotProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data: any = meanScoresPerCohort.map((e, idx) => {
    return {
      theta: e.variables, // the labels for the values
      r: e.values, // the values for the time series
      name: e.cohort_name, // the name for the time series
      type: 'scatterpolar',
      line: {
        color: color_sequence[idx],
        shape: 'spline',
        smoothing: 0.0, // todo: somehow the spline is not closed.. disabling this with 0.0 for now
      },
      fill: 'toself',
      mode: 'lines',
    };
  });

  return (
    <Plot
      data={data}
      layout={{
        polar: {
          radialaxis: {
            angle: 0,
            range: [1, 100],
            tickmode: 'array',
            tickvals: [20, 50, 80],
            ticktext: ['Low', 'Medium', 'High'],
            tickangle: 90,
            tickfont: {
              color: color_ticks,
            },
          },
          angularaxis: {
            tickfont: {
              color: color_ticks,
            },
          },
        },
        showlegend: false,
      }}
      className="w-full h-full"
      config={{ displayModeBar: false }}
    />
  );
};

/**
 * This objects renders a box plot. This component expects the following properties:
 *
 *  data: an array containing objects with 'dimensions', 'values', and a name. See below for an example
 *
 *  <pre>
 *    data = [
 *        {
 *             cohort_name: "Lorenz Fischer",
 *             variables: ['Realistic', 'Investigative', 'Artistic', 'Social', 'Enterprising', 'Conventional', ],
 *             values: [35.1574001866564, 50, 20, 90, 70, 17.176037644341463, 80],
 *             value_labels: ['Candidate', 'Candidate','Candidate','Candidate','Candidate','Candidate' ],
 *             annotations: [ { variable: 'Social', value: 90 } ]
 *        },
 *        {
 *             cohort_name: "Your Team",
 *             variables: [   'Realistic', 'Realistic', 'Realistic', 'Conventional', 'Conventional', 'Conventional'],
 *             values: [      35.1564,     35.52703,    31.60546356, 48.6517466,     37.09129894,    17.176463],
 *             value_labels: ['Lorenz',    'Mike',      'Jesse',     'Lorenz',       'Mike',         'Jesse']
 *        }
 *    ]
 *  </pre>
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

interface StripPlotProps {
  scoresPerCohort: ScoresPerCohortType[];
}

export const StripPlot = ({ scoresPerCohort }: StripPlotProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data: any = scoresPerCohort.map((e, idx) => {
    if (!e.annotations) {
      e.annotations = []; // add an empty list as a default value
    }
    return {
      type: 'box',
      x: e.variables,
      y: e.values,
      text: e.value_labels, // these are converted into the text labels
      name: e.cohort_name,
      hovertemplate: '%{text}', // this will just render the contents of the corresponding text field
      jitter: 0.3,
      marker: {
        color: color_sequence[idx],
      },
      boxpoints: 'all',
      pointpos: 0,
      line: { width: 0 }, // hiding the boxes by using zero width line
      fillcolor: color_sequence_weak[idx],
      whiskerwidth: 0,
    };
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const images: any = scoresPerCohort
    .map((e) => {
      if (e.annotations) {
        return e.annotations.map((a) => {
          const yCorrection = a.value && a.value > 50 ? -5 : 0;
          return {
            source: '/exclamation-mark.png', // todo: make this dynamic
            sizex: 8,
            sizey: 7,
            xref: 'x',
            yref: 'y',
            x: a.variable,
            y: (a.value ? a.value : 0) + yCorrection, // offset the exclamation mark
            opacity: 1.0,
            layer: 'above',
            xanchor: 'right',
            yanchor: 'middle',
          };
        });
      } else {
        return [];
      }
    })
    .flat();

  return (
    <Plot
      data={data}
      // Documentation: https://plotly.com/javascript/box-plots/
      layout={{
        yaxis: {
          // needed for the box plot
          tickvals: [20, 50, 80],
          ticktext: ['Low', 'Medium', 'High'],
          range: [0, 100], // render the plot from 0 to 100
          tickfont: {
            color: color_ticks,
          },
          tickangle: -30,
        },
        xaxis: {
          tickfont: {
            color: color_ticks,
          },
          tickangle: -30,
        },
        boxmode: 'group', // this groups the the traces in the box plot
        showlegend: true,
        legend: {
          orientation: 'h',
          yanchor: 'bottom',
          y: 1.02,
          xanchor: 'right',
          x: 1,
        },
        images,
      }}
      className="w-full h-full"
      config={{ displayModeBar: false }}
    />
  );
};
