import { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Helmet from 'react-helmet';
import { useGetHubspotSupportedPeople } from 'api/hooks/useCustomers';
import { ElderlyOutlined } from '@mui/icons-material';
import { LocationBar, Loading, Search, Table } from 'components';
import { PlusIcon, EyeIcon } from '@heroicons/react/24/outline';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { HubSpotCustomer } from '../../__generated__/graphql';

const HubSpotContactList = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');

  const { getHubspotSupportedPeople, loading, customers } = useGetHubspotSupportedPeople();

  const onSearch = () => {
    getHubspotSupportedPeople({
      variables: {
        query: {
          search,
        },
      },
    });
  };

  useEffect(() => {
    getHubspotSupportedPeople({
      variables: {
        query: {
          search,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEditButtonClick = useCallback(
    (sw: HubSpotCustomer) => {
      navigate(`/admin-people/${sw.teamhub?.id}`);
    },
    [navigate],
  );

  const onCreateButtonClick = useCallback(
    (sw: HubSpotCustomer) => {
      navigate(`/admin-people/hubspot/${sw.id}`);
    },
    [navigate],
  );

  const RowActions = useCallback(
    ({ row }: { row: HubSpotCustomer }) => {
      if (row.teamhub?.id) {
        return (
          <button data-cy="list-edit-button" type="button" onClick={() => onEditButtonClick(row)} aria-label="Create" className="m-0 p-0">
            <EyeIcon className="w-6 h-6 text-gray-500 hover:text-primary600" />
          </button>
        );
      }
      if (!row.teamhub?.id) {
        return (
          <button data-cy="list-create-button" type="button" onClick={() => onCreateButtonClick(row)} aria-label="Edit" className="m-0 p-0">
            <PlusIcon className="w-6 h-6 text-gray-500 hover:text-primary600" />
          </button>
        );
      }

      return null;
    },
    [onEditButtonClick, onCreateButtonClick],
  );

  const columnHelper = createColumnHelper<HubSpotCustomer>();

  const columns1 = useMemo(() => {
    return [
      columnHelper.accessor('id', {
        header: () => 'Id',
        cell: (info) => <div data-cy="list-id">{info.renderValue()}</div>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('firstname', {
        header: () => 'Name',
        cell: (info) => (
          <div data-cy="list-name">
            {info.row.original.firstname} {info.row.original.lastname}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('createDateTime', {
        header: () => 'Create Date Time',
        cell: (info) => <div data-cy="list-date">{format(info.renderValue() ?? 0, 'dd/MM/yy')}</div>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('teamhub', {
        header: () => 'Teamhub',
        cell: (props) => {
          if (!props.row.original.teamhub?.id) {
            return (
              <div
                data-cy="list-teamhub-not-imported"
                className="bg-error-50 border border-error-700 rounded-2xl text-error-700 text-sm leading-sm font-medium px-2.5 py-0.5 w-fit"
              >
                Not Imported
              </div>
            );
          } else if (props.row.original.teamhub?.id) {
            return (
              <div
                data-cy="list-teamhub-imported"
                className="bg-success-50 border border-success-700 rounded-2xl text-success-700 text-sm leading-sm font-medium px-2.5 py-0.5 w-fit"
              >
                Imported
              </div>
            );
          }
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('deal', {
        header: () => 'Deal',
        cell: (props) => {
          if (!props.row.original.deal?.signedTerms) {
            return (
              <div
                data-cy="list-deal-not-signed"
                className="bg-error-50 border border-error-700 rounded-2xl text-error-700 text-sm leading-sm font-medium px-2.5 py-0.5 w-fit"
              >
                Terms Not Signed
              </div>
            );
          } else if (props.row.original.deal?.signedTerms) {
            return (
              <div
                data-cy="list-deal-signed"
                className="bg-error-50 border border-error-700 rounded-2xl text-error-700 text-sm leading-sm font-medium px-2.5 py-0.5 w-fit"
              >
                Terms Signed
              </div>
            );
          }
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.display({
        id: 'actions',
        cell: (props) => <RowActions row={props.row.original} />,
      }),
    ] as Array<ColumnDef<HubSpotCustomer, unknown>>;
  }, [columnHelper, RowActions]);

  if (loading) {
    return <Loading />;
  }

  const rows = customers.map((c) => {
    return {
      ...c,
    };
  });

  return (
    <div>
      <Helmet>
        <title>Hubspot Supported People</title>
      </Helmet>
      <LocationBar section="Admin" page="Supported People List" Icon={ElderlyOutlined} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-display-sm sm:text-display-lg leading-display-sm sm:leading-display-lg sm:tracking-display-lg text-black font-medium">
            Hubspot Supported People
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-between mt-8 md:mt-16">
          <div className="flex flex-col md:flex-row items-center gap-4 md:gap-9 w-full md:w-fit">
            <Search value={search} onSearch={setSearch} />
            <div>
              <button
                className="relative border border-gray-900 text-gray-700 text-md leading-md flex items-center px-4 py-2.5 rounded-lg shadow-xs justify-center w-full md:w-fit"
                data-cy="search-button"
                type="button"
                onClick={() => onSearch()}
              >
                Search
              </button>
            </div>
          </div>
        </div>

        <div className="mt-3 md:mt-12 mb-10 overflow-x-auto overflow-y-hidden">
          {rows && rows.length > 0 && <Table<HubSpotCustomer> data={rows} columns={columns1} />}
        </div>
      </div>
    </div>
  );
};

export default HubSpotContactList;
