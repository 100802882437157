import { ReportingHours, Shift } from '__generated__/graphql';
import { differenceBetweenDates } from 'services/helpers';
import { camelCaseToNormalString } from 'services/strings';

export enum ShiftRunTypeEnum {
  agreed = 'agreed',
  tbc = 'tbc',
  oneoff = 'oneoff',
}

export enum VisitStatus {
  active = 'active',
  cancelled = 'cancelled',
  deleted = 'deleted',
  moved = 'moved',
}

export enum ReportingHourStatus {
  inProgress = 'In Progress',
  submitted = 'Submitted',
  rejected = 'Rejected',
}

export enum ShiftType {
  visit = 'visit',
  activity = 'activity',
  oneoff = 'oneoff',
  mileage = 'mileage',
}

export const calculateMilesTravelled = (data: Shift[]): number => {
  return data
    .filter((s) => s.status === VisitStatus.active)
    .map((x) => x.mileage || 0)
    .reduce((a, b) => a + b, 0);
};

export const calculateMilesInCarTravelled = (data: Shift[]): number => {
  return data
    .filter((s) => s.status === VisitStatus.active)
    .map((x) => x.travelWithCustomerMileage || 0)
    .reduce((a, b) => a + b, 0);
};

export const calculateTotalHoursInActivities = (data: Shift[]): number => {
  return data
    .filter((s) => s.type === ShiftType.activity && s.status === VisitStatus.active)
    .map((x) => differenceBetweenDates(x.startDateTime, x.endDateTime))
    .reduce((a, b) => a + b, 0);
};

export const calculateBankedHoursOnVisits = (data: ReportingHours[]): number => {
  return data
    .filter((s) => s.visit?.status === VisitStatus.active)
    .map((x) => x.bankedHours || 0)
    .reduce((a, b) => a + b, 0);
};

export const setType = (rh: ReportingHours, teamId: string, supportWorkerId: string) => {
  if (rh.type === ShiftType.activity) {
    return `Activity: ${camelCaseToNormalString(rh?.visit?.activityType || '')}`;
  } else if (rh?.teamId !== teamId) {
    return 'Cross team visit';
  } else if (rh?.visit?.type === ShiftType.oneoff) {
    return 'One off visit';
  } else if (rh?.shift?.ownerId === supportWorkerId) {
    return 'Agreed visit';
  } else if (rh?.shift?.ownerId !== supportWorkerId) {
    return `Cover for ${rh?.owner?.fullName} `;
  } else {
    return 'Unknown';
  }
};
