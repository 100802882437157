import { useLazyQuery, gql } from '@apollo/client';
import { Shift } from '__generated__/graphql';

export const GET_PAYROLL_REPORT = gql`
  query GetPayrollReport($query: PayrollReportSearch) {
    GetPayrollReport(query: $query) {
      id
      shiftId
      visitId
      startDateTime
      endDateTime
      travelTime
      teamId
      week
      status
      teamName
      type
      mileage
      travelWithCustomerMileage
      travelWithCustomerDestination
      notes
      whoCancelled
      cancelledDateTime
      oneOffVisitReason
      oneOffVisitNote
      isToBeRescheduled
      activityType
      rotaId
      birdieId
      origin {
        rotaId
        teamId
        visitId
        supportWorkerId
        fullName
      }
      destination {
        rotaId
        teamId
        visitId
        supportWorkerId
        fullName
      }
      customerId
      supportWorkerId
      secondarySupportWorkerId
    }
  }
`;

interface GetPayrollReportResponse {
  GetPayrollReport: Shift[];
}

export const useGetPayrollReport = (period: string) => {
  const [getShifts, { loading: visitsLoading, error: visitsError, data }] = useLazyQuery<GetPayrollReportResponse>(GET_PAYROLL_REPORT, {
    variables: { skip: !period },
  });
  return { getShifts, visitsLoading, visitsError, shifts: data?.GetPayrollReport || [] };
};
