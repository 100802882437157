import Helmet from 'react-helmet';
import { QueryStats } from '@mui/icons-material';
import { LocationBar } from 'components';

interface NavigationProps {
  report: string;
}

export default function Navigation({ report }: NavigationProps) {
  return (
    <>
      <Helmet>
        <title>Reports</title>
      </Helmet>
      <LocationBar section="Analytics" page={report} Icon={QueryStats} />
      <div className="px-4 md:px-[5%] flex flex-col sm:flex-row sm:items-center gap-2 mt-6">
        <a href="/reports/support-worker" className="text-gray-700 text-md leading-md hover:underline">
          Support Worker Report
        </a>
        <div className="text-gray-300 text-md leading-md hidden sm:block">|</div>
        <a href="/reports/rota" className="text-gray-700 text-md leading-md hover:underline">
          Rota Report
        </a>
        <div className="text-gray-300 text-md leading-md hidden sm:block">|</div>
        <a href="/reports/confirmation-practices" className="text-gray-700 text-md leading-md hover:underline">
          Confirmation Practices Report
        </a>
        <div className="text-gray-300 text-md leading-md hidden sm:block">|</div>
        <a href="/reports/annual-visit-cancellation" className="text-gray-700 text-md leading-md hover:underline">
          Annual Visit Cancellation Report
        </a>
        <div className="text-gray-300 text-md leading-md hidden sm:block">|</div>
        <a href="/reports/cancellations" className="text-gray-700 text-md leading-md hover:underline">
          Cancellation Report
        </a>
        <div className="text-gray-300 text-md leading-md hidden sm:block">|</div>
        <a href="/reports/rota/create" className="text-gray-700 text-md leading-md hover:underline">
          Generated Rota Reports
        </a>
      </div>
    </>
  );
}
