import { Team } from '__generated__/graphql';
import useGetTeams from 'api/hooks/useGetTeams';
import ReactSelect from 'components/Select';

interface TeamSelectProps {
  onSelectedTeam: (teamId: string) => void;
  selectedTeamId: string;
  isContained?: boolean;
  showAll?: boolean;
  allLabel?: string;
  testId?: string;
}

const TeamSelect = ({
  onSelectedTeam,
  selectedTeamId,
  showAll = false,
  isContained = false,
  allLabel = 'All',
  testId = 'team-react-select',
}: TeamSelectProps) => {
  const { teams, teamsLoading } = useGetTeams();
  const selectedTeam = teams?.find((team: Team) => team.teamId === selectedTeamId);
  const selectedTeamOption =
    selectedTeamId === 'all' ? { value: 'all', label: allLabel } : { value: selectedTeam?.teamId ?? '', label: selectedTeam?.teamName ?? '' };
  const teamOptions = teams.map((t) => ({ value: t.teamId, label: t.teamName }));
  const options = showAll ? [{ value: 'all', label: allLabel }, ...teamOptions] : teamOptions;

  return (
    <ReactSelect
      options={options}
      onChange={(option) => onSelectedTeam(option.value)}
      selectedValue={selectedTeamOption}
      isContained={isContained}
      isLoading={teamsLoading}
      testId={testId}
    />
  );
};

export default TeamSelect;
