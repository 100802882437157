import { AutoFixHigh } from '@mui/icons-material';
import Helmet from 'react-helmet';
import { Video, LocationBar } from 'components';

const cloudfront = process.env.REACT_APP_TEAMHUB_CLOUDFRONT;

const MagicNumber = () => {
  return (
    <>
      <Helmet>
        <title>The Magic number</title>
      </Helmet>
      <LocationBar section="Magic number" page="What is it?" Icon={AutoFixHigh} />
      <div className="my-10 px-4 md:px-[5%]">
        <Video fileName={`${cloudfront}/policies/magic-number.mp4`} />
      </div>
    </>
  );
};

export default MagicNumber;
