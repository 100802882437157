import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import AddIcon from '@mui/icons-material/Add';
import { User } from 'types/types';

interface TopNavButtonProps {
  toggleCreateShiftModal: (value: boolean) => void;
  toggleCreateActivityModal: (value: boolean) => void;
  user: User;
}

const TopNavButton = (props: TopNavButtonProps) => {
  const showActivityFeature = props.user.isTeamMember;
  const navigate = useNavigate();
  const { toggleCreateShiftModal, toggleCreateActivityModal } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const onMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const onClose = () => setAnchorEl(null);
  const onAddShift = () => {
    toggleCreateShiftModal(true);
    onClose();
  };

  const onAddActivity = () => {
    toggleCreateActivityModal(true);
    onClose();
  };

  const onGoBack = () => {
    navigate(-1);
    onClose();
  };

  if (!toggleCreateShiftModal) {
    return (
      <Tooltip title="Go Back" onClick={() => navigate(-1)} sx={{ width: '3em', height: '3em' }}>
        <Fab color="primary">
          <ChevronLeftIcon />
        </Fab>
      </Tooltip>
    );
  }

  if (!props.user.isGuest && !showActivityFeature) {
    return (
      <Tooltip title="Create a new shift" onClick={onAddShift} sx={{ width: '3em', height: '3em' }}>
        <Fab color="primary" data-cy="create-shift">
          <AddIcon />
        </Fab>
      </Tooltip>
    );
  }

  if (!props.user.isGuest && showActivityFeature) {
    return (
      <Box sx={{ position: 'absolute' }}>
        <Fab color="primary" onClick={onMenuClick} sx={{ width: '3em', height: '3em' }}>
          <SpeedDialIcon />
        </Fab>
        <Menu
          data-cy="team-shifts-menu"
          id="menu"
          anchorEl={anchorEl}
          open={open}
          onClose={onClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem data-cy="add-shift-menu" onClick={onAddShift}>
            Add Shift
          </MenuItem>
          <MenuItem data-cy="add-activity-menu" onClick={onAddActivity}>
            Add Activity
          </MenuItem>
          <MenuItem data-cy="go-back-menu" onClick={onGoBack}>
            Go Back
          </MenuItem>
        </Menu>
      </Box>
    );
  }

  return null;
};

export default TopNavButton;
