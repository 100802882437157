import { ChangeEvent, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { API, graphqlOperation } from 'aws-amplify';
import { User } from 'types/types';
import { ChatMessage, ChatRoom } from '__generated__/graphql';
import { AttachFile, HighlightOff, Send } from '@mui/icons-material';
import type { GraphQLSubscription } from '@aws-amplify/api';
import { useGetChatMessagesByRoom, useSaveChatMessage, OnCreateMessageByRoomId } from 'api/hooks/useChat';
import { getFilenameForChat, uploadFile } from 'services/file';
import Bubble from './components/Bubble';

const bucket = process.env.REACT_APP_TEAMHUB_S3_BUCKET;
const cloudfront = process.env.REACT_APP_TEAMHUB_CLOUDFRONT;
const region = process.env.REACT_APP_AWS_REGION;

interface ChatProps {
  user: User;
  room: ChatRoom | undefined;
}

type OnCreateMessageByRoomIdType = {
  OnCreateMessageByRoomId?: ChatMessage;
};

const Chat = ({ user, room }: ChatProps) => {
  const { chatMessages, refetch } = useGetChatMessagesByRoom({
    roomId: room?.id,
    teamId: room?.teamId,
  });

  const { saveMessage, mutationSaveMessage } = useSaveChatMessage({
    roomId: room?.id,
    teamId: room?.teamId,
  });

  const [currentMessage, setCurrentMessage] = useState<string>('');
  const [lastDate, setLastDate] = useState<number>(0);
  const [file, setFile] = useState<File | null>(null);

  const onSaveMessage = async () => {
    if (!currentMessage && !file) {
      return;
    }
    let fileUrl;

    if (file && file?.name) {
      const filename = getFilenameForChat(uuidv4(), 'chat/', file);

      if (filename) {
        fileUrl = await uploadFile(file, filename, { bucket, region, cloudfront });
      }
    }

    await saveMessage({
      variables: {
        input: {
          text: currentMessage,
          user: {
            _id: user.profile,
            name: user.fullName,
            avatar: user.avatar,
          },
          teamId: room?.teamId,
          roomId: room?.id,
          image: file && file.type?.includes('image') && fileUrl ? fileUrl : null,
          video: file && !file.type?.includes('image') && fileUrl ? fileUrl : null,
          file:
            file && !file.type?.includes('image') && fileUrl
              ? {
                  url: fileUrl,
                  name: file.name,
                  type: file.type,
                }
              : null,
        },
      },
    });
    setCurrentMessage('');
    setFile(null);
  };

  useEffect(() => {
    const chatScroll = document.getElementById('chat-scroll');
    if (chatScroll) {
      chatScroll?.scrollTo(0, chatScroll?.scrollHeight);
    }
  }, [chatMessages]);

  useEffect(() => {
    async function subscribe() {
      const subscription = API.graphql<GraphQLSubscription<OnCreateMessageByRoomIdType>>(
        graphqlOperation(OnCreateMessageByRoomId, {
          roomId: room?.id,
        }),
      ).subscribe({
        next: () => {
          refetch();
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        error: (error: any) => console.warn(error.error.errors),
      });

      return () => subscription.unsubscribe();
    }

    subscribe().catch();
  }, [refetch, room?.id]);

  const handeFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event?.target as HTMLInputElement;

    if (!input.files?.length) {
      return;
    }
    setFile(input.files[0]);
  };

  return (
    <>
      <div
        className={`overflow-y-auto ${
          file ? 'h-[calc(100vh-19.5em)] xl:h-[calc(100vh-15.5em)]' : 'h-[calc(100vh-16.5em)] xl:h-[calc(100vh-12.5em)]'
        }  py-6 flex flex-col gap-2`}
        id="chat-scroll"
      >
        {chatMessages.map((m: ChatMessage, index: number) => (
          <Bubble message={m} lastDate={lastDate} setLastDate={setLastDate} userId={user.profile ?? ''} key={index} />
        ))}
      </div>
      {file && (
        <div className="flex flex-row justify-between items-center gap-4 px-4 py-2 bg-white h-[3em]">
          <div className="flex flex-row items-center gap-2">
            <AttachFile className="text-primary-700" />
            <div className="text-gray-800 text-lg leading-lg font-medium">{file.name}</div>
          </div>
          <button type="button" onClick={() => setFile(null)} aria-label="delete file">
            <HighlightOff className="text-primary-700" />
          </button>
        </div>
      )}
      <div className="flex justify-between items-center gap-4 w-full px-4 py-2 shadow-chatInput rounded-xl h-[6em]" id="chat-input">
        <label htmlFor="file-input" aria-label="file-input" className="cursor-pointer">
          <AttachFile className="text-primary-700 disabled:opacity-50" />
          <input id="file-input" type="file" onChange={handeFileChange} className="hidden" />
        </label>
        <div className="w-full">
          <textarea
            value={currentMessage}
            onChange={(event) => setCurrentMessage(event.target.value)}
            className="bg-white px-5 py-2 rounded-lg text-md leading-md text-gray-700 placeholder:text-gray-500 border border-gray-300 shadow-xs w-full max-h-full"
            placeholder="Message"
          />
        </div>
        <button
          type="button"
          aria-label="send message"
          className="text-primary-700 disabled:opacity-50"
          onClick={onSaveMessage}
          disabled={mutationSaveMessage.loading}
        >
          <Send />
        </button>
      </div>
    </>
  );
};

export default Chat;
